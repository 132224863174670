
import {  quarterTargetDataList, quarterTarget } from "../endpoints";
import HttpService from "../http";
import { IQuartertargetResponse, IQuartertargetResponses } from "../../interfaces/planner/quarter-target";
const quarterTargetService = () => {

    const { httpRequest } = HttpService();
    const getQuarterTargetList = async (search:object):Promise<IQuartertargetResponses> => 
    new Promise((resolve,reject) => {
        httpRequest<IQuartertargetResponses>(
            "GET",
            `${quarterTargetDataList}`,
            {},
            search
            )
            .then(resolve)
            .catch(reject);
    });


    const addQuarterTarget = async (payload : object) :Promise<IQuartertargetResponse>=> new Promise((resolve,reject) => {
        httpRequest<IQuartertargetResponse>(
        "POST",
        `${quarterTarget}`,
        payload )
        .then(resolve)
        .catch(reject);
    });

    

    const getQuarterTarget = async (query:object) : Promise<IQuartertargetResponse> =>  new Promise((resolve,reject) => {
        httpRequest<IQuartertargetResponse>(
            "GET",
            `${quarterTarget}`,
            {},
            query
        )
        .then(resolve)
        .catch(reject);
    });

    const deleteQuarterTarget = async(payload:object):Promise<IQuartertargetResponse> =>
    new Promise((resolve,reject) => {
      httpRequest<IQuartertargetResponse>("DELETE",`${quarterTarget}`,payload)
      .then(resolve)
      .catch(reject);
    });
      
    const updateQuarterTarget = async(payload:object):Promise<IQuartertargetResponse> =>
      new Promise((resolve,reject) => {
        httpRequest<IQuartertargetResponse>("PUT",`${quarterTarget}`,payload)
        .then(resolve)
        .catch(reject);
      });



    return {getQuarterTargetList,addQuarterTarget,getQuarterTarget,updateQuarterTarget,deleteQuarterTarget};

};

export default quarterTargetService;