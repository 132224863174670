import Joi from "joi";
import { required, only_string, valid_string, valid_url } from "../../../shared";

export const basicDetailValidation = Joi.object({
    name: Joi.string()
        .regex(/^[a-zA-Z\s]+$/)
        .required()
        .min(3)
        .max(30)
        .custom((value, helpers) => {
            if (value.trim() === "") {
                return helpers.error("string.empty");
            }
            return value;
        }, "Name Validation")
        .label("Name")
        .messages({
            "string.empty": required,
            "string.pattern.base": only_string
        }),
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .lowercase()
        .label("Email")
        .allow(""),
    primaryNumber: Joi.string()
        .required()
        .min(8)
        .label("Primary Contact Number")
        .messages({
            "string.empty": required
        }),
    secondaryNumber: Joi.string()
        .min(8)
        .allow("")
        .label("Secondary Contact Number"),

    typeOfLead: Joi.string()
        .required()
        .label("Engagement Type")
        .messages({
            "string.empty": required
        }),
    _job: Joi.string()
        .required()
        .label("Job Title")
        .messages({
            "string.empty": required
        }),
    location: Joi.string()
        .valid("WFO", "WFH")
        .label("Work Location")
        .required()
        .messages({
            "string.empty": required,
            "any.only": valid_string
        }),
    homeLocation: Joi.string()
        .label("Home Location")
        .allow(""),
    testScore: Joi.number()
        .max(100)
        .min(0)
        .label("Test Score")
        .allow(""),
    firstSkill: Joi.string()
        .label("Primary Skill")
        .allow(""),
    secondSkill: Joi.string()
        .label("secondary Skill")
        .allow(""),
    resumes: Joi.string()
        .label("Resume")
        .allow(""),
    coderByte: Joi.string()
        .label("CoderByte")
        .allow(""),
    contentAssessment: Joi.string()
        .label("Content Assessment")
        .allow(""),
    graphicAssessment: Joi.string()
        .label("Graphics Assessment")
        .allow(""),
    url: Joi.string()
        .uri()
        .regex(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/)
        .trim()
        .allow("")
        .label("Profile URL")
        .messages({
            "string.pattern.base": valid_url
        }),
	reference: Joi.string()
		.label("Reference")
		.allow(""),
	others: Joi.string()
		.label("Other Reference")
		.allow(""),
    portfolio: Joi.string()
        .uri()
        .regex(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/)
        .trim()
        .allow("")
        .label("Portfolio URL")
        .messages({
            "string.pattern.base": valid_url
        }),
    currentLocation: Joi.object()
        .keys({
            country: Joi.string().allow("").optional(),
            state: Joi.string().allow("").optional(),
            city: Joi.string().allow("").optional(),
            postalCode: Joi.string().allow("").optional(),
            geo: Joi.array(),
            hometown: Joi.string().allow("").optional(),
            formattedAddress: Joi.string().allow("").optional(),
        })
        .allow("")
        .optional(),
    state: Joi.string().allow("").optional(),
    city: Joi.string().allow("").optional(),
    zip: Joi.string().allow("").optional(),
});
