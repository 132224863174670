import { IJobTitle } from "../../../../interfaces";
import { LOAD_JOB_TITLE } from "../../../actions";

interface IAction {
    type: "LOAD_JOB_TITLE",
    payload: IJobTitle
}

interface IState {
    list: IJobTitle[]
}

const initialState: IState = {
    list: [
        {
            createdAt: "",
            name: "",
            _id: "",
            createdBy: {
                name: ""
            },
            _department: {
                name: "",
                _id: ""
            },
            _jobPosition: {
                _id: "",
                name: ""
            }
        }
    ]
};

const jobTitleReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case LOAD_JOB_TITLE: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
};

export { jobTitleReducer };
