import { useEffect, FC } from "react";
import { useQuery } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { cpdDriveService } from "../../../../../services";
import { useParams } from "react-router-dom";
import { capitalize } from "../../../../../utilities/helper";
import { Box, Chip, Grid, TextField } from "@mui/material";
import { ICpdDriveCollegeField, ICpdDrive } from "../../../../../interfaces";
interface props {
    college: ICpdDrive | undefined;
    activeAction: boolean;
    currentTab: number;
}

const CpdCollegeDetail: FC<props> = ({ activeAction, currentTab }) => {
    const isDisable = true;
    const { id } = useParams();
    const hitQuery = (id === "new" || id === "view") ? false : true;
    const { getCpdDrive } = cpdDriveService();
    const DriveCollege = useQuery({ queryKey: ["collegeDriveList", hitQuery], queryFn: () => getCpdDrive({ _id: id }), enabled: hitQuery });
    const { control, setValue, getValues, watch, resetField } = useForm<ICpdDrive>({
        defaultValues: {
            _college: "",
            collegeId: {
                city: "",
                state: "",
                email: [],
                status: "",
                remark: "",
                duration: "",
                helpline: "",
                shortName: "",
                offerRule: "",
                designation: "",
                mobileNumber: "",
                linkedInPage: "",
                facebookPage: "",
                contactPerson: "",
                category: "tier-1",
                secondaryNumber: "",
                internshipStartMonth: "",
            }
        }
    });

    useEffect(() => {
        if (id === "new") {
            console.log("new");
        } else {
            if (DriveCollege.data?.data) {
                setValue("_college", capitalize(DriveCollege.data.data?.collegeId?.name));
                setValue("collegeId.city", capitalize(DriveCollege.data.data?.collegeId.city));
                setValue("collegeId.state", DriveCollege.data.data?.collegeId.state);
                setValue("collegeId.email", DriveCollege.data.data?.collegeId.email || []);
                setValue("status", DriveCollege.data.data?.collegeId.status);
                setValue("collegeId.remark", capitalize(DriveCollege.data.data?.collegeId.remark));
                setValue("collegeId.category", capitalize(DriveCollege.data.data?.collegeId.category));
                setValue("collegeId.helpline", DriveCollege.data.data?.collegeId.helpline);
                setValue("collegeId.duration", DriveCollege.data.data?.collegeId.duration);
                setValue("collegeId.shortName", DriveCollege.data.data?.collegeId.shortName.toUpperCase());
                setValue("collegeId.offerRule", DriveCollege.data.data?.collegeId.offerRule);
                setValue("collegeId.designation", capitalize(DriveCollege.data.data?.collegeId.designation));
                setValue("collegeId.facebookPage", DriveCollege.data.data?.collegeId.facebookPage);
                setValue("collegeId.mobileNumber", DriveCollege.data.data?.collegeId.mobileNumber);
                setValue("collegeId.linkedInPage", DriveCollege.data.data?.collegeId.linkedInPage);
                setValue("collegeId.contactPerson", capitalize(DriveCollege.data.data?.collegeId.contactPerson));
                setValue("collegeId.secondaryNumber", DriveCollege.data.data?.collegeId.secondaryNumber);
                setValue("collegeId.internshipStartMonth", DriveCollege.data.data?.collegeId.internshipStartMonth);
            }
        }
    }, [id, DriveCollege.data?.data]);

    const removeEmail = (key: string, value: string) => {
        if (key === "emailText") {
            let payload = getValues("collegeId.email");
            payload = payload.filter(email => email !== value);
            setValue("collegeId.email", payload);
        }
    };

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === "collegeId.email") {
                resetField("emailText");
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const fields: ICpdDriveCollegeField[] = [
        {
            label: "College Name*",
            name: "_college",
            type: "text",
        },
        {
            label: "College Short Name*",
            name: "collegeId.shortName",
            type: "text",
        },
        {
            label: "City*",
            name: "collegeId.city",
            type: "text",
        },
        {
            label: "State*",
            name: "collegeId.state",
            type: "text",
        },
        {
            label: "Category*",
            name: "collegeId.category",
            type: "text",
        },
        {
            label: "Helpline Number",
            name: "collegeId.helpline",
            type: "text",
        },
        {
            label: "Mobile Number (Primary)",
            name: "collegeId.mobileNumber",
            type: "text",
        },
        {
            label: "Mobile Number (Secondary)",
            name: "collegeId.secondaryNumber",
            type: "text",
        },
        {
            label: "Contact Person Name",
            name: "collegeId.contactPerson",
            type: "text",
        },
        {
            label: "Designation",
            name: "collegeId.designation",
            type: "text",
        },
        {
            label: "Email Address",
            name: "emailText",
            type: "text",
        },
        {
            label: "Status",
            name: "collegeId.status",
            type: "text",
        },
        {
            label: "Internship Start Month",
            name: "collegeId.internshipStartMonth",
            type: "text",
        },
        {
            label: "Duration",
            name: "collegeId.duration",
            type: "text",
        },
        {
            label: "LinkedIn",
            name: "collegeId.linkedInPage",
            type: "text",
        },
        {
            label: "Facebook",
            name: "collegeId.facebookPage",
            type: "text",
        },
        {
            label: "Offer Rule",
            name: "collegeId.offerRule",
            type: "text",
        },
        {
            label: "Remark",
            name: "collegeId.remark",
            type: "text",
        },
    ];

    currentTab === 1 && activeAction;

    return (
        <Box>
            <Grid container spacing={4}>
                {
                    fields.map(field => {
                        if (field.type === "text") {
                            return (<Grid key={field.label} item xs={12} md={6}>
                                <Controller
                                    control={control}
                                    name={field.name}
                                    render={(prop) => <TextField
                                        {...prop.field}
                                        label={field.label}
                                        className="disable-text"
                                        disabled={isDisable}
                                        variant={isDisable ? "standard" : "outlined"}
                                        size={isDisable ? "medium" : "small"}
                                    />}
                                />
                                {
                                    field.name === "emailText" && Array.isArray(getValues("collegeId.email")) && getValues("collegeId.email").map(email => (
                                        <Chip
                                            key={email}
                                            label={email}
                                            onDelete={() => removeEmail(field.name, email)}
                                            disabled={isDisable}
                                            color={isDisable ? undefined : "primary"}
                                            variant="outlined"
                                            sx={{ margin: "5px" }}
                                        />
                                    ))
                                }

                            </Grid>
                            );
                        }
                    })
                }
            </Grid>
        </Box>
    );
};

export default CpdCollegeDetail;