import { Grid, TextField } from "@mui/material";
import { useNavigate ,useOutletContext} from "react-router-dom";
import useSnackbar from "../../../hooks/useSnackbar";
import { SkillMatrixService } from "../../../services";
import { Controller, useForm } from "react-hook-form";
import { EditSkillValidation } from "../../../validations/skill-matrix/edit-skill-matrix/skill";
import { IAddSkillMatrix } from "../../../interfaces/skill-matrix/action";
import { joiResolver } from "@hookform/resolvers/joi";
import { ISKillField } from "../../../interfaces/skill-matrix/edit-skill-matrix/edit-skill";
import { IErrorResponse } from "../../../interfaces";
import CustomDialog from "../../../components/mui/dialog";
import CustomLabel from "../../../components/mui/custom-label";
interface IOutletProps{
    reFetch: () => void;
}

const AddSkillMatrixLayout = () => {
    const {snackbar} = useSnackbar();
    const { addSkill } = SkillMatrixService();
    const outlet = useOutletContext<IOutletProps>();
    const navigate = useNavigate();
    const {control,
        handleSubmit,
        formState:{errors}
    } = useForm<IAddSkillMatrix>({
        resolver: joiResolver(EditSkillValidation),
        defaultValues:{
            name:""
        }
    });

    const fields:ISKillField[] = [
        {
            name:"name",
            label:"Skill Matrix Name",
            type:"input",
            placeholder:"Enter Skill Matrix Name",
            required: true
        }
    ];

    const onSubmit = async (data:IAddSkillMatrix) => {
        try{
            const response = await addSkill(data);
            snackbar(response.message,"info");
            navigate("/skill-matrix");
            outlet?.reFetch && outlet.reFetch();
        }catch(error){
         const err = error as IErrorResponse;
         snackbar(err?.data?.message,"error");
         console.log("Error in adding skill",err);
        }
    };
    return (
       <CustomDialog
       size="sm"
       title="Add Skill Matrix"
       confirmText="ADD SKILL MATRIX"
       isOpen={true}
       onClose={() => navigate("/skill-matrix")}
       onSubmit={handleSubmit(onSubmit)}
       >
        <Grid container spacing={2}>
            { fields.map((field) =>{
            if(field.type==="input"){
                return(
                    <Grid key={field.label} item xs={12}>
                        <Controller
                            name={field.name}
                            control={control}
                            render = {(prop) => ( 
                                <TextField
                                label={<CustomLabel label={field.label} required={field.required}/>}
                                className="disable-text"
                                variant="outlined"
                                size="small"
                                placeholder={field.placeholder}
                                error={!!errors[field.name]}
                                helperText={errors[field.name]?.message}
                                {...prop.field}    
                                /> 
                                
                            )  }
                            />
                    </Grid>
                );
            }
            })}
        </Grid>

       </CustomDialog>
    );
};

export default AddSkillMatrixLayout;
