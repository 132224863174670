import React, { ChangeEvent, FC, SyntheticEvent, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Tabs, Tab, Typography, FormControlLabel, Checkbox, DialogActions, Button, Divider, Pagination, Tooltip } from "@mui/material";
import { IEditSkillMatrix, ISkillMatrixItem } from "../../../../interfaces/skill-matrix/edit-skill-matrix";
import GetActions from "../../../../components/get-actions";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../components/shared/mui-tabs";
import { SkillMatrixService } from "../../../../services";
import { useQuery } from "@tanstack/react-query";
import { SkillItemCategoryService } from "../../../../services/skill-matrix/skill-items-categories";
import useSnackbar from "../../../../hooks/useSnackbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import useResource from "../../../../hooks/useResource";
import { capitalize } from "../../../../utilities/helper";
import ErrorMessage from "../../../../components/shared/error-message";
import { ISkillMatrixUserState } from "../../../../interfaces";
import "./style.scss";
interface IProps {
  skillData: ISkillMatrixItem[];
  employees: IEditSkillMatrix[];
  pagination: {
    page: number;
    totalPages: number;
  };
  setState: React.Dispatch<React.SetStateAction<ISkillMatrixUserState>>
  onPageChange: (e: ChangeEvent<unknown>, page: number) => void;
  reFetchUserSkills: () => void;
  reFetchSkillItemData: () => void;
}

const SkillsTable: FC<IProps> = ({ skillData, employees, pagination, onPageChange, reFetchUserSkills, reFetchSkillItemData, setState }) => {
  const navigate = useNavigate();
  const { resourceAllocate } = useResource();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const { getSkillMatrixCategoryItems, deleteSkillItem } = SkillMatrixService();
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchParams] = useSearchParams();

  const { snackbar } = useSnackbar();

  const { deleteSkillItemCategory } = SkillItemCategoryService();

  const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, skillId: string) => {
    if (event.target.checked) {
      setSelectedSkills((prevSelectedSkills) => [...prevSelectedSkills, skillId]);
    } else {
      setSelectedSkills((prevSelectedSkills) =>
        prevSelectedSkills.filter((id) => id !== skillId)
      );
    }
  };
  const handleDeleteCategory = async () => {
    try {
      const category = await deleteSkillItemCategory({ _id: selectedCategory });
      setState(prev => ({ ...prev, _deletedCategoryId: selectedCategory }));
      snackbar(category.message, "info");
    } catch (error) {
      console.log("Error in category delete", error);
    }
  };

  const handleDeleteSkillItems = async () => {
    try {
      const category = await deleteSkillItem({ _ids: selectedSkills });
      snackbar(category.message, "info");
    } catch (error) {
      console.log("Error in skill items delete", error);
    }
  };

  const handleDelete = () => {
    if (selectedTab === 0) {
      handleDeleteCategory();
    } else {
      handleDeleteSkillItems();
    }
    reFetchUserSkills();
    reFetchSkillItemData();
    setSelectedSkills([]);
    setSelectedCategory("");
    setOpenDeleteDialog(false);
  };

  const skillItems = useQuery({
    queryKey: ["getSkillMatrixCategoryItems", selectedCategory],
    queryFn: () => getSkillMatrixCategoryItems({ _skillCategory: selectedCategory }),
    enabled: !!selectedCategory && selectedTab === 1
  });
  return (
    <>
      <TableContainer className="table-skill-container">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="category-cell header set-width">
                Category
              </TableCell>
              <TableCell className="category-cell header set-width">
                Skill
              </TableCell>
              {employees &&
                employees.map((employee, index) => (
                  <TableCell
                    key={index}
                    className={
                      resourceAllocate("skill-user-mapping.write")
                        ? "style-table-cell header category-cell"
                        : "header category-cell"
                    }
                    onClick={
                      resourceAllocate("skill-user-mapping.write")
                        ? () => {
                            setState((prevState) => ({
                              ...prevState,
                              editEmployee: !prevState.editEmployee,
                              _employeeId: employee._id,
                            }));
                          }
                        : undefined
                    }
                  >
                    <Tooltip title={employee._user.name || ""}>
                      <span>
                        {capitalize(
                          employee._user.name && employee._user.name.length > 8
                            ? employee._user.name.substring(0, 8) + "..."
                            : employee._user.name
                        )}
                      </span>
                    </Tooltip>
                  </TableCell>
                ))}
              <TableCell className="action-cell header">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {skillData &&
              skillData.map((categoryData) => (
                <React.Fragment
                  key={
                    categoryData.skillCategory && categoryData.skillCategory._id
                  }
                >
                  {categoryData.skillItems &&
                  categoryData.skillItems.length > 0 ? (
                    categoryData.skillItems.map((skillItem, index) => (
                      <TableRow key={skillItem._id}>
                        {index === 0 && (
                          <TableCell
                            rowSpan={categoryData.skillItems.length}
                            onClick={
                              resourceAllocate("skill-matrix-category.write")
                                ? () => {
                                    navigate({
                                      pathname:
                                        "category/" +
                                        categoryData.skillCategory._id,
                                      search: searchParams.toString(),
                                    });
                                  }
                                : undefined
                            }
                            className={
                              resourceAllocate("skill-matrix-category.write")
                                ? "style-table-cell category-cell"
                                : "category-cell"
                            }
                          >
                            <Tooltip
                              title={capitalize(
                                categoryData.skillCategory.name
                              )}
                            >
                              <span>
                                {categoryData.skillCategory.name.length > 30
                                  ? `${capitalize(
                                      categoryData.skillCategory.name
                                    ).slice(0, 30)}...`
                                  : capitalize(categoryData.skillCategory.name)}
                              </span>
                            </Tooltip>
                          </TableCell>
                        )}
                        <TableCell
                          className={
                            resourceAllocate("skill-matrix-item.write")
                              ? "style-table-cell category-cell"
                              : "category-cell"
                          }
                          onClick={
                            resourceAllocate("skill-matrix-item.write")
                              ? () => {
                                  setState((prevState) => ({
                                    ...prevState,
                                    editSkill: !prevState.editSkill,
                                    _skillId: skillItem._id,
                                  }));
                                }
                              : undefined
                          }
                        >
                          {capitalize(skillItem.name)}
                        </TableCell>
                        {employees &&
                          employees.map((employee) => (
                            <TableCell key={employee._id} className="data-cell">
                              {employee.skillItems.find(
                                (item) => item?._itemId?._id === skillItem?._id
                              )?.nextScore || 0}
                            </TableCell>
                          ))}
                        {index === 0 && (
                          <TableCell
                            className="action-cell"
                            rowSpan={categoryData.skillItems.length}
                          >
                            <GetActions
                              icons={[
                                {
                                  name: "Delete",
                                  disabled: !(
                                    resourceAllocate(
                                      "skill-matrix-category.remove"
                                    ) || resourceAllocate("skill-matrix.remove")
                                  ),
                                  method: () => {
                                    setOpenDeleteDialog(true);
                                    setSelectedCategory(
                                      categoryData.skillCategory._id
                                    );
                                  },
                                },
                              ]}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        onClick={
                          resourceAllocate("skill-matrix-category.write")
                            ? () => {
                                navigate({
                                  pathname:
                                    "category/" +
                                    categoryData.skillCategory._id,
                                  search: searchParams.toString(),
                                });
                              }
                            : undefined
                        }
                        className={
                          resourceAllocate("skill-matrix-category.write")
                            ? "style-table-cell category-cell"
                            : "category-cell"
                        }
                      >
                        <Tooltip
                          title={capitalize(categoryData.skillCategory.name)}
                        >
                          <span>
                            {(
                              categoryData.skillCategory &&
                              categoryData.skillCategory.name &&
                              categoryData.skillCategory.name
                            ).length > 30
                              ? `${capitalize(
                                  categoryData.skillCategory.name
                                ).slice(0, 30)}...`
                              : capitalize(categoryData.skillCategory.name)}
                          </span>
                        </Tooltip>
                        
                      </TableCell>
                      <TableCell className="data-cell">{""}</TableCell>
                      {employees &&
                        employees.map((_employee, index) => (
                          <TableCell key={index} className="data-cell">
                            {""}
                          </TableCell>
                        ))}
                      <TableCell className="action-cell">
                        <GetActions
                          icons={[
                            {
                              name: "Delete",
                              disabled: !(
                                resourceAllocate(
                                  "skill-matrix-category.remove"
                                ) || resourceAllocate("skill-matrix.remove")
                              ),
                              method: () => {
                                setOpenDeleteDialog(true);
                                setSelectedCategory(
                                  categoryData.skillCategory._id
                                );
                              },
                            },
                          ]}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!skillItems && !employees && (
        <ErrorMessage errorMessage={"Add some data to see"} />
      )}
      <BootstrapDialog
        maxWidth="sm"
        onClose={() => {
          setOpenDeleteDialog(false);
          setSelectedCategory("");
        }}
        aria-labelledby="customized-dialog-title"
        open={openDeleteDialog}
        scroll="paper"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenDeleteDialog(false);
            setSelectedCategory("");
          }}
        >
          {"What do you want to delete?"}
        </BootstrapDialogTitle>

        <Box className="dialog-body">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="category and skill items tabs"
          >
            <Tab label="Category" />
            <Tab label="Skill Items" />
          </Tabs>
          <Divider />
          {selectedTab === 0 && (
            <Box p={3}>
              <Typography>
                Are you sure you want to delete this entire category?
              </Typography>
            </Box>
          )}
          {selectedTab === 1 && (
            <Box p={3} className="skill-items-box">
              {skillItems?.data?.data?.length ? (
                skillItems.data.data.map((item) => (
                  <Box key={item._id}>
                    <FormControlLabel
                      key={item._id}
                      control={
                        <Checkbox
                          checked={selectedSkills.includes(item._id)}
                          onChange={(event) =>
                            handleCheckboxChange(event, item._id)
                          }
                          name={item.name}
                        />
                      }
                      label={item.name}
                    />
                  </Box>
                ))
              ) : (
                <Typography>No skill items available</Typography>
              )}
            </Box>
          )}
        </Box>

        <Divider />
        <DialogActions className="dialog-footer">
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          {selectedTab === 1 && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                skillItems &&
                  skillItems.data &&
                  skillItems.data.data &&
                  skillItems.data.data.forEach((item) => {
                    setSelectedSkills((prevSelectedSkills) => [
                      ...prevSelectedSkills,
                      item._id,
                    ]);
                  });
              }}
            >
              Select all
            </Button>
          )}
          <Button
            onClick={handleDelete}
            color="primary"
            disabled={
              selectedTab === 1
                ? !resourceAllocate("skill-matrix.remove") &&
                  selectedSkills.length === 0
                : !resourceAllocate("skill-matrix-category.remove")
            }
          >
            Delete
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Box className="pagination">
        <Pagination
          count={pagination.totalPages}
          page={pagination.page}
          onChange={onPageChange}
          color="primary"
        />
      </Box>
    </>
  );
};

export default SkillsTable;
