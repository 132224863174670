import { IRole } from "../../../../interfaces";
import { LOAD_ROLE } from "../../../actions";

interface IAction {
    type: "LOAD_ROLE",
    payload: IRole
}

interface IState {
    list: IRole[]
}

const initialState: IState = {
    list: [
        {
            _id: "",
            name: "",
            _department: {
                name: "",
                _id: "",
            },
            description: "",
            createdAt: "",
            isDefault: false,
            createdBy: {
                name: "",
            },
            role: "SUPERADMIN",
            status: "ACTIVE",
            resources: [],
        }
    ]
};

const roleReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case LOAD_ROLE: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
};

export { roleReducer };
