import Joi from "joi";
import { required, password_pattern } from "../../shared";

export const changePasswordValidation = Joi.object({
    oldPassword: Joi.string()
        .min(8)
        .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,1024}$/)
        .required()
        .label("Current Password")
        .messages({
            "string.empty": required,
            "string.pattern.base": password_pattern
        }),
    newPassword: Joi.string()
        .min(8)
        .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,1024}$/)
        .required()
        .label("New Password")
        .messages({
            "string.empty": required,
            "string.pattern.base": password_pattern
        }),
    confirmNewPassword: Joi.string()
        .min(8)
        .valid(Joi.ref("newPassword")).messages({ "any.only": "{{#label}} does not match" })
        .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,1024}$/)
        .required()
        .label("Confirm Password")
        .messages({
            "string.empty": required,
            "string.pattern.base": password_pattern
        }),
});