import Joi from "joi";
import { required } from "../shared";

export const BroadcastGroupValidation = Joi.object({
    typeOfLead: Joi.string()
        .required()
        .label("Job Type")
        .messages({
            "string.empty": required,
        }),
    source: Joi.array()
        .items(Joi.string())
        .optional()
        .allow("")
        .label("Source"),
    masterLimit: Joi.string()
        .optional()
        .allow(""),
    content: Joi.string()
        .optional()
        .allow(""),
    subject: Joi.string()
        .optional()
        .allow(""),
    jobId: Joi.array()
        .items(Joi.string())
        .min(1)
        .required()
        .label("Job Title")
        .messages({
            "any.required": "Job Title is required",
        }),
    candidateStatus: Joi.array()
        .items(Joi.string())
        .min(1)
        .required()
        .label("Status")
        .messages({
            "any.required": "Status is required",
        }),
    collegeName: Joi.string()
        .optional()
        .allow("")
        .label("College Name"),
    graduationYear: Joi.array()
        .items(Joi.string())
        .optional()
        .allow("")
        .label("Graduation Year"),
    experience: Joi.number()
        .optional()
        .allow("")
        .label("Experience"),
    noticePeriod: Joi.number()
        .when("typeOfLead", {
            is: Joi.not("INTERN"),
            then: Joi.required(),
            otherwise: Joi.optional().allow(""),
        })
        .label("Notice Period")
        .messages({
            "number.empty": required,
        }),
    broadcastGroupName: Joi.string()
        .min(3)
        .max(60)
        .trim()
        .required()
        .label("BroadcastGroup Name")
        .messages({
            "string.empty": required,
        }),
    totalLeads: Joi.string()
        .optional()
        .allow(""),

});

