import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { routePermission } from "../assets/permissions";
import useUser from "../hooks/useUser";

const AuthGuard = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { setUser } = useUser();
    const { user } = useUser();

    useEffect(() => {
        if (
            localStorage.getItem("currentUserToken") === "" ||
            localStorage.getItem("currentUserToken") === null
        ) {
            setUser(() => ({}));
            localStorage.setItem("currentPath", location.pathname);
            navigate("/login");
        } else {
            if (!user) {
                return;
            }
            if (location.pathname === "/skill-matrix") {
                return;
            }
            if (location.pathname === "/requested-skill") {
                return;
            }

            const permissionBlock = routePermission.find(block => {
                const regex = new RegExp("^" + block.path);
                return location.pathname.match(regex);
            });

            const permissionGrant = permissionBlock?.permissions?.some(permission => user?._role?.resources.includes(permission));

            if (!permissionGrant && location.pathname!== "/") {
                navigate("/");
            }
        }

    }, [location.pathname, user]);

    return (
        <div>
            <Outlet />
        </div>
    );
};

export default AuthGuard;
