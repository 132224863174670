import HttpService from "../../http";
import { questionLanguages } from "../../endpoints";
import { ILanguagesResponse } from "../../../interfaces";

const QuestionLanguageService = () => {
    const { httpRequest } = HttpService();

    const getLanguages = async (search: object): Promise<ILanguagesResponse> => new Promise((resolve, reject) => {
        httpRequest<ILanguagesResponse>(
            "GET",
            `${questionLanguages}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    return { getLanguages };
};

export { QuestionLanguageService };
