import Joi from "joi";
import { required, } from "../../../shared";

export const AddSkillValidation = Joi.object({

    name: Joi.string()
        .regex(/^[a-zA-Z\s]+$/)
        .trim()
        .required()
        .label("Skill")
        .messages({
            "string.empty": required,
            "string.pattern.base": "Please enter a valid skill name",
        }),
    skillItems: Joi.string()
        .optional()
        .allow("")
       
});
