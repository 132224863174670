import Joi from "joi";
import { required, valid_url } from "../../shared";

export const coderByteLinkValidation = Joi.object({
    link: Joi.string()
        .required()
        .uri()
        .regex(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/)
        .trim()
        .label("Logical Assessment")
        .messages({
            "string.empty": "Logical assessment link is required",
            "string.pattern.base": valid_url 
        }),
    template: Joi.string()
        .required()
        .label("Template")
        .messages({
            "string.empty": required,
        }),
    department: Joi.string()
        .required()
        .label("Department")
        .messages({
            "string.empty": required,
        })
});
