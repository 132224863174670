import { useState } from "react";
import { ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import { joiResolver } from "@hookform/resolvers/joi";
import { CollegeImageService } from "../../../../services";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import { ICollegeImageField, ICollegeImage, IErrorResponse } from "../../../../interfaces";
import { CollegeImageValidation } from "../../../../validations/careers/college-image";
import HttpService from "../../../../services/http";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import UploadImg from "../../../../assets/images/upload.png";

interface outletProps {
    reFetch: () => void
}

const ManageCollegeImage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const { httpFormRequest } = HttpService();
    const outlet = useOutletContext<outletProps>();
    const { addCollegeImage } = CollegeImageService();
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const { handleSubmit, setValue, getValues, trigger, formState: { errors } } = useForm<ICollegeImage>({
        resolver: joiResolver(CollegeImageValidation),
        defaultValues: {
            image: "",
        }
    });

    const uploadFile = async (e: ChangeEvent<HTMLInputElement>, type: string) => {
        try {
            if (e.target.files && e.target.files.length > 0) {
                const uploaded = await httpFormRequest<{ data: string }>(
                    e.target.files,
                    e.target.files[0].name,
                    ["png", "jpeg", "jpg"],
                    1
                );
                const keyExist = type === "image";
                if (keyExist) {
                    setValue(type, uploaded.data);
                    setSelectedImage(URL.createObjectURL(e.target.files[0]));
                    trigger(type);
                }
            }
        } catch (error) {
            console.log("error in candidate detail upload", error);
        }
    };

    const onSubmit = async (data: ICollegeImage) => {
        try {
            if (id === "new") {
                const payload = { ...data };
                const add = await addCollegeImage(payload);
                snackbar(add.message, "info");
                navigate("/careers/college-images");
                outlet?.reFetch && outlet.reFetch();
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }

    };
    const fields: ICollegeImageField[] = [
        {
            label: "Name*",
            name: "image",
            type: "upload",
        },
    ];

    return (
        <Box>
            <CustomDialog
                title={"Add College Image"}
                isOpen={id ? true : false}
                onClose={() => navigate("/careers/college-images")}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "upload") {
                                return (<Grid key={field.label} item xs={12}>
                                    <Box className='center'>
                                        <Box height='100px' width='100px' className="upload-img" aria-label="upload picture" component="label">
                                            <img src={selectedImage || getValues("image") || UploadImg} alt="review" />
                                            <div className="edit-img ">Edit</div>
                                            <input hidden accept="image/*" type="file" onChange={e => uploadFile(e, field.name)} />
                                        </Box>
                                    </Box>
                                    {errors[field.name] && <span style={{ color: "#d32f2f", marginLeft: "376px", fontWeight: 400, fontSize: "0.85rem" }}>{errors[field.name]?.message}</span>}
                                </Grid>
                                );
                            }
                        })
                    }
                </Grid>
            </CustomDialog>
        </Box>
    );

};

export default ManageCollegeImage;