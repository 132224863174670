import HttpService from "../../http";
import { IReviewParametersResponse, IReviewParameterResponse } from "../../../interfaces";
import { reviewParameter } from "../../endpoints";

const ReviewParameterService = () => {
    const { httpRequest } = HttpService();
    const addReviewParameter = async (payload: object): Promise<IReviewParametersResponse> => new Promise((resolve, reject) => {
        httpRequest<IReviewParametersResponse>(
            "POST",
            `${reviewParameter}`,
            payload

        )
            .then(resolve)
            .catch(reject);
    });

    const getReviewParameters = async (search: object): Promise<IReviewParametersResponse> => new Promise((resolve, reject) => {
        httpRequest<IReviewParametersResponse>(
            "GET",
            `${reviewParameter}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getReviewParameter = async (query: object): Promise<IReviewParameterResponse> => new Promise((resolve, reject) => {
        httpRequest<IReviewParameterResponse>(
            "GET",
            `${reviewParameter}`,
            {},
            query

        )
            .then(resolve)
            .catch(reject);
    });

    const updateReviewParameter = async (id: string, payload: object): Promise<IReviewParameterResponse> => new Promise((resolve, reject) => {
        httpRequest<IReviewParameterResponse>(
            "PUT",
            `${reviewParameter}`,
            payload

        )
            .then(resolve)
            .catch(reject);
    });

    const deleteReviewParameter = async (payload: object): Promise<IReviewParametersResponse> => new Promise((resolve, reject) => {
        httpRequest<IReviewParametersResponse>(
            "DELETE",
            `${reviewParameter}`,
            {},
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { addReviewParameter, getReviewParameters, getReviewParameter, updateReviewParameter, deleteReviewParameter };
};

export { ReviewParameterService };