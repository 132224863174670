import "./style.scss";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { Box, Button, Grid, SelectChangeEvent, TextField } from "@mui/material";
import GetActions from "../../../../components/get-actions";
import { IVendorEdit } from "../../../../interfaces/vendor";
import { VendorContactPersonService } from "../../../../services/vendor/contact-person";
import useSnackbar from "../../../../hooks/useSnackbar";
import { Controller, useForm } from "react-hook-form";
import { IErrorResponse } from "../../../../interfaces/shared/response";
import { IColumn } from "../../../../interfaces/shared";
import { capitalize } from "../../../../utilities/helper";
import CustomTable from "../../../../components/mui/table";
import WarningDialog from "../../../../components/mui/warning-dialog";
import ManageVendorContactPerson from "../contact-person/manage";
import MobileNumber from "../../../../components/mui/mobile-number";
import CustomLabel from "../../../../components/mui/custom-label";
import { useQuery } from "@tanstack/react-query";
import { IContactPerson, IContactPersonDetail, IContactPersonRow, IContactPersonState } from "../../../../interfaces/vendor/contact-person";
import { vendorContactPersonValidation } from "../../../../validations/vendor/manage-vendor/contact-person";
import { joiResolver } from "@hookform/resolvers/joi";
import SearchIcon from "@mui/icons-material/Search";
import useDebounce from "../../../../hooks/useDebounce";
import { useSearchParams } from "react-router-dom";
import CustomTypography from "../../../../components/mui/max-length-limit";

interface props {
  vendor: IVendorEdit | undefined;
  activeAction: boolean;
  setActiveAction: (e: boolean) => void;
}

const ContactDetail: FC<props> = ({ vendor, activeAction, setActiveAction}) => {
    const [contactPersonId, setContactPersonId] = useState("");
    const { snackbar } = useSnackbar();
    const [search, setSearch] = useState<string>("");
    const { addContactPerson, deleteContactPerson, getContactPersons } = VendorContactPersonService();
    const [searchParams, setSearchParams] = useSearchParams();
    const [state, setState] = useState<IContactPersonState>({
      list: [],
      deleteWarning: false,
      manageContactPerson: {
        isOpen: false,
        contactPerson: {
          name: "",
          email: "",
          mobileNumber: {
            country: "",
            dialCode: "",
            iso2: "",
            number: ""
          },
          phoneNumber: "",
          designation: "",
          note: "",
          _id: "",
        }
      },
      filters: {
        search: ""
      },
      _contactPerson: "",
      deleteIndex: -1
    });

    const searchRecord = useDebounce(search, 1000);

    useEffect(() => {
      if (searchRecord.length) {
        setSearchParams(prev => ({
          ...prev,
          page: 1,
          type: "contact-person",
          searchh: searchRecord
        }));
      } else {
        searchParams.delete("searchh");
        setSearchParams(searchParams);
      }
    }, [searchRecord]);

    useEffect(() => {
      const search = searchParams.get("searchh") ? String(searchParams.get("searchh")) : "";
  
      setState(prevState => ({
        ...prevState,
        filters: {
          search,
        },
      }));
    }, [searchParams]);
  
    const { control, handleSubmit, reset, formState: { errors } } = useForm<IContactPersonDetail>({
      resolver: joiResolver(vendorContactPersonValidation),
      defaultValues: {
        name: "",
        email: "",
        designation: "",
        note: ""
      }
    });

    const contactPersons = useQuery({
      queryKey: ["allContactPersons", state.filters.search],
      queryFn: () =>
        getContactPersons({
          _vendor: vendor?._id,
          search: state.filters.search,
        }),
      enabled: !!vendor?._id
    });

    let rows: IContactPersonRow[] = [];  
      const handleDelete = (_id?: string) => setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _contactPerson: _id ? _id : ""
    }));
  
    const onDelete = async () => {
      try {
        const id = {
          _id: state._contactPerson
        };
        const deletedCandidate = await deleteContactPerson(id);
        snackbar(deletedCandidate.message, "info");
        handleDelete();
        contactPersons.refetch();
      } catch (error) {
        const err = error as IErrorResponse;
        snackbar(err.data.message, "warning");
        handleDelete();
        console.log({ "Error in delete contact person": error });
      }
    };
  
    const handleEdit = (contactPerson?: IContactPersonDetail) => setState(prevState => {
      if(contactPerson?._id){
        setContactPersonId(contactPerson._id);
      }
      const newState = {
        ...prevState,
        manageContactPerson: {
          ...prevState.manageContactPerson,
          isOpen: !prevState.manageContactPerson.isOpen,
        },
        _contactPerson: contactPerson ? contactPerson._id : "",
      } as IContactPersonState;

      return newState;
    });

    const [stateNo, setStateNo] = useState({
      phoneNumber: {
          country: "INDIA",
          dialCode: "+91",
          iso2: "IN"
      },
  });
  
    const onSubmit = async (data: IContactPersonDetail) => {
      try {
        const payload = {
          ...data,
          mobileNumber: {
            ...stateNo.phoneNumber,
            number: data.phoneNumber,
        },
        };
        delete payload.phoneNumber;
        const contactDetail = await addContactPerson({
          ...payload,
          _vendor: vendor?._id,
        });
        snackbar(contactDetail?.message, "info");
        setActiveAction(false);
        reset();
        contactPersons.refetch();
      } catch (error) {
        const err = error as IErrorResponse;
        snackbar(err?.data?.message, "warning");
        console.log("error in contact detail", error);
      }
    };
  
  const onNumberChange = (e: SelectChangeEvent<string>) => {
      const { name, value } = e.target;
      const data = value.split(":");
  
      setStateNo(prev => ({
          ...prev,
          [name]: {
              country: data[0],
              iso2: data[1],
              dialCode: data[2]
          }
      }));
  };
  
    const fields: IContactPerson[] = [
      {
        type: "input",
        name: "name",
        label: "Name",
        placeholder: "Type name here",
        required: true
      },
      {
        type: "mobile-number",
        name: "phoneNumber",
        label: "Mobile Number",
        placeholder: "Type mobile number here",
        required: true
      },
      {
        type: "input",
        name: "email",
        label: "Email",
        placeholder: "Type email address here"
      },
      {
        type: "input",
        name: "designation",
        label: "Designation",
        placeholder: "Type designation here",
        required: true
      },
      {
        type: "input",
        name: "note",
        label: "Note",
        placeholder: "Type note here"
      },
    ];
  
    const columns: IColumn[] = [
      {
        id: "id",
        label: "S No."
      },
      {
        id: "name",
        label: "Name",
      },
      {
        id: "phoneNo",
        label: "Mobile Number"
      },
      {
        id: "email",
        label: "Email"
      },
      {
        id: "designation",
        label: "Designation"
      },
      {
        id: "note",
        label: "Note"
      },
      {
        id: "action",
        label: "Actions"
      },
    ];
  
    const createRow = (index: number, contactDetail: IContactPersonDetail,) => {
      const action = <GetActions
        icons={[
          { name: "Edit", method: () => handleEdit(contactDetail) },
          { name: "Delete", method: () => handleDelete(contactDetail._id) },
        ]}
      />;
  
      return {
        id: index + 1,
        name: capitalize(contactDetail?.name),
        email: contactDetail?.email ? contactDetail.email : "-",
        designation: contactDetail.designation,
        phoneNo: contactDetail?.mobileNumber?.number ? contactDetail.mobileNumber.dialCode + " " + contactDetail.mobileNumber.number : "-",
        note: (
          <CustomTypography
            limit={60}
            label={capitalize(contactDetail.note)}
          />
        ),
        action
      };
    };

    if (contactPersons?.data?.data?.length) {
      rows = contactPersons.data.data.map((person, i) => createRow(i, person));
    }

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value); 


    return (
      <Box paddingTop="10px">
        <Box height="56vh" overflow="auto" paddingTop="10px">

          <Box className="search-container-cp">
            <Box className="search-box-contact-person">
                <SearchIcon className="search-icon" />
                <input
                    className="search-input"
                    name="search"
                    placeholder="Search by name, email, designation or phone number"
                    onChange={onSearch}
                />
            </Box>
          </Box>
        
  
          {/* Add Data  */}
          {activeAction && 
            <Box marginBottom="20px">
              <form onSubmit={handleSubmit(onSubmit)} onKeyDown={e => e.key === "Enter" && e.preventDefault()}>
                <Grid container spacing={4}>
                  {
                    fields.map(field => {
                      if (field.type === "input") {
                        return (<Grid key={field.label} item xs={4}>
                          <Controller
                            control={control}
                            name={field.name}
                            render={(prop) => <TextField
                              label={<CustomLabel label={field.label} required={field?.required} />}
                              className="disable-text"
                              variant={"outlined"}
                              size={"small"}
                              placeholder={field.placeholder}
                              error={!!errors[field.name]}
                              helperText={errors[field.name]?.message}
                              {...prop.field}
                            />}
                          />
                        </Grid>
                        );
                      }else if (field.type === "mobile-number") {
                        const numberState = "phoneNumber";
                        return <Grid key={field.label} item xs={4} >
                            <Controller
                                control={control}
                                name={field.name}
                                render={(prop) => <MobileNumber
                                    key={field.label}
                                    className="disable-text"
                                    NumberFieldLabel={<CustomLabel label={field.label} required={field?.required} />}
                                    dialCodeValue={`${stateNo[numberState].country}:${stateNo[numberState].iso2}:${stateNo[numberState].dialCode}`}
                                    dialCodeName={field.name}
                                    onChange={onNumberChange}
                                    error={!!errors[field.name]}
                                    helperText={errors[field.name]?.message}
                                    other={prop.field}
                                />}
                            />
                        </Grid>;
                    }
                    })
                  }
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      type="submit"
                    >
                      Add Contact Person
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          }
  
          {/* Show Data  */}
          <CustomTable
            columns={columns}
            rows={rows}
            height={activeAction ? "calc(100% - 145px)" : "calc(100% - 13px)"}
            width="calc(100% - 2px)"
          />
  
          {/* Delete Data  */}
          <WarningDialog
            isOpen={state.deleteWarning}
            onClose={() => handleDelete()}
            onConfirm={onDelete}
            title="Delete Contact Person"
            description="Are you sure you want to delete this contact person?"
          />
  
          {/* Manage Data  */}
          <ManageVendorContactPerson
            contactPerson={contactPersonId}
            isOpen={state.manageContactPerson.isOpen}
            onClose={handleEdit}
            refetch={contactPersons.refetch}
          />
        </Box>
      </Box>
    );
};

export default ContactDetail;