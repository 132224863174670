
import {  projectDataList, project } from "../endpoints";
import HttpService from "../http";
import { IPlannerResponse, IPlannersResponses } from "../../interfaces/planner";
const plannerService = () => {

    const { httpRequest } = HttpService();
    const getPlannerList = async (search:object):Promise<IPlannersResponses> => 
    new Promise((resolve,reject) => {
        httpRequest<IPlannersResponses>(
            "GET",
            `${projectDataList}`,
            {},
            search
            )
            .then(resolve)
            .catch(reject);
    });


    const addQuarter = async (payload : object) :Promise<IPlannerResponse>=> new Promise((resolve,reject) => {
        httpRequest<IPlannerResponse>(
        "POST",
        `${project}`,
        payload )
        .then(resolve)
        .catch(reject);
    });

    

    const getQuarter = async (query:object) : Promise<IPlannerResponse> =>  new Promise((resolve,reject) => {
        httpRequest<IPlannerResponse>(
            "GET",
            `${project}`,
            {},
            query
        )
        .then(resolve)
        .catch(reject);
    });

    const getQuarterPartial = async (query:object) : Promise<IPlannerResponse> =>  new Promise((resolve,reject) => {
      httpRequest<IPlannerResponse>(
          "GET",
          `${project}/partial`,
          {},
          query
      )
      .then(resolve)
      .catch(reject);
  });

    const deleteQuarter = async(payload:object):Promise<IPlannerResponse> =>
    new Promise((resolve,reject) => {
      httpRequest<IPlannerResponse>("DELETE",`${project}`,payload)
      .then(resolve)
      .catch(reject);
    });
      
    const updateQuarter = async(payload:object):Promise<IPlannerResponse> =>
      new Promise((resolve,reject) => {
        httpRequest<IPlannerResponse>("PUT",`${project}`,payload)
        .then(resolve)
        .catch(reject);
      });

      const freeze = async(payload:object):Promise<IPlannerResponse> =>
        new Promise((resolve,reject) => {
          httpRequest<IPlannerResponse>("GET",`${project}/freeze`,{},payload)
          .then(resolve)
          .catch(reject);
        });



    return {getPlannerList,addQuarter,getQuarterPartial,getQuarter,deleteQuarter,updateQuarter, freeze};

};

export default plannerService;