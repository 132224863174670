import HttpService from "../../http";
import { IProfileResponse } from "../../../interfaces";
import { changepassword } from "../../endpoints";

const settingService = () => {
    const { httpRequest } = HttpService();
    const changePassword = async (payload: object): Promise<IProfileResponse> => new Promise((resolve, reject) => {
        httpRequest<IProfileResponse>(
            "PUT",
            `${changepassword}`,
            payload,

        )
            .then(resolve)
            .catch(reject);
    });

    return { changePassword };

};

export { settingService };