import { FC, useEffect, useState } from "react";
import { capitalize, checkTimePeriod } from "../../../utilities/helper";
import { MenuItem } from "../../../components/shared/filter";
import { useNavigate, useSearchParams } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton, ListItemText, Menu, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../../components/shared/filter/style.scss";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { IResourceRequest } from "../../../interfaces/resource-request";
import { IUser } from "../../../interfaces";
import { useSelector } from "react-redux";

interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    OnCloseFilter: () => void;
}

const TestFilter: FC<props> = ({ anchorEl, isOpen, OnCloseFilter }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(state => state.user.list);
    const resourceTypePartial = [{name:"Human Resource" , value:"human" },{name: "Hardware" , value:"hardware"},{name:"Software" , value:"software"}];
    const statusPartial = [{name:"Hr Approved" , value:"HR_APPROVED"}, {name:"Hr Rejected" , value:"HR_REJECTED"}, {name:"Completed" , value:"COMPLETED"}, {name:"Submitted" , value:"SUBMITTED"}, {name:"Admin Approved" , value:"ADMIN_APPROVED"}, {name:"Admin Rejected" , value:"ADMIN_REJECTED"} ];
    const [state, setState] = useState<IResourceRequest>({
        selectedMenu: 0,
        requestType: [],
        status: [],
        requestedBy: [],
        submissionDate: [],
        completionDate: []
    });

    useEffect(() => {
        const requestType: { key: string, value: string }[] = searchParams.get("requestType") ? JSON.parse(String(searchParams.get("requestType"))) : [];
        const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
        const requestedBy: { key: string, value: string }[] = searchParams.get("requestedBy") ? JSON.parse(String(searchParams.get("requestedBy"))) : [];
        const submissionDate: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("submissionDate") ? JSON.parse(String(searchParams.get("submissionDate"))) : [];
        const completionDate: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("completionDate") ? JSON.parse(String(searchParams.get("completionDate"))) : [];
        setState(prevState => ({
            ...prevState,
            requestType,
            status,
            requestedBy,
            submissionDate,
            completionDate
        }));
    }, [searchParams]);

    const handleLeftListItem = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setState(prevState => ({
            ...prevState,
            selectedMenu: index
        }));
    };

    const handleDateListCompletion = (name: "completionDate", key: string, value: string) => {
        const completionDate = checkTimePeriod(key);
    
        const payload: Array<{
          key: string;
          value: string;
          startDate: string,
          endDate: string,
        }> = [{
          key,
          value,
          startDate: completionDate.startDate,
          endDate: completionDate.endDate,
        }];
    
        setState(prevState => ({
          ...prevState,
          [name]: payload
        }));
      };
    
      const handleDateCompletion = (e: dayjs.Dayjs | null, period: "start" | "end") => {
        const newDoj = e ? dayjs(e).toISOString() : "";
        let completionDate: Array<{
          key: string;
          value: string;
          startDate: string,
          endDate: string,
        }> = [];
    
        if (state.completionDate.length && period === "start") {
            completionDate = state.completionDate.map(e => ({ ...e, startDate: newDoj, value: "custom", key: "Custom" }));
        } else if (state.completionDate.length && period === "end") {
            completionDate = state.completionDate.map(e => ({ ...e, endDate: newDoj, value: "custom", key: "Custom" }));
        } else if (!state.completionDate.length && period === "start") {
          const currentDate = new Date();
          currentDate.setHours(23,59,59);
          completionDate = [{
            key: "custom",
            value: "custom", 
            startDate:  dayjs(e).startOf("day").toISOString(),
            endDate: currentDate.toISOString()
          }];
        } else {
          const currentDate = new Date();
          currentDate.setHours(0,0,0,0);
          completionDate = [{
            key: "custom",
            value: "custom",
            startDate: currentDate.toISOString(),
            endDate: dayjs(e).endOf("day").toISOString(),
          }];
        }
    
        setState(prevState => ({
          ...prevState,
          completionDate
        }));
      };

      const handleDateListItem = (name: "submissionDate", key: string, value: string) => {
        const submissionDate = checkTimePeriod(key);
    
        const payload: Array<{
          key: string;
          value: string;
          startDate: string,
          endDate: string,
        }> = [{
          key,
          value,
          startDate: submissionDate.startDate,
          endDate: submissionDate.endDate,
        }];
    
        setState(prevState => ({
          ...prevState,
          [name]: payload
        }));
      };
    
      const handleDate = (e: dayjs.Dayjs | null, period: "start" | "end") => {
        const newDoj = e ? dayjs(e).toISOString() : "";
        let submissionDate: Array<{
          key: string;
          value: string;
          startDate: string,
          endDate: string,
        }> = [];
    
        if (state.submissionDate.length && period === "start") {
          submissionDate = state.submissionDate.map(e => ({ ...e, startDate: newDoj, value: "custom", key: "Custom" }));
        } else if (state.submissionDate.length && period === "end") {
          submissionDate = state.submissionDate.map(e => ({ ...e, endDate: newDoj, value: "custom", key: "Custom" }));
        } else if (!state.submissionDate.length && period === "start") {
          const currentDate = new Date();
          currentDate.setHours(23,59,59);
          submissionDate = [{
            key: "custom",
            value: "custom", 
            startDate:  dayjs(e).startOf("day").toISOString(),
            endDate: currentDate.toISOString()
          }];
        } else {
          const currentDate = new Date();
          currentDate.setHours(0,0,0,0);
          submissionDate = [{
            key: "custom",
            value: "custom",
            startDate: currentDate.toISOString(),
            endDate: dayjs(e).endOf("day").toISOString(),
          }];
        }
    
        setState(prevState => ({
          ...prevState,
          submissionDate
        }));
      };

    const handleRightListItem = (name: "requestType" | "status" | "requestedBy", key: string, value: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        const isExist = !!state[name].find(ele => ele.key === key);
        if (isExist) {
            payload = state[name].filter(ele => ele.key !== key);
        } else {
            payload = state[name];
            payload.push({
                key,
                value
            });
        }

        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const deleteChip = (name: "requestType" | "status" | "requestedBy" | "submissionDate" | "completionDate", key: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];
        payload = state[name].filter(ele => ele.key !== key);
        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const resetFilter = () => {
        setState({
            selectedMenu: 0,
            requestType: [],
            status: [],
            requestedBy: [],
            submissionDate: [],
            completionDate: []
        });
    };

    const onApply = () => {
        searchParams.set("requestType", JSON.stringify(state.requestType));
        searchParams.set("status", JSON.stringify(state.status));
        searchParams.set("requestedBy", JSON.stringify(state.requestedBy));
        searchParams.set("submissionDate", JSON.stringify(state.submissionDate));
        searchParams.set("completionDate", JSON.stringify(state.completionDate));

        searchParams.set("page", "1");
        navigate({
            pathname: "/resource-requested/requests",
            search: searchParams.toString()
        });
        OnCloseFilter();
    };

    const onClose = () => {
        const requestType: { key: string, value: string }[] = searchParams.get("requestType") ? JSON.parse(String(searchParams.get("requestType"))) : [];
        const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
        const requestedBy: { key: string, value: string }[] = searchParams.get("requestedBy") ? JSON.parse(String(searchParams.get("requestedBy"))) : [];
        const submissionDate: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("submissionDate") ? JSON.parse(String(searchParams.get("submissionDate"))) : [];
        const completionDate: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("completionDate") ? JSON.parse(String(searchParams.get("completionDate"))) : [];

        setState(prevState => ({
            ...prevState,
            requestType,
            status,
            requestedBy,
            submissionDate,
            completionDate
        }));
        OnCloseFilter();
    };

    return <>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={onClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            <Box id="filters-container">
                <Box className="center mb-3" justifyContent="space-between" alignItems="start">
                    <div className="active-filter mb-1">
                        {
                            (state.requestType.length || state.status.length || state.requestedBy.length || state.submissionDate.length || state.completionDate.length) ?
                                <>
                                    {state.requestType.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("requestType", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.status.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("status", ele.key)} label={ele.value} variant="outlined" />)}                                   
                                    {state.requestedBy.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("requestedBy", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.submissionDate.map(ele => <Chip key={ele.key} className="m-1" icon={<CalendarMonthIcon />} color="primary" onDelete={() => deleteChip("submissionDate", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.completionDate.map(ele => <Chip key={ele.key} className="m-1" icon={<CalendarMonthIcon />} color="primary" onDelete={() => deleteChip("completionDate", ele.key)} label={ele.value} variant="outlined" />)}
                                </>
                                :
                                <Box className="mt-2" display="flex" alignItems="center">
                                    <FilterAltOffIcon />
                                    <Typography className="ml-2">No filters are applied</Typography>
                                </Box>
                        }
                    </div>
                    <IconButton
                        onClick={OnCloseFilter}
                        style={{ marginRight: "-10px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Grid className="filter-box" container>
                    <Grid id="left" item xs={5}>
                        <List component="nav">

                            <MenuItem
                                index={0}
                                label="Resource Type"
                                selectedMenu={state.selectedMenu === 0}
                                onChange={handleLeftListItem}
                                count={state.requestType}
                            />

                            <MenuItem
                                index={1}
                                label="Status"
                                selectedMenu={state.selectedMenu === 1}
                                onChange={handleLeftListItem}
                                count={state.status}
                            />

                            <MenuItem
                                index={2}
                                label="Requested By"
                                selectedMenu={state.selectedMenu === 2}
                                onChange={handleLeftListItem}
                                count={state.requestedBy}
                            />

                            <MenuItem
                                index={3}
                                label="Submission Date"
                                selectedMenu={state.selectedMenu === 3}
                                onChange={handleLeftListItem}
                                count={state.submissionDate}
                             />

                            <MenuItem
                                index={4}
                                label="Completion Date"
                                selectedMenu={state.selectedMenu === 4}
                                onChange={handleLeftListItem}
                                count={state.completionDate}
                             />

                        </List>
                    </Grid>
                    <Divider orientation="vertical" />

                    <Grid id="right" item xs={6}>
                        <List component="nav">
                            {
                                state.selectedMenu === 0 &&
                                resourceTypePartial.map(i => <ListItemButton
                                    key={i.name}
                                    selected={!!state.requestType.find(ele => ele.key === i.name)}
                                    onClick={() => handleRightListItem("requestType",i.value, i.name)}
                                >
                                    <ListItemText primary={capitalize(i.name)} /> 
                                    <Checkbox edge="end" checked={!!state.requestType.find(ele => ele.key === i.value)} />
                                </ListItemButton>)
                            }

                            {
                                state.selectedMenu === 1 &&
                                statusPartial.map(i => <ListItemButton
                                    key={i.name}
                                    selected={!!state.status.find(ele => ele.key === i.name)}
                                    onClick={() => handleRightListItem("status", i.value, i.name)}
                                >
                                    <ListItemText primary={capitalize(i.name)} /> 
                                    <Checkbox edge="end" checked={!!state.status.find(ele => ele.key === i.value)} />
                                </ListItemButton>)
                            }
                            
                            {
                                state.selectedMenu === 2 &&
                                users.map(i => <ListItemButton
                                    key={i._id}
                                    selected={!!state.requestedBy.find(ele => ele.key === i._id)}
                                    onClick={() => handleRightListItem("requestedBy", i._id, i.name)} 
                                >
                                    <ListItemText primary={capitalize(i.name)} /> 
                                    <Checkbox edge="end" checked={!!state.requestedBy.find(ele => ele.key === i._id)} />
                                </ListItemButton>)
                            }

                            {
                               state.selectedMenu === 3 &&
                                <>
                                {[
                                    { key: "yesterday", value: "Yesterday" },
                                    { key: "today", value: "Today" },
                                    { key: "thisWeek", value: "Weekly" },
                                    { key: "thisMonth", value: "Monthly" },
                                    { key: "thisQuarter", value: "Quarterly" },
                                ]?.map((submissionDate) =>
                                    <ListItemButton
                                    key={submissionDate.key}
                                    selected={!!state.submissionDate.find(ele => ele.key === submissionDate.key)}
                                    onClick={() => handleDateListItem("submissionDate", submissionDate.key, capitalize(submissionDate.value))}
                                    >
                                    <ListItemText primary={submissionDate.value} />
                                    <Checkbox edge="end" checked={!!state.submissionDate.find(ele => ele.key === submissionDate.key)} />
                                    </ListItemButton>
                                )}
                                <Box marginTop={2}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        value={state.submissionDate[0]?.startDate ? dayjs(state.submissionDate[0]?.startDate) : null}
                                        onChange={e => handleDate(e, "start")}
                                        label="Start Date"
                                        format="LL"
                                    />
                                    </LocalizationProvider>
                                    <div className="mt-3" />
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        value={state.submissionDate[0]?.endDate ? dayjs(state.submissionDate[0]?.endDate) : null}
                                        onChange={e => handleDate(e, "end")}
                                        label="End Date"
                                        format="LL"
                                    />
                                    </LocalizationProvider>
                                </Box>
                                </>
                            }
                            {
                               state.selectedMenu === 4 &&
                                <>
                                {[
                                    { key: "yesterday", value: "Yesterday" },
                                    { key: "today", value: "Today" },
                                    { key: "thisWeek", value: "Weekly" },
                                    { key: "thisMonth", value: "Monthly" },
                                    { key: "thisQuarter", value: "Quarterly" },
                                ]?.map((completionDate) =>
                                    <ListItemButton
                                    key={completionDate.key}
                                    selected={!!state.completionDate.find(ele => ele.key === completionDate.key)}
                                    onClick={() => handleDateListCompletion("completionDate", completionDate.key, capitalize(completionDate.value))}
                                    >
                                    <ListItemText primary={completionDate.value} />
                                    <Checkbox edge="end" checked={!!state.completionDate.find(ele => ele.key === completionDate.key)} />
                                    </ListItemButton>
                                )}
                                <Box marginTop={2}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        value={state.completionDate[0]?.startDate ? dayjs(state.completionDate[0]?.startDate) : null}
                                        onChange={e => handleDateCompletion(e, "start")}
                                        label="Start Date"
                                        format="LL"
                                    />
                                    </LocalizationProvider>
                                    <div className="mt-3" />
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        value={state.completionDate[0]?.endDate ? dayjs(state.completionDate[0]?.endDate) : null}
                                        onChange={e => handleDateCompletion(e, "end")}
                                        label="End Date"
                                        format="LL"
                                    />
                                    </LocalizationProvider>
                                </Box>
                                </>
                            }
                                        </List>

                                    </Grid>
                                </Grid>
                <Box className="actions-btn" marginTop="8px" textAlign="end">
                    <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
                    <Button className="ml-2" onClick={onApply}>Apply</Button>
                </Box>
            </Box>
        </Menu>
    </>;
};

export default TestFilter;