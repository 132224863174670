import { Outlet, useNavigate, useSearchParams } from "react-router-dom";

import { useForm, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { Grid, MenuItem, TextField } from "@mui/material";

import { IAddTestCase, IErrorResponse, ITestCaseField } from "../../../../../../interfaces";
import useSnackbar from "../../../../../../hooks/useSnackbar";
import { useOutletContext } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import CustomDialog from "../../../../../../components/mui/dialog";

import { TechnicalQuestionService } from "../../../../../../services";

import { addTechnicalQuestionTestCaseValidation } from "../../../../../../validations";
import Select from "../../../../../../components/mui/select";

interface IOutletProps {
	reFetch: () => void;
}

const ManageQuestionTestCases = () => {
	const navigate = useNavigate();
	const { snackbar } = useSnackbar();
	const { id, testCaseId } = useParams();

	const { addTechnicalQuestionTestCase, updateTechnicalQuestionTestCase, getTechnicalQuestionTestCase } = TechnicalQuestionService();

	const outlet = useOutletContext<IOutletProps>();

	const [searchParam] = useSearchParams();

	const { handleSubmit, setValue, control, formState: { errors } } = useForm<IAddTestCase>({
		resolver: joiResolver(addTechnicalQuestionTestCaseValidation),
		defaultValues: {
			outputType: "",
			inputType: "",
			output: "",
			input: "",
			type: "",
		}
	});

	const testCaseData = useQuery(
		{
			queryKey: ["testCase", testCaseId],
			queryFn: () => getTechnicalQuestionTestCase({ _id: id, _testCase: testCaseId }),
			enabled: testCaseId !== "new"
		}
	);

	useEffect(() => {
		if (testCaseData.data?.data && testCaseId !== "new") {
			setValue("type", testCaseData.data.data.type ?? "");
			setValue("inputType", testCaseData.data.data.inputType ?? "");
			setValue("outputType", testCaseData.data.data.outputType ?? "");
			setValue("input", testCaseData.data.data.input ?? "");
			setValue("output", testCaseData.data.data.output ?? "");
		}
	}, [testCaseData.data?.data]);


	const fields: ITestCaseField[] = [
		{
			name: "type",
			label: "Type",
			type: "select",
			placeholder: "Select Type",
			width: 12,
			children: [
				<MenuItem key="PRIVATE" value="PRIVATE" >Private</MenuItem>,
				<MenuItem key="PUBLIC" value="PUBLIC" >Public</MenuItem>
			]
		},
		{
			name: "inputType",
			label: "Input Type",
			type: "select",
			placeholder: "Select Type",
			width: 6,
			children: [
				<MenuItem key="STRING" value="STRING" >String</MenuItem>,
				<MenuItem key="ARRAY" value="ARRAY" >Array</MenuItem>
			]
		},
		{
			name: "input",
			label: "Input",
			type: "input",
			placeholder: "Enter input",
			width: 6
		},
		{
			name: "outputType",
			label: "Output Type",
			type: "select",
			placeholder: "Select Type",
			width: 6,
			children: [
				<MenuItem key="STRING" value="STRING" >String</MenuItem>,
				<MenuItem key="ARRAY" value="ARRAY" >Array</MenuItem>
			]
		},
		{
			name: "output",
			label: "Output",
			type: "input",
			placeholder: "Enter Output",
			width: 6
		}
	];

	const onSubmit = async (data: IAddTestCase) => {
		try {
			let response;
			if (testCaseId === "new") {
				response = await addTechnicalQuestionTestCase({ _id: id, ...data });
			} else {
				response = await updateTechnicalQuestionTestCase({ _id: id, _testCase: testCaseId, ...data });
			}
			snackbar(response.message, "info");

			outlet.reFetch && outlet.reFetch();
			navigate({ pathname: `/configurations/technical-questions/${id}`, search: searchParam.toString() });
		} catch (error) {
			const err = error as IErrorResponse;
			snackbar(err?.data?.message, "error");
			console.log("Error in Adding Goal", error);
		}
	};

	return (
		<CustomDialog
			title={testCaseId === "new" ? "Add Test Case" : "Edit Test Case"}
			isOpen={!!testCaseId}
			onClose={() => navigate({ pathname: `/configurations/technical-questions/${id}`, search: searchParam.toString() })}
			onSubmit={handleSubmit(onSubmit)}
		>
			<Grid container spacing={4}>
				{fields.map((field) => {

					if (field.type === "input") {
						return (<Grid key={field.label} item xs={12} md={6}>
							<Controller
								control={control}
								name={field.name}
								render={(prop) => <TextField
									{...prop.field}
									label={field.label}
									className="disable-text"
									placeholder={field.placeholder}
									error={!!errors[field.name]}
									helperText={errors[field.name]?.message}
									multiline={true}
								/>}
							/>
						</Grid>
						);
					}
					if (field.type === "select") {
						return (<Grid key={field.label} item xs={field.width} >
							<Select
								control={control}
								name={field.name}
								label={field.label}
								size="small"
								variant="outlined"
								error={!!errors[field.name]}
								helperText={errors[field.name]?.message}
							>
								{field.children}
							</Select>
						</Grid>
						);
					}
				})}
			</Grid>
			<Outlet />
		</CustomDialog>
	);
};

export default ManageQuestionTestCases;
