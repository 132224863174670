import { useSelector } from "react-redux";
import { FC, useEffect, useState } from "react";
import { capitalize, displayName } from "../../../../utilities/helper";
import { MenuItem } from "../../../../components/shared/filter";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IJobFilter, IEngagement, ITeam, IUser } from "../../../../interfaces";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../../../components/shared/filter/style.scss";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs/AdapterDayjs";

interface props {
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  OnClose: () => void;
}

const JobFilter: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(
    (state) => state.user.list
  );
  const teams = useSelector<{ team: { list: ITeam[] } }, ITeam[]>(
    (state) => state.team.list
  );
  const engagementTypes =
    useSelector<{ engagementType: { list: IEngagement[] } }, IEngagement[]>(
      (state) => state.engagementType.list
    ) || [];
  const [state, setState] = useState<IJobFilter>({
    selectedMenu: 0,
    department: [],
    type: [],
    status: [],
    postingDate: [],
    closingDate: [],
    assignTo: [],
  });

  useEffect(() => {
    const department: { key: string; value: string }[] = searchParams.get(
      "department"
    )
      ? JSON.parse(String(searchParams.get("department")))
      : [];
    const type: { key: string; value: string }[] = searchParams.get("type")
      ? JSON.parse(String(searchParams.get("type")))
      : [];
    const status: { key: string; value: string }[] = searchParams.get("status")
      ? JSON.parse(String(searchParams.get("status")))
      : [];
    const postingDate: {
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }[] = searchParams.get("postingDate")
      ? JSON.parse(String(searchParams.get("postingDate")))
      : [];
    const closingDate: {
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }[] = searchParams.get("closingDate")
      ? JSON.parse(String(searchParams.get("closingDate")))
      : [];
    const assignTo: { key: string; value: string }[] = searchParams.get(
      "assign-to"
    )
      ? JSON.parse(String(searchParams.get("assign-to")))
      : [];
    setState((prevState) => ({
      ...prevState,
      department,
      type,
      status,
      postingDate,
      closingDate,
      assignTo,
    }));
  }, [searchParams]);

  const handleLeftListItem = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setState((prevState) => ({
      ...prevState,
      selectedMenu: index,
    }));
  };

  const handleRightListItem = (
    name: "department" | "type" | "status" | "assignTo",
    key: string,
    value: string
  ) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];

    const isExist = state[name].find((ele) => ele.key === key) ? true : false;
    if (isExist) {
      payload = state[name].filter((ele) => ele.key !== key);
    } else {
      payload = state[name];
      payload.push({
        key,
        value,
      });
    }

    setState((prevState) => ({
      ...prevState,
      [name]: payload,
    }));
  };

  const deleteChip = (
    name:
      | "department"
      | "type"
      | "status"
      | "assignTo"
      | "postingDate"
      | "closingDate",
    key: string
  ) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];

    payload = state[name].filter((ele) => ele.key !== key);
    setState((prevState) => ({
      ...prevState,
      [name]: payload,
    }));
  };

  const handlePostingDate = (
    e: dayjs.Dayjs | null,
    period: "start" | "end"
  ) => {
    const newDoj = e ? dayjs(e).toISOString() : "";
    let postingDate: Array<{
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }> = [];

    if (state.postingDate.length && period === "start") {
      postingDate = state.postingDate.map((e) => ({
        ...e,
        startDate: newDoj,
        value: "custom",
        key: "Custom",
      }));
    } else if (state.postingDate.length && period === "end") {
      postingDate = state.postingDate.map((e) => ({
        ...e,
        endDate: newDoj,
        value: "custom",
        key: "Custom",
      }));
    } else if (!state.postingDate.length && period === "start") {
      const currentDate = new Date();
      currentDate.setHours(23, 59, 59);
      postingDate = [
        {
          key: "custom",
          value: "custom",
          startDate: dayjs(e).startOf("day").toISOString(),
          endDate: currentDate.toISOString(),
        },
      ];
    } else {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      postingDate = [
        {
          key: "custom",
          value: "custom",
          startDate: currentDate.toISOString(),
          endDate: dayjs(e).endOf("day").toISOString(),
        },
      ];
    }

    setState((prevState) => ({
      ...prevState,
      postingDate,
    }));
  };

  const handleClosingDate = (
    e: dayjs.Dayjs | null,
    period: "start" | "end"
  ) => {
    const newDoj = e ? dayjs(e).toISOString() : "";
    let closingDate: Array<{
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }> = [];

    if (state.postingDate.length && period === "start") {
      closingDate = state.postingDate.map((e) => ({
        ...e,
        startDate: newDoj,
        value: "custom",
        key: "Custom",
      }));
    } else if (state.postingDate.length && period === "end") {
      closingDate = state.postingDate.map((e) => ({
        ...e,
        endDate: newDoj,
        value: "custom",
        key: "Custom",
      }));
    } else if (!state.postingDate.length && period === "start") {
      const currentDate = new Date();
      currentDate.setHours(23, 59, 59);
      closingDate = [
        {
          key: "custom",
          value: "custom",
          startDate: dayjs(e).startOf("day").toISOString(),
          endDate: currentDate.toISOString(),
        },
      ];
    } else {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      closingDate = [
        {
          key: "custom",
          value: "custom",
          startDate: currentDate.toISOString(),
          endDate: dayjs(e).endOf("day").toISOString(),
        },
      ];
    }

    setState((prevState) => ({
      ...prevState,
      closingDate,
    }));
  };

  const resetFilter = () => {
    setState({
      selectedMenu: 0,
      department: [],
      type: [],
      status: [],
      postingDate: [],
      closingDate: [],
      assignTo: [],
    });
  };

  const onApply = () => {
    searchParams.set("department", JSON.stringify(state.department));
    searchParams.set("type", JSON.stringify(state.type));
    searchParams.set("status", JSON.stringify(state.status));
    searchParams.set("postingDate", JSON.stringify(state.postingDate));
    searchParams.set("closingDate", JSON.stringify(state.closingDate));
    searchParams.set("assign-to", JSON.stringify(state.assignTo));

    searchParams.set("page", "1");
    navigate({
      pathname: "/careers/jobs",
      search: searchParams.toString(),
    });
    OnClose();
  };

  const onClose = () => {
    const department: { key: string; value: string }[] = searchParams.get(
      "department"
    )
      ? JSON.parse(String(searchParams.get("department")))
      : [];
    const type: { key: string; value: string }[] = searchParams.get("type")
      ? JSON.parse(String(searchParams.get("type")))
      : [];
    const assignTo: { key: string; value: string }[] = searchParams.get(
      "assign-to"
    )
      ? JSON.parse(String(searchParams.get("assign-to")))
      : [];
    const status: { key: string; value: string }[] = searchParams.get("status")
      ? JSON.parse(String(searchParams.get("status")))
      : [];
    const postingDate: {
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }[] = searchParams.get("postingDate")
      ? JSON.parse(String(searchParams.get("postingDate")))
      : [];
    const closingDate: {
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }[] = searchParams.get("closingDate")
      ? JSON.parse(String(searchParams.get("closingDate")))
      : [];
    setState((prevState) => ({
      ...prevState,
      department,
      type,
      status,
      postingDate,
      closingDate,
      assignTo,
    }));
    OnClose();
  };

  const defaultStatus = [
    {
      key: "OPEN",
      name: "Active",
    },
    {
      key: "HOLD",
      name: "Inactive",
    },
    {
      key: "CANCELLED",
      name: "Cancel",
    },
  ];

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={OnClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box id="filters-container">
          <Box
            className="center mb-3"
            justifyContent="space-between"
            alignItems="start"
          >
            <div className="active-filter mb-1">
              {state.closingDate.length ||
              state.postingDate.length ||
              state.assignTo.length ||
              state.department.length ||
              state.type.length ||
              state.status.length ? (
                <>
                  {state.department.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("department", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                  {state.type.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("type", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                  {state.status.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("status", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                  {state.postingDate.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      icon={<CalendarMonthIcon />}
                      color="primary"
                      onDelete={() => deleteChip("postingDate", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                  {state.closingDate.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      icon={<CalendarMonthIcon />}
                      color="primary"
                      onDelete={() => deleteChip("closingDate", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}

                  {state.assignTo.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("assignTo", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                </>
              ) : (
                <Box className="mt-2" display="flex" alignItems="center">
                  <FilterAltOffIcon />
                  <Typography className="ml-2">
                    No filters are applied
                  </Typography>
                </Box>
              )}
            </div>
            <IconButton onClick={onClose} style={{ marginRight: "-10px" }}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Grid className="filter-box" container>
            <Grid id="left" item xs={5}>
              <List component="nav">
                <MenuItem
                  index={0}
                  label="Team"
                  selectedMenu={state.selectedMenu === 0}
                  onChange={handleLeftListItem}
                  count={state.department}
                />

                <MenuItem
                  index={1}
                  label="Engagement Type"
                  selectedMenu={state.selectedMenu === 1}
                  onChange={handleLeftListItem}
                  count={state.type}
                />

                <MenuItem
                  index={2}
                  label="Status"
                  selectedMenu={state.selectedMenu === 2}
                  onChange={handleLeftListItem}
                  count={state.status}
                />

                <MenuItem
                  index={3}
                  label="Assign To"
                  selectedMenu={state.selectedMenu === 3}
                  onChange={handleLeftListItem}
                  count={state.assignTo}
                />

                <MenuItem
                  index={4}
                  label="Posting Date"
                  selectedMenu={state.selectedMenu === 4}
                  onChange={handleLeftListItem}
                  count={state.postingDate}
                />

                <MenuItem
                  index={5}
                  label="Closing Date"
                  selectedMenu={state.selectedMenu === 5}
                  onChange={handleLeftListItem}
                  count={state.closingDate}
                />
              </List>
            </Grid>
            <Divider orientation="vertical" />

            <Grid id="right" item xs={6}>
              <List component="nav">
                {state.selectedMenu === 0 &&
                  teams.map((team) => (
                    <ListItemButton
                      key={team._id}
                      selected={
                        state.department.find((ele) => ele.key === team.name)
                          ? true
                          : false
                      }
                      onClick={() =>
                        handleRightListItem(
                          "department",
                          team.name,
                          capitalize(team.name)
                        )
                      }
                    >
                      <ListItemText primary={capitalize(team.name)} />
                      <Checkbox
                        edge="end"
                        checked={
                          state.department.find((ele) => ele.key === team.name)
                            ? true
                            : false
                        }
                      />
                    </ListItemButton>
                  ))}
                {state.selectedMenu === 1 &&
                  engagementTypes.map((engagementType) => (
                    <ListItemButton
                      key={engagementType._id}
                      selected={
                        state.type.find(
                          (ele) => ele.key === engagementType.name
                        )
                          ? true
                          : false
                      }
                      onClick={() =>
                        handleRightListItem(
                          "type",
                          engagementType.name,
                          capitalize(engagementType.name)
                        )
                      }
                    >
                      <ListItemText primary={capitalize(engagementType.name)} />
                      <Checkbox
                        edge="end"
                        checked={
                          state.type.find(
                            (ele) => ele.key === engagementType.name
                          )
                            ? true
                            : false
                        }
                      />
                    </ListItemButton>
                  ))}
                {state.selectedMenu === 2 &&
                  defaultStatus.map((status) => (
                    <ListItemButton
                      key={status.key}
                      selected={
                        state.status.find((ele) => ele.key === status.key)
                          ? true
                          : false
                      }
                      onClick={() =>
                        handleRightListItem("status", status.key, status.name)
                      }
                    >
                      <ListItemText primary={status.name} />
                      <Checkbox
                        edge="end"
                        checked={
                          state.status.find((ele) => ele.key === status.key)
                            ? true
                            : false
                        }
                      />
                    </ListItemButton>
                  ))}
                {state.selectedMenu === 3 &&
                  users.map((user) => (
                    <ListItemButton
                      key={user._id}
                      selected={
                        state.assignTo.find((ele) => ele.key === user._id)
                          ? true
                          : false
                      }
                      onClick={() =>
                        handleRightListItem(
                          "assignTo",
                          user._id,
                          `${capitalize(displayName(user))}`
                        )
                      }
                    >
                      <ListItemText
                        primary={`${capitalize(displayName(user))}`}
                      />
                      <Checkbox
                        edge="end"
                        checked={
                          state.assignTo.find((ele) => ele.key === user._id)
                            ? true
                            : false
                        }
                      />
                    </ListItemButton>
                  ))}
                {state.selectedMenu === 4 && (
                  <>
                    <Box marginTop={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          value={
                            state.postingDate[0]?.startDate
                              ? dayjs(state.postingDate[0]?.startDate)
                              : null
                          }
                          onChange={(e) => handlePostingDate(e, "start")}
                          label="Start Date"
                          format="LL"
                        />
                      </LocalizationProvider>
                      <div className="mt-3" />
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          value={
                            state.postingDate[0]?.endDate
                              ? dayjs(state.postingDate[0]?.endDate)
                              : null
                          }
                          onChange={(e) => handlePostingDate(e, "end")}
                          label="End Date"
                          format="LL"
                        />
                      </LocalizationProvider>
                    </Box>
                  </>
                )}
                {state.selectedMenu === 5 && (
                  <>
                    <Box marginTop={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          value={
                            state.closingDate[0]?.startDate
                              ? dayjs(state.closingDate[0]?.startDate)
                              : null
                          }
                          onChange={(e) => handleClosingDate(e, "start")}
                          label="Start Date"
                          format="LL"
                        />
                      </LocalizationProvider>
                      <div className="mt-3" />
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          value={
                            state.closingDate[0]?.endDate
                              ? dayjs(state.closingDate[0]?.endDate)
                              : null
                          }
                          onChange={(e) => handleClosingDate(e, "end")}
                          label="End Date"
                          format="LL"
                        />
                      </LocalizationProvider>
                    </Box>
                  </>
                )}
              </List>
            </Grid>
          </Grid>

          <Box className="actions-btn" marginTop="8px" textAlign="end">
            <Button
              variant="outlined"
              color="error"
              onClick={() => resetFilter()}
            >
              Clear All
            </Button>
            <Button className="ml-2" onClick={onApply}>
              Apply
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default JobFilter;
