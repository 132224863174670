import { Box, Button, Chip, DialogContent, Divider, Typography } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { TestSessionService } from "../../../../../services";
import { useQuery } from "@tanstack/react-query";
import { capitalize, checkTestStatusBackgroundColor, checkTestStatusColor, formatHtmlString } from "../../../../../utilities/helper";
import Editor from "@monaco-editor/react";
import { ITestResponse } from "../../../../../interfaces";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../shared/mui-tabs";
import "./style.scss";


interface props {
  testId: string,
  questionId: string,
  isOpen: boolean,
  onClose: () => void
}

const ViewCoderByteQuestion: FC<props> = ({ testId, questionId, isOpen, onClose }) => {
  const { getCoderByteQuestion, runCode } = TestSessionService();
  const editorRef = useRef<{ focus: () => void; }>();
  const testQuestions = useQuery({
    queryKey: ["coder-byte-test-question"],
    queryFn: () => getCoderByteQuestion({ _id: testId, _question: questionId }),
  });
  const [testCaseResponse, setTestCaseResponse] = useState<ITestResponse[]>();
  const getResponse = async () => {
    const payload = {
      languageId: testQuestions?.data?.data.languageSelected._id,
      questionId: questionId,
      _id: testId,
      code: testQuestions?.data?.data.code
    };
    try {
      const response = await runCode(payload);
      setTestCaseResponse(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const [editorValue, setEditorValue] = useState<string | undefined>("");

  useEffect(() => {
    if (testQuestions && testQuestions.data && testQuestions.data.data) {
      setEditorValue(testQuestions.data.data.code ?? "");
    }
  }, [testQuestions && testQuestions.data && testQuestions.data.data]);

  const onMount = (editor: { focus: () => void; }) => {
    editorRef.current = editor;
    editor.focus();
  };

  return (
    <div>
      <BootstrapDialog
        maxWidth={"lg"}
        open={isOpen}
        onClose={onClose}
      >
        <BootstrapDialogTitle
          onClose={onClose} >
          <Box>{
            testQuestions &&
            testQuestions.data &&
            testQuestions.data.data &&
            testQuestions.data.data._question &&
            testQuestions?.data?.data._question.title || "Question"}</Box>
        </BootstrapDialogTitle>
        <Divider className="ques-divider"/>
        <DialogContent className="dialogContent">
          <Box className="ques-details">
            <Box>
              <Typography color="black">Score </Typography>
              <Typography variant="h3" component="div" color="success.main">
                <strong>{testQuestions?.data?.data.marksScored ?? "--"}/{testQuestions?.data?.data._question.weightage ?? "--"}</strong>
              </Typography>
            </Box>
            <Chip variant={"outlined"} sx={{ width: "110px", borderRadius: "4px", backgroundColor: checkTestStatusBackgroundColor(testQuestions && testQuestions.data && testQuestions.data.data && testQuestions.data.data._question.difficulty || "") }} color={checkTestStatusColor(testQuestions && testQuestions.data && testQuestions.data.data && testQuestions.data.data._question.difficulty || "")} label={capitalize(testQuestions && testQuestions.data && testQuestions.data.data && testQuestions.data.data._question.difficulty || "")} />
          </Box>
          <Box className="ques-description">
            <Typography className="ques-description-heading">
              {formatHtmlString(testQuestions?.data?.data?._question?.description ?? "--")}
            </Typography>
          </Box>
          <Box className="ques-code">
            <Box className="ques-editor">
              <div className="ques-editor-div">
                <Editor
                  height="420px"
                  language={testQuestions?.data?.data?.languageSelected?.language || "text"}
                  value={editorValue}
                  theme="vs-dark"
                  options={{
                    wordWrap: "on",
                    minimap: { enabled: false },
                    lineNumbersMinChars: 3,
                    folding: false,
                    fontSize: 16,
                    scrollBeyondLastLine: false,
                    automaticLayout: true,
                    readOnly: true,
                    scrollbar: {
                      vertical: "hidden",
                      horizontal: "hidden",
                    },
                  }}
                  onMount={onMount}
                  onChange={(value) => {
                    setEditorValue(value);
                  }}
                />
              </div>
            </Box>
            <Box className="ques-output">
              {testCaseResponse && testCaseResponse.map((testCase, index) => (
                <Box
                  key={index}
                  marginBottom={2}
                  padding={2}
                  border="1px solid #ccc"
                  borderRadius={2}
                >
                  <Typography variant="body2">
                    <strong>Input:</strong> {testCase.testCase.input}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Expected Output:</strong> {testCase.testCase.expectedOutput}
                  </Typography>
                  {testCase.error ? (
                    <Typography variant="body2" color="error">
                      <strong>Error:</strong> {testCase.error}
                    </Typography>
                  ) : (
                    <>
                      <Typography variant="body2">
                        <strong>Output:</strong> {testCase.output}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Result:</strong> {testCase.evaluation.message}
                      </Typography>
                    </>
                  )}
                  <Typography variant="body2" style={{ wordBreak: "break-word" }}>
                    <strong>Status:</strong> {testCase.error ? "Failed" : (testCase.evaluation.success ? "Passed" : "Failed")}
                  </Typography>
                </Box>

              ))}
            </Box>
          </Box>
          <Box className="action-box">
            <Divider sx={{ marginBottom: "10px" }} />
            <Button onClick={() => getResponse()}>Run</Button>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default ViewCoderByteQuestion;