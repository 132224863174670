import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import AuthService from "../../../services/auth";
import Lottie from "lottie-react";
import LoginIcon from "../../../assets/lottie/login.json";
import useSnackbar from "../../../hooks/useSnackbar";
import { IErrorResponse } from "../../../interfaces";

const GoogleLogin = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const { verifyGoogleCredentials } = AuthService();

    const verifyCode = async (code: string) => {
        try {
            const login = await verifyGoogleCredentials({ code }) as { data: { token: string } };
            localStorage.setItem("currentUserToken", login.data.token);
            window.location.replace("/dashboard");
        } catch (error) {
            const err = error as IErrorResponse;
            console.log("Error in google login", error);
            snackbar(err.data.message, "error");
            navigate("/login");
            
        }
    };

    useEffect(() => {
        const code = searchParams.get("code");

        verifyCode(code ? code : "");
    }, [searchParams]);

    return (
        <Box height="100vh" className="center" flexDirection="column">
            <Lottie
                animationData={LoginIcon}
                width='100%'
                height='100%'
            />
            <Typography variant="h5"> It will take a moment, please wait ... </Typography>
        </Box>
    );
};

export default GoogleLogin;