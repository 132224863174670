import { useQuery } from "@tanstack/react-query";
import { Box } from "@mui/material";
import { ChangeEvent, useState, useEffect, MouseEvent } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import { IPagination } from "../../../interfaces";
import {
  capitalize,
  createIndex,
  formatDate,
} from "../../../utilities/helper";
import Header from "../../../components/header";
import useDebounce from "../../../hooks/useDebounce";
import useResource from "../../../hooks/useResource";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import { VendorCategoryService } from "../../../services/vendor/vendor-category";
import { IVendorCategory, IVendorCategoryRow, IVendorCategoryState } from "../../../interfaces/vendor/vendor-categories";
import VendorCategoryFilters from "./filter";

interface outletProps {
  refetchCategories: () => void;
}

const VendorCategories = () => {
  let rows: IVendorCategoryRow[] = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const { getVendorCategory, deleteVendorCategory } = VendorCategoryService();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IVendorCategoryState>({
    deleteWarning: false,
    _vendorCategory: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filters: {
      search: "",
      type: [],
      createdBy: []
    },
    filterCount: 0,
  });

  const vendorsCategory = useQuery({
    queryKey: ["allCategories", state.pagination.page, state.filters],
    queryFn: () =>
      getVendorCategory({
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page,
        search: state.filters.search,
        createdBy: state.filters.createdBy,
        type: state.filters.type,
        startDate: state.filters.date?.startDate,    
        endDate: state.filters.date?.endDate 
      }),
  });

  const searchRecord = useDebounce(search, 1000); 
  useEffect(() => {
    if (searchRecord?.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (vendorsCategory.data?.data.length) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: vendorsCategory.data.meta.page,
          totalPages: vendorsCategory.data.meta.totalPages,
          totalRecords: vendorsCategory.data.meta.totalRecords,
        },
      }));
    }
  }, [vendorsCategory.data?.meta]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";
    const type: { key: string, value: string }[] = searchParams.get("type") ? JSON.parse(String(searchParams.get("type"))) : [];
    const createdBy: { key: string, value: string }[] = searchParams.get("created-by") ? JSON.parse(String(searchParams.get("created-by"))) : [];
    const datee: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];

    filterCount += type.length ? 1 : 0;
    filterCount += createdBy.length ? 1 : 0;
    filterCount += datee.length ? 1 : 0;

    let date: {
      startDate: string;
      endDate: string;
    } | undefined = undefined;
    if (datee?.length) {
      date = {
        startDate: datee[0]?.startDate,
        endDate: datee[0]?.endDate,
      };
    }
    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
        type: type.map(item => item.key),
        date,
        createdBy: createdBy.map(item => item.key)
      },
      filterCount
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const handleDelete = (_vendorCategory = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _vendorCategory,
    }));
  };

  const onDelete = async () => {
    try {
      const category = await deleteVendorCategory({ _id: state._vendorCategory });
      snackbar(category.message, "info");
      handleDelete();
      vendorsCategory.refetch();
      outlet?.refetchCategories && outlet.refetchCategories();
    } catch (error) {
      console.log("Error in vendor delete", error);
    }
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };


  const columns = [
    {
      id: "id",
      label: "S. No.",
    },
    {
      id: "category_name",
      label: "Category Name",
    },
    {
      id: "category_type",
      label: "Category Type"
    },
    {
      id: "created_at",
      label: "Created On",
    },
    {
      id: "created_by",
      label: "Created By",
    },
    {
      id: "action",
      label: "Actions",
    },
  ];


  const createRow = (index: number, category: IVendorCategory, pagination: IPagination) => {
    const action = 
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () =>
              navigate({ pathname:`${category._id}`, search: searchParams.toString() }),
            disabled: !resourceAllocate("vendor-category.write"),
          },
          {
            name: "Delete",
            method: () => handleDelete(category._id),
            disabled: !resourceAllocate("vendor-category.remove"),
          },
        ]}
      />;

    return {
      id: createIndex(pagination, index),
      category_name: capitalize(category.name),
      category_type: capitalize(category.type),
      created_at: formatDate(category.createdAt),
      created_by: capitalize(category.createdBy.name),
      action,
    };
  };

  if (vendorsCategory && vendorsCategory.data && vendorsCategory.data.data && vendorsCategory.data.data.length) {
    rows = vendorsCategory.data.data.map((category, i) =>
      createRow(i, category, state.pagination)
    );
  }

  return (
    <div>
      {/* Add Data  */}
      <Header
        className="my-2"
        searchPlaceholder="Search by name"
        onSearch={onSearch}
        btnText="Add Category"
        onBtnClick={
          resourceAllocate("vendor-category.write") ? () => navigate("new") : undefined
        }
        onFilter={openFilter}
        filterCount={state.filterCount}
      >
          <VendorCategoryFilters
            anchorEl={state.filterDialog.anchorEl}
            isOpen={state.filterDialog.isOpen}
            OnClose={closeFilter}
          />
        </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          errorMessage="Add category to see the data here"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Category"
        description="Are you sure you want to delete this category, the vendor with the corresponding category will become inactive?"
      />
      
      <Outlet context={{ ...outlet, reFetch: vendorsCategory.refetch }} />
    </div>
  );
};

export default VendorCategories;
