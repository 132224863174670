import Joi from "joi";
import { required } from "../../shared";

export const offeredMailValidation = Joi.object({
    to: Joi.string()
        .email({ tlds: { allow: false } })
        .label("Offered Email")
        .required()
        .messages({
            "string.empty": required,
        }),

    template: Joi.string()
        .label("Email Template")
        .allow(""),

    ccText: Joi.string()
        .label("CC")
        .allow(""),
    bccText: Joi.string()
        .label("CC")
        .allow(""),

    cc: Joi.array()
        .items(
            Joi.string()
                .email({ tlds: { allow: false } })
        )
        .allow("")
        .label("CC"),
    bcc: Joi.array()
        .items(
            Joi.string()
                .email({ tlds: { allow: false } })
        )
        .allow("")
        .label("BCC"),
    businessTitle: Joi.string()
        .label("Business Title")
        .required()
        .messages({
            "string.empty": required,
        }),
    reportingManager: Joi.string()
        .label("Reporting Manager")
        .required()
        .messages({
            "string.empty": required,
        }),
    stipend: Joi.alternatives().try(
        Joi.string().required().label("Stipend"),
        Joi.number().required().label("Stipend")
    ).messages({
        "string.empty": required,
        "number.empty": required,
    }),
    doj: Joi.string()
        .label("DOJ")
        .required()
        .messages({
            "string.empty": required,
        }),
    subject: Joi.string()
        .required()
        .label("Subject")
        .messages({
            "string.empty": required,
        }),
    content: Joi.string()
        .required()
        .label("Body")
        .messages({
            "string.empty": required,
        }),
    amount: Joi.number()
        .allow()
        .label("Amount"),
});
