import { IContentKeywordResponse ,IContentKeywordsResponse} from "../../../interfaces/content/keyword";
import { keyword, keywordList } from "../../endpoints";
import HttpService from "../../http";

const KeywordService = () => {

    const {httpRequest} = HttpService();

    const getContentKeywords = async (search:object):Promise<IContentKeywordsResponse> => 
    new Promise((resolve,reject) => {
        httpRequest<IContentKeywordsResponse>(
            "GET",
            `${keywordList}`,
            {},
            search
            )
            .then(resolve)
            .catch(reject);
    });

    const addContentKeyword = async (payload : object) :Promise<IContentKeywordResponse>=> new Promise((resolve,reject) => {
        httpRequest<IContentKeywordResponse>(
        "POST",
        `${keyword}`,
        payload )
        .then(resolve)
        .catch(reject);
    });

    const getContentKeyword = async (query:object) : Promise<IContentKeywordResponse> =>  new Promise((resolve,reject) => {
        httpRequest<IContentKeywordResponse>(
            "GET",
            `${keyword}`,
            {},
            query
        )
        .then(resolve)
        .catch(reject);
    });

    const getContentKeywordPartial = async (query:object) : Promise<IContentKeywordResponse> =>  new Promise((resolve,reject) => {
      httpRequest<IContentKeywordResponse>(
          "GET",
          `${keyword}/partial`,
          {},
          query
      )
      .then(resolve)
      .catch(reject);
  });

    const deleteContentKeyword = async(payload:object):Promise<IContentKeywordResponse> =>
    new Promise((resolve,reject) => {
      httpRequest<IContentKeywordResponse>("DELETE",`${keyword}`,payload)
      .then(resolve)
      .catch(reject);
    });
      
    const updateContentKeyword = async(payload:object):Promise<IContentKeywordResponse> =>
      new Promise((resolve,reject) => {
        httpRequest<IContentKeywordResponse>("PUT",`${keyword}`,payload)
        .then(resolve)
        .catch(reject);
      });

    return {addContentKeyword ,getContentKeywords,getContentKeywordPartial,getContentKeyword,updateContentKeyword,deleteContentKeyword};


};

export default KeywordService;