import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { JobService } from "../../../services";
import { ChangeEvent, MouseEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { IPagination, IErrorResponse } from "../../../interfaces/";
import { capitalize, createIndex, displayName, formatDate } from "../../../utilities/helper";
import { IJobRow, IJobState, IJob, IUser, IStatus } from "../../../interfaces";
import { useSearchParams, Outlet, useNavigate, useParams, useOutletContext } from "react-router-dom";
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Typography, IconButton, FormControlLabel, Checkbox, Divider, DialogActions, Button } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import ShareJob from "./share";
import JobFilter from "./filter";
import Header from "../../../components/header";
import useSnackbar from "../../../hooks/useSnackbar";
import useResource from "../../../hooks/useResource";
import useDebounce from "../../../hooks/useDebounce";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../components/shared/mui-tabs";
interface outletProps {
  refetchJobss: () => void;
}

const Job = () => {
  let rows: IJobRow[] = [];
  const { id } = useParams();
  const { getJobs, deleteJob, updateJobStatusAssignTo, updateJob } = JobService();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { snackbar } = useSnackbar();
  const { resourceAllocate } = useResource();
  const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(state => state.user.list);
  const statusList = useSelector<{ status: { list: IStatus[] } }, IStatus[]>(state => state.status.list);
  const usersIds = users.map(user => user._id);
  const statusNames = statusList.map(user => user.name);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IJobState>({
    deleteWarning: false,
    reopenWarning: false,
    share: {
      anchorEl: null,
      isOpen: false
    },
    _job: "",
    urlSlug:"",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      search: "",
      department: [],
      type: [],
      status: [],
      postingDate: [],
      closingDate: [],
      assignTo: [],
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filterCount: 0,
  });

  const getjob = useQuery({
    queryKey: ["allJobs", state.pagination.page, state.filters],
    queryFn: () => getJobs({
      pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
    },
      { ...state.filters })
  });
  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      const prevParams: { [index: string]: string } = {};
      searchParams.forEach((value, key) => {
        prevParams[key] = value;
      });
      setSearchParams(prev => ({
        ...prev,
        ...prevParams,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (getjob.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: getjob.data.meta.page,
          totalPages: getjob.data.meta.totalPages,
          totalRecords: getjob.data.meta.totalRecords
        }
      }));
    }
  }, [getjob.data?.meta]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
    const department: { key: string, value: string }[] = searchParams.get("department") ? JSON.parse(String(searchParams.get("department"))) : [];
    const type: { key: string, value: string }[] = searchParams.get("type") ? JSON.parse(String(searchParams.get("type"))) : [];
    const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
    const postingDate: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("postingDate") ? JSON.parse(String(searchParams.get("postingDate"))) : [];
    const closingDate: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("closingDate") ? JSON.parse(String(searchParams.get("closingDate"))) : [];
    const assignTo: { key: string, value: string }[] = searchParams.get("assign-to") ? JSON.parse(String(searchParams.get("assign-to"))) : [];
    filterCount += department.length ? 1 : 0;
    filterCount += type.length ? 1 : 0;
    filterCount += status.length ? 1 : 0;
    filterCount += assignTo.length ? 1 : 0;
    filterCount += postingDate.length ? 1 : 0;
    filterCount += closingDate.length ? 1 : 0;

    let posting: {
      startDate: string;
      endDate: string;
    } | undefined = undefined;
    if (postingDate?.length) {
      posting = {
        startDate: postingDate[0]?.startDate,
        endDate: postingDate[0]?.endDate,
      };
    }
    let closing: {
      startDate: string;
      endDate: string;
    } | undefined = undefined;
    if (closingDate?.length) {
      closing = {
        startDate: closingDate[0]?.startDate,
        endDate: closingDate[0]?.endDate,
      };
    }
    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search,
        department: department.map(department => department.key),
        type: type.map(type => type.key),
        status: status.map(status => status.key),
        posting,
        closing,
        assignTo : assignTo.map(user => user.key),
      },
      filterCount
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);
  const [selectedJob, setSelectedJob] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedAssignTo, setSelectedAssignTo] = useState<string>("");
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const categoryOptions = [
    {key: "OPEN", value: "Open"},
    {key: "IN-PROGRESS", value: "In-Progress"},
    {key: "ON-HOLD", value: "On-Hold"},
    {key: "CLOSE", value: "Close"}
  ];
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, categoryKey: string) => {
    if (event.target.checked) {
      setSelectedCategories((prevSelectedCategories) => [...prevSelectedCategories, categoryKey]);
    } else {
      setSelectedCategories((prevSelectedCategories) =>
        prevSelectedCategories.filter((id) => id !== categoryKey)
      );
    }
  };

  const handleAssignCategories = async () => {
    try {
      const payload = {
        _id: selectedJob,
        assignTo: selectedAssignTo,
        statusCategories: selectedCategories
      };
      const candidate = await updateJobStatusAssignTo(id ? id : "", payload);
      snackbar(candidate.message, "info");
      getjob.refetch();
      setOpenCategoryDialog(false); 
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log({ "Error in update job status": error });
    }
  };

  const handleAssignTo = async (event: SelectChangeEvent<string>, _id: string) => {
    setSelectedAssignTo(event.target.value);
    setSelectedJob(_id);
    setOpenCategoryDialog(true);
  };

  const handleStatus = async (event: SelectChangeEvent<string>, _id: string) => {
    const { name, value } = event.target;
    console.log({ name, value });
    try {
      const payload = {
        _id,
        [name]: value,
      };
      const candidate = await updateJobStatusAssignTo(id ? id : "", payload);
      snackbar(candidate.message, "info");
      getjob.refetch();
      outlet?.refetchJobss && outlet.refetchJobss();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log({ "Error in update job status": error });
    }
  };

  const OpenShareJob = (e: MouseEvent<HTMLButtonElement>, urlSlug = "") => {
    setState(prevState => ({
      ...prevState,
      share: {
        anchorEl: e.currentTarget,
        isOpen: !prevState.share.isOpen
      },

      urlSlug
    }
    ));
  };

  const closeShareJob = () => {
    setState(prevState => ({
      ...prevState,
      share: {
        anchorEl: null,
        isOpen: !prevState.share.isOpen
      },
      urlSlug: ""
    }
    ));
  };


  const handleReopen = (_job = "") => {
    setState(prevState => ({
      ...prevState,
      reopenWarning: !prevState.reopenWarning,
      _job
    }
    ));
  };

  const onReopen = async () => {
    try {
      const payload = { status: "OPEN", _id: state._job };
      console.log(payload);
      const update = await updateJob(id ? id : "", payload);
      snackbar(update.message, "info");
      navigate({
        pathname: "/careers/jobs",
        search: searchParams.toString()
      });
      handleReopen();
      getjob.refetch();
      outlet?.refetchJobss && outlet.refetchJobss();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log(error);
    }

  };

  const handleDelete = (_job = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _job
    }
    ));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteJob({ _id: state._job });
      snackbar(deleted.message, "info");
      handleDelete();
      getjob.refetch();
      outlet?.refetchJobss && outlet.refetchJobss();
    } catch (error) {
      console.log(error);
    }
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const onCopy = (label: string, text: string) => {
    navigator.clipboard.writeText(text);
    snackbar(`${capitalize(label)} ID copied`, "info");
  };

  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "jobs_jobID",
      label: "Job ID"
    },
    {
      id: "jobs_jobTitle",
      label: "Job Title"
    },
    {
      id: "jobs_engagementType",
      label: "Engagement Type"
    },
    {
      id: "jobs_experience",
      label: "Experience(Years)"
    },
    {
      id: "jobs_openings",
      label: "Openings"
    },
    {
      id: "jobs_applicants",
      label: "Applicants"
    },
    {
      id: "posting_date",
      label: "Posting Date"
    },
    {
      id: "closing_date",
      label: "Closing Date"
    },
    {
      id: "jobs_assignTo",
      label: "Assign To"
    },
    {
      id: "jobs_status",
      label: "Status"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

  const createRow = (
    index: number,
    job: IJob,
    pagination: IPagination,
    handleAssignTo: (e: SelectChangeEvent<string>, id: string) => void,
    handleStatus: (e: SelectChangeEvent<string>, id: string) => void,
  ) => {
    const name =
      <Box className="center">
        <Typography
          onClick={resourceAllocate("job.write") ? () => navigate({ pathname: "" + job._id, search: searchParams.toString() }) : undefined}
          variant="body2"
          color={resourceAllocate("job.write") ? "primary" : undefined}
          className="link-none-underline"
        >
          {(job.jobId)}
        </Typography>
        <IconButton className="ml-2" onClick={() => onCopy(job.title, job.jobId)} size="small">
          <ContentCopyIcon fontSize="small" />
        </IconButton >
      </Box>
      ;

    let action = <GetActions
      icons={[
        { name: "Reopen Job", method: () => handleReopen(job._id), disabled: job.status === "OPEN" || !resourceAllocate("job.write") },
        { name: "Delete", method: () => handleDelete(job._id), disabled: resourceAllocate("job.remove") ? false : true },
      ]}
    />;

    const share = (
      <IconButton
        onClick={e => OpenShareJob(e, job.urlSlug)}
        disabled={job.status !=="OPEN"}
      >
        <ShareIcon color= {job.status !=="OPEN" ? "disabled" :"primary"} />
      </IconButton>
    );

    action = (
      <>
        {share}
        {action}
      </>
    );

    const assignTo =
      <FormControl
        sx={{ width: 150 }}>
        <Select
          size="small"
          name="assignTo"
          disabled={resourceAllocate("job.write") ? false : true}
          onChange={e => handleAssignTo(e, job._id)}
          value={usersIds.includes(job.assignTo) ? job.assignTo : "none"}
        >
          <MenuItem disabled value="none">Select</MenuItem>
          {
            users.map((user, i) => <MenuItem key={i} value={user._id}>{`${capitalize(displayName(user))}`}</MenuItem>)
          }
        </Select>
      </FormControl>;

    const status = (
      <FormControl sx={{ width: 150 }}>
        <Select
          size="small"
          name="status"
          disabled={resourceAllocate("job.write") ? false : true}
          onChange={(e) => handleStatus(e, job._id)}
          value={
            statusNames.includes(job.status) ||
              job.status === "OPEN" ||
              job.status === "HOLD" ||
              job.status === "CANCELLED"
              ? job.status
              : "none"
          }
        >
          <MenuItem value="OPEN">Active</MenuItem>
          <MenuItem
            value="HOLD"
            disabled={
              job?.openings !== 0
            }
          >
            Inactive
          </MenuItem>
          <MenuItem value="CANCELLED">Cancel</MenuItem>
        </Select>
      </FormControl>
    );

    const title = <Typography 
                    onClick={() => navigate({ pathname: "timeline/" + job._id, search: searchParams.toString() })}
                    variant="body2"
                    color="primary"
                    className="link-none-underline"
                  >
                    {capitalize(job?.title)}
                  </Typography>;

    return {
      id: createIndex(pagination, index),
      jobs_jobID: name,
      jobs_jobTitle: title,
      jobs_engagementType: capitalize(job?.type),
      jobs_experience: `${job.minimumExperience} - ${job.maximumExperience}`,
      jobs_openings: (job?.openings),
      jobs_applicants: (job?.candidates),
      posting_date: (formatDate(job?.createdAt)),
      closing_date: (formatDate(job?.closingDate)),
      jobs_assignTo: assignTo,
      jobs_status: status,
      action,
    };
  };
  if (getjob.data?.data.length) {
    rows = getjob.data?.data.map((job, i) => createRow(i, job, state.pagination, handleAssignTo, handleStatus));
  }

  return (
    <>
      {/* Add Data  */}
      <Header
        className='my-2'
        searchPlaceholder="Search by job title or job id"
        onSearch={onSearch}
        btnText="ADD JOB"
        onBtnClick={resourceAllocate("job.write") ? () => navigate("new") : undefined}
        onFilter={openFilter}
        filterCount={state.filterCount}
      >
        <ShareJob
          anchorEl={state.share.anchorEl}
          isOpen={state.share.isOpen}
          onClose={closeShareJob}
          jobId={state.urlSlug}
        />

        <JobFilter
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnClose={closeFilter}
        />
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Job"
        description="Are you sure you want to delete this job?"
      />

      {/* Reopen Job  */}
      <WarningDialog
        isOpen={state.reopenWarning}
        onClose={() => handleReopen()}
        onConfirm={onReopen}
        title="Reopen Job"
        description="Are you sure you want to reopen this Job?"
      />

      <BootstrapDialog
        maxWidth="sm"
        onClose={() => { setOpenCategoryDialog(false); setSelectedCategories([]); }}
        aria-labelledby="customized-dialog-title"
        open={openCategoryDialog}
        scroll="paper"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => { setOpenCategoryDialog(false); setSelectedCategories([]); }}>
          {"Select Categories to Reassign Leads Based on Status"}
        </BootstrapDialogTitle>

        <Box className="dialog-body">
          <Box p={3}>
            {categoryOptions.map((item) => (
              <Box key={item.key}>
                <FormControlLabel
                  key={item.key}
                  control={
                    <Checkbox
                      checked={selectedCategories.includes(item.key)}
                      onChange={(event) => handleCheckboxChange(event, item.key)}
                      name={item.value}
                    />
                  }
                  label={item.value}
                />
              </Box>
            ))}
          </Box>
        </Box>

        <Divider />
        <DialogActions className="dialog-footer">
          <Button
            onClick={() => setOpenCategoryDialog(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
              color="primary"
              variant="contained"
              onClick={() => {
                categoryOptions.forEach((item) => {
                  setSelectedCategories((prevSelectedCategories) => [...prevSelectedCategories, item.key]);
                });
              }}
            >
              Select all 
            </Button>
          <Button 
            color="primary"
            onClick={handleAssignCategories}
          >
            {selectedCategories.length ? "Assign" : "Don`t Assign"}
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Outlet context={{ ...outlet, reFetch: getjob.refetch }} />

    </>
  );
};
export default Job;