import Joi from "joi";
import { required, valid_url } from "../../shared";

export const EditVendorValidation = Joi.object({
    vendorId: Joi.string()
        .required()
        .label("Vnedor ID")
        .messages({
            "string.empty": required,
        }),
    vendorName: Joi.string()
        .required()
        .trim()
        .min(3)
        .max(40)
        .label("Name")
        .messages({
            "string.empty": required,
        }),
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .lowercase()
        .label("Email Address")
        .optional()
        .allow("")
        .messages({
            "string.empty": required,
        }),
    rating: Joi.number()
        .required()
        .label("Rating")
        .messages({
            "number.empty": required,
        }),
    address: Joi.string()
        .optional()
        .allow("")
        .label("Address"),
    website: Joi.string()
        .optional()
        .allow("")
        .regex(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/)
        .trim()
        .label("Website")
        .messages({
            "string.empty": required,
            "string.pattern.base": valid_url 
        }),
    paymentFrequency: Joi.string()
        .required()
        .label("Payment Frequency")
        .messages({
            "string.empty": required,
        }),
    _category: Joi.string()
        .required()
        .label("Category")
        .messages({
            "array.empty": required,
    }),
    phoneNumber: Joi.string()
        .required()
        .min(8)
        .label("Mobile Number")
        .messages({
            "string.empty": required
        }),
    _primaryServices: Joi.array()
        .items(Joi.string())
        .min(1)
        .required()
        .label("Primary Services")
        .messages({
            "array.empty": required,
    }),
    _secondaryServices: Joi.array()
        .items(Joi.string())
        .optional()
        .label("Secondary Services"),
});