import HttpService from "../http";
import { IOverviewResponse, IJobReportResponse, ICandidateReportResponse, ITeamPerformanceReportResponse, ICpdReportResponse } from "../../interfaces";
import { report } from "../endpoints";

const ReportService = () => {
    const { httpRequest } = HttpService();
    const overview = async (search: object): Promise<IOverviewResponse> => new Promise((resolve, reject) => {
        httpRequest<IOverviewResponse>(
            "GET",
            `${report}/overview`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const jobReport = async (search: object): Promise<IJobReportResponse> => new Promise((resolve, reject) => {
        httpRequest<IJobReportResponse>(
            "GET",
            `${report}/jobs`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const candidateReport = async (search: object): Promise<ICandidateReportResponse> => new Promise((resolve, reject) => {
        httpRequest<ICandidateReportResponse>(
            "GET",
            `${report}/candidates`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const teamPerformanceReport = async (search: object): Promise<ITeamPerformanceReportResponse> => new Promise((resolve, reject) => {
        httpRequest<ITeamPerformanceReportResponse>(
            "GET",
            `${report}/team-performance`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const cpdReport = async (search: object): Promise<ICpdReportResponse> => new Promise((resolve, reject) => {
        httpRequest<ICpdReportResponse>(
            "GET",
            `${report}/cpd`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    return { overview, jobReport, candidateReport, teamPerformanceReport, cpdReport };
};

export default ReportService;