import { Box, Switch, Tooltip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";

import { DailyReportService } from "../../../services";
import CustomTable from "../../../components/mui/table";
import { IDailyReportRow, IErrorResponse, IReportData } from "../../../interfaces";
import { capitalize } from "../../../utilities/helper";
import GetActions from "../../../components/get-actions";
import useResource from "../../../hooks/useResource";
import { ChangeEvent } from "react";
import useSnackbar from "../../../hooks/useSnackbar";

const DailyReportRoles = () => {
  const [ searchParams ] = useSearchParams();
  const { resourceAllocate } = useResource();
  const { snackbar } = useSnackbar();
  const navigate = useNavigate();
  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "report_type",
      label: "Report Type"
    },
    {
      id: "roles",
      label: "Roles"
    },
    {
      id: "time_period",
      label: "Time Period"
    },
    {
      id: "isActive",
      label: "Active"
    },
    {
      id: "action",
      label: "Action"
    }
  ];

  const { getDailyReportRoles, updateStatusDailyReportRoles } = DailyReportService();

  const reportData = useQuery({
    queryKey: ["all-daily-report"],
    queryFn: getDailyReportRoles
  });

  const isActiveChange = async (event: ChangeEvent<HTMLInputElement>, checked: string, _id: string) => {
    try {
      const user = await updateStatusDailyReportRoles({ _id, isActive: checked });
      snackbar(user.message, "info");
      reportData.refetch();
    } catch(error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "error");
    }
  };

  const createRow = () => {
    const row: IDailyReportRow[] = [];

    if(reportData && reportData.data && reportData.data.data.length) {

      reportData.data.data.map((data: IReportData, index: number) => {
        const roles = data._roles.map((role: { name: string }) => capitalize(role.name));
        row.push({
          id: index + 1,
          report_type: capitalize(data.reportType),
          roles: <Tooltip title={roles.join(", ")}><span >{roles[0] } { roles.length > 1 ? <span style={{ color: "blue" }}>+{roles.length - 1}</span> : ""}</span></Tooltip>,
          time_period: data.duration,
          isActive: <Switch
          disabled={!resourceAllocate("daily-report-roles.write")}
          onChange={(event ,checked) => isActiveChange(event, String(checked), data._id)}
          checked={!!data.isActive}
        />,
          action: <GetActions icons={[{ name: "Edit", disabled: !resourceAllocate("daily-report-roles.write"), method: () => navigate({ pathname: `/configurations/daily-report-roles/${data._id}`, search: searchParams.toString()})}]}/>
        });
      });
    }

    return row;
  };

  return (
    <Box marginTop={"20px"}>
      <CustomTable 
        columns = {columns}
        rows = {createRow()}
        height="calc(100vh - 180px)" 
      />
      <Outlet context={{ refetchDailyReports: reportData.refetch }}/>
    </Box>
  );
};

export default DailyReportRoles;