import { Box, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { createIndex, formatDateTime } from "../../../utilities/helper";
import { IResourceRole, IResourceRoleRow, IRoleState } from "../../../interfaces";
import { IPagination } from "../../../interfaces";
import Header from "../../../components/header";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import useDebounce from "../../../hooks/useDebounce";
import { ResourceRoleService } from "../../../services/configuration/resource-role";

const ResourceRole = () => {
  let rows: IResourceRoleRow[] = [];

  const [searchParams, setSearchParams] = useSearchParams();
  const { getResourceRoles } = ResourceRoleService();
  const navigate = useNavigate();
  const [search] = useState<string>("");
  const [state] = useState<IRoleState>({
    deleteWarning: false,
    _team: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      search: ""
    }
  });

  const resourceRoles = useQuery({
    queryKey: ["all-resource-roles"],
    queryFn: () => getResourceRoles({})
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);



  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "role_title",
      label: "Roles"
    },
    {
      id: "role_createdAt",
      label: "Created At"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

  const createRow = (index: number, role: IResourceRole, pagination: IPagination) => {
    const action = <GetActions
      icons={[
        { name: "Edit", method: () => navigate({ pathname: "" + role._id, search: searchParams.toString() }) },
      ]}
    />;
    
    const roles =
      <Tooltip title={(role._roles || []).map(sub => sub.name).join(", ")}>
        <span>
          {role && role._roles && role._roles.length ? (
            <>
              {role._roles[0].name}{" "}
              {role._roles .length > 1 && (
                <span style={{ color: "blue" }}>+{role._roles .length - 1}</span>
              )}
            </>
          ) : (
            ""
          )}
        </span>
      </Tooltip>;
    return {
      id: createIndex(pagination, index),
      role_title: roles,
      role_createdAt: formatDateTime(role?.createdAt),
      action,
    };
  };
  if (resourceRoles &&
    resourceRoles.data &&
    resourceRoles.data.data && 
    resourceRoles.data.data.length) {
    rows = resourceRoles.data.data.map((resourceRole, i) => createRow(i, resourceRole, state.pagination));
  }
  return (
    <>
      {/* Add Data  */}
      <Header
        className='my-2'
      />

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
        />
      </Box>

      <Outlet context={{ reFetch: resourceRoles.refetch }} />
    </>
  );
};
export default ResourceRole;

