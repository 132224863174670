import HttpService from "../../http";
import { subCategory, subCategoryList } from "../../endpoints";
import {ISubCategoryResponse, ISubCategorysResponses } from "../../../interfaces/content/sub-category";
const SubCategoryService = () => {
  const { httpRequest } = HttpService();
  const getContentSubCategorys = async (
    search: object
  ): Promise<ISubCategorysResponses> =>
    new Promise((resolve, reject) => {
      httpRequest<ISubCategorysResponses>(
        "GET",
         `${subCategoryList}`,
          {}, 
          search)
        .then(resolve)
        .catch(reject);
    });

  const addContentSubCategory = async (
    payload: object
  ): Promise<ISubCategorysResponses> =>
    new Promise((resolve, reject) => {
      httpRequest<ISubCategorysResponses>("POST", `${subCategory}`, payload)
        .then(resolve)
        .catch(reject);
    });

    const getContentSubCategory = async(query:object):Promise<ISubCategoryResponse> => 
    new Promise((resolve,reject) => {
           httpRequest<ISubCategoryResponse>("GET",`${subCategory}`,{},query)
           .then(resolve)
           .catch(reject);
      });

      const getContentSubCategoryPartial = async(query:object):Promise<ISubCategoryResponse> => 
      new Promise((resolve,reject) => {
             httpRequest<ISubCategoryResponse>("GET",`${subCategory}/partial`,{},query)
             .then(resolve)
             .catch(reject);
        });

      const updateContentSubCategory = async(payload:object):Promise<ISubCategoryResponse> =>
      new Promise((resolve,reject) => {
        httpRequest<ISubCategoryResponse>("PUT",`${subCategory}`,payload)
        .then(resolve)
        .catch(reject);
      });
      const deleteContentSubCategory = async(payload:object):Promise<ISubCategoryResponse> =>
      new Promise((resolve,reject) => {
        httpRequest<ISubCategoryResponse>("DELETE",`${subCategory}`,payload)
        .then(resolve)
        .catch(reject);
      });

      return { getContentSubCategorys,getContentSubCategoryPartial, addContentSubCategory ,getContentSubCategory,updateContentSubCategory,deleteContentSubCategory};
};
export default SubCategoryService;
