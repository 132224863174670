import Joi from "joi";
import { required } from "../../../shared";

export const vendorContactPersonValidation = Joi.object({
    name: Joi.string()
        .required()
        .trim()
        .min(3)
        .max(40)
        .label("Name")
        .messages({
            "string.empty": required,
        }),
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .lowercase()
        .label("Email Address")
        .optional()
        .allow("")
        .messages({
            "string.empty": required,
        }),
    note: Joi.string()
        .optional()
        .allow("")
        .label("Note")
        .messages({
            "string.empty": required,
        }),
    designation: Joi.string()
        .required()
        .trim()
        .min(3)
        .max(40)
        .label("Designation")
        .messages({
            "string.empty": required,
        }),
    phoneNumber: Joi.string()
        .required()
        .min(8)
        .label("Mobile Number")
        .messages({
            "string.empty": required
        }),
});