import { Box, Button } from "@mui/material";
import CustomTable from "../../../components/mui/table";
import { ChangeEvent, useState } from "react";
import { IColumn } from "../../../interfaces";
import useUser from "../../../hooks/useUser";
import WarningDialog from "../../../components/mui/warning-dialog";
import { UsersService } from "../../../services";
import useSnackbar from "../../../hooks/useSnackbar";
import { IntegrationService } from "../../../services/configuration/integration";

const Integration = () => {
  const { user, userRefetch } = useUser();
  const { updateUser } = UsersService();
  const { generateGoogleCode } = IntegrationService();
  const { snackbar } = useSnackbar();
  const [state, setState] = useState({
    disconnect: {
      isOpen: false
    }
  });

  const onGoogleConnect = () => {
    generateGoogleCode().then((res) => {
      window.open(res.data, "_self");
    });
  };

  const handleDelete = () => {
    setState({
      ...state,
      disconnect: {
        ...state.disconnect,
        isOpen: !state.disconnect.isOpen
      }
    });
  };

  const onGoogleDisconnect = async () => {
    try {
      const updatedUser = await updateUser({ _id: user?._id, integrationCredentials: {}, integrationMeta: {} });
      userRefetch();
      snackbar(updatedUser.message, "info");
      handleDelete();
    } catch (error) {
      console.log({ error });
      handleDelete();
    }
  };

  const onPageChange = (e: ChangeEvent<unknown>) => console.log(e);

  const rows = [
    {
      id: 1,
      integration_name: "Google Integration",
      action: user?.integrationCredentials?.access_token?.length ?
        <Button color="error" onClick={handleDelete}>Disconnect</Button> :
        <Button onClick={onGoogleConnect}>Connect</Button>
    }
  ];

  const columns: IColumn[] = [
    {
      id: "id",
      label: "S No.",
      minWidth: 60

    },
    {
      id: "integration_name",
      label: "Name",
      minWidth: 200
    },
    {
      id: "action",
      label: "Actions",
    },
  ];

  return (
    <>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 182px)"
          pagination={{
            page: 1,
            totalPages: 1
          }}
          onPageChange={onPageChange}
        />

        {/* Delete Data  */}
        <WarningDialog
          isOpen={state.disconnect.isOpen}
          onClose={() => handleDelete()}
          onConfirm={onGoogleDisconnect}
          title="Disconnect Integration"
          description="Are you sure do you want to disconnect this integration?"
        />
      </Box>
    </>
  );
};
export default Integration;