import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { ReviewParameterService } from "../../../../services";
import { Box, Grid, MenuItem, TextField } from "@mui/material";
import { reviewParameterValidation } from "../../../../validations";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { IReviewParameterField, IJobTitle, IReviewParameter, IErrorResponse } from "../../../../interfaces";
import Select from "../../../../components/mui/select";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import { capitalize } from "../../../../utilities/helper";

interface outletProps {
    reFetch: () => void
}

const ManageReviewParameter = () => {
    const { id } = useParams();
    const [searchParam] = useSearchParams();
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();
    const hitQuery = (id === "new" || id === "view") ? false : true;
    const { addReviewParameter, getReviewParameter, updateReviewParameter } = ReviewParameterService();
    const reviewParameter = useQuery({ queryKey: [hitQuery], queryFn: () => getReviewParameter({ _id: id }), enabled: hitQuery });
    const jobTitles = useSelector<{ jobTitle: { list: IJobTitle[] } }, IJobTitle[]>(state => state.jobTitle.list);
    const { handleSubmit, control, setValue, formState: { errors } } = useForm<IReviewParameter>({
        resolver: joiResolver(reviewParameterValidation),
        defaultValues: {
            name: "",
            jobTitle: "general",
        }
    });

    useEffect(() => {
        if (id === "new") {
            console.log("new");
        } else {
            if (reviewParameter.data?.data) {
                setValue("name", capitalize(reviewParameter.data.data?.name));
                setValue("jobTitle", reviewParameter.data.data?.jobTitle);
            }
        }
    }, [id, reviewParameter.data]);

    const onSubmit = async (data: IReviewParameter) => {
        try {
            if (id === "new") {
                const payload = { ...data };
                const add = await addReviewParameter(payload);
                snackbar(add.message, "info");
                navigate("/configurations/review-parameters");
                outlet?.reFetch && outlet.reFetch();
            } else {
                const payload = { ...data, _id: id };
                const update = await updateReviewParameter(id ? id : "", payload);
                snackbar(update.message, "info");
                navigate({
                    pathname: "/configurations/review-parameters",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }
    };

    const onClose = () => {
        navigate({
            pathname: "/configurations/review-parameters",
            search: searchParam.toString()
        });
    };

    const fields: IReviewParameterField[] = [
        {
            label: "Name*",
            name: "name",
            type: "input",
            placeholder: "Type your parameter here",
        },
        {
            label: "Job Title*",
            name: "jobTitle",
            type: "select",
            children: [
                <MenuItem key={"general"} value="general">General</MenuItem>,
                ...jobTitles.map((jobTitle, i) => <MenuItem key={i} value={jobTitle.name} >{capitalize(jobTitle.name)}</MenuItem>)
            ]
        },
    ];

    return (
        <Box>
            <CustomDialog
                title={id !== "new" ? "Edit Review Parameter" : "Add Review Parameter"}
                isOpen={id ? true : false}
                onClose={onClose}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "input") {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={field.label}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            placeholder={field.placeholder}
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            } else {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Select
                                        control={control}
                                        name={field.name}
                                        label={field.label}
                                        size="small"
                                        variant="outlined"
                                        error={errors[field.name] ? true : false}
                                        helperText={errors[field.name]?.message}
                                    >
                                        {field.children}
                                    </Select>
                                </Grid>
                                );
                            }
                        })
                    }
                </Grid>
            </CustomDialog>
        </Box>
    );
};

export default ManageReviewParameter;