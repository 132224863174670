import { useEffect } from "react";
import { IEngagement, IErrorResponse, IFaq } from "../../../../interfaces";
import { useQuery } from "@tanstack/react-query";
import { FaqService } from "../../../../services";
import { IFaqField } from "../../../../interfaces";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { faqValidation } from "../../../../validations";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { Box, MenuItem, Grid, TextField } from "@mui/material";
import Select from "../../../../components/mui/select";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import { capitalize } from "../../../../utilities/helper";
import { useSelector } from "react-redux";
interface outletProps {
  reFetch: () => void
}

const ManageFaq = () => {
  const { id } = useParams();
  const [searchParam] = useSearchParams();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const hitQuery = (id === "new" || id === "view") ? false : true;
  const navigate = useNavigate();
  const { addFaq, getFaq, updateFaq } = FaqService();
  const engagementTypes = useSelector<{ engagementType: { list: IEngagement[] } }, IEngagement[]>(state => state.engagementType.list) || [];
  const faq = useQuery({ queryKey: [hitQuery], queryFn: () => getFaq({ _id: id }), enabled: hitQuery });
  const { handleSubmit, control, setValue, formState: { errors } } = useForm<IFaq>({
    resolver: joiResolver(faqValidation),
    defaultValues: {
      title: "",
      type: "INTERN",
      description: "",
    }
  });

  useEffect(() => {
    if (id === "new") {
      console.log("new");
    } else {
      if (faq.data?.data) {
        setValue("title", capitalize(faq.data.data.title));
        setValue("type", faq.data.data?.type);
        setValue("description", capitalize(faq.data.data?.description));
      }
    }
  }, [id, faq.data]);

  const onSubmit = async (data: IFaq) => {
    try {
      if (id === "new") {
        const payload = { ...data };
        const add = await addFaq(payload);
        snackbar(add.message, "info");
        navigate({
          pathname: "/careers/faqs",
          search: searchParam.toString()
        });
        outlet?.reFetch && outlet.reFetch();
      } else {
        const payload = { ...data, _id: id };
        const update = await updateFaq(id ? id : "", payload);
        snackbar(update.message, "info");
        navigate({
          pathname: "/careers/faqs",
          search: searchParam.toString()
        });
        outlet?.reFetch && outlet.reFetch();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log(error);
    }
  };

  const onClose = () => {
    navigate({
      pathname: "/careers/faqs",
      search: searchParam.toString()
    });
  };
  

  const fields: IFaqField[] = [
    {
      label: "Title*",
      name: "title",
      type: "input",
      placeholder: "Type title here",
    },
    {
      label: "Engagement Type*",
      name: "type",
      type: "select",
      children: [
        ...engagementTypes.map((engagementType, i) => <MenuItem key={i} value={engagementType.name.toUpperCase()}>{capitalize(engagementType.name)}</MenuItem>)
      ],
    },
    {
      label: "Description*",
      name: "description",
      type: "multiline",
      placeholder: "Type description here",
      width: 12
    },
  ];

  return (
    <Box>
      <CustomDialog
        title={id !== "new" ? "Edit FAQ" : "New FAQ"}
        isOpen={id ? true : false}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4}>
          {
            fields.map(field => {
              if (field.type === "input") {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <TextField
                      label={field.label}
                      className="disable-text"
                      variant="outlined"
                      size="small"
                      placeholder={field.placeholder}
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                      {...prop.field}
                    />}
                  />
                </Grid>
                );
              } else if (field.type === "multiline") {
                return (<Grid key={field.label} item xs={12} md={field.width ? field.width : 6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <TextField
                      label={field.label}
                      className="disable-text"
                      variant={"outlined"}
                      size={"small"}
                      placeholder={field.placeholder}
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                      multiline
                      minRows={2}
                      {...prop.field}
                    />}
                  />
                </Grid>
                );
              } else {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <Select
                    control={control}
                    name={field.name}
                    label={field.label}
                    size="small"
                    variant="outlined"
                    error={errors[field.name] ? true : false}
                    helperText={errors[field.name]?.message}
                  >
                    {field.children}
                  </Select>
                </Grid>
                );
              }
            })
          }
        </Grid>
      </CustomDialog>
    </Box>
  );

};

export default ManageFaq;