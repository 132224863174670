import {
  Menu,
  Box,
  Chip,
  Typography,
  IconButton,
  Grid,
  List,
  Divider,
  ListItemButton,
  ListItemText,
  Checkbox,
  Button,
  capitalize,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { MenuItem } from "../../../../components/shared/filter";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import ProjectsService from "../../../../services/content/projects-products";
import { checkTimePeriod } from "../../../../utilities/helper";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import dayjs from "dayjs";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";


interface props {
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  onClose: () => void;
}

export interface ICmsReportFilter {
  selectedMenu: number;
  product: {
    key: string,
    value: string
  }[];
  date: {
    key: string,
    value: string,
    startDate: string,
    endDate: string,
  }[];
}

const CmsReportFilter: FC<props> = ({ anchorEl, isOpen, onClose }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { getContentProjects } = ProjectsService();
  const navigate = useNavigate();
  const [state, setState] = useState<ICmsReportFilter>({
    selectedMenu: 0,
    product: [],
    date: []
  });



  const productsData = useQuery({
    queryKey: ["products"],
    queryFn: () =>
      getContentProjects({}),
  });
  let products: { key: string; name: string }[] = [];


  if (productsData.data?.data?.length) {
    products = productsData.data.data.map((item) => ({
      key: item._id,
      name: item.name,
    }));
  }
  const productList = productsData && productsData.data && productsData.data.data ? productsData.data.data : [];

    useEffect(() => {
      setDefaultDateFilter();
    }, []);
    
    const setDefaultDateFilter = () => {
      if (!searchParams.get("date")) {
        const date = checkTimePeriod("thisMonth");
    
        const defaultDateFilter = [
          {
            key: "thisMonth",
            value: "Monthly",
            startDate: date.startDate,
            endDate: date.endDate,
          },
        ];
    
        const product: { key: string, value: string }[] = [];
        searchParams.set("date", JSON.stringify(defaultDateFilter));
        if(localStorage.getItem("selectedFilterProductId")){
          const selectedFilterProductId = localStorage.getItem("selectedFilterProductId") || "";
          const selectedProduct = productList.find((item) => item._id === selectedFilterProductId);
          if (selectedProduct) {
            product.push({ key: selectedProduct._id, value: selectedProduct.name });
          } else {
            product.push({ key: "", value: "" });
          }
          if(product[0].value !== ""){
            searchParams.set("product", JSON.stringify(product));
          }
        }
        setSearchParams(searchParams);
        setState(prevState => ({
          ...prevState,
          date: defaultDateFilter,
          product,
        }));
      }
    };

  const resetFilter = () => {
    setState({
      selectedMenu: 0,
      product: [],
      date:[]
    });
  };

  const onApply = () => {
    searchParams.set("product", JSON.stringify(state.product));
    searchParams.set("date", JSON.stringify(state.date));
    searchParams.set("page", "1");
    navigate({
      pathname: "/reports/cms",
      search: searchParams.toString(),
    });
    onClose();
  };

  const OnClose = () => {
    const product: { key: string, value: string }[] = searchParams.get("product") ? JSON.parse(String(searchParams.get("product"))) : [];
    const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
    setState(prevState => ({
        ...prevState,
        product,
        date
    }));
    onClose();
};

  const handleDateListItem = (name: "date", key: string, value: string) => {
    const date = checkTimePeriod(key);

    const payload: Array<{
      key: string;
      value: string;
      startDate: string,
      endDate: string,
    }> = [{
      key,
      value,
      startDate: date.startDate,
      endDate: date.endDate,
    }];

    setState(prevState => ({
      ...prevState,
      [name]: payload
    }));
  };

  const handleDate = (e: dayjs.Dayjs | null, period: "start" | "end") => {
    const newDoj = e ? dayjs(e).toISOString() : "";
    let date: Array<{
      key: string;
      value: string;
      startDate: string,
      endDate: string,
    }> = [];

    if (state.date.length && period === "start") {
      date = state.date.map(e => ({ ...e, startDate: newDoj, value: "custom", key: "Custom" }));
    } else if (state.date.length && period === "end") {
      date = state.date.map(e => ({ ...e, endDate: newDoj, value: "custom", key: "Custom" }));
    } else if (!state.date.length && period === "start") {
      const currentDate = new Date();
      currentDate.setHours(23,59,59, 999);
      date = [{
        key: "custom",
        value: "custom", 
        startDate:  dayjs(e).startOf("day").toISOString(),
        endDate: currentDate.toISOString()
      }];
    } else {
      const currentDate = new Date();
      currentDate.setHours(0,0,0,0);
      date = [{
        key: "custom",
        value: "custom",
        startDate: currentDate.toISOString(),
        endDate: dayjs(e).endOf("day").toISOString(),
      }];
    }

    setState(prevState => ({
      ...prevState,
      date
    }));
  };


  const deleteChip = (name: "product" | "date", key: string) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];

    payload = state[name].filter((ele) => ele.key !== key);
    setState((prevState) => ({
      ...prevState,
      [name]: payload,
    }));
  };

  const handleLeftListItem = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setState((prevState) => ({
      ...prevState,
      selectedMenu: index,
    }));
  };

  const handleRightListItem = (
    name: "product",
    key: string,
    value: string
  ) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];

    const isExist = state[name].find((ele) => ele.key === key) ? true : false;
    if (isExist) {
      payload = state[name].filter((ele) => ele.key !== key);
    } else {
      payload = state[name];
      payload.push({
        key,
        value,
      });
    }

    setState((prevState) => ({
      ...prevState,
      [name]: payload,
    }));
  };

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box id="filters-container">
          <Box
            className="center mb-3"
            justifyContent="space-between"
            alignItems="start"
          >
            <div className="active-filter mb-1">
              {state.product.length || state.date.length ? (
                <>
                  {state.product.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("product", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                  {state.date.map(ele => <Chip key={ele.key} className="m-1" icon={<CalendarMonthIcon />} color="primary" onDelete={() => deleteChip("date", ele.key)} label={ele.value} variant="outlined" />)}
                </>
              ) : (
                <Box
                  className="mt-2"
                  display="flex"
                  alignItems="center"
                >
                  <FilterAltOffIcon />
                  <Typography className="ml-2">
                    No filter are applied
                  </Typography>
                </Box>
              )}
            </div>
            <IconButton
              onClick={OnClose} 
              style={{ marginRight: "-10px" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Grid
            className="filter-box"
            container
          >
            <Grid
              id="left"
              item
              xs={5}
            >
              <List component="nav">
                <MenuItem
                  index={0}
                  label="Product"
                  selectedMenu={state.selectedMenu === 0}
                  onChange={handleLeftListItem}
                  count={state.product}
                />
                <MenuItem
                  index={1}
                  label="Date"
                  selectedMenu={state.selectedMenu === 1}
                  onChange={handleLeftListItem}
                  count={state.date}
                />
              </List>
            </Grid>
            <Divider orientation="vertical" />

            <Grid
              id="right"
              item
              xs={6}
            >
              <List component="nav">
                {state.selectedMenu === 0 &&
                  products.map((product) => (
                    <ListItemButton
                      key={product.key}
                      selected={
                        state.product.find((ele) => ele.key === product.key)
                          ? true
                          : false
                      }
                      onClick={() =>
                        handleRightListItem(
                          "product",
                          product.key,
                          product.name
                        )
                      }
                    >
                      <ListItemText primary={product.name} />
                      <Checkbox
                        edge="end"
                        checked={
                          state.product.find((ele) => ele.key === product.key)
                            ? true
                            : false
                        }
                      />
                    </ListItemButton>
                  ))}

                {
                  state.selectedMenu === 1 &&
                  <>
                    {[
                      { key: "thisWeek", value: "Weekly" },
                      { key: "thisMonth", value: "Monthly" },
                      { key: "thisQuarter", value: "Quarterly" },
                    ]?.map((date) =>
                      <ListItemButton
                        key={date.key}
                        selected={state.date.find(ele => ele.key === date.key) ? true : false}
                        onClick={() => handleDateListItem("date", date.key, capitalize(date.value))}
                      >
                        <ListItemText primary={date.value} />
                        <Checkbox edge="end" checked={state.date.find(ele => ele.key === date.key) ? true : false} />
                      </ListItemButton>
                    )}
                    <Box marginTop={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          value={state.date[0]?.startDate ? dayjs(state.date[0]?.startDate) : null}
                          onChange={e => handleDate(e, "start")}
                          label="Start Date"
                          format="LL"
                        />
                      </LocalizationProvider>
                      <div className="mt-3" />
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          value={state.date[0]?.endDate ? dayjs(state.date[0]?.endDate) : null}
                          onChange={e => handleDate(e, "end")}
                          label="End Date"
                          format="LL"
                        />
                      </LocalizationProvider>
                    </Box>
                  </>
                }
              </List>
            </Grid>
          </Grid>

          <Box
            className="actions-btn"
            marginTop="8px"
            textAlign="end"
          >
            <Button
              variant="outlined"
              color="error"
              onClick={() => resetFilter()}
            >
              Clear All
            </Button>
            <Button
              className="ml-2"
              onClick={onApply}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default CmsReportFilter;
