import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IntegrationService } from "../../../services";
import useSnackbar from "../../../hooks/useSnackbar";
import useUser from "../../../hooks/useUser";
import { Box, Typography } from "@mui/material";
import Lottie from "lottie-react";
import WaitingIcon from "../../../assets/lottie/waiting.json";

const GoogleCalender = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const { user, userRefetch } = useUser();
    const { setGoogleCredentials } = IntegrationService();


    const setCredentials = async (code: string) => {
        try {
            await setGoogleCredentials({ code, id: user?._id });
            userRefetch();
            navigate("/configurations/integration");
            snackbar("Google integrated successfully", "info");
        } catch (error) {
            console.log("Error in google calendar", error);
        }
    };

    useEffect(() => {
        const code = searchParams.get("code") || "";
        setCredentials(code);
    }, [user]);

    return (
        <Box className="center" flexDirection="column">
            <Lottie
                animationData={WaitingIcon}
                width='100%'
                height='100%'
            />
            <Typography variant="h5"> It will take a moment, please wait ... </Typography>
        </Box>
    );
};

export default GoogleCalender;