import HttpService from "../http";
import { aiParser } from "../endpoints";
import { IResumeParserResponse } from "../../interfaces/resume-parser";



const ResumeParserService = () => {
const { httpRequest } = HttpService();


    const getResumeParsedInformation = async (query: object): Promise<IResumeParserResponse> => new Promise((resolve, reject) => {
        httpRequest<IResumeParserResponse>(
            "GET",
            `${aiParser}/resumes`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
      });
      
    return { getResumeParsedInformation };

};

export { ResumeParserService };

