import { ICmsSubCategoryResponse, ISubCategorySampleResponse } from "../../../../interfaces";
import { subCategory } from "../../../endpoints";
import HttpService from "../../../http";

const SubCategoryImportService = () => {

    const { httpRequest } = HttpService();
    const getSubCategorySample = async (search: object): Promise<ISubCategorySampleResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<ISubCategorySampleResponse>(
                "GET",
                `${subCategory}/sample`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });

    const importSubCategory = async (payload: object): Promise<ICmsSubCategoryResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<ICmsSubCategoryResponse>(
                "POST",
                `${subCategory}/import`,
                payload
            )
                .then(resolve)
                .catch(reject);
        });

    return { getSubCategorySample , importSubCategory };

};

export { SubCategoryImportService };