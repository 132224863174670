import HttpService from "../http";
import { ITrashsResponse, IQuery, ITrashResponse , ITrashBlogResponse, ITrashsBlogResponse} from "../../interfaces";
import { trash, trashBlog } from "../endpoints";

const TrashService = () => {
    const { httpRequest } = HttpService();
    const getTrashs = async (params: IQuery, data = {}): Promise<ITrashsResponse> => new Promise((resolve, reject) => {
        httpRequest<ITrashsResponse>(
            "POST",
            `${trash}/list`,
            data,
            params
        )
            .then(resolve)
            .catch(reject);
    });

    const recoverTrash = async ( payload: object): Promise<ITrashResponse> => new Promise((resolve, reject) => {
        httpRequest<ITrashResponse>(
            "PUT",
            `${trash}/recover`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getBlogTrashs = async (params: IQuery, data = {}): Promise<ITrashsBlogResponse> => new Promise((resolve, reject) => {
        httpRequest<ITrashsBlogResponse>(
            "GET",
            `${trashBlog}/list`,
            data,
            params 
        )
            .then(resolve)
            .catch(reject);
    });

    const recoverBlogTrash = async ( payload: object): Promise<ITrashBlogResponse> => new Promise((resolve, reject) => {
        httpRequest<ITrashBlogResponse>(
            "PUT",
            `${trashBlog}/recovery`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { getTrashs, recoverTrash, getBlogTrashs, recoverBlogTrash};

};

export { TrashService };