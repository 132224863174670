import HttpService from "../http";
import { IRequestedSkillResponse } from "../../interfaces";
import { resourceRequest } from "../endpoints";
import { IResourceRequestedResponse, IResourceRequestResponse } from "../../interfaces/resource-request";

const ResourceRequestedServices = () => {

    const { httpRequest } = HttpService();
    const getRequestedResources = async (search: object): Promise<IResourceRequestedResponse> => new Promise((resolve, reject) => {
        httpRequest<IResourceRequestedResponse>(
            "GET",  
            `${resourceRequest}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });


    const addRequestedResource = async (payload: object): Promise<IResourceRequestedResponse> => new Promise((resolve, reject) => {
      httpRequest<IResourceRequestedResponse>(
          "POST",
          `${resourceRequest}`,
          payload
      )
          .then(resolve)
          .catch(reject);
  });

  const updateResource = async (payload: object): Promise<IRequestedSkillResponse> => new Promise((resolve, reject) => {
    httpRequest<IRequestedSkillResponse>(
        "PUT",
        `${resourceRequest}`,
        payload,
    )
        .then(resolve)
        .catch(reject);
});

    const getRequestedResource = async (query: object): Promise<IResourceRequestResponse> => new Promise((resolve, reject) => {
        httpRequest<IResourceRequestResponse>(
            "GET",
            `${resourceRequest}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateResourceStatus = async (payload: object): Promise<IRequestedSkillResponse> => new Promise((resolve, reject) => {
        httpRequest<IRequestedSkillResponse>(
            "PUT",
            `${resourceRequest}/status`,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const sendRequestedResourceEmail = async (payload: object): Promise<IResourceRequestedResponse> => new Promise((resolve, reject) => {
        httpRequest<IResourceRequestedResponse>(
            "POST",
            `${resourceRequest}/email`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { getRequestedResources, getRequestedResource, updateResourceStatus, addRequestedResource, updateResource, sendRequestedResourceEmail };

};

export { ResourceRequestedServices };