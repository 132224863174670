import HttpService from "../../http";
import { IResourceRoleResponse, IResourceRolesResponse } from "../../../interfaces";
import { resourceRequestRole } from "../../endpoints";

const ResourceRoleService = () => {
    const { httpRequest } = HttpService();


    const getResourceRoles = async (search: object): Promise<IResourceRolesResponse> => new Promise((resolve, reject) => {
        httpRequest<IResourceRolesResponse>(
            "GET",
            `${resourceRequestRole}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getResourceRole = async (query: object): Promise<IResourceRoleResponse> => new Promise((resolve, reject) => {
        httpRequest<IResourceRoleResponse>(
            "GET",
            `${resourceRequestRole}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateResourceRole = async (payload: object): Promise<IResourceRoleResponse> => new Promise((resolve, reject) => {
        httpRequest<IResourceRoleResponse>(
            "PUT",
            `${resourceRequestRole}`,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    return { getResourceRoles, getResourceRole, updateResourceRole };
};

export { ResourceRoleService };
