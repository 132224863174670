import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { capitalize } from "../../../utilities/helper";
import { Box, Button, Grid, SelectChangeEvent, Switch, TextField, Typography, } from "@mui/material";
import { IProfileField, IProfle } from "../../../interfaces/setting/profile";
import Avatar from "@mui/material/Avatar";
import MobileNumber from "../../../components/mui/mobile-number";
import useUser from "../../../hooks/useUser";
import { UsersService } from "../../../services/configuration";
import useSnackbar from "../../../hooks/useSnackbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { joiResolver } from "@hookform/resolvers/joi";
import { myProfileValidation } from "../../../validations/configuration/my-profile";

const Profile = () => {
  const { myProfileUpdate } = UsersService();
  const [isDisabled,setIsDisabled] = useState(true);

  const handleEditClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDisabled(false);
  };

  const [emailtoggle, setEmailtoggle] = useState<boolean>(false);

  const handleToggleSwitch = () => {
    setEmailtoggle(!emailtoggle);
  };

  const handleCancelClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDisabled(true);
    if (user) {
      setValue("firstName", `${capitalize(user?.firstName || "")}`);
      setValue("lastName", `${capitalize(user?.lastName || "")}`);
      setValue("role", capitalize(user._role?.name));
      setValue("email", user.email);
      setValue("number", user.phone.number);
      setState(user.phone);
      setValue("department", capitalize(user.department?.name));
      setEmailtoggle(user.wantToBeNotified);
    }
    userRefetch();
    clearErrors();
   
  };

  const { snackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user, userRefetch } = useUser();  
  const { control, setValue, formState: { errors }, getValues, handleSubmit, clearErrors } = useForm<IProfle>({
   resolver: joiResolver(myProfileValidation),
    defaultValues: {
      firstName: "", 
      lastName: "",
      role: "",
      email: "",
      number: "",
      department: "",
    }
  });

  const [state, setState] = useState({
    country: "INDIA",
    dialCode: "+91",
    iso2: "IN",
  });

  useEffect(() => {
    if (user) {
      setValue("firstName", `${capitalize(user?.firstName || "")}`);
      setValue("lastName", `${capitalize(user?.lastName || "")}`);
      setValue("role", capitalize(user._role?.name));
      setValue("email", user.email);
      setValue("number", user.phone.number);
      setState(user.phone);
      setValue("department", capitalize(user.department?.name));
      setEmailtoggle(user.wantToBeNotified);
    }

  }, [user]);

  const onNumberChange = (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    const data = value.split(":");
    setState({
      country: data[0],
      iso2: data[1],
      dialCode: data[2]
    });
  };

  const fields: IProfileField[] = [
    {
      label: "First Name",
      name: "firstName",
      type: "input",
      placeholder: "Enter first name",
      wordLimit: 30,
    },
    {
      label: "Last Name",
      name: "lastName",
      type: "input",
      placeholder: "Enter last name",
      wordLimit: 30,
    },
    {
      label: "Email",
      name: "email",
      type: "input",
      placeholder: "Enter email",
      wordLimit: 30
    },
    {
      label: "Mobile Number",
      name: "number",
      type: "mobile-number",
      placeholder: "Enter contact",
    },
    {
      label: "Authority",
      name: "role",
      type: "input",
      wordLimit: 30
    },
    {
      label: "Team",
      name: "department",
      type: "input",
      wordLimit: 70
    },
  ];

  const onSubmit = async(data:IProfle) => {
    try {
    const payload = { ...data };
    payload.wantToBeNotified = emailtoggle;
    payload.phone = {dialCode:"",iso2:"",country:"",number:""};
    payload.name = "";
    payload.phone.dialCode = state.dialCode;
    payload.phone.iso2 = state.iso2;
    payload.phone.country = state.country;
    payload.name = `${getValues("firstName") && getValues("firstName")} ${getValues("lastName") && getValues("lastName")}`;
    payload.phone.number= getValues("number") && getValues("number") || "";
    delete payload.number;
    delete payload.role;
    delete payload.department;
    const update = await myProfileUpdate({_id: user && user._id , ...payload});
    setIsDisabled(true);
    navigate({
      pathname: "/settings/profile",
      search: searchParams.toString()
    });
    snackbar(update.message, "info");
    userRefetch();
  } catch (error) {
    const err = error as {message:string};
    snackbar(err.message, "warning"); 
  }
  };

  return (
    <Box paddingTop="50px" className="center" flexGrow={1}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box height={!isDisabled ? "60vh" : "60vh"} className="profile-box mb-2">      
         <Box height={!isDisabled ? "50vh" : "50vh"} overflow={"auto"}>
          <Avatar className="profile-avatar" sx={{ width: "60px", height: "60px", margin: "30px auto" }} src={user?.integrationMeta?.picture}>
            {user?.firstName && capitalize(user?.firstName.charAt(0) || "")}
          </Avatar>
          <Grid container spacing={4}>
            {
              fields.map(field => {
                if (field.type === "input") {
                  return (<Grid key={field.label} item xs={12} md={6}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => <TextField
                        {...prop.field}
                        label={field.label}
                        className="disable-text"
                        disabled={field.name === "role" || field.name === "department" ? true: isDisabled}
                        variant={isDisabled ? "standard" : "outlined"}
                        size={isDisabled ? "medium" : "small"}
                        placeholder={field.placeholder}
                        autoComplete={field.name === "email" ? "username" : ""}
                        error={!!errors[field.name]}
                        helperText={errors[field.name]?.message}
                        inputProps={{ maxLength: field.wordLimit, minLength: 3 }}
                        onKeyDown={(event) => {
                            const inputValue = event.target as HTMLInputElement;
                            if (event.key === "Enter" || (event.key === " " && inputValue.value.length === 0)) {
                              event.preventDefault();
                            }
                        }}
                      />}
                    />
                  </Grid>
                  );
                }
                else if (field.type === "mobile-number") {
                  return <Grid key={field.label} item xs={12} md={6}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => <MobileNumber
                        style={{ width: "100%"}}
                        other={prop.field}
                        key={field.label}
                        placeholder={field.placeholder}
                        className="disable-text"
                        disabled={isDisabled}
                        NumberFieldLabel={field.label}
                        dialCodeValue={`${state.country}:${state.iso2}:${state.dialCode}`}
                        dialCodeName={field.name}
                        onChange={onNumberChange}
                        error={errors[field.name] ? true : false}
                        helperText={errors[field.name]?.message}
                      />}
                    />
                  </Grid>;
                }
              })
            }

            {
              user?._role?.name === "default" ?
                <Grid item xs={12}>
                  <Box className="text-center">
                    <Typography variant="caption" color="red">You have not assign any permission, Please contact to Administrator</Typography>
                    <br />
                    <Typography variant="caption">
                      <a className="primary-color " href="mailto:admin@example.com">Click here to send an email</a>
                    </Typography>
                  </Box>
                </Grid>
                :
                null
            }
          </Grid>    
          <Box>
            <Typography variant="body2" className="mt-5" color={isDisabled? "textSecondary" : ""}>Email Notification</Typography>
            <Switch
            disabled= {isDisabled}
            checked={emailtoggle || false}
            onChange={handleToggleSwitch}
         />
        </Box>  
        </Box>
          <Box className="form-actions mt-1" display="flex" justifyContent="flex-end" alignItems={"end"}>
          {isDisabled ? (
            <Button variant="contained" color="primary"  type="button" onClick={handleEditClick}>
              Edit
            </Button>
          ) : (
            <Box>
              <Button variant="outlined" type="button" onClick={handleCancelClick}>
                Discard
              </Button>
              <Button variant="contained" color="primary" type="submit" className="ml-2 ">
                Save
              </Button>
            </Box>
          )}
        </Box>
        </Box>
      </form>
    </Box>
  );
};

export default Profile;