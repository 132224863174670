import "../style.scss";
import { FC, useState } from "react";
import { Box, Button, Grid, TextField } from "@mui/material";
import GetActions from "../../../../components/get-actions";
import { IVendorEdit } from "../../../../interfaces/vendor";
import useSnackbar from "../../../../hooks/useSnackbar";
import { Controller, useForm } from "react-hook-form";
import { IErrorResponse } from "../../../../interfaces/shared/response";
import { IColumn } from "../../../../interfaces/shared";
import CustomTypography from "../../../../components/mui/max-length-limit";
import { capitalize, formatDate } from "../../../../utilities/helper";
import CustomTable from "../../../../components/mui/table";
import WarningDialog from "../../../../components/mui/warning-dialog";
import { useQuery } from "@tanstack/react-query";
import { VendorNotesService } from "../../../../services/vendor/notes";
import ManageNotes from "./manage";
import { joiResolver } from "@hookform/resolvers/joi";
import { INote, INoteDetail, INoteRow, INoteState } from "../../../../interfaces/vendor/notes";
import CustomLabel from "../../../../components/mui/custom-label";
import { vendorNoteValidation } from "../../../../validations/vendor/manage-vendor/notes";

interface props {
  vendor: IVendorEdit | undefined;
  activeAction: boolean;
  setActiveAction: (e: boolean) => void;
}

const Notes: FC<props> = ({ vendor, activeAction, setActiveAction}) => {
    const { snackbar } = useSnackbar();
    const [noteId, setNoteId] = useState("");
    const { addNote, deleteNote, getNotes } = VendorNotesService();
    const [state, setState] = useState<INoteState>({
      list: [],
      deleteWarning: false,
      manageNote: {
        isOpen: false,
        note: {
          note: "",
          createdAt: "",
          createdBy: {
            name: "",
            _id: ""
          },
          _id: "",
        }
      },
      _noteDetail: "",
      deleteIndex: -1
    });
  
    const { control, handleSubmit, reset, formState: { errors } } = useForm<INoteDetail>({
      resolver: joiResolver(vendorNoteValidation),
      defaultValues: {
          note: "",
      }
    });

    const vendorNotes = useQuery({
      queryKey: ["allNotes"],
      queryFn: () =>
        getNotes({
          _vendor: vendor?._id
        }),
    });

    let rows: INoteRow[] = [];

    const createRow = (index: number, noteDetail: INoteDetail,) => {
      const action = <GetActions
        icons={[
          { name: "Edit", method: () => handleEdit(noteDetail) },
          { name: "Delete", method: () => handleDelete(noteDetail._id) },
        ]}
      />;
  
      return {
        id: index + 1,
        note: (
          <CustomTypography
            limit={60}
            label={noteDetail.note}
          />
        ),
        createdAt: formatDate(noteDetail.createdAt),
        createdBy: capitalize(noteDetail.createdBy.name),
        action
      };
    };

    if (vendorNotes?.data?.data?.length) {
      rows = vendorNotes.data.data.map((note, i) => createRow(i, note));
    }
  
    const handleDelete = (_id?: string) => setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _noteDetail: _id ? _id : ""
    }));
  
    const onDelete = async () => {
      try {
        const id = {
          _id: state._noteDetail
        };
        const deletedCandidate = await deleteNote(id);
        snackbar(deletedCandidate.message, "info");
        handleDelete();
        vendorNotes.refetch();
      } catch (error) {
        const err = error as IErrorResponse;
        snackbar(err.data.message, "warning");
        handleDelete();
        console.log({ "Error in delete note": error });
      }
    };
  
    const handleEdit = (note?: INoteDetail) => setState(prevState => {
      if(note?._id){
        setNoteId(note._id);
      }
      const newState = {
        ...prevState,
        manageNote: {
          ...prevState.manageNote,
          isOpen: !prevState.manageNote.isOpen,
        },
        _noteDetail: note ? note._id : "",
      } as INoteState;
  
      return newState;
    });

    const onSubmit = async (data: INoteDetail) => {
      try {
        const payload = {
          ...data
        };
        const noteDetail = await addNote({
          ...payload,
          _vendor: vendor?._id,
        });
        setActiveAction(false);
        reset();
        snackbar(noteDetail?.message, "info");
        vendorNotes.refetch();
      } catch (error) {
        const err = error as IErrorResponse;
        snackbar(err?.data?.message, "warning");
        console.log("error in contract detail", error);
      }
    };
  
    const fields: INote[] = [
      {
        type: "input",
        name: "note",
        label: "Note",
        placeholder: "Type note here",
        required: true
      }
    ];
  
    const columns: IColumn[] = [
      {
        id: "id",
        label: "S No."
      },
      {
        id: "note",
        label: "Note",
      },
      {
        id: "createdAt",
        label: "Created On"
      },
      {
        id: "createdBy",
        label: "Created By"
      },
      {
        id: "action",
        label: "Actions"
      },
    ];
  
    return (
      <Box paddingTop="10px">
        <Box height="56vh" overflow="auto" paddingTop="10px">
  
          {/* Add Data  */}
          {activeAction && 
            <Box marginBottom="20px">
              <form onSubmit={handleSubmit(onSubmit)} onKeyDown={e => e.key === "Enter" && e.preventDefault()}>
                <Grid container spacing={4}>
                  {
                    fields.map(field => {
                      if (field.type === "input") {
                        return (<Grid key={field.label} item xs={4}>
                          <Controller
                            control={control}
                            name={field.name}
                            render={(prop) => <TextField
                              label={<CustomLabel label={field.label} required={field?.required} />}
                              className="disable-text"
                              variant={"outlined"}
                              size={"small"}
                              placeholder={field.placeholder}
                              error={!!errors[field.name]}
                              helperText={errors[field.name]?.message}
                              {...prop.field}
                            />}
                          />
                        </Grid>
                        );
                      }
                    })
                  }
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      type="submit"
                    >
                      Add Note
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          }
  
          {/* Show Data  */}
          <CustomTable
            columns={columns}
            rows={rows}
            height={activeAction ? "calc(100% - 145px)" : "calc(100% - 13px)"}
            width="calc(100% - 2px)"
          />
  
          {/* Delete Data  */}
          <WarningDialog
            isOpen={state.deleteWarning}
            onClose={() => handleDelete()}
            onConfirm={onDelete}
            title="Delete Note"
            description="Are you sure you want to delete this note?"
          />
  
          {/* Manage Data  */}
          <ManageNotes
            note={noteId}
            refetch={vendorNotes.refetch}
            isOpen={state.manageNote.isOpen}
            onClose={handleEdit}
          />
        </Box>
      </Box>
    );
};

export default Notes;