import { Grid, TextField } from "@mui/material";
import { useNavigate ,useParams,useOutletContext} from "react-router-dom";
import CustomDialog from "../../../../components/mui/dialog";
import {Controller,useForm} from "react-hook-form";
import { IErrorResponse} from "../../../../interfaces";
import { IEditSkillName,ISKillField } from "../../../../interfaces/skill-matrix/edit-skill-matrix/edit-skill";
import { useQuery } from "@tanstack/react-query";
import { SkillMatrixService } from "../../../../services";
import { useEffect } from "react";
import useSnackbar from "../../../../hooks/useSnackbar";
import { joiResolver } from "@hookform/resolvers/joi";
import { EditSkillValidation } from "../../../../validations/skill-matrix/edit-skill-matrix/skill";
interface IOutletProps{
    reFetch: () => void;
}

const EditSkill = () => {
    const {snackbar} = useSnackbar();
    const {getSkillItem,updateSkill } = SkillMatrixService();
    const {id} = useParams();
    const outlet = useOutletContext<IOutletProps>();
    const navigate = useNavigate();
    const {control,
        handleSubmit,
        setValue,
        formState:{errors}
    } = useForm<IEditSkillName>({
        resolver: joiResolver(EditSkillValidation),
        defaultValues:{
            name:""
        }
    });
    const skill = useQuery({
        queryKey:["Skill"],
        queryFn:() => getSkillItem({_id:id})
    });

    useEffect(() => {
        if(skill?.data){
            setValue("name",skill?.data?.data?.name || "");
        }
    },[skill?.data]);
    const fields:ISKillField[] = [
        {
            name:"name",
            label:"Skill Name",
            type:"input",
            placeholder:"Enter Skill Name"
        }
    ];

    const onSubmit = async (data:IEditSkillName) => {
        try{
            const payload = {
                ...data,_id:id
            };
            const response = await updateSkill(payload);
            snackbar(response.message,"info");
            navigate("/skill-matrix");
            outlet?.reFetch && outlet.reFetch();
        }catch(error){
         const err = error as IErrorResponse;
         if(err?.data?.message === "Name already exists"){
            return snackbar("Skill Matrix name already exists","warning");
         }
         console.log("Error in edit skill",err);
        }
    };
    return (
       <CustomDialog
       size="sm"
       title="Edit Skill Matrix Name"
       isOpen={true}
       onClose={() => navigate("/skill-matrix")}
       onSubmit={handleSubmit(onSubmit)}
       >
        <Grid container spacing={2}>
            { fields.map((field) =>{
            if(field.type==="input"){
                return(
                    <Grid key={field.label} item xs={12}>
                        <Controller
                            name={field.name}
                            control={control}
                            render = {(prop) => ( 
                                <TextField
                                label={field.label}
                                className="disable-text"
                                variant="outlined"
                                size="small"
                                placeholder={field.placeholder}
                                error={errors[field.name] ? true:false}
                                helperText={errors[field.name]?.message}
                                {...prop.field}    
                                /> 
                                
                            )  }
                            />
                    </Grid>
                );
            }
            })}
        </Grid>

       </CustomDialog>
    );
};

export default EditSkill;