import HttpService from "../http";
import { IBroadcastAnalyticsResponse, IBroadcastInsightResponse, IBroadcastsResponse, IWhatsappTemplateResponse } from "../../interfaces/broadcast";
import { broadcast } from "../endpoints";

const broadcastService = () => {
    const { httpRequest } = HttpService();
    const addbroadcast = async (payload: object): Promise<IBroadcastsResponse> => new Promise((resolve, reject) => {
        httpRequest<IBroadcastsResponse>(
            "POST",
            `${broadcast}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const addWhatsappBroadcast = async (payload: object): Promise<IBroadcastsResponse> => new Promise((resolve, reject) => {
        httpRequest<IBroadcastsResponse>(
            "POST",
            `${broadcast}/whatsapp`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getbroadcasts = async (search: object): Promise<IBroadcastsResponse> => new Promise((resolve, reject) => {
        httpRequest<IBroadcastsResponse>(
            "GET",
            `${broadcast}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const whatsappTemplates = async (search: object): Promise<IWhatsappTemplateResponse> => new Promise((resolve, reject) => {
        httpRequest<IWhatsappTemplateResponse>(
            "GET",
            `${broadcast}/whatsapp-templates`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getBroadcastEvents = async (search: object): Promise<IBroadcastAnalyticsResponse> => new Promise((resolve, reject) => {
        httpRequest<IBroadcastAnalyticsResponse>(
            "GET",
            `${broadcast}/events`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getBroadcastInsights = async (search: object): Promise<IBroadcastInsightResponse> => new Promise((resolve, reject) => {
        httpRequest<IBroadcastInsightResponse>(
            "GET",
            `${broadcast}/insights`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const resendBroadcast = async (payload: object): Promise<IBroadcastsResponse> => new Promise((resolve, reject) => {
        httpRequest<IBroadcastsResponse>(
            "POST",
            `${broadcast}/resend`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { addbroadcast, addWhatsappBroadcast, getbroadcasts, whatsappTemplates, getBroadcastInsights, getBroadcastEvents, resendBroadcast };

};

export { broadcastService };