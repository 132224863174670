import Joi from "joi";
import { required, only_string } from "../../shared";

export const writerReportRolesValidation = Joi.object({
    _roles: Joi.array().items(Joi.string()
        .required()
        .label("Role")
        .max(80)
        .min(3)
        .messages({
            "string.empty": required,
            "string.pattern.base": only_string,
            "string.max": "Maximum 80 characters are allowed"
        })),
    ccText: Joi.string()
        .label("CC")
        .allow(""),
    bccText: Joi.string()
        .label("BCC")
        .allow(""),

    cc: Joi.array()
        .items(
            Joi.string()
                .email({ tlds: { allow: false } })
        )
        .allow("")
        .label("CC"),
    bcc: Joi.array()
        .items(
            Joi.string()
                .email({ tlds: { allow: false } })
        )
        .allow("")
        .label("BCC"),
});
