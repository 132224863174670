import HttpService from "../../http";
import { assessments } from "../../endpoints";
import { IAssessmentResponse, IAssessmentsResponse } from "../../../interfaces/configuration/assessments";

const AssessmentService = () => {
	const { httpRequest } = HttpService();

	const addAssessment = async (payload: object): Promise<IAssessmentResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentResponse>(
			"POST",
			`${assessments}`,
			payload
		)
			.then(resolve)
			.catch(reject);
	});

	const updateAssessment = async (payload: object): Promise<IAssessmentResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentResponse>(
			"PUT",
			`${assessments}`,
			payload
		)
			.then(resolve)
			.catch(reject);
	});

	const getAssessment = async (query: object): Promise<IAssessmentResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentResponse>(
			"GET",
			`${assessments}`,
			{},
			query
		)
			.then(resolve)
			.catch(reject);
	});

	const getAssessments = async (search: object): Promise<IAssessmentsResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentsResponse>(
			"GET",
			`${assessments}/list`,
			{},
			search
		)
			.then(resolve)
			.catch(reject);
	});

	const deleteAssessments = async (payload: object): Promise<IAssessmentResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentResponse>(
			"DELETE",
			`${assessments}`,
			payload
		)
			.then(resolve)
			.catch(reject);
	});

	return { addAssessment, updateAssessment, getAssessments, deleteAssessments, getAssessment };
};

export { AssessmentService };
