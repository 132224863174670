

import { IQuartertargetResponse, IQuarterTargetSampleResponse } from "../../../interfaces/planner/quarter-target";
import { quarterTargetImport, quarterTargetSample } from "../../endpoints";
import HttpService from "../../http";

const QuarterTargetImportService = () => {

    const { httpRequest } = HttpService();
    const getQuarterTargetSample = async (search: object): Promise<IQuarterTargetSampleResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IQuarterTargetSampleResponse>(
                "GET",
                `${quarterTargetSample}`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });

    const importQuarterTarget = async (payload: object): Promise<IQuartertargetResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IQuartertargetResponse>(
                "POST",
                `${quarterTargetImport}`,
                payload
            )
                .then(resolve)
                .catch(reject);
        });

    return { getQuarterTargetSample, importQuarterTarget };

};

export { QuarterTargetImportService };