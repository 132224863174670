import { Autocomplete, Box, Chip, Grid, TextField, Tooltip } from "@mui/material";
import { Controller } from "react-hook-form";
import { ISkillItemDetail, ISkillItemFields } from "../../../../interfaces/skill-matrix/action";
import { FC, KeyboardEvent, useEffect } from "react";
import { Control, UseFormReset, FieldErrors, UseFormGetValues, UseFormSetValue, UseFormTrigger, UseFormResetField } from "react-hook-form";
import { capitalize } from "../../../../utilities/helper";
import { SkillItemCategoryService } from "../../../../services/skill-matrix/skill-items-categories";
import { useQuery } from "@tanstack/react-query";
import CustomLabel from "../../../../components/mui/custom-label";
import { useParams } from "react-router-dom";

interface OptionType {
    key: string;
    value: string;
    fullName: string;
}


interface IProps {
    currentTab: number;
    reset: UseFormReset<ISkillItemDetail>;
    control: Control<ISkillItemDetail>
    errors: FieldErrors<ISkillItemDetail>
    setSelectedEmployee: (value: string[]) => void;
    setSelectedCategory: (value: string) => void;
    enteredValue: string[];
    setEnteredValue: (value: string[]) => void; 
    selectedUser: string[];
    setSelectedUsers: (value: string[]) => void;
    getValues: UseFormGetValues<ISkillItemDetail>;
    setValue: UseFormSetValue<ISkillItemDetail>;
    trigger: UseFormTrigger<ISkillItemDetail>;
    resetField: UseFormResetField<ISkillItemDetail>;
}

const SkillItem: FC<IProps> = ({ control, errors, enteredValue, setEnteredValue, setSelectedCategory, getValues, setValue, trigger, resetField }) => {
    const { id } = useParams();
    const addChip = (e: KeyboardEvent<HTMLDivElement>, key: string) => {
        if (key === "skillItemsText") {
            const enteredSkillItem = getValues(key) ? String(getValues(key)) : "";

            if (enteredSkillItem.trim() !== "" && e.key === "Enter") {
                if (key === "skillItemsText") {
                    const prev = getValues("skillItems") ? getValues("skillItems") : [];
                    const updatedValues = [...prev, enteredSkillItem];
                    setEnteredValue(updatedValues);
                    setValue("skillItems", [...new Set(updatedValues)]);
                    resetField(key);
                }
            }
        }
    };

    useEffect(() => {
        setSelectedCategory(getValues("_skillCategory"));
    }, [getValues("_skillCategory")]);

    const removeChip = (value: string) => {
        let payload = getValues("skillItems");
        payload = payload.filter(skillItem => skillItem !== value);
        setValue("skillItems", payload);
        trigger("skillItems");
        const updatedValues = enteredValue.filter(item => item !== value);
        setEnteredValue(updatedValues);
    };

    const { getSkillItemCategories } = SkillItemCategoryService();
    const skillItemCategories = useQuery({
        queryKey: ["all-skill-item-categories"],
        queryFn: () => getSkillItemCategories({
            pagination: false,
            _skillParameter: id,
        })
    });

    const categoryOptions: OptionType[] = skillItemCategories && skillItemCategories.data && skillItemCategories.data.data && skillItemCategories.data.data.map((i) => ({
        key: i._id,
        value: i.name.length > 25 ? `${capitalize(i.name.slice(0, 25))}...` : capitalize(i.name),
        fullName: capitalize(i.name),
      })) || [];
      

    const fields: ISkillItemFields[] = [
        {
            label: "Category",
            name: "_skillCategory",
            type: "select",
            required: true,
            options: categoryOptions,
            displayFieldKey: "value",
            storeFieldKey: "key",
        },
        {
            type: "input",
            name: "skillItemsText",
            label: "Skill",
            placeholder: "Type skill name and press enter",
            required: true
        },
    ];

    return (
        <Box paddingTop="10px">
            <Box height="20vh" overflow="auto" paddingTop="10px">

                {/* Add Data  */}
                <Box marginBottom="20px">
                    <Grid container spacing={4}>
                        {
                            fields.map(field => {
                                if (field.type === "input") {
                                    return (
                                    <Grid key={field.label} item xs={12} md={6}>
                                        <Controller
                                            control={control}
                                            name={field.name}
                                            render={(prop) => <TextField
                                                label={<CustomLabel label={field.label} required={field?.required} />}
                                                className="disable-text"
                                                variant={"outlined"}
                                                size={"small"}
                                                placeholder={field.placeholder}
                                                error={errors[field.name] ? true : false}
                                                helperText={errors[field.name]?.message}
                                                {...prop.field}
                                                onChange={(e) => {
                                                    const newValue = e.target.value;
                                                        setEnteredValue([newValue]);
                                                        prop.field.onChange(e);
                                                }}
                                                inputProps={{maxLength:25}}
                                                onKeyUp={e => addChip(e, field.name)}
                                            />}
                                        />
                                        {
                                            <Box marginBottom="50px">
                                                
                                                {
                                                    field.name === "skillItemsText" && getValues("skillItems") &&
                                                    getValues("skillItems").map(skillItem => <Chip
                                                        key={skillItem}
                                                        label={skillItem}
                                                        onDelete={() => removeChip(skillItem)}
                                                        color="primary"
                                                        variant="outlined"
                                                        sx={{ margin: "5px" }}
                                                    />)
                                                }
                                            </Box>
                                        }
                                    </Grid>
                                    );
                                }else {
                                    return (<Grid key={field.label} item xs={12} md={6}>
                                        <Autocomplete
                                            options={categoryOptions}
                                            getOptionLabel={(option: OptionType) => option.value}
                                            renderOption={(props, option: OptionType) => (
                                                <li {...props}>
                                                <Tooltip title={option.fullName}>
                                                    <span>{option.value}</span>
                                                </Tooltip>
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                {...params}
                                                name={field.name}
                                                label={<CustomLabel label={field.label} required={field?.required} />}
                                                error={!!errors[field.name]}
                                                helperText={errors[field.name]?.message}
                                                disabled={field.disabled}
                                                />
                                            )}
                                            onChange={(_, newValue) => {
                                                if(newValue){
                                                    setSelectedCategory(newValue.key);
                                                    setValue(field.name, newValue.key);
                                                }
                                            }}
                                            value={categoryOptions.find(option => option.key === getValues(field.name))}
                                        />
                                      </Grid>
                                      );
                                }
                            })
                        }
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default SkillItem;