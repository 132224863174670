import Joi from "joi";
import { manyUserValidation } from "../../../../validations";
import { IRole, ITeam, IUser } from "../../../../interfaces";
import { useSelector } from "react-redux";
import { JobPositionService } from "../../../../services";
interface IData {
    // eslint-disable-next-line
    success: any[];
    failed: {
        [x: string]: string | number;
    }[];
}

const useUserValidateImport = () => {
    const teams = useSelector<{ team: { list: ITeam[] } }, ITeam[]>(state => state.team.list);
    const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(state => state.user.list);
    const roles = useSelector<{ role: { list: IRole[] } }, IRole[]>(state => state.role.list);
    const { getPartialJobPositions } = JobPositionService();

    const validateUserUpload = async (leads: Array<Array<string|number>>) =>
        // eslint-disable-next-line
        new Promise(async (resolve) => {
            let row = 2;
            const data: IData = {
                success: [],
                failed: [],
            };

            for await (const lead of leads) {
                if (!lead?.length) {
                    ++row;
                    continue;
                }

                const errors: {
                    [x: string]: string;
                } = {};
                const designation = await getPartialJobPositions({ name: lead[15] });


                let department = teams.find(t => t.name.toLowerCase() === String(lead[14])?.toLowerCase())?._id;
                department = department ? department : teams.find(t => t.name.toLowerCase() === "management".toLowerCase())?._id;

                let _role = roles.find(r => r?.name.toLowerCase() === String(lead[13])?.toLowerCase())?._id;
                _role = _role ? _role : roles.find(r => r?.name.toLowerCase() === "default".toLowerCase())?._id;

                let reportingTo = users.find(u => u.userId === lead[10])?._id;
                reportingTo = reportingTo ? reportingTo : users.find(u => u._role.role === "SUPERADMIN")?._id;

                if(designation && designation.data[0] && designation.data[0]._department && designation.data[0]._department._id ? designation.data[0]._department._id !== department : true) {
                    errors["department"] = "Designation and Department does not match";
                }

                const employee = {
                    employeeNumber: lead[1],
                    firstName: String(lead[2]).trim(),
                    lastName: String(lead[3]).trim(),
                    phone: {
                        country: "INDIA",
                        dialCode: "+91",
                        iso2: "IN",
                        number: String(lead[4])
                    },
                    email: lead[5],
                    gender: typeof lead[6] === "string" ? lead[6].toUpperCase() : "",
                    dob: lead[7],
                    location: { formattedAddress: lead[8] },
                    joinedDate: lead[9],
                    reportingTo,
                    legalEntry: lead[11],
                    businessUnit: lead[12],
                    _role,
                    department,
                    designation: designation.data[0]?._id,
                    dateOfLeaving: lead[16],
                    extensionNumber: {
                        country: "INDIA",
                        dialCode: "+91",
                        iso2: "IN",
                        number: String(lead[17])
                    },
                    status: "ACTIVE",
                    name: `${String(lead[2]).trim()} ${String(lead[3]).trim()}`,
                };

                const validated: Joi.ValidationResult<IUser> = manyUserValidation.validate(employee, { abortEarly: false });
                validated.error?.details.forEach(element => {
                    errors[String(element.context?.label)] = element.message;
                });

                const keys = Object.keys(errors);
                if (keys.length) {
                    data.failed.push({ ...errors, row });
                } else {
                    data.success.push(employee);
                }

                ++row;
            }

            resolve({
                data: data.success,
                errorMessage: data.failed,
            });

        });

    return { validateUserUpload };
};

export default useUserValidateImport;

