import { useEffect, useState } from "react";
import { IErrorResponse, IReview } from "../../../interfaces";
import { useQuery } from "@tanstack/react-query";
import { ReviewParameterService, ReviewService } from "../../../services";
import { IReviewField } from "../../../interfaces";
import { Box, Grid, TextField, MenuItem } from "@mui/material";
import { joiResolver } from "@hookform/resolvers/joi";
import { Controller, useForm } from "react-hook-form";
import { ReviewValidation } from "../../../validations";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomDialog from "../../../components/mui/dialog";
import Select from "../../../components/mui/select";
import { capitalize } from "../../../utilities/helper";

interface outletProps {
    reFetch: () => void
}

const ManageReview = () => {
    const { id, types } = useParams();
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const [searchParam] = useSearchParams();
    const outlet = useOutletContext<outletProps>();
    const hitQuery = (id === "new" || id === "view") ? false : true;
    const { getReview, updateReview, getReviews } = ReviewService();
    const { getReviewParameters } = ReviewParameterService();
    const reviews = useQuery({ queryFn: () => getReviews({}) });
    const review = useQuery({ queryKey: [hitQuery], queryFn: () => getReview({ _id: id }), enabled: hitQuery });
    const { handleSubmit, control, setValue, formState: { errors } } = useForm<IReview>({
        resolver: joiResolver(ReviewValidation),
        defaultValues: {
            status: ""
        }
    });

    const openedJob = reviews.data?.data.find(job => job._id === id);
    const openedJobTitle = openedJob?.jobId?.title;

    const parameters = useQuery({
        queryKey: ["parameters"],
        queryFn: () => getReviewParameters({ jobTitle: openedJobTitle }),
        enabled: !!openedJobTitle
    });

    const [state, setState] = useState<{
        inputParameter: string[];
        parameters: {
            [key: string]: string
        };
    }>({
        inputParameter: [],
        parameters: {}
    });

    useEffect(() => {
        if (id !== "new") {
            if (review.data?.data) {
                setState(prevState => (
                    {
                        ...prevState,
                        parameters: review.data?.data?.parameters ? review.data?.data?.parameters : {},
                    }
                ));
                setValue("status", review.data.data?.status);
            }
        }
    }, [id, review.data]);

    useEffect(() => {
        if (parameters.data?.data) {
            const params: string[] = [];
            parameters.data?.data?.map(parameter => {
                params.push(capitalize(parameter.name));
            });

            setState(prevState => (
                {
                    ...prevState,
                    inputParameter: params
                }
            ));
        }
    }, [parameters.data]);

    const handleInputField = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
        if (event?.target) {
            const { name, value } = event.target;
            setState({
                ...state,
                parameters: {
                    ...state.parameters,
                    [name]: value
                }
            });
        }
    };

    const onClose = () => {
        navigate({
            pathname: "/assessments/review/" + types,
            search: searchParam.toString()
        });
    };

    const onSubmit = async (data: IReview) => {
        try {
            if (id) {
                const payload = { ...data, parameters: state.parameters, _id: id };
                const update = await updateReview(id ? id : "", payload);
                snackbar(update.message, "info");
                onClose();
                outlet?.reFetch && outlet.reFetch();
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }
    };

    const fields: IReviewField[] = [
        {
            label: "Status*",
            name: "status",
            type: "select",
            children: [
                <MenuItem key={"none"} value="REVIEW SCHEDULED" disabled>Review Scheduled</MenuItem>,
                <MenuItem key={"REVIEW SELECTED"} value="REVIEW SELECTED">Review Selected</MenuItem>,
                <MenuItem key={"REVIEW SELECTED"} value="REVIEW HOLD">Review Hold</MenuItem>,
                <MenuItem key={"REVIEW REJECTED"} value="REVIEW REJECTED">Review Rejected</MenuItem>,

            ]
        },
    ];

    return (
        <Box>
            <CustomDialog
                title={"Edit Review"}
                isOpen={id ? true : false}
                onClose={onClose}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "input") {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={field.label}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            } else {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Select
                                        control={control}
                                        name={field.name}
                                        label={field.label}
                                        size="small"
                                        variant="outlined"
                                        error={errors[field.name] ? true : false}
                                        helperText={errors[field.name]?.message}
                                    >
                                        {field.children}
                                    </Select>
                                </Grid>
                                );
                            }
                        })
                    }

                    {
                        state.inputParameter.map(parameter => (<Grid key={parameter} item xs={12} md={6}>
                            <TextField
                                label={parameter}
                                className="disable-text"
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                    shrink: state.parameters[parameter?.toLowerCase()]?.length ? true : false
                                }}
                                onChange={e => handleInputField(e)}
                                name={parameter?.toLowerCase()}
                                value={state.parameters[parameter?.toLowerCase()] ? state.parameters[parameter?.toLowerCase()] : ""}
                            />

                        </Grid>
                        ))
                    }
                </Grid>
            </CustomDialog>
        </Box>
    );
};

export default ManageReview;