import { useState, SyntheticEvent, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import useResource from "../../hooks/useResource";
import useUser from "../../hooks/useUser";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface IState {
  redirectionLinks: string[]
}

const ReportLayout = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const { user } = useUser();
  const location = useLocation();
  const { resourceAllocate } = useResource();
  const [state, setState] = useState<IState>({
    redirectionLinks: []
  });

  useEffect(() => {
    const redirectionLinks = [...state.redirectionLinks];
    if (resourceAllocate("report-analytics")) {
      const links = ["overview", "jobs", "candidate", "team-performance", "cpd"];
      redirectionLinks.push(...links);
    }
    if (resourceAllocate("kpi.read")) {
      redirectionLinks.push("kpi");
    }
    if (resourceAllocate("cms-blog-analytics")) {
      redirectionLinks.push("cms");
    }

    setState(prev => ({
      ...prev,
      redirectionLinks
    }));
  }, [user]);

  useEffect(() => {
    const index = state.redirectionLinks.indexOf(location.pathname.split("/")[2]);
    setValue(index < 0 ? 0 : index);

  }, [location.pathname, state.redirectionLinks]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    navigate(state.redirectionLinks[newValue]);
  };

  return (
    <Box width="100%" >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {resourceAllocate("report-analytics") && <Tab className="tabs-space start-tab-space" label="Overview" {...a11yProps(0)} />}
          {resourceAllocate("report-analytics") && <Tab className="tabs-space" label="Jobs" {...a11yProps(1)} />}
          {resourceAllocate("report-analytics") && <Tab className="tabs-space" label="Candidates" {...a11yProps(2)} />}
          {resourceAllocate("report-analytics") && <Tab className="tabs-space" label="Team Performance" {...a11yProps(2)} />}
          {resourceAllocate("report-analytics") && <Tab className="tabs-space" label="CPD" {...a11yProps(2)} />}
          {resourceAllocate("kpi.read") && <Tab className="tabs-space" label="KPI" {...a11yProps(2)} />}
          {resourceAllocate("cms-blog-analytics") && <Tab className="tabs-space" label="CMS" {...a11yProps(2)} />}
        </Tabs>
      </Box>
      <Outlet />
    </Box>
  );
};

export default ReportLayout;
