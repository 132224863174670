import React from "react";
import { Grid, Card, CardContent, Typography, Chip } from "@mui/material";
import { capitalize, checkTestStatusBackgroundColor, checkTestStatusColor } from "../../../../../../../../utilities/helper";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { TestsService } from "../../../../../../../../services/configuration/tests";

const ResponseResult: React.FC = () => {
  const {testId, sessionId} = useParams();
  const {getPsyAssignedTest} = TestsService();
  const assignedTest = useQuery({
    queryKey: ["assigned-test"],
    queryFn: () => getPsyAssignedTest({ _test: testId, _session: sessionId })
  });

  return (
    <Grid container sx={{ marginBottom: "5rem", padding: "1rem" }}>
      <Grid item xs={2}>
        <Card variant="outlined" sx={{ border: 0 }}>
          <CardContent>
            <Typography variant="subtitle1" component="div">
              Overall Marks
            </Typography>
            <Typography variant="h3" component="div" color="success.main">
              <strong>{assignedTest?.data?.data?.test?.overAllScore.obtained ?? "--"}/{assignedTest?.data?.data?.test?.overAllScore.total ?? "--"}</strong>
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={6}>
        <Card variant="outlined" sx={{ border: 0 }}>
          <CardContent>
            <Typography color="textSecondary">Name: <span style={{ color: "black" }}>{capitalize(assignedTest?.data?.data?.lead?.name ?? "")}</span></Typography>
            <Typography color="textSecondary">
              Email: <span style={{ color: "black" }}>{assignedTest?.data?.data?.lead?.email ?? ""}</span>
            </Typography>
            <Typography color="textSecondary">Percentage: <span style={{ color: "black" }}>{assignedTest?.data?.data?.test?.overAllScore?.percentage ?? ""}%</span></Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          {
            (assignedTest &&
              assignedTest.data &&
              assignedTest.data.data &&
              assignedTest.data.data.test &&
              assignedTest.data.data.test.categoryWiseScore || []).map((cardNumber, index) => (
              <Grid item xs={4} key={index} sx={{ padding: "0.5rem" }}>
                <Card variant="outlined">
                  <CardContent
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <Typography variant="body1">{capitalize(cardNumber._category.name) ?? ""}</Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "2rem",
                      }}
                    >
                      <Typography
                        variant="body1"
                        component="div"
                        color="textSecondary"
                      >
                        <strong style={{ fontSize: "1.5em", color: "black" }}>
                          {cardNumber.obtained ?? "--"}
                        </strong>{" "}
                        /{cardNumber.total ?? "--"}
                      </Typography>
                              <Chip variant={"outlined"} sx={{ borderRadius: "4px", width: "110px", backgroundColor: checkTestStatusBackgroundColor(cardNumber.level) }} color={checkTestStatusColor(cardNumber.level)} label={capitalize(cardNumber.level)} />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))
          }
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ResponseResult;