import Joi from "joi";
import { required } from "../shared";

export const ReviewValidation = Joi.object({
    status: Joi.string()
        .required()
        .label("Status")
        .messages({
            "string.empty": required
        }),

});