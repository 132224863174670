import "./style.scss";
import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { ICandidateDetails, ICandidateActivity } from "../../../interfaces";
import { formatDate } from "../../../utilities/helper";
import TimelineIcon from "@mui/icons-material/Timeline";
import dayjs from "dayjs";

interface props {
  candidate: ICandidateDetails | undefined;
}

const Timeline: FC<props> = ({ candidate }) => {
  const groupByDate = (array: ICandidateActivity[] | undefined) =>
    array && array.reduce((acc: { [key: string]: ICandidateActivity[] }, obj) => {
      const istDate = new Date(obj.createdAt);
      const dateKey = formatDate(istDate);
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(obj);
      return acc;
    }, {});

  const activities = groupByDate(candidate?.activities);
  const dateIsSame = (date1: Date | string, date2: Date | string) => dayjs(date1).startOf("day").isSame(dayjs(date2).startOf("day"));

  return (
    <Box paddingTop="10px">
      <Box height="56vh" overflow="auto" paddingTop="10px">

    <div id="timeline" style={{ height: "calc(100% - 50px)", overflow: "scroll" }}>
      {activities && Object.keys(activities).length ? (
        Object.entries(activities).reverse().map(([date, activityArray], index) => (
          <Box key={index} display="flex">
            <div
              className={dateIsSame(date, new Date()) ? "primary-bg-color text-white" : "grey-color"}
            >
              <Typography
                className="center h-100"
                sx={{ width: "200px" }}
                variant="h5"
              >
                {formatDate(date)}
              </Typography>
            </div>
            <div className="timelineBox w-100">
              {[...activityArray].reverse().map((activity, idx) => (
                <div
                  key={idx}
                  className="timelineDescBox py-3 pl-5"
                >
                  <Typography className="time" sx={{ width: "150px" }} variant="body1">
                    {dayjs(activity.createdAt).format("LT")}
                  </Typography>
                  <Typography className="desc" variant="body2">
                    {activity.message}
                  </Typography>
                </div>
              ))}
            </div>
          </Box>
        ))
      ) : (
        <Box className="no-data center" height={"calc(100% - 30px)"}>
          <TimelineIcon fontSize="large" className="error-icon" />
          <Typography className="noData-title">No data to show!</Typography>
          <Typography className="noData-sub-title">
            Make an action to see the timeline here
          </Typography>
        </Box>
      )}
      </div>
      <Box paddingTop={3} className="center" justifyContent="start">
        <Box className="center" justifyContent="start">
          <div className="identify-box primary-bg-color" />
          <Typography className="ml-2" variant="body1">
            Today
          </Typography>
        </Box>
        <Box className="center" justifyContent="start" marginLeft="30px">
          <div className="identify-box grey-color" />
          <Typography className="ml-2" variant="body1">
            Earlier Dates
          </Typography>
        </Box>
      </Box>
    </Box>
    </Box>

  );
};

export default Timeline;