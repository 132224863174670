import Joi from "joi";
import { min_characters, required } from "../../shared";

export const scoreValidations = Joi.object({
    name:Joi.string()
        .min(3)
        .trim()
        .required()
        .label("Name")
        .messages({
            "string.empty":required,
            "string.min":min_characters

        }),
    description:Joi.string()
        .min(3)
        .trim()
        .required()
        .label("Description")
        .messages({
            "string.empty":required
        })
});