import HttpService from "../../http";
import { IFaqsResponse, IFaqResponse } from "../../../interfaces/careers/faq";
import { faq } from "../../endpoints";

const FaqService = () => {
    const { httpRequest } = HttpService();
    const addFaq = async (payload: object): Promise<IFaqsResponse> => new Promise((resolve, reject) => {
        httpRequest<IFaqsResponse>(
            "POST",
            `${faq}`,
            payload

        )
            .then(resolve)
            .catch(reject);
    });

    const getFaqs = async (search: object): Promise<IFaqsResponse> => new Promise((resolve, reject) => {
        httpRequest<IFaqsResponse>(
            "GET",
            `${faq}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getFaq = async (query: object): Promise<IFaqResponse> => new Promise((resolve, reject) => {
        httpRequest<IFaqResponse>(
            "GET",
            `${faq}`,
            {},
            query

        )
            .then(resolve)
            .catch(reject);
    });

    const updateFaq = async (id: string, payload: object): Promise<IFaqResponse> => new Promise((resolve, reject) => {
        httpRequest<IFaqResponse>(
            "PUT",
            `${faq}`,
            payload

        )
            .then(resolve)
            .catch(reject);
    });

    const deleteFaq = async (payload: object): Promise<IFaqsResponse> => new Promise((resolve, reject) => {
        httpRequest<IFaqsResponse>(
            "DELETE",
            `${faq}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { addFaq, getFaqs, getFaq, updateFaq, deleteFaq };

};

export { FaqService };