import HttpService from "../../http";
import { IReportDataResponse, IReportDataResponseSingle } from "../../../interfaces";
import { dailyReportRoles } from "../../endpoints";

const DailyReportService = () => {
  const { httpRequest } = HttpService();

	const getDailyReportRoles = async (query: object = {}): Promise<IReportDataResponse> => new Promise((resolve, reject) => {
		httpRequest<IReportDataResponse>(
			"GET",
			`${dailyReportRoles}/list`,
			{},
      query
		)
			.then(resolve)
			.catch(reject);
	});

	const updateStatusDailyReportRoles = async (payload: object = {}): Promise<IReportDataResponse> => new Promise((resolve, reject) => {
		httpRequest<IReportDataResponse>(
			"PUT",
			`${dailyReportRoles}/active`,
			payload,
      {}
		)
			.then(resolve)
			.catch(reject);
	});

	const updateDailyReportRoles = async (payload: object = {}): Promise<IReportDataResponse> => new Promise((resolve, reject) => {
		httpRequest<IReportDataResponse>(
			"PUT",
			`${dailyReportRoles}`,
			payload,
      {}
		)
			.then(resolve)
			.catch(reject);
	});

	const getDailyReportRole = async (query: object = {}): Promise<IReportDataResponseSingle> => new Promise((resolve, reject) => {
		httpRequest<IReportDataResponseSingle>(
			"GET",
			`${dailyReportRoles}`,
      {},
			query
		)
			.then(resolve)
			.catch(reject);
	});

  return { getDailyReportRoles, updateStatusDailyReportRoles, updateDailyReportRoles, getDailyReportRole };
};

export { DailyReportService };