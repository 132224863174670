import Joi from "joi";
import { required } from "../../shared";
export const CategoryValidation = Joi.object({
  name: Joi.string()
  .required()
  .label("Title")
  .messages({
      "string.empty":required
  }),
  _productId: Joi.string()
  .required()
  .label("Product ID")
  .messages({
      "string.empty":required
  }),
  categoryId: Joi.number()
  .required()
  .label("Category ID")
  .messages({
      "number.empty":required
  }),
});
