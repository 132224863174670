import Joi from "joi";
import { ICategory } from "../../../../interfaces/content/category";
import { CategoryValidation } from "../../../../validations/content/category";

interface IData {
    success: { data: { name: string }[] }[];
    failed: {
        [x: string]: string | number;
    }[];
}

const usevalidateCategory = () => {
    const validateCategory = async (leads: Array<Array<string | number>>) =>
     /*eslint-disable-next-line*/ 
    new Promise(async (resolve) => {
            let row = 2;

            const data: IData = {
                success: [],
                failed: [],
            };

            const categoryData: { name: string }[] = [];

            for await (const lead of leads) {
                if (!lead?.length) {
                    ++row;
                    continue;
                }

                const errors: { [x: string]: string } = {};
                const partsOfFunnel: { name: string } = {name:""}; // Initialize partsOfFunnel

                if (String(lead[1]).trim() === "") {
                    errors["name"] = "Name is required";
                } else {
                    partsOfFunnel["name"] = String(lead[1]).trim();
                }

                categoryData.push(partsOfFunnel);

                const validated: Joi.ValidationResult<ICategory> = CategoryValidation.validate(
                    partsOfFunnel,
                    { abortEarly: false }
                );

                validated.error?.details.forEach((element) => {
                    errors[String(element.context?.label)] = element.message;
                });

                const keys = Object.keys(errors);
                if (keys.length) {
                    data.failed.push({ ...errors, row });
                }
                ++row;
            }



            resolve({
                data: categoryData,
                errorMessage: data.failed,
            });
        });

    return { validateCategory };
};

export default usevalidateCategory;
