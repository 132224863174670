import HttpService from "../../http";
import { assessmentInstructions } from "../../endpoints";
import { IAssessmentInstructionsListResponse, IAssessmentInstructionsResponse, IAssessmentsInstructionPartialResponse } from "../../../interfaces/configuration/assessments-instructions";

const AssessmentInstructionService = () => {
	const { httpRequest } = HttpService();

	const addAssessmentInstructions = async (payload: object): Promise<IAssessmentInstructionsResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentInstructionsResponse>(
			"POST",
			`${assessmentInstructions}`,
			payload
		)
			.then(resolve)
			.catch(reject);
	});

	const updateAssessmentInstructions = async (payload: object): Promise<IAssessmentInstructionsResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentInstructionsResponse>(
			"PUT",
			`${assessmentInstructions}`,
			payload
		)
			.then(resolve)
			.catch(reject);
	});

	const getAssessmentInstruction = async (query: object): Promise<IAssessmentInstructionsResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentInstructionsResponse>(
			"GET",
			`${assessmentInstructions}`,
			{},
			query
		)
			.then(resolve)
			.catch(reject);
	});

	const getAssessmentsInstructions = async (search: object): Promise<IAssessmentInstructionsListResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentInstructionsListResponse>(
			"GET",
			`${assessmentInstructions}/list`,
			{},
			search
		)
			.then(resolve)
			.catch(reject);
	});

	const deleteAssessmentsInstructions = async (payload: object): Promise<IAssessmentInstructionsResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentInstructionsResponse>(
			"DELETE",
			`${assessmentInstructions}`,
			payload
		)
			.then(resolve)
			.catch(reject);
	});

	const getAssessmentInstructionPartial = async (): Promise<IAssessmentsInstructionPartialResponse> => new Promise((resolve, reject) => {
		httpRequest<IAssessmentsInstructionPartialResponse>(
			"GET",
			`${assessmentInstructions}/partial`,
			
		)
			.then(resolve)
			.catch(reject);
	});

	return { addAssessmentInstructions, getAssessmentInstruction, updateAssessmentInstructions, getAssessmentsInstructions, deleteAssessmentsInstructions, getAssessmentInstructionPartial };
};

export { AssessmentInstructionService };
