import { useState } from "react";
import { ISendDynamicTemplate, IWhatsappTemplate } from "../../../../interfaces";

interface IState {
    sendDynamicTemplate: ISendDynamicTemplate,
    acceptValues: string[]
}

const useTemplate = () => {
    const [state, setState] = useState<IState>({
        sendDynamicTemplate: {
          isSelectedTemplateDynamic: false,
          body: [],
          header: [],
          media: [],
          button: []
        },
        acceptValues: []
      });

    const isDynamicTemplate = (template: IWhatsappTemplate) => {
        const regex = /{{\d}}/g;
        let totalHeaderVariables: Array<string> = [];
        const sendDynamicTemplate: ISendDynamicTemplate = {
            isSelectedTemplateDynamic: false,
            body: [],
            header: [],
            media: [],
            button: []
        };

        const templateBodyText = getTemplateParams(template, "body");
        const templateHeaderText = getTemplateParams(template, "header");

        const totalBodyVariables = templateBodyText ? templateBodyText.match(regex) : [];
        if (templateHeaderText) {
            totalHeaderVariables = templateHeaderText.match(regex) || [];
        }

        let mediaType;
        if (template.configuration.components.some(item => ["DOCUMENT", "VIDEO", "IMAGE"].includes(item?.format || ""))) {
            mediaType = template.configuration.components.find(item => ["DOCUMENT", "VIDEO", "IMAGE"].includes(item?.format || ""))?.format;
            checkMediaType(mediaType);

            sendDynamicTemplate.media.push({
                title: mediaType || "title",
                type: "media",
                value: "",
                dynamicInput: ""
            });
        }

        if (template.configuration?.template_type === "Media") {
            sendDynamicTemplate.media.push({
                title: "IMAGE",
                type: "media",
                value: "",
                fileName: "",
                dynamicInput: ""
            });
            const acceptValues = [".png", ".jpg", ".jpeg"];
            setState(prev => ({ ...prev, acceptValues }));
        }

        if (totalBodyVariables && !totalBodyVariables.length && !totalHeaderVariables.length &&
            !sendDynamicTemplate.media.length && !getIsButtonDynamic(template)) {
            sendDynamicTemplate.isSelectedTemplateDynamic = false;

        } else {
            sendDynamicTemplate.isSelectedTemplateDynamic = true;

            if (Array.isArray(totalBodyVariables)) {
                totalBodyVariables.forEach((param, index) => {
                    sendDynamicTemplate.body.push({
                        title: `{{${index + 1}}}`,
                        type: "text",
                        value: "",
                        dynamicInput: ""
                    });
                });
            }

            if (totalHeaderVariables.length) {
                sendDynamicTemplate.isSelectedTemplateDynamic = true;
                totalHeaderVariables.forEach((param, index) => {
                    sendDynamicTemplate.header.push({
                        title: `{{${index + 1}}}`,
                        type: "text",
                        value: "",
                        dynamicInput: ""
                    });
                });
            }

            if (getIsButtonDynamic(template)) {
                const buttons = template.configuration.components.find(item => item.type === "BUTTONS");
                if (buttons?.buttons?.length) {
                    const websiteIndex = buttons.buttons.findIndex(item => item.type === "URL");
                    if (websiteIndex !== -1 && buttons.buttons[websiteIndex]?.example?.length) {
                        sendDynamicTemplate.button.push({
                            title: buttons.buttons[websiteIndex].url || "button title",
                            type: "text",
                            sub_type: "URL",
                            index: websiteIndex,
                            value: "",
                            dynamicInput: ""
                        });
                    }
                }
            }
        }

        return {
            ...state,
            sendDynamicTemplate:sendDynamicTemplate
        };
    };

    const getTemplateParams = (template: IWhatsappTemplate, type: string) => {
        switch (type) {
            case "body":
                return template.configuration.components.find(item => item.type === "BODY")?.text;
            case "header":
                return template.configuration.components.find(item => item?.type === "HEADER")?.text;
            default:
                return "";
        }
    };

    const checkMediaType = (type: string | undefined) => {
        let acceptValues: Array<string>;
        switch (type) {
            case "IMAGE":
                acceptValues = [".png", ".jpg", ".jpeg"];
                break;
            case "VIDEO":
                acceptValues = [".mp4", ".3gp", ".mov"];
                break;
            case "DOCUMENT":
                acceptValues = [".pdf", ".csv", ".docx", ".xlsx", ".xls", ".ppt", ".pptx"];
                break;
            case "AUDIO":
                acceptValues = [".aac", ".mpeg", ".amr", ".ogg"];
                break;
            default:
                acceptValues = [".png", ".jpg", ".jpeg"];
                break;
        }

        setState(prev => ({ ...prev, acceptValues }));
    };


    const getIsButtonDynamic = (template: IWhatsappTemplate) => {
        let isUrlButtonDynamic = false;
        const buttons = template.configuration.components.find(item => item.type === "BUTTONS");
        if (buttons?.buttons?.length) {
            const websiteIndex = buttons.buttons.findIndex(item => item.type === "URL");
            if (websiteIndex !== -1 && buttons?.buttons[websiteIndex]?.example?.length) {
                isUrlButtonDynamic = true;
            }
        }
        return isUrlButtonDynamic;
    };

    return { isDynamicTemplate };
};

export default useTemplate;