import Joi from "joi";
import { required } from "../../shared";

export const resourceMailValidation = Joi.object({
	to: Joi.string()
		.email({ tlds: { allow: false } })
		.label("Email")
		.required()
		.messages({
			"string.empty": required,
		}),
	templateAttachments: Joi.array()
		.items(
			Joi.object({
				filename: Joi.string()
					.required(),
				path: Joi.string()
					.required(),
				sizeInBytes: Joi.number()
					.required(),
				contentType: Joi.string()
					.required(),
			})
		).min(0),
	template: Joi.string()
		.label("Email Template")
		.allow(""),

	ccText: Joi.string()
		.label("CC")
		.allow(""),
	bccText: Joi.string()
		.label("CC")
		.allow(""),

	cc: Joi.array()
		.items(
			Joi.string()
				.email({ tlds: { allow: false } })
		)
		.allow("")
		.label("CC"),
	bcc: Joi.array()
		.items(
			Joi.string()
				.email({ tlds: { allow: false } })
		)
		.allow("")
		.label("BCC"),
	subject: Joi.string()
		.min(3)
		.trim()
		.required()
		.label("Subject")
		.messages({
			"string.empty": required,
		}),
	content: Joi.string()
		.required()
		.min(3)
		.trim()
		.label("Body")
		.messages({
			"string.empty": required,
		}),
});
