import Joi from "joi";
import { required } from "../../../shared";

export const vendorModeValidation = Joi.object({
    name: Joi.string()
        .required()
        .trim()
        .min(3)
        .max(40)
        .label("Name")
        .messages({
            "string.empty": required,
        }),
    paymentMode: Joi.string()
        .required()
        .valid("UPI", "CASH", "ACCOUNT")
        .label("Payment Mode")
        .messages({
            "string.empty": required,
        }),
    phoneNumber: Joi.string()
        .required()
        .min(8)
        .label("Mobile Number")
        .messages({
            "string.empty": required
        }),
    bankName: Joi.string()
        .label("Bank Name")
        .when("paymentMode", {
            is: Joi.valid("UPI", "ACCOUNT"),
            then: Joi.string()
            .required()
            .min(3)
            .max(30)
            .messages({
                "string.empty": required,
            }),
            otherwise: Joi.optional().allow("")
        }),
    upiId: Joi.string()
        .label("UPI ID")
        .when("paymentMode", {
            is: "UPI",
            then: Joi.string().required().messages({
                "string.empty": required,
            }),
            otherwise: Joi.optional().allow("")
        }),
    ifsc: Joi.string()
        .label("IFSC Code")
        .when("paymentMode", {
            is: "ACCOUNT",
            then: Joi.string().required().messages({
                "string.empty": required,
            }),
            otherwise: Joi.optional().allow("")
        }),
    accountNumber: Joi.string()
        .label("Account Number")
        .when("paymentMode", {
            is: "ACCOUNT",
            then: Joi.string()
                .required()
                .min(8)
                .max(18)
                .messages({
                "string.empty": required,
            }),
            otherwise: Joi.optional().allow("")
        }),
});
