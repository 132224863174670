import HttpService from "../../http";
import { IRolesResponse, IRoleResponse } from "../../../interfaces";
import { role } from "../../endpoints";

const RoleService = () => {
    const { httpRequest } = HttpService();
    const addRole = async (payload: object): Promise<IRolesResponse> => new Promise((resolve, reject) => {
        httpRequest<IRolesResponse>(
            "POST",
            `${role}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getPartialRoles = async (query = {}): Promise<IRolesResponse> => new Promise((resolve, reject) => {
        httpRequest<IRolesResponse>(
            "GET",
            `${role}/list/partial`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const getRoles = async (search: object): Promise<IRolesResponse> => new Promise((resolve, reject) => {
        httpRequest<IRolesResponse>(
            "GET",
            `${role}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getRole = async (query: object): Promise<IRoleResponse> => new Promise((resolve, reject) => {
        httpRequest<IRoleResponse>(
            "GET",
            `${role}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateRole = async (payload: object): Promise<IRoleResponse> => new Promise((resolve, reject) => {
        httpRequest<IRoleResponse>(
            "PUT",
            `${role}`,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteRole = async (query: object): Promise<IRolesResponse> => new Promise((resolve, reject) => {
        httpRequest<IRolesResponse>(
            "DELETE",
            `${role}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });
    return { addRole, getPartialRoles, getRoles, getRole, updateRole, deleteRole };
};

export { RoleService };
