import { Box, Grid, MenuItem, SelectChangeEvent, TextField } from "@mui/material";
import CustomDialog from "../../../../../components/mui/dialog";
import { Controller, useForm } from "react-hook-form";
import { IErrorResponse } from "../../../../../interfaces/shared/response";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { FC, useEffect, useState } from "react";
import { capitalize } from "../../../../../utilities/helper";
import { useQuery } from "@tanstack/react-query";
import Select from "../../../../../components/mui/select";
import CustomLabel from "../../../../../components/mui/custom-label";
import { joiResolver } from "@hookform/resolvers/joi";
import { IVendorPaymentMode, IVendorPaymentModeField } from "../../../../../interfaces/vendor/payment-mode";
import { VendorPaymentModeService } from "../../../../../services/vendor/payment-mode";
import MobileNumber from "../../../../../components/mui/mobile-number";
import { vendorModeValidation } from "../../../../../validations/vendor/manage-vendor/payment-modes";

interface props {
  paymentMode: string;
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void
}

const ManageVendorPaymentModeDetail: FC<props> = ({ paymentMode, isOpen, onClose, refetch}) => {
    const { updatePaymentMode, getPaymentMode } = VendorPaymentModeService();
    const { snackbar } = useSnackbar();
    const { handleSubmit, control, setValue, watch, formState: { errors } } = useForm<IVendorPaymentMode>({
      resolver: joiResolver(vendorModeValidation),
      defaultValues: {
        paymentMode: "",
        name: "",
        phoneNumber: "",
        upiId: "",
        bankName: "",
        accountNumber: "",
        ifsc: ""
      }
    });

  const paymentModeData = useQuery({
    queryKey: ["mode", paymentMode],
    queryFn: () =>
      getPaymentMode({
        _id: paymentMode
      }),
    enabled:  !!paymentMode && isOpen,
  });

  useEffect(() => {
    if(paymentModeData?.data?.data){
      const data = paymentModeData.data.data;
      setValue("name", capitalize(data.name));
      setValue("paymentMode", data.paymentMode);
      setValue("upiId", data.upiId);
      setValue("bankName", data.bankName);
      setValue("accountNumber", data.accountNumber);
      setValue("ifsc", data.ifsc);
      setValue("phoneNumber", data.mobileNumber?.number);
    }
  }, [paymentModeData?.data?.data, isOpen]);

  const onSubmit = async (data: IVendorPaymentMode) => {
    try {
      const payload = {
          ...data,
          _id: paymentModeData?.data?.data?._id,
          mobileNumber: {
            ...stateNo.phoneNumber,
            number: data.phoneNumber,
          },
      };
      delete payload.phoneNumber;
      delete payload?._vendor;
      if(watch("paymentMode") === "CASH"){
        delete payload.upiId;
        delete payload.accountNumber;
        delete payload.bankName;
        delete payload.ifsc;
      }else if(watch("paymentMode") === "UPI"){
        delete payload.ifsc;
        delete payload.accountNumber;
      }else{
        delete payload.upiId;
      }
      const user = await updatePaymentMode(payload);
      snackbar(user.message, "info");
      refetch();
      onClose();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log("error in manage contract", error);
    }
  };

  const [stateNo, setStateNo] = useState({
    phoneNumber: {
        country: "INDIA",
        dialCode: "+91",
        iso2: "IN"
    },
});

const onNumberChange = (e: SelectChangeEvent<string>) => {
  const { name, value } = e.target;
  const data = value.split(":");

  setStateNo(prev => ({
      ...prev,
      [name]: {
          country: data[0],
          iso2: data[1],
          dialCode: data[2]
      }
  }));
};

const fields: IVendorPaymentModeField[] = [
  {
    type: "input",
    name: "name",
    label: "Name",
    placeholder: "Type name here",
    required: true
  },
  {
    type: "select",
    name: "paymentMode",
    label: "Payment Mode",
    required: true,
    children: [
        <MenuItem key="ACCOUNT" value="ACCOUNT" >Account</MenuItem>,
        <MenuItem key="UPI" value="UPI" >UPI</MenuItem>,
        <MenuItem key="CASH" value="CASH" >Cash</MenuItem>
    ]
  },
  {
    type: "input",
    name: "upiId",
    label: "UPI ID",
    placeholder: "Type upi id here",
    required: true
  },
  {
    type: "input",
    name: "bankName",
    label: "Bank Name",
    placeholder: "Type bank name here",
    required: true
  },
  {
    type: "input",
    name: "ifsc",
    label: "IFSC",
    placeholder: "Type ifsc here",
    required: true
  },
  {
    type: "input",
    name: "accountNumber",
    label: "Account Number",
    placeholder: "Type account number here",
    required: true
  },
  {
    type: "mobile-number",
    name: "phoneNumber",
    label: "Mobile Number",
    required: true
  },
];

const filteredFields = fields.filter((field) => {
  if (watch("paymentMode") === "ACCOUNT") {
    return !["upiId"].includes(field.name);
  }else if(watch("paymentMode") === "UPI"){
    return !["ifsc", "accountNumber"].includes(field.name);
  }else if(watch("paymentMode") === "CASH"){
    return !["ifsc", "bankName", "accountNumber", "upiId"].includes(field.name);
  }else{
    return ["name", "paymentMode", "phoneNumber"].includes(field.name);
  }
});


  return (
    <Box>
      <CustomDialog
        title={"Edit Payment Mode Details"}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4}>
          {
                    filteredFields.map(field => {
                        if (field.type === "input") {
                          return (<Grid key={field.label} item xs={6}>
                            <Controller
                              control={control}
                              name={field.name}
                              render={(prop) => <TextField
                                label={<CustomLabel label={field.label} required={field?.required} />}
                                className="disable-text"
                                variant={"outlined"}
                                size={"small"}
                                placeholder={field.placeholder}
                                error={!!errors[field.name]}
                                helperText={errors[field.name]?.message}
                                {...prop.field}
                              />}
                            />
                          </Grid>
                          );
                        }  else if (field.type === "mobile-number") {
                          const numberState = "phoneNumber";
                          return <Grid key={field.label} item xs={6}>
                              <Controller
                                  control={control}
                                  name={field.name}
                                  render={(prop) => <MobileNumber
                                      key={field.label}
                                      className="disable-text"
                                      NumberFieldLabel={<CustomLabel label={field.label} required={field?.required} />}
                                      dialCodeValue={`${stateNo[numberState].country}:${stateNo[numberState].iso2}:${stateNo[numberState].dialCode}`}
                                      dialCodeName={field.name}
                                      onChange={onNumberChange}
                                      error={!!errors[field.name]}
                                      helperText={errors[field.name]?.message}
                                      other={prop.field}
                                      disabled={field?.disabled}
                                  />}
                              />
                          </Grid>;
                      }else{
                          return (<Grid key={field.label} item xs={6} >
                            <Select
                              control={control}
                              className="disable-text"
                              name={field.name}
                              label={<CustomLabel label={field.label} required={field?.required} />}
                              error={!!errors[field.name]}
                              helperText={errors[field.name]?.message}
                            >
                              {field.children}
                            </Select>
                          </Grid>
                          );
                        } })}
        </Grid>
      </CustomDialog>
    </Box>
  );
};

ManageVendorPaymentModeDetail.defaultProps = {
  isOpen: false
};

export default ManageVendorPaymentModeDetail;
