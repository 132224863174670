import Joi from "joi";
import { required } from "../../shared";

export const myProfileValidation = Joi.object({
  firstName: Joi.string()
    .max(30)
    .trim()
    .label("First Name")
    .required()
    .messages({
      "string.empty": required,
    }),  
    lastName: Joi.string()
    .max(30)
    .trim()
    .required()
    .label("Last Name"),
  role: Joi.string()
    .max(30)
    .trim()
    .allow("")
    .label("Authority"),
  department: Joi.string()
    .max(30)
    .trim()
    .allow("")
    .label("Teams"),
  number: Joi.string()
    .required()
    .min(10)
    .max(10)
    .label("Primary Contact Number")
    .messages({
      "string.empty": required
    }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .label("Email")
    .messages({
      "string.empty": required
    })
    .required(),
});
