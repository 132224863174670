import { IPartsOfFunnelResponse, IPartsOfFunnelsResponse } from "../../../interfaces/content/parts-of-funnel";
import { partsOfFunnel, partsOfFunnelList } from "../../endpoints";
import HttpService from "../../http";

const PartsOfFunnelService = () => {
    const {httpRequest} = HttpService();

    const getContentPartsOfFunnels = async (search:object):Promise<IPartsOfFunnelsResponse> => 
    new Promise((resolve,reject) => {
        httpRequest<IPartsOfFunnelsResponse>(
            "GET",
            `${partsOfFunnelList}`,
            {},
            search
            )
            .then(resolve)
            .catch(reject);
    });

    const addContentPartsOfFunnel = async (payload : object) :Promise<IPartsOfFunnelResponse>=> new Promise((resolve,reject) => {
        httpRequest<IPartsOfFunnelResponse>(
        "POST",
        `${partsOfFunnel}`,
        payload )
        .then(resolve)
        .catch(reject);
    });

    const importContentPartsOfFunnel = async (payload : object) :Promise<IPartsOfFunnelResponse>=> new Promise((resolve,reject) => {
      httpRequest<IPartsOfFunnelResponse>(
      "POST",
      `${partsOfFunnel}/import`,
      payload )
      .then(resolve)
      .catch(reject);
  });
    

    const getContentPartsOfFunnel = async (query:object) : Promise<IPartsOfFunnelResponse> =>  new Promise((resolve,reject) => {
        httpRequest<IPartsOfFunnelResponse>(
            "GET",
            `${partsOfFunnel}`,
            {},
            query
        )
        .then(resolve)
        .catch(reject);
    });

    const getContentPartsOfFunnelPartial = async (query:object) : Promise<IPartsOfFunnelResponse> =>  new Promise((resolve,reject) => {
      httpRequest<IPartsOfFunnelResponse>(
          "GET",
          `${partsOfFunnel}/partial`,
          {},
          query
      )
      .then(resolve)
      .catch(reject);
  });

    const deleteContentPartsOfFunnel = async(payload:object):Promise<IPartsOfFunnelResponse> =>
    new Promise((resolve,reject) => {
      httpRequest<IPartsOfFunnelResponse>("DELETE",`${partsOfFunnel}`,payload)
      .then(resolve)
      .catch(reject);
    });
      
    const updateContentPartsOfFunnel = async(payload:object):Promise<IPartsOfFunnelResponse> =>
      new Promise((resolve,reject) => {
        httpRequest<IPartsOfFunnelResponse>("PUT",`${partsOfFunnel}`,payload)
        .then(resolve)
        .catch(reject);
      });




    return {getContentPartsOfFunnels ,addContentPartsOfFunnel,getContentPartsOfFunnelPartial,importContentPartsOfFunnel,getContentPartsOfFunnel,deleteContentPartsOfFunnel,updateContentPartsOfFunnel};
};

export default PartsOfFunnelService;