import HttpService from "../http";
import { skillMatrix } from "../endpoints";
import { IExportSKillMatrixResponse, ISkillMatrixsResponse } from "../../interfaces";
import { ISkillItemResponse, IAddSkillMatrixsResponse, IAddSkillMatrixResponse, ISkillMatrixRequest, ISkillItemSampleResponse, IEditSkillItemResponse } from "../../interfaces/skill-matrix/action";
import { IEditSkillMatrixsResponse ,IEditRecordResponse, ISkillMatrixItemResponse, ISkillMatrixCategoryItemResponse} from "../../interfaces/skill-matrix/edit-skill-matrix";
import { IEditSkillNameResponse ,IDeleteSkillResponse, IDeleteEmployeeResponse} from "../../interfaces/skill-matrix/edit-skill-matrix/edit-skill";

const SkillMatrixService = () => {

    const { httpRequest } = HttpService();
    const getSkillMatrixs = async (search: object): Promise<ISkillMatrixsResponse> => new Promise((resolve, reject) => {
        httpRequest<ISkillMatrixsResponse>(
            "GET",
            `${skillMatrix}/parameter/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const addSkill = async (payload: object): Promise<IAddSkillMatrixsResponse> => new Promise((resolve, reject) => {
        httpRequest<IAddSkillMatrixsResponse>(
            "POST",
            `${skillMatrix}/parameter`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const addEmployee = async (payload: object): Promise<ISkillMatrixsResponse> => new Promise((resolve, reject) => {
        httpRequest<ISkillMatrixsResponse>(
            "POST",
            `${skillMatrix}/add-employee`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    //actions

    const addItemAndEmployee = async (payload: object): Promise<ISkillItemResponse> => new Promise((resolve, reject) => {
        httpRequest<ISkillItemResponse>(
            "PUT",
            `${skillMatrix}/parameter/add`,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });
    const updateSkill = async(payload:object): Promise <IEditSkillNameResponse> => new Promise((resolve,reject) =>{
        httpRequest<IEditSkillNameResponse>(
            "PUT",
            `${skillMatrix}/parameter`,
            payload
        )
        .then(resolve)
        .catch(reject);
    });

    


    const getSkillItem = async (query: object): Promise<IAddSkillMatrixResponse> => new Promise((resolve, reject) => {
        httpRequest<IAddSkillMatrixResponse>(
            "GET",
            `${skillMatrix}/parameter`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const getSkillLable = async (query: object): Promise<IEditSkillItemResponse> => new Promise((resolve, reject) => {
        httpRequest<IEditSkillItemResponse>(
            "GET",
            `${skillMatrix}/item`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });


    const updateSkillLable = async (id: string, payload: object): Promise<IAddSkillMatrixResponse> => new Promise((resolve, reject) => {
        httpRequest<IAddSkillMatrixResponse>(
            "PUT",
            `${skillMatrix}/item`,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const getSkillMatrix = async (query: object): Promise<IEditSkillMatrixsResponse> => new Promise((resolve, reject) => {
        httpRequest<IEditSkillMatrixsResponse>(
            "GET",
            `${skillMatrix}/parameter/fetch-user-mappings`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const getSkillMatrixItems = async (query: object): Promise<ISkillMatrixItemResponse> => new Promise((resolve, reject) => {
        httpRequest<ISkillMatrixItemResponse>(
            "GET",
            `${skillMatrix}/item/list`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const getSkillMatrixCategoryItems = async (query: object): Promise<ISkillMatrixCategoryItemResponse> => new Promise((resolve, reject) => {
        httpRequest<ISkillMatrixCategoryItemResponse>(
            "GET",
            `${skillMatrix}/item/list/partial`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const UpdateSkillMatrix = async (query: object): Promise<IEditRecordResponse> => new Promise((resolve, reject) => {
        httpRequest<IEditRecordResponse>(
            "GET",
            `${skillMatrix}/user-mapping`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });
  const deleteSkill = async (query:object) :Promise<IDeleteSkillResponse> => new Promise((resolve,reject) => {
    httpRequest<IDeleteSkillResponse>(
        "DELETE",
        `${skillMatrix}/parameter`,
        {},
        query
    )
    .then(resolve)
    .catch(reject);
});

 const deleteSkillItem = async (query:object) :Promise<IDeleteSkillResponse> => new Promise((resolve,reject) => {

    httpRequest<IDeleteSkillResponse>(
        "DELETE",
        `${skillMatrix}/item`,
        {},
        query
    )
    .then(resolve)
    .catch(reject);
 });

    const deleteSkillMatrix = async (query:object) : Promise<IDeleteEmployeeResponse> => new Promise((resolve,reject) => {

        httpRequest<IDeleteEmployeeResponse>(
            "DELETE",
            `${skillMatrix}/user-mapping`,
            {},
            query
        )
        .then(resolve)
        .catch(reject);
    });


    const addSkillMatrixs = async (payload: object): Promise<IAddSkillMatrixsResponse> => new Promise((resolve, reject) => {
        httpRequest<IAddSkillMatrixsResponse>(
            "POST",
            `${skillMatrix}/parameter/import`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });


    const exportSkillMatrixs = async (payload:object): Promise<IExportSKillMatrixResponse> => new Promise((resolve,reject) => {
        httpRequest<IExportSKillMatrixResponse>(
            "POST",
            `${skillMatrix}/user-mapping/export`,
            payload
        )
        .then(resolve)
        .catch(reject);
    });

    const addSkillMatrixRequest = async (payload:object):Promise<ISkillMatrixRequest> => new Promise((resolve,reject) => {
        httpRequest<ISkillMatrixRequest>(
           "POST",
           `${skillMatrix}/request`,
           payload 
        )
        .then(resolve)
        .catch(reject);
    });

    const updateSkillMatrixRequest = async (payload:object) :Promise<ISkillMatrixRequest> => new Promise((resolve,reject) => {
        httpRequest<ISkillMatrixRequest>(
           "PUT",
           `${skillMatrix}/user-mapping`,
           payload 
        )
        .then(resolve)
        .catch(reject);
    });

    const getSkillItemSample = async (search: object): Promise<ISkillItemSampleResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<ISkillItemSampleResponse>(
                "GET",
                `${skillMatrix}/parameter/sample`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });

    return { getSkillMatrixCategoryItems, getSkillMatrixItems, getSkillMatrixs,exportSkillMatrixs,deleteSkillMatrix,deleteSkillItem,deleteSkill,updateSkill,updateSkillMatrixRequest, addSkill, addEmployee, addItemAndEmployee, getSkillItem, getSkillLable, updateSkillLable, getSkillMatrix, UpdateSkillMatrix, addSkillMatrixs,addSkillMatrixRequest, getSkillItemSample};

};

export { SkillMatrixService };