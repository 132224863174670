import { IOrganisationUsersResponse } from "../../interfaces/organisation-tree";
import HttpService from "../../services/http";
import { user } from "../endpoints";

const OrganisationDataService = () => {
    const { httpRequest } = HttpService();

    const getOrganisationTreeData = async (): Promise<IOrganisationUsersResponse> => new Promise((resolve, reject) => {
        httpRequest<IOrganisationUsersResponse>(
            "GET",
            `${user}/get-users-heirarchy`
        )
            .then(resolve)
            .catch(reject);
    });

    return { getOrganisationTreeData };
};

export { OrganisationDataService };