import HttpService from "../../http";
import { IAutomationResponse, IAutomationsResponse } from "../../../interfaces/configuration/automation";
const AutomationService = () => {
    const {httpRequest} = HttpService();

    const addAutomation = async (payload: object):Promise<IAutomationsResponse> => new Promise((resolve,reject) => {
        httpRequest<IAutomationsResponse>(
            "POST",
            "configuration/automation",
            payload
        )
        .then(resolve)
        .catch(reject);
    }); 

    const getAutomations = async (search:object):Promise<IAutomationsResponse> => new Promise((resolve,reject) => {
        httpRequest<IAutomationsResponse>(
            "GET",
            "configuration/automation/list",
            {},
            search
        )
        .then(resolve)
        .catch(reject);
    });

    const getAutomation = async(query:object):Promise<IAutomationResponse> => new Promise ((resolve,reject) => {
        httpRequest<IAutomationResponse>(
            "GET",
            "configuration/automation",
            {},
            query
        )
        .then(resolve)
        .catch(reject);
    });

    const updateAutomation = async(payload:object):Promise<IAutomationResponse> => new Promise((resolve,reject) => {
        httpRequest<IAutomationResponse>(
            "PUT",
            "configuration/automation",
            payload
        )
        .then(resolve)
        .catch(reject);
    });

    const deleteAutomation = async (payload:object) :Promise<IAutomationsResponse> => new Promise((resolve,reject) => {
        httpRequest<IAutomationsResponse>(
            "DELETE",
            "configuration/automation",
            payload
        )
        .then(resolve)
        .catch(reject);
    });

    const updateActiveStatus = async(payload:object):Promise<IAutomationResponse> => new Promise((resolve,reject) => {
        httpRequest<IAutomationResponse>(
            "PUT",
            "configuration/automation/status",
            payload
        )
        .then(resolve)
        .catch(reject);
    });

    const updateAutomationPosition = async (payload: object): Promise<IAutomationResponse> => new Promise((resolve, reject) => {
        httpRequest<IAutomationResponse>(
            "PUT",
            "configuration/automation/position",
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const updateAutomationPositionByButton = async (payload: object): Promise<IAutomationResponse> => new Promise((resolve, reject) => {
        httpRequest<IAutomationResponse>(
            "PUT",
            "configuration/automation/position-button",
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    return {addAutomation,getAutomations,updateAutomation,getAutomation,deleteAutomation, updateActiveStatus, updateAutomationPosition, updateAutomationPositionByButton };
};

export default AutomationService;