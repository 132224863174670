import Joi from "joi";
import { IPartsOfFunnel } from "../../../../interfaces/content/parts-of-funnel";
import { partsOfFunnelValidations } from "../../../../validations/content/parts-of-funnel";

interface IData {
    success: { partsOfFunnels: { name: string }[] }[];
    failed: {
        [x: string]: string | number;
    }[];
}

const usevalidatePartsOfFunnel = () => {
    const validatePartsOfFunnel = async (leads: Array<Array<string | number>>) =>
     /*eslint-disable-next-line*/ 
    new Promise(async (resolve) => {
            let row = 2;

            const data: IData = {
                success: [],
                failed: [],
            };

            const partsOfFunnels: { name: string }[] = [];

            for await (const lead of leads) {
                if (!lead?.length) {
                    ++row;
                    continue;
                }

                const errors: { [x: string]: string } = {};
                const partsOfFunnel: { name: string } = {name:""}; // Initialize partsOfFunnel

                if (String(lead[1]).trim() === "") {
                    errors["name"] = "Name is required";
                } else {
                    partsOfFunnel["name"] = String(lead[1]).trim();
                }

                partsOfFunnels.push(partsOfFunnel);

                const validated: Joi.ValidationResult<IPartsOfFunnel> = partsOfFunnelValidations.validate(
                    partsOfFunnel,
                    { abortEarly: false }
                );

                validated.error?.details.forEach((element) => {
                    errors[String(element.context?.label)] = element.message;
                });

                const keys = Object.keys(errors);
                if (keys.length) {
                    data.failed.push({ ...errors, row });
                }
                ++row;
            }

            resolve({
                data: partsOfFunnels,
                errorMessage: data.failed,
            });
        });

    return { validatePartsOfFunnel };
};

export default usevalidatePartsOfFunnel;
