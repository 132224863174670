import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { EngagementService } from "../../../../services";
import { engagementValidation } from "../../../../validations";
import {
  IEngagement,
  IEngagementField,
  IErrorResponse,
} from "../../../../interfaces";
import {
  useParams,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import Select from "../../../../components/mui/select";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import { capitalize } from "../../../../utilities/helper";
interface outletProps {
  reFetch: () => void;
  refetchEngagementTypes: () => void;
}

const ManageEngagementType = () => {
  const { id } = useParams();
  const [searchParam] = useSearchParams();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const hitQuery = id === "new" || id === "view" ? false : true;
  const navigate = useNavigate();
  const { getEngagement, addEngagement, updateEngagement } =
    EngagementService();
  const engagements = useQuery({
    queryKey: [hitQuery],
    queryFn: () => getEngagement({ _id: id }),
    enabled: hitQuery,
  });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<IEngagement>({
    resolver: joiResolver(engagementValidation),
    defaultValues: {
      name: "",
    },
  });

  useEffect(() => {
    if (id !== "new") {
      if (engagements.data?.data) {
        setValue("name", capitalize(engagements.data.data?.name) || "");
      }
    }
  }, [id, engagements.data]);

  const onSubmit = async (data: IEngagement) => {
    try {
      if (id === "new") {
        const payload = { ...data };
        const add = await addEngagement(payload);
        snackbar(add.message, "info");
        navigate({
          pathname: "/configurations/engagement-type",
          search: searchParam.toString(),
        });
        outlet?.reFetch && outlet.reFetch();
        outlet?.refetchEngagementTypes && outlet.refetchEngagementTypes();
      } else {
        const payload = { ...data, _id: id };
        const update = await updateEngagement(id ? id : "", payload);
        snackbar(update.message, "info");
        navigate({
          pathname: "/configurations/engagement-type",
          search: searchParam.toString(),
        });
        outlet?.reFetch && outlet.reFetch();
        outlet?.refetchEngagementTypes && outlet.refetchEngagementTypes();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log(error);
    }
  };

  const onClose = () => {
    navigate({
      pathname: "/configurations/engagement-type",
      search: searchParam.toString(),
    });
  };

  const fields: IEngagementField[] = [
    {
      label: "Name*",
      name: "name",
      type: "input",
      placeholder: "Type job here",
    },
  ];

  return (
    <Box>
      <CustomDialog
        size="sm"
        title={id !== "new" ? "Edit Engagement Type" : "Add Engagement Type"}
        isOpen={id ? true : false}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4}>
          {fields.map((field) => {
            if (field.type === "input") {
              return (
                <Grid key={field.label} item xs={12}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => (
                      <TextField
                        label={field.label}
                        className="disable-text"
                        variant="outlined"
                        size="small"
                        placeholder={field.placeholder}
                        error={errors[field.name] ? true : false}
                        helperText={errors[field.name]?.message}
                        {...prop.field}
                      />
                    )}
                  />
                </Grid>
              );
            } else {
              return (
                <Grid key={field.label} item xs={12}>
                  <Select
                    control={control}
                    name={field.name}
                    label={field.label}
                    size="small"
                    variant="outlined"
                    error={errors[field.name] ? true : false}
                    helperText={errors[field.name]?.message}
                  >
                    {field.children}
                  </Select>
                </Grid>
              );
            }
          })}
        </Grid>
      </CustomDialog>
    </Box>
  );
};

export default ManageEngagementType;
