
import { Box, Card, Typography } from "@mui/material";
import { FC } from "react";
import "./style.scss";

interface props {
    index: number;
    title: string;
    subtitle: string;
    count: string | number | undefined;
    isMoreSubtitle: boolean;
    primarySubTitle?: string;
    count1?: number |  undefined;
    secondarySubTitle?: string;
    count2?: number | undefined;
}

const AnalyticsCard: FC<props> = ({ index, title, subtitle, count, isMoreSubtitle, primarySubTitle, count1, secondarySubTitle, count2 }) => {
    const showSubtitle = (isDisplay: boolean) => {
        const subtitle = document.getElementById(`sub-title-${index}`) as HTMLElement;
        if (isDisplay) {
            subtitle.style.display = "block";
        } else {
            subtitle.style.display = "none";
        }
    };

    return (
        <Box onMouseEnter={() => showSubtitle(true)} onMouseLeave={() => showSubtitle(false)}>
            <Card className="card">
                <div>
                    <Typography sx={{fontWeight:700}} variant="h6">
                        {title}
                    </Typography>
                    <Typography id={`sub-title-${index}`} className={"hideSubtitle subtitle"} gutterBottom>
                        {subtitle}
                    </Typography>
                </div>
                {
                    isMoreSubtitle
                        ?
                        <Box className="cardCount">
                            <Box className="center" justifyContent="space-between">
                                <div>
                                    <p className="subtitle">
                                        {primarySubTitle}
                                    </p>
                                    <div>
                                        <p className="cardCount">
                                            {count1}
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <p className="subtitle">
                                        {secondarySubTitle}
                                    </p>
                                    <div>
                                        <p className="cardCount">
                                            {count2}
                                        </p>
                                    </div>
                                </div>
                            </Box>
                        </Box>
                        :
                        <p className="cardCount">
                            {count}
                        </p>
                }
            </Card>
        </Box>
    );
};

export default AnalyticsCard;