import Joi from "joi";
import { min_characters, required } from "../../shared";

export const KeywordValidations = Joi.object({
    form:Joi.array()
    .items({
      name:Joi.string()
      .trim()
      .custom((value, helpers) => {
        if (!value.trim()) {
          return helpers.error("string.empty");
        }
        return value;
      })
      .min(3)
      .required()
      .label("Name")
      .messages({
        "string.empty":required,
        "string.min":min_characters
      }),

      _category:Joi.string()
      .required()
      .label("Category")
      .messages({
        "string.empty":required
      }),
      children3: Joi.array(),
      _subCategory:Joi.array()
      .items(Joi.string().required())
      .required()
      .label("Sub Category")
      .messages({
        "array.empty":required
      }),
      _product: Joi.string()
      .required()
      .label("Product")
      .messages({
        "string.empty":required
      })


    })
   

    .sparse()
        .optional()

});