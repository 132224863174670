import { SyntheticEvent, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { ResourceRoleService } from "../../../../services";
import { Autocomplete, Box, Chip, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { IErrorResponse, IResourceRoleField, IResourceRoleForm, IRole } from "../../../../interfaces";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { resourceRoleValidation } from "../../../../validations";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import CustomLabel from "../../../../components/mui/custom-label";
import { useSelector } from "react-redux";
interface outletProps {
    reFetch: () => void
}

const ManageResourceRole = () => {
    const { id } = useParams();
    const [searchParam] = useSearchParams();
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();
    const hitQuery = !(id === "new" || id === "view");
    const navigate = useNavigate();
    const { updateResourceRole, getResourceRole } = ResourceRoleService();

    const roles = useSelector<{ role: { list: IRole[] } }, IRole[]>(
        (state) => state.role.list
      );
    const resourceRole = useQuery({ queryKey: [hitQuery], queryFn: () => getResourceRole({ _id: id }), enabled: hitQuery });
    const { handleSubmit, control,getValues ,trigger, setValue, formState: { errors } } = useForm<IResourceRoleForm>({
        resolver: joiResolver(resourceRoleValidation),
        defaultValues: {
            _roles: [],
        }
    });
    const onChangeAutoComplete = (
        event: SyntheticEvent<Element, Event>,
        newValues: IRole[],
      ) => {
        const values = (getValues("_roles") || []) as string[];
    
        const newValuesArray = Array.isArray(newValues) ? newValues : [newValues];
        const uniqueValues = newValuesArray
          .map((value) => value?._id)
          .filter((id) => !values.includes(id));
    
        const resultArray = [...values, ...uniqueValues];
        setValue("_roles", resultArray);
        trigger("_roles");
      };

    useEffect(() => {
        if (id !== "new" && resourceRole.data?.data) {
                const roleIds = resourceRole.data.data._roles.map((role: IRole) => role._id);
                setValue("_roles", roleIds);
        }
    }, [id, resourceRole.data]);


    const onSubmit = async (data: IResourceRoleForm) => {
        try {
            if (id) {
                const add = await updateResourceRole({_id: id, ...data});
                snackbar(add.message, "info");
                navigate({
                    pathname: "/configurations/resource-request-roles",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }

    };

    const onClose = () => {
        navigate({
            pathname: "/configurations/resource-request-roles",
            search: searchParam.toString()
        });
    };

    const fields: IResourceRoleField[] = [
        {
            label: "Roles*",
            name: "_roles",
            type: "select",
            options: roles,
        },
    ];



    return (
        <Box>
            <CustomDialog
                size='md'
                title={id !== "new" ? "Edit Resource Role" : "Add Resource Role"}
                isOpen={!!id}
                onClose={onClose}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "input") {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={field.label}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            } else {
                                return (
                                  <Grid key={field.label} item xs={12} md={6}>
                                    <Controller
                                      control={control}
                                      name={field.name}
                                      render={(prop) => (
                                        <>
                                          <Autocomplete
                                            className="disable-text"
                                           options={roles}
                                           getOptionLabel={(option) => option.name}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                variant="outlined"
                                                size="small"
                                                label={
                                                  <CustomLabel
                                                    label={field && field.label}
                                                    required={field && field.required}
                                                  />
                                                }
                                                placeholder={field.placeholder}
                                                error={!!errors[field.name]}
                                                helperText={errors[field.name]?.message}
                                              />
                                            )}
                                            {...prop.field}
                                            value={[]}
                                            onChange={(event, newValues) => {
                                                onChangeAutoComplete(event, newValues);
                                              }}
                                            multiple
                                          />
                                          {((getValues(field.name)) || []).map(
                                            (item) => (
                                              <Chip
                                                key={item}
                                                label={
                                                  (roles || []).find(
                                                      (role) => role._id === item
                                                    )?.name
                                                  }
                                                color="primary"
                                                onDelete={() => {
                                                  setValue(
                                                    field.name,
                                                    (getValues(field.name))?.filter(
                                                      (value) => value !== item
                                                    )
                                                  );
                                                }}
                                                variant="outlined"
                                                sx={{ margin: "5px" }}
                                              />
                                            ))}
                                        </>
                                      )}
                                    />
                                  </Grid>
                                );
                              }
                        })
                    }
                </Grid>
            </CustomDialog>
        </Box>
    );
};

export default ManageResourceRole;