import { useState, MouseEvent } from "react";
import { settingService } from "../../../services";
import { IChangeProfile } from "../../../interfaces";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { IChangePasswordField } from "../../../interfaces";
import { changePasswordValidation } from "../../../validations";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Box, Grid, TextField, InputAdornment, IconButton, Button } from "@mui/material";
import useSnackbar from "../../../hooks/useSnackbar";
import { IErrorResponse } from "../../../interfaces";

interface outletProps {
  reFetch: () => void
}

const ChangePassword = () => {
  const { snackbar } = useSnackbar();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { changePassword } = settingService();
  const { control, reset, handleSubmit, formState: { errors } } = useForm<IChangeProfile>({
    resolver: joiResolver(changePasswordValidation),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    }
  });

  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });

  const handleClickShowPassword = (fieldName: keyof typeof showPassword) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmit = async (data: IChangeProfile) => {
    try {
      const payload = { ...data };
      const changePass = await changePassword(payload);
      snackbar(changePass.message, "info");
      navigate("/settings/profile");
      outlet?.reFetch && outlet.reFetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log(error);
    }

  };

  const fields: IChangePasswordField[] = [
    {
      label: "Current Password*",
      name: "oldPassword",
      type: "input",
    },
    {
      label: "New Password*",
      name: "newPassword",
      type: "input",
    },
    {
      label: "Confirm Password*",
      name: "confirmNewPassword",
      type: "input",
    },

  ];
  return (
    <Box paddingTop="50px" >
      <form className="center" onSubmit={handleSubmit(onSubmit)} >
        <Box overflow="auto" className="password-box">
          <Grid container spacing={4} >
            {
              fields.map(field => {
                if (field.type === "input" && field.name === "oldPassword" || field.name === "newPassword" || field.name === "confirmNewPassword") {
                  return (<Grid key={field.label} item xs={12} md={12}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => <TextField
                        label={field.label}
                        className="disable-text"
                        variant={"outlined"}
                        size={"small"}
                        error={errors[field.name] ? true : false}
                        helperText={errors[field.name]?.message}
                        type={showPassword[field.name] ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleClickShowPassword(field.name)}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword[field.name] ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        autoComplete="current-password"
                        {...prop.field}
                      />}
                    />
                  </Grid>
                  );
                }
              })
            }
          </Grid>
          <Box className="actions-btn" paddingTop="30px" textAlign="end">
            <Button variant="outlined" color="error" onClick={() => reset()}>Clear All</Button>
            <Button className="ml-2" type="submit">SAVE</Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default ChangePassword;