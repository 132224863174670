import { KeyboardEvent, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { OfferedService } from "../../../services";
import { CandidateService } from "../../../services";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { newJoineeValidation } from "../../../validations";
import { validateEmail } from "../../../validations/shared";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, Chip, TextField, Grid, Switch } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { capitalize, formatMobileNumber, formatDate } from "../../../utilities/helper";
import { IOfferedNewJoineeField, IOfferedNewJoinee, INewJoineeRow, ICandidate } from "../../../interfaces";
import dayjs from "dayjs";
import useUser from "../../../hooks/useUser";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomTable from "../../../components/mui/table";
import CustomDialog from "../../../components/mui/dialog";
import EmailSuggestion from "../../../components/mui/email-suggestion";
import CustomTypography from "../../../components/mui/max-length-limit";
interface outletProps {
    reFetch: () => void
}

const NewJoinee = () => {
    let rows: INewJoineeRow[] = [];
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { user } = useUser();
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();
    const { getNewJoinee } = CandidateService();
    const { addNewJoinees } = OfferedService();
    const [doj, setDoj] = useState<{ [key: string]: string }>({});
    const [email, setEmail] = useState<{ [key: string]: string }>({});
    const [systemRequired, setSystemRequired] = useState<{ [key: string]: string }>({});
    const { control, getValues, setValue, resetField, watch, trigger, handleSubmit, formState: { errors } } = useForm<IOfferedNewJoinee>({
        resolver: joiResolver(newJoineeValidation),
        defaultValues: {
            to: "",
            doj: "",
            ccText: "",
        }
    });

    const getNewJoinees = useQuery({
        queryKey: ["allOffereds"],
        queryFn: () => getNewJoinee({ _ids: searchParams.get("ids") ? JSON.parse(String(searchParams.get("ids"))) : [] },
        )
    });

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name && ["to", "ccText"].includes(name)) {
                trigger(name);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (user) {
            const cc: string[] = getValues("cc") || [];
            cc.push(user?.email);
            setValue("cc", [...new Set(cc)]);
            trigger("cc");
        }
    }, [user]);

    const addEmail = (e: KeyboardEvent<HTMLDivElement>, key: string) => {
        let payload: string[] = [];
        if (key === "ccText") {
            const err = validateEmail(getValues(key));
            if (err.error) {
                return;
            }
            const enteredEmail = getValues(key) ? String(getValues(key)) : "";

            if (enteredEmail.trim() !== "" && e.key === "Enter") {
                if (key === "ccText") {
                    const prev = getValues("cc") ? getValues("cc") : [];
                    payload = [...prev, enteredEmail];
                    setValue("cc", [...new Set(payload)]);
                    resetField(key);
                }
            }
        }
    };

    const handleEmailSelect = (email: string, name: string) => {
        let payload: string[] = [];
        if (name === "to") {
            setValue("to", email);
        } else if (["ccText", "bccText"].includes(name)) {
            if (name === "ccText") {
                const prev = getValues("cc") ? getValues("cc") : [];
                payload = [...prev, email];
                setValue("cc", [...new Set(payload)]);
                resetField(name);

            }
        }
    };

    const removeEmail = (key: string, value: string) => {
        if (key === "ccText") {
            let payload = getValues("cc");
            payload = payload.filter(email => email !== value);
            setValue("cc", payload);
            trigger("cc");
        }
    };

    // eslint-disable-next-line
    const selectDate = (value: any, name: string) => {
        const date = value && dayjs(value).toString() !== "Invalid Date" ? dayjs(value)?.toISOString() : undefined;
        const keyExist = name === "doj";
        if (keyExist) {
            setValue(name, date);
            trigger(name);
        }
    };

    const handleSystemRequired = (candidateId: string, newValue: string) => {
        setSystemRequired(prevState => ({
            ...prevState,
            [candidateId]: newValue,
        }));
    };

    const handleEmail = (candidateId: string, newEmail: string) => {
        setEmail(prevState => ({
            ...prevState,
            [candidateId]: newEmail,
        }));
    };

    const handleDoj = (candidateId: string, newDoj: string) => {
        setDoj(prevState => ({
            ...prevState,
            [candidateId]: newDoj,
        }));
    };

    const onSubmit = async (data: IOfferedNewJoinee) => {
        const candidatesData = getNewJoinees.data?.data || [];
        const candidatesPayload = candidatesData.map(candidate => ({
            name: capitalize(candidate?.name) || "",
            typeOfLead: capitalize(candidate?.typeOfLead) || "",
            jobTitle: candidate?.jobId?.title || "",
            location: candidate?.location || "",
            mobileNumber: formatMobileNumber(candidate?.mobileNumber) || "",
            email: email[candidate._id] || "",
            systemRequired: capitalize(systemRequired[candidate._id]) || "NO",
            doj: formatDate(doj[candidate._id]),
        }));
        const payload = {
            ...data,
            candidates: candidatesPayload,
        };
        delete payload.doj;
        delete payload.ccText;
        delete payload.systemRequired;
        try {
            const add = await addNewJoinees(payload);
            snackbar(add.message, "info");
            navigate({
                pathname: "/offered",
                search: searchParams.toString()
            });
            outlet?.reFetch && outlet.reFetch();
        } catch (error) {
            console.log(error);
        }
    };

    const onClose = () => {
        navigate({
            pathname: "/offered",
            search: searchParams.toString()
        });
    };

    const fields: IOfferedNewJoineeField[] = [
        {
            type: "input",
            name: "to",
            label: "To*",
            placeholder: "Type email address"
        },
        {
            type: "input",
            name: "ccText",
            label: "CC",
            placeholder: "Type email address and press enter"
        },
    ];

    const columns = [
        {
            id: "name",
            label: "Name"
        },
        {
            id: "email",
            label: "Email",
        },
        {
            id: "doj",
            label: "DOJ",
        },
        {
            id: "systemRequired",
            label: "Sytem Required"
        },
    ];

    const createRow = (candidate: ICandidate) => ({
        name: (
            <CustomTypography
                limit={30}
                label={capitalize(candidate.name)}
            />
        ),
        email: (
            <TextField
                className="disable-text"
                variant="outlined"
                size="small"
                placeholder="Type email address"
                value={email[candidate._id] || ""}
                onChange={(e) => handleEmail(candidate._id, e.target.value)}
                onKeyUp={e => addEmail(e, "email")}
                error={errors["email"] ? true : false}
                helperText={errors["email"]?.message}
            />
        ),
        doj: (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                    onChange={(e) => {
                        const newDoj = e ? dayjs(e).toISOString() : "";
                        handleDoj(candidate._id, newDoj);
                        selectDate(e, "doj");
                    }}
                    value={doj[candidate._id] ? dayjs(doj[candidate._id]) : null}
                    slotProps={{
                        textField: {
                            error: errors["doj"] ? true : false,
                            helperText: errors["doj"]?.message
                        }
                    }}
                    format="LL"
                />
            </LocalizationProvider>
        ),
        systemRequired: (
            <Switch
                checked={systemRequired[candidate._id] === "YES"}
                onChange={(e) => {
                    const newValue = e.target.checked ? "YES" : "NO";
                    handleSystemRequired(candidate._id, newValue);
                }}
            />
        ),
    });

    if (getNewJoinees.data?.data.length) {
        rows = getNewJoinees.data?.data.map((candidate) => createRow(candidate));
    }

    return (
        <Box>
            <CustomDialog
                title={"New Joinees"}
                isOpen={true}
                onClose={onClose}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "input" && field.name === "to" || field.name === "ccText") {
                                return (<Grid key={field.label} item xs={12} md={field.width ? field.width : 6}>
                                    <EmailSuggestion
                                        control={control}
                                        label={field.label}
                                        name={field.name}
                                        value={getValues(field.name)}
                                        placeholder={field.placeholder}
                                        error={errors[field.name] ? true : false}
                                        helperText={errors[field.name]?.message}
                                        onEmailSelect={handleEmailSelect}
                                        onKeyUp={addEmail}
                                    />
                                    {
                                        <Box>
                                            {
                                                field.name === "ccText" && getValues("cc") &&
                                                getValues("cc").map(email => <Chip
                                                    key={email}
                                                    label={email}
                                                    onDelete={() => removeEmail(field.name, email)}
                                                    color="primary"
                                                    variant="outlined"
                                                    sx={{ margin: "5px" }}
                                                />)
                                            }
                                        </Box>
                                    }
                                </Grid>
                                );
                            } else if (field.type === "input") {
                                return (<Grid key={field.label} item xs={12} md={field.width ? field.width : 6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={field.label}
                                            className="disable-text"
                                            variant={"outlined"}
                                            size={"small"}
                                            placeholder={field.placeholder}
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message}
                                            {...prop.field}

                                        />}
                                    />
                                </Grid>
                                );
                            }
                        })
                    }
                </Grid>

                <Box marginTop="20px">
                    <CustomTable
                        columns={columns}
                        rows={rows}
                        height="calc(100vh - 400px)"
                    />
                </Box>

            </CustomDialog>
        </Box>
    );
};
export default NewJoinee;