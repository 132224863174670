import HttpService from "../http";
import { IRequestedSkillsResponse, IRequestedSkillResponse } from "../../interfaces";
import { skillMatrix } from "../endpoints";

const RequestedSkillService = () => {

    const { httpRequest } = HttpService();
    const getRequestedSkills = async (search: object): Promise<IRequestedSkillsResponse> => new Promise((resolve, reject) => {
        httpRequest<IRequestedSkillsResponse>(
            "GET",  
            `${skillMatrix}/request/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getRequestedSkill = async (query: object): Promise<IRequestedSkillResponse> => new Promise((resolve, reject) => {
        httpRequest<IRequestedSkillResponse>(
            "GET",
            `${skillMatrix}/request`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateStatus = async (payload: object): Promise<IRequestedSkillResponse> => new Promise((resolve, reject) => {
        httpRequest<IRequestedSkillResponse>(
            "PUT",
            `${skillMatrix}/request/approve`,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    return { getRequestedSkills, getRequestedSkill, updateStatus };

};

export { RequestedSkillService };