import { ICertificationColumnDefinition, IEducationColumnDefinition, IProjectColumnDefinition, IWorkExperienceColumnDefinition } from "../../../../interfaces/resume-parser";

export const workExperienceColumns: IWorkExperienceColumnDefinition[] = [
  {
    id: "company",
    label: "Company Name"
  },

  {
    id: "jobTitle",
    label: "Job Title"
  },

  {
    id: "duration",
    label: "Duration"
  },
  
  {
    id: "achievements",
    label: "Achievement"
  },

  {
    id: "relevant",
    label: "Relevant"
  }
];

export const educationColumns: IEducationColumnDefinition[] = [
  {
    id: "qualification",
    label: "Qualification"
  },

  {
    id: "specialization",
    label: "Specialization"
  },

  {
    id: "university",
    label: "University/College"
  },

  {
    id: "score",
    label: "Score"
  }
];

export const certificationColumns: ICertificationColumnDefinition[] = [

  {
    id: "name",
    label: "Certificate Name"
  },

  {
    id: "platform",
    label: "Platform"
  },

  {
    id: "year",
    label: "year"
  }
];

export const projectColumns: IProjectColumnDefinition[] = [
  {
    id: "name",
    label: "Poject Name"
  },

  {
    id: "portal",
    label: "Portal Name"
  },
  
  {
    id: "year",
    label: "Year"
  }
];
