import { MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import { IJobTitle } from "../../../../interfaces";

export const getresourceFields = () => {
  const jobTitles = useSelector<{ jobTitle: { list: IJobTitle[] } }, IJobTitle[]>(
    (state) => state.jobTitle.list
  );

  return {
  
  softwareFields: [
    {
      label: "Type of Resource",
      name: "requestType",
      type: "select",
      placeholder: "Type of resource",
      children: [
        <MenuItem key={"human"} value="human">
          Human Resource
        </MenuItem>,
        <MenuItem key={"hardware"} value="hardware">
          Hardware
        </MenuItem>,
        <MenuItem key={"software"} value="software">
          Software
        </MenuItem>
      ],
      required: false,
      error: "type"
    },
    {
      label: "Software Name",
      name: "softwareDetails.name",
      type: "input",
      placeholder: "Enter software name",
      required: true,
      error: "name"
    },
    {
      label: "Purchase Type",
      name: "softwareDetails.purchaseType",
      type: "select",
      placeholder: "enter purchase type",
      required: false,
      children: [
        <MenuItem key={"in house"} value="in house">
          In-House
        </MenuItem>,
        <MenuItem key={"out source"} value="out source">
          Out-Source
        </MenuItem>
      ],
      error: "resourceType"
    },
    {
      label: "Currency",
      name: "softwareDetails.payment.currency",
      type: "select",
      placeholder: "Enter payment currency",
      children: [
        <MenuItem key={"rupees"} value="rupees">
          ₹ Rupees
        </MenuItem>,
        <MenuItem key={"dollar"} value="dollar">
          $ US Dollar
        </MenuItem>
      ],
      required: false,
      error: "payment"
    },


    {
      label: "Cost",
      name: "softwareDetails.payment.amount",
      type: "input",
      placeholder: "Enter payment amount ",
      required: false,
      error: "payment"
    },
    {
      label: "Required In (Weeks)",
      name: "priorityDuration",
      type: "input",
      placeholder: "Enter priority duration",
      required: false,
      error: "priorityDuration"
    },
    {
      label: "Required for (Months)",
      name: "softwareDetails.durationOfUse",
      type: "input",
      placeholder: "Enter duration of use",
      required: false,
      error: "durationOfUse"
    },
    {
      label: "Reason",
      name: "justification",
      type: "input",
      placeholder: "Provide reason",
      required: true,
      error: "justification"
    },

    {
      label: "Notify",
      name: "ccText",
      type: "emailInput",
      placeholder: "Enter cc list",
      error: "ccText",
      required: true
    }
  ],
  hardwareFields: [
    {
      label: "Request type",
      name: "requestType",
      type: "select",
      placeholder: "Type of Resource",
      children: [
        <MenuItem key={"human"} value="human">
          Human Resource
        </MenuItem>,
        <MenuItem key={"hardware"} value="hardware">
          Hardware
        </MenuItem>,
        <MenuItem key={"software"} value="software">
          Software
        </MenuItem>
      ],
      required: false,
      error: "type"
    },
    {
      label: "Hardware Name",
      name: "hardwareDetails.name",
      type: "input",
      placeholder: "Enter Hardware Name",
      required: true,
      error: "name"
    },
    {
      label: "Purchase Type",
      name: "hardwareDetails.purchaseType",
      type: "select",
      placeholder: "Enter purchase type",
      required: false,
      children: [
        <MenuItem key={"in house"} value="in house">
          In-House
        </MenuItem>,
        <MenuItem key={"out source"} value="out source">
          Out-Source
        </MenuItem>
      ],
      error: "resourceType"
    },


    {
      label: "Currency",
      name: "hardwareDetails.payment.currency",
      type: "select",
      placeholder: "Enter Currency",
      children: [
        <MenuItem key={"rupees"} value="rupees">
          ₹ Rupees
        </MenuItem>,
        <MenuItem key={"dollar"} value="dollar">
          $ US Dollar
        </MenuItem>
      ],
      required: false,
      error: "payment"
    },


    {
      label: "Cost",
      name: "hardwareDetails.payment.amount",
      type: "input",
      placeholder: "Enter payment amount",
      required: false,
      error: "payment"
    },
    {
      label: "Required In (Weeks)",
      name: "priorityDuration",
      type: "input",
      placeholder: "Enter priority duration",
      required: false,
      error: "priorityDuration"
    },
    {
      label: "Required for (Months)",
      name: "hardwareDetails.durationOfUse",
      type: "input",
      placeholder: "Enter duration of use",
      required: false,
      error: "durationOfUse"
    },
    {
      label: "Reason",
      name: "justification",
      type: "input",
      placeholder: "Provide reason",
      required: true,
      error: "justification"
    },

    {
      label: "Notify",
      name: "ccText",
      type: "emailInput",
      placeholder: "Enter CC List",
      error: "ccText",
      required: true
    }
  ],
  teamMemberFields: [
    {
      label: "Type of resource",
      name: "requestType",
      type: "select",
      placeholder: "Type of resource",
      children: [
        <MenuItem key={"human"} value="human">
          Human Resource
        </MenuItem>,
        <MenuItem key={"hardware"} value="hardware">
          Hardware
        </MenuItem>,
        <MenuItem key={"software"} value="software">
          Software
        </MenuItem>
      ],
      required: false,
      error: "type"
    },
    {
      label: "Job Title",
      name: "jobTitle",
      type: "searchSelect",
      placeholder: "Enter job title",
      children: jobTitles.map((jobTitle) => (
        {
          key: jobTitle.name,
          value: jobTitle._id
        }
      )),
      displayFieldKey: "key",
      storeFieldKey: "value",
      required: true,
      error: "type"
    },
    {
      label: "Engagement Type",
      name: "engagementType",
      type: "select",
      placeholder: "Enter engagement type",
      required: true,
      children: [
        <MenuItem key={"full time"} value="full time">
          Full Time
        </MenuItem>,
        <MenuItem key={"intern"} value="intern">
          Intern
        </MenuItem>,
        <MenuItem key={"consultant"} value="consultant">
          Consultant
        </MenuItem>
      ],
      error: "engagementType"
    },
    {
      label: "Number of Openings",
      name: "openings",
      type: "input",
      placeholder: "Enter number of openings",
      required: true,
      error: "openings"
    },
    {
      label: "Work Location",
      name: "workLocation",
      type: "select",
      placeholder: "Enter work location",
      required: true,
      children: [
        <MenuItem key={"remote"} value="remote">
          WFH
        </MenuItem>,
        <MenuItem key={"in office"} value="in office">
          WFO
        </MenuItem>
      ]
    },
    {
      label: "Experience Min (Months)",
      name: "minExp",
      type: "input",
      placeholder: "Enter minimum experience required",
      required: true,
      error: "minExp"
    },
    {
      label: "Experience Max (Months)",
      name: "maxExp",
      type: "input",
      placeholder: "Enter maximum experience required",
      required: true,
      error: "maxExp"
    },
    {
      label: "Required in (Weeks)",
      name: "priorityDuration",
      type: "input",
      placeholder: "Enter priority duration",
      required: false,
      error: "priorityDuration"
    },
    {
      label: "Required for (Months)",
      name: "durationNeeded",
      type: "input",
      placeholder: "Enter duration needed",
      required: false,
      error: "durationOfUse"
    },
    {
      label: "Resource Type",
      name: "resourceType",
      type: "select",
      placeholder: "Enter resource type",
      required: true,
      children: [
        <MenuItem key={"in house"} value="in house">
          In-House
        </MenuItem>,
        <MenuItem key={"out source"} value="out source">
          Out-Source
        </MenuItem>
      ]
    },
    
    {
      label: "Skills Required",
      name: "skillsetRequired",
      type: "input",
      placeholder: "Enter skill required",
      required: false,
      error: "skillsetRequired"
    },

    {
      label: "Reason",
      name: "justification",
      type: "input",
      placeholder: "Provide reason",
      required: true,
      error: "justification"
    },

    {
      label: "Notify",
      name: "ccText",
      type: "emailInput",
      placeholder: "Enter CC List",
      error: "ccText",
      required: true
    }
  ]
  };
};


