import CategoryService from "../../../../services/content/category";
import goalsService from "../../../../services/content/goals";
import KeywordService from "../../../../services/content/keyword";
import PartsOfFunnelService from "../../../../services/content/parts-of-funnel";
import ProjectsService from "../../../../services/content/projects-products";
import SubCategoryService from "../../../../services/content/sub-category";
import TypesOfContentService from "../../../../services/content/type-of-content";
import plannerService from "../../../../services/planner";


const usevalidateBlog = () => {
    const { getContentCategoryPartial } = CategoryService();
    const { getContentSubCategoryPartial } = SubCategoryService();
    const { getContentGoalPartial } = goalsService();
    const { getContentProjectPartial } = ProjectsService();
    const { getQuarterPartial } = plannerService();
    const {getContentKeywordPartial} = KeywordService();
    const {getContentPartsOfFunnelPartial} = PartsOfFunnelService();
    const {getContentTypesOfContentPartial} = TypesOfContentService();


    // eslint-disable-next-line
    const validateBlog = async (leads: any[]) =>
        // eslint-disable-next-line
        new Promise(async (resolve) => {
            const mandatoryFields = ["title", "description", "slug", "_category", "_subCategory", "goals","partOfFunnel","primaryKeywords","secondaryKeywords","_product","_quarter","typeOfContent","suggestedSubHeadings","blogBannerButton","blogBannerButtonText"];
            const payload = [];
            const error = [];
            let row = 2;

            for await (const lead of leads) {
                if (!lead?.length) {
                    ++row;
                    continue;
                }

                // eslint-disable-next-line
                const data: any = {};
                const secKeywords = [];
                // eslint-disable-next-line
                const errMessage: any = {};

                if (typeof lead[0] === "string") {
                    data.title = lead[0];
                } else {
                    if (lead[0] === undefined) {
                        errMessage["Blog Title"] = "Blog Title is required";
                    } else {
                        errMessage["Blog Title"] = "Blog Title must be string";
                    }
                }

                if (typeof lead[1] === "string") {
                    data.description = lead[1];
                } else {
                    if (lead[1] === undefined) {
                        errMessage["Description"] = "Description is required";
                    }
                    else {
                        errMessage["Description"] = "Description must be string";
                    }
                }

                if (typeof lead[2] === "string") {
                    data.slug = lead[2];
                } else {
                    if (lead[2] === undefined) {
                        errMessage["Slug"] = "Slug is required";
                    }
                    else {
                        errMessage["Slug"] = "Slug must be string";
                    }
                }

 
                if (typeof lead[3] === "string") {
                    try {
                        const project = await getContentProjectPartial({
                            search: lead[3].trim(),
                        });
                        if (project.data?._id) {

                            data._product = project.data?._id;

                        } else {
                            errMessage["Product"] = "Please enter a valid product name";
                        }
                    } catch (error) {
                        errMessage["Product"] = "Please enter a valid product name";
                    }
                } else {
                    if (lead[3] !== undefined) {
                        errMessage["Product"] = "Please enter a valid product name";
                    }
                }

                if (typeof lead[4] === "string") {
                    try {
                        const project = await getQuarterPartial({
                            search: lead[4].trim(),
                            _product: data._product
                        });
                        if (project.data?._id) {

                            data._quarter = project.data?._id;

                        } else {
                            errMessage["Quarter"] = "Please enter a valid quarter name";
                        }
                    } catch (error) {
                        errMessage["Quarter"] = "Please enter a valid quarter name";
                    }
                } else {
                    if (lead[4] !== undefined) {
                        errMessage["Quarter"] = "Please enter a valid quarter name";
                    }
                }

                if (typeof lead[5] === "string") {
                    try {
                        const category = await getContentCategoryPartial({
                            search: lead[5].trim(),
                        });
                        if (category.data?._id) {

                            data._category = category.data?._id;

                        } else {
                            errMessage["Category"] = "Please enter a valid category name";
                        }
                    } catch (error) {
                        errMessage["Category"] = "Please enter a valid category name";
                    }
                } else {
                    if (lead[5] !== undefined) {
                        errMessage["Category"] = "Please enter a valid category name";
                    }
                }

                if (typeof lead[6] === "string") {
                    try {
                        const subCategory = await getContentSubCategoryPartial({
                            search: lead[6].trim(),
                        });
                        if (subCategory.data?._id) {

                            data._subCategory = subCategory.data?._id;

                        } else {
                            errMessage["Sub Category"] = "Please enter a valid subcategory name";
                        }
                    } catch (error) {
                        errMessage["Sub Category"] = "Please enter a valid subcategory name";
                    }
                } else {
                    if (lead[6] !== undefined) {
                        errMessage["Sub Category"] = "Please enter a valid subcategory name";
                    }
                }

                if (typeof lead[7] === "string") {
                    try {
                        const goal = await getContentGoalPartial({
                            search: lead[7].trim(),
                        });
                        if (goal.data?._id) {

                            data.goals = goal.data?._id;

                        } else {
                            errMessage["Goal"] = "Please enter a valid goal name";
                        }
                    } catch (error) {
                        errMessage["Goal"] = "Please enter a valid goal name";
                    }
                } else {
                    if (lead[7] !== undefined) {
                        errMessage["Goal"] = "Please enter a valid goal name";
                    }
                }


                if (typeof lead[8] === "string") {
                    try {
                        const keyword = await getContentKeywordPartial({
                            search: lead[8].trim(),
                        });
                        if (keyword.data?._id) {

                            data.primaryKeywords = keyword.data?._id;

                        } else {
                            errMessage["Primary Keyword"] = "Please enter a valid primaryKeyword name";
                        }
                    } catch (error) {
                        errMessage["Primary Keyword"] = "Please enter a valid primaryKeyword name";
                    }
                } else {
                    if (lead[8] !== undefined) {
                        errMessage["Primary Keyword"] = "Please enter a valid primaryKeyword name";
                    }
                }

                if (typeof lead[9] === "string") {
                    try {
                        const funnelParts = await getContentPartsOfFunnelPartial({
                            search: lead[9].trim(),
                        });
                        if (funnelParts.data?._id) {

                            data.partOfFunnel = funnelParts.data?._id;

                        } else {
                            errMessage["Part of funnel"] = "Please enter a valid partOfFunnel name";
                        }
                    } catch (error) {
                        errMessage["Part of funnel"] = "Please enter a valid partOfFunnel name";
                    }
                } else {
                    if (lead[9] !== undefined) {
                        errMessage["Part of funnel"] = "Please enter a valid partOfFunnel name";
                    }
                }

                if (typeof lead[10] === "string") {
                    try {
                        const content = await getContentTypesOfContentPartial({
                            search: lead[10].trim(),
                        });
                        if (content.data?._id) {

                            data.typeOfContent = content.data?._id;

                        } else {
                            errMessage["Type of content"] = "Please enter a valid typeOfContent name";
                        }
                    } catch (error) {
                        errMessage["Type of content"] = "Please enter a valid typeOfContent name";
                    }
                } else {
                    if (lead[10] !== undefined) {
                        errMessage["Type of content"] = "Please enter a valid typeOfContent name";
                    }
                }

                if (typeof lead[11] === "string") {
                    data.suggestedSubHeadings = lead[11];
                } else {
                    errMessage["Suggested Subheadings"] = "suggestedSubHeadings must be string";
                }

                if (typeof lead[12] === "string") {
                    data.blogBannerButton = lead[12];
                } else {
                    if (lead[12] === undefined) {
                        errMessage["Blog Banner Button"] = "Blog Banner Button is required";
                    }
                }

                if (typeof lead[13] === "string") {
                    data.blogBannerButtonText = lead[13];
                } else {
                    if (lead[13] === undefined) {
                        errMessage["Blog Banner Button Text"] = "Blog Banner Button Text is required";
                    }
                }

                if(typeof lead[14] !== "string" && lead[14] !== undefined){
                    errMessage["Secondary Keyword"] = "Please enter a valid secondaryKeyword name";
                }else{
                    let ind = 14;
                    try {
                        while(ind < lead.length){
                            if(lead[ind]){
                                const keyword = await getContentKeywordPartial({
                                    search: lead[ind].trim(),
                                });
                                if (keyword.data?._id) {
                                    secKeywords.push( keyword.data?._id);
                                } else {
                                    errMessage["Secondary Keyword"] = "Please enter a valid secondaryKeyword name";
                                }
                            }
                            ind++;
                        }
                        data.secondaryKeywords = secKeywords;
                    } catch (error) {
                         errMessage["Secondary Keyword"] = "Please enter a valid secondaryKeyword name";
                    }
                }

                if (mandatoryFields.every((value) => Object.keys(data).includes(value))) {
                    if (Object.keys(errMessage).length) {
                        error.push({ ...errMessage, row });
                    } else {
                        console.log("INSIDE ELSE",data);
                        payload.push(data);
                    }
                } else {
                    if (Object.keys(errMessage).length) {
                        error.push({ ...errMessage, row });
                    }
                }
                ++row;
            }

            // return all the data
            resolve({
                data: payload,
                errorMessage: error,
            });

        });

    return { validateBlog };
};
export default usevalidateBlog;
