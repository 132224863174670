import { useEffect, FC } from "react";
import { useQuery } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { cpdDriveService } from "../../../../../services";
import { useParams } from "react-router-dom";
import { ICpdDriveBasicField, ICpdDrive} from "../../../../../interfaces";
import { Box, Grid, TextField } from "@mui/material";
import dayjs from "dayjs";
import { capitalize } from "../../../../../utilities/helper";
interface props {
    college: ICpdDrive | undefined;
    activeAction: boolean;
    currentTab: number;
}

const CpdBasicDetail: FC<props> = ({ activeAction, currentTab }) => {
    const isDisable = true;
    const { id } = useParams();
    const { getCpdDrive } = cpdDriveService();
    const hitQuery = (id === "new" || id === "view") ? false : true;
    const drive = useQuery({ queryKey: ["collegeBasicList", hitQuery], queryFn: () => getCpdDrive({ _id: id }), enabled: hitQuery, cacheTime:0 });
    const { control, setValue, } = useForm<ICpdDrive>({
        defaultValues: {
            _college: "",
            status: "",
            driveDate: "",
            startDate: "",
            endDate: "",
            recruiterId: "",
            notes: ""
        }
    });

    useEffect(() => {
        if (id !== "new") {
            if (drive.data?.data) {
                setValue("_college", capitalize(drive.data.data.collegeId.name));
                drive.data.data?.driveDate && setValue("driveDate",dayjs(drive.data.data?.driveDate).format("ll") );
                drive.data.data?.startDate && setValue("startDate", dayjs(drive.data.data?.startDate).format("ll") );
                drive.data.data?.endDate && setValue("endDate", dayjs(drive.data.data?.endDate).format("ll"));
                setValue("notes", drive.data.data?.notes);
            }
        }
    }, [id, drive.data]);

    const fields: ICpdDriveBasicField[] = [
        {
            label: "College Name*",
            name: "_college",
            type: "text",
        },
        {
            label: "Drive Date",
            name: "driveDate",
            type: "text",
        },
        {
            label: "Start Date",
            name: "startDate",
            type: "text",
        },
        {
            label: "End Date",
            name: "endDate",
            type: "text",
        },
        {
            label: "Remark",
            name: "notes",
            type: "multiline",
            placeholder: "Type remark here",
            width: 12,
        },
    ];

    currentTab === 0 && activeAction;
    return (
        <Box>
            <Grid container spacing={4}>
                {
                    fields.map(field => {
                        if (field.type === "multiline") {
                            return (<Grid key={field.label} item xs={12} md={field.width ? field.width : 6}>
                                <Controller
                                    control={control}
                                    name={field.name}
                                    render={(prop) => <TextField
                                        {...prop.field}
                                        label={field.label}
                                        className="disable-text"
                                        disabled={isDisable}
                                        variant={isDisable ? "standard" : "outlined"}
                                        size={isDisable ? "medium" : "small"}
                                        placeholder={field.placeholder}
                                        multiline
                                        minRows={2}
                                    />}
                                />
                            </Grid>
                            );
                        } else if (field.type === "text") {
                            return (<Grid key={field.label} item xs={12} md={field.width ? field.width : 6}>
                                <Controller
                                    control={control}
                                    name={field.name}
                                    render={(prop) => <TextField
                                        {...prop.field} 
                                        label={field.label}
                                        className="disable-text"
                                        disabled={isDisable}
                                        variant={isDisable ? "standard" : "outlined"}
                                        size={isDisable ? "medium" : "small"}
                                        placeholder={field.placeholder}
                                    />}
                                />
                            </Grid>
                            );
                        }
                    })
                }
            </Grid>
        </Box>
    );
};

export default CpdBasicDetail;