import Joi from "joi";
import { required } from "../../shared";

const endDateAfterStartDate = (endDate: string, helpers: Joi.CustomHelpers<string>) => {
    const startDate = helpers.state.ancestors[0].startDate; 
    if (startDate && endDate <= startDate) {
        return helpers.error("endDate.afterStartDate");
    }
    return endDate;
};

export const driveValidation = Joi.object({
    _college: Joi.string()
        .required()
        .label("College Name")
        .messages({
            "string.empty": required
        }),
    driveDate: Joi.string()
        .allow(""),
    startDate: Joi.string()
        .allow(""),
    endDate: Joi.string()
        .allow("")
        .custom(endDateAfterStartDate, "End date validation")
        .messages({
            "endDate.afterStartDate" : "End date must be after start date" 
        }),
    notes: Joi.string()
        .allow(""),
});