import "./style.scss";
import { ChangeEvent, useEffect, MouseEvent, useState, SetStateAction } from "react";
import Header from "../../../../components/header";
import CustomTable from "../../../../components/mui/table";
import { Box, Button, Checkbox, FormControl, MenuItem, Select, SelectChangeEvent, Tooltip, Typography } from "@mui/material";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import WarningDialog from "../../../../components/mui/warning-dialog";
import useDebounce from "../../../../hooks/useDebounce";
import useResource from "../../../../hooks/useResource";
import { IBlog, IBlogRow, IBlogState, IErrorResponse, IPagination, IProjectData } from "../../../../interfaces";
import { blogService } from "../../../../services/blog";
import { useQuery } from "@tanstack/react-query";
import GetActions from "../../../../components/get-actions";
import { capitalize, createIndex } from "../../../../utilities/helper";
import useSnackbar from "../../../../hooks/useSnackbar";
import BlogFilters from "../filters";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import BulkAssignment from "../bulk assignment";
import { handleSelect, handleSelectAll } from "../../../content-layout/common/helper";
import CustomTypography from "../../../../components/mui/max-length-limit";
import { useSelector } from "react-redux";

const BlogList = () => {
  const navigate = useNavigate();
  const { resourceAllocate } = useResource();
  const [search, setSearch] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { getBlogList, deleteBlog, updateBlogStatus } = blogService();
  const { snackbar } = useSnackbar();
  const [state, setState] = useState<IBlogState>({
    selectAll: [],
    deleteWarning: false,
    _blog: "",
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    assignmentDialog: {
      anchorEl: null,
      isOpen: false
    },
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      search: "",
      category: []
    },
    filterCount: 0,
    searching: "",
    _deleteId: ""
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  const productList = useSelector<{ cmsProduct: { list: IProjectData[] } }, IProjectData[]>(
    (state) => state.cmsProduct.list
  ) || [];

  const applyDefaultFilter = () => {
    const selectedFilterProductId = localStorage.getItem("selectedFilterProductId") || "";
    const selectedProduct = productList.find((item) => item._id === selectedFilterProductId);
    const product = [];
  
    if (selectedProduct) {
      product.push({ key: selectedProduct._id, value: selectedProduct.name });
    } else {
      product.push({ key: "", value: "" });
    }
    if(product[0].value !== ""){
      searchParams.set("product", JSON.stringify(product));
    }
  
  };
  
  useEffect(() => {
    applyDefaultFilter();
  }, []);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
    const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
    const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
    const product: { key: string, value: string }[] = searchParams.get("product") ? JSON.parse(String(searchParams.get("product"))) : [];
    const subCategory: { key: string, value: string }[] = searchParams.get("subCategory") ? JSON.parse(String(searchParams.get("subCategory"))) : [];
    const quarter: { key: string, value: string }[] = searchParams.get("quarter") ? JSON.parse(String(searchParams.get("quarter"))) : [];
    const date: {  key: string, value: string, startDate: string, endDate: string  }[] = searchParams.get("week") ? JSON.parse(String(searchParams.get("week"))) : [];
    const writers: { key: string, value: string }[] = searchParams.get("writers") ? JSON.parse(String(searchParams.get("writers"))) : [];
    const reviewers: { key: string, value: string }[] = searchParams.get("reviewers") ? JSON.parse(String(searchParams.get("reviewers"))) : [];
    const illustrators: { key: string, value: string }[] = searchParams.get("illustrators") ? JSON.parse(String(searchParams.get("illustrators"))) : [];
    const publishers: { key: string, value: string }[] = searchParams.get("publishers") ? JSON.parse(String(searchParams.get("publishers"))) : [];

    filterCount += category.length ? 1 : 0;
    filterCount += status.length ? 1 : 0;
    filterCount += product.length ? 1 : 0;
    filterCount += subCategory.length ? 1 : 0;
    filterCount += quarter.length ? 1 : 0;
    filterCount += date.length ? 1 : 0;
    filterCount += writers.length ? 1 : 0;
    filterCount += reviewers.length ? 1 : 0;
    filterCount += illustrators.length ? 1 : 0;
    filterCount += publishers.length ? 1 : 0;


    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search,
        categoryId: category.map(item => item.key),
        status: status.map(item => item.key),
        productId: product.map(item => item.key),
        quarterId: quarter.map(item => item.key),
        subCategoryId: subCategory.map(item => item.key),
        startDate: date[0] && date[0].startDate,
        endDate: date[0] && date[0].endDate,
        writers: writers.map(item => item.key),
        reviewers: reviewers.map(item => item.key),
        illustrators: illustrators.map(item => item.key),
        publishers: publishers.map(item => item.key)
      },
      filterCount
    }));
  }, [searchParams]);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const handleDelete = (_deleteId = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _deleteId
    }
    ));
  };

  const onDelete = async () => {
    try {
      const response = await deleteBlog({ _id: [state._deleteId] });
      snackbar(response?.message, "info");
      handleDelete();
      blogListData.refetch();
    } catch (error) {
      console.log(error);
    }

  };
  const handleStatus = async (event: SelectChangeEvent<string>, _id: string) => {
    const { name, value } = event.target;

    try {
      const payload = {
        _id,
        [name]: value
      };
      const response = await updateBlogStatus(payload);
      snackbar(response.message, "info");
      blogListData.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log({ "Error in update candidate status": error });
    }
  };

  const createRows = (blog: IBlog, index: number, selectedAll: string[], pagination: IPagination) => {
    const action = <GetActions
      icons={[
        { name: "Delete", method: () => handleDelete(blog._id), disabled: resourceAllocate("cms-blog.remove") ? false : true },
      ]}
    />;
    const status =
      <FormControl
        sx={{ width: 150 }}
      >
        <Select
          size="small"
          name="status"
          // disabled={allowPermission(candidate.createdBy?._user) ? false : true}
          onChange={e => handleStatus(e, blog?._id)}
          value={blog?.status}
          disabled={!resourceAllocate("cms-blog-status.write")}
        >
          <MenuItem disabled value="none">Select</MenuItem>
          <MenuItem value="DRAFT">Draft</MenuItem>
          <MenuItem value="IN_WRITING">In Writing</MenuItem>
          <MenuItem value="IN_REVIEW">In Review</MenuItem>
          <MenuItem value="REASSIGN">Re-assign</MenuItem>
          <MenuItem value="IN_PUBLISHING">In Publishing</MenuItem>
          <MenuItem value="SCHEDULED">Scheduled</MenuItem>
          <MenuItem value="COMPLETED">Completed</MenuItem>
        </Select>
      </FormControl>;

    const subCategory =
      <Tooltip title={blog?._subCategory?.map(sub => sub.name).join(", ")}>
        <span>
          {blog && blog._subCategory && blog._subCategory.length > 0 ? (
            <>
              {blog._subCategory[0].name}{" "}
              {blog._subCategory.length > 1 && (
                <span style={{ color: "blue" }}>+{blog._subCategory.length - 1}</span>
              )}
            </>
          ) : (
            ""
          )}
        </span>
      </Tooltip>;

    const assignment = capitalize(
      (() => {
        switch (assignmentRole) {
          case "writer":
          case "reviewer":
          case "illustrator":
          case "publisher":
            return blog && blog.assignments && blog.assignments[assignmentRole] && blog.assignments[assignmentRole].userId && blog.assignments[assignmentRole].userId.name || "";
          default:
            return "";
        }
      })()
    );

    const title = <CustomTypography
        limit={30}
        label={capitalize(blog && blog.title || "")}
        onClick={resourceAllocate("cms-blog.edit") ? () => navigate({
          pathname: `manage/${blog._id}`,
          search: resourceAllocate("cms-assignment.read") ? "type=basic-detail&"+ searchParams.toString() : resourceAllocate("cms-blog-image.read") ? "type=blog-image&"+ searchParams.toString() : resourceAllocate("cms-blog-content.read") ? "type=content&"+ searchParams.toString() : resourceAllocate("cms-blog-score.read") ? "type=score&"+ searchParams.toString() : ""
        }) : undefined}
        color={resourceAllocate("cms-blog.edit") ? "primary" : undefined}
      />;

    return {
      all: <Checkbox onChange={e => handleSelect(e, blog._id, state, setState)} checked={selectedAll.includes(blog._id)} />,
      id: createIndex(pagination, index),
      blog_title: title,
      quarter: blog?._quarter?.name,
      category: blog?._category?.name,
      sub_category: subCategory,
      product: blog?._product?.name,
      assignments: assignment,
      status: status,
      action
    };
  };

  const blogListData = useQuery({
    queryKey: ["blogs", state.pagination.page, state.searching, state.filters, state.assignmentDialog.isOpen],
    queryFn: () => getBlogList({
      pagination: true, page: state.pagination.page, limit: state.pagination.limit, ...state.filters
    })
  });

  const onLimitChange = (event: SelectChangeEvent<string>) => { 
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        limit: Number(event.target.value)
      }
    }));
  };

  useEffect(() => {
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    blogListData.refetch();
  }, [state.pagination.limit]);

  useEffect(() => {
    if (blogListData?.data?.data?.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: blogListData?.data?.meta?.page,
          totalPages: blogListData?.data?.meta?.totalPages,
          totalRecords: blogListData?.data?.meta?.totalRecords
        }
      }));
    }
  }, [blogListData?.data?.meta]);


  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const openAssignmentForm = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    assignmentDialog: {
      ...prevState.assignmentDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.assignmentDialog.isOpen
    }
  }));

  const closeAssignmentForm = () => {
    setState(prevState => ({
      ...prevState,
      assignmentDialog: {
        ...prevState.assignmentDialog,
        isOpen: false
      }
    }));
  };
  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const [assignmentRole, setAssignmentRole] = useState("writer");
  const handleAssignmentRoleChange = (event: { target: { value: SetStateAction<string>; }; }) => {
    setAssignmentRole(event.target.value);
  };
  const isChecked =  state.selectAll.length === blogListData?.data?.data.length;
  const isIndeterminateChecked = (state.selectAll.length > 0 && state.selectAll.length < Number(blogListData.data?.data.length)) ? true : false;
  const columns = [
    {
      id: "all",
      label: <Checkbox onChange={e => handleSelectAll(e, blogListData?.data?.data ? blogListData?.data?.data : [], setState)} checked={isChecked} indeterminate={isIndeterminateChecked} />
    },
    {
      id: "blog_title",
      label: "Blog Title"
    },
    {
      id: "quarter",
      label: "Quarter"
    },
    {
      id: "category",
      label: "Category"
    },
    {
      id: "sub_category",
      label: "Sub Categories"
    },
    {
      id: "assignments",
      label: (
        <Select
          defaultValue="writer"
          size="small"
          onChange={handleAssignmentRoleChange}
        >
          <MenuItem value="writer">Writer</MenuItem>
          <MenuItem value="reviewer">Reviewer</MenuItem>
          <MenuItem value="illustrator">Illustrator</MenuItem>
          <MenuItem value="publisher">Publisher</MenuItem>
        </Select>
      )
    },
    {
      id: "product",
      label: "Product"
    },
    {
      id: "status",
      label: "Status"
    },
    {
      id: "action",
      label: "Action"
    },
  ];
  let rows: IBlogRow[] = [];
  if (blogListData?.data?.data.length) {
    rows = blogListData.data.data.map((data: IBlog, index: number) => createRows(data, index, state.selectAll, state.pagination));
  }

  return (
    <div id="blog">

      <Typography variant="h6">All Blogs</Typography>
      <Typography className="sub-title" variant="caption">Here you can manage all the blogs.</Typography>
      <Header
        className='my-2'
        btnText="Add New Blog"
        onBtnClick={resourceAllocate("cms-blog.write") ? () => navigate({ pathname: "manage/new", search: "type=basic-detail" }) : undefined}
        searchPlaceholder="Search by title"
        onSearch={onSearch}
        onFilter={openFilter}
        filterCount={state.filterCount}
        onImport={resourceAllocate("cms-blog.write") ? () => navigate({ pathname: "/blog/import", search: searchParams.toString() }) : undefined}

      >
        <BlogFilters
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnClose={closeFilter}
        />
        {resourceAllocate("cms-assignment.write") && <Button
          style={{ backgroundColor: "rgb(231 231 231)", borderColor: "grey", boxShadow: "none" }}
          disabled={state.selectAll.length ? false : true}
          className='ml-2 h-100'
          onClick={openAssignmentForm}
        >
          <Typography variant="body2" style={{ color: state.selectAll.length ? "#000" : "grey" }}>
            Assign To
          </Typography>
          {state.assignmentDialog.isOpen
            ? <ArrowDropUpIcon
              style={{ color: state.selectAll.length ? "#000" : "grey" }} />
            : <ArrowDropDownIcon
              style={{ color: state.selectAll.length ? "#000" : "grey" }} />}
        </Button>}
        <BulkAssignment
          anchorEl={state.assignmentDialog.anchorEl}
          isOpen={state.assignmentDialog.isOpen}
          OnClose={closeAssignmentForm}
          ids={state.selectAll}
          setState={setState}
        />
      </Header>

      <Box display="flex" className="mb-2" justifyContent="space-between">

        <Box>
          {state.selectAll.length ? <Box>
            <Typography className="ml-2" variant="body1">
              {state.selectAll.length > 1 ? `${state.selectAll.length} entries selected`: `${state.selectAll.length} entry selected`}
            </Typography>
          </Box> : null}
        </Box>

        <Box display="flex" alignItems="center">
          <Typography variant="body1">Total Blogs:</Typography>
          <Typography className="ml-3" variant="body1" >{blogListData?.data?.meta?.totalRecords}</Typography>
        </Box>

      </Box>
      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 300px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
          limitPerPage={true}
          onLimitChange={onLimitChange}
          limitValue={state.pagination.limit.toString()}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Blog"
        description="Are you sure you want to delete this blog?"
      />

      <Outlet context={{ ...Outlet, reFetch: blogListData.refetch }} />
    </div>
  );
};

export default BlogList;