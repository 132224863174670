import { Box, Checkbox, Tooltip, Typography } from "@mui/material";
import Header from "../../../components/header";
import { ChangeEvent, useEffect, MouseEvent, useState } from "react";
import { useNavigate, useSearchParams, Outlet } from "react-router-dom";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import {
  IKeyword,
  IKeywordRow,
  IKeywordStatus,
} from "../../../interfaces/content/keyword";
import useDebounce from "../../../hooks/useDebounce";
import KeywordService from "../../../services/content/keyword";
import { useQuery } from "@tanstack/react-query";
import { createIndex } from "../../../utilities/helper";
import { IColumn, IErrorResponse, IPagination, IProjectData } from "../../../interfaces";
import WarningDialog from "../../../components/mui/warning-dialog";
import useSnackbar from "../../../hooks/useSnackbar";
import { handleSelect, handleSelectAll } from "../common/helper";
import useResource from "../../../hooks/useResource";
import KeywordFilters from "../keyword/filters";
import { useSelector } from "react-redux";

const KeywordList = () => {
  const { snackbar } = useSnackbar();
  const [search, setSearch] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { resourceAllocate } = useResource();
  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
  const { getContentKeywords, deleteContentKeyword } = KeywordService();
  const [state, setState] = useState<IKeywordStatus>({
    selectAll: [],
    multiDeleteWarning: false,
    pagination: {
      page: page,
      limit: 20,
      totalPages: 1,
      totalRecords: 0,
    },
    searching: {
      search: "",
    },
    filters: {
      search: "",
      categoryId: [],
      subCategoryId: [],
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false,
    },
    filterCount: 0,
    deleteWarning: false,
    _keyword: "",
    reopenWarning: false,
  });

  const keywordsList = useQuery({
    queryKey: [
      "keyword",
      state.pagination.page,
      state.searching.search,
      state.filters,
    ],
    queryFn: () =>
      getContentKeywords({
        pagination: true,
        page: state.pagination.page,
        limit: state.pagination.limit,
        ...state.searching,
        ...state.filters,
      }),
  });

  useEffect(() => {
    if (keywordsList.data?.data.length) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: keywordsList.data.meta.page,
          totalPages: keywordsList.data.meta.totalPages,
          totalRecords: keywordsList.data.meta.totalRecords,
        },
      }));
    }
  }, [keywordsList.data?.meta]);

  const productList = useSelector<{ cmsProduct: { list: IProjectData[] } }, IProjectData[]>(
    (state) => state.cmsProduct.list
  ) || [];

  const applyDefaultFilter = () => {
    const selectedFilterProductId = localStorage.getItem("selectedFilterProductId") || "";
    const selectedProduct = productList.find((item) => item._id === selectedFilterProductId);
    const product = [];
  
    if (selectedProduct) {
      product.push({ key: selectedProduct._id, value: selectedProduct.name });
    } else {
      product.push({ key: "", value: "" });
    }
    if(product[0].value !== ""){
      searchParams.set("productId", JSON.stringify(product));
    }
  
  };
  
  useEffect(() => {
    applyDefaultFilter();
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";

    const category: { key: string; value: string }[] = searchParams.get(
      "category"
    )
      ? JSON.parse(String(searchParams.get("category")))
      : [];
    const subCategory: { key: string; value: string }[] = searchParams.get(
      "subCategory"
    )
      ? JSON.parse(String(searchParams.get("subCategory")))
      : [];

    const productId : { key: string; value: string }[] = searchParams.get(
      "productId"
    )
      ? JSON.parse(String(searchParams.get("productId")))
      : [];

    filterCount += category.length ? 1 : 0;
    filterCount += subCategory.length ? 1 : 0;
    filterCount += productId.length ? 1 : 0;

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page: page,
      },
      searching: {
        ...prevState.searching,
        search: search,
      },
      filters: {
        ...prevState.filters,
        search,
        categoryId: category.map((item) => item.key),
        subCategoryId: subCategory.map((item) => item.key),
        productId: productId.map((item) => item.key),
      },
      filterCount,
    }));
  }, [searchParams]);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      searchParams.delete("page");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  const handleDelete = (_keyword = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _keyword,
    }));
  };

  const onDelete = async () => {
    try {
      const response = await deleteContentKeyword({ ids: [state._keyword] });
      setState((prevState) => ({
        ...prevState,
        selectAll: prevState.selectAll.filter(
          (keyword) => keyword !== state._keyword
        ),
      }));
      snackbar(response.message, "info");
      handleDelete();
      keywordsList.refetch();
      navigate({
        pathname: "/content/keywords",
        search: searchParams.toString(),
      });
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log("Error in delete sub-category", err);
    }
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) =>
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        anchorEl: e.currentTarget,
        isOpen: !state.filterDialog.isOpen,
      },
    }));

  const closeFilter = () => {
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false,
      },
    }));
  };

  const openManageImport = () => {
    navigate("/content/keywords/import");
  };

  const handleMultiDelete = () =>
    setState((prevState) => ({
      ...prevState,
      multiDeleteWarning: !prevState.multiDeleteWarning,
    }));
  const onMultiDelete = async () => {
    try {
      const goal = await deleteContentKeyword({ ids: state.selectAll });
      snackbar(goal.message, "info");
      keywordsList.refetch();
      setState((prevState) => ({
        ...prevState,
        multiDeleteWarning: false,
        selectAll: [],
      }));
      navigate({
        pathname: "/content/keywords",
        search: searchParams.toString(),
      });
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      handleMultiDelete();
      console.log({ "Error in delete project": error });
    }
  };

  const isChecked =
    keywordsList.data?.data?.length &&
    state.selectAll?.length === keywordsList.data?.data?.length
      ? true
      : false;
  const isIndeterminateChecked =
    state.selectAll.length > 0 &&
    state.selectAll.length < Number(keywordsList.data?.data?.length)
      ? true
      : false;

  const columns: IColumn[] = [
    {
      id: "all",
      label: (
        <Checkbox
          onChange={(e) =>
            handleSelectAll(
              e,
              keywordsList?.data?.data ? keywordsList?.data?.data : [],
              setState
            )
          }
          checked={isChecked}
          indeterminate={isIndeterminateChecked}
        />
      ),
      maxWidth: 20,
    },
    {
      id: "category_title",
      label: "Keyword",
    },
    {
      id: "product_title",
      label: "Product"
    },
    {
      id: "category_name",
      label: "Category",
    },
    {
      id: "sub_category_name",
      label: "Sub Categories",
    },
    {
      id: "action",
      label: "Actions",
      align: "right",
    },
  ];

  let rows: IKeywordRow[] = [];
  const createRows = (
    keyword: IKeyword,
    selectedAll: string[],
    index: number,
    pagination: IPagination
  ) => {
    const action = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () => {
              navigate({
                pathname: "" + keyword?._id,
                search: searchParams.toString(),
              });
            },
            disabled: resourceAllocate("cms-keyword.write") ? false : true,
          },
          {
            name: "Delete",
            method: () => handleDelete(keyword?._id),
            disabled: resourceAllocate("cms-keyword.remove") ? false : true,
          },
        ]}
      />
    );
    const subCategory = (
      <Tooltip title={keyword?._subCategory?.map((sub) => sub.name).join(", ")}>
        <span>
          {keyword &&
          keyword._subCategory &&
          keyword._subCategory.length > 0 ? (
            <>
              {keyword._subCategory[0].name}{" "}
              {keyword._subCategory.length > 1 && (
                <span style={{ color: "blue" }}>
                  +{keyword._subCategory.length - 1}
                </span>
              )}
            </>
          ) : (
            ""
          )}
        </span>
      </Tooltip>
    );
    return {
      all: (
        <Checkbox
          onChange={(e) => handleSelect(e, keyword._id, state, setState)}
          checked={selectedAll.includes(keyword._id)}
        />
      ),
      id: createIndex(pagination, index),
      category_title: keyword?.name,
      sub_category_name: subCategory,
      category_name: keyword?._category?.name,
      product_title: keyword && keyword._product && keyword._product.name,
      action,
    };
  };

  if (keywordsList?.data?.data?.length) {
    rows = keywordsList?.data?.data?.map((keyword: IKeyword, index: number) =>
      createRows(keyword, state.selectAll, index, state.pagination)
    );
  }

  const addOpenBox = () => {
    navigate("/content/keywords/new");
  };

  return (
    <div>
      <Outlet context={{ ...Outlet, reFetch: keywordsList.refetch }} />
      <Header
        className="my-2"
        searchPlaceholder="Search by name"
        onSearch={onSearch}
        onDelete={
          resourceAllocate("cms-keyword.remove") ? handleMultiDelete : undefined
        }
        isDeleteDisable={state.selectAll.length ? false : true}
        btnText="Add Keyword"
        onFilter={openFilter}
        filterCount={state.filterCount}
        onBtnClick={
          resourceAllocate("cms-keyword.write") ? () => addOpenBox() : undefined
        }
        onImport={
          resourceAllocate("cms-keyword.write")
            ? () => openManageImport()
            : undefined
        }
      >
        <KeywordFilters
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnClose={closeFilter}
        />
      </Header>
      <Box
        display="flex"
        className="mb-2"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <Typography variant="body1">Total Keywords:</Typography>
          <Typography className="ml-3" variant="body1">
            {keywordsList?.data?.meta?.totalRecords}
          </Typography>
        </Box>
      </Box>
      <Box id="custom-table" marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 285px)"
          errorMessage="Add user to see the data here"
          pagination={{
            page: state.pagination.page,
            totalPages: state.pagination.totalPages,
          }}
          onPageChange={onPageChange}
        />
      </Box>
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Keyword"
        description="Are you sure you want to delete this keyword?"
      />
      {/* Multiple Delete  */}
      <WarningDialog
        isOpen={state.multiDeleteWarning}
        onClose={() => handleMultiDelete()}
        onConfirm={onMultiDelete}
        title="Delete All keywords"
        description={`Are you sure you want to delete ${
          state.selectAll.length
        } ${state.selectAll.length > 1 ? "keywords" : "keyword"} ?`}
      />
    </div>
  );
};
export default KeywordList;