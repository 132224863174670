import HttpService from "../../http";
import { IEmployeeReviewsResponse, IEmployeeReviewResponse } from "../../../interfaces";
import { employeeReview, addemployeeReview, getemployeeReview, updateemployeeReview } from "../../endpoints";

const EmployeeReviewService = () => {
    const { httpRequest } = HttpService();
    const addEmployeeReviews = async (payload: object): Promise<IEmployeeReviewsResponse> => new Promise((resolve, reject) => {
        httpRequest<IEmployeeReviewsResponse>(
            "POST",
            `${addemployeeReview}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getEmployeeReviews = async (search: object): Promise<IEmployeeReviewsResponse> => new Promise((resolve, reject) => {
        httpRequest<IEmployeeReviewsResponse>(
            "GET",
            `${employeeReview}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getEmployeeReview = async (query: object): Promise<IEmployeeReviewResponse> => new Promise((resolve, reject) => {
        httpRequest<IEmployeeReviewResponse>(
            "GET",
            `${getemployeeReview}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateEmployeeReview = async (id: string, payload: object): Promise<IEmployeeReviewResponse> => new Promise((resolve, reject) => {
        httpRequest<IEmployeeReviewResponse>(
            "PUT",
            `${updateemployeeReview}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteEmployeeReview = async (payload: object): Promise<IEmployeeReviewsResponse> => new Promise((resolve, reject) => {
        httpRequest<IEmployeeReviewsResponse>(
            "DELETE",
            `${employeeReview}`,
            {}, 
            payload

        )
            .then(resolve)
            .catch(reject);
    });


    return { addEmployeeReviews, getEmployeeReviews, getEmployeeReview, updateEmployeeReview, deleteEmployeeReview };
};

export { EmployeeReviewService };