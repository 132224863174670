import { useState } from "react";
import Header from "../../../../../components/header";
import CustomTable from "../../../../../components/mui/table";
import { useParams, useSearchParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { IColumn, IErrorResponse, IQuestionTemplateRow, IQuestionTemplateState, Template } from "../../../../../interfaces";
import GetActions from "../../../../../components/get-actions";
import useSnackbar from "../../../../../hooks/useSnackbar";
import WarningDialog from "../../../../../components/mui/warning-dialog";
import { Box, Typography } from "@mui/material";
import useResource from "../../../../../hooks/useResource";
import { TechnicalQuestionService } from "../../../../../services";


const Templates = () => {

	const { snackbar } = useSnackbar();
	const { id } = useParams();
	const { resourceAllocate } = useResource();
	const [searchParams] = useSearchParams();
	const { getTechnicalQuestion, deleteTechnicalQuestionTemplate } = TechnicalQuestionService();
	const [state, setState] = useState<IQuestionTemplateState>({
		searching: {
			search: ""
		},
		deleteWarning: false,
		_deleteId: "",
	});

	const navigate = useNavigate();

	const hitQuery = id !== "new";

	const getQuestion = useQuery({
		queryKey: ["getTechnicalQuestion", id],
		queryFn: () => getTechnicalQuestion({ _id: id }),
		enabled: hitQuery
	});


	const columns: IColumn[] = [

		{
			id: "name",
			label: "Language",
			minWidth: 200

		},
		{
			id: "action",
			label: "Actions",
			align: "right"
		}
	];

	const handleDelete = (_deleteId = "") => {
		setState(prevState => ({
			...prevState,
			deleteWarning: !state.deleteWarning,
			_deleteId: _deleteId
		}));
	};

	const onDelete = async () => {
		try {
			const response = await deleteTechnicalQuestionTemplate({ _template: state._deleteId, _id: id });

			snackbar(response?.message, "info");
			handleDelete();
			getQuestion.refetch();
			navigate({
				pathname: "/configurations/technical-questions/" + id,
				search: searchParams.toString()
			});
		} catch (error) {
			const err = error as IErrorResponse;
			snackbar(err?.data?.message, "error");
			console.log("Error in delete of question", err);
		}
	};


	const createRows = (data: Template) => {
		const action = <GetActions
			icons={[
				{
					name: "Edit", method: () => {
						navigate({
							pathname: "manage-template/" + data._id,
							search: searchParams.toString()
						});
					},
					disabled: !resourceAllocate("technical-question.write")
				},
				{ name: "Delete", method: () => handleDelete(data._id), disabled: !resourceAllocate("technical-question.remove") },
			]}
		/>;
		return {
			name: data.language.language,
			action
		};
	};

	let rows: IQuestionTemplateRow[] = [];
	if (getQuestion &&
		getQuestion.data &&
		getQuestion.data.data &&
		getQuestion.data.data.templates &&
		getQuestion.data.data.templates.length) {
		rows = (getQuestion.data.data.templates || []).map((data: Template) => createRows(data));
	}


	const openManageComponent = () => {
		navigate({ pathname: "manage-template/new", search: searchParams.toString() });
	};


	return (
		<div>

			<Header
				className="my-2"
				btnText="Add Template"
				onBtnClick={resourceAllocate("technical-question.write") ? () => openManageComponent() : undefined}
			/>
			<Box display="flex" className="mb-2" justifyContent="flex-end" alignItems="center">
				<Box display="flex" alignItems="center">
					<Typography variant="body1">Total Templates:</Typography>
					<Typography className="ml-3" variant="body1" >{getQuestion?.data?.data?.templates.length ?? 0}</Typography>
				</Box>
			</Box>
			<CustomTable
				columns={columns}
				rows={rows}
				height={"calc(100vh - 355px)"}
			/>

			<WarningDialog
				isOpen={state.deleteWarning}
				onClose={() => handleDelete()}
				onConfirm={onDelete}
				title="Delete Template"
				description="Are you sure you want to delete this Template?"

			/>
			<Outlet context={{ reFetch: getQuestion.refetch }} />
		</div>
	);
};

export default Templates;