import { IErrorResponse } from "../../../../interfaces";
import { Box, Grid, MenuItem, TextField } from "@mui/material";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import Select from "../../../../components/mui/select";
import { VendorCategoryService } from "../../../../services/vendor/vendor-category";
import { IVendorCategory, IVendorCategoryBasicField } from "../../../../interfaces/vendor/vendor-categories";
import { joiResolver } from "@hookform/resolvers/joi";
import { vendorCategoryValidation } from "../../../../validations/vendor/vendor-category";
import CustomLabel from "../../../../components/mui/custom-label";
interface outletProps {
    reFetch: () => void;
    refetchCategories: () => void;
}

const ManageVendorCategory = () => {
    const { id } = useParams();
    const { snackbar } = useSnackbar();
    const [searchParam] = useSearchParams();
    const outlet = useOutletContext<outletProps>();
    const hitQuery = (id === "new") ? false : true;
    const navigate = useNavigate();
    const { addVendorCategory, updateVendorCategory, getVendorCategoryy} = VendorCategoryService();
    const category = useQuery({ queryKey: [hitQuery], queryFn: () => getVendorCategoryy({ _id: id }), enabled: hitQuery });
    const { handleSubmit, control, setValue, formState: { errors } } = useForm<IVendorCategory>({
        resolver: joiResolver(vendorCategoryValidation),
        defaultValues: {
            name: "",
            type: ""
        }
    });
    useEffect(() => {
        if (id !== "new") {
            if (category.data?.data) {
                setValue("name", category.data.data.name);
                setValue("type", category.data.data.type);
            }
        }
    }, [id, category.data]);

    const onSubmit = async (data: IVendorCategory) => {
        try {
            if (id === "new") {
                const payload = { ...data };

                const add = await addVendorCategory(payload);
                snackbar(add.message, "info");
                navigate({
                    pathname: "/directory/categories",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
                outlet?.refetchCategories && outlet.refetchCategories();
            } else {
                const payload = { ...data, _id: id};
                const update = await updateVendorCategory(payload);
                snackbar(update.message, "info");
                navigate({
                    pathname: "/directory/categories",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
                outlet?.refetchCategories && outlet.refetchCategories();
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }
    };

    const onClose = () => {
        navigate({
            pathname: "/directory/categories",
            search: searchParam.toString()
        });
    };

    const fields: IVendorCategoryBasicField[] = [
        {
            label: "Category Name",
            name: "name",
            type: "input",
            placeholder: "Type category name",
            required: true,
        },
        {
            type: "select",
            name: "type",
            label: "Select category type",
            required: true,
            children: [
              <MenuItem key="DOCUMENT" value="DOCUMENT">Document</MenuItem>,
              <MenuItem key="SERVICE" value="SERVICE">Service</MenuItem>
            ]
        },
    ];

    return (
        <Box>
            <CustomDialog
                size="xs"
                title={id !== "new" ? "Edit Category" : "Add Category"}
                isOpen={id ? true : false}
                onClose={onClose}
                confirmText={id !== "new" ? "Edit Category" : "Add Category"}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "input") {
                                return (<Grid key={field.label} item xs={12}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => 
                                        <TextField
                                            label={<CustomLabel label={field.label} required={field?.required} />}
                                            className="disable-text"
                                            variant={"outlined"}
                                            size={"small"}
                                            placeholder={field.placeholder}
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            }else {
                                return (<Grid key={field.label} item xs={12}>
                                  <Select
                                    control={control}
                                    className="disable-text"
                                    name={field.name}
                                    label={<CustomLabel label={field.label} required={field?.required} />}
                                    size={"small"}
                                    variant={"outlined"}
                                    error={!!errors[field.name]}
                                    helperText={errors[field.name]?.message}
                                  >
                                    {field.children}
                                  </Select>
                                </Grid>
                                );
                              }

                        })
                    }
                </Grid>
            </CustomDialog>
        </Box>
    );

};

export default ManageVendorCategory;