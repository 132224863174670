import Joi from "joi";
import { required, only_string, valid_string, password_pattern } from "../../shared";

export const userValidation = Joi.object({
    firstName: Joi.string()
        .regex(/^[a-zA-Z0-9\s]+$/)
        .required()
        .min(3)
        .max(30)
        .trim()
        .label("First Name")
        .messages({
            "string.empty": required,
            "string.pattern.base": only_string
        }),
    lastName: Joi.string()
        .regex(/^[a-zA-Z0-9\s]+$/)
        .required()
        .min(3)
        .max(30)
        .trim()
        .label("Last Name")
        .messages({
            "string.empty": required,
            "string.pattern.base": only_string
        }),
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .lowercase()
        .label("Email")
        .messages({
            "string.empty": required
        }),
    gender: Joi.string()
        .valid("MALE", "FEMALE")
        .required()
        .label("Gender")
        .messages({
            "any.only": valid_string
        }),
    // phone: Joi.object({
    //     dialCode: Joi.string(),
    //     iso2: Joi.string()
    //         .max(2),
    //     country: Joi.string(),
    //     number: Joi.string()
    // }),
    password: Joi.string()
        .min(8)
        .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,1024}$/)
        .required()
        .label("Password")
        .messages({
            "string.empty": required,
            "string.pattern.base": password_pattern
        }),
    designation: Joi.string()
        .required()
        .label("Designation")
        .messages({
            "string.empty": required
        }),
    departmentId: Joi.string()
        .required()
        .disallow("none")
        .label("Department")
        .messages({
            "any.invalid": valid_string,
            "string.empty": required
        }),
    number: Joi.string()
        .required()
        .min(8)
        .label("Mobile Number")
        .messages({
            "string.empty": required
        }),
    extensionPhoneNumber: Joi.string()
        .optional()
        .allow("")
        .label("Extension Phone Number")
        .when(Joi.string().min(1), {
            then: Joi.string().min(8),
        }),
    roleId: Joi.string()
        .required()
        .label("Role")
        .messages({
            "any.invalid": valid_string,
            "string.empty": required
        }),
    status: Joi.string()
        .valid("ACTIVE")
        .required()
        .label("Type")
        .messages({
            "any.only": valid_string
        }),
    dob: Joi.string()
        .allow(""),
    joinedDate: Joi.string()
        .required(),
    dateOfLeaving: Joi.string()
        .allow("")
        .label("Date of Leaving"),
    reportingTo: Joi.string()
        .required()
        .label("Reporting Manager")
        .messages({
            "string.empty": required
        }),
    address: Joi.string()
        .allow(""),
    legalEntry: Joi.string()
        .allow(""),
    businessUnit: Joi.string()
        .allow(""),
    employeeNumber: Joi.string()
        .allow(""),
});

export const manyUserValidation = Joi.object({
    employeeNumber: Joi.string()
        .required()
        .label("Employee Id")
        .messages({
            "any.only": valid_string
        }),
    name: Joi.string()
        .label("Name"),
    firstName: Joi.string()
        .regex(/^[a-zA-Z\s]+$/)
        .required()
        .label("Name")
        .messages({
            "string.empty": required,
            "string.pattern.base": only_string
        }),
    lastName: Joi.string()
        .regex(/^[a-zA-Z\s]+$/)
        .required()
        .label("Name")
        .messages({
            "string.empty": required,
            "string.pattern.base": only_string
        }),
    phone: Joi.object({
        dialCode: Joi.string(),
        iso2: Joi.string()
            .max(2),
        country: Joi.string(),
        number: Joi.string().length(10)
    })
        .label("Mobile Number")
        .required()
        .messages({
            "string.empty": required,
        }),
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .lowercase()
        .label("Email")
        .messages({
            "string.empty": required
        }),
    gender: Joi.string()
        .required()
        .valid("MALE", "FEMALE")
        .label("Gender")
        .messages({
            "any.only": valid_string
        }),

    dob: Joi.string()
        .label("Date of Birth"),
    location: Joi.object()
        .keys({
            formattedAddress: Joi.string()
        })
        .label("Employee Address"),
    joinedDate: Joi.string()
        .required()
        .label("Joined Date"),
    dateOfLeaving: Joi.string()
        .allow("")
        .label("Date of Leaving"),
    extensionNumber:  Joi.object({
        dialCode: Joi.string(),
        iso2: Joi.string()
            .max(2),
        country: Joi.string(),
        number: Joi.string()
                   .optional()
        })
        .label("Extension Number")
        .optional(),
    reportingTo: Joi.string()
        .label("Reporting Manager"),
    legalEntry: Joi.string()
        .label("Legal Entry"),
    businessUnit: Joi.string(),

    _role: Joi.string()
        .required()
        .label("Role")
        .messages({
            "any.invalid": valid_string,
            "string.empty": required
        }),
    department: Joi.string()
        .required()
        .disallow("none")
        .label("Team")
        .messages({
            "any.invalid": valid_string,
            "string.empty": required
        }),
    designation: Joi.string()
        .required()
        .label("Designation")
        .messages({
            "string.empty": required
        }),
    status: Joi.string()
        .valid("ACTIVE")
        .required()
        .label("Status")
        .messages({
            "any.only": valid_string
        }),
});

export const userEditValidation = Joi.object({
    firstName: Joi.string()
        .regex(/^[a-zA-Z\s]+$/)
        .required()
        .min(3)
        .max(40)
        .trim()
        .label("First Name")
        .messages({
            "string.empty": required,
            "string.pattern.base": only_string
        }),
    lastName: Joi.string()
        .regex(/^[a-zA-Z\s]+$/)
        .required()
        .min(3)
        .max(40)
        .trim()
        .label("Last Name")
        .messages({
            "string.empty": required,
            "string.pattern.base": only_string
        }),
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .lowercase()
        .label("Email")
        .messages({
            "string.empty": required
        }),
    dateOfLeaving: Joi.string()
        .allow("")
        .label("Date of Leaving"),
    extensionPhoneNumber: Joi.string()
        .optional()
        .allow("")
        .label("Extension Phone Number")
        .when(Joi.string().min(1), {
            then: Joi.string().min(8),
        }),
    password: Joi.string()
        .min(8)
        .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,1024}$/)
        .optional()
        .label("Password")
        .messages({
            "string.pattern.base": password_pattern
        }),
    gender: Joi.string()
        .valid("MALE", "FEMALE")
        .required()
        .label("Gender")
        .messages({
            "any.only": valid_string
        }),
    // phone: Joi.object({
    //     dialCode: Joi.string(),
    //     iso2: Joi.string()
    //         .max(2),
    //     country: Joi.string(),
    //     number: Joi.string()
    // }),
    designation: Joi.string()
        .required()
        .label("Designation")
        .messages({
            "string.empty": required
        }),
    departmentId: Joi.string()
        .required()
        .disallow("none")
        .label("Department")
        .messages({
            "any.invalid": valid_string,
            "string.empty": required
        }),
    number: Joi.string()
        .required()
        .min(8)
        .label("Mobile Number")
        .messages({
            "string.empty": required
        }),
    roleId: Joi.string()
        .required()
        .label("Role")
        .messages({
            "any.invalid": valid_string,
            "string.empty": required
        }),
    status: Joi.string()
        .valid("ACTIVE" ,"INACTIVE")
        .required()
        .label("Type")
        .messages({
            "any.only": valid_string
        }),
    dob: Joi.string()
        .allow(""),
    joinedDate: Joi.string()
        .required(),
    reportingTo: Joi.string()
        .required()
        .label("Reporting Manager")
        .messages({
            "string.empty": required
        }),
    address: Joi.string()
        .allow(""),
    legalEntry: Joi.string()
        .allow(""),
    businessUnit: Joi.string()
        .allow(""),
    employeeNumber: Joi.string()
        .allow(""),
});