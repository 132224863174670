import { Box, Tooltip } from "@mui/material";
import { FC, useEffect, useState, MouseEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { ChangeEvent } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom";
import DragIndicatorIcon from "@mui/icons-material/DragHandle";
import {
  createIndex,
  formatDate,
  formatHtmlString,
} from "../../../../../../utilities/helper";
import { IErrorResponse, IPagination } from "../../../../../../interfaces";
import useResource from "../../../../../../hooks/useResource";
import useSnackbar from "../../../../../../hooks/useSnackbar";
import CustomTable from "../../../../../../components/mui/table";
import GetActions from "../../../../../../components/get-actions";
import WarningDialog from "../../../../../../components/mui/warning-dialog";
import useDebounce from "../../../../../../hooks/useDebounce";
import { TestsService } from "../../../../../../services/configuration/tests";

import {
  ITestUserQuestions,
  ITestUserQuestionsRow,
} from "../../../../../../interfaces/configuration/tests";

import { DropResult } from "react-beautiful-dnd";
import Filters from "./filter";
import Header from "../../../../../../components/header";
interface props {
  status: string;
}

const TestQuestions : FC<props> = ({ status }) => {
  const { snackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    deleteTestQuestion,
    updateTestQuestionPosition,
    getTestsQuestionList,
  } = TestsService();
  const navigate = useNavigate();
  const { testId } = useParams();
  const { resourceAllocate } = useResource();
  const [search, setSearch] = useState<string>("");


  interface IState {
    deleteWarning: boolean;
    _test: string;
    pagination: {
      page: number;
      limit: number;
      totalPages: number;
    };
    filterDialog: {
      anchorEl: null | HTMLElement;
      isOpen: boolean
    };
    filters: {
      search: string;
      category?: string[];
    };
    filterCount?: number
    rows: ITestUserQuestionsRow[];
  }

  const [state, setState] = useState<IState>({
    deleteWarning: false,
    _test: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filters: {
      search: "",
      category: []
    },
    filterCount: 0,
    rows: [],
  });


  const testQuestions = useQuery({
    queryKey: ["allTestQuestion", state.pagination.page, state.filters],
    queryFn: () =>
      getTestsQuestionList({
        _test: testId,
        pagination: false,
        ...state.filters,
      }),
  });

  let rows: ITestUserQuestionsRow[] = [];
  useEffect(() => {
    if (testQuestions.data?.data.length) {
      setState((prevState) => ({
        ...prevState,
        rows: testQuestions.data?.data.map((question, i) =>
          createRow(i, question, state.pagination)
        ),
      }));
    }
  }, [testQuestions.data?.data]);

  const searchRecord = useDebounce(search, 1000);

  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        type: searchParams.get("type"),
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";

      const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
      filterCount += category.length ? 1 : 0;

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
        category: category.map(category => category.key),
      },
      filterCount,
    }));
  }, [searchParams]);

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const handleDelete = (_test = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _test,
    }));
  };
  const onDelete = async () => {
    try {
      const deleted = await deleteTestQuestion({ _id: state._test , _test: testId});
      snackbar(deleted.message, "info");
      handleDelete();
      testQuestions.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };
  const onDrag = async (e: DropResult) => {
    const payload = [...state.rows];
    const row = payload[e.source.index];
    if (e.destination?.droppableId) {
      payload.splice(e.source.index, 1);
      payload.splice(e.destination?.index, 0, row);
      setState((prevState) => ({
        ...prevState,
        rows: payload,
      }));

      const position: { _id: string; position: number }[] = [];

      payload.forEach((status, index) =>
        position.push({ _id: status._id, position: index })
      );
      try {
        const updated = await updateTestQuestionPosition({
          questions: position,
        });
        snackbar(updated.message, "info");
        testQuestions.refetch();
      } catch (error) {
        const err = error as IErrorResponse;
        snackbar(err.data.message, "warning");
      }
    }
  };

  const columns = [
    ...(status !== "ARCHIVED" && status !== "PUBLISHED"
    ? [{ id: "drag", label: <DragIndicatorIcon /> }]
    : []),

    {
      id: "id",
      label: "S No.",
    },
    {
      id: "question",
      label: "Question",
    },
    {
      id: "category",
      label: "Category",
    },
    {
      id: "created",
      label: "Created At",
    },
    {
      id: "action",
      label: "Actions",
    },
  ];
  
  const createRow = (
    index: number,
    test: ITestUserQuestions,
    pagination: IPagination
  ) => {
    const action = (
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <GetActions
            icons={[
              {
                name: "Edit",
                method: () =>
                  navigate({
                    pathname: `manage-questions/${test._id}`,
                    search: searchParams.toString(),
                  }),
                disabled: resourceAllocate("question.write") && status!=="ARCHIVED" && status!=="PUBLISHED" ? false : true,
              },
              {
                name: "View",
                method: () =>
                  navigate({
                    pathname: `view-questions/${test._id}`,
                    search: searchParams.toString(),
                  }),
                disabled: resourceAllocate("question.read") ? false : true,
              },
              {
                name: "Delete",
                method: () => handleDelete(test._id),
                disabled: resourceAllocate("question.remove") && status!=="ARCHIVED" && status!=="PUBLISHED" ? false : true,
              },
            ]}
          />
        </div>
      </>
    );

    return {
      ...(status !== "ARCHIVED" && status !== "PUBLISHED"
      ? {
          drag: (
            <Tooltip title="Drag row">
              <DragIndicatorIcon />
            </Tooltip>
          ),
        }
      : {}),
      id: createIndex(pagination, index),
      _id: test._id ?? "",
      question:
      <Tooltip title={test.question ? formatHtmlString(test.question) : ""}>
      <span>
        {test.question && test.question.length > 60
          ? `${formatHtmlString(test.question).slice(0, 60)}...`
          : formatHtmlString(test.question)}
      </span>
    </Tooltip>,
      category: test._category.name ? test._category.name : "",
      created: formatDate(test.createdAt ? test.createdAt : ""),
      action,
    };
  };
  if (
    testQuestions.data &&
    testQuestions.data.data &&
    testQuestions.data.data.length
  ) {
    rows = (
      (testQuestions.data &&
        testQuestions.data.data &&
        testQuestions.data.data) ||
      []
    ).map((test, i) => createRow(i, test, state.pagination));
  }
  return (
    <div id="test-manage">
      <Header
        className="my-2"
        searchPlaceholder="Search by question"
        onSearch={onSearch}
        btnText="Create Test Question"
        onBtnClick={
          resourceAllocate("question.write") && status!=="ARCHIVED" && status!=="PUBLISHED"
            ? () =>
                navigate({
                  pathname: "manage-questions/new",
                  search: searchParams.toString(),
                })
            : undefined
        }
        onImport={
          resourceAllocate("question.write") && status!=="ARCHIVED" && status!=="PUBLISHED"
            ? () => navigate({
              pathname:"import-questions/import",
              search: searchParams.toString(),
            })
            : undefined
        }
        onFilter={openFilter}
        filterCount={state.filterCount}
        >
          <Filters
            anchorEl={state.filterDialog.anchorEl}
            isOpen={state.filterDialog.isOpen}
            OnClose={closeFilter}
          />
        </Header>
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
          isDND={true}
          draggableId="drag"
          showPagination={false}
          onDrag={resourceAllocate("question.write") && status!=="ARCHIVED" && status!=="PUBLISHED" ? onDrag : undefined}
        />
      </Box>
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Question"
        description="Are you sure you want to delete this Question?"
      />

      <Outlet context={{ ...Outlet, reFetch: testQuestions.refetch }} />
    </div>
  );
};
export default TestQuestions;
