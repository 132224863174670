import { Box, Button, Grid, InputLabel, Link, MenuItem, TextField } from "@mui/material";
import { IVendorEdit } from "../../../../../interfaces/vendor";
import CustomDialog from "../../../../../components/mui/dialog";
import { Controller, useForm } from "react-hook-form";
import { IErrorResponse } from "../../../../../interfaces/shared/response";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { FC, useEffect, ChangeEvent } from "react";
import { capitalize } from "../../../../../utilities/helper";
import { useQuery } from "@tanstack/react-query";
import Select from "../../../../../components/mui/select";
import HttpService from "../../../../../services/http";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CustomLabel from "../../../../../components/mui/custom-label";
import { VendorTransactionService } from "../../../../../services/vendor/transaction";
import { IVendorTransaction, IVendorTransactionField } from "../../../../../interfaces/vendor/transaction";
import { VendorPaymentModeService } from "../../../../../services/vendor/payment-mode";
import { VendorContactPersonService } from "../../../../../services/vendor/contact-person";
import { joiResolver } from "@hookform/resolvers/joi";
import { vendorTransactionValidation } from "../../../../../validations/vendor/manage-vendor/transaction";

interface props {
  transaction: string;
  isOpen: boolean;
  onClose: () => void;
  vendor: IVendorEdit | undefined;
  refetch: () => void;
}

const ManageVendorTransactionDetail: FC<props> = ({ transaction, isOpen, onClose, vendor , refetch}) => {
  const { updateTransaction, getTransaction } = VendorTransactionService();
  const { httpFormRequest } = HttpService();
  const { getPaymentModes } = VendorPaymentModeService();
  const { getContactPersons } = VendorContactPersonService();
  const { snackbar } = useSnackbar();
  const { handleSubmit, control, getValues, setValue, trigger, watch, formState: { errors } } = useForm<IVendorTransaction>({
    resolver: joiResolver(vendorTransactionValidation),
    defaultValues: {
      mode: "",
      name: "",
      upiId: "",
      bankName: "",
      invoice: "",
      poc: "",
      status: "",
      paymentDate: "",
      amount: 0,
      accountNumber: "",
      ifsc: "",
      note: ""
    }
  });

  const transactionData = useQuery({
    queryKey: ["single-transaction",transaction],
    queryFn: () =>
      getTransaction({
        _id: transaction
      }),
      enabled: !!transaction && isOpen
  });

  useEffect(() => {
    if (transactionData?.data?.data) {
      const transaction = transactionData.data.data;
      setValue("name", transaction.name);
      setValue("mode", transaction.mode);
      setValue("upiId", transaction.upiId);
      setValue("bankName", transaction.bankName);
      setValue("accountNumber", transaction.accountNumber);
      setValue("ifsc", transaction.ifsc);
      setValue("invoice", transaction.invoice);
      setValue("poc", transaction.poc);
      setValue("status", transaction.status);
      setValue("paymentDate", transaction.paymentDate);
      setValue("amount", transaction.amount);
      setValue("note", transaction.note);
    }
  }, [transactionData?.data?.data, isOpen]);

  const onSubmit = async (data: IVendorTransaction) => {
    try {
      const payload = {
        ...data,
        _id: transactionData?.data?.data?._id
      };
      delete payload._vendor;
      if (watch("mode") === "CASH") {
        delete payload.upiId;
        delete payload.accountNumber;
        delete payload.bankName;
        delete payload.ifsc;
      } else if (watch("mode") === "UPI") {
        delete payload.ifsc;
        delete payload.accountNumber;
      } else {
        delete payload.upiId;
      }
      const user = await updateTransaction(payload);
      snackbar(user.message, "info");
      refetch();
      onClose();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log("error in manage contract", error);
    }
  };


  const paymentModes = useQuery({
    queryKey: ["allModes"],
    queryFn: () =>
      getPaymentModes({
        _vendor: vendor?._id
      }),
    enabled: !!transaction && isOpen
  });
  const nameChildren = paymentModes?.data?.data?.map(mode => <MenuItem key={mode.name} value={mode.name}>{`${capitalize(mode.name)}`}</MenuItem>);
  if (!paymentModes?.data?.data?.some(mode => mode.name === transactionData?.data?.data.name)) {
    nameChildren && nameChildren.push(
      <MenuItem 
        key={transactionData?.data?.data.name} 
        value={transactionData?.data?.data.name}
        disabled
      >
        {capitalize(transactionData?.data?.data.name || "")}
      </MenuItem>
    );
  }

  const contactPersons = useQuery({
    queryKey: ["allContactPersons"],
    queryFn: () =>
      getContactPersons({
        _vendor: vendor?._id
      }),
    enabled: !!transaction && isOpen
  });

  const selectDate = (value: string | number | Date | dayjs.Dayjs | null | undefined, name: string) => {
    const date = value && dayjs(value).toString() !== "Invalid Date" ? dayjs(value)?.toISOString() : undefined;
    const keyExist = name === "paymentDate";
    if (keyExist) {
      setValue(name, date);
      trigger(name);
    }
  };

  useEffect(() => {
    const selectedName = watch("name");
    if (selectedName && paymentModes.data) {
      const selectedMode = paymentModes.data.data.find(mode => mode.name === selectedName);
      if (selectedMode) {
        setValue("mode", selectedMode.paymentMode);
        setValue("upiId", selectedMode.upiId || "");
        setValue("bankName", selectedMode.bankName || "");
        setValue("ifsc", selectedMode.ifsc || "");
        setValue("accountNumber", selectedMode.accountNumber || "");
      }
    }
  }, [watch("name"), paymentModes.data]);

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const uploaded = await httpFormRequest<{ data: string }>(
        e.target.files,
        e.target.files ? e.target.files[0].name : "",
        ["doc", "docx", "pdf", "png", "jpeg", "jpg"],
        10
      );
      setValue("invoice", uploaded.data.split("uploads")[1]);
    } catch (error) {
      console.log("error in candidate detail upload", error);
    }

  };


  const fields: IVendorTransactionField[] = [
    {
      type: "select",
      name: "name",
      label: "Name",
      children: nameChildren,
      required: true
    },
    {
      type: "input",
      name: "mode",
      label: "Payment Mode",
      required: true,
      disabled: true
    },
    {
      type: "input",
      name: "upiId",
      label: "UPI ID",
      required: true,
      disabled: true,
    },
    {
      type: "input",
      name: "bankName",
      label: "Bank Name",
      required: true,
      disabled: true,
    },
    {
      type: "input",
      name: "ifsc",
      label: "IFSC",
      required: true,
      disabled: true,
    },
    {
      type: "input",
      name: "accountNumber",
      label: "Account Number",
      required: true,
      disabled: true,
    },
    {
      type: "select",
      name: "poc",
      label: "POC",
      children: contactPersons?.data?.data.map(per => <MenuItem key={per.name} value={per.name}>{`${capitalize(capitalize(per.name))}`}</MenuItem>),
      required: true
    },
    {
      type: "select",
      name: "status",
      label: "Status",
      required: true,
      children: [
        <MenuItem key="DUE" value="DUE" >Due</MenuItem>,
        <MenuItem key="FAILED" value="FAILED" >Failed</MenuItem>,
        <MenuItem key="SUCCESSFUL" value="SUCCESSFUL" >Successful</MenuItem>
      ]
    },
    {
      type: "input",
      name: "amount",
      label: "Amount",
      required: true,
    },
    {
      label: "Payment Date",
      name: "paymentDate",
      type: "date",
      required: true
    },
    {
      label: "Note",
      name: "note",
      type: "input"
    },
    {
      label: "Invoice",
      name: "invoice",
      type: "doc",
      required: true
    }
  ];

  const filteredFields = fields.filter((field) => {
    if (watch("mode") === "ACCOUNT") {
      return !["upiId"].includes(field.name);
    } else if (watch("mode") === "UPI") {
      return !["ifsc", "accountNumber"].includes(field.name);
    } else if (watch("mode") === "CASH") {
      return !["ifsc", "bankName", "accountNumber", "upiId"].includes(field.name);
    } else {
      return ["name", "mode", "poc", "status", "amount", "paymentDate", "invoice", "note"].includes(field.name);
    }
  });


  return (
    <Box>
      <CustomDialog
        title={"Edit Transaction Details"}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4}>
          {
            filteredFields.map(field => {
              if (field.type === "input") {
                return (<Grid key={field.label} item xs={4}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <TextField
                      label={<CustomLabel label={field.label} required={field?.required} />}
                      className="disable-text"
                      disabled={field?.disabled}
                      variant={"outlined"}
                      size={"small"}
                      placeholder={field.placeholder}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      type={field.name === "amount" || field.name === "accountNumber" ? "number" : "text"}
                      {...prop.field}
                    />}
                  />
                </Grid>
                );
              } else if (field.type === "select") {
                return (<Grid key={field.label} item xs={4} >
                  <Select
                    control={control}
                    className="disable-text"
                    name={field.name}
                    label={<CustomLabel label={field.label} required={field?.required} />}
                    error={!!errors[field.name]}
                    helperText={errors[field.name]?.message}
                  >
                    {field.children}
                  </Select>
                </Grid>
                );
              } else if (field.type === "date") {
                return (<Grid key={field.label} item xs={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      label={<CustomLabel label={field.label} required={field?.required} />}
                      onChange={(e) => selectDate(e, field.name)}
                      value={dayjs(getValues(field.name))}
                      slotProps={{
                        textField: {
                          error: errors[field.name] ? true : false,
                          helperText: errors[field.name]?.message
                        }
                      }}
                      format="LL"
                    />
                  </LocalizationProvider>
                </Grid>
                );
              } else {
                const hasValue = getValues(field.name);
                return (
                  <Grid key={field.label} item xs={4} >
                    <div>
                      <div style={{ color: "rgb(118, 118, 118)", marginTop: "-17px", marginBottom: "3px" }}>
                        {hasValue &&
                          <label>{field.label}</label>
                        }
                      </div>
                      <Grid container spacing={2}>
                        {hasValue && (
                          <Grid item xs>
                            <Link href={!getValues(field.name)?.toString().includes("https://") ? String(process.env.REACT_APP_S3_BASE_URL) + getValues(field.name)?.toString() : getValues(field.name)?.toString()} target="_blank" underline="none" color="inherit">
                              <Button variant="outlined" fullWidth>
                                Preview
                              </Button>
                            </Link>
                          </Grid>
                        )}
                        {!hasValue && (
                          <Grid item xs>
                            <InputLabel id={`upload-${field.name}`} >
                              <Button component="label" variant="outlined" fullWidth>
                                Upload {field.label}*
                                <input hidden type="file" id={`upload-${field.name}`} onChange={e => uploadFile(e)} accept="application/pdf, image/png, image/jpeg, .doc, .docx" />
                              </Button>
                            </InputLabel>
                            {errors[field.name] && <span style={{ color: "#d32f2f", fontWeight: 400, fontSize: "0.85rem" }}>{errors[field.name]?.message}</span>}
                          </Grid>
                        )}
                        {hasValue && (
                          <Grid item xs>
                            <Button onClick={() => {
                              setValue(field.name, "");
                              trigger(field.name);
                            }} variant="outlined" color="error" fullWidth>
                              Delete
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  </Grid>
                );
              }
            })
          }
        </Grid>
      </CustomDialog>
    </Box>
  );
};

ManageVendorTransactionDetail.defaultProps = {
  isOpen: false
};

export default ManageVendorTransactionDetail;
