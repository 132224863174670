import Header from "../../../components/header";
import CustomTable from "../../../components/mui/table";
import { Outlet } from "react-router-dom";
import { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import useDebounce from "../../../hooks/useDebounce";
import { IScoreData, IScoreDataRow, IScoreStatus } from "../../../interfaces/content/score-data";
import ScoreDataService from "../../../services/content/score-data";
import useSnackbar from "../../../hooks/useSnackbar";
import { useQuery } from "@tanstack/react-query";
import { IColumn, IErrorResponse } from "../../../interfaces";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import { Box, Checkbox, Typography } from "@mui/material";
import { handleSelect, handleSelectAll } from "../common/helper";
import useResource from "../../../hooks/useResource";
import CustomTypography from "../../../components/mui/max-length-limit";

const ScoreData = () => {

    const [search, setSearch] = useState("");
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const { resourceAllocate } = useResource();
    const [openState, setOpenState] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const { getContentsScoreDatas, deleteContentSCoreData } = ScoreDataService();
    const [state, setState] = useState<IScoreStatus>({
        multiDeleteWarning: false,
        selectAll: [],
        pagination: {
            page: 1,
            limit: 20,
            totalPages: 1,
            totalRecords: 0
        },
        searching: {
            search: ""
        },
        deleteWarning: false,
        _deleteId: ""
    });


    const scoreDataList = useQuery({
        queryKey: ["scoreData", state.pagination.page, state.searching],
        queryFn: () => getContentsScoreDatas({
            pagination: true,
            page: state.pagination.page,
            limit: state.pagination.limit,
            ...state.searching,
        })

    });

    useEffect(() => {
        if (scoreDataList?.data?.data?.length) {

            setState(prevState => ({
                ...prevState,
                pagination: {
                    ...prevState.pagination,
                    page: scoreDataList?.data?.meta.page,
                    totalPages: scoreDataList?.data?.meta.totalPages,
                    totalRecords: scoreDataList?.data?.meta?.totalRecords
                }
            }));
        }

    }, [scoreDataList?.data?.meta]);

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

    const searchRecord = useDebounce(search, 1000);

    useEffect(() => {
        if (searchRecord.length) {
            setSearchParams(prev => ({
                ...prev,
                page: 1,
                search: searchRecord
            }));
        } else {
            searchParams.delete("search");
            setSearchParams(searchParams);
        }

    }, [searchRecord]);

    useEffect(() => {
        const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
        const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
        setState(prev => ({
            ...prev,
            pagination: {
                ...prev.pagination,
                page: page
            },
            searching: {
                ...prev.searching,
                search: search
            }
        }));

    }, [searchParams]);

    const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    };

    const isChecked = (scoreDataList.data?.data?.length && state.selectAll?.length === scoreDataList.data?.data?.length) ? true : false;
    const isIndeterminateChecked = (state.selectAll.length > 0 && state.selectAll.length < Number(scoreDataList.data?.data.length)) ? true : false;
    const columns: IColumn[] = [
        {
            id: "all",
            label: <Checkbox onChange={e => handleSelectAll(e, scoreDataList?.data?.data ? scoreDataList?.data?.data : [], setState)} checked={isChecked} indeterminate={isIndeterminateChecked} />,
            maxWidth: 10
        },
        {
            id: "name",
            label: "Score Data",
        },
        {
            id: "description",
            label: "Description",
        },
        {
            id: "action",
            label: "Actions",
            align: "right"
        }
    ];

    const openManageComponent = () => {
        setOpenState(true);
        navigate("/content/score-data/new");
    };

    const handleDelete = (_deleteId = "") => {
        setState(prevState => ({
            ...prevState,
            deleteWarning: !prevState.deleteWarning,
            _deleteId: _deleteId
        }));
    };

    const onDelete = async () => {
        try {
            const response = await deleteContentSCoreData({ ids: [state._deleteId] });
            setState(prevState => ({
                ...prevState,
                selectAll : prevState.selectAll.filter((del) => del !== state._deleteId)
            }));
            snackbar(response?.message, "info");
            handleDelete();
            scoreDataList.refetch();
            navigate(
                {pathname : "/content/score-data",
                search: searchParams.toString()}
            );
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err?.data?.message, "error");
            console.log("Error in deletes of score Data", err);
        }
    };

    const handleMultiDelete = () => setState(prevState => ({
        ...prevState,
        multiDeleteWarning: !prevState.multiDeleteWarning
    }));
    const onMultiDelete = async () => {
        try {
            const goal = await deleteContentSCoreData({ ids: state.selectAll });
            snackbar(goal.message, "info");
            scoreDataList.refetch();
            setState(prevState => ({
                ...prevState,
                multiDeleteWarning: false,
                selectAll: []
            }));
            navigate(
                {pathname : "/content/score-data",
                search: searchParams.toString()}
            );
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            handleMultiDelete();
            console.log({ "Error in delete scores data": error });
        }
    };

    const createRows = (score: IScoreData, selectedAll: string[]) => {
        const action = <GetActions
            icons={[
                {
                    name: "Edit", method: () => {
                        setOpenState(true);
                        navigate({
                            pathname: "" + score._id,
                            search: searchParams.toString()
                        });
                    },
                    disabled: resourceAllocate("cms-score-data.write") ? false : true

                },
                { name: "Delete", method: () => handleDelete(score._id), disabled: resourceAllocate("cms-score-data.remove") ? false : true },
            ]}
        />;

        const description = (<Box display="flex" alignItems="center">
            <CustomTypography
                limit={70}
                label={score?.description}
            />
        </Box>
        );
        return {
            all: <Checkbox onChange={e => handleSelect(e, score._id, state, setState)} checked={selectedAll.includes(score._id)} />,
            name: score?.name,
            description: description,
            action
        };
    };

    let rows: IScoreDataRow[] = [];
    if (scoreDataList?.data?.data?.length) {
        rows = scoreDataList?.data?.data?.map((score: IScoreData) => createRows(score, state.selectAll));
    }

    return (
        <div>
            {openState && <Outlet context={{ ...Outlet, reFetch: scoreDataList.refetch }} />}
            <Header
                className="my-2"
                searchPlaceholder="Search by name"
                onSearch={onSearch}
                onDelete={resourceAllocate("cms-score-data.remove") ? handleMultiDelete : undefined}
                isDeleteDisable={state.selectAll.length ? false : true}
                btnText="Add Score Data"
                onBtnClick={resourceAllocate("cms-score-data.write") ? () => openManageComponent() : undefined}
            />
            <Box display="flex" className="mb-2" justifyContent="flex-end" alignItems="center">
                <Box display="flex" alignItems="center">
                    <Typography variant="body1">Total Score Data:</Typography>
                    <Typography className="ml-3" variant="body1" >{scoreDataList?.data?.meta?.totalRecords}</Typography>
                </Box>
            </Box>
            <CustomTable
                rows={rows}
                columns={columns}
                height="calc(100vh - 285px)"
                pagination={state.pagination}
                onPageChange={onPageChange}
            />

            <WarningDialog
                isOpen={state.deleteWarning}
                onClose={() => handleDelete()}
                onConfirm={onDelete}
                title="Delete Score-data"
                description="Are you sure you want to delete this score-data?"

            />
            {/* Multiple Delete  */}
            <WarningDialog
                isOpen={state.multiDeleteWarning}
                onClose={() => handleMultiDelete()}
                onConfirm={onMultiDelete}
                title="Delete All Score-data"
                description={`Are you sure you want to delete ${state.selectAll.length} selected scores-data?`}
            />
        </div>
    );
};

export default ScoreData;