import Joi from "joi";
import { required } from "../../shared";
export const authorValidation = Joi.object({
  name: Joi.string()
  .required()
  .label("Title")
  .messages({
      "string.empty":required
  }),
  authorId: Joi.number()
  .required()
  .label("Author ID")
  .messages({
      "number.empty":required
  }),
  _productId: Joi.string()
  .required()
  .label("Prodcut ID")
  .messages({
      "string.empty":required
  }),
});
