import { IJobPosition } from "../../../../interfaces";
import { LOAD_JOB_POSITION } from "../../../actions";

interface IAction {
    type: "LOAD_JOB_POSITION",
    payload: IJobPosition
}

interface IState {
    list: IJobPosition[]
}

const initialState: IState = {
    list: [
        {
            createdAt: "",
            name: "",
            _id: "",
            createdBy: {
                name: ""
            },
            _department: {
                name: "",
                _id: ""
            }
        }
    ]
};

const jobPositionReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case LOAD_JOB_POSITION: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
};

export { jobPositionReducer };
