import { IVendorsTransactionResponse, IVendorsTransactionsResponse } from "../../../interfaces/vendor/transaction";
import HttpService from "../../http";
import { vendorTransaction } from "../../endpoints";

const VendorTransactionService = () => {
    const { httpRequest } = HttpService();

    const addTransaction = async (payload: object): Promise<IVendorsTransactionsResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsTransactionsResponse>(
            "POST",
            `${vendorTransaction}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updateTransaction = async (payload: object): Promise<IVendorsTransactionResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsTransactionResponse>(
            "PUT",
            `${vendorTransaction}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getTransactions = async (search: object): Promise<IVendorsTransactionsResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsTransactionsResponse>(
            "GET",
            `${vendorTransaction}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getTransaction = async (search: object): Promise<IVendorsTransactionResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsTransactionResponse>(
            "GET",
            `${vendorTransaction}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    return { addTransaction, updateTransaction, getTransaction, getTransactions };
};

export { VendorTransactionService };