import Joi from "joi";
import { required } from "../../shared";

export const JobValidation = Joi.object({
    title: Joi.string()
        .required()
        .label("Job Title")
        .messages({
            "string.empty": required
        }),
    type: Joi.string()
        .required()
        .label("Engagement Type")
        .messages({
            "string.empty": required
        }),
    assignTo: Joi.string()
        .required()
        .label("Assign To")
        .messages({
            "string.empty": required
        }),
    minimumExperience: Joi.number()
        .required()
        .label("Minimum Experience")
        .messages({
            "number.empty": required
        }),
    maximumExperience: Joi.number()
        .required()
        .label("Maximum Experience")
        .messages({
            "number.empty": required
        }),
    location: Joi.string()
        .required()
        .label("Work Location")
        .messages({
            "string.empty": required
        }),
    jobCategory: Joi.string()
        .required()
        .label("Job Category")
        .messages({
            "string.empty": required
        }),
    skill: Joi.string()
        .allow(""),
    skills: Joi.array()
        .allow(""),
    openings: Joi.number()
        .required()
        .label("Number Of Openings")
        .messages({
            "number.empty": required
        }),
    status: Joi.string()
        .required()
        .label("Job Status")
        .messages({
            "string.empty": required
        }),
    department: Joi.string()
        .required()
        .label("Team")
        .messages({
            "string.empty": required
        }),
    departmentId: Joi.string()
        .required()
        .label("Team")
        .messages({
            "string.empty": required
        }),
    shortDescription: Joi.string()
        .required()
        .min(3)
        .trim()
        .max(200)
        .label("Short Description")
        .messages({
            "string.empty": required
        }),
    description: Joi.string()
        .min(3)
        .trim()
        .required()
        .label("Long Description")
        .messages({
            "string.empty": required
        }),
    urlSlug: Joi.string()
        .required()
        .label("Slug")
        .messages({
            "string.empty": required
        }),
});
