import { Box, Button, Chip, DialogContent, Divider, Drawer, Grid, IconButton, TextField } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { RequestedSkillService } from "../../../services/requested-skill";
import { capitalize } from "../../../utilities/helper";
import CustomTable from "../../../components/mui/table";
import { useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { IUpdateRequestedSkillRow } from "../../../interfaces";
import useSnackbar from "../../../hooks/useSnackbar";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../components/shared/mui-tabs";
import useUser from "../../../hooks/useUser";
import CloseIcon from "@mui/icons-material/Close";

interface IOutletProps {
    reFetch: () => void
}

const UpdateRequest = () => {
    const { user } = useUser();
    const [rows, setRows] = useState<IUpdateRequestedSkillRow[]>([]);
    const { id } = useParams();
    const navigate = useNavigate();
    const outlet = useOutletContext<IOutletProps>();  
    const [searchParam] = useSearchParams();
    const { snackbar } = useSnackbar();
    const { getRequestedSkill, updateStatus } = RequestedSkillService();
    const hitQuery = (id === "new" || id === "view") ? false : true;
    const requestedSkill = useQuery({ queryKey: [hitQuery], queryFn: () => getRequestedSkill({ _id: id }), enabled: hitQuery });
    const [reasonForReject,setReasonForReject] = useState("");

    const onStatusUpdate = async (checked: boolean, id: string, reason ?: string) => {
        const status = checked ? "REJECTED" : "APPROVED";
        try {
            const payload = {
            _id: id,
            status,
            ...(status === "REJECTED" && reason && { reason } ),
        };
            const statusUpdate = await updateStatus(payload);
            snackbar(statusUpdate.message, "info");
            onClose();
            requestedSkill.refetch();
            outlet?.reFetch && outlet.reFetch();
        } catch (error) {
            console.log("Error in status update Request", error);
        }
    };
    

    const columns = [
        {
            id: "skillItems",
            label: "Skill Items"
        },
        {
            id: "currentLevel",
            label: "Current Level"
        },
        {
            id: "updatedLevel",
            label: "Updated Level"
        },
    ];

    const createRows = () => {
        const items = requestedSkill.data?.data?.items || [];
        return items.map(item => ({
            skillItems: capitalize(item._itemId.name),
            currentLevel: Number(item.previousScore) || 0,
            updatedLevel: Number(item.nextScore) || 0,
        }));
    };

    useEffect(() => {
        if (requestedSkill.isSuccess) {
            const newRows = createRows();
            setRows(newRows);
        }
    }, [requestedSkill?.data?.data]);

    const chipLabel = capitalize(requestedSkill.data?.data?.createdBy?.name || "");

    const onClose = () => {
        navigate({
            pathname: "/requested-skill",
            search: searchParam.toString()
        });
    };

    const [open,setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <>
        <Drawer anchor="right"
            open={id ? true : false}
            onClose={onClose}
        >
            
            <Box sx={{ width: 500, height: "100vh" }}>
                <Box className="center" justifyContent="space-between" sx={{
                    padding: "16px 20px",
                    position: "sticky",
                    top: 0,
                    right: 0,
                    zIndex: 1,
                    bgcolor: "#f8f8f8"
                }}
                />

                <Box className="update-request-box">
                    <Box className="update-req-inner-box">
                        <strong style={{ paddingLeft: "30px" }}>Update Request</strong>
                        <Grid item xs={12}>
                            <Chip
                                label={chipLabel}
                                className="user-chip"
                            />
                        </Grid>
                    </Box>

                    <IconButton onClick={onClose} size="small" style={{marginRight: "20px", height:"30px"}}>
                        <CloseIcon />
                    </IconButton>
                </Box>


                <Box className="req-skill-table">
                    {requestedSkill.isSuccess && requestedSkill.data?.data && (
                        <CustomTable
                            columns={columns}
                            rows={rows}
                            height="calc(100vh - 191.3px)"
                        />
                    )}

                </Box>
                

                {
                    <>
                        <Box className="action-box">
                            {
                                user._id !== requestedSkill.data?.data?.createdBy?._id && requestedSkill.data?.data?.status !== "REJECTED" && (
                                    <Button
                                        onClick={() => {handleOpen();}
                                        }
                                    >
                                        Reject
                                    </Button>
                                )
                            }
                            {
                                user._id !== requestedSkill.data?.data?.createdBy?._id && requestedSkill.data?.data?.status !== "APPROVED" && (
                                    <Button
                                        onClick={() =>
                                            requestedSkill.data?.data?._id && onStatusUpdate(false, requestedSkill.data?.data?._id,"")
                                        }
                                    >
                                        Approve
                                    </Button>
                                )
                            }
                        </Box>
                    </>
                }
            </Box>
        </Drawer>
        <BootstrapDialog
                open={open}
                onClose={handleClose}
                style={{ height: "450px", marginTop: "80px" }}
                >
                    <BootstrapDialogTitle
                    onClose={onClose}
                    >
                     <Box sx={{textAlign:"center"}}>Reason for Rejection</Box>
                     

                    </BootstrapDialogTitle>
                    <Divider/>
                    <DialogContent sx={{padding:"20px"}} >

                       <Box  sx={{paddingTop:"5px"}}>
                        <TextField 
                       sx={{height:"210px"}}
                       id="outlined-multiline-static"
                       label="Reason..."
                       multiline
                       rows={5}
                       value={reasonForReject}
                        onChange={(e) => setReasonForReject(e.target.value)}
                    />
                        </Box>
                    <Box className="action-box">
                        <Divider sx={{ marginBottom: "20px" }} />
                        {
                            requestedSkill?.data?.data?.status !== "REJECTED" && (
                            <Button type="submit" onClick={() => {requestedSkill?.data?.data?._id && onStatusUpdate(true, requestedSkill?.data?.data?._id,reasonForReject);}}>
                            Okay
                            </Button>
                            )
                        }



                        
                    </Box>
                    </DialogContent>
                </BootstrapDialog>
        </>

    );
};

export default UpdateRequest;