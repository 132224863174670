import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { JobPositionService } from "../../../../services";
import { jobPositionValidation } from "../../../../validations";
import { IErrorResponse, IJobPositionField, IJobPositionValues, ITeam } from "../../../../interfaces";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import { capitalize } from "../../../../utilities/helper";
import CustomLabel from "../../../../components/mui/custom-label";
import { useSelector } from "react-redux";
import SearchSelect from "../../../../components/mui/search-select";
interface outletProps {
    reFetch: () => void;
    refetchJobPositions: () => void;
}

const ManageJobPosition = () => {
    const { id } = useParams();
    const [searchParam] = useSearchParams();
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();
    const hitQuery = (id === "new" || id === "view") ? false : true;
    const navigate = useNavigate();
    const { getJobPosition, addJobPositions, updateJobPosition } = JobPositionService();
    const jobPositions = useQuery({ queryKey: [hitQuery], queryFn: () => getJobPosition({ _id: id }), enabled: hitQuery });
    const teams = useSelector<{ team: { list: ITeam[] } }, ITeam[]>(
        (state) => state.team.list
    );
    const { handleSubmit, control, setValue, trigger, getValues, formState: { errors } } = useForm<IJobPositionValues>({
        resolver: joiResolver(jobPositionValidation),
        defaultValues: {
            name: "",
            _department: ""
        }
    });

    useEffect(() => {
        if (id !== "new") {
            if (jobPositions.data?.data) {
                setValue("name", capitalize(jobPositions.data.data?.name) || "");
                setValue("_department", jobPositions.data.data._department && jobPositions.data.data._department._id || "");
                trigger("_department");
            }
        }
    }, [id, jobPositions.data]);

    const onSubmit = async (data: IJobPositionValues) => {
        try {
            if (id === "new") {
                const payload = { ...data };
                const add = await addJobPositions(payload);
                snackbar(add.message, "info");
                navigate("/configurations/designation");
                outlet?.reFetch && outlet.reFetch();
                outlet?.refetchJobPositions && outlet.refetchJobPositions();
            }
            else {
                const payload = { ...data, _id: id };
                const update = await updateJobPosition(id ? id : "", payload);
                snackbar(update.message, "info");
                navigate({
                    pathname: "/configurations/designation",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
                outlet?.refetchJobPositions && outlet.refetchJobPositions();
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }

    };

    const onClose = () => {
        navigate({
            pathname: "/configurations/designation",
            search: searchParam.toString()
        });
    };

    const fields: IJobPositionField[] = [
        {
            label: "Name",
            name: "name",
            type: "input",
            placeholder: "Type designation here",
            required: true
        },
        {
            label: "Department",
            name: "_department",
            type: "select",
            required: true,
            options: teams.map((i) => ({
                key: i._id,
                value: capitalize(i.name)
            })),
            displayFieldKey: "value",
            storeFieldKey: "key",
        },
    ];

    return (
        <Box>
            <CustomDialog
                size='sm'
                title={id !== "new" ? "Edit Designation" : "Add Designation"}
                confirmText={id !== "new" ? "Update Designation" : "Add Designation"}
                isOpen={!!id}
                onClose={onClose}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "input") {
                                return (<Grid key={field.label} item xs={12}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={<CustomLabel label={field.label} required={field.required} />}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            placeholder={field.placeholder}
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            } else {
                                return (<Grid key={field.label} item xs={12}>
                                    <SearchSelect
                                        name={field.name}
                                        label={<CustomLabel label={field.label} required={field.required} />}
                                        error={!!errors[field.name]}
                                        helperText={errors[field.name]?.message}
                                        options={field.options}
                                        displayFieldKey={field.displayFieldKey ? field.displayFieldKey : ""}
                                        storeFieldKey={field.storeFieldKey ? field.storeFieldKey : ""}
                                        displayUserName={field.displayUserName}
                                        capitalize={field.capitalize}
                                        trigger={trigger}
                                        setValue={setValue}
                                        getValues={getValues}
                                    />
                                </Grid>
                                );
                            }
                        })
                    }
                </Grid>
            </CustomDialog>
        </Box>
    );
};

export default ManageJobPosition;