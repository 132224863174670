import { IVendorsCategoryResponse, IVendorsCategoryyResponse } from "../../../interfaces/vendor/vendor-categories";
import HttpService from "../../http";
import { vendorCategory } from "../../endpoints";

const VendorCategoryService = () => {
    const { httpRequest } = HttpService();

    const getVendorCategory = async (search: object): Promise<IVendorsCategoryResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsCategoryResponse>(
            "GET",
            `${vendorCategory}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getVendorCategoryy = async (search: object): Promise<IVendorsCategoryyResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsCategoryyResponse>(
            "GET",
            `${vendorCategory}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getVendorPartialCategory = async (search: object): Promise<IVendorsCategoryResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsCategoryResponse>(
            "GET",
            `${vendorCategory}/partial/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteVendorCategory = async (payload: object): Promise<IVendorsCategoryResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsCategoryResponse>(
            "DELETE",
            `${vendorCategory}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const addVendorCategory = async (payload: object): Promise<IVendorsCategoryResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsCategoryResponse>(
            "POST",
            `${vendorCategory}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updateVendorCategory = async (payload: object): Promise<IVendorsCategoryResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsCategoryResponse>(
            "PUT",
            `${vendorCategory}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { getVendorPartialCategory, getVendorCategory, deleteVendorCategory, addVendorCategory, updateVendorCategory, getVendorCategoryy };
};

export { VendorCategoryService };