import Joi from "joi";
import { required } from "../../../shared";

export const vendorTransactionValidation = Joi.object({
    name: Joi.string()
        .required()
        .trim()
        .label("Name")
        .messages({
            "string.empty": required,
        }),
    mode: Joi.string()
        .required()
        .valid("UPI", "CASH", "ACCOUNT")
        .label("Payment Mode")
        .messages({
            "string.empty": required,
        }),
    bankName: Joi.string()
        .label("Bank Name")
        .when("mode", {
            is: Joi.valid("UPI", "ACCOUNT"),
            then: Joi.string().required().messages({
                "string.empty": required,
            }),
            otherwise: Joi.optional().allow("")
        }),
    upiId: Joi.string()
        .label("UPI ID")
        .when("mode", {
            is: "UPI",
            then: Joi.string().required().messages({
                "string.empty": required,
            }),
            otherwise: Joi.optional().allow("")
        }), 
    ifsc: Joi.string()
        .label("IFSC Code")
        .when("mode", { 
            is: "ACCOUNT",
            then: Joi.string()
                .required()
                .messages({
                    "string.empty": required,
                }),
            otherwise: Joi.optional().allow("")
        }),
    accountNumber: Joi.string()
        .label("Account Number")
        .when("mode", {
            is: "ACCOUNT",
            then: Joi.string()
                .required()
                .min(8)
                .max(18)
                .messages({
                "   string.empty": required,
                }),
            otherwise: Joi.optional().allow("")
        }),
    poc: Joi.string()
        .required()
        .label("POC")
        .messages({
            "string.empty": required,
        }),
    status: Joi.string()
        .required()
        .label("Status")
        .messages({
            "string.empty": required,
        }),
    amount: Joi.number()
        .required()
        .label("Amount")
        .messages({
            "number.empty": required,
        }),
    note: Joi.string()
        .optional()
        .allow("")
        .label("Note")
        .messages({
            "string.empty": required,
        }),
    paymentDate: Joi.string()
        .required()
        .label("Payment Date")
        .messages({
            "string.empty": required,
        }),
    invoice: Joi.string()
        .required()
        .label("Invoice")
        .messages({
            "string.empty": required,
        }),
});
