import "./App.scss";
import "./assets/css/bootstrap/style.scss";
import { useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { SnackbarKey } from "notistack";
import message from "./assets/json/message.json";
import theme from "./assets/theme";
import routes from "./routes";
import useSnackbar from "./hooks/useSnackbar";
import { UserProvider } from "./hooks/useUser";

function App() {
  const { snackbar, closeSnackbar } = useSnackbar();
  const [snackbarId, setSnackbarId] = useState<SnackbarKey>();

  const handleOnlineStatus = () => {
    if (!navigator.onLine) {
      const id = snackbar(message.EN.offline, "default", true);
      setSnackbarId(id);
    } else {
      closeSnackbar(snackbarId);
    }
  };

  useEffect(() => {
    if (!navigator.onLine) {
      snackbar(message.EN.offline, "default", true);
    }
    window.addEventListener("offline", handleOnlineStatus);
    window.addEventListener("online", handleOnlineStatus);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <RouterProvider router={routes} />
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;