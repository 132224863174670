import { SyntheticEvent, useEffect } from "react";
import { ICollege, IErrorResponse } from "../../../../interfaces";
import { useQuery } from "@tanstack/react-query";
import { HighLightService } from "../../../../services";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { HighLightValidation } from "../../../../validations";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { Autocomplete, AutocompleteChangeReason, Box, Grid, TextField } from "@mui/material";
import Select from "../../../../components/mui/select";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import { capitalize } from "../../../../utilities/helper";
import { useSelector } from "react-redux";
import { IHighlightField, IHighLight } from "../../../../interfaces/careers/highlights";
import CustomLabel from "../../../../components/mui/custom-label";

interface outletProps {
  reFetch: () => void
}

const ManageHighlight = () => {
  const { id } = useParams();
  const [searchParam] = useSearchParams();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const hitQuery = ["new", "view"].includes(id ?? "") ? false : true;
  const [...colleges] = useSelector<{ college: { list: ICollege[] } }, ICollege[]>(state => state.college.list) || [];
  const navigate = useNavigate();
  const { addHighLight, getHightLight, updateHightLight } = HighLightService();
  const highlight = useQuery({ queryKey: [hitQuery], queryFn: () => getHightLight({ _id: id }), enabled: hitQuery });
  const { handleSubmit, control, setValue, trigger, getValues, formState: { errors } } = useForm<IHighLight>({
    resolver: joiResolver(HighLightValidation),
    defaultValues: {
      collegeId: "",
      title: "",
      youtubeUrl: "",
      description: ""
    }
  });

  const fields: IHighlightField[] = [
    {
      label: "Title*",
      name: "title",
      type: "input",
      placeholder: "Type title here",
    },
    {
      label: "College",
      name: "collegeId",
      type: "select",
      options: colleges,
      displayFieldKey: "name",
      storeFieldKey: "_id",
      capitalize: true
    },
    {
      label: "Youtube Url*",
      name: "youtubeUrl",
      type: "input",
      placeholder: "Type url here",
      width: 12
    },
    {
      label: "Description",
      name: "description",
      type: "multiline",
      placeholder: "Type description here",
      width: 12
    },
  ];
  
  useEffect(() => {
    if (id !== "new" && highlight.data?.data) {
      highlight.data?.data?.title && setValue("title", capitalize(highlight.data.data?.title));
      highlight.data?.data?.youtubeUrl && setValue("youtubeUrl", highlight.data.data?.youtubeUrl);
      highlight.data?.data?._college?._id && setValue("collegeId", highlight.data.data?._college?._id);
      highlight.data?.data?.description && setValue("description", highlight.data.data?.description);
    }
  }, [id, highlight.data]);

  const onSubmit = async (data: IHighLight) => {
    try {
      if (id === "new") {
        const payload = { ...data, _college: data?.collegeId };
        delete payload.collegeId;
        const add = await addHighLight(payload);
        snackbar(add.message, "info");
        navigate({
          pathname: "/careers/highlight",
          search: searchParam.toString()
        });
        outlet?.reFetch && outlet.reFetch();
      } else {
        const payload = { ...data, _college: data?.collegeId, _id: id };
        delete payload.collegeId;
        const update = await updateHightLight(id ? id : "", payload);
        snackbar(update.message, "info");
        navigate({
          pathname: "/careers/highlight",
          search: searchParam.toString()
        });
        outlet?.reFetch && outlet.reFetch();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log(error);
    }
  };

  const onClose = () => {
    navigate({
      pathname: "/careers/highlight",
      search: searchParam.toString()
    });
  };
  const selectCollege = (event: SyntheticEvent<Element, Event>, value: ICollege | null, reason: AutocompleteChangeReason) => {
    switch (reason) {
      case "selectOption":
        setValue("collegeId", value?._id);
        break;
      case "clear":
        setValue("collegeId", "");
        break;
      case "removeOption":
        setValue("collegeId", "");
        break;
      default:
        break;
    }
    trigger("collegeId");
  };

  return (
    <Box>
      <CustomDialog
        title={id !== "new" ? "Edit HighLight" : "New HighLight"}
        isOpen={id ? true : false}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4}>
          {
            fields.map(field => {
              if (field.type === "input") {
                return (
                  <Grid key={field.label} item xs={12} md={field.width ? field.width : 6}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => (
                        <TextField
                          label={<CustomLabel label={field.label} required={field?.required} />}
                          className="disable-text"
                          variant="outlined"
                          size="small"
                          placeholder={field.placeholder}
                          error={errors[field.name] ? true : false}
                          helperText={errors[field.name]?.message}
                          {...prop.field}
                        />
                      )}
                    />
                  </Grid>
                );
              } else if (field.type === "select") {
                return <Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <Autocomplete
                      className="disable-text"
                      options={colleges}
                      getOptionLabel={(option) => capitalize(option.name)}
                      renderInput={(params) => <TextField
                        {...params}
                        error={errors.collegeId ? true : false}
                        helperText={errors.collegeId ? errors.collegeId.message : ""}
                        size={"small"}
                        variant={"outlined"}
                        label={<CustomLabel label="College Name*" />}
                        placeholder={field.placeholder}
                      />}
                      {...prop.field}
                      value={colleges.find(college => college._id === getValues("collegeId")) || null}
                      onChange={selectCollege}
                    />}
                  />
                </Grid>;
              } else if (field.type === "multiline") {
                return (<Grid key={field.label} item xs={12} md={field.width ? field.width : 12}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <TextField
                      label={<CustomLabel label={field.label} required={field?.required} />}
                      className="disable-text"
                      variant="outlined"
                      size="small"
                      placeholder={field.placeholder}
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                      multiline
                      minRows={2}
                      {...prop.field}
                    />}
                  />
                </Grid>
                );
              }
              else {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <Select
                    control={control}
                    name={field.name}
                    label={field.label}
                    size="small"
                    variant="outlined"
                    error={errors[field.name] ? true : false}
                    helperText={errors[field.name]?.message}
                  >
                    {field.children}
                  </Select>
                </Grid>
                );
              }
            })
          }
        </Grid>
      </CustomDialog>
    </Box>
  );

};

export default ManageHighlight;