import HttpService from "../http";
import { IInterviewsResponse, IInterviewResponse } from "../../interfaces";
import { interview, rescheduleInterview } from "../endpoints";

const InterviewService = () => {
    const { httpRequest } = HttpService();

    const addInterview = async (payload: object): Promise<IInterviewsResponse> => new Promise((resolve, reject) => {
        httpRequest<IInterviewsResponse>(
            "POST",
            interview,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteInterview = async (payload: object): Promise<IInterviewsResponse> => new Promise((resolve, reject) => {
        httpRequest<IInterviewsResponse>(
            "DELETE",
            interview,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getInterview = async (search: object): Promise<IInterviewResponse> => new Promise((resolve, reject) => {
        httpRequest<IInterviewResponse>(
            "GET",
            `${interview}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getInterviews = async (search: object): Promise<IInterviewsResponse> => new Promise((resolve, reject) => {
        httpRequest<IInterviewsResponse>(
            "GET",
            `${interview}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const rescheduleInterviews = async (id: string, payload: object): Promise<IInterviewResponse> => new Promise((resolve, reject) => {
        httpRequest<IInterviewResponse>(
            "PUT",
            rescheduleInterview,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updateInterview = async (id: string, payload: object): Promise<IInterviewResponse> => new Promise((resolve, reject) => {
        httpRequest<IInterviewResponse>(
            "PUT",
            interview,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { addInterview, getInterviews, getInterview, rescheduleInterviews, deleteInterview, updateInterview };

};

export { InterviewService };