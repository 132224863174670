import HttpService from "../../http";
import { ICollegeImagesResponse } from "../../../interfaces";
import { collegeImage, removecollegeImage } from "../../endpoints";

const CollegeImageService = () => {
    const { httpRequest } = HttpService();
    const addCollegeImage = async (payload: object): Promise<ICollegeImagesResponse> => new Promise((resolve, reject) => {
        httpRequest<ICollegeImagesResponse>(
            "POST",
            `${collegeImage}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getCollegeImages = async (search: object): Promise<ICollegeImagesResponse> => new Promise((resolve, reject) => {
        httpRequest<ICollegeImagesResponse>(
            "GET",
            `${collegeImage}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteCollegeImage = async (payload: object): Promise<ICollegeImagesResponse> => new Promise((resolve, reject) => {
        httpRequest<ICollegeImagesResponse>(
            "DELETE",
            `${removecollegeImage}`,
            {},
            payload
        )
            .then(resolve)
            .catch(reject); 
    });

    return { addCollegeImage, getCollegeImages, deleteCollegeImage };

};

export { CollegeImageService };