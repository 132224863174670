import Joi from "joi";
import { required } from "../../shared";

export const productMappingValidations = Joi.object({
  form: Joi.object({
    _productId: Joi.string()
      .required()
      .label("Product")
      .messages({
        "string.empty": required
      }),
    link: Joi.string()
      .required()
      .label("Link")
      .messages({
        "string.empty": required
      }),
    token: Joi.string()
      .required()
      .label("Token")
      .messages({
        "string.empty": required
      }),
    mapping: Joi.array()
      .items(Joi.object({
        field: Joi.string()
          .required()
          .label("Field")
          .messages({
            "string.empty": required
          }),
        fallbackValue: Joi.string()
          .required()
          .label("FallbackValue")
          .messages({
            "string.empty": required
          }),
      }))
      .required()
      .label("Mapping")
      .messages({
        "array.empty": required
      }),
  })
    .required()

});