import HttpService from "../../http";
import { IOfficeEmployeesResponse, IOfficeEmployeeResponse } from "../../../interfaces";
import { officeEmployeeImage, addofficeEmployeeImage, getofficeEmployeeImage, updateofficeEmployeeImage, removeofficeEmployeeImage } from "../../endpoints";

const OfficeEmployeeImageService = () => {
    const { httpRequest } = HttpService();
    const addOfficeEmployeeImage = async (payload: object): Promise<IOfficeEmployeesResponse> => new Promise((resolve, reject) => {
        httpRequest<IOfficeEmployeesResponse>(
            "POST",
            `${addofficeEmployeeImage}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getOfficeEmployeeImages = async (search: object): Promise<IOfficeEmployeesResponse> => new Promise((resolve, reject) => {
        httpRequest<IOfficeEmployeesResponse>(
            "GET",
            `${officeEmployeeImage}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getOfficeEmployeeImage = async (query: object): Promise<IOfficeEmployeeResponse> => new Promise((resolve, reject) => {
        httpRequest<IOfficeEmployeeResponse>(
            "GET",
            `${getofficeEmployeeImage}`,
            {},
            query

        )
            .then(resolve)
            .catch(reject);
    });

    const updateOfficeEmployeeImage = async (id: string, payload: object): Promise<IOfficeEmployeeResponse> => new Promise((resolve, reject) => {
        httpRequest<IOfficeEmployeeResponse>(
            "PUT",
            `${updateofficeEmployeeImage}`,
            payload

        )
            .then(resolve)
            .catch(reject);
    });

    const deleteOfficeEmployeeImages = async (payload: object): Promise<IOfficeEmployeesResponse> => new Promise((resolve, reject) => {
        httpRequest<IOfficeEmployeesResponse>(
            "DELETE",
            `${removeofficeEmployeeImage}`,
            {},
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { addOfficeEmployeeImage, getOfficeEmployeeImages, getOfficeEmployeeImage, updateOfficeEmployeeImage, deleteOfficeEmployeeImages };

};

export { OfficeEmployeeImageService };