import Joi from "joi";
import { required } from "../../shared";

export const CollegeImageValidation = Joi.object({
    image: Joi.string()
        .required()
        .label("Image")
        .messages({
            "string.empty": required
        }),
});