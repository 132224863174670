import HttpService from "../../http";
import { questionTags } from "../../endpoints";
import { ITagResponse, ITagsResponse } from "../../../interfaces";

const QuestionTagService = () => {
    const { httpRequest } = HttpService();
    const addTag = async (payload: object): Promise<ITagsResponse> => new Promise((resolve, reject) => {
        httpRequest<ITagsResponse>(
            "POST",
            `${questionTags}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });


    const getPartialTags = async (): Promise<ITagsResponse> => new Promise((resolve, reject) => {
        httpRequest<ITagsResponse>(
            "GET",
            `${questionTags}/list/partial`,
            {},
            {}
        )
            .then(resolve)
            .catch(reject);
    });

    const getTags = async (search: object): Promise<ITagsResponse> => new Promise((resolve, reject) => {
        httpRequest<ITagsResponse>(
            "GET",
            `${questionTags}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getTag = async (query: object): Promise<ITagResponse> => new Promise((resolve, reject) => {
        httpRequest<ITagResponse>(
            "GET",
            `${questionTags}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateTag = async (id: string, payload: object): Promise<ITagResponse> => new Promise((resolve, reject) => {
        httpRequest<ITagResponse>(
            "PUT",
            `${questionTags}`,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteTag = async (payload: object): Promise<ITagResponse> => new Promise((resolve, reject) => {
        httpRequest<ITagResponse>(
            "DELETE",
            `${questionTags}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });
    return { addTag, getPartialTags, getTags, getTag, updateTag, deleteTag };
};

export { QuestionTagService };
