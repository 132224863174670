import HttpService from "../../http";
import { ISingleWriterReportRoles, IWriterReportRolesResponse } from "../../../interfaces";
import { writerReportRoles } from "../../endpoints";

const WriterReportRoles = () => {
  const { httpRequest } = HttpService();

	const getWriterReportRoles = async (query: object = {}): Promise<IWriterReportRolesResponse> => new Promise((resolve, reject) => {
		httpRequest<IWriterReportRolesResponse>(
			"GET",
			`${writerReportRoles}/list`,
			{},
      query
		)
			.then(resolve)
			.catch(reject);
	});

	const updateWriterReportRoles = async (payload: object = {}): Promise<IWriterReportRolesResponse> => new Promise((resolve, reject) => {
		httpRequest<IWriterReportRolesResponse>(
			"PUT",
			`${writerReportRoles}`,
			payload,
      {}
		)
			.then(resolve)
			.catch(reject);
	});

	const getWriterReportRole = async (query: object = {}): Promise<ISingleWriterReportRoles> => new Promise((resolve, reject) => {
		httpRequest<ISingleWriterReportRoles>(
			"GET",
			`${writerReportRoles}`,
      {},
			query
		)
			.then(resolve)
			.catch(reject);
	});

  return { getWriterReportRoles, updateWriterReportRoles, getWriterReportRole };
};

export { WriterReportRoles };