import { useEffect, KeyboardEvent, useState, ChangeEvent, FC } from "react";
import { CollegeService } from "../../../../../services";
import { ICollegeField, ICollege, IErrorResponse, IContactPersonDetail } from "../../../../../interfaces";
import { Box, Button, Chip, Divider, Grid, MenuItem, TextField } from "@mui/material";
import { validateEmail } from "../../../../../validations/shared";
import { useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { Control, Controller, FieldErrors, UseFormGetValues, UseFormHandleSubmit, UseFormReset, UseFormResetField, UseFormSetValue, UseFormTrigger, UseFormWatch } from "react-hook-form";
import Select from "../../../../../components/mui/select";
import useSnackbar from "../../../../../hooks/useSnackbar";
import UploadImg from "../../../../../assets/images/upload.png";
import HttpService from "../../../../../services/http";


interface outletProps {
  reFetch: () => void;
  refetchColleges: () => void;
}

interface props {
  handleSubmit: UseFormHandleSubmit<ICollege>;
  getValues: UseFormGetValues<ICollege>;
  trigger: UseFormTrigger<ICollege>;
  resetField: UseFormResetField<ICollege>;
  reset: UseFormReset<ICollege>;
  watch: UseFormWatch<ICollege>
  control: Control<ICollege>
  errors: FieldErrors<ICollege>
  setValue: UseFormSetValue<ICollege>
  tableData: IContactPersonDetail[];
  setTableData: React.Dispatch<React.SetStateAction<IContactPersonDetail[]>>
}

const AddCollege: FC<props> = ({
  handleSubmit, getValues, trigger, resetField, reset, watch, control, errors, setValue, tableData
}) => {
  const [searchParam] = useSearchParams();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const navigate = useNavigate();
  const { addCollege } = CollegeService();
  const { httpFormRequest } = HttpService();
  const [imageSelected, setImageSelected] = useState<string | null>(null);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "email") {
        trigger("email");
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);


  const addEmail = (e: KeyboardEvent<HTMLDivElement>, key: string) => {
    let payload: string[] = [];

    if (key === "emailText") {
      const err = validateEmail(getValues(key));
      if (err.error) {
        return;
      }

      if (e.key === "Enter" && key === "emailText") {
        const prev = getValues("email") ? getValues("email") : [];
        const newEmail = getValues("emailText") ? String(getValues("emailText")) : "";
        payload = [...prev, newEmail];

        setValue("email", [...new Set(payload)]);
        resetField(key);
      }

    }
  };

  const removeEmail = (key: string, value: string) => {
    if (key === "emailText") {
      let payload = getValues("email");
      payload = payload.filter(email => email !== value);
      setValue("email", payload);
      trigger("email");
    }
  };

  const handleEmailCopyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content);
      snackbar("Email ID copied to clipboard", "info");
  };

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, type: string) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const uploaded = await httpFormRequest<{ data: string }>(
          e.target.files,
          e.target.files[0].name,
          ["png", "jpeg", "jpg"],
          1
        );
        const keyExist = type === "image";
        if (keyExist) {
          setValue(type, uploaded?.data);
          setImageSelected(URL.createObjectURL(e.target.files[0]));
          trigger(type);
        }
      }
    }
    catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log("error in candidate detail upload", error);
    }
  };

  const onSubmit = async (data: ICollege) => {
    try {

      const payload = {
        ...data,
        contactPersons: tableData,
      };
      delete payload.emailText;
      const add = await addCollege(payload);
      snackbar(add.message, "info");
      navigate({
        pathname: "/cpd/college",
        search: searchParam.toString()
      });
      outlet?.reFetch && outlet.reFetch();
      outlet?.refetchColleges && outlet.refetchColleges();

    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log(error);
    }

  };

  const fields: ICollegeField[] = [
    {
      label: "Image*",
      name: "image",
      type: "upload",
    },
    {
      label: "College Name*",
      name: "name",
      type: "input",
      placeholder: "Enter college name"
    },
    {
      label: "College Short Name*",
      name: "shortName",
      type: "input",
      placeholder: "Enter college short name"
    },
    {
      label: "City*",
      name: "city",
      type: "input",
      placeholder: "Enter city name"
    },
    {
      label: "State*",
      name: "state",
      type: "input",
      placeholder: "Enter state name"
    },
    {
      label: "Category*",
      name: "category",
      type: "select",
      children: [
        <MenuItem key={"none"} value="none" disabled>Category</MenuItem>,
        <MenuItem key={"tier-1"} value="tier-1">Tier-1</MenuItem>,
        <MenuItem key={"tier-2"} value="tier-2">Tier-2</MenuItem>,
        <MenuItem key={"tier-3"} value="tier-3">Tier-3</MenuItem>,
      ],
      placeholder: "Select category"
    },
    {
      label: "Helpline Number",
      name: "helpline",
      type: "input",
      placeholder: "Enter helpline number"
    },
    {
      label: "Mobile Number (Primary)",
      name: "mobileNumber",
      type: "input",
      placeholder: "Enter mobile number"
    },
    {
      label: "Mobile Number (Secondary)",
      name: "secondaryNumber",
      type: "input",
      placeholder: "Enter mobile number"
    },
    {
      label: "Email Address",
      name: "emailText",
      type: "input",
      placeholder: "Type email address and press enter"
    },
    {
      label: "Status",
      name: "status",
      type: "input",
      placeholder: "Enter status"
    },
    {
      label: "Internship Start Month",
      name: "internshipStartMonth",
      type: "input",
      placeholder: "Enter month name"
    },
    {
      label: "Duration",
      name: "duration",
      type: "input",
      placeholder: "Enter duration"
    },
    {
      label: "LinkedIn",
      name: "linkedInPage",
      type: "input",
      placeholder: "Enter linkedIn page url"
    },
    {
      label: "Facebook",
      name: "facebookPage",
      type: "input",
      placeholder: "Enter facebook page url"
    },
    {
      label: "Offer Rule",
      name: "offerRule",
      type: "input",
      placeholder: "Enter offer rule"
    },
    {
      label: "Remark",
      name: "remark",
      type: "input",
      placeholder: "Enter remark"
    },

  ];

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          {
            fields.map(field => {
              if (field.type === "input") {
                return (<Grid key={field.label} item xs={12} md={6} style={{ marginTop: "8px" }}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <TextField
                      label={field.label}
                      className="disable-text"
                      variant="outlined"
                      size="small"
                      placeholder={field.placeholder}
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                      {...prop.field}
                      onKeyUp={e => addEmail(e, field.name)}
                      onKeyDown={e => e.key === "Enter" && e.preventDefault()}
                    />}
                  />
                  {
                    <Box>
                      {
                        field.name === "emailText" && getValues("email") &&
                        getValues("email").map(email => <Chip
                          key={email}
                          label={email}
                          onClick={() => handleEmailCopyToClipboard(email)}
                          onDelete={() => removeEmail(field.name, email)}
                          color="primary"
                          variant="outlined"
                          sx={{ margin: "5px" }}
                        />)
                      }
                    </Box>
                  }
                </Grid>
                );
              } else if (field.type === "upload") {
                return (<Grid key={field.label} item xs={12}>
                  <Box className="center">
                    <Box height="100px" width="100px" className="upload-img" aria-label="upload picture" component="label">
                      <img src={imageSelected || UploadImg} alt="review" />
                      <div className="edit-img ">Edit</div>
                      <input hidden accept="image/*" type="file" onChange={e => uploadFile(e, field.name)} />
                    </Box>
                  </Box>
                  {errors[field.name] && <span style={{ color: "#d32f2f", marginLeft: "528px", fontWeight: 400, fontSize: "0.85rem" }}>{errors[field.name]?.message}</span>}
                </Grid>
                );
              } else {
                return (<Grid key={field.label} item xs={12} md={6} style={{ marginTop: "8px" }}>
                  <Select
                    control={control}
                    name={field.name}
                    label={field.label}
                    size="small"
                    variant="outlined"
                    error={errors[field.name] ? true : false}
                    helperText={errors[field.name]?.message}

                  >
                    {field.children}
                  </Select>
                </Grid>
                );
              }
            })
          }
        </Grid>
        {
          <>
            <Box className="action-box">
              <Divider sx={{ marginBottom: "20px" }} />
              <Button variant="outlined" onClick={() => reset()}>clear</Button>
              <Button type="submit">Save</Button>
            </Box>
          </>
        }
      </form>
    </Box>
  );
};

export default AddCollege;