import Joi from "joi";
import { required } from "../../../shared";

export const blogScoreValidation = Joi.object({
    smallSeoTools: Joi.string()
        .required()
        .label("Small Seo Tools")
        .messages({
            "string.empty": required,
        }),
    focusKeyword1: Joi.string()
        .required()
        .label("FocusKeyword1")
        .messages({
            "string.empty": required,
        }),
    grammerlyScore: Joi.string()
        .required()
        .label("GrammerlyScore")
        .messages({
            "string.empty": required,
        }),
    focusKeyword2: Joi.string()
        .required()
        .label("FocusKeyword2")
        .messages({
            "string.empty": required,
        }),
    contentHumanSource: Joi.string()
        .required()
        .label("Content Human Source")
        .messages({
            "string.empty": required,
        }),
    focusKeyword3: Joi.string()
        .required()
        .label("FocusKeyword3")
        .messages({
            "string.empty": required,
        }),
    numberOfWords: Joi.string()
        .required()
        .label("Number of Words")
        .messages({
            "string.empty": required,
        }),
    focusKeyword4: Joi.string()
        .required()
        .label("FocusKeyword4")
        .messages({
            "string.empty": required,
        })
});
