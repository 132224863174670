import "./index.scss";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { LoadingProvider } from "./components/loader";
import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { Provider } from "react-redux";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import store from "./redux/store";
import { SnackbarProvider } from "notistack";


// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <LoadingProvider>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider
            preventDuplicate
            maxSnack={3}
            iconVariant={{
              success: <ErrorOutlineIcon />,
              error: <ErrorOutlineIcon />,
              warning: <WarningAmberIcon />,
              info: <TaskAltIcon />,
              default: <WifiOffIcon />
            }}
          >

            <App />
          </SnackbarProvider>
        </QueryClientProvider>
      </LoadingProvider>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
