import CategoryService from "../../../../services/content/category";


const usevalidateSubCategories = () => {
    const { getContentCategoryPartial } = CategoryService();

    // eslint-disable-next-line
    const validateSubCategory = async (leads: any[]) =>
        // eslint-disable-next-line
        new Promise(async (resolve) => {
            const mandatoryFields = ["name", "categoryId"];
            const payload = [];
            const error = [];
            let row = 2;
            for await (const lead of leads) {
                if (!lead?.length) {
                    ++row;
                    continue;
                }

                // eslint-disable-next-line
                const data: any = {};
                // eslint-disable-next-line
                const errMessage: any = {};

                if (String(lead[1]).trim() === "") {
                    errMessage["name"] = "Name is required";
                } else {
                    data.name = String(lead[1]).trim();
                }
                console.log(lead[0]);
                if (typeof lead[0] === "string") {
                    try {
                        const category = await getContentCategoryPartial({
                            search: lead[0].trim(),
                        });
                        
                        if (category.data?._id) {

                            data.categoryId = category.data?._id;

                        } else {
                            errMessage["Category"] = "Please enter a valid category name";
                        }
                    } catch (error) {
                        errMessage["Category"] = "Please enter a valid category name";
                    }
                } else {
                    if (lead[0] !== undefined) {
                        errMessage["Category"] = "Please enter a valid category name";
                    }
                }

                if (mandatoryFields.every((value) => Object.keys(data).includes(value))) {
                    if (Object.keys(errMessage).length) {
                        error.push({ ...errMessage, row });
                    } else {
                        payload.push(data);
                    }
                } else {
                    if (Object.keys(errMessage).length) {
                        error.push({ ...errMessage, row });
                    }
                }
                ++row;
            }

            // return all the data
            resolve({
                data: payload,
                errorMessage: error,
            });

        });

    return { validateSubCategory };
};
export default usevalidateSubCategories;
