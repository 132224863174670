import { FC } from "react";
import { ICandidateFilterMenu } from "../../../interfaces";
import { ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";

export const MenuItem: FC<ICandidateFilterMenu> = ({ index, label, selectedMenu, onChange, count, isApplied, min, max }) => 
<ListItem
  secondaryAction={
    <>
      {
        isApplied &&
        <Typography variant="body2">({min} - {max})</Typography>
      }
      {
        (count && count?.length > 0) &&
        <Typography variant="body2">({count.length})</Typography>
      }
    </>
  }
  disablePadding
>
  <ListItemButton
    selected={selectedMenu}
    onClick={(event) => onChange(event, index)}
  >
    <ListItemText primary={label} />
  </ListItemButton>
</ListItem>;

export const defaultStatus = [
    {
      key: "APPLIED",
      name: "Applied"
    },
    {
      key: "CALL_NA",
      name: "Called NA"
    },
    {
      key: "NOT_INTERESTED",
      name: "Not Interested"
    },
    {
      key: "TBC_LATER",
      name: "TBC Later"
    },
  ];