import "./style.scss";
import backArrowRoundIcon from "../../../../assets/images/back-arrow-round.svg";

import { Box, Tab, Tabs, Typography } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { TabPanel } from "../../../../components/shared/mui-tabs";


import useResource from "../../../../hooks/useResource";
import useSnackbar from "../../../../hooks/useSnackbar";

import { TechnicalQuestionBasicDetail } from "./basic-detail";
import Templates from "./templates";
import TestCases from "./test-cases";


function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface IState {
  redirectionLinks: string[];
}

const ManageTechnicalQuestion = () => {
  const [value, setValue] = useState(0);
  const { id } = useParams();
  const { resourceAllocate } = useResource();

  const { snackbar } = useSnackbar();

  const [searchParam, setSearchParam] = useSearchParams();



  const [state, setState] = useState<IState>({
    redirectionLinks: [],
  });

  useEffect(() => {
    const redirectionLinks = [...state.redirectionLinks];
    if (!redirectionLinks.length) {
      if (resourceAllocate("technical-question.read")) {
        redirectionLinks.push("basic-detail");
        redirectionLinks.push("templates");
        redirectionLinks.push("test-case");
      }
      setState((prev) => ({
        ...prev,
        redirectionLinks,
      }));
    }
    const tab = searchParam.get("type")
      ? String(searchParam.get("type"))
      : "basic-detail";

    setValue(redirectionLinks.indexOf(tab));
  }, [state.redirectionLinks, searchParam]);

  const navigate = useNavigate();

  const handleChange = (event: SyntheticEvent, newValue: number) => {

    if (id === "new" && newValue !== 0) {
      snackbar("Please add basic details First.", "error");
      return;
    }
    searchParam.set("type", state.redirectionLinks[newValue]);
    setSearchParam(searchParam);
  };
  const handleClick = () => {
    navigate("/configurations/technical-questions");
  };

  return (
    <div id="question-manage">
      {/* header */}
      <header className="header">
        <div className="title-box">
          <img src={backArrowRoundIcon} onClick={handleClick} />
          <Typography className="title" variant="h5">
            {id === "new" ? "Add Question" : "Edit Question"}
          </Typography>
        </div>
      </header>

      {/* tabs  */}
      <Box width="100%">
        <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: "30px" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {state.redirectionLinks &&
              state.redirectionLinks.map((link, index) => (
                <Tab
                  key={index}
                  className="tabs-space"
                  label={link.charAt(0).toUpperCase() + link.slice(1)}
                  {...a11yProps(0)}
                />
              ))}
          </Tabs>
          </Box>
          {state.redirectionLinks &&
          state.redirectionLinks.map((link, index) => (
            <TabPanel key={index} value={value} index={index} >
              {link === "basic-detail" && <TechnicalQuestionBasicDetail />}
              {link === "templates" && <Templates />}
              {link === "test-case" && <TestCases />}
            </TabPanel>
          ))}
        </Box>
    </div>
  );
};

export default ManageTechnicalQuestion;