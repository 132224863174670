import { Link, useParams } from "react-router-dom";
import Header from "../../../components/header";
import { useState, useEffect, ChangeEvent, MouseEvent } from "react";
import useSnackbar from "../../../hooks/useSnackbar";
import { useSearchParams } from "react-router-dom";
import useDebounce from "../../../hooks/useDebounce";
import { IPlanner, IPlannerRow, IPlannerStatus } from "../../../interfaces/planner";
import { Outlet } from "react-router-dom";
import CustomTable from "../../../components/mui/table";
import { IColumn, IErrorResponse, IPagination } from "../../../interfaces";
import { createIndex } from "../../../utilities/helper";
import WarningDialog from "../../../components/mui/warning-dialog";
import plannerService from "../../../services/planner";
import { useQuery } from "@tanstack/react-query";
import { handleSelect } from "../../content-layout/common/helper";
import { Box, capitalize, Checkbox, Typography } from "@mui/material";
import useResource from "../../../hooks/useResource";
import CmsQuarterFilter from "./filter";
import TypesOfContentService from "../../../services/content/type-of-content";

const ManageProjects = () => {


  const { id } = useParams();
  const [search, setSearch] = useState("");
  const { snackbar } = useSnackbar();
  const { deleteQuarter, getPlannerList } = plannerService();
  const { getContentTypesOfContents } = TypesOfContentService();
  const [searchParams, setSearchParams] = useSearchParams();
  const { resourceAllocate } = useResource();

  const [state, setState] = useState<IPlannerStatus>({
    selectAll: [],
    multiDeleteWarning: false,
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
      totalRecords: 0,
    },
    searching: {
      search: "",
    },
    deleteWarning: false,
    _deleteId: "",
    filters: {
      showFutureQuarters: false,
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filterCount: 0,
  });



  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const ProjectQuarterList = useQuery({
    queryKey: ["allQuarters", state.pagination.page, state.searching, state.filters],
    queryFn: () =>
      getPlannerList({
        productId: id,
        pagination: true,
        page: state.pagination.page,
        limit: state.pagination.limit,
        ...state.searching,
        ...state.filters.showFutureQuarters ? {
          showFutureQuarters: state.filters.showFutureQuarters
        } : {}
      }),
  });

  useEffect(() => {
    if (id !== "new") {
      if (ProjectQuarterList?.data?.data) {
        setState(prevState => ({
          ...prevState,
          pagination: {
            ...prevState.pagination,
            totalPages: ProjectQuarterList?.data?.meta?.totalPages,
            totalRecords: ProjectQuarterList?.data?.meta?.totalRecords
          }
        }));
      }
    }
  }, [ProjectQuarterList?.data?.data]);


  // <!----------------------------- page handliation starts here here---------------------------------------------->
  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  // <!----------------------------- page handliation ends here ---------------------------------------------->

  // <!----------------- searching starts here-------------------------------------------------- -->
  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);
  const searchRecord = useDebounce(search, 1000);
  const [planner, setPlanner] = useState({});
  const handleClick = (data: IPlanner) => {
    setPlanner(data);
  };
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if(!searchParams.get("futureQuarters")) {
      searchParams.set("futureQuarters", "true");
      setSearchParams(searchParams);
    }
  });

  // <!----------------- searching ends here-------------------------------------------------- -->

  const typeOfContent = useQuery({
    queryKey: ["typeOfContent"],
    queryFn: () => getContentTypesOfContents({})
  });

  const dynamicColumns = typeOfContent.data?.data?.map((item) => ({
    id: item._id,
    label: item.name,
  })) || [];

  const columns: IColumn[] = [
    {
      id: "task",
      label: "Task",
    },
    {
      id: "number_of_blogs",
      label: "No. of Blogs",
    },
    ...dynamicColumns.map(column => ({
      id: column.id,
      label: (
        <>
          <span>{capitalize(column.label)}</span>
        </>
      ),
    }))
  ];

  // <!----------------- Delete Funvtionality starts here-------------------------------------------------- -->

  useEffect(() => {
    let filterCount = 0;

    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
    const futureQuarters = searchParams.get("futureQuarters") ? searchParams.get("futureQuarters") === "true" : false;
    filterCount += futureQuarters ? 1 : 0;
    setState((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        page: page,
      },
      searching: {
        ...prev.searching,
        search: search,
      },
      filters: {
        ...prev.filters,
        showFutureQuarters: futureQuarters,
      },
      filterCount
    }));

  }, [searchParams]);

  const handleDelete = (_deleteId = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !state.deleteWarning,
      _deleteId: _deleteId,
    }));
  };

  const onDelete = async () => {
    try {
      const response = await deleteQuarter({ ids: [state._deleteId] });
      snackbar(response?.message, "info");
      handleDelete();
      ProjectQuarterList.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
      console.log("Error in delete of goals", err);
    }
  };
  const handleMultiDelete = () => setState(prevState => ({
    ...prevState,
    multiDeleteWarning: !prevState.multiDeleteWarning
  }));
  const onMultiDelete = async () => {
    try {
      const result = await deleteQuarter({ ids: state.selectAll });
      snackbar(result.message, "info");
      ProjectQuarterList.refetch();
      setState(prevState => ({
        ...prevState,
        multiDeleteWarning: false,
        selectAll: []
      }));
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      handleMultiDelete();
      console.log({ "Error in delete scores data": error });
    }
  };

  useEffect(() => {
    searchParams.set("futureQuarters", "true");
    setSearchParams(searchParams);
  }, [id]);

  const createRows = (planner: IPlanner, selectedAll: string[], index: number, pagination: IPagination) => {
    const dynamicColumnsData = dynamicColumns.map((column) => {
      const matchingItem = planner?.targets?.find((item) => item?._id?._id === column.id);

      const obj: {[key:string]: number} = {};
      if (matchingItem) {
        obj[column.id] = matchingItem.count;
        return obj;
      } else {
        obj[column.id] = 0;
        return obj;
      }
    });
    return ({
      all: <Checkbox onChange={e => handleSelect(e, planner._id, state, setState)} checked={selectedAll.includes(planner._id)} />,
      id: createIndex(pagination, index),
      task: resourceAllocate("cms-quarter-targets.read") ? (
        <Link to={`/planner/${id}/manage/${planner._id}`} onClick={() => handleClick(planner)} state={{ tabs: "", name: planner.name }}>
          {planner.name}
        </Link>
      ) : (
        <span>{planner.name}</span>
      ),
      number_of_blogs: planner?.noOfBlogs ? planner.noOfBlogs : 0,
      ...Object.assign({}, ...dynamicColumnsData),
      // action,
    });
  };


  let rows: IPlannerRow[] = [];
  if (ProjectQuarterList?.data?.data?.length) {
    rows = ProjectQuarterList?.data.data.map((planner: IPlanner, index: number) =>
      createRows(planner, state.selectAll, index, state.pagination)
    );
  }

  return (
    <div>
      <Outlet context={{ ...Outlet, reFetch: ProjectQuarterList.refetch, List: ProjectQuarterList?.data?.data, data: planner }} />
      <Header
        className="my-2"
        searchPlaceholder="Search by task"
        onFilter={openFilter}
        filterCount={state.filterCount}
        onSearch={onSearch}
      >
        <CmsQuarterFilter
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          onClose={closeFilter}
        />
      </Header>
      <Box display="flex" className="mb-2" justifyContent="flex-end" alignItems="center">
        <Box display="flex" alignItems="center">
          <Typography variant="body1">Total Tasks:</Typography>
          <Typography className="ml-3" variant="body1" >{ProjectQuarterList?.data?.meta?.totalRecords}</Typography>
        </Box>
      </Box>

      <CustomTable
        rows={rows}
        columns={columns}
        height={"calc(100vh - 290px)"}
        pagination={state.pagination}
        onPageChange={onPageChange}
      />

      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Quarter"
        description="Are you sure you want to delete this quarter?"
      />

      {/* Multiple Delete  */}
      <WarningDialog
        isOpen={state.multiDeleteWarning}
        onClose={() => handleMultiDelete()}
        onConfirm={onMultiDelete}
        title="Delete All Quarters"
        description={`Are you sure you want to delete ${state.selectAll.length} selected ${state.selectAll.length > 1 ? "quarters" : "quarter"}?`}
      />
    </div>
  );
};
export default ManageProjects;
