import HttpService from "../http";
import { cmsHome, cmsHomeActivity } from "../endpoints";
import { ICmsHomeActivityResponse, ICmsHomeResponse } from "../../interfaces/cms-home";

const CmsHomeService = () => {
    const { httpRequest } = HttpService();
    const cmsHomeStats = async (search: object): Promise<ICmsHomeResponse> => new Promise((resolve, reject) => {
        httpRequest<ICmsHomeResponse>(
            "GET",
            `${cmsHome}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const cmsHomeActivityData = async (search: object): Promise<ICmsHomeActivityResponse> => new Promise((resolve, reject) => {
        httpRequest<ICmsHomeActivityResponse>(
            "GET",
            `${cmsHomeActivity}`,
            {},
            search,
            false
        )
            .then(resolve)
            .catch(reject);
    });

    return { cmsHomeStats,cmsHomeActivityData };
};

export default CmsHomeService;