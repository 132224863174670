import "./style.scss";
import { Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import { FC } from "react";

interface props {
    label: string | JSX.Element,
    value?: string,
    format?: string,
    name: string,
    disabled?: boolean,
    disablePastDate?: boolean,
    disableFutureDate?: boolean,
    changeDate: (e: dayjs.Dayjs | null, name: string) => void,
}

const DateTime: FC<props> = ({ label, value, format, name, disablePastDate, disableFutureDate,disabled, changeDate }) =>
(
    <Box sx={{ position: "relative" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
            disabled={disabled}
                value={dayjs(value)}
                onChange={e => changeDate(e, name)}
                label={label}
                format={format}
                disablePast={disablePastDate}
                disableFuture={disableFutureDate}
            />
        </LocalizationProvider>
    </Box>
);


DateTime.defaultProps = {
    format: "LL",
    disablePastDate: false,
    disableFutureDate: false
};

export default DateTime;