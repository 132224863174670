import { Route, Routes } from "react-router-dom";
import Tests from "./Tests";
import ManageTest from "./Tests/manageTest";
const TestRouting = () => (
  <Routes>
    <Route path="/" element={<Tests />}>
      <Route path=":id" element={<ManageTest />} />
    </Route>
  </Routes>
);
export default TestRouting;