import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles
import "./style.scss";

import {
  useForm,
  Controller,
} from "react-hook-form";
import {
  Grid,
  TextField,
   MenuItem,
  IconButton,
  Typography,
  InputAdornment,
  Radio,
} from "@mui/material";
 import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
 } from "react-router-dom";
import RemoveIcon from "@mui/icons-material/Remove";
import useSnackbar from "../../../../../../../../hooks/useSnackbar";
import TestInputDialog from "../../../../../../../../components/mui/dialog/testInputDialog";
import NumberInputIntroduction from "../../../../../../../../components/mui/custom-number-input";
import { useQuery } from "@tanstack/react-query";
import { TestsService } from "../../../../../../../../services/configuration/tests";
import { testQuestionValidation } from "../../../../../../../../validations/configuration/test/test-question";
import { joiResolver } from "@hookform/resolvers/joi";
import { IErrorResponse } from "../../../../../../../../interfaces";
import { ITestCategories } from "../../../../../../../../interfaces/configuration/tests";
import { cleanHtmlString } from "../../../../../../../../utilities/helper";

interface Field {
  label: string;
  name: "question" | "_category";
  type: "input" | "select";
  placeholder: string;
  options?: ITestCategories[];
}


interface Answer {
  option: string;
  weightage: number;
  id: number;
}

interface ITestQuesData {
  options: Answer[];
  question: string;
  _category: string;
}
const AddQuestion: React.FC = () => {
  const { control, setValue, handleSubmit, trigger, formState: { errors } } = useForm<ITestQuesData>(
    {
      resolver: joiResolver(testQuestionValidation),
      defaultValues: {
        question: "",
        _category: "",
        options: [{ option: "", weightage: 0, id: 1 }],
      }
    }
  );
  const navigate = useNavigate();
  const { getTestCategoryList, createTestQuestion, getSingleTestQuestion, updateTestQuestion } = TestsService();
  const [searchParam] = useSearchParams();
  const { snackbar } = useSnackbar();

  interface OutletProps {
    reFetch: () => void;
    refetchTest: () => void;
  }

  const outlet = useOutletContext<OutletProps>();
  const [answers, setAnswers] = useState<Answer[]>([
    { option: "", weightage: 0, id: 1 },
  ]);
  
  useEffect(() => () => setAnswers([]), []);
  const [answerIdCounter, setAnswerIdCounter] = useState<number>(2);
  
  const { categoryQuesId, testId } = useParams();
  const categories = useQuery({
    queryKey: ["test-category"],
    
    queryFn: () => getTestCategoryList({
      _test: testId,
    })
  });

  const question = useQuery({
    queryKey: ["question-data"],
    
    queryFn: () => getSingleTestQuestion({
      _id: categoryQuesId,
    }),
    enabled: categoryQuesId === "new" ? false : true
  });
  const fields: Field[] = [
    {
      label: "Question",
      name: "question",
      type: "input",
      placeholder: "Question?",
    },
    {
      label: "Question Category",
      name: "_category",
      type: "select",
      placeholder: "options",
      options: categories?.data?.data || [],
    },
  ];
  const onSubmit = async (data: ITestQuesData) => {

    const formData = {
      ...data,
      question: cleanHtmlString(data.question),
      options: data.options.map((optionValue: Answer) => ({
        option: optionValue.option,
        weightage: optionValue.weightage
      }))
    };

    try {
      if (categoryQuesId === "new") {
        const response = await createTestQuestion({ ...formData, _test: testId });
        snackbar(response?.message, "info");
        navigate({
          pathname: `/configurations/tests/test-info/manage/${testId}`,
          search: searchParam.toString()
        });
      
        outlet.reFetch && outlet.reFetch();
        outlet.refetchTest && outlet.refetchTest();


      } else {
        const response = await updateTestQuestion({ ...formData, _test: testId, _id: categoryQuesId });
        snackbar(response?.message, "info");
        navigate({
          pathname: `/configurations/tests/test-info/manage/${testId}`,
          search: searchParam.toString()
        });
        outlet.reFetch && outlet.reFetch();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "warning");
      console.error("Validation error:", err);
    }
  };

  useEffect(() => {
    if (categoryQuesId !== "new" && question?.data?.data) {
      setValue("question", question?.data?.data?.question);
      setValue("_category", question?.data?.data?._category?._id);
      setValue("options",question?.data?.data?.options.map((option, index) => ({
        option: option.option,
        weightage: option.weightage,
        id: index + 1,
      })));
      setAnswers(question?.data?.data?.options.map((option, index) => ({
        option: option.option,
        weightage: option.weightage,
        id: index + 1,
      })));
    }
  }, [categoryQuesId, question?.data]);

  const handleAnswerChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = {
      ...updatedAnswers[index],
      option: e.target.value,
    };
    setValue("options", updatedAnswers);
    trigger("options");
    setAnswers(updatedAnswers);
  };

  const handlePointsChange = (value: number | null, index: number) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = {
      ...updatedAnswers[index],
      weightage: value ? value : 0,
    };
    setValue("options", updatedAnswers);
    setAnswers(updatedAnswers);
  };

  const handleAddAnswer = () => {
    const newAnswer = {
      option: "",
      weightage: 0,
      id: answerIdCounter,
    };

    setValue("options", [...answers, newAnswer]);
    setAnswers([...answers, newAnswer]);
    setAnswerIdCounter(answerIdCounter + 1);
  };

  const handleRemoveAnswer = (id: number) => {
    const updatedAnswers = answers.filter((answer) => answer.id !== id);
    setValue("options", updatedAnswers);
    setAnswers(updatedAnswers);
  };

  const onClose = () => {
    navigate({
      pathname: `/configurations/tests/test-info/manage/${testId}`,
      search: searchParam.toString()
    });
  };
 

 // Custom toolbar options
 const modules = {
  toolbar: [
    ["bold", "italic", "underline"]
  ],
};

const isQuillEmpty = (value: string) => {
  if (!value.replace(/<(.|\n)*?>/g, "").trim().length && !value.includes("<img")) {
    return true;
  }
  return false;
};

  return (
    


<TestInputDialog
      title={categoryQuesId !== "new" ? "Edit Question" : "Create Question"}
      isOpen={!!categoryQuesId}
      onClose={onClose}
      primaryButton={{
        name: "Cancel",
        color: "primary",
        onClick: onClose,
      }}
      secondaryButton={{
        name: "Save Changes",
        color: "primary",
        onClick: handleSubmit(onSubmit),
      }}

    >           
    
      <Grid container spacing={2} sx={{ padding: "0.5rem" }}>
        <Grid item xs={12}>
          <Typography variant="body1">Question?</Typography>
        </Grid>
        {fields.map((field, index) => (
          <Grid item xs={field.type === "select" ? 4 : 8} key={index}>
            <Controller
              name={field.name}
              control={control}
              render={({ field: controllerField }) => (
                <>
                  {field.type === "select" ? (
                    <TextField
                      {...controllerField}
                      select
                      label={field.label}
                      fullWidth
                      variant="outlined"
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                      InputProps={{
                        style: {
                          height: "46px",
                        },
                      }}
                    >
                      {(field.options || []).map((option: ITestCategories) => (
                        <MenuItem key={option._id} value={option._id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <div className={errors[field.name] ? "ql-editor-error" : ""}>
                    <ReactQuill
                    modules={modules}

                    value={controllerField.value}
                    onChange={(content) => {
                      if (!isQuillEmpty(content)) {
                        controllerField.onChange(content);
                      }else{
                        controllerField.onChange("");
                      }
                    }}
                    placeholder={field.placeholder}
                    style={{
                      display: "flex",
                      flexDirection: "column-reverse",
                    }}
                  />
                         {errors[field.name] && (
                    <Typography color="error" variant="caption">
                      {errors[field.name]?.message as React.ReactNode}
                    </Typography>
                  )}
                  </div>
                  )}
                </>
              )}
            />
          </Grid>
        ))}

        <Grid item xs={12}>
          <Typography variant="body1">Options:</Typography>
        </Grid>

        {answers.map((answer, index) => (
          <React.Fragment key={answer.id}>
            <Grid item xs={9} style={{ height: "calc(100% / 7)" }}>
              <TextField
                type="input"
                placeholder="options"
                fullWidth
                value={answer.option}
                error={errors?.options && errors.options[index]?.option ? true : false} // Check for option errors
                helperText={errors?.options && errors.options[index]?.option?.message}
                onChange={(e) => handleAnswerChange(e, index)}
                style={{ height: "100%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Radio
                        checked={false}
                        disabled={true}
                        inputProps={{ "aria-label": "Select Correct Answer" }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
              <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                {<NumberInputIntroduction initialValue ={answer.weightage} onChange={handlePointsChange} index={index}/>}
              </Grid>
              <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="body1"
                  style={{ fontSize: "16px", height: "60%", paddingLeft: "0.5rem", color: "#666666" }}
                >
                  points
                </Typography>
              </Grid>
              {index > 0 && (
              <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  onClick={() => handleRemoveAnswer(answer.id)}
                  style={{ padding: "0.1rem", backgroundColor: "#AA263F" }}
                >
                  <RemoveIcon style={{ fontSize: "1rem", color: "white" }} />
                </IconButton>
              </Grid>
              )}
            </Grid>
          </React.Fragment>
        ))}

        <Grid item xs={12} sx={{ marginBottom: "2rem", marginTop: "1rem" }}>
        <span
   style={{
    textDecoration: "underline",
    cursor: "pointer",
    textDecorationColor: "#346CF5",
    color: "#346CF5",
  }}
  onClick={handleAddAnswer}
>
  Add Option
</span>

        </Grid>
      </Grid>
      <Outlet />
    {/* </CustomDialog> */}

    </TestInputDialog>


  );
};

export default AddQuestion;
