import { LegacyRef, useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { ChangeEvent, MouseEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { IPagination } from "../../../interfaces";
import { InterviewService } from "../../../services";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { IInterviewRow, IInterviewState, IInterview } from "../../../interfaces";
import { Box, Chip, FormControl, MenuItem, Select, InputLabel, SelectChangeEvent } from "@mui/material";
import { capitalize, createIndex, formatDateTime, checkStatusColor } from "../../../utilities/helper";
import InterviewFilter from "../filter-interview";
import Header from "../../../components/header";
import useSnackbar from "../../../hooks/useSnackbar";
import useResource from "../../../hooks/useResource";
import useDebounce from "../../../hooks/useDebounce";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import CustomTypography from "../../../components/mui/max-length-limit";
dayjs.extend(isBetween);

interface outletProps {
  inputRef?: LegacyRef<HTMLInputElement>;
}

const Interviews = () => {
  const [rows, setRows] = useState<IInterviewRow[]>([]);
  const { type } = useParams();
  const { inputRef } = useOutletContext<outletProps>();
  const { getInterviews, deleteInterview } = InterviewService();
  const { snackbar } = useSnackbar();
  const navigate = useNavigate();
  const { resourceAllocate, isSuperAdmin } = useResource();
  const [search, setSearch] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState<IInterviewState>({
    deleteWarning: false,
    interview: "",
    lead: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      status: type === "conducted" ? ["TA SELECTED", "HR SELECTED", "ROHIT SELECTED", "TA REJECTED", "HR REJECTED", "ROHIT REJECTED"] : ["ROHIT SCHEDULED", "HR SCHEDULED", "TA SCHEDULED"],
      interviewStatus: [],
      search: "",
      typeOfLead: [],
      jobId: [],
      assignTo: searchParams.get("assignTo") || "me",
      type
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filterCount: 0,
  });

  const getinterview = useQuery({
    queryKey: ["allInterviews", state.pagination.page, state.filters],
    queryFn: () => getInterviews({
      pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
    })
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      const prevParams: { [index: string]: string } = {};
      searchParams.forEach((value, key) => {
        prevParams[key] = value;
      });
      setSearchParams(prev => ({
        ...prev,
        ...prevParams,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    const status = type === "conducted" ? ["TA SELECTED", "HR SELECTED", "ROHIT SELECTED", "TA REJECTED", "HR REJECTED", "ROHIT REJECTED"] : ["ROHIT SCHEDULED", "HR SCHEDULED", "TA SCHEDULED"];
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        type,
        status
      }
    }));
  }, [type]);

  useEffect(() => {
    if (getinterview.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: getinterview.data.meta.page,
          totalPages: getinterview.data.meta.totalPages,
          totalRecords: getinterview.data.meta.totalRecords
        }
      }));
    }
  }, [getinterview.data?.meta]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
    const typeOfLead: { key: string, value: string }[] = searchParams.get("typeOfLead") ? JSON.parse(String(searchParams.get("typeOfLead"))) : [];
    const jobId: { key: string, value: string }[] = searchParams.get("jobId") ? JSON.parse(String(searchParams.get("jobId"))) : [];
    const interviewStatus: { key: string, value: string }[] = searchParams.get("interviewStatus") ? JSON.parse(String(searchParams.get("interviewStatus"))) : [];
    filterCount += typeOfLead.length ? 1 : 0;
    filterCount += jobId.length ? 1 : 0;
    filterCount += interviewStatus.length ? 1 : 0;
    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search,
        typeOfLead: typeOfLead.map(typeOfLead => typeOfLead.key),
        jobId: jobId.map(jobId => jobId.key),
        interviewStatus: interviewStatus.map(interviewStatus => interviewStatus.key),
      },
      filterCount
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleDelete = (interview = "", lead = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      interview,
      lead
    }
    ));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteInterview({ _id: state.interview, _lead: state.lead});
      snackbar(deleted.message, "info");
      handleDelete();
      getinterview.refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const onChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    searchParams.set(name, value);
    setSearchParams(searchParams);
    setState({
      ...state,
      filters: {
        ...state.filters,
        [name]: value,
      },
    });
  };
  const onChangeStatus = (e: SelectChangeEvent<string>) => {
    if (e.target.value === "upcoming") {
      navigate("/assessments/interview/upcoming");
    }
    if (e.target.value === "conducted") {
      navigate("/assessments/interview/conducted");
    }

  };
  const checkMeetTime = (interviewDateTime: Date | string): boolean => {
    const currentDateTime = dayjs().toLocaleString();
    const beforeFiveMinutes = dayjs(interviewDateTime)
      .subtract(5, "minutes")
      .toLocaleString();
    const afterFiveMinutes = dayjs(interviewDateTime)
      .add(20, "minutes")
      .toLocaleString();

    if (
      dayjs(currentDateTime).isBetween(beforeFiveMinutes, afterFiveMinutes)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "candidateName",
      label: "Candidate Name"
    },
    {
      id: "engagementType",
      label: "Engagement Type"
    },
    {
      id: "jobTitle",
      label: "Job Title"
    },
    {
      id: "interviewRound",
      label: "Interview Round"
    },
    {
      id: "interviewOn",
      label: "Interview On"
    },
    {
      id: "status",
      label: "Status"
    },
    ...(type === "conducted"
      ? [
        {
          id: "score",
          label: "Score"
        },
      ]
      : []),
    {
      id: "action",
      label: "Actions"
    },
  ];

  const createRow = (index: number, interview: IInterview, pagination: IPagination) => {

    const upcomingAction = <GetActions
      icons={[
        { name: "Edit", method: () => navigate({ pathname: "manage/" + interview._id, search: searchParams.toString() }), disabled: resourceAllocate("interview.write") ? false : true },
        { name: "Reschedule Interview", method: () => navigate({ pathname: "reschedule/" + interview._id, search: searchParams.toString() }), disabled: resourceAllocate("interview.write") ? false : true },
        { name: "Delete", method: () => handleDelete(interview._id, interview._lead._id), disabled: resourceAllocate("interview.remove") ? false : true },
        { name: "Google Meet", method: () => window.open(interview.googleMeetUrl, "_blank"), disabled: checkMeetTime(interview?.interviewDateTime ? interview?.interviewDateTime : new Date()) ? false : true },
      ]}
    />;
    const conductedAction = <GetActions
      icons={[
        { name: "Edit", method: () => navigate({ pathname: "manage/" + interview._id, search: searchParams.toString() }), disabled: resourceAllocate("interview.write") ? false : true },
      ]}
    />;

    return {
      id: createIndex(pagination, index),
      candidateName: (
        <CustomTypography
          limit={30}
          label={capitalize(interview._lead?.name)}
          onClick={() => navigate({ pathname: `${interview._lead.typeOfLead.toLowerCase()}/manage/${interview._lead._id}`, search: searchParams.toString() })}
          color="primary"
        />
      ),
      engagementType: capitalize(interview?._lead?.typeOfLead),
      jobTitle: (interview?.jobId?.title),
      interviewRound: (interview?.title),
      interviewOn: interview?.interviewDateTime ? formatDateTime(interview?.interviewDateTime) : "",
      status: <Chip variant={"outlined"} color={checkStatusColor(interview.status)} label={capitalize(interview.status)} />,
      score: (interview?.score),
      action: type === "conducted" ? conductedAction : upcomingAction,
    };
  };

  useEffect(() => {
    if (getinterview.data?.data.length) {
      const rows = getinterview?.data?.data.map((interview, i) => createRow(i, interview, state.pagination));
      setRows(rows);
    } else {
      setRows([]);
    }
  }, [state.filters.type, getinterview?.data?.data]);

  return (
    <>
      {/* Add Data  */}
      <Header
        className="my-2"
        searchPlaceholder="Search by name or email"
        onSearch={onSearch}
        onFilter={openFilter}
        filterCount={state.filterCount}
        inputRef={inputRef}
      >
        {isSuperAdmin() && <FormControl
          fullWidth
          style={{ width: "120px" }}
          variant="outlined"
          className="ml-2"
          size="small"
        >
          <InputLabel >Assign to</InputLabel>
          <Select
            required
            name="assignTo"
            label="Assign to"
            onChange={onChange}
            value={state.filters.assignTo}
          >
            <MenuItem value="me">Only Me</MenuItem>
            <MenuItem value="everyone">My Team</MenuItem>
          </Select>

        </FormControl>}

        <FormControl
          fullWidth
          style={{ width: "135px" }}
          variant="outlined"
          className="ml-2"
          size="small"
        >
          <InputLabel>Interview</InputLabel>
          <Select
            required
            name="interview"
            label="Interview"
            onChange={onChangeStatus}
            value={type}
          >
            <MenuItem value="upcoming">Upcoming</MenuItem>
            <MenuItem value="conducted">Conducted</MenuItem>
          </Select>
        </FormControl>

        <InterviewFilter
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnClose={closeFilter}
        />


      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Interview"
        description="Are you sure you want to delete this interview?"
      />
      <Outlet context={{ reFetch: getinterview.refetch }} />

    </>
  );
};
export default Interviews;
