import { Box, Chip } from "@mui/material";
import { IReview, ICandidateReviewRow, ICandidateDetails } from "../../../interfaces";
import { formatDateTime, capitalize, checkStatusColor } from "../../../utilities/helper";
import { FC, useEffect, useState } from "react";
import CustomTable from "../../mui/table";

interface props {
    candidate: ICandidateDetails | undefined;
    activeAction: boolean;
    currentTab: number;
    candidateDetailRefetch: () => void;
    candidateListRefetch: () => void;
}

interface IState {
    list: ICandidateReviewRow[];
}

const Review: FC<props> = ({ candidate, activeAction, currentTab }) => {
    const reviews = candidate && candidate.reviewRounds && [...candidate.reviewRounds];
    const [state, setState] = useState<IState>({
        list: [],
    });

    useEffect(() => {
        if (reviews) {
            const list = reviews ? reviews.map((callStatus, i) => createRow(i, callStatus)) : [];
            setState(prevState => (
                {
                    ...prevState,
                    list
                }
            ));
        }
    }, [candidate?.reviewRounds]);

    const columns = [
        {
            id: "id",
            label: "S No."
        },
        {
            id: "reviewer",
            label: "Reviewer"
        },
        {
            id: "review_at",
            label: "Review On"
        },
        {
            id: "status",
            label: "Status"
        },
    ];

    const createRow = (index: number, review: IReview,) => ({
        id: index + 1,
        reviewer: capitalize(review._reviewer.name),
        review_at: formatDateTime(review.createdAt),
        status: <Chip variant={"outlined"} color={checkStatusColor(review.status)} label={capitalize(review.status)} />,
    });

    const isActionActive = currentTab === 5 && activeAction;

    return (

        <Box paddingTop="10px">
            <Box height="56vh" overflow="auto" paddingTop="10px">
                {/* Show Data  */}
                <CustomTable
                    columns={columns}
                    rows={state.list}
                    height={isActionActive ? "calc(100% - 144px)" : "calc(100% - 12px)"}
                    width="calc(100% - 2px)"
                />
            </Box>
        </Box>

    );
};

export default Review;