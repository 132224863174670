import { useState, SyntheticEvent, useEffect, useRef } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const redirectionLinks = ["interview/upcoming","review/pending"];
const routes = ["interview/upcoming","interview/conducted","review/pending","review/completed"];
const AssessmentsLayout = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const inputRef = useRef<HTMLInputElement | null>(null);



  useEffect(() => {
    let tabNumber = routes.indexOf(location.pathname.split("/")[2] + "/" + location.pathname.split("/")[3]);
    tabNumber = Math.floor(tabNumber/2);
    setValue(tabNumber);
  }, [location.pathname]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    navigate(redirectionLinks[newValue]);
    if (inputRef && inputRef?.current) {
      inputRef.current.value = "";
    }
  };

  return (
    <Box width="100%" >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab className="tabs-space start-tab-space" label="Interviews" {...a11yProps(0)} />
          <Tab className="tabs-space" label="Reviews" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Outlet context={{ inputRef }} />
    </Box>
  );
};

export default AssessmentsLayout;