import { Box } from "@mui/material";
import { useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import {
  createIndex,
  capitalize,
  formatHtmlString,
} from "../../../../../../../../utilities/helper";
import { IPagination } from "../../../../../../../../interfaces";
import CustomTable from "../../../../../../../../components/mui/table";
import {
  IResponseQuestionRow,
} from "../../../../../../../../interfaces/configuration/tests";
import { useQuery } from "@tanstack/react-query";
import { ITestQuestion } from "../../../../../../../../interfaces/candidate/manage-candidate/psy-test";
import { TestSessionService } from "../../../../../../../../services";

const ResponseAllQuestions = () => {
  let rows: IResponseQuestionRow[] = [];
  const { sessionId, testId } = useParams();
  const {  getPsyResponsesTest } = TestSessionService();


  interface IState {

    pagination: {
      page: number;
      limit: number;
      totalPages: number;
    };
  }

  const [state] = useState<IState>({
    pagination: {
      page: 1,
      limit: 999999,
      totalPages: 1,
    },
  });

  const testQuestions = useQuery({
    queryKey: ["psy-test-questions"],
    queryFn: () => getPsyResponsesTest({ _session : sessionId, _test: testId }),
  }
  );  

  const columns = [
    {
      id: "id",
      label: "Sr No.",
    },
    {
      id: "question",
      label: "Question",
    },
    {
      id: "selectedOption",
      label: "Selected Options",
    },
    {
      id: "marksObtained",
      label: "Marks Obtained",
    },
    {
      id: "category",
      label: "Category",
    },
  ];

  const createRow = (
    index: number,
    test: ITestQuestion,
    pagination: IPagination
  ): IResponseQuestionRow => ({
    id: createIndex(pagination, index),
    question: test.question.length > 60
    ? `${capitalize(formatHtmlString(test.question)).slice(0, 60)}...`
    : capitalize(formatHtmlString(test.question)),
    selectedOption: test.question.length > 30 
      ? `${capitalize(test.selectedOption).slice(0, 30)}...`
      : test.selectedOption ?? "--", 
    marksObtained: `${test.obtained ?? 0}/${test.highMarks ?? 0}`,
    category: test._category.name ?? "",
  });
  if (testQuestions &&
    testQuestions.data &&
    testQuestions.data.data
    && testQuestions.data.data.length) {
    rows = testQuestions.data.data.map((test, index) =>
      createRow(index, test, state.pagination)
    );
  }

  return (
    <div>
      <Box paddingTop="10px">
        <Box height="56vh" paddingTop="10px" margin={"0 10px"}>
          <CustomTable
            columns={columns}
            rows={rows}
            height={"calc(100% - 30px)"}
            width="calc(100% - 2px)"
          />
        </Box>
      </Box>
      <Outlet context={{ ...Outlet }} />
    </div>
  );
};
export default ResponseAllQuestions;