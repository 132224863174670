import { ICollege, IContactPerson, IContactPersonDetail, IErrorResponse, TBasicContactPerson } from "../../../../../../interfaces";
import { Box, Grid, TextField, Chip, SelectChangeEvent } from "@mui/material";
import CustomDialog from "../../../../../../components/mui/dialog";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { ContactPersonValidation } from "../../../../../../validations";
import { KeyboardEvent, useEffect, useState, FC } from "react";
import { validateEmail } from "../../../../../../validations/shared";
import MobileNumber from "../../../../../../components/mui/mobile-number";
import { capitalize } from "../../../../../../utilities/helper";
import useSnackbar from "../../../../../../hooks/useSnackbar";
import { CollegeService } from "../../../../../../services";
interface props {
  contactPerson: IContactPersonDetail | undefined;
  isOpen: boolean;
  onClose: () => void;
  college: ICollege | undefined;
  onUpdate?: (data: IContactPersonDetail) => void;
  contactDetailRefetch: () => void;

}
const ManageContactPerson: FC<props> = ({ contactPerson, isOpen, onClose, college, contactDetailRefetch }) => {
  const { snackbar } = useSnackbar();
  const { updateContactPerson } = CollegeService();
  const { handleSubmit, control, getValues, setValue, trigger, resetField,setError, formState: { errors } } = useForm<IContactPersonDetail>({
    resolver: joiResolver(ContactPersonValidation),
    defaultValues: {
      name: "",
      emailText: "",
      number: "",
      designation: "",
      notes: "",
    }
  });

  const [mobileNumber, setMobileNumber] = useState({
    country: "INDIA",
    dialCode: "+91",
    iso2: "IN"
  });

  const onNumberChange = (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    const data = value.split(":");
    setMobileNumber({
      country: data[0],
      iso2: data[1],
      dialCode: data[2]
    });
  };

  const handleEmailCopyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content);
      snackbar("Email ID copied to clipboard", "info");
  };

  useEffect(() => {
    if (contactPerson) {
      const contactPersonInfo = contactPerson;
      const keys: TBasicContactPerson[] = ["name", "email", "number", "designation", "notes"];
      keys.forEach(key => {
        switch (key) {
          case "name":
            setValue("name", capitalize(contactPersonInfo?.name));
            break;
          case "number":
            setValue("number", contactPersonInfo?.mobileNumber?.number);
            break;
          case "email":
            setValue("email", contactPersonInfo?.email || []);
            break;
          case "designation":
            setValue("designation", contactPersonInfo?.designation);
            break;
          case "notes":
            setValue("notes", contactPersonInfo?.notes);
        }
        trigger("email");
      });
      const mobileNumber = contactPersonInfo.mobileNumber;
      setMobileNumber(prevState => (
        {
          ...prevState,
          country: mobileNumber?.country,
          iso2: mobileNumber?.iso2,
          dialCode: mobileNumber?.dialCode
        }
      ));
    }
  }, [contactPerson, isOpen]);

  const addEmail = (e: KeyboardEvent<HTMLDivElement>, key: string) => {
    let payload: string[] = [];
    if (key === "emailText") {
      const err = validateEmail(getValues(key)); 
      if (err.error) {
        return;
      }
      if (e.key === "Enter" && key === "emailText") {
        const prev = getValues("email") ? getValues("email") : [];
        const newEmail = getValues("emailText") ? String(getValues("emailText")) : "";
        payload = [...prev, newEmail];
        setValue("email", [...new Set(payload)]);
        resetField(key);
      }
    }
  };

  const removeEmail = (value: string) => {
    let payload = getValues("email");
    payload = payload.filter(email => email !== value);
    setValue("email", payload);
    trigger("email");
  };

  const onSubmit = async (data: IContactPersonDetail) => {
    const payload = {
      ...data,
      _college: college?._id,
      _contactPerson: contactPerson?._id,
      mobileNumber: {
        ...mobileNumber,
        number: data.number,
      },
      designation: data.designation,
      notes: data.notes,
    };
    
    if(payload.emailText){
      setError("emailText",{
        message:"please press enter to proceed"
      });
      return;
    }
    delete payload.emailText;
    delete payload.number;
    try {
      const user = await updateContactPerson(payload);
      snackbar(user.message, "info");
      onClose();
      contactDetailRefetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log("error in manage contact person", error);
    }
  };

  const fields: IContactPerson[] = [
    {
      type: "input",
      name: "name",
      label: "Name*",
      placeholder: "Type name here"
    },
    {
      type: "mobile-number",
      name: "number",
      label: "Mobile Number*",
      placeholder: "Type mobile number here"
    },
    {
      type: "input",
      name: "emailText",
      label: "Email*",
      placeholder: "Type email address and press enter"
    },
    {
      type: "input",
      name: "designation",
      label: "Designation*",
      placeholder: "Type designation here"
    },
    {
      type: "multiline",
      name: "notes",
      label: "Note",
      width: 12,
      placeholder: "Type note here"
    },
  ];

  return (
    <Box>
      <CustomDialog
        title={"Edit Contact Person Detail"}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4}>
          {
            fields.map(field => {
              if (field.type === "input") {
                return (<Grid key={field.label} item xs={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <TextField
                      label={field.label}
                      className="disable-text"
                      variant={"outlined"}
                      size={"small"}
                      placeholder={field.placeholder}
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                      {...prop.field}
                      onKeyUp={e => addEmail(e, field.name)}
                      onKeyDown={e => e.key === "Enter" && e.preventDefault()}
                    />}
                  />
                  {
                    <Box>
                      {
                        field.name === "emailText" && getValues("email") &&
                        getValues("email").map(email => <Chip
                          key={email}
                          label={email}
                          onClick={() => handleEmailCopyToClipboard(email)}
                          onDelete={() => removeEmail(email)}
                          color="primary"
                          variant="outlined"
                          sx={{ margin: "5px" }}
                        />)
                      }
                    </Box>
                  }
                </Grid>
                );
              } else if (field.type === "mobile-number") {
                return <Grid key={field.label} item xs={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <MobileNumber
                      key={field.label}
                      NumberFieldLabel={field.label}
                      dialCodeValue={`${mobileNumber?.country}:${mobileNumber?.iso2}:${mobileNumber?.dialCode}`}
                      onChange={onNumberChange}
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                      other={prop.field}
                    />}
                  />
                </Grid>;
              } else if (field.type === "multiline") {
                return (<Grid key={field.label} item xs={12} md={field.width ? field.width : 12}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <TextField
                      label={field.label}
                      className="disable-text"
                      variant="outlined"
                      size="small"
                      placeholder={field.placeholder}
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                      multiline
                      minRows={2}
                      {...prop.field}
                    />}
                  />
                </Grid>
                );
              }
            })
          }
        </Grid>
      </CustomDialog>
    </Box>
  );
};

ManageContactPerson.defaultProps = {
  isOpen: false
};

export default ManageContactPerson;
