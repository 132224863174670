
import "./style.scss";
import { FC } from "react";
import { Box, Card, Typography } from "@mui/material";
import CircularProgressWithLabel from "../circular-progress-bar";

interface props {
    title: string;
    subtitle: string;
    count: number;
    label: string;
}

const AnalyticsCard:FC<props> = ({ title, subtitle, count, label}) => 
        <Box>
            <Card className="card" style={{height:300}}>
                <div>
                    <Typography  sx={{fontWeight:700}} variant="h6">
                        {title}
                    </Typography>

                    <Typography className="subtitle" gutterBottom>
                        { subtitle }
                    </Typography>
                </div>

                <Box sx={{ position: "relative", display: "inline-flex" }}>
                    <CircularProgressWithLabel count={count} label={label} />
                </Box>
            </Card>
        </Box>;
        
export default AnalyticsCard;