import { Box, Grid, TextField } from "@mui/material";
import CustomDialog from "../../../../../components/mui/dialog";
import { Controller, useForm } from "react-hook-form";
import { IErrorResponse } from "../../../../../interfaces/shared/response";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { FC, useEffect } from "react";
import React from "react";
import { VendorNotesService } from "../../../../../services/vendor/notes";
import { useQuery } from "@tanstack/react-query";
import { joiResolver } from "@hookform/resolvers/joi";
import { INote, INoteDetail } from "../../../../../interfaces/vendor/notes";
import CustomLabel from "../../../../../components/mui/custom-label";
import { vendorNoteValidation } from "../../../../../validations/vendor/manage-vendor/notes";

interface props {
  note: string;
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}

const ManageNotes: FC<props> = ({ note, isOpen, onClose, refetch }) => {
  const { updateNote, getNote } = VendorNotesService();
  const { snackbar } = useSnackbar();
  const { handleSubmit, control, setValue,formState: { errors } } = useForm<INoteDetail>({
    resolver: joiResolver(vendorNoteValidation),
    defaultValues: {
      note: "",
    }
  });

  const vendorNote = useQuery({
    queryKey: ["note", note],
    queryFn: () =>
      getNote({
        _id: note
      }),
    enabled:  !!note && isOpen,
  });

  useEffect(() => {
    if(vendorNote?.data?.data){
      const data = vendorNote.data.data;
      setValue("note", data.note);
    }
  }, [vendorNote?.data?.data, isOpen]);

  const onSubmit = async (data: INoteDetail) => {
    const payload = {
      ...data,
      _id:  note
    };
    try {
      const user = await updateNote(payload);
      snackbar(user.message, "info");
      refetch();
      onClose();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log("error in manage contact person", error);
    }
  };

  const fields: INote[] = [
    {
      type: "input",
      name: "note",
      label: "Note",
      placeholder: "Type note here",
      required: true
    }
  ];

  return (
    <Box>
      <CustomDialog
        title={"Edit Note Detail"}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
        size="xs"
      >
        <Grid container spacing={4}>
          {
            fields.map(field => {
              if (field.type === "input") {
                return (<Grid key={field.label} item xs={12}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <TextField
                      label={<CustomLabel label={field.label} required={field?.required} />}
                      className="disable-text"
                      variant={"outlined"}
                      size={"small"}
                      placeholder={field.placeholder}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      {...prop.field}
                    />}
                  />
                </Grid>
                );
              }
              }
            )}
        </Grid>
      </CustomDialog>
    </Box>
  );
};

ManageNotes.defaultProps = {
  isOpen: false
};

export default ManageNotes;
