import { useState } from "react";
import DoneSharpIcon from "@mui/icons-material/DoneSharp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Radio,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomDialogTest from "../../../../../../../../components/mui/dialog/testDialogMultiOption";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DoneIcon from "@mui/icons-material/Done";
import GetAppIcon from "@mui/icons-material/GetApp";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams } from "react-router-dom";
import { singleQuestions } from "../../../../../testSampleInputs";
import useSnackbar from "../../../../../../../../hooks/useSnackbar";

interface Question {
  question: string;
  answer: string;
  isCorrect: boolean;
}

const questions: Question[] = singleQuestions;
const QUESTIONS_PER_PAGE = 5;

const PurpleRadio = styled(Radio)(() => ({
  color: "#9c27b0",
  "&.Mui-checked": {
    color: "#9c27b0",
  },
}));

const SingleResponse = () => {
  const { id } = useParams();
  const { snackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const totalPages = Math.ceil(questions.length / QUESTIONS_PER_PAGE);

  const handlePrevious = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNext = () => {
    setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
  };

  const navigate = useNavigate();
  const onClose = () => {
    const pathname = `/configurations/tests/test-info/manage/${id}`;
    navigate({ pathname });
  };

  const handleInfoMenuItemClick = () => {
    snackbar("clicked on menu", "info");
  };

  const infoMenuItems = [
    {
      icon: <VisibilityOffIcon />,
      label: "Show Unanswered Questions",
      onClick: handleInfoMenuItemClick,
    },
    {
      icon: <DoneIcon />,
      label: "Print this Response",
      onClick: handleInfoMenuItemClick,
    },
    {
      icon: <GetAppIcon />,
      label: "Download this Response",
      onClick: handleInfoMenuItemClick,
    },
    {
      icon: <DeleteIcon />,
      label: "Delete this Response",
      onClick: handleInfoMenuItemClick,
    },
  ];

  const paginatedQuestions = questions.slice(
    page * QUESTIONS_PER_PAGE,
    (page + 1) * QUESTIONS_PER_PAGE
  );

  return (
    <CustomDialogTest
      isOpen={true}
      onCancel={onClose}
      showSave={false}
      onClose={onClose}
      title="Peter Boyle"
      secondaryTitle="PeterBoyle@gmail.com"
      confirmText="Save"
      cancelText="Close"
      infoMenuItems={infoMenuItems}
      bottomLeftField="17 May 2024 15:42"
      bottomRightField="Marks 26/30"
      pageNext={handleNext}
      pagePrevious={handlePrevious}
    >
      <Grid container spacing={2}>
        {paginatedQuestions.map((question, index) => (
          <Grid item xs={12} key={index}>
            <Card variant="outlined" sx={{ border: "none" }}>
              <CardContent sx={{ paddingBottom: "8px" }}>
                <Grid container alignItems="center">
                  <Grid item>
                    <FormControlLabel
                      control={<PurpleRadio checked />}
                      label={
                        <Typography variant="body2" color="textPrimary">
                          {index + 1 + page * QUESTIONS_PER_PAGE}.{" "}
                          {question.question}
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container alignItems="center" mt={0.5} mx={"1rem"}>
                  <Grid item>
                    {question.isCorrect ? (
                      <DoneSharpIcon
                        sx={{
                          color: "green",
                          fontSize: 24,
                          marginRight: "0.5rem",
                        }}
                      />
                    ) : (
                      <CloseSharpIcon
                        sx={{ color: "red", marginRight: "0.5rem" }}
                      />
                    )}
                  </Grid>

                  <Grid item xs>
                    <Typography variant="body2" color="textSecondary">
                      {question.answer}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </CustomDialogTest>
  );
};
export default SingleResponse;