import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { IErrorResponse } from "../../../../interfaces";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import { capitalize } from "../../../../utilities/helper";
import { BusinessUnitsService } from "../../../../services/configuration/business-unit";
import { IBusinessUnit, IBusinessUnitField } from "../../../../interfaces/configuration/business-unit";
import { businessUnitValidation } from "../../../../validations/configuration/business-unit";
import CustomLabel from "../../../../components/mui/custom-label";
interface outletProps {
    reFetch: () => void;
    refetchBusinessUnits: () => void;
}

const ManageBusinessUnit = () => {
    const { id } = useParams();
    const [searchParam] = useSearchParams();
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();
    const hitQuery = !(id === "new");
    const navigate = useNavigate();
    const { getBusinessUnit, addBusinessUnit, updateBusinessUnit } = BusinessUnitsService();
    const businessUnit = useQuery({ queryKey: [hitQuery], queryFn: () => getBusinessUnit({ _id: id }), enabled: hitQuery });
    const { handleSubmit, control, setValue, formState: { errors } } = useForm<IBusinessUnit>({
        resolver: joiResolver(businessUnitValidation),
        defaultValues: {
            name: "",
        }
    });

    useEffect(() => {
        if (id !== "new") {
            if (businessUnit.data?.data) {
                setValue("name", capitalize(businessUnit.data.data.name) || "");
            }
        }
    }, [id, businessUnit.data]);

    const onSubmit = async (data: IBusinessUnit) => {
        try {
            if (id === "new") {
                const add = await addBusinessUnit(data);
                snackbar(add.message, "info");
                navigate({
                    pathname: "/configurations/business-unit",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
                outlet?.refetchBusinessUnits && outlet.refetchBusinessUnits();
            }
            else {
                const payload = { ...data, _id: id };
                const update = await updateBusinessUnit(payload);
                snackbar(update.message, "info");
                navigate({
                    pathname: "/configurations/business-unit",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
                outlet?.refetchBusinessUnits && outlet.refetchBusinessUnits();
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
        }

    };

    const onClose = () => {
        navigate({
            pathname: "/configurations/business-unit",
            search: searchParam.toString()
        });
    };

    const fields: IBusinessUnitField[] = [
        {
            label: "Name",
            name: "name",
            type: "input",
            placeholder: "Type your business unit name here",
            required: true
        },
    ];

    return (
        <Box>
            <CustomDialog
                size='sm'
                title={id !== "new" ? "Edit Business Unit" : "Add Business Unit"}
                confirmText={id !== "new" ? "Update" : "Add"}
                isOpen={!!id}
                onClose={onClose}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "input") {
                                return (<Grid key={field.label} item xs={12}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={<CustomLabel label={field.label} required={field.required} />}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            placeholder={field.placeholder}
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message}
                                            inputProps={{maxLength: 80}}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            }
                        })
                    }
                </Grid>
            </CustomDialog>
        </Box>
    );
};

export default ManageBusinessUnit;