import HttpService from "../../http";
import { IVendorResponse, IVendorsResponse } from "../../../interfaces/vendor";
import { IVendorContactPersonResponse, IVendorContactPersonsResponse } from "../../../interfaces/vendor/contact-person";
import { vendorContactPerson } from "../../endpoints";

const VendorContactPersonService = () => {
    const { httpRequest } = HttpService();

    const addContactPerson = async (payload: object): Promise<IVendorsResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsResponse>(
            "POST",
            `${vendorContactPerson}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updateContactPerson = async (payload: object): Promise<IVendorResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorResponse>(
            "PUT",
            `${vendorContactPerson}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteContactPerson = async (search: object): Promise<IVendorResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorResponse>(
            "DELETE",
            `${vendorContactPerson}`,
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getContactPersons = async (search: object): Promise<IVendorContactPersonsResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorContactPersonsResponse>(
            "GET",
            `${vendorContactPerson}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getContactPerson = async (search: object): Promise<IVendorContactPersonResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorContactPersonResponse>(
            "GET",
            `${vendorContactPerson}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    return { addContactPerson, updateContactPerson, deleteContactPerson, getContactPersons, getContactPerson};
};

export { VendorContactPersonService };