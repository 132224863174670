import HttpService from "../../http";
import { ITemplatesResponse, ITemplateResponse } from "../../../interfaces/configuration/template";
import { template } from "../../endpoints";

const TemplateService = () => {
    const { httpRequest } = HttpService();
    const getTemplates = async (search: object = {}): Promise<ITemplatesResponse> => new Promise((resolve, reject) => {
        httpRequest<ITemplatesResponse>(
            "GET",
            `${template}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getTemplate = async (query: object): Promise<ITemplateResponse> => new Promise((resolve, reject) => {
        httpRequest<ITemplateResponse>(
            "GET",
            template,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const addTemplate = async (payload: object): Promise<ITemplateResponse> => new Promise((resolve, reject) => {
        httpRequest<ITemplateResponse>(
            "POST",
            template,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const updateTemplate = async (payload: object): Promise<ITemplateResponse> => new Promise((resolve, reject) => {
        httpRequest<ITemplateResponse>(
            "PUT",
            template,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteTeam = async (search: object): Promise<ITemplateResponse> => new Promise((resolve, reject) => {
        httpRequest<ITemplateResponse>(
            "DELETE",
            template,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    return { getTemplates, getTemplate, addTemplate, updateTemplate, deleteTeam };
};

export { TemplateService };
