import HttpService from "../../http";
import {
  ITestResponse,
  ITestsResponse,
  ITestsCategoriesResponse,
  ITestQuestionResponse,
  ITestResponsesResponse,
  ITestUserQuestionResponse,
  ITestSingleQuestionResponse,
  IQuestionSampleResponse,
} from "../../../interfaces/configuration/tests";
import { test, testSession } from "../../endpoints";
import { ITestSingleCategoryResponse } from "../../../interfaces/configuration/tests";
import { ICreateTestCategoriesResponse } from "../../../interfaces/configuration/tests";
import { IAssignedTestQuestionsResponse, IAssignedTestResponse, IAssignedTestsResponse } from "../../../interfaces/candidate/manage-candidate/psy-test";

const TestsService = () => {
  const { httpRequest } = HttpService();

    const addTest = async (payload: object): Promise<ITestsResponse> => new Promise((resolve, reject) => {
        httpRequest<ITestsResponse>(
            "POST",
            `${test}`,                               
            payload         
        )
            .then(resolve)
            .catch(reject);
    });

    const getTests = async (search?: object): Promise<ITestsResponse> => new Promise((resolve, reject) => {
        httpRequest<ITestsResponse>(
            "GET",
            `${test}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getSingleTest = async (search: object): Promise<ITestResponse> => new Promise((resolve, reject) => {
        httpRequest<ITestResponse>(
            "GET",
            `${test}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteTest = async (query: object): Promise<ITestsResponse> => new Promise((resolve, reject) => {
        httpRequest<ITestsResponse>(
            "DELETE",
            `${test}`,
            query,
        )
            .then(resolve)
            .catch(reject);
    });


    const cloneTest = async (payload: object): Promise<ITestsResponse> => new Promise((resolve, reject) => {
      httpRequest<ITestsResponse>(
          "POST",
          `${test}/clone`,
          payload
      )
          .then(resolve)
          .catch(reject);
  });
    const updateTest = async ( payload: object): Promise<ITestResponse> => new Promise((resolve, reject) => {
        httpRequest<ITestResponse>(
            "PUT",
            test,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const updateTestStatus = async ( payload: object): Promise<ITestResponse> => new Promise((resolve, reject) => {
      httpRequest<ITestResponse>(
          "PUT",
          `${test}/status`,
          payload,
      )
          .then(resolve)
          .catch(reject);
  });
    const skillAssesmentInfo = async ( payload: object): Promise<ITestsCategoriesResponse> => new Promise((resolve, reject) => {
        httpRequest<ITestsCategoriesResponse>(
            "GET",
            `${test}/test-category/list`,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

// API for fetching Categories List

    const getTestCategoryList = async ( payload: object): Promise<ITestsCategoriesResponse> => new Promise((resolve, reject) => {
        httpRequest<ITestsCategoriesResponse>(
            "GET",
            `${test}/test-category/list`,
            {},
            payload
        )
            .then(resolve)
            .catch(reject);
    });

 // creating a new sub test category 


  const createTestCategory = async (
    payload: object
  ): Promise<ICreateTestCategoriesResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<ICreateTestCategoriesResponse>(
        "POST",
        `${test}/test-category`,
        payload
      )
        .then(resolve)
        .catch(reject);
    });

  const getTestQuestionList = async (
    payload: object
  ): Promise<ITestQuestionResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<ITestQuestionResponse>(
        "GET",
        `${test}/question/list`,
        payload
      )
        .then(resolve)
        .catch(reject);
    });

  const createTestQuestion = async (
    payload: object
  ): Promise<ITestsCategoriesResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<ITestsCategoriesResponse>("POST", `${test}/question`, payload)
        .then(resolve)
        .catch(reject);
    });


//For updating questions 

const updateTestQuestion = async ( payload: object): Promise<ITestSingleQuestionResponse> => new Promise((resolve, reject) => {
    httpRequest<ITestSingleQuestionResponse>(
        "PUT",
        `${test}/question`,
        payload,
    )
        .then(resolve)
        .catch(reject);
});

// Creating API for fetching of Response list 
const getTestResponseList = async ( payload: object): Promise<ITestResponsesResponse> => new Promise((resolve, reject) => {
    httpRequest<ITestResponsesResponse>(
        "GET",
        `${test}/response/list`,
        {},
        payload
      )
        .then(resolve)
        .catch(reject);
    });
    const getTestPsyResponse  = async ( payload: object): Promise<ITestResponsesResponse> => new Promise((resolve, reject) => {
    httpRequest<ITestResponsesResponse>(
        "GET",
        `${testSession}/psy-question-responses/list`,
        {},
        payload
      )
        .then(resolve)
        .catch(reject);
    });

  const getSingleTestResponse = async (
    payload: object
  ): Promise<ITestsCategoriesResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<ITestsCategoriesResponse>(
        "GET",
        `${test}/response/list`,
        payload
      )
        .then(resolve)
        .catch(reject);
    });

  const getAllQuestionsByUser = async (
    payload: object
  ): Promise<ITestUserQuestionResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<ITestUserQuestionResponse>(
        "GET",
        `${test}/response/list`,
        payload
      )
        .then(resolve)
        .catch(reject);
    });

  const getUserBasicDetail = async (
    payload: object
  ): Promise<ITestUserQuestionResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<ITestUserQuestionResponse>(
        "GET",
        `${test}/response/list`,
        payload
      )
        .then(resolve)
        .catch(reject);
    });

  const deleteTestCategories = async (query: object): Promise<ITestsResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<ITestsResponse>("DELETE", `${test}/test-category`, query)
        .then(resolve)
        .catch(reject);
    });

  const updateTestCategories = async (
    payload: object
  ): Promise<ITestResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<ITestResponse>("PUT", `${test}/test-category`, payload)
        .then(resolve)
        .catch(reject);
    });

  const getTestsQuestionList = async (
    search: object
  ): Promise<ITestQuestionResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<ITestQuestionResponse>(
        "GET",
        `${test}/question/list`,
        {},
        search
      )
        .then(resolve)
        .catch(reject);
    });

  const getSingleTestQuestion = async (
    search: object
  ): Promise<ITestSingleQuestionResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<ITestSingleQuestionResponse>(
        "GET",
        `${test}/question`,
        {},
        search
      )
        .then(resolve)
        .catch(reject);
    });

  const getTestQuestionSample = async (search: object): Promise<IQuestionSampleResponse> => new Promise((resolve, reject) => {
    httpRequest<IQuestionSampleResponse>(
      "GET",
      `${test}/question/sample`,
      {},
      search
    )
      .then(resolve)
      .catch(reject);
  });

  const importTestQuestions = async (payload: object): Promise<ITestQuestionResponse> =>
    new Promise((resolve, reject) => {
        // eslint-disable-next-line
        httpRequest<ITestQuestionResponse>(
            "POST",
            `${test}/question/import`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

  const getgetCategoryById = async (
    payload: object
  ): Promise<ITestSingleCategoryResponse> =>
    new Promise((resolve, reject) => {
      httpRequest<ITestSingleCategoryResponse>(
        "GET",
        `${test}/test-category`,
        {},
        payload
      )
        .then(resolve)
        .catch(reject);
    });

    const updateTestQuestionPosition = async (payload: object): Promise<ITestSingleQuestionResponse> => new Promise((resolve, reject) => {
      httpRequest<ITestSingleQuestionResponse>(
          "PUT",
          `${test}/question/position`,
          payload
      )
          .then(resolve)
          .catch(reject);
  });

  const deleteTestQuestion = async (payload: object): Promise<ITestSingleQuestionResponse> => new Promise((resolve, reject) => {
      httpRequest<ITestSingleQuestionResponse>(
          "DELETE",
          `${test}/question`,
          payload
      )
          .then(resolve)
          .catch(reject);
  });

  // Assign Test to User

  const assignTest = async (payload: object): Promise<ITestResponse> => new Promise((resolve, reject) => {
    httpRequest<ITestResponse>(
        "POST",
        `${test}/assign`,
        payload,
    )
        .then(resolve)
        .catch(reject);
});

const getAssignedTests = async (search: object): Promise<IAssignedTestsResponse> => new Promise((resolve, reject) => {
  httpRequest<IAssignedTestsResponse>(
      "GET",
      `${test}/response/assigned-tests`,
      {},
      search
  )
      .then(resolve)
      .catch(reject);
});

const getAssignedTest = async (search: object): Promise<IAssignedTestResponse> => new Promise((resolve, reject) => {
  httpRequest<IAssignedTestResponse>(
      "GET",
      `${test}/response/assigned-test`,
      {},
      search
  )
      .then(resolve)
      .catch(reject);
});
const getPsyAssignedTest = async (search: object): Promise<IAssignedTestResponse> => new Promise((resolve, reject) => {
  httpRequest<IAssignedTestResponse>(
      "GET",
      `${testSession}/psy/assigned-test`,
      {},
      search
  )
      .then(resolve)
      .catch(reject);
});

const getAssignedTestQuestions = async (search: object): Promise<IAssignedTestQuestionsResponse> => new Promise((resolve, reject) => {
  httpRequest<IAssignedTestQuestionsResponse>(
      "GET",
      `${test}/response/assigned-test/questions`,
      {},
      search
  )
      .then(resolve)
      .catch(reject);
});

const getTestCategoryPartial = async(query:object):Promise<ITestSingleCategoryResponse> => 
  new Promise((resolve,reject) => {
         httpRequest<ITestSingleCategoryResponse>("GET",`${test}/test-category/partial`,{},query)
         .then(resolve)
         .catch(reject);
    });
  return {
    addTest,
    getTests,
    getSingleTest,
    deleteTest,
    cloneTest,
    updateTest,
    updateTestStatus,
    skillAssesmentInfo,
    getTestCategoryList,
    updateTestQuestionPosition,
    getTestQuestionList,
    getTestResponseList,
    createTestCategory,
    createTestQuestion,
    getSingleTestResponse,
    getAllQuestionsByUser,
    getUserBasicDetail,
    deleteTestCategories,
    updateTestCategories,
    getTestsQuestionList,
    getSingleTestQuestion,
    getTestQuestionSample,
    updateTestQuestion,
    getgetCategoryById,
    deleteTestQuestion,
    assignTest,
    getAssignedTests,
    getAssignedTest,
    getAssignedTestQuestions,
    getTestCategoryPartial,
    importTestQuestions,
    getTestPsyResponse,
    getPsyAssignedTest
  };
};

export { TestsService };