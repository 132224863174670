import HttpService from "../../http";
import { IVendorResponse, IVendorsResponse } from "../../../interfaces/vendor";
import { IVendorNoteResponse, IVendorNotesResponse } from "../../../interfaces/vendor/notes";
import { vendorNote } from "../../endpoints";

const VendorNotesService = () => {
    const { httpRequest } = HttpService();

    const addNote = async (payload: object): Promise<IVendorsResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsResponse>(
            "POST",
            `${vendorNote}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updateNote = async (payload: object): Promise<IVendorResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorResponse>(
            "PUT",
            `${vendorNote}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteNote = async (payload: object): Promise<IVendorResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorResponse>(
            "DELETE",
            `${vendorNote}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getNotes = async (search: object): Promise<IVendorNotesResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorNotesResponse>(
            "GET",
            `${vendorNote}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getNote = async (search: object): Promise<IVendorNoteResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorNoteResponse>(
            "GET",
            `${vendorNote}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    return { addNote, updateNote, deleteNote , getNotes, getNote};
};

export { VendorNotesService };