import HttpService from "../../http";
import { IParametersResponse, IParameterResponse } from "../../../interfaces/configuration/parameter";
import {parameter} from "../../endpoints";

const ParameterService = () => {
    const { httpRequest } = HttpService();
    const addParameters = async (payload: object): Promise<IParametersResponse> => new Promise((resolve, reject) => {
        httpRequest<IParametersResponse>(
            "POST",
            `${parameter}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getParameters = async (search: object): Promise<IParametersResponse> => new Promise((resolve, reject) => {
        httpRequest<IParametersResponse>(
            "GET",
            `${parameter}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getParameter = async (query: object): Promise<IParameterResponse> => new Promise((resolve, reject) => {
        httpRequest<IParameterResponse>(
            "GET",
            `${parameter}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateParameter = async (id: string, payload: object): Promise<IParameterResponse> => new Promise((resolve, reject) => {
        httpRequest<IParameterResponse>(
            "PUT",
            `${parameter}`,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteParameter = async (payload: object): Promise<IParametersResponse> => new Promise((resolve, reject) => {
        httpRequest<IParametersResponse>(
            "DELETE",
            `${parameter}`,
            {},
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { addParameters,getParameters, getParameter, updateParameter ,deleteParameter};
};

export  {ParameterService};