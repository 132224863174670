import Joi from "joi";
import { required,valid_string} from "../../shared";

export const parameterValidation = Joi.object({
    name: Joi.string()
        .min(3)
        .max(40)
        .trim()
        .required()
        .label("Name")
        .messages({
            "string.empty": required
        }),
    type: Joi.string()
        .valid("technical" ,"non-technical")
        .required()
        .label("Type")
        .messages({
            "any.only": valid_string
        }),
    fieldType: Joi.string()
        .valid("input" , "checkbox")
        .required()
        .label("Field Type")
        .messages({
            "any.only": valid_string
        }),
});
