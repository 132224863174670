import { FC } from "react";

interface props {
    label: string;
    required?: boolean;
}

const CustomLabel: FC<props> = ({ required, label }) =>
(
    <span>{label}{required && <span style={{ color: "#d32f2f" }}>*</span>}</span>
);

CustomLabel.defaultProps = {
    required: false
};

export default CustomLabel;