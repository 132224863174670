import { ITypesOfContentsResponse ,ITypesOfContentResponse} from "../../../interfaces/content/types-of-content";
import { typesOfContent, typesOfContentList } from "../../endpoints";
import HttpService from "../../http";

const TypesOfContentService = () => {
    const {httpRequest} = HttpService();

    const getContentTypesOfContents = async (search:object):Promise<ITypesOfContentsResponse> => 
    new Promise((resolve,reject) => {
        httpRequest<ITypesOfContentsResponse>(
            "GET",
            `${typesOfContentList}`,
            {},
            search
            )
            .then(resolve)
            .catch(reject);
    });

    const addContentTypesOfContent = async (payload : object) :Promise<ITypesOfContentResponse>=> new Promise((resolve,reject) => {
        httpRequest<ITypesOfContentResponse>(
        "POST",
        `${typesOfContent}`,
        payload )
        .then(resolve)
        .catch(reject);
    });

    

    const getContentTypesOfContent = async (query:object) : Promise<ITypesOfContentResponse> =>  new Promise((resolve,reject) => {
        httpRequest<ITypesOfContentResponse>(
            "GET",
            `${typesOfContent}`,
            {},
            query
        )
        .then(resolve)
        .catch(reject);
    });

    const getContentTypesOfContentPartial = async (query:object) : Promise<ITypesOfContentResponse> =>  new Promise((resolve,reject) => {
      httpRequest<ITypesOfContentResponse>(
          "GET",
          `${typesOfContent}/partial`,
          {},
          query
      )
      .then(resolve)
      .catch(reject);
  });

    const deleteContentTypesOfContent = async(body:object):Promise<ITypesOfContentResponse> =>
    new Promise((resolve,reject) => {
      httpRequest<ITypesOfContentResponse>("DELETE",`${typesOfContent}`,body)
      .then(resolve)
      .catch(reject);
    });
      
    const updateContentTypesOfContent = async(payload:object):Promise<ITypesOfContentResponse> =>
      new Promise((resolve,reject) => {
        httpRequest<ITypesOfContentResponse>("PUT",`${typesOfContent}`,payload)
        .then(resolve)
        .catch(reject);
      });

    return {getContentTypesOfContents ,addContentTypesOfContent,getContentTypesOfContentPartial,getContentTypesOfContent,deleteContentTypesOfContent,updateContentTypesOfContent};
};

export default TypesOfContentService;