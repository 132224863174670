
import { blogComment, blogCommentList, blogCommentReply } from "../../endpoints";
import HttpService from "../../http";
import { IBlogCommentReplyResponse, IBlogCommentResponse, IBlogCommentResponses } from "../../../interfaces/content-marketing/blog/comment";
const blogCommentService = () => {

    const { httpRequest } = HttpService();
    const getBlogCommentList = async (search: object): Promise<IBlogCommentResponses> =>
        new Promise((resolve, reject) => {
            httpRequest<IBlogCommentResponses>(
                "GET",
                `${blogCommentList}`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });


    const addBlogComment = async (payload: object): Promise<IBlogCommentResponse> => new Promise((resolve, reject) => {
        httpRequest<IBlogCommentResponse>(
            "POST",
            `${blogComment}`,
            payload)
            .then(resolve)
            .catch(reject);
    });

    const addBlogCommentReply = async (payload: object): Promise<IBlogCommentReplyResponse> => new Promise((resolve, reject) => {
        httpRequest<IBlogCommentReplyResponse>(
            "POST",
            `${blogCommentReply}`,
            payload)
            .then(resolve)
            .catch(reject);
    });

    return { addBlogComment, getBlogCommentList, addBlogCommentReply };

};

export { blogCommentService };