import { Box, IconButton, Tooltip } from "@mui/material";
import { ChangeEvent } from "react";
import { useState, useEffect } from "react";
import { IPagination } from "../../../interfaces/";
import { broadcastService } from "../../../services";
import { useQuery } from "@tanstack/react-query";
import { IBroadcastRow, IBroadcast, IBroadcastState } from "../../../interfaces";
import { capitalize, createIndex, formatDateTime } from "../../../utilities/helper";
import { Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Header from "../../../components/header";
import useDebounce from "../../../hooks/useDebounce";
import CustomTable from "../../../components/mui/table";
import PieChartIcon from "@mui/icons-material/PieChart";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import useResource from "../../../hooks/useResource";


const Broadcast = () => {
  let rows: IBroadcastRow[] = [];
  const { getbroadcasts } = broadcastService();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState<string>("");
  const { id } = useParams();
  const { resourceAllocate } = useResource();
  const [state, setState] = useState<IBroadcastState>({
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      search: ""
    },
    keepFetching: 0
  });

  const getbroadcast = useQuery({
    queryKey: ["allBroadcasts", state.pagination.page, state.filters, id],
    queryFn: () => getbroadcasts({
      _broadcastGroup: id,
      pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
    })
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (getbroadcast.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: getbroadcast.data.meta.page,
          totalPages: getbroadcast.data.meta.totalPages,
          totalRecords: getbroadcast.data.meta.totalRecords
        }
      }));
    }
  }, [getbroadcast.data?.meta]);

  useEffect(() => {

    const status = getbroadcast.data?.data.map(broadCast => broadCast.status);
    if (status && status.includes("QUEUED")) {
      setTimeout(() => {
        getbroadcast.refetch();
        setState(prev => ({ ...prev, keepFetching: prev.keepFetching + 1 }));
      }, 1000 * 5);
    }
  }, [getbroadcast.data, state.keepFetching]);

  useEffect(() => {
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search
      }
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "broadcast_name",
      label: "Broadcast Name"
    },
    {
      id: "broadcast_type",
      label: "Broadcast type"
    },
    {
      id: "broadcast_leadType",
      label: "Lead Type"
    },
    {
      id: "broadcast_delivered",
      label: "Delivered"
    },
    {
      id: "broadcast_failure",
      label: "Failure"
    },
    {
      id: "broadcast_createdAt",
      label: "Created At"
    },
    {
      id: "actions",
      label: "Actions"
    }
  ];

  const createRow = (index: number, broadcast: IBroadcast, pagination: IPagination) => {
    const actions = <>
      <Tooltip title="Show Events">
        <span>
          <IconButton onClick={() => navigate(`/broadcast-group/${id}/broadcast/events/${broadcast._id}?eventType=${broadcast?.type?.toLowerCase()}`)} size="small" color="primary">
            <PieChartIcon color="primary" />
          </IconButton>
        </span>
      </Tooltip>
    </>;

    return {
      id: createIndex(pagination, index),
      broadcast_name: (capitalize(broadcast?.broadcastName || "") || "N/A"),
      broadcast_type: (capitalize(broadcast?.type || "") || "N/A"),
      broadcast_leadType: capitalize(broadcast?.typeOfLead || ""),
      broadcast_delivered: (broadcast?.sent || 0),
      broadcast_failure: (broadcast?.failed || 0),
      broadcast_createdAt: formatDateTime(broadcast?.createdAt),
      actions
    };
  };

  if (getbroadcast.data?.data.length) {
    rows = getbroadcast.data?.data.map((broadcast, i) => createRow(i, broadcast, state.pagination));
  }

  return (
    <>
      {/* Add Data  */}
      <Box display={"flex"} alignItems="center">
        <Tooltip title="Back">
          <IconButton className="back mr-1" onClick={() => navigate("/broadcast-group")}>
            <ArrowBackOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Header
          className="w-100"
          searchPlaceholder="Search by broadcast template"
          onSearch={onSearch}
          btnText="BROADCAST TEMPLATE"
          onBtnClick={resourceAllocate("broadcast.write") ? () => navigate("new") : undefined}
        />
      </Box>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 191.3px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>
      <Outlet context={{ reFetch: getbroadcast.refetch }} />
    </>
  );
};
export default Broadcast;

