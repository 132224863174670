import "../style.scss";
import { FC, useState } from "react";
import { Box, Button, Grid, MenuItem, SelectChangeEvent, TextField } from "@mui/material";
import GetActions from "../../../../components/get-actions";
import { IVendorEdit } from "../../../../interfaces/vendor";
import useSnackbar from "../../../../hooks/useSnackbar";
import { Controller, useForm } from "react-hook-form";
import { IErrorResponse } from "../../../../interfaces/shared/response";
import { IColumn } from "../../../../interfaces/shared";
import { capitalize } from "../../../../utilities/helper";
import CustomTable from "../../../../components/mui/table";
import WarningDialog from "../../../../components/mui/warning-dialog";
import { useQuery } from "@tanstack/react-query";
import Select from "../../../../components/mui/select";
import CustomLabel from "../../../../components/mui/custom-label";
import { VendorPaymentModeService } from "../../../../services/vendor/payment-mode";
import { IVendorPaymentMode, IVendorPaymentModeField, IVendorPaymentModeRow, IVendorPaymentModeState } from "../../../../interfaces/vendor/payment-mode";
import MobileNumber from "../../../../components/mui/mobile-number";
import ManageVendorPaymentModeDetail from "./manage";
import { joiResolver } from "@hookform/resolvers/joi";
import { vendorModeValidation } from "../../../../validations/vendor/manage-vendor/payment-modes";

interface props {
  vendor: IVendorEdit | undefined;
  isDisable?: boolean;
  activeAction: boolean;
  setIsDisable?: (e: boolean) => void;
  onClose?: () => void;
  reFetch?: () => void;
  setActiveAction: (e: boolean) => void;
}

const PaymentDetails: FC<props> = ({ vendor, activeAction, setActiveAction}) => {
    const [modeId, setModeId] = useState("");
    const { snackbar } = useSnackbar();
    const { addPaymentMode, deletePaymentMode, getPaymentModes } = VendorPaymentModeService();
    const [state, setState] = useState<IVendorPaymentModeState>({
      list: [],
      deleteWarning: false,
      managePaymentMode: {
        isOpen: false,
        paymentMode: {
            _vendor: "",
            paymentMode: "",
            name: "",
            mobileNumber: {
                country: "",
                dialCode: "",
                iso2: "",
                number: "",
            },
            upiId: "",
            bankName: "",
            accountNumber: "",
            ifsc: ""
        }
      },
      _paymentModeDetail: "",
      deleteIndex: -1
    });
  
    const { control, handleSubmit, watch, reset, formState: { errors } } = useForm<IVendorPaymentMode>({
      resolver: joiResolver(vendorModeValidation),
      defaultValues: {
            paymentMode: "",
            name: "",
            upiId: "",
      }
    });

    const paymentModes = useQuery({
      queryKey: ["allModes"],
      queryFn: () =>
        getPaymentModes({
          _vendor: vendor?._id
        }),
      enabled: !!vendor?._id
    });

    let rows: IVendorPaymentModeRow[] = []; 
    const handleDelete = (_id?: string) => setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _paymentModeDetail: _id ? _id : ""
    }));
  
    const onDelete = async () => {
      try {
        const id = {
          _id: state._paymentModeDetail,
        };
        const deletedMode = await deletePaymentMode(id);
        snackbar(deletedMode.message, "info");
        handleDelete();
        paymentModes.refetch();
      } catch (error) {
        const err = error as IErrorResponse;
        snackbar(err.data.message, "warning");
        handleDelete();
        console.log({ "Error in delete mode": error });
      }
    };
  
    const handleEdit = (payment?: IVendorPaymentMode) => setState(prevState => {
      if(payment?._id){
        setModeId(payment._id);
      }
      const newState = {
        ...prevState,
        managePaymentMode: {
          ...prevState.managePaymentMode,
          isOpen: !prevState.managePaymentMode.isOpen,
        },
        _contractDetail: payment ? payment._id : "",
      } as IVendorPaymentModeState;
  
      return newState;
    });

    const onSubmit = async (data: IVendorPaymentMode) => {
      try {
        const payload = {
            ...data,
            mobileNumber: {
              ...stateNo.phoneNumber,
              number: data.phoneNumber,
          },
        };
        delete payload.phoneNumber;
        if(watch("paymentMode") === "CASH"){
          delete payload.upiId;
          delete payload.accountNumber;
          delete payload.bankName;
          delete payload.ifsc;
        }else if(watch("paymentMode") === "UPI"){
          delete payload.ifsc;
          delete payload.accountNumber;
        }else{
          delete payload.upiId;
        }
        const paymentDetail = await addPaymentMode({
          ...payload,
          _vendor: vendor?._id,
        });
        setActiveAction(false);
        reset();
        snackbar(paymentDetail?.message, "info");
        paymentModes.refetch();
      } catch (error) {
        const err = error as IErrorResponse;
        snackbar(err?.data?.message, "warning");
        console.log("error in mode detail", error);
      }
    };

    const onNumberChange = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target;
        const data = value.split(":");

        setStateNo(prev => ({
            ...prev,
            [name]: {
                country: data[0],
                iso2: data[1],
                dialCode: data[2]
            }
        }));
    };

    const [stateNo, setStateNo] = useState({
        phoneNumber: {
            country: "INDIA",
            dialCode: "+91",
            iso2: "IN"
        },
    });
  
    const fields: IVendorPaymentModeField[] = [
      {
        type: "input",
        name: "name",
        label: "Name",
        placeholder: "Type name here",
        required: true
      },
      {
        type: "select",
        name: "paymentMode",
        label: "Payment Mode",
        required: true,
        children: [
            <MenuItem key="ACCOUNT" value="ACCOUNT" >Account</MenuItem>,
            <MenuItem key="UPI" value="UPI" >UPI</MenuItem>,
            <MenuItem key="CASH" value="CASH" >Cash</MenuItem>
        ]
      },
      {
        type: "input",
        name: "upiId",
        label: "UPI ID",
        placeholder: "Type upi id here",
        required: true
      },
      {
        type: "input",
        name: "bankName",
        label: "Bank Name",
        placeholder: "Type bank name here",
        required: true
      },
      {
        type: "input",
        name: "ifsc",
        label: "IFSC",
        placeholder: "Type ifsc here",
        required: true
      },
      {
        type: "input",
        name: "accountNumber",
        label: "Account Number",
        placeholder: "Type account number here",
        required: true
      },
      {
        type: "mobile-number",
        name: "phoneNumber",
        label: "Mobile Number",
        required: true
      },
    ];

    const filteredFields = fields.filter((field) => {
      if (watch("paymentMode") === "ACCOUNT") {
        return !["upiId"].includes(field.name);
      }else if(watch("paymentMode") === "UPI"){
        return !["ifsc", "accountNumber"].includes(field.name);
      }else if(watch("paymentMode") === "CASH"){
        return !["ifsc", "bankName", "accountNumber", "upiId"].includes(field.name);
      }else{
        return ["name", "paymentMode", "phoneNumber"].includes(field.name);
      }
    });
  
    const columns: IColumn[] = [
      {
        id: "id",
        label: "S No."
      },
      {
        id: "name",
        label: "Name",
      },
      {
        id: "paymentMode",
        label: "Payment Mode"
      },
      {
        id: "type",
        label: "A/C No. / UPI ID"
      },
      {
        id: "bankName",
        label: "Bank Name"
      },
      {
        id: "ifsc",
        label: "IFSC Code"
      },
      {
        id: "mobileNumber",
        label: "Mobile Number"
      },
      {
        id: "action",
        label: "Actions"
      },
    ];
  
    const createRow = (index: number, paymentModeDetail: IVendorPaymentMode) => {
      const action = <GetActions
        icons={[
          { name: "Edit", method: () => handleEdit(paymentModeDetail) },
          { name: "Delete", method: () => handleDelete(paymentModeDetail._id) },
        ]}
      />;
  
      return {
        id: index + 1,
        name: capitalize(paymentModeDetail.name),
        paymentMode: paymentModeDetail.paymentMode,
        type: paymentModeDetail?.accountNumber ? paymentModeDetail.accountNumber : (paymentModeDetail?.upiId ? paymentModeDetail.upiId : "-") ,
        bankName: paymentModeDetail?.bankName ? capitalize(paymentModeDetail.bankName ): "-",
        ifsc: paymentModeDetail?.ifsc ? paymentModeDetail.ifsc : "-",
        mobileNumber: paymentModeDetail && paymentModeDetail.mobileNumber && paymentModeDetail?.mobileNumber.dialCode + " " + paymentModeDetail?.mobileNumber.number,
        action
      };
    };

    if (paymentModes?.data?.data?.length) {
      rows = paymentModes?.data?.data.map((payment, i) => createRow(i, payment));
    }

    return (
      <Box paddingTop="10px">
        <Box height="56vh" overflow="auto" paddingTop="10px">
  
          {/* Add Data  */}
          {activeAction && 
            <Box marginBottom="20px">
              <form onSubmit={handleSubmit(onSubmit)} onKeyDown={e => e.key === "Enter" && e.preventDefault()}>
                <Grid container spacing={4}>
                  {
                    filteredFields.map(field => {
                      if (field.type === "input") {
                        return (<Grid key={field.label} item xs={4}>
                          <Controller
                            control={control}
                            name={field.name}
                            render={(prop) => <TextField
                              label={<CustomLabel label={field.label} required={field?.required} />}
                              className="disable-text"
                              disabled={field?.disabled}
                              variant={"outlined"}
                              size={"small"}
                              type={field.name === "accountNumber" ? "number" : "string"}
                              placeholder={field.placeholder}
                              error={!!errors[field.name]}
                              helperText={errors[field.name]?.message}
                              {...prop.field}
                            />}
                          />
                        </Grid>
                        );
                      } else if (field.type === "mobile-number") {
                        const numberState = "phoneNumber";
                        return <Grid key={field.label} item xs={4}>
                            <Controller
                                control={control}
                                name={field.name}
                                render={(prop) => <MobileNumber
                                    key={field.label}
                                    className="disable-text"
                                    NumberFieldLabel={<CustomLabel label={field.label} required={field?.required} />}
                                    dialCodeValue={`${stateNo[numberState].country}:${stateNo[numberState].iso2}:${stateNo[numberState].dialCode}`}
                                    dialCodeName={field.name}
                                    onChange={onNumberChange}
                                    error={!!errors[field.name]}
                                    helperText={errors[field.name]?.message}
                                    other={prop.field}
                                    disabled={field?.disabled}
                                />}
                            />
                        </Grid>;
                    }else {
                        return (<Grid key={field.label} item xs={4}>
                            <Select
                                control={control}
                                name={field.name}
                                label={<CustomLabel label={field.label} required={field?.required} />}
                                size="small"
                                variant="outlined"
                                error={!!errors[field.name]}
                                helperText={errors[field.name]?.message}
                                disabled={field?.disabled}
                            >
                                {field.children}
                            </Select>
                        </Grid>
                        );
                    }
                    })
                  }
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      type="submit"
                    >
                      Add Payment Mode
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          }
  
          {/* Show Data  */}
          <CustomTable
            columns={columns}
            rows={rows}
            height={activeAction ? "calc(100% - 145px)" : "calc(100% - 13px)"}
            width="calc(100% - 2px)"
          />
  
          {/* Delete Data  */}
          <WarningDialog
            isOpen={state.deleteWarning}
            onClose={() => handleDelete()}
            onConfirm={onDelete}
            title="Delete Payment Mode"
            description="Are you sure you want to delete this payment mode?"
          />
  
          {/* Manage Data  */}
          <ManageVendorPaymentModeDetail
            paymentMode={modeId}
            isOpen={state.managePaymentMode.isOpen}
            onClose={handleEdit}
            refetch={paymentModes.refetch}
          />
        </Box>
      </Box>
    );
};

export default PaymentDetails;