import { ChangeEvent, useState } from "react";
import ImportFile from "../../../../components/mui/import-candidate";
import useCpdValidateImport from "./useValidate";
import { useNavigate, useOutletContext } from "react-router-dom";
import * as XLSX from "xlsx";
import useSnackbar from "../../../../hooks/useSnackbar";
import { ICollege, IErrorResponse } from "../../../../interfaces";
import ImportFileError from "../../../../components/mui/import-candidate/erros";
import { cpdDriveService } from "../../../../services";

interface IState {
    fileName: string;
    data: ICollege[];
    errorMessage: ICollege[];
    showError: boolean;
}

interface IValidate {
    data: ICollege[];
    errorMessage: ICollege[]
}

interface outletProps {
    reFetch: () => void
}

const ImportCpd = () => {
    const navigate = useNavigate();
    const outlet = useOutletContext<outletProps>();
    const { addCpdDrives, getCpdSample } = cpdDriveService();
    const { snackbar } = useSnackbar();
    const { validateCpdUpload } = useCpdValidateImport();
    const [state, setState] = useState<IState>({
        fileName: "",
        data: [],
        showError: false,
        errorMessage: []
    });

    const closeImport = () => {
        navigate(-1);
    };

    const getDownloadInfo = async () => {
        try {
          const response = await getCpdSample({}); 
          const byteArray = new Uint8Array(response?.data?.data);
    
          const blob = new Blob([byteArray], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
    
          const file = new File([blob], "temp.xlsx", {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
    
          const link = document.createElement("a");
          document.body.appendChild(link);
    
          link.href = window.URL.createObjectURL(file);
          link.download = "temp.xlsx";
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error("Error in downloading sample", error);
          snackbar("Error in downloading sample", "error");
        }
      };

    const onUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const formData = new FormData();
        const fileType = [
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
        ];

        const selectedFile = event.target.files ? event.target.files[0] : false;

        if (selectedFile && fileType.includes(selectedFile.type)) {
            formData.append("file", selectedFile);
            const reader = new FileReader();
            reader.readAsArrayBuffer(selectedFile);
            reader.onload = async (e) => {
                if (e.target) {
                    const data = e.target.result;
                    const readedData = XLSX.read(data, { type: "binary" });
                    const wsname = readedData.SheetNames[0];
                    const ws = readedData.Sheets[wsname];
                    let dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
                    dataParse = dataParse?.slice(1);

                    const collegeData = await validateCpdUpload(dataParse) as IValidate;

                    setState(prev => ({
                        ...prev,
                        ...collegeData,
                        fileName: selectedFile.name
                    }));
                }

            };
        } else {
            snackbar("Upload excel or xls file", "warning");
        }
    };

    const onSubmit = async () => {
        try {
            if (state.data.length) {
                const payload = state.data;
                const candidates = await addCpdDrives(payload);
                outlet.reFetch();
                snackbar(candidates.message, "info");
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
        }


        if (state.errorMessage.length) {
            setState(prev => ({
                ...prev,
                showError: true
            }));
        } else {
            closeErrorDialog();
        }
    };

    const closeErrorDialog = () => {
        setState(prev => ({
            ...prev,
            fileName: "",
            data: [],
            showError: false,
            errorMessage: []
        }));

        navigate(-1);
    };

    return (
        <>
            <ImportFile
                isOpen={true}
                onClose={closeImport}
                onDownload={getDownloadInfo}
                onUpload={onUpload}
                onsubmit={onSubmit}
                fileName={state.fileName}
                title="Before you start upload, please make sure:"
                content={[
                    "Download the sample excel file.",
                    "From College section, copy the College id and paste it accordingly.",
                    "Read all the fields in the 1st row and do not change the text.",
                    "Start uploading the data from 2nd row and onwards.",
                    "In each row, there will be data for one college.",
                    "All variants are mapped to their field by field title.",
                    "Mandatory field are College name, Status.",
                    "Once sheet is prepared, upload it.",
                    "Now sit back and relax!",
                ]}
            />

            <ImportFileError
                title="Excel file Errors"
                isOpen={state.showError}
                onClose={closeErrorDialog}
                errorMessage={state.errorMessage}
            />
        </>
    );
};

export default ImportCpd;