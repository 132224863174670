import { Typography } from "@mui/material";
import { FC } from "react";
import "./index.scss";
import draftIcon from "../../../../assets/images/draft.svg";
import publishedIcon from "../../../../assets/images/published.svg";
import scheduledIcon from "../../../../assets/images/scheduled.svg";
import totalBlogsIcon from "../../../../assets/images/total-blogs.svg";
import underReviewIcon from "../../../../assets/images/under-review.svg"
    ;


interface props {
    name: string;
    count: number;
}

const imageMapping: Record<string, string> = {
    Draft: draftIcon,
    Published: publishedIcon,
    Scheduled: scheduledIcon,
    "Total Blogs": totalBlogsIcon,
    "Under Review": underReviewIcon,
    "Reassign Blogs" : totalBlogsIcon
};
const CmsTag: FC<props> = ({ name, count }) => (
    <div
        className={"count-box"}
    >
        <div className="cmsCard">
            <Typography variant="body1" className="cards-title">
                {name}
            </Typography>
            <img src={imageMapping[name]} alt="" className="comment-icon" />
        </div>

        <Typography className="analytics" variant="h4">
            {count || 0}
        </Typography>
    </div>
);

export default CmsTag;