import "./style.scss";
import { Box, TextField } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";

const initMap = (lat = -33.8688, lng = 151.2195) => {
    const map = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
            center: { lat, lng },
            zoom: 13,
            mapTypeId: "roadmap",
        }
    );

    return map;
};

// eslint-disable-next-line
const searchLocation = (map: any, setState: any) => {
    // eslint-disable-next-line
    let location: any;
    let markers: google.maps.Marker[] = [];

    const input = document.getElementById("location") as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);

    searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces() || [];

        if (places.length === 0) {
            return;
        }

        // Clear out the old markers.
        markers.forEach((marker) => {
            marker.setMap(null);
        });
        markers = [];

        // For each place, get the icon, name and location.
        const bounds = new google.maps.LatLngBounds();

        // eslint-disable-next-line
        places.forEach((place: any) => {
            if (!place.geometry || !place.geometry.location) {
                console.log("Returned place contains no geometry");
                return;
            }
            location = {
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
                place,
                hometown: place?.name,
                homeLocation: place?.formatted_address,
                // eslint-disable-next-line
                city: place?.address_components?.find((x: any) =>
                    x.types.includes("locality")
                )?.long_name,
                // eslint-disable-next-line
                line2: place?.address_components?.find((x: any) =>
                    x.types.includes("locality")
                )?.long_name,
                // eslint-disable-next-line
                country: place?.address_components?.find((x: any) =>
                    x.types.includes("country")
                )?.long_name,
                // eslint-disable-next-line
                zip: place?.address_components?.find((x: any) =>
                    x.types.includes("postal_code")
                )?.long_name,
                // eslint-disable-next-line
                state: place?.address_components?.find((x: any) =>
                    x.types.includes("administrative_area_level_1")
                )?.long_name
            };


            setState(location);

            const icon = {
                url: place.icon as string,
                size: new google.maps.Size(71, 71),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(17, 34),
                scaledSize: new google.maps.Size(25, 25),
            };

            // Create a marker for each place.
            markers.push(
                new google.maps.Marker({
                    map,
                    icon,
                    title: place.name,
                    position: place.geometry.location,
                })
            );
            if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }
        });
        map.fitBounds(bounds);
    });

    return location;
};

interface props {
    name: string
    label: string;
    disabled: boolean;
    placeholder?: string;
    className?: string;
    error?: boolean;
    helperText?: string;
    // eslint-disable-next-line
    locationChange: (prev: any, name: string) => void,
    // eslint-disable-next-line
    value?: string | number,
    // eslint-disable-next-line
    control: any;
    latitude?: number;
    longitude?: number;
    showMap?: boolean;
}

const GoogleMap: FC<props> = ({
    name,
    label,
    disabled,
    placeholder,
    className,
    error,
    helperText,
    control,
    value,
    locationChange,
    latitude,
    longitude,
    showMap
}) => {
    const location = useRef<HTMLHeadingElement>(null);
    const [state, setState] = useState({
        latitude,
        longitude,
        place: {}
    });

    useEffect(() => {
        const map = initMap(state.latitude, state.longitude);
        searchLocation(map, setState);
        locationChange(state, name);
    }, [state, state.latitude, state.longitude, control]);

    return (
        <>
            <Controller
                control={control}
                name={name}
                render={(prop) => <TextField
                    {...prop.field}
                    ref={location}
                    id='location'
                    label={label}
                    disabled={disabled}
                    error={error}
                    helperText={helperText}
                    style={{ display: disabled ? "none" : "block" }}
                    size={disabled ? "medium" : "small"}
                    className={`controls ${className}`}
                    fullWidth
                    type="text"
                    placeholder={placeholder}
                />}
            />

            <TextField
                name={name}
                className="controls"
                style={{ display: !disabled ? "none" : "inherit" }}
                variant={disabled ? "standard" : "outlined"}
                size={disabled ? "medium" : "small"}
                fullWidth
                disabled
                label={label}
                value={value}
                placeholder={placeholder}
            />

            <Box display={showMap ? "block" : "none"} height="500px" width="100%" marginTop="40px" id="map" />
        </>
    );
};

GoogleMap.defaultProps = {
    latitude: 0,
    longitude: 0,
    showMap: false
};

export default GoogleMap;
