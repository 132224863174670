import { IBusinessUnitResponse, IBusinessUnitsResponse } from "../../../interfaces/configuration/business-unit";
import { businessUnit } from "../../endpoints";
import HttpService from "../../http";

const BusinessUnitsService = () => {
    const { httpRequest } = HttpService();
    const addBusinessUnit = async (payload: object): Promise<IBusinessUnitsResponse> => new Promise((resolve, reject) => {
        httpRequest<IBusinessUnitsResponse>(
            "POST",
            `${businessUnit}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getPartialBusinessUnits = async (): Promise<IBusinessUnitsResponse> => new Promise((resolve, reject) => {
        httpRequest<IBusinessUnitsResponse>(
            "GET",
            `${businessUnit}/list/partial`,
        )
            .then(resolve)
            .catch(reject);
    });

    const getBusinessUnits = async (search: object): Promise<IBusinessUnitsResponse> => new Promise((resolve, reject) => {
        httpRequest<IBusinessUnitsResponse>(
            "GET",
            `${businessUnit}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getBusinessUnit = async (query: object): Promise<IBusinessUnitResponse> => new Promise((resolve, reject) => {
        httpRequest<IBusinessUnitResponse>(
            "GET",
            `${businessUnit}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateBusinessUnit = async (payload: object): Promise<IBusinessUnitResponse> => new Promise((resolve, reject) => {
        httpRequest<IBusinessUnitResponse>(
            "PUT",
            `${businessUnit}`,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteBusinessUnit = async (payload: object): Promise<IBusinessUnitsResponse> => new Promise((resolve, reject) => {
        httpRequest<IBusinessUnitsResponse>(
            "DELETE",
            `${businessUnit}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });
    return { getBusinessUnits, getPartialBusinessUnits, addBusinessUnit, getBusinessUnit, updateBusinessUnit, deleteBusinessUnit };
};

export { BusinessUnitsService };
