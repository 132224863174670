import "./style.scss";
import { FC, ReactNode } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Typography, Box } from "@mui/material";
import React from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowFrontRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    minHeight: "50vh",
    maxHeight: "50vh",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: ReactNode;
  onClose: () => void;
  onInfoClick: (event: React.MouseEvent<HTMLElement>) => void;
  bottomLeftField?: ReactNode;
  bottomRightField?: ReactNode;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onInfoClick, bottomLeftField, bottomRightField } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Box>{children}</Box>
        <Box display="flex" alignItems="center">
          <IconButton
            aria-label="info"
            onClick={onInfoClick}
            sx={{
              backgroundColor: (theme) => theme.palette.grey[200],
              "&:hover": {
                backgroundColor: (theme) => theme.palette.grey[300],
              },
            }}
          >
            <MoreHorizIcon />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" width="100%" mt={2}>
        {bottomLeftField && (
          <Typography variant="body2" color="textSecondary">
            {bottomLeftField}
          </Typography>
        )}
        {bottomRightField && (
          <Typography variant="body2" color="textSecondary">
            {bottomRightField}
          </Typography>
        )}
      </Box>
    </DialogTitle>
  );
}

interface props {
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  title: string;
  secondaryTitle?: string;
  isOpen: boolean;
  disabled?: boolean;
  confirmText?: string;
  cancelText?: string;
  showCancel?: boolean;
  showSave?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  onSubmit?: () => void;
  children: ReactNode;
  bottomLeftField?: ReactNode;
  bottomRightField?: ReactNode;
  infoMenuItems: { icon: JSX.Element; label: string; onClick: () => void }[];
  onClose: () => void;
  pageNext?: () => void;
  pagePrevious?: () => void;
}

const CustomDialogTest: FC<props> = ({
  size,
  title,
  secondaryTitle,
  isOpen,
  disabled,
  confirmText,
  cancelText,
  showCancel = true,
  showSave = true,
  onCancel = () => {
    /* Default empty function */
  },
  onSave = () => {
    /* Default empty function */
  },
  onSubmit,
  children,
  bottomLeftField,
  bottomRightField,
  infoMenuItems,
  pageNext,
  pagePrevious,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleInfoClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleInfoClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <BootstrapDialog
        maxWidth={size}
        sx={{ minHeight: "55vh" }}
        onClose={onCancel}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        scroll="paper"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={onCancel}
          onInfoClick={handleInfoClick}
          bottomLeftField={bottomLeftField}
          bottomRightField={bottomRightField}
        >
          {title}
          {secondaryTitle && (
            <Typography variant="body2" color="textSecondary">
              {secondaryTitle}
            </Typography>
          )}
        </BootstrapDialogTitle>

        <form id="dialog-form" onSubmit={onSubmit}>
          <DialogContent>{children}</DialogContent>
          {!disabled && (
            <DialogActions id="dialog-action">
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box display="flex" justifyContent="flex-end" flex="1">
                  {pagePrevious && (
                    <IconButton onClick={pagePrevious}>
                      <ArrowBackRoundedIcon />
                    </IconButton>
                  )}
                  {pageNext && (
                    <IconButton onClick={pageNext}>
                      <ArrowFrontRoundedIcon />
                    </IconButton>
                  )}
                </Box>
                <Box display="flex" justifyContent="flex-end" flex="1">
                  {showCancel && (
                    <Button variant="outlined" size="medium" onClick={onCancel}>
                      {cancelText ? cancelText : "Cancel"}
                    </Button>
                  )}
                  {showSave && (
                    <Button
                      size="medium"
                      type="submit"
                      autoFocus
                      onClick={onSave}
                    >
                      {confirmText ? confirmText : "Save"}
                    </Button>
                  )}
                </Box>
              </Box>
            </DialogActions>
          )}
        </form>
      </BootstrapDialog>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleInfoClose}
      >
        {infoMenuItems.map((item, index) => (
          <MenuItem key={index} onClick={item.onClick}>
            <IconButton>{item.icon}</IconButton>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
CustomDialogTest.defaultProps = {
  size: "md",
  disabled: false,
  secondaryTitle: "",
  showCancel: true,
  showSave: true,
  cancelText: "Close",
  confirmText: "Save",
  bottomLeftField: null,
  bottomRightField: null,
  infoMenuItems: [],
};
export default CustomDialogTest;