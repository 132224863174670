import HttpService from "../../http";
import { IStatussResponse, IStatusResponse } from "../../../interfaces/configuration/status";
import { status } from "../../endpoints";

const StatusService = () => {
    const { httpRequest } = HttpService();
    const addStatus = async (payload: object): Promise<IStatussResponse> => new Promise((resolve, reject) => {
        httpRequest<IStatussResponse>(
            "POST",
            `${status}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getStatuss = async (search?: object): Promise<IStatussResponse> => new Promise((resolve, reject) => {
        httpRequest<IStatussResponse>(
            "GET",
            `${status}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getStatus = async (query: object): Promise<IStatusResponse> => new Promise((resolve, reject) => {
        httpRequest<IStatusResponse>(
            "GET",
            `${status}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateStatus = async ( payload: object): Promise<IStatusResponse> => new Promise((resolve, reject) => {
        httpRequest<IStatusResponse>(
            "PUT",
            status,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const updateStatusPosition = async (payload: object): Promise<IStatusResponse> => new Promise((resolve, reject) => {
        httpRequest<IStatusResponse>(
            "PUT",
            `${status}/position`,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteStatus = async (query: object): Promise<IStatussResponse> => new Promise((resolve, reject) => {
        httpRequest<IStatussResponse>(
            "DELETE",
            `${status}`,
            {},
            query,
        )
            .then(resolve)
            .catch(reject);
    });
    return { addStatus, getStatuss, getStatus, updateStatus, updateStatusPosition, deleteStatus };
};

export { StatusService };
