import { LOAD_CPD_DRIVE } from "../../actions";
import { ICpdDrive } from "../../../interfaces";

interface IAction {
    type: "LOAD_CPD_DRIVE",
    payload: ICpdDrive
}

interface IState {
    list: ICpdDrive[]
}

const initialState: IState = {
    list: []
};

const cpdDriveReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case LOAD_CPD_DRIVE: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
};

export { cpdDriveReducer };
