import Joi from "joi";
import { required } from "../../../shared";

export const blogAssignmentValidation = Joi.object({
    writer: Joi.object({
        userId: Joi.string()
            .required()
            .label("Writer")
            .messages({
                "string.empty": required,
            }),
        submissionDate: Joi.string()
            .required()
            .label("Writer submission date")
            .messages({
                "string.empty": required,
            }),
        notes: Joi.string()
            .required()
            .label("Writer notes")
            .optional()
            .allow("")
    }),
    reviewer: Joi.object({
        userId: Joi.string()
            .required()
            .label("Reviewer")
            .messages({
                "string.empty": required,
            }),
        submissionDate: Joi.string()
            .required()
            .label("Reviewer submission date")
            .messages({
                "string.empty": required,
            }),
        notes: Joi.string()
            .required()
            .label("Reviewer notes")
            .optional()
            .allow("")
    }),
    illustrator: Joi.object({
        userId: Joi.string()
            .required()
            .label("Designer")
            .messages({
                "string.empty": required,
            }),
        submissionDate: Joi.string()
            .required()
            .label("Designer submission date")
            .messages({
                "string.empty": required,
            }),
        notes: Joi.string()
            .required()
            .label("Designer notes")
            .optional()
            .allow("")
    }),
    publisher: Joi.object({
        userId: Joi.string()
            .required()
            .label("publisher")
            .messages({
                "string.empty": required,
            }),
        submissionDate: Joi.string()
            .required()
            .label("publisher submission date")
            .messages({
                "string.empty": required,
            }),
        notes: Joi.string()
            .required()
            .label("Publisher notes")
            .optional()
            .allow("")
    }),




});

export const blogBulkAssignmentValidation = Joi.object({
    writer: Joi.object({
        userId: Joi.string()
            .required()
            .label("Writer")
            .messages({
                "string.empty": required,
            }),
        submissionDate: Joi.string()
            .required()
            .label("Writer submission date")
            .messages({
                "string.empty": required,
            })
    }),
    reviewer: Joi.object({
        userId: Joi.string()
            .required()
            .label("Reviewer")
            .messages({
                "string.empty": required,
            }),
        submissionDate: Joi.string()
            .required()
            .label("Reviewer submission date")
            .messages({
                "string.empty": required,
            }),
    }),
    illustrator: Joi.object({
        userId: Joi.string()
            .required()
            .label("Designer")
            .messages({
                "string.empty": required,
            }),
        submissionDate: Joi.string()
            .required()
            .label("Designer submission date")
            .messages({
                "string.empty": required,
            })
    }),
    publisher: Joi.object({
        userId: Joi.string()
            .required()
            .label("publisher")
            .messages({
                "string.empty": required,
            }),
        submissionDate: Joi.string()
            .required()
            .label("publisher submission date")
            .messages({
                "string.empty": required,
            })
    }),
});
