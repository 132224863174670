import "../style.scss";
import { Box, Button, Divider, Grid, TextField } from "@mui/material";
import { ICandidate, ICandidateDetails, IErrorResponse, IFulltimeCareersField, TFulltimeCareersName } from "../../../interfaces";
import { Controller, useForm } from "react-hook-form";
import { FC, useEffect } from "react";
import { fullTimeCareerValidation } from "../../../validations";
import { joiResolver } from "@hookform/resolvers/joi";
import { CandidateService } from "../../../services";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Select from "../../mui/select";
import useSnackbar from "../../../hooks/useSnackbar";
import dayjs from "dayjs";
import { Outlet } from "react-router-dom";
interface props {
  candidate: ICandidateDetails | undefined;
  isDisable: boolean;
  setIsDisable: (e: boolean) => void;
  onClose: () => void;
  reFetch: () => void
}

const FulltimeCareers: FC<props> = ({ candidate, isDisable, setIsDisable, onClose, reFetch }) => {
  const { updateCandidate } = CandidateService();
  const { snackbar } = useSnackbar();
  const { control, handleSubmit, setValue, getValues, trigger, watch, formState: { errors } } = useForm<ICandidate>({
    resolver: joiResolver(fullTimeCareerValidation),
    defaultValues: {
      experience: 0,
      noticePeriod: 0,
      ectc: 0,
      ctc: 0,
      lwd: "",
      comments: "",
      approxExperience: 0
    }
  });

  useEffect(() => {
    if (candidate && Object.keys(candidate).length) {
      const keys: TFulltimeCareersName[] = ["experience", "approxExperience", "noticePeriod", "ctc", "ectc", "lwd", "comments"];
      keys.forEach(key => {
        switch (key) {
          case "lwd": {
            setValue(key, candidate[key]);
            trigger(key);
            break;
          }
          default:
            setValue(key, candidate[key]);
            break;
        }
      });
    }
  }, [isDisable, candidate]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "lwd") {
        trigger("lwd");
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  // eslint-disable-next-line
  const selectDate = (value: any, name: string) => {
    const date = value && dayjs(value).toString() !== "Invalid Date" ? dayjs(value)?.toISOString() : undefined;
    const keyExist = name === "lwd";
    if (keyExist) {
      setValue(name, date);
      trigger(name);
    }
  };

  const onSubmit = async (data: ICandidate) => {
    try {
      const payload = {
        ...data,
        _lead: candidate?._id
      };
      delete payload.approxExperience; 
      const updatedCandidate = await updateCandidate(payload);
      snackbar(updatedCandidate.message, "info");
      onClose();
      reFetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log("error in candidate detail update", error);
    }
  };

  const fields: IFulltimeCareersField[] = [
    {
      label: "Experience(Months)",
      name: "experience",
      type: "input",
    },
    {
      label: "Approx Experience(Months)",
      name: "approxExperience",
      type: "input",
    },
    {
      label: "Notice Period(Days)",
      name: "noticePeriod",
      type: "input",
    },
    {
      label: "CTC(LPA)",
      name: "ctc",
      type: "input",
    },
    {
      label: "ECTC(LPA)",
      name: "ectc",
      type: "input",
    },
    {
      label: "LWD",
      name: "lwd",
      type: "date",
    },
    {
      label: "Comment",
      name: "comments",
      type: "multiline",
    },
  ];

  return (
    <Box paddingTop="10px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          {
            fields.map(field => {
              if (field.type === "input") {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <TextField
                      {...prop.field}
                      label={field.label}
                      className="disable-text"
                      variant={isDisable ? "standard" : "outlined"}
                      disabled={isDisable}
                      inputProps = {{
                        readOnly : field.name === "approxExperience",
                        type: "number",
                        onKeyDown: (e) => {
                          if (
                            e.key === "e" ||
                            e.key === "E" ||
                            e.key === "-" ||
                            e.key === "+"
                          ) {
                            e.preventDefault(); 
                          }
                        }
                      }}
                      size={isDisable ? "medium" : "small"}
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                    />}
                  />
                </Grid>
                );
              }
              else if (field.type === "multiline") {
                return (<Grid key={field.label} item xs={12}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <TextField
                      {...prop.field}
                      label={field.label}
                      className="disable-text"
                      variant={isDisable ? "standard" : "outlined"}
                      disabled={isDisable}
                      size={isDisable ? "medium" : "small"}
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                      multiline
                      minRows={4}
                    />}
                  />
                </Grid>
                );
              } else if (field.type === "date") {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      label={field.label}
                      onChange={(e) => selectDate(e, field.name)}
                      value={dayjs(getValues(field.name) ? getValues(field.name) : null)}
                      slotProps={{
                        textField: {
                          className: "disable-text",
                          disabled: isDisable,
                          variant: isDisable ? "standard" : "outlined",
                          size: isDisable ? "medium" : "small",
                          error: errors[field.name] ? true : false,
                          helperText: errors[field.name]?.message
                        }
                      }}
                      format="LL"
                    />
                  </LocalizationProvider>

                </Grid>
                );
              } else {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <Select
                    control={control}
                    name={field.name}
                    label={field.label}
                    disabled={isDisable}
                    size={isDisable ? "medium" : "small"}
                    variant={isDisable ? "standard" : "outlined"}
                    error={errors[field.name] ? true : false}
                    helperText={errors[field.name]?.message}
                  >
                    {field.children}
                  </Select>
                </Grid>
                );
              }
            })
          }
        </Grid>
        {
          !isDisable &&
          <>
            <Box className="action-box">
              <Divider sx={{ marginBottom: "20px" }} />
              <Button variant="outlined" onClick={() => setIsDisable(true)}>Cancel</Button>
              <Button type="submit">Save</Button>
            </Box>
          </>
        }
      </form>
      <Outlet />
    </Box>
  );
};

export default FulltimeCareers;