import { LegacyRef, useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { ChangeEvent, MouseEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { ReviewService } from "../../../services";
import { IPagination } from "../../../interfaces";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { IReviewRow, IReviewState, IReview } from "../../../interfaces";
import { Box, Chip, FormControl, MenuItem, Select, InputLabel, SelectChangeEvent } from "@mui/material";
import { capitalize, createIndex, checkStatusColor } from "../../../utilities/helper";
import ReviewFilter from "../filter-review";
import Header from "../../../components/header";
import useResource from "../../../hooks/useResource";
import useSnackbar from "../../../hooks/useSnackbar";
import useDebounce from "../../../hooks/useDebounce";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import CustomTypography from "../../../components/mui/max-length-limit";

interface outletProps {
  inputRef?: LegacyRef<HTMLInputElement>;
}

const Reviews = () => {
  const [rows, setRows] = useState<IReviewRow[]>([]);
  const { types } = useParams();
  const { inputRef } = useOutletContext<outletProps>();
  const { getReviews, deleteReview } = ReviewService();
  const { snackbar } = useSnackbar();
  const navigate = useNavigate();
  const { resourceAllocate, isSuperAdmin } = useResource();
  const [search, setSearch] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState<IReviewState>({
    deleteWarning: false,
    review: "",
    lead: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      status: types === "completed" ? ["REVIEW SELECTED", "REVIEW REJECTED"] : ["REVIEW SCHEDULED"],
      reviewStatus: [],
      search: "",
      typeOfLead: [],
      jobId: [],
      assignTo: searchParams.get("assignTo") || "me",
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filterCount: 0,
  });

  const getreview = useQuery({
    queryKey: ["allReviews", state.pagination.page, state.filters],
    queryFn: () => getReviews({
      pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
    })
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      const prevParams: { [index: string]: string } = {};
      searchParams.forEach((value, key) => {
        prevParams[key] = value;
      });

      setSearchParams(prev => ({
        ...prev,
        ...prevParams,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    const status = types === "completed" ? ["REVIEW SELECTED", "REVIEW REJECTED"] : ["REVIEW SCHEDULED"];
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        status
      }
    }));
  }, [types]);

  useEffect(() => {
    if (getreview.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: getreview.data.meta.page,
          totalPages: getreview.data.meta.totalPages,
          totalRecords: getreview.data.meta.totalRecords
        }
      }));
    }
  }, [getreview.data?.meta]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
    const typeOfLead: { key: string, value: string }[] = searchParams.get("typeOfLead") ? JSON.parse(String(searchParams.get("typeOfLead"))) : [];
    const jobId: { key: string, value: string }[] = searchParams.get("jobId") ? JSON.parse(String(searchParams.get("jobId"))) : [];
    const reviewStatus: { key: string, value: string }[] = searchParams.get("reviewStatus") ? JSON.parse(String(searchParams.get("reviewStatus"))) : [];
    filterCount += typeOfLead.length ? 1 : 0;
    filterCount += jobId.length ? 1 : 0;
    filterCount += reviewStatus.length ? 1 : 0;
    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search,
        typeOfLead: typeOfLead.map(typeOfLead => typeOfLead.key),
        jobId: jobId.map(jobId => jobId.key),
        reviewStatus: reviewStatus.map(reviewStatus => reviewStatus.key),
      },
      filterCount
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleDelete = (review = "", lead = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      review,
      lead
    }
    ));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteReview({ _id: state.review, _lead: state.lead });
      snackbar(deleted.message, "info");
      handleDelete();
      getreview.refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const onChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    searchParams.set(name, value);
    setSearchParams(searchParams);
    setState({
      ...state,
      filters: {
        ...state.filters,
        [name]: value,
      },
    });
  };

  const onChangeStatus = (e:SelectChangeEvent<string>) => {
    if(e.target.value === "pending"){
      navigate("/assessments/review/pending");
    }

    if(e.target.value === "completed"){
      navigate("/assessments/review/completed");
    }
  };

  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "candidateName",
      label: "Candidate Name"
    },
    {
      id: "engagementType",
      label: "Engagement Type"
    },
    {
      id: "jobTitle",
      label: "Job Title"
    },
    {
      id: "status",
      label: "Status"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

  const createRow = (index: number, review: IReview, pagination: IPagination) => {

    const pendingAction = <GetActions
      icons={[
        { name: "Edit", method: () => navigate({ pathname: "" + review._id, search: searchParams.toString() }) },
        { name: "Delete", method: () => handleDelete(review._id, review._lead._id) },
      ]}
    />;
    const completeAction = <GetActions
      icons={[
        { name: "Edit", method: () => navigate({ pathname: "" + review._id, search: searchParams.toString() }) },
      ]}
    />;

    return {
      id: createIndex(pagination, index),
      candidateName: (
        <CustomTypography
          limit={30}
          label={capitalize(review._lead?.name)}
          onClick={() => navigate({ pathname: `${review._lead.typeOfLead.toLowerCase()}/manage/${review._lead._id}`, search: searchParams.toString() })}
          color="primary"
        />
      ),
      engagementType: capitalize(review?._lead?.typeOfLead),
      jobTitle: capitalize(review?.jobId?.title),
      status: <Chip variant={"outlined"} color={checkStatusColor(review.status)} label={capitalize(review.status)} />,
      action: types === "completed" ? completeAction : pendingAction,
    };
  };

useEffect(() => {
  if (getreview.data?.data.length) {
    const rows = getreview?.data?.data.map((review, i) => createRow(i, review, state.pagination));
    setRows(rows);
  } else {
    setRows([]);
  }
},[types,getreview?.data?.data]);
  return (
    <>
      {/* Add Data  */}
      <Header
        className='my-2'
        searchPlaceholder="Search by name"
        onSearch={onSearch}
        onFilter={openFilter}
        filterCount={state.filterCount}
        inputRef={inputRef}
      >
        {isSuperAdmin() && <FormControl
          fullWidth
          style={{ width: "120px" }}
          variant="outlined"
          className="ml-2"
          size="small"
        >
          <InputLabel >Assign to</InputLabel>
          <Select
            required
            name="assignTo"
            label="Assign to"
            onChange={onChange}
            value={state.filters.assignTo}
            disabled={resourceAllocate("review.write") ? false : true}
          >
            <MenuItem value="me">Only Me</MenuItem>
            <MenuItem value="everyone">My Team</MenuItem>
          </Select>
        </FormControl>}
        <FormControl
          fullWidth
          style={{ width: "135px" }}
          variant="outlined"
          className="ml-2"
          size="small"
        >
          <InputLabel >Reviews</InputLabel>
          <Select
            required
            name="assignTo"
            label="Assign to"
            onChange={onChangeStatus}
            value={types}
          >
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="completed">Completed</MenuItem>
          </Select>
        </FormControl>

        <ReviewFilter
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnClose={closeFilter}
        />

      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}

        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Review"
        description="Are you sure you want to delete this review?"
      />
      <Outlet context={{ reFetch: getreview.refetch }} />
    </>
  );

};

export default Reviews;