import "../../../../components/shared/filter/style.scss";
import { FC, useEffect, useState } from "react";
import { Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton, ListItemText, Menu, Typography } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MenuItem } from "../../../../components/shared/filter";
import { ICategoryFilter, IProjectData } from "../../../../interfaces";
import { useSelector } from "react-redux";


interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    OnClose: () => void;
}

const BlogCategoryFilter: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const products = useSelector<{ cmsProduct: { list: IProjectData[] } }, IProjectData[]>(
      (state) => state.cmsProduct.list
    ) || [];

    const [state, setState] = useState<ICategoryFilter>({
        selectedMenu: 0,
        productId: [],
      });

    useEffect(() => {
        resetFilter();
    }, []);

    useEffect(() => {
        const productId : { key: string, value: string }[] = searchParams.get("productId") ? JSON.parse(String(searchParams.get("productId"))) : [];

        setState(prevState => ({
            ...prevState,
            productId
        }));
    }, [searchParams]);


    const handleLeftListItem = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setState(prevState => ({
            ...prevState,
            selectedMenu: index
        }));
    };


    const handleRightListItem = (name: "productId", key: string, value: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        const isExist = state[name].find(ele => ele.key === key);
        if (isExist) {
            payload = state[name].filter(ele => ele.key !== key);
        } else {
            payload = state[name];
            payload.push({
                key,
                value
            });
        }

        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const deleteChip = (name: "productId" , key: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        payload = state[name].filter(ele => ele.key !== key);
        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };


    const resetFilter = () => {
        setState({
            selectedMenu: 0,
            productId: [],
        });
    };

    const onApply = () => {
        searchParams.set("productId", JSON.stringify(state.productId));

        searchParams.set("page", "1");
        navigate({
            pathname: "/content/category",
            search: searchParams.toString()
        });
        OnClose();
    };

    const onCancel = () => {
        const productId : { key: string, value: string }[] = searchParams.get("productId") ? JSON.parse(String(searchParams.get("productId"))) : [];

        setState(prevState => ({
            ...prevState,
            productId
        }));
        OnClose();
    };

    return <>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={onCancel}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            <Box id="filters-container">
                <Box className="center mb-3" justifyContent="space-between" alignItems="start">
                    <div className="active-filter mb-1">
                        {
                            (state.productId.length) ?
                                <>
                                    {state.productId.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("productId", ele.key)} label={ele.value} variant="outlined" />)}
                                </>
                                :
                                <Box className="mt-2" display="flex" alignItems="center">
                                    <FilterAltOffIcon />
                                    <Typography className="ml-2">No filters are applied</Typography>
                                </Box>
                        }
                    </div>
                    <IconButton
                        onClick={onCancel}
                        style={{ marginRight: "-10px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Grid className="filter-box" container>
                    <Grid id="left" item xs={5}>
                        <List component="nav">
                            <MenuItem
                                index={0}
                                label="Product"
                                selectedMenu={state.selectedMenu === 0}
                                onChange={handleLeftListItem}
                                count={state.productId}
                            />
                        </List>
                    </Grid>
                    <Divider orientation="vertical" />

                    <Grid id="right" item xs={6}>
                        <List component="nav">
                            {
                                state.selectedMenu === 0 &&
                                products.filter((data) => data.isActive).map((data) => <ListItemButton
                                    key={data._id}
                                    selected={!!state.productId.find(ele => ele.key === data._id)}
                                    onClick={() => handleRightListItem("productId", data._id, data.name)}
                                >
                                    <ListItemText primary={data.name} />
                                    <Checkbox edge="end" checked={!!state.productId.find(ele => ele.key === data._id)} />
                                </ListItemButton>)
                            }
                        </List>

                    </Grid>
                </Grid>

                <Box className="actions-btn" marginTop="8px" textAlign="end">
                    <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
                    <Button className="ml-2" onClick={onApply}>Apply</Button>
                </Box>
            </Box>
        </Menu>
    </>;
};

export default BlogCategoryFilter;