import { Box, Button, Grid, TextField, capitalize } from "@mui/material";
import { FC, useEffect, useState } from "react";
import CustomTable from "../../mui/table";
import { ICandidateNote, IColumn, IErrorResponse, INoteState, ICandidateDetails } from "../../../interfaces";
import { formatDateTime } from "../../../utilities/helper";
import GetActions from "../../get-actions";
import { Controller, useForm } from "react-hook-form";
import { noteValidation } from "../../../validations";
import { joiResolver } from "@hookform/resolvers/joi";
import { CandidateService } from "../../../services";
import useSnackbar from "../../../hooks/useSnackbar";
import WarningDialog from "../../mui/warning-dialog";
import ManageNote from "../manage-note";

interface props {
  candidate: ICandidateDetails | undefined;
  currentTab: number;
  activeAction: boolean;
  setActiveAction?: (e: boolean) => void;
  candidateDetailRefetch: () => void;
}

const Notes: FC<props> = ({ candidate, currentTab, activeAction, setActiveAction, candidateDetailRefetch }) => {
  const notes = candidate && candidate.leadNotes && [...candidate.leadNotes].reverse();
  const { addCandidateNote, updateCandidateNote, deleteCandidateNote } = CandidateService();
  const { snackbar } = useSnackbar();
  const [state, setState] = useState<INoteState>({
    list: [],
    deleteWarning: false,
    manageNote: {
      isOpen: false,
      note: ""
    },
    _note: ""
  });
  const { control, handleSubmit, reset, formState: { errors } } = useForm<ICandidateNote>({
    resolver: joiResolver(noteValidation),
    defaultValues: {
      note: ""
    }
  });

  useEffect(() => {
    if (notes) {
      const list = notes ? notes.map((note, i) => createRow(i, note)) : [];
      setState(prevState => (
        {
          ...prevState,
          list
        }
      ));
    }
  }, [candidate?.leadNotes]);

  const handleEdit = (note?: ICandidateNote) => setState(prevState => ({
    ...prevState,
    manageNote: {
      ...prevState.manageNote,
      isOpen: !prevState.manageNote.isOpen,
      note: note ? note.note : ""
    },
    _note: note ? note._id : ""
  }));

  const handleDelete = (_id?: string) => setState(prevState => ({
    ...prevState,
    deleteWarning: !prevState.deleteWarning,
    _note: _id ? _id : ""
  }));

  const onDelete = async () => {
    try {
      const id = {
        _lead: candidate?._id,
        _note: state._note
      };
      const deletedCandidate = await deleteCandidateNote(id);
      snackbar(deletedCandidate.message, "info");
      handleDelete();
      candidateDetailRefetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      handleDelete();
      console.log({ "Error in delete candidate note": error });
    }
  };

  const onUpdate = async (data: { note: string }) => {
    const payload = {
      ...data,
      _lead: candidate ? candidate._id : "",
      _note: state._note
    };

    try {
      const user = await updateCandidateNote(payload);
      snackbar(user.message, "info");
      handleEdit();
      candidateDetailRefetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log("error in manage candidate note", error);
    }
  };

  const onSubmit = async (data: ICandidateNote) => {
    reset();
    try {
      const payload = {
        _id: candidate ? candidate?._id : "",
        leadNote: {
          ...data,
          timestamp: new Date().toISOString(),
        }
      };

      const updatedCandidate = await addCandidateNote(payload);
      snackbar(updatedCandidate.message, "info");
      reset();
      setActiveAction && setActiveAction(false);
      candidateDetailRefetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      setActiveAction && setActiveAction(false);
      console.log("error in candidate note", error);
    }
  };

  const columns: IColumn[] = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "note",
      label: "Note",
      maxWidth: 400,
      whiteSpace: "normal"
    },
    {
      id: "called_by",
      label: "Called By"
    },
    {
      id: "called_at",
      label: "Called At"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

  const createRow = (index: number, note: ICandidateNote,) => {
    const action = <GetActions
      icons={[
        { name: "Edit", method: () => handleEdit(note) },
        { name: "Delete", method: () => handleDelete(note._id) },
      ]}
    />;

    return {
      id: index + 1,
      note: note.note,
      called_by: capitalize(note.addedBy.name),
      called_at: formatDateTime(note.createdAt),
      action
    };
  };

  const isActionActive = currentTab === 3 && activeAction;

  return (
    <Box paddingTop="10px">
      <Box height="56vh" overflow="auto" paddingTop="10px">

        {/* Add Data  */}
        {
          isActionActive &&
          <Box marginBottom="20px">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item md={10}>
                  <Controller
                    control={control}
                    name="note"
                    render={(prop) => <TextField
                      label="Note*"
                      variant="outlined"
                      size="small"
                      placeholder="Add note for candidate"
                      error={errors.note ? true : false}
                      helperText={errors["note"]?.message}
                      {...prop.field}
                    />}
                  />
                </Grid>
                <Grid item md={2}>
                  <Button
                    fullWidth
                    type="submit"
                  >
                    Add Note
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        }

        {/* Show Data  */}
        <CustomTable
          columns={columns}
          rows={state.list}
          height={isActionActive ? "calc(100% - 77px)" : "calc(100% - 13px)"}
          width="calc(100% - 2px)"
        />

        {/* Delete Data  */}
        <WarningDialog
          isOpen={state.deleteWarning}
          onClose={() => handleDelete()}
          onConfirm={onDelete}
          title="Delete Note"
          description="Are you sure you want to delete this note?"
        />

        {/* Manage Data  */}
        <ManageNote
          note={state.manageNote.note}
          isOpen={state.manageNote.isOpen}
          onClose={handleEdit}
          onUpdate={onUpdate}
        />
      </Box>
    </Box>
  );
};

export default Notes;