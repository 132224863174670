import { Route, Routes } from "react-router-dom";
import NewCategory from "./Tests/manageTestCategories/testCategories/manageCategories";
import ManageResponses from "./Tests/manageTestCategories/testResponse/manageResponse";
import AddQuestion from "./Tests/manageTestCategories/testQuestions/manageQuestions/addQuestions";
import SingleResponse from "./Tests/manageTestCategories/testResponse/manageResponse/singleResponse";
import ImportTestQuestion from "./Tests/manageTestCategories/testQuestions/manageQuestions/import";
import ViewQuestion from "./Tests/manageTestCategories/testQuestions/manageQuestions/ViewQuestions";
const TestNestedRouting = () => (
  <Routes>
    <Route path="manage-category/:categoryId" element={<NewCategory />} />{" "}
    <Route path="manage-questions/:categoryQuesId" element={<AddQuestion />} />{" "}
    <Route path="view-questions/:categoryQuesId" element={<ViewQuestion />} />{" "}
    <Route path="import-questions/import" element={<ImportTestQuestion />} />{" "}
    <Route path="manage-response/:responseId" element={<SingleResponse />} />{" "}
    <Route
      path="response-details/:sessionId/:leadId"
      element={<ManageResponses />}
    />
  </Routes>
);

export default TestNestedRouting;