import "./style.scss";
import { Box } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { BootstrapDialog, BootstrapDialogTitle, TabPanel } from "../../../../components/shared/mui-tabs";
import DialogContent from "@mui/material/DialogContent";
import { useQuery } from "@tanstack/react-query";
import { CollegeService } from "../../../../services";
import { capitalize } from "../../../../utilities/helper";
import Mail from "./email";
import { useSelector } from "react-redux";
import { ITemplate } from "../../../../interfaces";

const CollegeActionLayout = () => {
      const navigate = useNavigate();
      const [...templates] = useSelector<{ template: { list: ITemplate[] } }, ITemplate[]>(state => state.template.list) || [];
      const { id } = useParams();
      const [value] = useState(0);
      const { getCollege } = CollegeService();
      const [searchParam] = useSearchParams();
      const college = useQuery({
            queryKey: ["colleges"],
            queryFn: () => getCollege({ _id: id })
      });

      const onClose = () => {
            searchParam.delete("type");
            navigate({
                  pathname: "/cpd/college",
                  search: searchParam.toString()
            });
      };

      const name = college.data?.data?.name ? capitalize(college.data?.data.name) : "College Actions";
      return <div>
            <BootstrapDialog
                  maxWidth="md"
                  onClose={onClose}
                  open={id ? true : false}
            >
                  <BootstrapDialogTitle onClose={onClose}>
                        <Box display="flex" alignItems="center">
                              {name}
                        </Box>
                  </BootstrapDialogTitle>
                  <DialogContent dividers sx={{ height: "60vh" }}>
                        <Box width="100%" >

                              {/* Mail  */}
                              <TabPanel value={value} index={0}>
                                    <Mail
                                          college={college.data?.data}
                                          templates={templates.filter(template => template.type === "email" && template.tag === "general")}
                                    />
                              </TabPanel>
                        </Box>
                  </DialogContent>
            </BootstrapDialog>
      </div>;
};

export default CollegeActionLayout;