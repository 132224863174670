import HttpService from "../../http";
import { ICollegesResponse, ICollegeResponse, ICollegeSampleResponse } from "../../../interfaces/configuration/college";
import { college, commonEmail } from "../../endpoints";

const CollegeService = () => {
    const { httpRequest } = HttpService();
    const addCollege = async (payload: object): Promise<ICollegesResponse> => new Promise((resolve, reject) => {
        httpRequest<ICollegesResponse>(
            "POST",
            `${college}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const addColleges = async (payload: object): Promise<ICollegesResponse> => new Promise((resolve, reject) => {
        httpRequest<ICollegesResponse>(
            "POST",
            `${college}/many`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getPartialColleges = async (): Promise<ICollegesResponse> => new Promise((resolve, reject) => {
        httpRequest<ICollegesResponse>(
            "GET",
            `${college}/list/partial`
        )
            .then(resolve)
            .catch(reject);
    });

    const getColleges = async (search: object): Promise<ICollegesResponse> => new Promise((resolve, reject) => {
        httpRequest<ICollegesResponse>(
            "GET",
            `${college}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getCollege = async (query: object): Promise<ICollegeResponse> => new Promise((resolve, reject) => {
        httpRequest<ICollegeResponse>(
            "GET",
            `${college}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateCollege = async (id: string, payload: object): Promise<ICollegeResponse> => new Promise((resolve, reject) => {
        httpRequest<ICollegeResponse>(
            "PUT",
            `${college}`,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const updateCollegeStatusAssignTo = async (payload: object,): Promise<ICollegeResponse> => new Promise((resolve, reject) => {
        httpRequest<ICollegeResponse>(
            "PUT",
            `${college}/status-assignto`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });
    const sendEmail = async (payload: object): Promise<ICollegeResponse> => new Promise((resolve, reject) => {
        httpRequest<ICollegeResponse>(
            "POST",
            `${commonEmail}/email`,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteCollege = async (query: object): Promise<ICollegesResponse> => new Promise((resolve, reject) => {
        httpRequest<ICollegesResponse>(
            "DELETE",
            `${college}`,
            {},
            query,
        )
            .then(resolve)
            .catch(reject);
    });

    const addContactPerson = async (payload: object): Promise<ICollegesResponse> => new Promise((resolve, reject) => {
        httpRequest<ICollegesResponse>(
            "POST",
            `${college}/contact-person`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updateContactPerson = async (payload: object): Promise<ICollegeResponse> => new Promise((resolve, reject) => {
        httpRequest<ICollegeResponse>(
            "PUT",
            `${college}/contact-person`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteContactPerson = async (search: object): Promise<ICollegeResponse> => new Promise((resolve, reject) => {
        httpRequest<ICollegeResponse>(
            "DELETE",
            `${college}/contact-person`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getCollegeSample = async (search: object): Promise<ICollegeSampleResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<ICollegeSampleResponse>(
                "GET",
                `${college}/sample`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });

    return { getPartialColleges, getColleges, addCollege, getCollege, updateCollege, updateCollegeStatusAssignTo, sendEmail, deleteCollege, addColleges, addContactPerson, updateContactPerson, deleteContactPerson, getCollegeSample };
};

export { CollegeService };