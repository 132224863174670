import Joi from "joi";
import { required} from "../../shared";

export const assessmentValidation = Joi.object({
    title: Joi.string()
        .trim()
        .required()
        .label("Title")
        .messages({
            "string.empty": required
        }),
    description: Joi.string()
        .trim()
        .required()
        .label("Description")
        .messages({
            "string.empty": required
        }),
    instructions: Joi.string()
    .trim()
    .required()
    .label("Instructions")
    .messages({
        "string.empty": required
    }),    
});