import "./style.scss";
import { useEffect, useRef, MouseEvent, useState } from "react";
import { formatMonthTime } from "../../utilities/helper";

import FilterListIcon from "@mui/icons-material/FilterList";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { ClassNamesGenerator, DayCellContentArg } from "@fullcalendar/core";
import { Badge, Box, Button, capitalize, Grid, MenuItem, Select, SelectChangeEvent, Tooltip, Typography } from "@mui/material";
import backArrow from "../../assets/images/back-arrow.svg";
import frontArrow from "../../assets/images/front-arrow.svg";
import activityIcon from "../../assets/images/cms-activity.svg";
import CmsActivity from "./component/activity";
import CmsActivityFilter from "./filter";
import useResource from "../../hooks/useResource";
import { useQuery } from "@tanstack/react-query";
import CmsHomeService from "../../services/cms-home";
import { ICmsHomeData } from "../../interfaces/cms-home";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { IProjectData } from "../../interfaces";

interface Filters {
  status: string[];
}

interface FilterDialog {
  anchorEl: HTMLButtonElement | null;
  isOpen: boolean;
}

interface CmsHomeModuleState {
  filters: Filters;
  filterDialog: FilterDialog;
  filterCount: number;
}


const CmsHomeModule = () => {

  const productList = useSelector<{ cmsProduct: { list: IProjectData[] } }, IProjectData[]>(
    (state) => state.cmsProduct.list
  ) || [];

  const formatDate = (fetchedDate: string) => {
    const dateObject = new Date(fetchedDate);

    // Get the year, month, and day components
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const day = dateObject.getDate().toString().padStart(2, "0");

    // Format the date in "YYYY-MM-DD" format
    return `${year}-${month}-${day}`;
  };

  const oneDayAfterCurrentDay = new Date();
  oneDayAfterCurrentDay.setDate(oneDayAfterCurrentDay.getDate() + 1);

  const oneDayBeforeCurrentDay = new Date();
  oneDayBeforeCurrentDay.setDate(oneDayBeforeCurrentDay.getDate() - 1);
  const calendarRef = useRef<FullCalendar>(null);
  const { resourceAllocate } = useResource();
  const { cmsHomeStats } = CmsHomeService();
  const [selectedProduct, setSelectedProduct] = useState({ id: "all", name: "All Products" });
  const [state, setState] = useState<CmsHomeModuleState>({
    filters: {
      status: [],
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false,
    },
    filterCount: 0
  });
  // TO-DO: Remove this once API is ready.
  // eslint-disable-next-line
  const [events, setEvents] = useState<ICmsHomeData[]>([]);
  const navigate = useNavigate();
  const [activeAction, setActiveAction] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if(localStorage.getItem("selectedFilterProductId")){
      const selectedFilterProductId = localStorage.getItem("selectedFilterProductId") || "all";
      const selectedProduct = productList.find((item) => item._id === selectedFilterProductId) || { id: "all", name: "All Products" };
      setSelectedProduct({ 
        id: selectedFilterProductId, 
        name: selectedProduct.name
      });
    }
  }, []);

    const handleProductChange = (event: SelectChangeEvent) => {
      const { name, value } = event.target;
      setSelectedProduct({ id: value, name: name });
      localStorage.setItem("selectedFilterProductId", value);
    };
  const [currentMonthStartDate, setCurrentMonthStartDate] = useState(new Date().toISOString());
  const [currentMonthendDate, setCurrentMonthEndDate] = useState(new Date().toISOString());

  const homeEvents = useQuery({
    queryKey: ["cms-home-events", currentMonthStartDate, currentMonthendDate, state.filters, selectedProduct],
    queryFn: () => cmsHomeStats({ startDate: currentMonthStartDate, endDate: currentMonthendDate, ...state.filters, productId: selectedProduct.id === "all" ? null : selectedProduct.id }),
  });

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;

    const category: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
    filterCount += category.length ? 1: 0;
    setState((prevState) => ({
      ...prevState,
      pagination: {
        page,
      },
      filters: {
        ...prevState.filters,
        status: category.map(item => item.key)
      },
      filterCount
    }));
  }, [searchParams]);

  useEffect(() => {

    if (homeEvents?.data?.data?.length) {
      console.log(new Date(homeEvents?.data?.data[0]?.start).toUTCString());
      const eventData = homeEvents?.data?.data.map((event: ICmsHomeData) => ({
        ...event,
        start: formatDate(new Date(event?.start).toUTCString()),
      }));
      setEvents(eventData);
    } else {
      setEvents([]);
    }
  }, [homeEvents?.data?.data]);
  // eslint-disable-next-line
  const eventClassNamesHandler = (arg: any) => {
    const currentDate = new Date();
    const startOfDay = new Date(currentDate);
    startOfDay.setHours(0, 0, 0, 0);
    const eventStatus = arg.event.extendedProps.status;
    const endOfDay = new Date(currentDate);
    endOfDay.setHours(23, 59, 59, 999);
    const eventDate = new Date(arg.event.start || "");

    if (eventStatus === "COMPLETED") {
      return "completed-event";
    } else if (eventStatus !== "COMPLETED" && (eventDate < startOfDay)) {
      return "past-event";
    } else {
      return "future-event";
    }
  };

  const handleCustomPrevButtonClick = () => {
    // Calculate the previous month start date
    const currentDate = new Date(currentMonthStartDate);
    currentDate.setMonth(currentDate.getMonth() - 1);
    setCurrentMonthStartDate(currentDate.toISOString());
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => {
    const anchorElement = e.currentTarget;

    setState((prevState: CmsHomeModuleState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        anchorEl: anchorElement,
        isOpen: !state.filterDialog.isOpen
      }
    }));
  };


  const closeFilter = () => {
    setState((prevState: CmsHomeModuleState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const handleCustomNextButtonClick = () => {
    // Calculate the next month start date
    const currentDate = new Date(currentMonthStartDate);
    currentDate.setMonth(currentDate.getMonth() + 1);
    setCurrentMonthStartDate(currentDate.toISOString());
  };


  useEffect(() => {
    if (calendarRef.current) {
      const startDate = new Date(new Date(currentMonthStartDate).getFullYear(), new Date(currentMonthStartDate).getMonth(), 1).setHours(0, 0, 0, 0); //new Date(currentDate.getFullYear(), currentMonthStartDate.getMonth(), 1).toISOString();
      const endDate = new Date(new Date(currentMonthStartDate).getFullYear(), new Date(currentMonthStartDate).getMonth() + 1, 0).setHours(23, 59, 59, 0);
      calendarRef.current.getApi().gotoDate(startDate);
      setCurrentMonthStartDate(new Date(startDate).toISOString());
      setCurrentMonthEndDate(new Date(endDate).toISOString());
    }
  }, [currentMonthStartDate]);
  const dayCellNamesHandler: ClassNamesGenerator<DayCellContentArg> = (arg: DayCellContentArg) => {

    if (arg.isPast) {
      return "custom-past-day";
    } else {
      return "custom-current-day";
    }
  };

  // eslint-disable-next-line
  const headerClassNamesHandler = (arg: any) => "custom-header";
  // eslint-disable-next-line
  const eventClickHandler = (eventInfo: any) => {
    // alert(`Event details: ${eventInfo.event.id}`);
    navigate(`/blog/manage/${eventInfo.event.id}?type=basic-detail`);
  };
  // eslint-disable-next-line
  const handleEventMouseEnter = (eventInfo: any) => {
    eventInfo.el.classList.add("event-hover");
  };
  // eslint-disable-next-line
  const handleEventMouseLeave = (eventInfo: any) => {
    eventInfo.el.classList.remove("event-hover");
  };
  const customButtons = {
    customPrevButton: {
      text: "Custom Prev"
    },
    customNextButton: {
      text: "Custom Next"
    },
    customTodayButton: {
      text: "Custom Today"
    },
  };
  return (
    <>
      <Box
        width="100%"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        className="mt-3 mb-4 calender-header"
      >
        <Box display="flex" alignItems="center" className="calender-icon">
          <img
            height="24px"
            className="p-2 arrows cursor-pointer mr-3"
            onClick={handleCustomPrevButtonClick}
            src={backArrow}
            alt=""
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent={"center"}>
          <Typography
            className="p-2 custom-typography"
            fontWeight={"550"}
          >
            {formatMonthTime(new Date(currentMonthStartDate))}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" className="calender-icon">
          <img
            height="24px"
            className="p-2 arrows cursor-pointer ml-3"
            onClick={handleCustomNextButtonClick}
            src={frontArrow}
            alt=""
          />
        </Box>


        <Box display="flex" alignItems="center" className="mr-3" marginLeft={"auto"}>
          <Select value={selectedProduct.id} onChange={handleProductChange} size="small">
            <MenuItem value="all">All Products</MenuItem>
            {productList.filter((data) => data.isActive).map((product) => <MenuItem key={product._id} value={product._id} >{product.name}</MenuItem>)}
          </Select>
        </Box>
        <Box display="flex" className="mr-2" alignItems="center">
          {resourceAllocate("cms-home-activity.read") && <button
            className="arrows cursor-pointer"
            onClick={() => setActiveAction(!activeAction)}
            style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "50px", height: "42px" }}
          >
            <img
              height="18px"
              src={activityIcon}
              alt=""
            />
          </button>
          }
        </Box>
        <Box className="filter-box">
          <Tooltip title="Filter">
          <Badge
            className="ml-2"
            color="primary"
            badgeContent={state.filterCount}
            invisible={!state.filterCount}
          >
            <Button
              className={state.filterCount ? "p-2 filter active" : "p-2 filter default"}
              variant='outlined'
              onClick={openFilter}
            >
              <FilterListIcon />
            </Button>
          </Badge>
          </Tooltip>
        </Box>
        <CmsActivityFilter
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          onClose={closeFilter}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item {...(activeAction ? { xs: 8 } : { xs: 12 })} style={{ borderRadius: "15px" }}>
          <FullCalendar
            ref={calendarRef}
            events={events}
            eventMouseEnter={handleEventMouseEnter}
            eventMouseLeave={handleEventMouseLeave}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            eventClick={eventClickHandler}
            eventClassNames={eventClassNamesHandler}
            eventContent={(arg) => (
              <Typography variant="body2" className="ml-2">
                {arg.event.title.length > 16 ? capitalize(`${arg.event.title.slice(0, 16)}...`) : capitalize(arg.event.title)}
              </Typography>
            )}
            dayCellClassNames={dayCellNamesHandler}
            customButtons={customButtons}
            headerToolbar={false}
            initialDate={currentMonthStartDate}
            dayHeaderClassNames={headerClassNamesHandler}
          />
        </Grid>
        {activeAction && <Grid item xs={4}>
          <CmsActivity
            activeAction={activeAction}
            setActiveAction={setActiveAction}
          />
        </Grid>
        }
      </Grid>
    </>
  );
};

export default CmsHomeModule;