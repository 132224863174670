import Joi from "joi";
import { required } from "../shared";

export const BroadcastValidation = Joi.object({
    content: Joi.string()
        .optional()
        .allow(""),
    subject: Joi.alternatives().conditional("type", {
        is: "EMAIL",
        then:
            Joi.string()
                .required()
                .min(3)
                .max(60)
                .trim()
                .label("Subject")
                .messages({
                    "string.empty": required,
                }),
        otherwise: Joi.optional()
        }),
    scheduled: Joi.string()
        .optional()
        .allow("")
        .label("Schedule"),
    type: Joi.string()
        .valid("EMAIL", "WHATSAPP")
        .label("Broadcast Type")
        .required()
        .messages({
            "number.empty": required,
        }),
    updatedStatus: Joi.string()
        .required()
        .messages({
            "string.empty": required,
        }),

    currentStatus: Joi.array()
        .items(Joi.string())
        .min(1)
        .required()
        .messages({
            "string.empty": required,
        }),

    template: Joi.alternatives().conditional("type", {
        is: "EMAIL",
        then:
            Joi.string()
                .required()
                .label("Template")
                .messages({
                    "string.empty": required,
                }),
        otherwise: Joi.optional()
    }),

    whatsappTemplate: Joi.alternatives().conditional("type", {
        is: "WHATSAPP",
        then:
            Joi.string()
                .required()
                .label("Whatsapp Template")
                .messages({
                    "string.empty": required,
                }),
        otherwise: Joi.optional()
    }),

    broadcastName: Joi.string()
        .required()
        .min(3)
        .max(60)
        .trim()
        .label("Broadcast Name")
        .messages({
            "string.empty": required,
        }),

    dynamicParameter: Joi.alternatives().conditional("type", {
        is: "WHATSAPP",
        then:
            Joi.object()
                .keys({
                    body: Joi.array()
                        .items({
                            title: Joi.string()
                                .required()
                                .label("Title")
                                .messages({
                                    "string.empty": required,
                                }),
                            type: Joi.string()
                                .optional()
                                .label("Type"),
                            value: Joi.string()
                                .required()
                                .label("Attribute")
                                .messages({
                                    "string.empty": required,
                                }),
                            dynamicInput: Joi.string()
                                .required()
                                .label("Fallback Value")
                                .messages({
                                    "string.empty": required,
                                })
                        })
                })
                .optional()
                .label("Dynamic Parameters")
                .messages({
                    "string.empty": required,
                }),
        otherwise: Joi.optional()
    }),

});