import Joi from "joi";
import { min_characters, required } from "../../shared";

export const SubCategoryValidation = Joi.object({
    name: Joi.string()
    .required()
    .min(3)
    .trim()
    .label("Title")
    .messages({
        "string.empty": required,
        "string.min":min_characters
      }),
      categoryId: Joi.string()
      .required()
      .label("Category")
      .messages({
        "string.empty": required,
      }),
});