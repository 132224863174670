import Joi from "joi";
import { required, valid_string } from "../shared";

export const kpiValidation = Joi.object({
    target: Joi.number()
        .required()
        .label("Target")
        .messages({
            "number.empty": required
        }),
    typeOfLead: Joi.string()
        .required()
        .label("Engagement Type")
        .messages({
            "any.only": valid_string
        }),
    title: Joi.string()
        .required()
        .label("Job Title")
        .messages({
            "any.only": valid_string
        }),
    _recruiter: Joi.string()
        .required()
        .label("Recruiter Name")
        .messages({
            "any.only": valid_string
        }),
    type: Joi.string()
        .valid("week", "month")
        .required()
        .label("Type")
        .messages({
            "any.only": valid_string
        }),
    deadline: Joi.string()
        .required()
        .messages({
            "string.empty": required
        }),
});