import Joi from "joi";
import { required } from "../../../shared";

export const whatsappValidation = Joi.object({
    to: Joi.string()
        .label("Candidate Mobile Number")
        .min(8)
        .required()
        .messages({
            "string.empty": required,
        }),
    templateId: Joi.string()
        .label("Whatsapp Template")
        .required()
        .messages({
            "string.empty": required,
        }),
        templateName: Joi.string()
        .label("Template Name")
        .required()
        .messages({
            "string.empty": required,
        }),
    content: Joi.string()
        .required()
        .label("Content")
        .messages({
            "string.empty": required,
        }),
});
