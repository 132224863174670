import Joi from "joi";
import { required } from "../../shared";

export const manySkillMatrixValidation = Joi.object({
        name: Joi.string()
          .required()
          .max(25)
          .label("Skill Item")
          .messages({
            "string.empty": required,
            "string.max": "Maximum 25 characters are allowed"
          }),
        _skillCategory: Joi.string()
          .required()
          .label("Skill Category")
          .messages({
            "string.empty": required,
          })
});