import "../style.scss";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { Box, Button, Grid, InputLabel, Link, MenuItem, TextField } from "@mui/material";
import GetActions from "../../../../components/get-actions";
import { IVendorEdit } from "../../../../interfaces/vendor";
import useSnackbar from "../../../../hooks/useSnackbar";
import { Controller, useForm } from "react-hook-form";
import { IErrorResponse } from "../../../../interfaces/shared/response";
import { IColumn } from "../../../../interfaces/shared";
import { capitalize } from "../../../../utilities/helper";
import CustomTable from "../../../../components/mui/table";
import { useQuery } from "@tanstack/react-query";
import Select from "../../../../components/mui/select";
import HttpService from "../../../../services/http";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CustomLabel from "../../../../components/mui/custom-label";
import { VendorTransactionService } from "../../../../services/vendor/transaction";
import { IVendorTransaction, IVendorTransactionField, IVendorTransactionRow, IVendorTransactionState } from "../../../../interfaces/vendor/transaction";
import ManageVendorTransactionDetail from "./manage";
import { VendorPaymentModeService } from "../../../../services/vendor/payment-mode";
import { VendorContactPersonService } from "../../../../services/vendor/contact-person";
import { joiResolver } from "@hookform/resolvers/joi";
import { vendorTransactionValidation } from "../../../../validations/vendor/manage-vendor/transaction";
import CustomTypography from "../../../../components/mui/max-length-limit";

interface props {
  vendor: IVendorEdit | undefined;
  isDisable?: boolean;
  activeAction: boolean;
  setIsDisable?: (e: boolean) => void;
  onClose?: () => void;
  reFetch?: () => void;
  setActiveAction: (e: boolean) => void;
}

const TransactionDetails: FC<props> = ({ vendor, activeAction, setActiveAction }) => {
  const { httpFormRequest } = HttpService();
  const [transactionId, setTransactionId] = useState("");
  const { snackbar } = useSnackbar();
  const { addTransaction, getTransactions } = VendorTransactionService();
  const { getPaymentModes } = VendorPaymentModeService();
  const { getContactPersons } = VendorContactPersonService();
  const [state, setState] = useState<IVendorTransactionState>({
    list: [],
    deleteWarning: false,
    manageTransaction: {
      isOpen: false,
      transaction: {
        _vendor: "",
        mode: "",
        name: "",
        upiId: "",
        bankName: "",
        invoice: "",
        poc: "",
        status: "",
        paymentDate: "",
        amount: 0,
        accountNumber: "",
        ifsc: "",
        note: ""
      }
    },
    _transactionDetail: "",
    deleteIndex: -1
  });

  const { control, handleSubmit, trigger, setValue, getValues, reset, watch, formState: { errors } } = useForm<IVendorTransaction>({
    resolver: joiResolver(vendorTransactionValidation),
    defaultValues: {
      mode: "",
      name: "",
      upiId: "",
      bankName: "",
      invoice: "",
      poc: "",
      status: "",
      paymentDate: "",
      amount: 0,
      accountNumber: "",
      ifsc: "",
      note: ""
    }
  });

  const transactionsData = useQuery({
    queryKey: ["allTransactions"],
    queryFn: () =>
      getTransactions({
        _vendor: vendor?._id
      }),
    enabled: !!vendor?._id
  });

  const paymentModes = useQuery({
    queryKey: ["allPaymentModes"],
    queryFn: () =>
      getPaymentModes({
        _vendor: vendor?._id
      }),
    enabled: activeAction
  });

  const contactPersons = useQuery({
    queryKey: ["all-contact-persons"],
    queryFn: () =>
      getContactPersons({
        _vendor: vendor?._id
      }),
    enabled: activeAction
  });

  let rows: IVendorTransactionRow[] = [];

  const handleEdit = (transaction: IVendorTransaction) => setState(prevState => {
    if (transaction?._id) {
      setTransactionId(transaction._id);
    }
    const newState = {
      ...prevState,
      manageTransaction: {
        ...prevState.manageTransaction,
        isOpen: !prevState.manageTransaction.isOpen,
      },
      _contractDetail: transaction ? transaction._id : "",
    } as IVendorTransactionState;

    return newState;
  });

  const onClose = () => {
    setState(prevState => ({
      ...prevState,
      manageTransaction: {
        ...prevState.manageTransaction,
        isOpen: !prevState.manageTransaction.isOpen,
      }
    }));
  };

  const onSubmit = async (data: IVendorTransaction) => {
    try {
      const payload = {
        ...data,
      };
      if (watch("mode") === "CASH") {
        delete payload.upiId;
        delete payload.accountNumber;
        delete payload.bankName;
        delete payload.ifsc;
      } else if (watch("mode") === "UPI") {
        delete payload.ifsc;
        delete payload.accountNumber;
      } else {
        delete payload.upiId;
      }
      const paymentDetail = await addTransaction({
        ...payload,
        _vendor: vendor?._id
      });
      reset();
      setActiveAction(false);
      snackbar(paymentDetail?.message, "info");
      transactionsData.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "warning");
      console.log("error in mode detail", error);
    }
  };

  useEffect(() => {
    const selectedName = watch("name");
    if (selectedName && paymentModes.data) {
      const selectedMode = paymentModes.data.data.find(mode => mode.name === selectedName);
      if (selectedMode) {
        setValue("mode", selectedMode.paymentMode);
        setValue("upiId", selectedMode.upiId || "");
        setValue("bankName", selectedMode.bankName || "");
        setValue("ifsc", selectedMode.ifsc || "");
        setValue("accountNumber", selectedMode.accountNumber || "");
      }
    }
  }, [watch("name"), paymentModes.data]);

  const fields: IVendorTransactionField[] = [
    {
      type: "select",
      name: "name",
      label: "Name",
      children: paymentModes?.data?.data.map(mode => <MenuItem key={mode.name} value={mode.name}>{`${capitalize(capitalize(mode.name))}`}</MenuItem>),
      required: true
    },
    {
      type: "input",
      name: "mode",
      label: "Payment Mode",
      required: true,
      disabled: true
    },
    {
      type: "input",
      name: "upiId",
      label: "UPI ID",
      required: true,
      disabled: true,
    },
    {
      type: "input",
      name: "bankName",
      label: "Bank Name",
      required: true,
      disabled: true,
    },
    {
      type: "input",
      name: "ifsc",
      label: "IFSC",
      required: true,
      disabled: true,
    },
    {
      type: "input",
      name: "accountNumber",
      label: "Account Number",
      required: true,
      disabled: true,
    },
    {
      type: "select",
      name: "poc",
      label: "POC",
      children: contactPersons?.data?.data.map(per => <MenuItem key={per.name} value={per.name}>{`${capitalize(capitalize(per.name))}`}</MenuItem>),
      required: true
    },
    {
      type: "select",
      name: "status",
      label: "Status",
      required: true,
      children: [
        <MenuItem key="DUE" value="DUE" >Due</MenuItem>,
        <MenuItem key="FAILED" value="FAILED" >Failed</MenuItem>,
        <MenuItem key="SUCCESSFUL" value="SUCCESSFUL" >Successful</MenuItem>
      ]
    },
    {
      type: "input",
      name: "amount",
      label: "Amount",
      required: true,
    },
    {
      label: "Payment Date",
      name: "paymentDate",
      type: "date",
      required: true
    },
    {
      label: "Note",
      name: "note",
      type: "input"
    },
    {
      label: "Invoice",
      name: "invoice",
      type: "doc",
      required: true
    }
  ];

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const uploaded = await httpFormRequest<{ data: string }>(
        e.target.files,
        e.target.files ? e.target.files[0].name : "",
        ["doc", "docx", "pdf", "png", "jpeg", "jpg"],
        10
      );
      setValue("invoice", uploaded.data.split("uploads")[1]);
    } catch (error) {
      console.log("error in candidate detail upload", error);
    }

  };

  const selectDate = (value: string | number | Date | dayjs.Dayjs | null | undefined, name: string) => {
    const date = value && dayjs(value).toString() !== "Invalid Date" ? dayjs(value)?.toISOString() : undefined;
    const keyExist = name === "paymentDate";
    if (keyExist) {
      setValue(name, date);
      trigger(name);
    }
  };

  const filteredFields = fields.filter((field) => {
    if (watch("mode") === "ACCOUNT") {
      return !["upiId"].includes(field.name);
    } else if (watch("mode") === "UPI") {
      return !["ifsc", "accountNumber"].includes(field.name);
    } else if (watch("mode") === "CASH") {
      return !["ifsc", "bankName", "accountNumber", "upiId"].includes(field.name);
    } else {
      return ["name", "mode", "poc", "status", "amount", "paymentDate", "invoice", "note"].includes(field.name);
    }
  });

  const columns: IColumn[] = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "mode",
      label: "Mode Of Transaction"
    },
    {
      id: "poc",
      label: "POC"
    },
    {
      id: "status",
      label: "Status"
    },
    {
      id: "note",
      label: "Note"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

  const createRow = (index: number, transactionDetail: IVendorTransaction) => {
    const action = <GetActions
      icons={[
        { name: "Edit", method: () => handleEdit(transactionDetail) },
      ]}
    />;

    return {
      id: index + 1,
      name: capitalize(transactionDetail?.name),
      mode: transactionDetail.mode,
      status: transactionDetail.status,
      poc: capitalize(transactionDetail.poc),
      note: (
        <CustomTypography
          limit={60}
          label={transactionDetail.note || "-"}
        />
      ),
      action
    };
  };

  if (transactionsData?.data?.data?.length) {
    rows = transactionsData?.data?.data.map((payment, i) => createRow(i, payment));
  }

  return (
    <Box paddingTop="10px">
      <Box height="56vh" overflow="auto" paddingTop="10px">

        {/* Add Data  */}
        {activeAction &&
          <Box marginBottom="20px">
            <form onSubmit={handleSubmit(onSubmit)} onKeyDown={e => e.key === "Enter" && e.preventDefault()}>
              <Grid container spacing={4}>
                {
                  filteredFields.map(field => {
                    if (field.type === "input") {
                      return (<Grid key={field.label} item xs={4}>
                        <Controller
                          control={control}
                          name={field.name}
                          render={(prop) => <TextField
                            label={<CustomLabel label={field.label} required={field?.required} />}
                            className="disable-text"
                            disabled={field?.disabled}
                            variant={"outlined"}
                            size={"small"}
                            placeholder={field.placeholder}
                            error={!!errors[field.name]}
                            helperText={errors[field.name]?.message}
                            type={field.name === "amount" || field.name === "accountNumber" ? "number" : "text"}
                            {...prop.field}
                          />}
                        />
                      </Grid>
                      );
                    } else if (field.type === "select") {
                      return (<Grid key={field.label} item xs={4} >
                        <Select
                          control={control}
                          className="disable-text"
                          name={field.name}
                          label={<CustomLabel label={field.label} required={field?.required} />}
                          error={!!errors[field.name]}
                          helperText={errors[field.name]?.message}
                        >
                          {field.children}
                        </Select>
                      </Grid>
                      );
                    } else if (field.type === "date") {
                      return (<Grid key={field.label} item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            label={<CustomLabel label={field.label} required={field?.required} />}
                            onChange={(e) => selectDate(e, field.name)}
                            value={dayjs(getValues(field.name))}
                            slotProps={{
                              textField: {
                                error: errors[field.name] ? true : false,
                                helperText: errors[field.name]?.message
                              }
                            }}
                            format="LL"
                          />
                        </LocalizationProvider>
                      </Grid>
                      );
                    } else {
                      const hasValue = getValues(field.name);
                      return (
                        <Grid key={field.label} item xs={4} >
                          <div>
                            <div style={{ color: "rgb(118, 118, 118)", marginTop: "-17px", marginBottom: "3px" }}>
                              {hasValue &&
                                <label>{field.label}</label>
                              }
                            </div>
                            <Grid container spacing={2}>
                              {hasValue && (
                                <Grid item xs>
                                  <Link href={!getValues(field.name)?.toString().includes("https://") ? String(process.env.REACT_APP_S3_BASE_URL) + getValues(field.name)?.toString() : getValues(field.name)?.toString()} target="_blank" underline="none" color="inherit">
                                    <Button variant="outlined" fullWidth>
                                      Preview
                                    </Button>
                                  </Link>
                                </Grid>
                              )}
                              {!hasValue && (
                                <Grid item xs>
                                  <InputLabel id={`upload-${field.name}`} >
                                    <Button component="label" variant="outlined" fullWidth>
                                      Upload {field.label}*
                                      <input hidden type="file" id={`upload-${field.name}`} onChange={e => uploadFile(e)} accept="application/pdf, image/png, image/jpeg, .doc, .docx" />
                                    </Button>
                                  </InputLabel>
                                  {errors[field.name] && <span style={{ color: "#d32f2f", fontWeight: 400, fontSize: "0.85rem" }}>{errors[field.name]?.message}</span>}
                                </Grid>
                              )}
                              {hasValue && (
                                <Grid item xs>
                                  <Button onClick={() => {
                                    setValue(field.name, "");
                                    trigger(field.name);
                                  }} variant="outlined" color="error" fullWidth>
                                    Delete
                                  </Button>
                                </Grid>
                              )}
                            </Grid>
                          </div>
                        </Grid>
                      );
                    }
                  })
                }
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    type="submit"
                  >
                    Add Transaction
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        }

        {/* Show Data  */}
        <CustomTable
          columns={columns}
          rows={rows}
          height={activeAction ? "calc(100% - 145px)" : "calc(100% - 13px)"}
          width="calc(100% - 2px)"
        />

        {/* Manage Data  */}
        <ManageVendorTransactionDetail
          transaction={transactionId}
          vendor={vendor}
          isOpen={state.manageTransaction.isOpen}
          onClose={onClose}
          refetch={transactionsData.refetch}
        />
      </Box>
    </Box>
  );
};

export default TransactionDetails;