import HttpService from "../http";
import { IIReviewsResponse, IReviewResponse } from "../../interfaces";
import { review } from "../endpoints";

const ReviewService = () => {
    const { httpRequest } = HttpService();
    const addReview = async (payload: object): Promise<IIReviewsResponse> => new Promise((resolve, reject) => {
        httpRequest<IIReviewsResponse>(
            "POST",
            review,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getReviews = async (search: object): Promise<IIReviewsResponse> => new Promise((resolve, reject) => {
        httpRequest<IIReviewsResponse>(
            "GET",
            `${review}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getReview = async (query: object): Promise<IReviewResponse> => new Promise((resolve, reject) => {
        httpRequest<IReviewResponse>(
            "GET",
            `${review}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateReview = async (id: string, payload: object): Promise<IReviewResponse> => new Promise((resolve, reject) => {
        httpRequest<IReviewResponse>(
            "PUT",
            `${review}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteReview = async (payload: object): Promise<IIReviewsResponse> => new Promise((resolve, reject) => {
        httpRequest<IIReviewsResponse>(
            "DELETE",
            `${review}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { addReview, getReviews, getReview, updateReview, deleteReview };
};

export { ReviewService };