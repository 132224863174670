import { useState, SyntheticEvent, useEffect, useRef } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import useResource from "../../hooks/useResource";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const VendorLayout = () => {
  const [value, setValue] = useState(0);
  const { resourceAllocate } = useResource();
  const redirectionLinks: string[] = [];
  if(resourceAllocate("vendor.read")){
    redirectionLinks.push("vendors");
  }
  if(resourceAllocate("vendor-category.read")){
    redirectionLinks.push("categories");
  }
  if(resourceAllocate("vendor-service.read")){
    redirectionLinks.push("services");
  }
  const navigate = useNavigate();
  const location = useLocation();
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setValue(redirectionLinks.indexOf(location.pathname.split("/")[2]));
  }, [location.pathname]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    navigate(redirectionLinks[newValue]);

    if (inputRef && inputRef?.current) {

      inputRef.current.value = "";
    }
  };

  return (
    <Box width="100%" >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Box>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            {resourceAllocate("vendor.read") && <Tab className="tabs-space start-tab-space" label="Vendors" {...a11yProps(0)} />}
            {resourceAllocate("vendor-category.read") && <Tab className="tabs-space" label="Categories" {...a11yProps(1)} />}
            {resourceAllocate("vendor-service.read") && <Tab className="tabs-space" label="Services" {...a11yProps(1)} />}
          </Tabs>
        </Box>
      </Box>
      <Outlet />
    </Box>
  );
};

export default VendorLayout;