import { IErrorResponse } from "../../../../interfaces";
import { Box, Grid, MenuItem, TextField } from "@mui/material";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import { capitalize } from "../../../../utilities/helper";
import Select from "../../../../components/mui/select";
import { VendorCategoryService } from "../../../../services/vendor/vendor-category";
import { VendorServiceService } from "../../../../services/vendor/vendor-service";
import { IVendorServiceBasicField, IVendorServicee } from "../../../../interfaces/vendor/vendor-service";
import { joiResolver } from "@hookform/resolvers/joi";
import { vendorServiceValidation } from "../../../../validations/vendor/vendor-service";
import CustomLabel from "../../../../components/mui/custom-label";
interface outletProps {
    reFetch: () => void;
    refetchServices: () => void;
}

const ManageVendorService = () => {
    const { id } = useParams();
    const { snackbar } = useSnackbar();
    const [searchParam] = useSearchParams();
    const outlet = useOutletContext<outletProps>();
    const hitQuery = (id === "new") ? false : true;
    const navigate = useNavigate();
    const { addVendorService, updateVendorService, getVendorService} = VendorServiceService();
    const service = useQuery({ queryKey: [hitQuery], queryFn: () => getVendorService({ _id: id }), enabled: hitQuery });
    const { handleSubmit, control, setValue, formState: {errors} } = useForm<IVendorServicee>({
        resolver: joiResolver(vendorServiceValidation),
        defaultValues: {
            name: "",
            _category: ""
        }
    });
    useEffect(() => {
        if (id !== "new") {
            if (service.data?.data) {
                setValue("name", service?.data?.data.name);
                setValue("_category", service?.data?.data?._category?._id);
            }
        }
    }, [id, service.data]);

    const onSubmit = async (data: IVendorServicee) => {
        try {
            if (id === "new") {
                const add = await addVendorService(data);
                snackbar(add.message, "info");
                navigate({
                    pathname: "/directory/services",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
                outlet?.refetchServices && outlet.refetchServices();
            } else {
                const payload = { ...data, _id: id};
                const update = await updateVendorService(payload);
                snackbar(update.message, "info");
                navigate({
                    pathname: "/directory/services",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
                outlet?.refetchServices && outlet.refetchServices();
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }
    };

    const onClose = () => {
        navigate({
            pathname: "/directory/services",
            search: searchParam.toString()
        });
    };

    const { getVendorPartialCategory } = VendorCategoryService();

    const categories = useQuery({
      queryKey: ["allUsers"],
      queryFn: () =>
        getVendorPartialCategory({
          type: "SERVICE",
        }),
    });

    const categoriesData = categories?.data?.data;

    const fields: IVendorServiceBasicField[] = [
        {
            label: "Service Name",
            name: "name",
            type: "input",
            placeholder: "Type service name",
            required: true,
        },
        {
            type: "select",
            required: true,
            displayFieldKey: "name",
            storeFieldKey: "_id",
            name: "_category",
            label: "Category",
            placeholder: "Select category",
            children: categoriesData && categoriesData.map((category) => <MenuItem key={category._id} value={category._id} >{capitalize(category.name)}</MenuItem>)
          },
    ];

    return (
        <Box>
            <CustomDialog
                size="xs"
                title={id !== "new" ? "Edit Service" : "Add Service"}
                isOpen={id ? true : false}
                onClose={onClose}
                confirmText={id !== "new" ? "Edit Service" : "Add Service"}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "input") {
                                return (<Grid key={field.label} item xs={12}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => 
                                        <TextField
                                            label={<CustomLabel label={field.label} required={field?.required} />}
                                            className="disable-text"
                                            variant={"outlined"}
                                            size={"small"}
                                            placeholder={field.placeholder}
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            } else{
                                return (<Grid key={field.label} item xs={12} >
                                  <Select
                                    control={control}
                                    className="disable-text"
                                    name={field.name}
                                    label={<CustomLabel label={field.label} required={field?.required} />}
                                    error={!!errors[field.name]}
                                    helperText={errors[field.name]?.message}
                                  >
                                    {field.children}
                                  </Select>
                                </Grid>
                                );
                              }

                        })
                    }
                </Grid>
            </CustomDialog>
        </Box>
    );

};

export default ManageVendorService;