import { IBlogsInsightsResponse } from "../../interfaces/blogs-insights";
import HttpService from "../../services/http";

const BlogsInsightsService = () => {
    const { httpRequest } = HttpService();

    const getBlogsInsights = async (search: object): Promise<IBlogsInsightsResponse> => new Promise((resolve, reject) => {
        httpRequest<IBlogsInsightsResponse>(
            "GET",
            "cms/blog-insights/list",
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });


    return { getBlogsInsights };
};

export { BlogsInsightsService };