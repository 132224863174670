import HttpService from "../http";
import { IBroadcastAnalyticsResponse, IBroadcastInsightResponse, IBroadcastsResponse, IWhatsappTemplateResponse } from "../../interfaces/broadcast";
import { broadcastGroup, lead } from "../endpoints";
import { IBroadcastGroupCountResponse, IBroadcastGroupResponse, IBroadcastGroupResponses } from "../../interfaces/broadcast-Group";
import { ICandidatesResponse, IQuery } from "../../interfaces";

const broadcastGroupService = () => {
    const { httpRequest } = HttpService();
    const addbroadcastGroup = async (payload: object): Promise<IBroadcastGroupResponse> => new Promise((resolve, reject) => {
        httpRequest<IBroadcastGroupResponse>(
            "POST",
            `${broadcastGroup}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const broadCastLeadsCount = async (payload: object): Promise<IBroadcastGroupCountResponse> => new Promise((resolve, reject) => {
        httpRequest<IBroadcastGroupCountResponse>(
            "POST",
            `${broadcastGroup}/total-count-leads`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updatebroadcastGroup = async (payload: object): Promise<IBroadcastGroupResponse> => new Promise((resolve, reject) => {
        httpRequest<IBroadcastGroupResponse>(
            "PUT",
            `${broadcastGroup}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });


    const getbroadcastGroups = async (search: object): Promise<IBroadcastGroupResponses> => new Promise((resolve, reject) => {
        httpRequest<IBroadcastGroupResponses>(
            "GET",
            `${broadcastGroup}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const deletebroadcastGroup = async (query: object): Promise<IBroadcastGroupResponse> => new Promise((resolve, reject) => {
        httpRequest<IBroadcastGroupResponse>(
            "DELETE",
            `${broadcastGroup}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const getbroadcastGroup = async (query: object): Promise<IBroadcastGroupResponse> => new Promise((resolve, reject) => {
        httpRequest<IBroadcastGroupResponse>(
            "GET",
            `${broadcastGroup}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const whatsappTemplates = async (search: object): Promise<IWhatsappTemplateResponse> => new Promise((resolve, reject) => {
        httpRequest<IWhatsappTemplateResponse>(
            "GET",
            `${broadcastGroup}/whatsapp-templates`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getBroadcastEvents = async (search: object): Promise<IBroadcastAnalyticsResponse> => new Promise((resolve, reject) => {
        httpRequest<IBroadcastAnalyticsResponse>(
            "GET",
            `${broadcastGroup}/events`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getBroadcastInsights = async (search: object): Promise<IBroadcastInsightResponse> => new Promise((resolve, reject) => {
        httpRequest<IBroadcastInsightResponse>(
            "GET",
            `${broadcastGroup}/insights`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const resendBroadcast = async (payload: object): Promise<IBroadcastsResponse> => new Promise((resolve, reject) => {
        httpRequest<IBroadcastsResponse>(
            "POST",
            `${broadcastGroup}/resend`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getLeads = async (query: IQuery, payload: object): Promise<ICandidatesResponse> => new Promise((resolve, reject) => {
        httpRequest<ICandidatesResponse>(
            "POST",
            `${lead}/broadcast-leads`,
            payload,
            query
        )
            .then(resolve)
            .catch(reject);
    });

    return { addbroadcastGroup, getbroadcastGroups,broadCastLeadsCount,deletebroadcastGroup,getbroadcastGroup,updatebroadcastGroup, whatsappTemplates, getBroadcastInsights, getBroadcastEvents, resendBroadcast, getLeads };

};

export { broadcastGroupService };