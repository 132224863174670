import HttpService from "../http";
import { IAnalyticsCount, IAnalyticsDayWise } from "../../interfaces";
import { analytics } from "../endpoints";

const AnalyticsService = () => {
    const { httpRequest } = HttpService();
    const getAnalyticsCount = async (): Promise<IAnalyticsCount> => new Promise((resolve, reject) => {
        httpRequest<IAnalyticsCount>(
            "GET",
            `${analytics}/count`,
        )
            .then(resolve)
            .catch(reject);
    });


    const dayWiseAnalytics = async (search: object): Promise<IAnalyticsDayWise> => new Promise((resolve, reject) => {
        httpRequest<IAnalyticsDayWise> (
            "GET",
            analytics,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    return { getAnalyticsCount, dayWiseAnalytics };
};

export default AnalyticsService;
