import { Box, Tab, Tabs } from "@mui/material";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { TabPanel } from "../../../components/shared/mui-tabs";
import ResponseDialogTest from "../../mui/dialog/responseDialogTest";
import { CandidateService, TestSessionService } from "../../../services";
import { useQuery } from "@tanstack/react-query";
import { capitalize, formatDateTime } from "../../../utilities/helper";
import AssessmentResponseResult from "./result";
import ResponseAllPsyQuestions from "./psy-questions";
import ResponseAllTechnicalQuestions from "./coder-byte-questions";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface IState {
  redirectionLinks: string[];
}

const ViewAssessmentResponses: FC = () => {
  const [value, setValue] = useState<number>(0);

  const { leadId } = useParams();

  const {getCandidatePartial} = CandidateService();

  const { getTestSession } = TestSessionService();

  const { testId } = useParams();

  
  const assignedAssessments = useQuery({
      queryKey: ["assigned-assessments"],
      queryFn: () => getTestSession({ _id: testId })
  });

  const candidateDetails = useQuery({
    queryKey: ["candidate"],
    queryFn: () => getCandidatePartial({ _id: leadId })
  });

  const navigate = useNavigate();
  const [searchParam,setSearchParam] = useSearchParams();
  const tab = location.pathname.split("/")[2];
  const [state] = useState<IState>({
    redirectionLinks: ["results", "psy Questions", "technical Questions"],
  });
  
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    searchParam.set("subType", state.redirectionLinks[newValue]);
    setSearchParam(searchParam);
    setValue(newValue);
  };
  
  useEffect(() => {
    const tab = searchParam.get("subType") ? String(searchParam.get("subType")) : "results";
    setValue(state.redirectionLinks.indexOf(tab));
  }, [searchParam]);
  const onClose = () => {
    searchParam.delete("subType");
    if(tab === "interview"){
      navigate(-1);
    }else{
      navigate({
        pathname: `/candidates/${tab}/manage/${leadId}`,
        search: searchParam.toString()
      });
    }
  };
  const name = (candidateDetails &&
    candidateDetails.data &&
    candidateDetails.data.data &&
    candidateDetails.data.data.name)
    ? capitalize(candidateDetails.data?.data.name)
    : "Candidate Detail";

    const completionTime = 
    assignedAssessments && 
    assignedAssessments.data && 
    assignedAssessments.data.data && 
    assignedAssessments.data.data.submittedDate ? (
      <span>
        <span style={{ color:"black" }}>Completed on: </span>
        {formatDateTime(assignedAssessments.data.data.submittedDate)}
      </span>
    ) : (
      "Not Submitted"
    );
  
  return (
    <>
      <ResponseDialogTest
        title={name}
        secondaryText={completionTime}
        isOpen={true}
        onClose={onClose}
        size={"xl"}
        disabled
      >
        <div className="response-tab">
          <Box width="100%">
            <Box sx={{ borderBottom: 1, borderColor: "divider", margin: "0px 10px" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {state.redirectionLinks.map((link, index) => (
                  <Tab
                    key={index}
                    className={(index === 0) ? "tabs-space start-tab-space" : "tabs-space"}
                    label={link.charAt(0).toUpperCase() + link.slice(1)}
                    {...a11yProps(index)}
                  />
                ))}
              </Tabs>
            </Box>
            {state.redirectionLinks.map((link, index) => (
              <TabPanel key={index} value={value} index={index}>
                {link === "results" && <AssessmentResponseResult />}
                {link === "psy Questions" && <ResponseAllPsyQuestions />}     
                {link === "technical Questions" && <ResponseAllTechnicalQuestions />} 
              </TabPanel>
            ))}
          </Box>
        </div>
        </ResponseDialogTest>
    </>
  );
};

export default ViewAssessmentResponses;
