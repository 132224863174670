import { useEffect, useState } from "react";
import { Box, Checkbox } from "@mui/material";
import { ChangeEvent } from "react";
import { FaqService } from "../../../services";
import { useQuery } from "@tanstack/react-query";
import {  IErrorResponse, IPagination } from "../../../interfaces";
import { IFaqRow, IFaqState, IFaq } from "../../../interfaces";
import { capitalize, createIndex } from "../../../utilities/helper";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../../components/header";
import useResource from "../../../hooks/useResource";
import useSnackbar from "../../../hooks/useSnackbar";
import useDebounce from "../../../hooks/useDebounce";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";

const Faq = () => {
  let rows: IFaqRow[] = [];
  const { getFaqs, deleteFaq } = FaqService();
  const { snackbar } = useSnackbar();
  const { resourceAllocate } = useResource();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState<string>("");
  const navigate = useNavigate();
  const { allowPermission } = useResource();
  const [state, setState] = useState<IFaqState>({
    selectAll: [],
    deleteWarning: false,
    multiDeleteWarning: false,
    _faq: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      search: ""
    }
  });

  const getFaqData = useQuery({
    queryKey: ["allfaqs", state.pagination.page, state.filters],
    queryFn: () => getFaqs({
      pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
    })
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (getFaqData.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: getFaqData.data.meta.page,
          totalPages: getFaqData.data.meta.totalPages,
          totalRecords: getFaqData.data.meta.totalRecords
        }
      }));
    }
  }, [getFaqData.data?.meta]);

  useEffect(() => {
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";

    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search
      }
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleDelete = (_faq = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _faq
    }
    ));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteFaq({ _ids: [state._faq ]});
      setState(prevState => ({
        ...prevState,
        selectAll : prevState.selectAll.filter((faq) => faq != state._faq)
      }));
      snackbar(deleted.message, "info");
      handleDelete();
      getFaqData.refetch();
    } catch (error) {
      console.log(error);
    }
  };
  const handleMultiDelete = () => setState(prevState => ({
    ...prevState,
    multiDeleteWarning: !prevState.multiDeleteWarning
  }));

  const onMultiDelete = async () => {
    try {
      const faq = await deleteFaq({ _ids: state.selectAll });
      snackbar(faq.message, "info");
      getFaqData.refetch();
      setState(prevState => ({
        ...prevState,
        multiDeleteWarning: false,
        selectAll: []
      }));
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      handleMultiDelete();
      console.log({ "Error in delete faq": error });
    }
  };

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    let payload: Array<string> = [];
    if (e.target.checked) {
      if (getFaqData.data?.data.length) {
        payload = getFaqData.data?.data
          .filter(faq => allowPermission(faq.createdBy?.name))
          .map(faq => faq._id);
      }
    } else {
      payload = [];
    }

    setState(prevState => ({
      ...prevState,
      selectAll: payload
    }));
  };
  
  const createColumn = (handleSelectAll: (event: ChangeEvent<HTMLInputElement>) => void, isChecked: boolean, isIndeterminateChecked: boolean) => [
    {
      id: "all",
      label: <Checkbox onChange={handleSelectAll} checked={isChecked} indeterminate={isIndeterminateChecked}/>
    },
    {
      id: "id",
      label: "S No."
    },
    {
      id: "faq_title",
      label: "Title"
    },
    {
      id: "faq_engagementType",
      label: "Engagement Type"
    },
    {
      id: "faq_createdBy",
      label: "Created By"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

  const isChecked = (getFaqData.data?.data?.length && state.selectAll?.length === getFaqData.data?.data?.length) ? true : false;
  const isIndeterminateChecked = (state.selectAll.length > 0 && state.selectAll.length < Number(getFaqData.data?.data.length)) ? true : false;
  const columns = createColumn(handleSelectAll, isChecked, isIndeterminateChecked);

  const createRow = (index: number, faq: IFaq, pagination: IPagination, selectedAll: string[],) => {
    const action = <GetActions
      icons={[
        { name: "Edit", method: () => navigate({ pathname: "" + faq._id, search: searchParams.toString() }), disabled: resourceAllocate("faq.write") ? false : true },
        { name: "Delete", method: () => handleDelete(faq._id), disabled: resourceAllocate("faq.remove") ? false : true },
      ]}
    />;

    return {
      all: <Checkbox onChange={e => handleSelect(e, faq._id)} checked={selectedAll.includes(faq._id)}/>,
      id: createIndex(pagination, index),
      faq_title: capitalize(faq.title),
      faq_engagementType: capitalize(faq.type),
      faq_createdBy: capitalize(faq?.createdBy?.name),
      action,
    };
  };

  const handleSelect = (
    e: ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    let payload: Array<string> = [];
    if (e.target.checked) {
      payload = state.selectAll;
      payload.push(id);
    } else {
      payload = state.selectAll.filter((ele) => ele !== id);
    }

    setState(prevState => ({
      ...prevState,
      selectAll: payload
    }));
  };

  if (getFaqData.data?.data.length) {
    rows = getFaqData.data?.data.map((faq, i) => createRow(i, faq, state.pagination, state.selectAll));
  }

  return (
    <>
      {/* Add Data  */}
      <Header
        className='my-2'
        searchPlaceholder="Search by title"
        onSearch={onSearch}
        btnText="ADD FAQ"
        onBtnClick={resourceAllocate("college-recruitment.write") ? () => navigate("new") : undefined}
        onDelete={handleMultiDelete}
        isDeleteDisable={state.selectAll.length ? false : true}
      />

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete FAQ"
        description="Are you sure you want to delete this FAQ?"
      />

      <WarningDialog
        isOpen={state.multiDeleteWarning}
        onClose={() => handleMultiDelete()}
        onConfirm={onMultiDelete}
        title="Delete All FAQs"
        description={`Are you sure you want to delete ${state.selectAll.length} selected faq${state.selectAll.length > 1 ? "s" : ""}?`}
      />

      <Outlet context={{ reFetch: getFaqData.refetch }} />
    </>
  );
};
export default Faq;