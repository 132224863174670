import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ChangeEvent } from "react";
import { Outlet, useNavigate, useSearchParams, useOutletContext } from "react-router-dom";
import { capitalize, createIndex, formatDateTime } from "../../../utilities/helper";
import Header from "../../../components/header";
import useResource from "../../../hooks/useResource";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import useDebounce from "../../../hooks/useDebounce";
import { IBusinessUnit, IBusinessUnitRow, IBusinessUnitState } from "../../../interfaces/configuration/business-unit";
import { BusinessUnitsService } from "../../../services/configuration/business-unit";
import { IPagination } from "../../../interfaces";

interface outletProps {
  refetchBusinessUnits: () => void;
}

const BusinessUnit = () => {
  let rows: IBusinessUnitRow[] = [];
  const { snackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getBusinessUnits, deleteBusinessUnit } = BusinessUnitsService();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IBusinessUnitState>({
    deleteWarning: false,
    _businessUnit: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      search: ""
    }
  });

  const businessUnits = useQuery({
    queryKey: ["all-business-units", state.pagination.page, state.filters],
    queryFn: () => getBusinessUnits({
      pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
    })
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (businessUnits && businessUnits.data && businessUnits.data.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: businessUnits.data.meta.page,
          totalPages: businessUnits.data.meta.totalPages,
          totalRecords: businessUnits.data.meta.totalRecords
        }
      }));
    }
  }, [businessUnits.data?.meta]);


  useEffect(() => {
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";

    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search
      }
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleDelete = (_businessUnit = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _businessUnit
    }
    ));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteBusinessUnit({ _id: state._businessUnit });
      snackbar(deleted.message, "info");
      handleDelete();
      businessUnits.refetch();
      outlet?.refetchBusinessUnits && outlet.refetchBusinessUnits();
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "business_unit_title",
      label: "Title"
    },
    {
      id: "business_unit_createdBy",
      label: "Created By"
    },
    {
      id: "business_unit_createdAt",
      label: "Created At"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

  const createRow = (index: number, businessUnit: IBusinessUnit, pagination: IPagination) => {
    const action = <GetActions
      icons={[
        { name: "Edit", method: () => navigate({ pathname: "" + businessUnit._id, search: searchParams.toString() }), disabled: !resourceAllocate("business-unit.write")},
        { name: "Delete", method: () => handleDelete(businessUnit._id), disabled: !resourceAllocate("business-unit.remove")},
      ]}
    />;

    return {
      id: createIndex(pagination, index),
      business_unit_title: capitalize(businessUnit.name),
      business_unit_createdBy: businessUnit && businessUnit.createdBy ? capitalize(businessUnit.createdBy.name) : "",
      business_unit_createdAt: formatDateTime(businessUnit.createdAt),
      action,
    };
  };
  if (businessUnits && businessUnits.data && businessUnits.data.data && businessUnits.data.data.length) {
    rows = businessUnits.data.data.map((businessUnit, i) => createRow(i, businessUnit, state.pagination));
  }
  return (
    <>
      {/* Add Data  */}
      <Header
        className='my-2'
        searchPlaceholder="Search by title"
        onSearch={onSearch}
        btnText="ADD BUSINESS UNIT"
        onBtnClick={resourceAllocate("business-unit.write") ? () => navigate("new") : undefined}
      />

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Business Unit"
        description="Are you sure you want to delete this business unit?"
      />
      <Outlet context={{ ...outlet, reFetch: businessUnits.refetch }} />
    </>
  );
};
export default BusinessUnit;

