import Joi from "joi";
import { required} from "../../shared";

export const testValidation = Joi.object({
    name: Joi.string()
        .trim()
        .required()
        .label("Name")
        .messages({
            "string.empty": required
        }),
    description: Joi.string()
        .trim()
        .required()
        .label("Description")
        .messages({
            "string.empty": required
        }),
    duration: Joi.number()
        .min(1)
        .required()
        .label("Duration")
});

const minMaxValidator = (max: string, helpers: Joi.CustomHelpers<string>) => {
  const min = helpers.state.ancestors[0].min; 
  if (min >= max) {
    return helpers.error("max.lessThanMin");
  }
  return max;
};

export const categoryValidationSchema = Joi.object({
  name: Joi.string()
    .trim()
    .required()
    .label("Category Name")
    .min(3)
    .max(255),
  low: Joi.object({
    min: Joi.number()
      .min(0)
      .required()
      .label("Start Number"),
    max: Joi.number()
      .min(0)
      .required()
      .label("End Number")
      .custom(minMaxValidator, "min-max validation")
      .messages({ "max.lessThanMin": "End number must be greater than start number" }),
  }),
  moderate: Joi.object({
    min: Joi.number()
      .min(0)
      .required()
      .label("Start Number"),
    max: Joi.number()
      .min(0)
      .required()
      .label("End Number")
      .custom(minMaxValidator, "min-max validation")
      .messages({ "max.lessThanMin": "End number must be greater than start number" }),
  }),
  high: Joi.object({
    min: Joi.number()
      .min(0)
      .required()
      .label("Start Number"),
    max: Joi.number()
      .min(0)
      .required()
      .label("End Number")
      .custom(minMaxValidator, "min-max validation")
      .messages({ "max.lessThanMin": "End number must be greater than start number" }),
  })
});