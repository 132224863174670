import { Menu, Box, Button, Divider } from "@mui/material";
import { FC } from "react";
import useSnackbar from "../../../../hooks/useSnackbar";
import "./style.scss";
interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    jobId: string;
    onClose: () => void;
}

const ShareJob: FC<props> = ({ anchorEl, isOpen, onClose, jobId }) => {
    const { snackbar } = useSnackbar();

    const handleFacebookShare = () => {
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`${process.env.REACT_APP_CAREERS_URL}/jobid-detail?id=${jobId}`)}`;
        window.open(facebookShareUrl, "_blank");
    };

    const handleWhatsappShare = () => {
        const whatsappShareUrl = `https://web.whatsapp.com/send?text=${encodeURIComponent(`${process.env.REACT_APP_CAREERS_URL}/jobid-detail?id=${jobId}`)}`;
        window.open(whatsappShareUrl, "_blank");
    };

    const handleLinkedinShare = () => {
        const linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(`${process.env.REACT_APP_CAREERS_URL}/jobid-detail?id=${jobId}`)}`;
        window.open(linkedinShareUrl, "_blank");
    };


    const onCopy = (label: string) => {
        navigator.clipboard.writeText(label);
        snackbar("Link copy successfully", "info");
    };

    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={onClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            <Box id="shareJob-container">
                <span>

                    <div>
                        <p style={{ fontWeight: 600, marginTop: "10px", paddingLeft: "5px" }}>Job Link</p>
                        <input className="input-container"
                            disabled
                            value={`${process.env.REACT_APP_CAREERS_URL}/apply/${jobId}`}
                        />
                        <div className="input-button">
                            <Button className="copy-button" onClick={() => onCopy(`${process.env.REACT_APP_CAREERS_URL}/apply/${jobId}`)}>COPY</Button>
                            <Button className="open-button" onClick={() => {
                                window.open(`${process.env.REACT_APP_CAREERS_URL}/apply/${jobId}`, "_blank");
                            }}>OPEN</Button>
                        </div>
                    </div>

                    <Divider orientation="horizontal" style={{ marginTop: "40px" }} />
                    <div>
                        <p style={{ fontWeight: 600, marginTop: "20px", paddingLeft: "5px" }}>Share job on Social Media</p>
                        <div className="share-button-container">
                            <button className="facebook-button" onClick={handleFacebookShare} >FACEBOOK</button>
                            <button className="whatsapp-button" onClick={handleWhatsappShare} >WHATSAPP</button>
                            <button className="linkedin-button" onClick={handleLinkedinShare} >LINKEDIN</button>
                        </div>
                    </div>
                </span>
            </Box>
        </Menu>
    );
};

export default ShareJob;
