import Joi from "joi";
import { required } from "../../../shared";

const endDateAfterStartDate = (endDate: string, helpers: Joi.CustomHelpers<string>) => {
    const startDate = helpers.state.ancestors[0].startDate; 
    if (startDate && endDate < startDate) {
        return helpers.error("endDate.afterStartDate");
    }
    return endDate;
};

export const vendorAddContractValidation = Joi.object({
    name: Joi.string()
        .required()
        .trim()
        .min(3)
        .max(40)
        .label("Name")
        .messages({
            "string.empty": required,
        }),
    _category: Joi.string()
        .required()
        .trim()
        .label("Category")
        .messages({
            "string.empty": required,
        }),
    link: Joi.string()
        .required()
        .label("Document")
        .messages({
            "string.empty": required,
        }),
    startDate: Joi.string()
        .required()
        .label("Start Date")
        .messages({
            "string.empty": required,
        }),
    endDate: Joi.string()
        .allow("")
        .optional()
        .label("End Date")
        .custom(endDateAfterStartDate, "End date validation")
        .messages({
            "endDate.afterStartDate" : "End date must be on or after start date" 
        }),
});

export const vendorEditContractValidation = Joi.object({
    name: Joi.string()
        .required()
        .trim()
        .label("Name")
        .messages({
            "string.empty": required,
        }),
    _category: Joi.string()
        .required()
        .trim()
        .label("Category")
        .messages({
            "string.empty": required,
        }),
    link: Joi.string()
        .required()
        .label("Document")
        .messages({
            "string.empty": required,
        }),
    startDate: Joi.string()
        .required()
        .label("Start Date")
        .messages({
            "string.empty": required,
        }),
    endDate: Joi.string()
        .allow("")
        .optional()
        .label("End Date")
        .custom(endDateAfterStartDate, "End date validation")
        .messages({
            "endDate.afterStartDate" : "End date must be on or after start date" 
        }),
});