import "../../../../components/shared/filter/style.scss";
import { FC, useEffect, useState } from "react";
import { Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton, ListItemText, Menu, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { ICpdDriveFilter, IStatus, IUser } from "../../../../interfaces";
import { capitalize, displayName } from "../../../../utilities/helper";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MenuItem } from "../../../../components/shared/filter";
interface props {
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  OnClose: () => void;
}

const DriveFilters: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(state => state.user.list);
  const statusList = useSelector<{ status: { list: IStatus[] } }, IStatus[]>(state => state.status.list);
  const [state, setState] = useState<ICpdDriveFilter>({
    selectedMenu: 0,
    _recruiter: [],
    status: [],
    category: []
  });

  useEffect(() => {
    const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
    const _recruiter: { key: string, value: string }[] = searchParams.get("_recruiter") ? JSON.parse(String(searchParams.get("_recruiter"))) : [];
    const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
    setState(prevState => ({
      ...prevState,
      status,
      _recruiter,
      category,
    }));
  }, [searchParams]);


  const handleLeftListItem = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setState(prevState => ({
      ...prevState,
      selectedMenu: index
    }));
  };


  const handleRightListItem = (name: "status" | "_recruiter" | "category", key: string, value: string) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];

    const isExist = state[name].find(ele => ele.key === key) ? true : false;
    if (isExist) {
      payload = state[name].filter(ele => ele.key !== key);
    } else {
      payload = state[name];
      payload.push({
        key,
        value
      });
    }

    setState(prevState => ({
      ...prevState,
      [name]: payload
    }));
  };

  const deleteChip = (name: "status" | "_recruiter" | "category", key: string) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];

    payload = state[name].filter(ele => ele.key !== key);
    setState(prevState => ({
      ...prevState,
      [name]: payload
    }));
  };

  const resetFilter = () => {
    setState({
      selectedMenu: 0,
      status: [],
      _recruiter: [],
      category: [],
    });
  };

  const onApply = () => {
    searchParams.set("status", JSON.stringify(state.status));
    searchParams.set("_recruiter", JSON.stringify(state._recruiter));
    searchParams.set("category", JSON.stringify(state.category));

    searchParams.set("page", "1");
    navigate({
      pathname: "/cpd/drives",
      search: searchParams.toString()
    });
    OnClose();
  };

  const onClose = () => {
    const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
    const _recruiter: { key: string, value: string }[] = searchParams.get("_recruiter") ? JSON.parse(String(searchParams.get("_recruiter"))) : [];
    const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
    setState(prevState => ({
      ...prevState,
      status,
      _recruiter,
      category,
    }));
    OnClose();
};

  const defaultStatus = [
    {
      key: "CREATED",
      name: "Created"
    },
    {
      key: "INITIATED",
      name: "Initiated"
    },
    {
      key: "CALL_NA",
      name: "Called NA"
    },
    {
      key: "NOT_INTERESTED",
      name: "Not Interested"
    },
    {
      key: "TBC_LATER",
      name: "TBC Later"
    },
  ];

  const category = [
    {
      key: "tier-1",
      name: "Tier 1"
    },
    {
      key: "tier-2",
      name: "Tier 2"
    },
    {
      key: "tier-3",
      name: "Tier 3"
    },
  ];

  return <>
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={isOpen}
      onClose={OnClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <Box id="filters-container">
        <Box className="center mb-3" justifyContent="space-between" alignItems="start">
          <div className="active-filter mb-1">
            {
              (state.status.length || state._recruiter.length || state.category.length) ?
                <>
                  {state.status.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("status", ele.key)} label={ele.value} variant="outlined" />)}
                  {state._recruiter.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("_recruiter", ele.key)} label={ele.value} variant="outlined" />)}
                  {state.category.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("category", ele.key)} label={ele.value} variant="outlined" />)}
                </>
                :
                <Box className="mt-2" display="flex" alignItems="center">
                  <FilterAltOffIcon />
                  <Typography className="ml-2">No filters are applied</Typography>
                </Box>
            }
          </div>
          <IconButton
            onClick={onClose}
            style={{ marginRight: "-10px" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Grid className="filter-box" container>
          <Grid id="left" item xs={5}>
            <List component="nav">

              <MenuItem
                index={0}
                label="Status"
                selectedMenu={state.selectedMenu === 0}
                onChange={handleLeftListItem}
                count={state.status}
              />

              <MenuItem
                index={1}
                label="Assign To"
                selectedMenu={state.selectedMenu === 1}
                onChange={handleLeftListItem}
                count={state._recruiter}
              />

              <MenuItem
                index={2}
                label="Category"
                selectedMenu={state.selectedMenu === 2}
                onChange={handleLeftListItem}
                count={state.category}
              />

            </List>
          </Grid>
          <Divider orientation="vertical" />

          <Grid id="right" item xs={6}>
            <List component="nav">
              {
                state.selectedMenu === 0 &&
                defaultStatus.map(status => <ListItemButton
                  key={status.key}
                  selected={state.status.find(ele => ele.key === status.key) ? true : false}
                  onClick={() => handleRightListItem("status", status.key, status.name)}
                >
                  <ListItemText primary={status.name} />
                  <Checkbox edge="end" checked={state.status.find(ele => ele.key === status.key) ? true : false} />
                </ListItemButton>)
              }
              {
                state.selectedMenu === 0 &&
                statusList
                  .filter(status => status.type === "CPD")
                  .map((status) =>
                    <ListItemButton
                      key={status._id}
                      selected={state.status.find(ele => ele.key === status.name.toUpperCase()) ? true : false}
                      onClick={() => handleRightListItem("status", status.name.toUpperCase(), capitalize(status.name))}
                    >
                      <ListItemText primary={capitalize(status.name)} />
                      <Checkbox edge="end" checked={state.status.find(ele => ele.key === status.name.toUpperCase()) ? true : false} />
                    </ListItemButton>
                  )
              }
              {
                state.selectedMenu === 1 &&
                users.map((user) =>
                  <ListItemButton
                    key={user._id}
                    selected={state._recruiter.find(ele => ele.key === user._id) ? true : false}
                    onClick={() => handleRightListItem("_recruiter", user._id, `${capitalize(displayName(user))}`)}
                  >
                    <ListItemText primary={`${capitalize(displayName(user))}`} />
                    <Checkbox edge="end" checked={state._recruiter.find(ele => ele.key === user._id) ? true : false} />
                  </ListItemButton>
                )
              }
              {
                state.selectedMenu === 2 &&
                category.map(category => <ListItemButton
                  key={category.key}
                  selected={state.category.find(ele => ele.key === category.key) ? true : false}
                  onClick={() => handleRightListItem("category", category.key, category.name)}
                >
                  <ListItemText primary={category.name} />
                  <Checkbox edge="end" checked={state.category.find(ele => ele.key === category.key) ? true : false} />
                </ListItemButton>)
              }
            </List>

          </Grid>
        </Grid>

        <Box className="actions-btn" marginTop="8px" textAlign="end">
          <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
          <Button className="ml-2" onClick={onApply}>Apply</Button>
        </Box>
      </Box>
    </Menu>
  </>;
};

export default DriveFilters;