import { ChangeEvent, useState } from "react";
import ImportFile from "../../../../components/mui/import-candidate";
import useSkillMatrixValidateImport from "./skill-matrix-validation";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import useSnackbar from "../../../../hooks/useSnackbar";
import { ISkillMatrix, IErrorResponse } from "../../../../interfaces";
import ImportFileError from "../../../../components/mui/import-candidate/erros";
import { SkillMatrixService } from "../../../../services";

interface IState {
    fileName: string;
    data: ISkillMatrix[];
    errorMessage: ISkillMatrix[];
    showError: boolean;
}

interface IValidate {
    data: ISkillMatrix[];
    errorMessage: ISkillMatrix[]
}

interface IoutletProps {
    reFetchSkillItemData:() => void;
    reFetchUserSkills: () => void;
}

const ImportSkillMatrix = () => {
    const navigate = useNavigate();
    const outlet = useOutletContext<IoutletProps>();
    const { addSkillMatrixs, getSkillItemSample } = SkillMatrixService();
    const { snackbar } = useSnackbar();
    const { validateSkillMatrixUpload } = useSkillMatrixValidateImport();

    // creating a state to store the value, so that when any changes happen we see on screen
    const {id} = useParams();
    const [state, setState] = useState<IState>({
    // state to store the file name
        fileName: "",
        //state to store the  scuccess data
        data: [],
        // state to check error
        showError: false,
        // state to store the error message
        errorMessage: []
    });

    const closeImport = () => {
        navigate(-1);
    };

    const onUpload = (event: ChangeEvent<HTMLInputElement>) => {
        
        // create a file
        const formData = new FormData();

        //define file type
        const fileType = [
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
        ];
       // get selected file
        const selectedFile = event.target.files ? event.target.files[0] : false;

    
        if (selectedFile && fileType.includes(selectedFile.type)) {
            // append file to form
            formData.append("file", selectedFile);
            
            // create a way to read file
            const reader = new FileReader();

            // read the file and stor in reader
            reader.readAsArrayBuffer(selectedFile);


            reader.onload = async (e) => {
                if (e.target) {

                    // extraxting the array of buffers repersenting the constents of the data file
                    const data = e.target.result;
                    // use the XLSX library to read the data and interpret it as an Excel sheet 
                    const readedData = XLSX.read(data, { type: "binary" });

                    // Get the name of the first sheet in the Excel file
                    const wsname = readedData.SheetNames[0];
                    //Get the data of the first shet
                    const ws = readedData.Sheets[wsname];
                    //  convert the sheet data to two dimensional array of strings and number 
                    let dataParse: (string | number)[][] = XLSX.utils.sheet_to_json(ws, { header: 1 });
                    // removing the header
                    dataParse = dataParse?.slice(1);

                    const skillItemData = await validateSkillMatrixUpload(dataParse) as IValidate;

                    setState(prev => ({
                        ...prev,
                        ...skillItemData,
                        fileName: selectedFile.name
                    }));
                }

            };
        } else {
            snackbar("Upload excel or xls file", "warning");
        }
    };

    const onSubmit = async () => {
        try {
            if (state.data.length) {
                const skillItems = await addSkillMatrixs(state.data);
                snackbar(skillItems.message, "info");
                outlet?.reFetchSkillItemData && outlet.reFetchSkillItemData();
                outlet?.reFetchUserSkills && outlet.reFetchUserSkills();
                navigate(`skill-matrix/manage/${id}`);
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            navigate(`skill-matrix/manage/${id}`);
        }

        if (state.errorMessage.length) {
            setState(prev => ({
                ...prev,
                showError: true
            }));
        } else {
            closeErrorDialog();
        }
    };

    const closeErrorDialog = () => {
        setState(prev => ({
            ...prev,
            fileName: "",
            data: [],
            showError: false,
            errorMessage: []
        }));

        navigate(`skill-matrix/manage/${id}`);
    };

    const onExport = async () => {
        try {
          const response = await getSkillItemSample({_skillParameter:id});
          const byteArray = new Uint8Array(response?.data?.data);
    
          const blob = new Blob([byteArray], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
    
          const file = new File([blob], "skill_item_sample.xlsx", {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
    
          const link = document.createElement("a");
          document.body.appendChild(link);
    
          link.href = window.URL.createObjectURL(file);
          link.download = "skill_item_sample.xlsx";
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error("Error in downloading sample", error);
          snackbar("Error in downloading sample", "error");
        }
      };

    return (
        <>
            <ImportFile
                isOpen={true}
                onClose={closeImport}
                onDownload={onExport}
                onUpload={onUpload}
                onsubmit={onSubmit}
                fileName={state.fileName}
                title="Before you start upload, please make sure:"
                content={[
                    "Download the sample excel file.",
                    "Read all the fields in the 1st row and do not change the text.",
                    "Start uploading the data from 2nd row and onwards.",
                    "In each row, there will be data for one Skill Item.",
                    "All variants are mapped to their field by field title.",
                    "Once sheet is prepared, upload it.",
                    "Now sit back and relax!",
                ]}
            />

            <ImportFileError
                title="Excel file Errors"
                isOpen={state.showError}
                onClose={closeErrorDialog}
                errorMessage={state.errorMessage}
            />
        </>
    );
};

export default ImportSkillMatrix;