import Joi from "joi";
import { required } from "../shared";
export const quarterValidations = Joi.object({
    name:Joi.string()
    .required()
    .label("Title")
    .messages({
        "string.empty":required
    }),

    startDate: Joi.string()
    .label("Start Date")
    .required()
    .messages({
        "string.empty": required,
    }),
      

    endDate:Joi.string()
    .label("End Date")
    .required()
    .messages({
        "string.empty": required,
    }),
});