import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ChangeEvent, MouseEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { IPagination } from "../../../interfaces";
import { Outlet, useNavigate, useSearchParams, useOutletContext } from "react-router-dom";
import { capitalize, createIndex, formatDateTime } from "../../../utilities/helper";
import { IJobPositionRow, IJobPositionState, IJobPosition } from "../../../interfaces";
import Header from "../../../components/header";
import useSnackbar from "../../../hooks/useSnackbar";
import { JobPositionService } from "../../../services";
import CustomTable from "../../../components/mui/table";
import useResource from "../../../hooks/useResource";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import useDebounce from "../../../hooks/useDebounce";
import DepartmentFIlter from "../common-filter";

interface outletProps {
  refetchJobPositions: () => void;
}

const JobPosition = () => {
  let rows: IJobPositionRow[] = [];
  const { snackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getJobPositions, deleteJobPositions } = JobPositionService();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IJobPositionState>({
    deleteWarning: false,
    _jobPosition: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      search: "",
      _department: []
    },
    filterCount: 0,
    filterDialog: {
      anchorEl: null,
      isOpen: false
    }
  });

  const jobPositions = useQuery({
    queryKey: ["allJobPositions", state.pagination.page, state.filters],
    queryFn: () => getJobPositions({
      pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
    })
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (jobPositions.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: jobPositions.data.meta.page,
          totalPages: jobPositions.data.meta.totalPages,
          totalRecords: jobPositions.data.meta.totalRecords
        }
      }));
    }
  }, [jobPositions.data?.meta]);


  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";
    const _department: { key: string; value: string }[] = searchParams.get("_department")
      ? JSON.parse(String(searchParams.get("_department")))
      : [];
    filterCount += _department.length ? 1 : 0;
    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
        _department: _department.map((_department) => _department.key),
      },
      filterCount,
    }));
  }, [searchParams]);

  const openFilter = (e: MouseEvent<HTMLButtonElement>) =>
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        anchorEl: e.currentTarget,
        isOpen: !state.filterDialog.isOpen,
      },
    }));

  const closeFilter = () => {
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false,
      },
    }));
  };

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleDelete = (_jobPosition = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _jobPosition
    }
    ));
  };
  const onDelete = async () => {
    try {
      const deleted = await deleteJobPositions({ _id: state._jobPosition });
      snackbar(deleted.message, "info");
      handleDelete();
      jobPositions.refetch();
      outlet?.refetchJobPositions && outlet.refetchJobPositions();
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "jobPosition_title",
      label: "Title"
    },
    {
      id: "jobPosition_department",
      label: "Department"
    },
    {
      id: "jobPosition_createdBy",
      label: "Created By"
    },
    {
      id: "jobPosition_createdAt",
      label: "Created At"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

  const createRow = (index: number, JobPosition: IJobPosition, pagination: IPagination) => {
    const action = <GetActions
      icons={[
        { name: "Edit", method: () => navigate({ pathname: "" + JobPosition._id, search: searchParams.toString() }), disabled: resourceAllocate("job-position.write") ? false : true },
        { name: "Delete", method: () => handleDelete(JobPosition._id), disabled: resourceAllocate("job-position.remove") ? false : true },
      ]}
    />;

    return {
      id: createIndex(pagination, index),
      jobPosition_title: capitalize(JobPosition.name),
      jobPosition_department: JobPosition._department && JobPosition?._department.name ? capitalize(JobPosition._department.name) : "",
      jobPosition_createdBy: JobPosition?.createdBy?.name && capitalize(JobPosition?.createdBy?.name),
      jobPosition_createdAt: formatDateTime(JobPosition?.createdAt),
      action,
    };
  };
  if (jobPositions.data?.data.length) {
    rows = jobPositions.data?.data.map((JobPosition, i) => createRow(i, JobPosition, state.pagination));
  }
  return (
    <>
      {/* Add Data  */}
      <Header
        className='my-2'
        searchPlaceholder="Search by title"
        onSearch={onSearch}
        btnText="ADD DESIGNATION"
        onBtnClick={resourceAllocate("job-position.write") ? () => navigate("new") : undefined}
        onFilter={openFilter}
        filterCount={state.filterCount}
      >
        <DepartmentFIlter 
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
           OnClose={closeFilter}
        />
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Job-Position"
        description="Are you sure you want to delete this designation?"
      />
      <Outlet context={{ ...outlet, reFetch: jobPositions.refetch }} />
    </>
  );
};
export default JobPosition;

