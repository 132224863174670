import React, { SyntheticEvent, useEffect, KeyboardEvent, ChangeEvent, useState, useRef } from "react";
import { Box, Radio, RadioGroup, FormControlLabel, Button, TextField, MenuItem, Typography, Grid, Chip, Autocomplete, styled, AutocompleteChangeReason, Divider} from "@mui/material";
import { useForm, Controller, FieldError } from "react-hook-form";
import "./ResourceRequestForm.scss";
import SearchSelect from "../../../components/mui/search-select";
import useSnackbar from "../../../hooks/useSnackbar";
import { IResourceField, IResourceRequestFields } from "../../../interfaces/resource-request";
import { IErrorResponse } from "../../../interfaces";
import { ResourceRequestedServices } from "../../../services/resource-request";
import { joiResolver } from "@hookform/resolvers/joi";
import { resourceRequestInput } from "../../../validations";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import EmailSuggestion from "../../../components/mui/email-suggestion";
import { validateEmail } from "../../../validations/shared";
import { useQuery } from "@tanstack/react-query";
import { getresourceFields } from "./resource-fields";
import useResource from "../../../hooks/useResource";
import CustomLabel from "../../../components/mui/custom-label";
const StyledChip = styled(Chip)`
  &&& {
    margin: 5px;
  }`;
const ManageResourceRequest: React.FC = () => {
  const {id} = useParams();
  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (inputRef.current) {
        inputRef.current.focus();
    }
}, []);
  const { control, handleSubmit, watch, getValues, formState: { errors }, setValue, trigger, reset, resetField  } = useForm<IResourceRequestFields>({
    resolver: joiResolver(resourceRequestInput),
    defaultValues: {
      ccList: [],
      jobTitle: "",
      engagementType: "",
      workLocation: "",
      minExp: "",
      maxExp: "",
      openings:"",
      priorityDuration: "",
      durationNeeded: "",
      skillsetRequired: [],
      justification: "",
      hardwareDetails: {    
        purchaseType: "",
        durationOfUse:"",
        name: "",
        payment: {
          amount : "",
          currency: ""
        }},
      softwareDetails: {    
      purchaseType: "",
      durationOfUse: "",
      name: "",
      payment: {
        amount : "",
        currency: ""
      }},
      ccText: ""
    },
  });
  const fieldReset = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
    reset({
      ...getValues(),
      requestType: e.target.value,
      ccList: [],
      jobTitle: "",
      engagementType: "",
      workLocation: "",
      maxExp: "",
      minExp: "",
      openings:"",
      priorityDuration: "",
      durationNeeded: "",
      skillsetRequired: [],
      justification: "",
      hardwareDetails: {},
      softwareDetails: {},
      resourceType: "",
      ccText: ""
    });
  }; 

  const resourceFields = getresourceFields();
  const { getRequestedResource, updateResource } = ResourceRequestedServices();
  const hitQuery = !!id;
  const { resourceAllocate } = useResource();
  const [disappear, setDisappear] = useState(true);
  const [focussedField, setFocussedField] = useState("");
  const requestedResource = useQuery({ queryKey: [hitQuery], queryFn: () => getRequestedResource({ _id: id }), enabled: hitQuery });
  const resourceData = requestedResource && requestedResource.data && requestedResource.data.data ;
  const navigate = useNavigate();
  const { addRequestedResource } = ResourceRequestedServices();
  const [searchParams] = useSearchParams();
  const hasrequirement = watch("hasrequirement");
  const { snackbar } = useSnackbar();
  const requestType = watch("requestType");
  const humanResource = [ "jobTitle", "engagementType", "workLocation", "minExp", "maxExp", "durationNeeded", "skillsetRequired","resourceType", "openings" ];
  const onSubmit = async(data: IResourceRequestFields) => {
    try {
    const payload = { ...data };
    switch ( getValues("requestType") ) {
      case "human":
        delete payload.softwareDetails;
        delete payload.hardwareDetails;
        break;
      case "hardware":
        humanResource.forEach((fields)=>delete payload[fields as keyof typeof payload]);
        delete payload.softwareDetails;
        break;
      case "software":
        humanResource.forEach((fields)=>delete payload[fields as keyof typeof payload]);
        delete payload.hardwareDetails;
        break;
      default:
        humanResource.forEach((fields)=>delete payload[fields as keyof typeof payload]);
        delete payload.hardwareDetails;
        delete payload.softwareDetails;
        break;
      }
    delete payload.hasrequirement;
    delete payload.ccText;
    const add = id ? await updateResource({_id: id , ...payload}) : await addRequestedResource(payload);
    reset();
    navigate({
      pathname: "/resource-requested/requests",
      search: searchParams.toString()
    });
    snackbar(add.message, "info");
  } catch (error) {
    const err = error as IErrorResponse;
    snackbar(err.data.message, "warning"); 
  }
  };
  const onCancelNew = ()=>{
    setDisappear(true);
    reset({
      hasrequirement: "true",
    });
  };
  const onCancelEdit = ()=>{
    navigate({ pathname: "/resource-requested/requests", search: searchParams.toString()});
  };

  const removeEmail = (key: string, value: string) => {
    if (key === "ccText") {
        let payload = getValues("ccList");
        payload = payload && payload.filter(email => email !== value);
        setValue("ccList", payload);
        trigger("ccList");
    } 
};

const handleKeyDown = (event : React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === "Enter") {
      event.preventDefault();
  }
};

useEffect(() => {
  id && setValue("hasrequirement", "true");  
  if (resourceData) {
    const candidateInfo = resourceData;
    const keys = [ "jobTitle", "engagementType", "workLocation", "minExp", "maxExp","openings", "durationNeeded", "skillsetRequired", "softwareDetails", "hardwareDetails", "requestType", "justification", "ccList", "resourceType", "priorityDuration", "softwareDetails.purchaseType", "softwareDetails.name", "softwareDetails.paymentAmount","softwareDetails.durationOfUse","hardwareDetails.name", "hardwareDetails.purchaseType", "hardwareDetails.paymentAmount", "hardwareDetails.durationOfUse" ];
    keys.forEach(key => {
      switch (key) {
        case "jobTitle":
          setValue("jobTitle", candidateInfo?.jobTitle ?? "");
          break;
        case "engagementType":
          setValue("engagementType", candidateInfo?.engagementType ?? "");
          break;
        case "workLocation":
          setValue("workLocation", candidateInfo?.workLocation ?? "");
          break;
        case "minExp":
          setValue("minExp", candidateInfo?.minExp ?? "");
          break;
          case "maxExp":
            setValue("maxExp", candidateInfo?.maxExp ?? "");
            break;
        case "openings":
          setValue("openings", candidateInfo?.openings ?? "");
          break;  
        case "durationNeeded":
          setValue("durationNeeded", candidateInfo?.durationNeeded ?? "");
          break;
        case "skillsetRequired":
          setValue("skillsetRequired", candidateInfo?.skillsetRequired ?? []);
          break; 
        case "hardwareDetails":
          setValue("hardwareDetails", candidateInfo?.hardwareDetails);
          break; 
        case "softwareDetails":
          setValue("softwareDetails", candidateInfo?.softwareDetails);
          break; 
        case "hasRequirement":
          setValue("hasrequirement", "true");
          break;
        case "resourceType":
          setValue("resourceType", candidateInfo?.resourceType);
          break;     
        case "requestType":
          setValue("requestType", candidateInfo?.requestType ?? "");
          break;
        case "justification":
          setValue("justification", candidateInfo?.justification ?? "");
          break;
        case "ccList":
          setValue("ccList", candidateInfo?.ccList ?? []);
          break;  
        case "priorityDuration":
          setValue("priorityDuration", candidateInfo?.priorityDuration ?? "");
          break;                   
      }
      
    });
  }
}, [resourceData]);

 useEffect(() => {
  const subscription = watch((value, { name }) => {
      if (name && ["ccText"].includes(name)) {
          trigger(name);
      }
      if (name && ["ccList"].includes(name)) {
        trigger(name);
    }
  });
  return () => subscription.unsubscribe();
}, [watch]);

 const types = [{name: "Human Resource", value: "human"}, {name: "Hardware", value: "hardware"}, {name: "Software", value: "software"}];

  const handleAutocompleteDelete = (match: string, field: "ccList" | "skillsetRequired") => {
    const emailData = getValues(field);
    const newData = emailData && emailData.filter((email) => email !== match);
    setValue(field, newData);
  };

  const addEmail = (e: KeyboardEvent<HTMLDivElement>, key: string) => {
    let payload: string[] = [];
    if (key === "ccText") {
       e.preventDefault(); 
        const err = validateEmail(getValues(key));
        if (err.error) {
          console.log(err,"err");
            return;
        }
        const enteredEmail = getValues(key) ? String(getValues(key)) : "";
        if (enteredEmail.trim() !== "" && e.key === "Enter") {
            if (key === "ccText") {
                const prev = getValues("ccList") ? getValues("ccList") : [];
                payload = [...prev , enteredEmail];
                setValue("ccList", [...new Set(payload)]);
                resetField(key);
            }
        }
    }
};

const handleEmailSelect = (email: string, name: string) => {
  let payload: string[] = [];
    if (["ccText"].includes(name)) {
      if (name === "ccText") {
          const prev = getValues("ccList") ? getValues("ccList") : [];
          payload = [...prev, email];
          setValue("ccList", [...new Set(payload)]);
          resetField(name);
      }
  }
};

const onChangeAutoComplete = ( event: SyntheticEvent<Element, Event>, value: string[], reason: AutocompleteChangeReason, field: "ccList" | "skillsetRequired" ) => {
    const data = getValues(field);
    const isExist = data && data.includes(value[value.length - 1]);
    switch (reason) {
      case "selectOption":
        if (!isExist) {
          data && data.push(value[value.length - 1]);
          setValue(field, data);
        }
        break;

        case "createOption":
          if (!isExist) {
            data && data.push(value[value.length - 1]);
            setValue(field, data);
          }
          break;
      default:
        setValue(field, []);
        break;
    }
    trigger(field);
  };

  const handleKeyDownAutocomplete = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;
    
    if (event.key === " ") {
      event.preventDefault();
    }
    
    if (input.value.length >= 30 && event.key !== "Backspace" && event.key !== "Delete") {
      event.preventDefault();
    }
  };

  const handleNumericInput = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (value: string) => void
  ) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement | HTMLTextAreaElement;
    const inputValue = target.value;
    const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
    target.value = sanitizedValue;
    onChange(sanitizedValue);
  };
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} className="resource-request-form" onKeyDown={handleKeyDown}>
    {!id && (
      <div className="form-header">
      <Box mt={2} mb={1}>
    <Typography display="block">Do you have any Resource Requirement?</Typography>
    </Box>
    <Controller
          name="hasrequirement"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field} row>
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="" control={<Radio />} label="No" />
            </RadioGroup>
          )}
        />
      </div>)}
      {!id && hasrequirement && disappear && (
        <Grid container spacing={2} mb={2}>
          <Grid item xs={4}>
            <Controller
              name="requestType"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  disabled= {!!id}
                  label={<CustomLabel label = "Type of Resource"  required= {true}/>}
                  size="medium"
                  onChange={(e) => {
                    field.onChange(e);                    
                    setValue(field.name, e.target.value);
                    fieldReset(e);
                    setDisappear(false);
                  }}
                >
                {types.map((item)=> (
                  <MenuItem key={item.value} value={item.value}>
                  {item.name}
                  </MenuItem>
                ))}
                </TextField>
              )}
            />
          </Grid>
        </Grid>
      )}
      {hasrequirement && (
         <><div className="form-fields">
          {hasrequirement && (["human"].includes(requestType ?? "")) && (
            <Grid container spacing={2} mb={2}>        
              {hasrequirement && requestType === "human" && (
            <>
      {(resourceFields.teamMemberFields as IResourceField[]).map((field) => (
        <Grid item xs={4} key={field.name}>
          {(() => {
            if (field.name === "ccText") {
              return (
                <Controller
                  name="ccText"
                  control={control}
                  render={() => (
                    <>
                      <EmailSuggestion
                        name="ccText"
                        label=<CustomLabel label="Notify" required= {true}/>
                        disabled = {!!id}
                        control={control}
                        value={getValues("ccText")}
                        placeholder="Enter CC List"
                        error={!!errors.ccList}
                        helperText={errors["ccList"] && errors["ccList"].message}
                        onEmailSelect={handleEmailSelect}
                        onKeyUp={addEmail}
                        size="medium"
                      />
                      <Box mt={1}>
                        {(getValues("ccList") || []).map((ccEmail) => (
                          <StyledChip
                            className="styled-chip"
                            disabled= {!!id}
                            key={ccEmail}
                            label={ccEmail}
                            color="primary"
                            variant="outlined"
                            onDelete={() => removeEmail("ccText", ccEmail)}
                          />
                        ))}
                      </Box>
                    </>
                  )}
                />
              ); 
            } 
            else if (field.name === "jobTitle") {
              return (
            <SearchSelect
            name={field.name}
            size={"medium"}
            label={<CustomLabel label={field.label} required={field?.required} />}
            error={!!errors[field.name]}
            helperText={errors[field.name]?.message}
            options={field.children}
            displayFieldKey={field.displayFieldKey ? field.displayFieldKey : ""}
            storeFieldKey={field.storeFieldKey ? field.storeFieldKey : ""}
            displayUserName={field.displayUserName}
            capitalize={field.capitalize}
            trigger={trigger}
            setValue={setValue}
            getValues={getValues}
            disabled = {!!id}
            />);
            } else if (field.name === "skillsetRequired") {
              return (
                <Controller
                  name="skillsetRequired"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Autocomplete
                        {...field}
                        options={[]}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=<CustomLabel label="Skills Required" required={true}/>
                            disabled= {!!id}
                            variant="outlined"
                            inputRef={inputRef}
                            placeholder="Enter a skill*"
                            error={!!errors.skillsetRequired}
                            helperText={
                              errors.skillsetRequired &&
                              errors.skillsetRequired.message
                            }
                            size={"medium"}
                            onKeyDown={handleKeyDownAutocomplete}
                          />
                        )}
                        value={field.value || []}
                        onChange={(e, value, reason) => onChangeAutoComplete(e, value, reason, field.name)}
                        multiple
                        freeSolo
                        disabled = {!!id}
                        renderTags={() => null}
                      />
                      {getValues("skillsetRequired")?.map((skillSet) => (
                        <Chip
                          className="styled-chip"
                          disabled= {!!id}
                          key={skillSet}
                          label={skillSet}
                          color="primary"
                          variant="outlined"
                          onDelete={() => handleAutocompleteDelete(skillSet, field.name)}
                        />
                      ))}
                    </>
                  )}
                />
              );
            } else {
              return (
                <Controller
                  name={field.name}
                  control={control}
                  render={({ field: controllerField }) => (
                    field.type === "select" ? (
                          <TextField
                            {...controllerField}
                            select
                            label=<CustomLabel label={field.label} required={field.required}/>

                            disabled={!!id}
                            onChange={(e) => {
                              if (field.name === "requestType") {
                                fieldReset(e);
                              } else {
                                setValue(field.name, e.target.value);
                                trigger(field.name);
                              }
                            }}
                            placeholder={field.placeholder}
                            error={!!(errors[field.name as keyof typeof errors])}
                            helperText={errors[field.name as keyof typeof errors]?.message}
                            size={"medium"}
                            onFocus= {() => {
                              setFocussedField(field.name);
                          }}
                          onBlur={() => {
                              setFocussedField("");
                          }}
                            InputLabelProps={{
                              shrink: focussedField === field.name || !!controllerField.value || !!getValues(field.name)
                            }}

                          >
                            {field.children}
                          </TextField>
                    ) : (
                      <TextField
                        {...controllerField}
                        className="form-field"
                        label=<CustomLabel label={field.label} required={field.required}/>
                        placeholder={field.placeholder}
                        disabled = {!!id}
                        multiline
                        error={!!(errors[field.name as keyof typeof errors])}
                        helperText={errors[field.name as keyof typeof errors]?.message}
                        size={"medium"}
                        inputProps={
                          (() => {
                            if (["durationNeeded", "priorityDuration", "minExp", "maxExp", "openings"].includes(field.name)) {
                              return {
                                pattern: "[0-9]*",
                                inputMode: "numeric",
                                onInput: (e) => handleNumericInput(e, controllerField.onChange),
                                maxLength: 6
                              };
                            } else if (field.name === "justification") {
                              return {
                                maxLength: 100,
                                onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                                  const value = e.target.value;
                                  if (value.startsWith(" ")) {
                                    e.target.value = value.trimStart();
                                  }
                                }
                              };
                            } else {
                              return {};
                            }
                          })()
                        }
                      />
                    )
                  )}
                />
              );
            }
          })()}
        </Grid>
      ))}
          </>
        )}

            </Grid>
          )}
          {hasrequirement && (["hardware"].includes(requestType ?? "")) && (
            <Grid container spacing={2}>
          {(["hardware"].includes(requestType ?? "")) && (
            <>
              {(resourceFields.hardwareFields as IResourceField[]).map(field => (
                <Grid item xs={4} key={field.name}>
                  {(() => {
                    if (field.name === "ccText") {
                      return (
                        <Controller
                          name="ccText"
                          control={control}
                          render={() => (
                            <>
                              <EmailSuggestion
                                name="ccText"
                                label=<CustomLabel label="Notify" required={true}/>                        
                                disabled = {!!id}
                                control={control}
                                value={getValues("ccText")}
                                placeholder="Enter CC List"
                                error={!!errors.ccList}
                                helperText={errors["ccList"] && errors["ccList"].message}
                                onEmailSelect={handleEmailSelect}
                                onKeyUp={addEmail}
                                size="medium"
                              />
                              <Box mt={1}>
                                {(getValues("ccList") || []).map((ccEmail) => (
                                  <StyledChip
                                    className="styled-chip"
                                    disabled= {!!id}
                                    key={ccEmail}
                                    label={ccEmail}
                                    color="primary"
                                    variant="outlined"
                                    onDelete={() => removeEmail("ccText", ccEmail)}
                                  />
                                ))}
                              </Box>
                            </>
                          )}
                        />
                      );
                    } else {
                      return (
                        <Controller
                          name={field.name}
                          control={control}
                          render={({ field: controllerField }) => {
                            if (field.type === "select") {
                              return (
                                <TextField
                                  {...controllerField}
                                  select
                                  label={<CustomLabel label={field && field.label} required={field && field.required}/>}                                                         
                                  disabled={!!id}
                                  onChange={(e) => {
                                    if (field.name === "requestType") {
                                      fieldReset(e);
                                    } else {
                                      setValue(field.name, e.target.value);
                                    }
                                  }}
                                  placeholder={field.placeholder}
                                  error={
                                    ["justification"].includes(field.name)
                                      ? !!(errors[field.name as keyof typeof errors])
                                      : !!(field.error && errors.hardwareDetails?.[field.error as keyof typeof errors.hardwareDetails])
                                  }
                                  helperText={
                                    ["justification"].includes(field.name)
                                      ? errors[field.name as keyof typeof errors]?.message
                                      : (field.error && errors.hardwareDetails?.[field.error as keyof typeof errors.hardwareDetails]
                                        ? (errors.hardwareDetails[field.error as keyof typeof errors.hardwareDetails] as FieldError).message || undefined
                                        : undefined)
                                  }
                                  size={"medium"}
                                >
                                  {field.children}
                                </TextField>
                              );
                            } else {
                              return (
                                <TextField
                                  {...controllerField}
                                  className="form-field"
                                  disabled= {!!id}
                                  label={<CustomLabel label={field.label} required={field.required}/>}                                                         
                                  placeholder={field.placeholder}
                                  error={
                                    ["justification"].includes(field.name)
                                      ? !!(errors[field.name as keyof typeof errors])
                                      : !!(field.error && errors.hardwareDetails?.[field.error as keyof typeof errors.hardwareDetails])
                                  }
                                  helperText={
                                    ["justification"].includes(field.name)
                                      ? errors[field.name as keyof typeof errors]?.message
                                      : (field.error && errors.hardwareDetails?.[field.error as keyof typeof errors.hardwareDetails]
                                        ? (errors.hardwareDetails[field.error as keyof typeof errors.hardwareDetails] as FieldError).message || undefined
                                        : undefined)
                                  }
                                  size={"medium"}
                                  inputProps={
                                    (() => {
                                      if (["hardwareDetails.payment.amount", "hardwareDetails.durationOfUse", "priorityDuration"].includes(field.name)) {
                                        return {
                                          pattern: "[0-9]*",
                                          inputMode: "numeric",
                                          onInput: (e) => handleNumericInput(e, controllerField.onChange),
                                          maxLength: 6
                                        };
                                      } else if (field.name === "justification") {
                                        return {
                                          maxLength: 100,
                                          onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                                            const value = e.target.value;
                                            if (value.startsWith(" ")) {
                                              e.target.value = value.trimStart();
                                            }
                                          }
                                        };
                                      }  else if (field.name === "hardwareDetails.name") {
                                        return {
                                          maxLength: 50,
                                          onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                                            const value = e.target.value;
                                            if (value.startsWith(" ")) {
                                              e.target.value = value.trimStart();
                                            }
                                          }
                                        };
                                       } else {
                                        return {};
                                      }
                                    })()
                                  }
                                />
                              );
                            }
                          }}
                        />
                      );
                    }
                  })()}
                </Grid>
              ))}
            </>
          )}
           </Grid>
          )}
          {hasrequirement && (["software"].includes(requestType ?? "")) && (
            <Grid container spacing={2}>
          {(["software"].includes(requestType ?? "")) && (
            <>
              {(resourceFields.softwareFields as IResourceField[]).map((field) => (
                <Grid item xs={4} key={field.name}>
                  {(() => {
                    if (field.name === "ccText") {
                      return (
                        <Controller
                          name="ccText"
                          control={control}
                          render={() => (
                            <>
                              <EmailSuggestion
                                name="ccText"
                                label=<CustomLabel label="Notify" required={true}/>
                                disabled = {!!id}
                                control={control}
                                value={getValues("ccText")}
                                placeholder="Enter CC List"
                                error={!!errors.ccList}
                                helperText={errors["ccList"] && errors["ccList"].message}
                                onEmailSelect={handleEmailSelect}
                                onKeyUp={addEmail}
                                size="medium"
                              />
                              <Box mt={1}>
                                {(getValues("ccList") || []).map((ccEmail) => (
                                  <StyledChip
                                    className="styled-chip"
                                    disabled= {!!id}
                                    key={ccEmail}
                                    label={ccEmail}
                                    color="primary"
                                    variant="outlined"
                                    onDelete={() => removeEmail("ccText", ccEmail)}
                                  />
                                ))}
                              </Box>
                            </>
                          )}
                        />
                      );
                    } else {
                      return (
                        <Controller
                          name={field.name}
                          control={control}
                          render={({ field: controllerField }) => {
                            if (field.type === "select") {
                              return (
                                <TextField
                                  {...controllerField}
                                  select
                                  disabled={!!id}
                                  label={<CustomLabel label={field.label} required={field.required}/>}                                                         
                                  onChange={(e) => {
                                    if (field.name === "requestType") {
                                      fieldReset(e);
                                    } else {
                                      setValue(field.name, e.target.value);
                                    }
                                  }}
                                  placeholder={field.placeholder}
                                  error={!!(field.error && errors.softwareDetails?.[field.error as keyof typeof errors.softwareDetails])}
                                  helperText={
                                    field.error && errors.softwareDetails?.[field.error as keyof typeof errors.softwareDetails]
                                      ? (errors.softwareDetails[field.error as keyof typeof errors.softwareDetails] as FieldError).message || undefined
                                      : undefined
                                  }
                                  size={"medium"}
                                >
                                  {field.children}
                                </TextField>
                              );
                            } else {
                              return (
                                <TextField
                                  {...controllerField}
                                  className="form-field"
                                  disabled= {!!id}
                                  label={<CustomLabel label={field.label} required={field.required}/>}                                                         
                                  placeholder={field.placeholder}
                                  error={
                                    ["justification"].includes(field.name)
                                      ? !!(errors[field.name as keyof typeof errors])
                                      : !!(field.error && errors.softwareDetails?.[field.error as keyof typeof errors.softwareDetails])
                                  }
                                  helperText={
                                    ["justification"].includes(field.name)
                                      ? errors[field.name as keyof typeof errors]?.message
                                      : field.error && errors.softwareDetails?.[field.error as keyof typeof errors.softwareDetails]
                                      ? (errors.softwareDetails[field.error as keyof typeof errors.softwareDetails] as FieldError).message || undefined
                                      : undefined
                                  }
                                  size={"medium"}
                                  inputProps={
                                    (() => {
                                      if (["softwareDetails.payment.amount", "softwareDetails.durationOfUse", "priorityDuration"].includes(field.name)) {
                                        return {
                                          pattern: "[0-9]*",
                                          inputMode: "numeric",
                                          onInput: (e) => handleNumericInput(e, controllerField.onChange),
                                          maxLength: 6
                                        };
                                      } else if (field.name === "justification") {
                                        return {
                                          maxLength: 100,
                                          onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                                            const value = e.target.value;
                                            if (value.startsWith(" ")) {
                                              e.target.value = value.trimStart();
                                            }
                                          }
                                        };
                                      } else if (field.name === "softwareDetails.name") {
                                        return {
                                          maxLength: 50,
                                          onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                                            const value = e.target.value;
                                            if (value.startsWith(" ")) {
                                              e.target.value = value.trimStart();
                                            }
                                          }
                                        };
                                       } else {
                                        return {};
                                      }
                                    })()
                                  }
                                />
                              );
                            }
                          }}
                        />
                      );
                    }
                  })()}
                </Grid>
              ))}
            </>
          )}
            </Grid>
          )}
        </div>
        </>
      )}
      <Box className={!id ? "form-actions-start" : "action-box"}>
            { !!id && <Divider sx={{ marginBottom: "10px" }} />}
            {(["human", "hardware","software"].includes(requestType ?? "")) && hasrequirement && (
              <Button variant="outlined" color="primary" onClick={id ? onCancelEdit : onCancelNew}>
                Cancel
              </Button>
            )}
              <Button type="submit" variant="contained" color="primary" 
                 disabled = {(!(["human", "hardware", "software"].includes(requestType ?? "") 
                 && hasrequirement )|| !!id || !(resourceAllocate("resource-request.write")))}>
                Submit
              </Button>
          </Box>
          </Box>
  );
};

export default ManageResourceRequest;
