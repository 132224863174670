import { ICmsKeywordResponse, IKeywordSampleResponse } from "../../../../interfaces";
import { keyword } from "../../../endpoints";
import HttpService from "../../../http";

const KeywordImportService = () => {

    const { httpRequest } = HttpService();
    const getKeywordSample = async (search: object): Promise<IKeywordSampleResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IKeywordSampleResponse>(
                "GET",
                `${keyword}/sample`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });

    const importKeyword = async (payload: object): Promise<ICmsKeywordResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<ICmsKeywordResponse>(
                "POST",
                `${keyword}/import`,
                payload
            )
                .then(resolve)
                .catch(reject);
        });

    return { getKeywordSample, importKeyword };

};

export { KeywordImportService };