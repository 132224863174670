import HttpService from "../../http";
import { IVendorResponse, IVendorsResponse } from "../../../interfaces/vendor";
import { IVendorContractResponse, IVendorContractsResponse } from "../../../interfaces/vendor/contract-details";
import { vendorContract } from "../../endpoints";

const VendorContractService = () => {
    const { httpRequest } = HttpService();

    const addContract = async (payload: object): Promise<IVendorsResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsResponse>(
            "POST",
            `${vendorContract}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updateContract = async (payload: object): Promise<IVendorResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorResponse>(
            "PUT",
            `${vendorContract}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteContract = async (search: object): Promise<IVendorResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorResponse>(
            "DELETE",
            `${vendorContract}`,
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getContracts = async (search: object): Promise<IVendorContractsResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorContractsResponse>(
            "GET",
            `${vendorContract}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getContract = async (search: object): Promise<IVendorContractResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorContractResponse>(
            "GET",
            `${vendorContract}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    return { addContract, updateContract, deleteContract, getContract, getContracts };
};

export { VendorContractService };