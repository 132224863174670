import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { joiResolver } from "@hookform/resolvers/joi";
import { Controller, useForm } from "react-hook-form";
import { ParameterService } from "../../../../services";
import { parameterValidation } from "../../../../validations";
import { Box, Grid, MenuItem, TextField } from "@mui/material";
import { IParameterField, IParameter, IErrorResponse } from "../../../../interfaces";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import Select from "../../../../components/mui/select";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import { capitalize } from "../../../../utilities/helper";
interface outletProps {
  reFetch: () => void
}

const ManageParameter = () => {
  const { id } = useParams();
  const [searchParam] = useSearchParams();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const hitQuery = (id === "new" || id === "view") ? false : true;
  const navigate = useNavigate();
  const { addParameters, getParameter, updateParameter } = ParameterService();
  const parameter = useQuery({ queryKey: [hitQuery], queryFn: () => getParameter({ _id: id }), enabled: hitQuery });
  const { handleSubmit, control, setValue, formState: { errors } } = useForm<IParameter>({
    resolver: joiResolver(parameterValidation),
    defaultValues: {
      name: "",
      type: "technical",
      fieldType: "input",
    }
  });

  useEffect(() => {
    if (id === "new") {
      console.log("new");
    } else {
      if (parameter.data?.data) {
        setValue("name",capitalize( parameter.data.data?.name) || "");
        setValue("type", parameter.data.data?.type || "");
        setValue("fieldType", parameter.data.data?.fieldType || "");
      }
    }
  }, [id, parameter.data]);

  const onSubmit = async (data: IParameter) => {
    try {
      if (id === "new") {
        const payload = { ...data };
        const add = await addParameters(payload);
        snackbar(add.message, "info");
        navigate("/configurations/parameters");
        outlet?.reFetch && outlet.reFetch();
      } else {
        const payload = { ...data, _id: id };
        const update = await updateParameter(id ? id : "", payload);
        snackbar(update.message, "info");
        navigate({
          pathname: "/configurations/parameters",
          search: searchParam.toString()
        });
        outlet?.reFetch && outlet.reFetch();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log(error);
    }
  };

  const onClose = () => {
    navigate({
      pathname: "/configurations/parameters",
      search: searchParam.toString()
    });
  };

  const fields: IParameterField[] = [
    {
      label: "Name*",
      name: "name",
      type: "input",
      placeholder: "Type your parameter here",
    },
    {
      label: "Parameter Type*",
      name: "type",
      type: "select",
      children: [
        <MenuItem key={"technical"} value="technical">Technical</MenuItem>,
        <MenuItem key={"non-technical"} value="non-technical">Non-Technical</MenuItem>,
      ]
    },
    {
      label: "Field Type*",
      name: "fieldType",
      type: "select",
      children: [
        <MenuItem key={"input"} value="input">Input</MenuItem>,
        <MenuItem key={"checkbox"} value="checkbox">Checkbox</MenuItem>,
      ]
    },

  ];

  return (
    <Box>
      <CustomDialog
        title={id !== "new" ? "Edit Parameter" : "New Parameter"}
        isOpen={id ? true : false}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4}>
          {
            fields.map(field => {
              if (field.type === "input") {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <TextField
                      label={field.label}
                      className="disable-text"
                      variant="outlined"
                      size="small"
                      placeholder={field.placeholder}
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                      {...prop.field}
                    />}
                  />
                </Grid>
                );
              } else {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <Select
                    control={control}
                    name={field.name}
                    label={field.label}
                    size="small"
                    variant="outlined"
                    error={errors[field.name] ? true : false}
                    helperText={errors[field.name]?.message}
                  >
                    {field.children}
                  </Select>
                </Grid>
                );
              }
            })
          }
        </Grid>
      </CustomDialog>
    </Box>
  );
};

export default ManageParameter;