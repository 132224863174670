import { IJob } from "../../../../interfaces";
import { LOAD_JOB } from "../../../actions";

interface IAction {
    type: "LOAD_JOB",
    payload: IJob
}

interface IState {
    list?: IJob[]
}

const initialState: IState = {
    list: []
};

const jobReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case LOAD_JOB: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
};

export { jobReducer };
