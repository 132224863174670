import { ITeam } from "../../../../interfaces";
import { LOAD_TEAM } from "../../../actions";

interface IAction {
    type: "LOAD_TEAM",
    payload: ITeam
}

interface IState {
    list: ITeam[]
}

const initialState: IState = {
    list: [
        {
            createdAt: "",
            name: "",
            _id: "",
            createdBy: {
                name: ""
            }
        }
    ]
};

const teamReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case LOAD_TEAM: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
};

export { teamReducer };
