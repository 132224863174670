import "./style.scss";
import { Box, Tab, Tabs } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SyntheticEvent, useEffect, useState } from "react";
import { BootstrapDialog, BootstrapDialogTitle, TabPanel, a11yProps } from "../../../../components/shared/mui-tabs";
import DialogContent from "@mui/material/DialogContent";
import AddContactPersonDetail from "./contact-person";
import AddCollege from "./add-basic-detail";
import { joiResolver } from "@hookform/resolvers/joi";
import { collegeValidation, ContactPersonValidation } from "../../../../validations";
import { useForm } from "react-hook-form";
import { ICollege, IContactPersonDetail } from "../../../../interfaces";

const redirectionLinks = ["college-detail", "contact-person"];

const AddCollegeLayout = () => {
    const navigate = useNavigate();
    const [values, setValues] = useState(0);
    const [searchParam, setSearchParam] = useSearchParams();
    useEffect(() => {
        const tab = searchParam.get("type") ? String(searchParam.get("type")) : "college-detail";
        setValues(redirectionLinks.indexOf(tab));
    }, [searchParam]);

    const { handleSubmit, getValues, trigger, resetField, reset, watch, control, setValue, formState: { errors } } = useForm<ICollege>({
        resolver: joiResolver(collegeValidation),
        defaultValues: {
            name: "",
            city: "",
            state: "",
            emailText: "",
            status: "",
            remark: "",
            duration: "",
            helpline: "",
            shortName: "",
            offerRule: "",
            mobileNumber: "",
            linkedInPage: "",
            facebookPage: "",
            category: "tier-1",
            secondaryNumber: "",
            internshipStartMonth: "",
            image: "",
        }
    });

    const { handleSubmit: ContactPersonhandleSubmit, control: ContactPersoncontrol, getValues: ContactPersongetValues, setValue: ContactPersonsetValue, trigger: ContactPersontrigger, resetField: ContactPersonresetField, reset: ContactPersonreset, formState: { errors: ContactPersonerrors } } = useForm<IContactPersonDetail>({
        resolver: joiResolver(ContactPersonValidation),
        defaultValues: {
            name: "",
            emailText: "",
            number: "",
            designation: "",
            notes: "",
        }
    });

    const [tableData, setTableData] = useState<IContactPersonDetail[]>([]);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        searchParam.set("type", redirectionLinks[newValue]);
        setSearchParam(searchParam);
    };

    const onClose = () => {
        searchParam.delete("type");
        navigate({
            pathname: "/cpd/college",
            search: searchParam.toString()
        });
    };

    return <div>
        <BootstrapDialog
            maxWidth="lg"
            onClose={onClose}
            open={true}
        >
            <BootstrapDialogTitle onClose={onClose}>
                <Box display="flex" alignItems="center">
                    Add College
                </Box>
            </BootstrapDialogTitle>
            <DialogContent dividers sx={{ height: "65vh" }}>
                <Box width="100%" height="100%" >
                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Box>
                            <Tabs value={values} onChange={handleChange} aria-label="basic tabs example">
                                <Tab className="tabs-space start-tab-space" label="College Detail" {...a11yProps(0)} />
                                <Tab className="tabs-space" label="Contact Person" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                    </Box>

                    {/* College Detail */}
                    <TabPanel
                        style={{
                            height: "calc(100% - 135px)",
                            overflow: "auto",
                            marginTop: 10
                        }}
                        value={values}
                        index={0}>
                        <AddCollege
                            handleSubmit={handleSubmit}
                            getValues={getValues}
                            trigger={trigger}
                            resetField={resetField}
                            reset={reset}
                            watch={watch}
                            control={control}
                            errors={errors}
                            setValue={setValue}
                            tableData={tableData}
                            setTableData={setTableData}
                        />
                    </TabPanel>

                    {/* Contact Person  */}
                    <TabPanel value={values} index={1}>
                        <AddContactPersonDetail
                            currentTab={values}
                            handleSubmit={ContactPersonhandleSubmit}
                            getValues={ContactPersongetValues}
                            trigger={ContactPersontrigger}
                            control={ContactPersoncontrol}
                            errors={ContactPersonerrors}
                            setValue={ContactPersonsetValue}
                            resetField={ContactPersonresetField}
                            reset={ContactPersonreset}
                            tableData={tableData}
                            setTableData={setTableData} />
                    </TabPanel>

                </Box>
            </DialogContent>
        </BootstrapDialog>
    </div>;
};

export default AddCollegeLayout;
