import Joi from "joi";
import { required } from "../../../shared";

export const candidateSmsValidation = Joi.object({
    to: Joi.string()
        .label("Candidate Mobile Number")
        .min(8)
        .required()
        .messages({
            "string.empty": required,
        }),
    template: Joi.string()
        .label("Email Template")
        .allow(""),
    content: Joi.string()
        .required()
        .label("Body")
        .messages({
            "string.empty": required,
        }),
});
