import "../style.scss";
import { Bar } from "react-chartjs-2";
import { Chart, registerables, ArcElement, Tooltip, Legend } from "chart.js";
import { Box, Typography } from "@mui/material";
import { FC } from "react";
import ErrorMessage from "../../shared/error-message";
import BarChartIcon from "@mui/icons-material/BarChart";
Chart.register(...registerables, ArcElement, Tooltip, Legend);

interface props {
    label?: string;
    data: {
        labels: string[];
        // eslint-disable-next-line
        datasets: any;
    };
    isStacked?: boolean;
    height?: number;
    errorMessage?: string;
}

const BarGraph: FC<props> = ({ label, data, isStacked, height, errorMessage }) => (

    <Box className="box-container" sx={{ height: height }}>
        {
            data.labels.length
                ?
                <>
                    <Typography variant="h6">
                        {label}
                    </Typography>
                    <div>
                        {
                            isStacked
                                ?
                                <Bar
                                    data={data}
                                    width={100}
                                    height={Number(height) - 30}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        scales: {
                                            x: {
                                                stacked: true,
                                            },
                                            y: {
                                                stacked: true,
                                            }
                                        }
                                    }}
                                />
                                :
                                <Bar
                                    data={data}
                                    width={100}
                                    height={Number(height) - 30}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                    }}
                                />
                        }

                    </div>
                </>
                :
                <ErrorMessage Icon={<BarChartIcon fontSize='large' className='error-icon' />} fullHeight errorMessage={errorMessage} />
        }
    </Box>
);

BarGraph.defaultProps = {
    isStacked: false,
    height: 400,
};

export { BarGraph };