import Joi from "joi";
import { required} from "../../../shared";

export const testQuestionValidation = Joi.object({
    question: Joi.string()
    .trim()
        .required()
        .label("Question")
        .messages({
            "string.empty": required
        }),
        _category: Joi.string()
        .required()
        .label("Category")
        .messages({
            "string.empty": required
        }),
        options:Joi.array().items(
          Joi.object({
            option: Joi.string().trim().required().label("Option").messages({
                "string.empty": required
            }),
            weightage: Joi.number().required().label("Weightage").messages({
                "string.empty": required
            }),
            id: Joi.number()
        }
          )
        )
        .min(1)
        .required()
        .label("Options")
        .messages({
            "string.empty": required
        })
});