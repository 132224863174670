import { IProductMappingResponse, IProductMappingResponses } from "../../../interfaces/content/product-mapping";
import { productMapping, productMappingList } from "../../endpoints";
import HttpService from "../../http";

const ProductMappingService = () => {

    const {httpRequest} = HttpService();

    const getProductMappings = async (search:object):Promise<IProductMappingResponses> => 
    new Promise((resolve,reject) => {
        httpRequest<IProductMappingResponses>(
            "GET",
            `${productMappingList}`,
            {},
            search
            )
            .then(resolve)
            .catch(reject);
    });

    const getProductMapping = async (query : object) :Promise<IProductMappingResponse>=> new Promise((resolve,reject) => {
        httpRequest<IProductMappingResponse>(
        "GET",
        `${productMapping}`,
        {},
        query
         )
        .then(resolve)
        .catch(reject);
    });

    const addProductMapping = async (payload:object) : Promise<IProductMappingResponse> =>  new Promise((resolve,reject) => {
        httpRequest<IProductMappingResponse>(
            "POST",
            `${productMapping}`,
            payload
        )
        .then(resolve)
        .catch(reject);
    });

    const updateProductMapping = async (payload:object) : Promise<IProductMappingResponse> =>  new Promise((resolve,reject) => {
      httpRequest<IProductMappingResponse>(
          "PUT",
          `${productMapping}`,
          payload
      )
      .then(resolve)
      .catch(reject);
  });

    const deleteProductMapping = async(payload:object):Promise<IProductMappingResponse> =>
    new Promise((resolve,reject) => {
      httpRequest<IProductMappingResponse>("DELETE",`${productMapping}`,payload)
      .then(resolve)
      .catch(reject);
    });

    return {addProductMapping,getProductMapping ,getProductMappings,updateProductMapping,deleteProductMapping};


};

export default ProductMappingService;