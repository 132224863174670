import { IScoreDatasResponse ,ISCoreDataResponse} from "../../../interfaces/content/score-data";
import { scoreData, scoreDataList } from "../../endpoints";
import HttpService from "../../http";

const ScoreDataService = () => {
    const {httpRequest} = HttpService();

    const getContentsScoreDatas = async (search:object):Promise<IScoreDatasResponse> => 
    new Promise((resolve,reject) => {
        httpRequest<IScoreDatasResponse>(
            "GET",
            `${scoreDataList}`,
            {},
            search
            )
            .then(resolve)
            .catch(reject);
    });

    const addContentScoreData = async (payload : object) :Promise<ISCoreDataResponse>=> new Promise((resolve,reject) => {
        httpRequest<ISCoreDataResponse>(
        "POST",
        `${scoreData}`,
        payload )
        .then(resolve)
        .catch(reject);
    });

    

    const getContentScoreData = async (query:object) : Promise<ISCoreDataResponse> =>  new Promise((resolve,reject) => {
        httpRequest<ISCoreDataResponse>(
            "GET",
            `${scoreData}`,
            {},
            query
        )
        .then(resolve)
        .catch(reject);
    });

    const deleteContentSCoreData = async(payload:object):Promise<ISCoreDataResponse> =>
    new Promise((resolve,reject) => {
      httpRequest<ISCoreDataResponse>("DELETE",`${scoreData}`,payload)
      .then(resolve)
      .catch(reject);
    });
      
    const updateContentScoreData = async(payload:object):Promise<ISCoreDataResponse> =>
      new Promise((resolve,reject) => {
        httpRequest<ISCoreDataResponse>("PUT",`${scoreData}`,payload)
        .then(resolve)
        .catch(reject);
      });

    return {getContentsScoreDatas ,addContentScoreData,getContentScoreData,deleteContentSCoreData,updateContentScoreData};
};

export default ScoreDataService;