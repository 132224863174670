import { joiResolver } from "@hookform/resolvers/joi";
import { Autocomplete, Button, Grid, MenuItem, TextField, capitalize, Box, AutocompleteChangeReason, Chip, Paper } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { blogBasicDetailValidation } from "../../../../../validations";
import { IBlogFieldForm, IErrorResponse } from "../../../../../interfaces";
import Select from "../../../../../components/mui/select";
import { useQuery } from "@tanstack/react-query";
import CategoryService from "../../../../../services/content/category";
import SubCategoryService from "../../../../../services/content/sub-category";
import { SyntheticEvent, useEffect, useState } from "react";
import {  ISubCategory } from "../../../../../interfaces/content/sub-category";
import { ICategory } from "../../../../../interfaces/content/category";
import CustomLabel from "../../../../../components/mui/custom-label";
import ProjectsService from "../../../../../services/content/projects-products";
import { IProject } from "../../../../../interfaces/content/projects-products";
import plannerService from "../../../../../services/planner";
import KeywordService from "../../../../../services/content/keyword";
import PartsOfFunnelService from "../../../../../services/content/parts-of-funnel";
import TypesOfContentService from "../../../../../services/content/type-of-content";
import goalsService from "../../../../../services/content/goals";
import { Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { blogService } from "../../../../../services/blog";
import useSnackbar from "../../../../../hooks/useSnackbar";
import useResource from "../../../../../hooks/useResource";
import { IKeyword } from "../../../../../interfaces/content/keyword";
import CategoryCustomDialog from "../../../../../components/mui/dialog/categoryDialog";

const BlogImage = () => {
  const [ searchParams ] = useSearchParams();
  const { handleSubmit, getValues, setValue, trigger, control, reset, formState: { errors } } = useForm<IBlogFieldForm>({
    resolver: joiResolver(blogBasicDetailValidation),
    defaultValues: {
      title: "",
      description: "",
      slug: "",
      _product: "",
      _quarter: "",
      primaryKeywords: "",
      secondaryKeywords: [],
      partOfFunnel: "",
      typeOfContent: "",
      suggestedSubHeadings: "",
      goals: "",
      _category: "",
      _subCategory: [],
      data: "",
      audience: "",
      blogIdea: "",
      blog_button: "",
      button_text: ""
    }
  });
  const { getContentCategorys, getStrapiContentCategories, addContentCategory } = CategoryService();
  const { resourceAllocate } = useResource();
  const { addContentSubCategory } = SubCategoryService();
  const { getContentSubCategorys } = SubCategoryService();
  const { getContentProjects } = ProjectsService();
  const { getPlannerList } = plannerService();
  const { getContentKeywords } = KeywordService();
  const { snackbar } = useSnackbar();
  const { getContentPartsOfFunnels } = PartsOfFunnelService();
  const { getContentTypesOfContents } = TypesOfContentService();
  const { getContentsGoals } = goalsService();
  const { addBlog, getBlog, updateBlog } = blogService();
  const navigate = useNavigate();
  const { id } = useParams();
  const hitQuery = (id === "new" || id === "view") ? false : true;
  const [addSubCategory, setAddSubCategory] = useState(false);
  const [addCategory, setAddCategory] = useState(false);
  const [subCategoryName, setSubCategoryName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryId1, setSelectedCategoryId1] = useState(0);
  const [selectedCategoryId, setSelectedCategoryId] = useState<ICategory | null>(null);
  const [selectedProductId, setSelectedProductId] = useState<IProject | null>(null);

  const categories = useQuery({
    queryKey: ["categories", selectedProductId?._id],
    queryFn: () => getContentCategorys({ productId: selectedProductId?._id, isActive: true })
  });

  const subCategories = useQuery({
    queryKey: ["subCategories", selectedCategoryId?._id],
    queryFn: () => getContentSubCategorys({ categoryId: [selectedCategoryId?._id], isActive: true })
  });


  const projectsList = useQuery({
    queryKey: ["projects"],
    queryFn: () => getContentProjects({ isActive: true }),
  });

  const QuartersList = useQuery({
    queryKey: ["quarters", selectedProductId?._id],
    queryFn: () => getPlannerList({ productId: selectedProductId?._id }),
  });

  const KeywordsList = useQuery({
    queryKey: ["keywords"],
    queryFn: () => getContentKeywords({}),
  });

  const funnelsList = useQuery({
    queryKey: ["funnels"],
    queryFn: () => getContentPartsOfFunnels({}),
  });

  const typeOfContentList = useQuery({
    queryKey: ["typeOfContent"],
    queryFn: () => getContentTypesOfContents({}),
  });
  const blogData = useQuery({
    queryKey: ["blogs", id],
    queryFn: () => getBlog({ _id: id }),
    enabled: hitQuery
  });

  const goalList = useQuery({
    queryKey: ["goals"],
    queryFn: () => getContentsGoals({}),
  });

  useEffect(() => {
    if (blogData?.data?.data && id !== "new") {
      setValue("title", blogData?.data?.data?.title || "");
      setValue("description", blogData?.data?.data?.description || "");
      setValue("slug", blogData?.data?.data?.slug || "");
      setValue("_product", blogData?.data?.data?._product?._id || "");
      setSelectedProductId(blogData?.data?.data?._product);
      setValue("_quarter", blogData?.data?.data?._quarter?._id || "");
      setValue("primaryKeywords", blogData?.data?.data?.primaryKeywords?._id || "");
      setValue("secondaryKeywords", blogData?.data?.data?.secondaryKeywords?.map((data) => data._id) || []);
      setValue("partOfFunnel", blogData?.data?.data?.partOfFunnel?._id || "");
      setValue("typeOfContent", blogData?.data?.data?.typeOfContent?._id || "");
      setValue("suggestedSubHeadings", blogData?.data?.data?.suggestedSubHeadings || "");
      setValue("_category", blogData?.data?.data?._category?._id || "");
      setSelectedCategoryId(blogData?.data?.data?._category);
      setValue("_subCategory", blogData?.data?.data?._subCategory?.map((data) => data._id) || []);
      setValue("goals", blogData?.data?.data?.goals?._id || "");
      setValue("blogIdea", blogData?.data?.data?.blogIdea || "");
      setValue("data", blogData?.data?.data?.data || "");
      setValue("audience", blogData?.data?.data?.audience || "");
      setValue("blog_button", blogData?.data?.data?.blog_button?.type || "");
      setValue("button_text", blogData?.data?.data?.blog_button?.button_text || "");
    }
  }, [blogData?.data?.data]);
  const onSubmit = async (data: IBlogFieldForm) => {
    try {
      if (id === "new") {
        const payload = {...data, blog_button:{
          type: data.blog_button ?? "",
          button_text: data.button_text
        }, button_text : undefined};
        const response = await addBlog(payload);
        snackbar(response.message, "info");
        navigate(`/blog/manage/${response?.data?._id}?type=basic-detail`);
      }
      else {
        const payload = {...data, _id: id , blog_button:{
          type: data.blog_button ?? "",
          button_text: data.button_text
        }, button_text : undefined};
        const response = await updateBlog(payload);
        snackbar(response.message, "info");
        navigate({
          pathname: "/blog",
          search: searchParams.toString()
        });
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
      console.log("Error in Adding Blog", error);
    }
  };
  const handleProductChange = async (
    value: ICategory | null,
  ) => {
    if (value) {
      setSelectedProductId(value);
      setValue("_product", value._id);
      trigger("_product");
    }
  };

  const handlePrimaryKeywordChange = async (
    value: ICategory | null,
  ) => {
    if (value) {
      setValue("primaryKeywords", value._id);
      trigger("primaryKeywords");
    }
  };
  const handleButtonChange = async (
    value: string
  ) => {
    if (value) {
      setValue("blog_button", value);
      trigger("blog_button");
    }
  };


  const handleCategoryChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: ICategory | null
  ) => {
    if (value) {
      setSelectedCategoryId(value);
      setValue("_category", value._id);
      trigger("_category");
      setValue("_subCategory", []);
    }
  };

  const blogBannerButtons = [ "signup","affiliate","contactUs"];

  const onChangeUsersAutoComplete = (
    event: SyntheticEvent<Element, Event>,
    newValues: ISubCategory[]
  ) => {
    const values = (getValues("_subCategory") || []) as string[];

    const newValuesArray = Array.isArray(newValues) ? newValues : [newValues];
    const uniqueValues = newValuesArray
      .map(value => value?._id)
      .filter(id => !values.includes(id));

    const resultArray = [...values, ...uniqueValues];
    setValue("_subCategory", resultArray);
    trigger("_subCategory");
  };

  const onChangeSecondaryKeywordsAutoComplete = (
    event: SyntheticEvent<Element, Event>,
    value: IKeyword[],
    reason: AutocompleteChangeReason
  ) => {
    const keywordData = getValues("secondaryKeywords");
    const isExist = keywordData.includes(value[0]?._id);
    switch (reason) {
      case "selectOption":
        if (!isExist) {
          keywordData.push(value[0]?._id);
          setValue("secondaryKeywords", keywordData);
        }
        break;
      default:
        break;
    }

    trigger("secondaryKeywords");
  };

  const categoriesList = useQuery({
    queryKey: ["categoryList", selectedProductId],
    queryFn: async () => {
      try {
        const data = await getStrapiContentCategories({ productId: selectedProductId });
        return data;
      } catch (error) {
        const err = error as IErrorResponse;
        console.error("Error fetching category list:", error);
        snackbar(err.data.message, "error");
      }
    },
    enabled: selectedProductId !== null ? true : false
  });

  const onClose = () => {
    setAddSubCategory(false);
    setAddCategory(false);
  };

  const handleSubCategoryNameChange = (event: { target: { value: string; }; }) => {
    setSubCategoryName(event.target.value);
  };

  const handleAddSubCategory = async () => {
    try {
      const payload = {
        categoryId: selectedCategoryId?._id,
        name: subCategoryName
       };
      const add = await addContentSubCategory(payload);
      snackbar(add.message, "info");
      setAddSubCategory(false);
      subCategories.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log("Error in add or update in sub-category", err);
      setAddSubCategory(false);
    }
  };

  const handleAddCategory = async () => {
    try {
      const payload = {
        categoryId: selectedCategoryId1,
        _productId: selectedProductId?._id,
        name: selectedCategory
       };
      const add = await addContentCategory(payload);
      snackbar(add.message, "info");
      setAddCategory(false);
      categories.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log("Error in add or update in category", err);
      setAddCategory(false);
    }
  };

  const handleDeleteChip = (subCategoryId: string) => {
    const anss = getValues("_subCategory");
    const newData = anss.filter((id) => id !== subCategoryId);
    setValue("_subCategory", newData);

  };

  const handleKewordDeleteChip = (kewordId: string) => {
    const anss = getValues("secondaryKeywords");
    const newData = anss.filter((id) => id !== kewordId);
    setValue("secondaryKeywords", newData);

  };
  return (
    <div className="my-3 form-border">
      <form onSubmit={handleSubmit(onSubmit)}>

        <Grid container spacing={3}>

          <Grid item xs={4}>
            <Controller
              control={control}
              name="title"
              render={(prop) => <TextField
                label="Blog Title*"
                className="disable-text"
                variant="outlined"
                size="small"
                placeholder="Type blog title"
                error={errors["title"] ? true : false}
                helperText={errors["title"]?.message}
                {...prop.field}
              />}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="description"
              render={(prop) => <TextField
                label="Description*"
                className="disable-text"
                variant="outlined"
                size="small"
                placeholder="Type blog description"
                multiline
                minRows={3}
                maxRows={3}
                error={errors["description"] ? true : false}
                helperText={errors["description"]?.message}
                {...prop.field}
              />}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="slug"
              render={(prop) => <TextField
                label="Slug*"
                className="disable-text"
                variant="outlined"
                size="small"
                placeholder="Type blog slug"
                error={errors["slug"] ? true : false}
                helperText={errors["slug"]?.message}
                {...prop.field}
              />}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={"_product"}
              render={(prop) => <Autocomplete
                className="disable-text"
                options={projectsList?.data?.data?.map(
                  (data) => data
                ) || []}
                clearIcon={null}
                getOptionLabel={(option) => capitalize(option.name)}
                renderInput={(params) => <TextField
                  {...params}
                  error={errors["_product"] ? true : false}
                  helperText={errors["_product"]?.message}
                  size={"small"}
                  variant={"outlined"}
                  label={<CustomLabel label="Product*" />}
                  placeholder={"Please Select Product"}
                />}
                {...prop.field}
                value={projectsList?.data?.data?.find(data => data._id === getValues("_product")) || null}
                onChange={(e, value) => {
                  handleProductChange(value);
                }}
              />}
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              control={control}
              className="disable-text"
              name="_quarter"
              label="Quarter*"
              size={"small"}
              variant={"outlined"}
              error={errors["_quarter"] ? true : false}
              helperText={errors["_quarter"]?.message}
              disabled={selectedProductId === null ? true : false}
            >
              {QuartersList.data?.data?.map((data) => (
                <MenuItem key={data._id} value={data._id}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
               <Controller
              control={control}
              name={"primaryKeywords"}
              render={(prop) => <Autocomplete
                className="disable-text"
                options={KeywordsList?.data?.data?.map(
                  (data) => data
                ) || []}
                clearIcon={null}
                getOptionLabel={(option) => capitalize(option.name)}
                renderInput={(params) => <TextField
                  {...params}
                  error={errors["primaryKeywords"] ? true : false}
                  helperText={errors["primaryKeywords"]?.message}
                  size={"small"}
                  variant={"outlined"}
                  label={<CustomLabel label="Primary Keyword*" />}
                  placeholder={"Please Select Product"}
                />}
                {...prop.field}
                value={KeywordsList?.data?.data?.find(data => data._id === getValues("primaryKeywords")) || null}
                onChange={(e, value) => {
                  handlePrimaryKeywordChange(value);
                }}
              />}
            />
          </Grid>
          <Grid item xs={4}>
            <Box
              display="flex"
              alignItems="start"
              flexDirection="column"
            >
              <Controller
                control={control}
                name={"secondaryKeywords"}
                render={(prop) => (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap="7px"
                      mb={1}
                      width="100%"
                    >
                      <Autocomplete
                        sx={{ width: "100%" }}
                        options={
                          KeywordsList?.data?.data?.map(
                            (keywordData) => keywordData
                          ) || []
                        }
                        getOptionLabel={(option) =>
                          capitalize(option.name)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            label={"Secondary Keyword*"}
                            placeholder={"Secondary Keyword"}
                            error={errors["secondaryKeywords"] ? true : false}
                            helperText={errors["secondaryKeywords"]?.message
                            }
                          />
                        )}
                        {...prop.field}
                        value={[]} 
                        onChange={(e, value, reason) =>
                          onChangeSecondaryKeywordsAutoComplete(
                            e,
                            value,
                            reason
                          )
                        }
                        multiple
                      />
                    </Box>
                    <Box
                      display="flex"
                      alignItems={"flex-start"}
                      width="100%"
                      flexWrap={"wrap"}
                    >

                      {getValues("secondaryKeywords")?.map(
                        (keyowrdId) => (
                          <Chip
                            key={keyowrdId}
                            label={
                              KeywordsList?.data?.data?.find(
                                (user) => user._id === keyowrdId
                              )?.name
                            }
                            color="primary"
                            variant="outlined"
                            onDelete={() =>
                              handleKewordDeleteChip(keyowrdId)
                            }
                            sx={{ margin: "5px" }}
                          />
                        )
                      )}
                    </Box>
                  </>
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Select
              control={control}
              className="disable-text"
              name="partOfFunnel"
              label="Part of funnel*"
              size={"small"}
              variant={"outlined"}
              error={errors["partOfFunnel"] ? true : false}
              helperText={errors["partOfFunnel"]?.message}
            >
              {funnelsList.data?.data?.map((data) => (
                <MenuItem key={data?._id} value={data._id}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Select
              control={control}
              className="disable-text"
              name="typeOfContent"
              label="Type of content*"
              size={"small"}
              variant={"outlined"}
              error={errors["typeOfContent"] ? true : false}
              helperText={errors["typeOfContent"]?.message}
            >
              {typeOfContentList.data?.data?.map((data) => (
                <MenuItem key={data?._id} value={data._id}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="suggestedSubHeadings"
              render={(prop) => <TextField
                label="Suggested Sub Heading"
                className="disable-text"
                variant="outlined"
                size="small"
                placeholder="Type suggested sub-heading"
                error={errors["suggestedSubHeadings"] ? true : false}
                helperText={errors["suggestedSubHeadings"]?.message}
                {...prop.field}
              />}
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              control={control}
              className="disable-text"
              name="goals"
              label="Goals*"
              size={"small"}
              variant={"outlined"}
              error={errors["goals"] ? true : false}
              helperText={errors["goals"]?.message}
            >
              {goalList.data?.data?.map((data) => (
                <MenuItem key={data?._id} value={data._id}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={"_category"}
              disabled={selectedProductId === null ? true : false}
              render={(prop) => <Autocomplete
                className="disable-text"
                options={categories?.data?.data?.map(
                  (data) => data
                ) || []}
                clearIcon={null}
                getOptionLabel={(option) => capitalize(option.name)}
                renderInput={(params) => <TextField
                  {...params}
                  error={errors["_category"] ? true : false}
                  helperText={errors["_category"]?.message}
                  size={"small"}
                  variant={"outlined"}
                  label={<CustomLabel label="Category*" />}
                  placeholder={"Please Select Category"}
                />}
                PaperComponent={({ children }) => 
                  <Paper>
                    {children}
                    <Button
                      color="primary"
                      fullWidth
                      sx={{ justifyContent: "flex-start", pl: 2 }}
                      disabled={resourceAllocate("cms-category.write") ? false : true}
                      onMouseDown={() => {
                        setAddCategory(true);
                      }}
                    >
                      +  Add New Category
                    </Button>
                  </Paper>
              }
                {...prop.field}
                value={categories?.data?.data?.find(category => category._id === getValues("_category")) || null}
                onChange={(e, value) => {
                  handleCategoryChange(e, value);
                }}
              />}
            />
          </Grid>
          <Grid item xs={4}>
            <Box
              display="flex"
              alignItems="start"
              flexDirection="column"
            >
              <Controller
                control={control}
                name={"_subCategory"}
                render={(prop) => (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap="7px"
                      mb={1}
                      width="100%"
                    >
                      <Autocomplete
                        sx={{ width: "100%" }}
                        disabled={selectedCategoryId === null ? true : false}
                        options={
                          subCategories?.data?.data?.map(
                            (subCategorysDa) => subCategorysDa
                          ) || []
                        }
                        getOptionLabel={(option) =>
                          capitalize(option.name)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            label={"Sub Category*"}
                            placeholder={"Sub Category"}
                            error={errors["_subCategory"] ? true : false}
                            helperText={errors["_subCategory"]?.message}
                          />
                        )}
                        PaperComponent={({ children }) => 
                            <Paper>
                              {children}
                              <Button
                                color="primary"
                                fullWidth
                                sx={{ justifyContent: "flex-start", pl: 2 }}
                                disabled={resourceAllocate("cms-sub-category.write") ? false : true}
                                onMouseDown={() => {
                                  setAddSubCategory(true);
                                }}
                              >
                                +  Add New Sub Category
                              </Button>
                            </Paper>
                        }
                        {...prop.field}
                        value={[]}
                        onChange={(event, newValues) => onChangeUsersAutoComplete(event, newValues)}
                        multiple
                      />
                    </Box>
                    <Box
                      display="flex"
                      alignItems={"flex-start"}
                      width="100%"
                      flexWrap={"wrap"}
                    >

                      {(getValues("_subCategory") ?? [])?.map(
                        (subCategoryId) => (
                          <Chip
                            key={subCategoryId}
                            label={
                              subCategories?.data?.data?.find(
                                (user) => user._id === subCategoryId
                              )?.name
                            }
                            color="primary"
                            variant="outlined"
                            onDelete={() =>
                              handleDeleteChip(subCategoryId)
                            }
                            sx={{ margin: "5px" }}
                          />
                        )
                      )}
                    </Box>
                  </>
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="audience"
              render={(prop) => <TextField
                label="Audience"
                className="disable-text"
                variant="outlined"
                size="small"
                placeholder="Write audience of the blogs here..."
                multiline
                minRows={3}
                maxRows={3}
                error={errors["audience"] ? true : false}
                helperText={errors["audience"]?.message}
                {...prop.field}
              />}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="blogIdea"
              render={(prop) => <TextField
                label="Idea of the Blog"
                className="disable-text"
                variant="outlined"
                size="small"
                placeholder="Write idea of the blogs here..."
                multiline
                minRows={3}
                maxRows={3}
                error={errors["blogIdea"] ? true : false}
                helperText={errors["blogIdea"]?.message}
                {...prop.field}
              />}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="data"
              render={(prop) => <TextField
                label="Data"
                className="disable-text"
                variant="outlined"
                size="small"
                placeholder="Write stats for the blogs..."
                error={errors["data"] ? true : false}
                helperText={errors["data"]?.message}
                {...prop.field}
              />}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={"blog_button"}
              render={(prop) => <Autocomplete
                className="disable-text"
                options={blogBannerButtons.map(
                  (name) => name
                ) || []}
                clearIcon={null}
                getOptionLabel={(option) => capitalize(option)}
                renderInput={(params) => <TextField
                  {...params}
                  error={errors["blog_button"] ? true : false}
                  helperText={errors["blog_button"]?.message}
                  size={"small"}
                  variant={"outlined"}
                  label={<CustomLabel label="Blog Banner Button *" />}
                  placeholder={"Please Select Blog Banner Button"}
                />}
                {...prop.field}
                value={blogBannerButtons.find(data => data === (getValues("blog_button"))) || null}
                onChange={(e, value) => {
                  handleButtonChange(value ? value : "");
                }}
              />}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="button_text"
              render={(prop) => <TextField
                label="Blog Banner Button Text*"
                className="disable-text"
                variant="outlined"
                size="small"
                placeholder="Type blog banner button text"
                error={errors["button_text"] ? true : false}
                helperText={errors["button_text"]?.message}
                {...prop.field}
              />}
            />
          </Grid>
        </Grid>
        
        <div className="form-action-btn">
          <Button variant="outlined"
            onClick={() => reset()}
            disabled={resourceAllocate("cms-blog.write") ? false : true}
          >Discard</Button>
          <Button
            type="submit"
            className="ml-2"
            disabled={resourceAllocate("cms-blog.write") ? false : true}
          >Save</Button>
        </div>
      <CategoryCustomDialog
                size='sm'
                title="Add Sub-Category"
                isOpen={addSubCategory}
                onClose={onClose}
                confirmText="Add"
                onConfirm={handleAddSubCategory}
        >
            <Grid container spacing={4}>
                <Grid key="sub-category" item xs={12}>
                  <TextField
                    label="Sub-Category Name"
                    className="disable-text"
                    variant="outlined"
                    size="small"
                    placeholder="Enter Sub-Category Name"
                    onChange={handleSubCategoryNameChange}
                  />
                </Grid>
            </Grid>
        </CategoryCustomDialog>
        <CategoryCustomDialog
                size='sm'
                title="Add Category"
                isOpen={addCategory}
                onClose={onClose}
                confirmText="Add"
                onConfirm={handleAddCategory}
        >
            <Grid container spacing={4}>
                <Grid key="category" item xs={12}>
                <Autocomplete
                    className="disable-text"
                    options={categoriesList?.data?.data?.map(
                      (data) => data
                    ) || []}
                    clearIcon={null}
                    getOptionLabel={(option) => capitalize(option.name)}
                    renderInput={(params) => <TextField
                      {...params}
                      size={"small"}
                      variant={"outlined"}
                      label={<CustomLabel label="Category*" />}
                      placeholder={"Please Select Category"}
                    />}
                    onChange={(e, value) => {
                      if(value){
                        setSelectedCategory(value?.name);
                        setSelectedCategoryId1(value?.id);
                      }
                }}
              />
                </Grid>
            </Grid>
        </CategoryCustomDialog>
      </form>
      <Outlet />
    </div>

  );
};

export default BlogImage;