import dayjs from "dayjs";
import { IDataSet, IMergedData, IPagination } from "../interfaces";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);

export const capitalize = (text: string) => {
    if (typeof text !== "string") {
        return "";
    }
    let name: string | string[] = text?.trim()?.toLowerCase() || "";
    name = name?.split(" ");
    name = name
        .map((word) => {
            if (["TA", "LA", "HR", "BD", "UI/UX", "CPD"].includes(word?.toUpperCase())) {
                return word?.toUpperCase();
            } else if (word?.includes("-")) {
                const word2 = word?.split("-");
                return word2
                    .map((wrd) => wrd[0] ? wrd[0]?.toUpperCase() + wrd?.substring(1) : "")
                    .join("-");
            } else {
                return word[0] ? word[0]?.toUpperCase() + word?.substring(1) : "";
            }
        })
        .join(" ");

    return name;
};

export const formatCallStatus = (status: string) => {
    switch (status) {
        case "APPLIED":
            return "Applied";
        case "CALL_NA":
            return "Called NA";
        case "TBC_LATER":
            return "TBC Later";
        case "NOT_INTERESTED":
            return "Not Interested";

        default:
            return capitalize(status);
    }
};

export const checkStatusColor = (status: string): "warning" | "success" | "error" => {
    let color: "warning" | "success" | "error" = "warning";

    switch (status.toUpperCase()) {
        case "TA SELECTED":
            color = "success";
            break;
        case "TA REJECTED":
            color = "error";
            break;
        case "HR SELECTED":
            color = "success";
            break;
        case "HR REJECTED":
            color = "error";
            break;
        case "REVIEW SELECTED":
            color = "success";
            break;
        case "REVIEW REJECTED":
            color = "error";
            break;
    }

    return color;
};

export const checkCollegeStatusColor = (isDriveActive: boolean): "success" | "error" =>
    isDriveActive ? "success" : "error";

export const formateEngagementType = (type: string) => {
    let engagementType = "";
    switch (type?.toLowerCase()) {
        case "full-time":
            engagementType = "Full Time";
            break;
        case "internship":
            engagementType = "Intern";
            break;
        default:
            engagementType = type;
            break;
    }
    return capitalize(engagementType?.toLowerCase());
};

export const checkRequestedSkillStatusColor = (status: string): "warning" | "success" | "error" => {
    let color: "warning" | "success" | "error" = "warning";

    switch (status.toUpperCase()) {
        case "APPROVED":
            color = "success";
            break;
        case "REJECTED":
            color = "error";
            break;
        case "PENDING":
            color = "warning";
            break;
        default:
            break;
    }
    return color;
};

export const checkRequestedResourcesStatusColor = (status: string) => {

    switch (status.toUpperCase()) {
        case "HR_APPROVED":
            return "D0F0D2";
        case "HR_REJECTED":
            return "#D32F2F80";
        case "ADMIN_APPROVED":
            return "D0F0D2";
        case "ADMIN_REJECTED":
            return "#D32F2F80";
        case "IN-PROGRESS":
            return "D0F0D2";
        case "COMPLETED":
            return "#D0F0D2";
        default:
            break;
    }
};

export function formatMobileNumber(mobile?: { dialCode: string, number: string, iso2: string, country: string }): string {
    return `${mobile?.dialCode || ""} ${mobile?.number || ""}`;
}

export const createIndex = (pagination: IPagination, index: number) => pagination?.limit * (pagination?.page - 1) + index + 1;

export const formatDateTime = (d: string | Date | undefined) => dayjs(d)
    .format("lll");

export const formatTime = (d: string | Date | undefined) => {
    if (!d) {
        return "";
    }

    return dayjs(d).format("h:mm A");
};

export const formatDate = (d: string | Date | undefined) => {
    if (!d) {
        return "";
    }
    return dayjs(d).format("ll");
};

export function isValidDate(d: Date | string) {
    if (new Date(d).toString() !== "Invalid Date") {
        return new Date(d)?.toISOString();
    } else {
        return false;
    }
}

export const replaceHtmlKeyword = (htmlContent: string, words: { [key: string]: string | string[] | number }) => {
    let processedString = htmlContent?.replace(/<span[^>]*>(<em[^>]*>)?{{\s*([\w.]+)\s*}}(<\/em>)?<\/span>/g, (match, emTagOpen, keyword) => {
        const replacement = words[keyword];
        if (replacement !== undefined)
            return replacement.toString();
        else {
            return `${keyword}`;
        }
    });

    processedString = processedString?.replace(/{{\s*([\w.]+)\s*}}/g, (match, keyword) => {
        const replacement = words[keyword];
        if (replacement !== undefined)
            return replacement.toString();
        else {
            return `${keyword}`;
        }
    });

    return processedString;
};

export const toIndianRupee = (num: number) =>
    num.toLocaleString("en-IN", {
        maximumFractionDigits: 0,
        style: "currency",
        currency: "INR",
    });

export const excelDateToJsDate = (serial: number) => {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);

    const fractional_day = serial - Math.floor(serial) + 0.0000001;

    let total_seconds = Math.floor(86400 * fractional_day);

    const seconds = total_seconds % 60;

    total_seconds -= seconds;

    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;

    const data = new Date(
        date_info.getFullYear(),
        date_info.getMonth(),
        date_info.getDate(),
        hours,
        minutes,
        seconds,
    );

    if (data.toString() !== "Invalid Date") {
        return data?.toISOString();
    } else {
        return undefined;
    }
};

export const displayName = (user: { firstName: string; lastName: string; name?: string }) => {
    if (user?.name) {
        return user?.name;
    }
    const FirstName = (user?.firstName);
    const LastName = (user?.lastName);
    return `${FirstName} ${LastName}`;
};
export const checkTimePeriod = (timePeriod: string) => {
    switch (timePeriod) {
        case "today": {
            return {
                startDate: dayjs()
                    .startOf("day")
                    .toISOString(),
                endDate: dayjs()
                    .endOf("day")
                    .toISOString()
            };
        }
        case "thisWeek": 
        case "thisComparisonWeek": {
            return {
                startDate: dayjs()
                    .startOf("week")
                    .toISOString(),
                endDate: dayjs()
                    .endOf("week")
                    .toISOString()
            };
        }
        case "thisMonth": 
        case "thisComparisonMonth": {
            return {
                startDate: dayjs()
                    .startOf("month")
                    .toISOString(),
                endDate: dayjs()
                    .endOf("month")
                    .toISOString()
            };
        }
        case "yesterday": {
            return {
                startDate: dayjs()
                    .subtract(1, "day")
                    .startOf("day")
                    .toISOString(),
                endDate: dayjs()
                    .subtract(1, "day")
                    .endOf("day")
                    .toISOString()
            };
        }
        case "lastWeek": {
            return {
                startDate: dayjs()
                    .subtract(1, "weeks")
                    .startOf("week")
                    .toISOString(),
                endDate: dayjs()
                    .subtract(1, "weeks")
                    .endOf("week")
                    .toISOString()
            };
        }
        case "lastMonth": {
            return {
                startDate: dayjs()
                    .subtract(1, "month")
                    .startOf("month")
                    .toISOString(),
                endDate: dayjs()
                    .subtract(1, "month")
                    .endOf("month")
                    .toISOString()
            };
        }
        case "thisQuarter": 
        case "thisComparisonQuarter": {
            return {
                startDate: dayjs()
                    .subtract(2, "months")
                    .startOf("month")
                    .toISOString(),
                endDate: dayjs()
                    .endOf("month")
                    .toISOString()
            };
        }
        default: {
            return {
                startDate: dayjs()
                    .startOf("day")
                    .toISOString(),
                endDate: dayjs()
                    .endOf("day")
                    .toISOString()
            };
        }
    }
};

export const getPreviousTimePeriod = (key: string) => {
    switch (key) {
        case "thisComparisonWeek":
            return {
                startDate: dayjs().subtract(1, "week").startOf("week").toISOString(),
                endDate: dayjs().subtract(1, "week").endOf("week").toISOString(),
            };
        case "thisComparisonMonth":
            return {
                startDate: dayjs().subtract(1, "month").startOf("month").toISOString(),
                endDate: dayjs().subtract(1, "month").endOf("month").toISOString(),
            };
        case "thisComparisonQuarter":
            return {
                startDate: dayjs().subtract(4, "months").startOf("month").toISOString(),
                endDate: dayjs().subtract(2, "months").endOf("month").toISOString(),
            };

        default:
            return {
                startDate: dayjs()
                    .startOf("day")
                    .toISOString(),
                endDate: dayjs()
                    .endOf("day")
                    .toISOString()
            };
    }
};

export const formatHistoryDate = (dateString: Date) => {
    const dateObject = new Date(dateString);
    const currentDate = new Date();
    const isToday = dateObject.toDateString() === currentDate.toDateString();

    return isToday
        ? "Today"
        : `${new Intl.DateTimeFormat("en-US", { month: "short" }).format(dateObject)} ${dateObject.getFullYear()}`;
};

export const formatMonthDate = (dateString: Date) => {
    const dateObject = new Date(dateString);
    const currentDate = new Date();
    const isToday = dateObject.toDateString() === currentDate.toDateString();

    return isToday
        ? "Today"
        : dayjs(dateString).format("ll");
};

export const formatMonthTime = (dateString: Date) => {
    const dateObject = new Date(dateString);
    return `${new Intl.DateTimeFormat("en-US", { month: "long" }).format(dateObject)} ${dateObject.getFullYear()}`;
};

export const getGraduationYears = (currentYear: number) => {
    const graduationYears = [];
    for (let year = currentYear - 20; year <= currentYear + 5; year++) {
        graduationYears.push({ value: String(year) });
    }
    return graduationYears;
};

export const formatHtmlString = (htmlString: string) => {
    const parser = new DOMParser();

    const doc = parser.parseFromString(htmlString, "text/html");

    const formattedString = doc.body.textContent || "";

    return formattedString.trim();
};

export const checkTestStatusColor = (status: string): "warning" | "success" | "error" => {
    switch (status && status.toUpperCase()) {
        case "PENDING":
        case "MODERATE":    
        case "MEDIUM":
            return "warning";
        case "SUBMITTED":
        case "HIGH":
        case "EASY":    
            return "success";
        case "LOW":
        case "HARD":            
            return "error";
        default:
            return "warning";
    }
};
export const checkTestStatusBackgroundColor = (status: string) => {
    switch (status && status.toUpperCase()) {
        case "PENDING":
        case "MODERATE":    
        case "MEDIUM":
            return "#FCF5EB";
        case "HIGH":
        case "EASY":    
        case "SUBMITTED":
            return "#D0F0D2";
        case "LOW":
        case "HARD":            
            return "#F9D9DC";
        default:
            return "warning";
    }
};


export const cleanHtmlString = (html: string) => {
    let cleanedHtml = html.replace(/>\s+</g, "><") // Remove spaces between tags
      .replace(/(<br\s*\/?>)+/g, "") // Remove unnecessary <br> tags
      .replace(/<p>(\s*(&nbsp;)?)*<\/p>/g, "")
      .trim(); // Trim the result

    // Trim spaces inside <p> tags
    cleanedHtml = cleanedHtml.replace(/<p>(.*?)<\/p>/g, (match, p1) => `<p>${p1.trim()}</p>`);


    return cleanedHtml;
  };

  export const increaseDate = (dateString: string , day:number) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + day);
    return date.toISOString();
  };

  export const checkElegibilityChipColor = (status: string): "warning" | "success" | "error" => {
    switch (status && status.toUpperCase()) {
        case "Potential Fit":
            return "warning";
        case "Perfect Match":
        case "Good Match":    
            return "success";
        case "Not a Fit":
            return "error";
        default:
            return "warning";
    }
};

export const mergeMultipleJson = (...dataSets: IDataSet[]): IMergedData[] => {
    const mergedArray: IMergedData[] = [];

    dataSets.forEach(dataSet => {
        const { name, data } = dataSet;
        mergedArray.push({ name, data });
    });

    return mergedArray;
};

export const getDisplayLabel = (perm: string) => {
    if (perm.endsWith("Assigned")) {
        return "Assigned"; // For assigned permissions
    }
    if (perm.includes("read") || perm.includes("edit") || perm.includes("remove")) {
        return "All"; // For read, edit, or remove permissions
    }
    return perm.split(".")[1]; // Default label for other permissions
};

export const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};