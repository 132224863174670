import { Alert, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { FC } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { capitalize } from "../../../../utilities/helper";
import { IUploadError } from "../../../../interfaces/candidate";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    errorMessages: IUploadError[];
}

const UploadFileError: FC<Props> = ({ isOpen, onClose, errorMessages }) => (
    
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="custom-dialog"
        >
            <DialogTitle id="alert-dialog-title">
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
                Excel File Errors
            </DialogTitle>
            <DialogContent style={{margin: "10px"}}>
                {errorMessages?.map((error, index) => {
                    const keys = Object.keys(error).reverse();
                    return (
                        <Alert className="mb-3" key={index} severity="warning">
                            {keys.map((key, i) => (
                                <div key={i}>
                                    <span style={{ fontWeight: "bold" }}>{capitalize(key)}:</span> {error[key]}
                                </div>
                            ))}
                        </Alert>
                    );
                })}
            </DialogContent>
        </Dialog>
    
            );

export default UploadFileError;

