import { ITemplate } from "../../../../interfaces";
import { LOAD_TEMPLATE } from "../../../actions";

interface IAction {
    type: "LOAD_TEMPLATE",
    payload: ITemplate
}

interface IState {
    list: ITemplate[]
}

const initialState: IState = {
    list: []
};

const templateReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case LOAD_TEMPLATE: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
};

export { templateReducer };
