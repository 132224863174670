import { useQuery } from "@tanstack/react-query";
import { MouseEvent } from "react";
import { Box, Button, CircularProgress, Tooltip, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Header from "../../../components/header";
import CustomTable from "../../../components/mui/table";
import { IBlogsInsights, IBlogKeywordsRow, IBlogsInsightsState, IBlogsInsightsComparison } from "../../../interfaces/blogs-insights";
import { BlogsInsightsService } from "../../../services/blogs-insights";
import BlogInsightFilters from "../pages/filters";
import ProjectsService from "../../../services/content/projects-products";
import CustomTypography from "../../../components/mui/max-length-limit";

const BlogsKeywordsInsights = () => {
  let rows: IBlogKeywordsRow[] = [];
  const { getContentProjectPartial } = ProjectsService();
  const [searchParams] = useSearchParams();
  const { getBlogsInsights } = BlogsInsightsService();
  const [state, setState] = useState<IBlogsInsightsState>({
      pagination: {
          page: 1,
          limit: 20,
          totalPages: 1,
        },
        filterDialog: {
            anchorEl: null,
            isOpen: false
        },
        filters: {
          date: {
            startDate: "",
            endDate: ""
          },
          comparisonDate: {
            currentStartDate: "",
            currentEndDate: "",
            previousStartDate: "",
            previousEndDate: ""
          },
          url: {
              expression: "",
              operator: ""
          }
        },
        filterCount: 0,
});

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const handleLoadMore = () => {
      setCurrentPage(prevPage => prevPage + 1);
    };

    const project = useQuery({
      queryKey: ["project"],
      queryFn: () => getContentProjectPartial({
        search: "BotPenguin"
      }),
    });
    
  const insights = useQuery({
    queryKey: ["allInsights", currentPage, state.filters],
    queryFn: () => {
      setLoading(true);
      return getBlogsInsights({
        pagination: true,
        limit: state.pagination.limit,
        page: currentPage,
        dimensions: ["query"],
        _product: project.data?.data?._id,
        startDate: state.filters.comparisonDate.currentStartDate ? state.filters.comparisonDate.currentStartDate : state.filters.date.startDate,
        endDate: state.filters.comparisonDate.currentEndDate ? state.filters.comparisonDate.currentEndDate : state.filters.date.endDate,
        dimension: "query",
        operator: state.filters.url.operator,
        expression: state.filters.url.expression,
        compareStartDate: state.filters.comparisonDate.previousStartDate,
        compareEndDate: state.filters.comparisonDate.previousEndDate
      });
    },
    onSuccess: () => setLoading(false),
    enabled: !!project?.data?.data,
  });

  const [insightsData, setInsightsData] = useState<IBlogsInsights[] | null>(null);
  const [insightsComparisonData, setInsightsComparsionData] = useState<IBlogsInsightsComparison[] | null>(null);

  useEffect(() => {
    insights.remove();
    setInsightsData(null);
    setInsightsComparsionData(null);
  }, []);

  useEffect(() => {
    if (insights?.data?.success && !insights.data?.data?.rows) {
      setInsightsData(null);
    }
    if (insights.data?.data?.rows) {

      setInsightsData((prevData) => {
        if (!prevData) {
          return insights.data.data.rows as IBlogsInsights[];
        } else {
          if (Array.isArray(insights.data.data.rows)) {
            const newData = insights.data.data.rows as IBlogsInsights[];
            newData.forEach((newItem) => {
              prevData.push(newItem);
            });
            return [...prevData];
          } else {
            return prevData;
          }
        }
      });
    }
    if(insights?.data?.data?.compareRecords) {
      setInsightsComparsionData((prevData) => {
        if (!prevData) {
          return insights.data.data.compareRecords as IBlogsInsightsComparison[];
        } else {
          if (Array.isArray(insights.data.data.compareRecords)) {
            const newData = insights.data.data.compareRecords as IBlogsInsightsComparison[];
            newData.forEach((newItem) => {
              prevData.push(newItem);
            });
            return [...prevData];
          } else {
            return prevData;
          }
        }
      });
    }
  }, [insights?.data?.data]);

  useEffect(() => {
    let filterCount = 0;
    const dateArray: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
    const urlFilters: { key: string, value: string, expression: string, operator: string }[] = searchParams.get("url") ? JSON.parse(String(searchParams.get("url"))) : [];
    const comparisonDateArray: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("comparisonDate") ? JSON.parse(String(searchParams.get("comparisonDate"))) : [];
    filterCount += urlFilters.length ? 1 : 0;
    filterCount += dateArray.length ? 1 : 0;
    filterCount += comparisonDateArray.length ? 1 : 0;

    let date: {
      startDate: string;
      endDate: string;
    } = {
      startDate: "",
      endDate: "",
    };
    if (dateArray?.length) {
      date = {
        startDate: dateArray[0]?.startDate,
        endDate: dateArray[0]?.endDate,
      };
    }

    let comparisonDate: {
      currentStartDate: string;
      currentEndDate: string;
      previousStartDate: string;
      previousEndDate: string;
    } = { 
      currentStartDate: "",
      currentEndDate: "",
      previousStartDate: "",
      previousEndDate: "",
    };
    if (comparisonDateArray?.length) {
      comparisonDate = {  
        currentStartDate: comparisonDateArray[0].startDate,
        currentEndDate: comparisonDateArray[0].endDate,
        previousStartDate: comparisonDateArray[1].startDate,
        previousEndDate: comparisonDateArray[1].endDate,
      };
    }

    let url: {
      expression: string;
      operator: string;
    } = {
      expression: "",
      operator: "",
    };
    if (urlFilters?.length) {
      url = {
        expression: urlFilters[0]?.expression,
        operator: urlFilters[0]?.operator
      };
    }

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
      },
      filters: {
        ...prevState.filters,
        date,
        url,
        comparisonDate
      },
      filterCount
    }));
    setInsightsData(null);
  }, [searchParams]);

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const createRow = (index: number, insight: IBlogsInsights) => ({
    id: index + 1,
    keyword: <CustomTypography
        limit={60}
        label={insight.keys[0]}
      />,
    clicks: insight.clicks,
    impressions: insight.impressions
  });

  const createDifferenceRow = (index: number, insight: IBlogsInsightsComparison) => ({
    id: index + 1,
    keyword: <CustomTypography
      limit={60}
      label={insight.query}
    />,
    clicks: (
      <Tooltip title={`Current Period: ${insight.metrics1Data.clicks} | Previous Period: ${insight.metrics2Data.clicks}`}>
        <Typography>{insight.metrics2Data.clicks - insight.metrics1Data.clicks}</Typography>
      </Tooltip>
    ),
    impressions: (
      <Tooltip title={`Current Period: ${insight.metrics1Data.impressions} | Previous Period: ${insight.metrics2Data.impressions}`}>
        <Typography>{insight.metrics2Data.impressions - insight.metrics1Data.impressions}</Typography>
      </Tooltip>
    )
  });


  if (insightsData) {
    rows = insightsData.map((insight, i) =>
      createRow(i, insight)
    );
  }else if (insightsComparisonData) {
    rows = insightsComparisonData.map((insight, i) =>
      createDifferenceRow(i, insight)
    );
  }

  const columns = [
    {
      id: "id",
      label: "S. No.",
    },
    {
      id: "keyword",
      label: "Keyword",
    },
    {
      id: "impressions",
      label: state.filters.comparisonDate.currentEndDate.length ? " Impressions Difference" : "Impressions",
    },
    {
      id: "clicks",
      label: state.filters.comparisonDate.currentEndDate.length ? "Clicks Difference" : "Clicks",
    }
  ];

  return (
    <div>
      <Header
        className="my-2"
        onFilter={openFilter}
        filterCount={state.filterCount}
      >
        <BlogInsightFilters
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnClose={closeFilter}
        />
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 246px)"
          errorMessage="No data available"
        />
      </Box>

      <div style={{ display: "flex", justifyContent: "center", marginTop: "5px", alignItems: "center" }}>
        {loading ? (
          <CircularProgress />
        ) : (
          insights?.data?.meta?.isLoad && (
            <Button variant="contained" color="primary" onClick={handleLoadMore}>
              Load More
            </Button>
          )
        )}
      </div>
    </div>
  );
};

export default BlogsKeywordsInsights;
