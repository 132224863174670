import { useState, SyntheticEvent, useEffect } from "react";
import { useNavigate, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import useResource from "../../hooks/useResource";
import useUser from "../../hooks/useUser";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface IState {
  redirectionLinks: string[]
}

const CareersLayout = () => {
  const outlet = useOutletContext();
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();
  const { resourceAllocate } = useResource();
  const [state, setState] = useState<IState>({
    redirectionLinks: []
  });

  useEffect(() => {
    const redirectionLinks = [...state.redirectionLinks];
    if (resourceAllocate("job.read")) {
      redirectionLinks.push("jobs");
    }
    if (resourceAllocate("employee-review.read")) {
      redirectionLinks.push("employee-review");
    }
    if (resourceAllocate("college-image.read")) {
      redirectionLinks.push("college-images");
    }
    if (resourceAllocate("employee-image.read")) {
      redirectionLinks.push("office-employee-images");
    }
    if (resourceAllocate("faq.read")) {
      redirectionLinks.push("faqs");
    }
    if (resourceAllocate("careers-highlight.read")) {
      redirectionLinks.push("highlight");
    }
    setState(prev => ({
      ...prev,
      redirectionLinks
    }));
  }, [user]);

  useEffect(() => {
    const index = state.redirectionLinks.indexOf(location.pathname.split("/")[2]);
    setValue(index < 0 ? 0 : index);
  }, [location.pathname, state.redirectionLinks]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    navigate(state.redirectionLinks[newValue]);
  };

  return (
    <Box width="100%" >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {resourceAllocate("job.read") && <Tab className="tabs-space start-tab-space" label="Jobs" {...a11yProps(0)} />}
          {resourceAllocate("employee-review.read") && <Tab className="tabs-space" label="Employee Review" {...a11yProps(1)} />}
          {resourceAllocate("college-image.read") && <Tab className="tabs-space" label="College Images" {...a11yProps(2)} />}
          {resourceAllocate("employee-image.read") && <Tab className="tabs-space" label="Office Employee Images" {...a11yProps(2)} />}
          {resourceAllocate("faq.read") && <Tab className="tabs-space" label="FAQ's" {...a11yProps(2)} />}
          {resourceAllocate("careers-highlight.read") && <Tab className="tabs-space" label="HighLights" {...a11yProps(2)} />}
        </Tabs>
      </Box>
      <Outlet context={outlet} />
    </Box>
  );
};

export default CareersLayout;
