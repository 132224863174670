import { Controller, useForm } from "react-hook-form";
import "./style.scss";
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, capitalize, Dialog, Grid, IconButton, TextField, Typography } from "@mui/material";
import { IBlogContent, IErrorResponse } from "../../../../../interfaces";
import CloseIcon from "@mui/icons-material/Close";
import { FC, useEffect, useState } from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import BlogComment from "../../../../../components/blog-comments";
import { Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { blogContentService } from "../../../../../services/blog/content";
import { useQuery } from "@tanstack/react-query";
import { formatDate, formatTime } from "../../../../../utilities/helper";
import { IBlogContentVersion, IBlogContentVersionData } from "../../../../../interfaces/content-marketing/blog/content";
import useResource from "../../../../../hooks/useResource";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import {
    ClassicEditor,
    Base64UploadAdapter,
    List,
    ListProperties,
    Alignment,
    Autoformat,
    AutoImage,
    AutoLink,
    Autosave,
    Bold,
    CKBox,
    CloudServices,
    Code,
    CodeBlock,
    Essentials,
    GeneralHtmlSupport,
    Heading,
    HtmlEmbed,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsert,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Italic,
    Link,
    LinkImage,
    Paragraph,
    PictureEditing,
    SelectAll,
    ShowBlocks,
    SourceEditing,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    Underline,
    Undo
} from "ckeditor5";

import "ckeditor5/ckeditor5.css";
import { formatHistoryDate } from "../../../../../utilities/helper";
import AuthorService from "../../../../../services/content/author";
import CustomLabel from "../../../../../components/mui/custom-label";
import { IAuthor } from "../../../../../interfaces/content/author";
import { blogAssignmentService, blogImageService } from "../../../../../services/blog";
import useUser from "../../../../../hooks/useUser";
import useDebounce from "../../../../../hooks/useDebounce";
import { blogScoreService } from "../../../../../services/blog/scores";
import { joiResolver } from "@hookform/resolvers/joi";
import { blogContentValidation } from "../../../../../validations/content-marketing/content";
import CustomDialog from "../../../../../components/mui/dialog";

interface props {
    activeAction: boolean;
    setActiveAction: (value: boolean) => void;
    commentActiveAction: boolean;
    setCommentActiveAction: (value: boolean) => void;
}

const Content: FC<props> = ({ activeAction, setActiveAction, commentActiveAction, setCommentActiveAction }) => {
    const { control, getValues, setValue, handleSubmit, watch, reset, trigger, formState: { errors } } = useForm<IBlogContent>({
        resolver: joiResolver(blogContentValidation),
        defaultValues: {
            author: "",
            readTime: "",
            content: "",
            metaTitle: "",
            metaDescription: ""
        }
    });
    const { user } = useUser();
    const { id } = useParams();
    const { snackbar } = useSnackbar();
    const { getBlogAssignment } = blogAssignmentService();
    const navigate = useNavigate();
    const { addBlogContent, blogContentAutoSave, getBlogContent, getBlogContentVersions } = blogContentService();
    const { getBlogKeywordDensity } = blogScoreService();
    const { getContentAuthors } = AuthorService();
    const { resourceAllocate } = useResource();
    const [initialRender, setInitialRender] = useState<boolean>(true);
    const [editorInstance, setEditorInstance] = useState<ClassicEditor>();
    const [editorContent, setEditorContent] = useState<string>("");
    const [searchParam] = useSearchParams();
    const searchRecord = useDebounce(editorContent, 1000);

    const getKeywordDensity = useQuery({
        queryKey: ["keyword-density"],
        queryFn: () => getBlogKeywordDensity({ blogId: id }),
    });

    useEffect(() => {
        const saveRecord = async () => {
            if (id === "new") return;
            if (!initialRender) {
                await blogContentAutoSave({ _blog: id, content: searchRecord });
                getKeywordDensity.refetch();
            }
        };
        saveRecord();
    }, [searchRecord]);
    const hitQuery = id !== "new" ? true : false;
    const blogContent = useQuery({
        queryKey: ["blog-content", id],
        queryFn: () => getBlogContent({ _blog: id }),
        enabled: hitQuery
    });
    const blogAssignment = useQuery({
        queryKey: ["blogs-assignment", id],
        queryFn: () => getBlogAssignment({ blogId: id }),
    });
    const submissionDate = blogAssignment?.data?.data?.writer?.submissionDate;
    const isDateInPast = (dateString: string | undefined) => {
        if (!dateString) {
            return false;
        }
        const givenDate = new Date(dateString);
        const currentDate = new Date();
        return givenDate < currentDate;
    };
    const blogContentVersions = useQuery({
        queryKey: ["blog-content-versions", id],
        queryFn: () => getBlogContentVersions({ _blog: id, pagination: true }),
        enabled: hitQuery
    });
    const blogContentVersionsData = blogContentVersions && blogContentVersions.data && blogContentVersions.data.data || [];

    const authorList = useQuery({
        queryKey: ["author-list"],
        queryFn: () => getContentAuthors({ blogId: id, pagination: false }),
        enabled: hitQuery
    });

    useEffect(() => {
        if (activeAction) {
            blogContentVersions.refetch();
        }
    }, [activeAction]);
    useEffect(() => {
        if (blogContent?.data?.data && id !== "new") {
            setValue("author", blogContent?.data?.data?.author || "");
            trigger("author");
            setValue("readTime", blogContent?.data?.data?.readTime || "");
            setValue("content", blogContent?.data?.data?.content || "");
            trigger("content");
            setInitialRender(true);
            setValue("metaTitle", blogContent?.data?.data?.metaTitle || "");
            setValue("metaDescription", blogContent?.data?.data?.metaDescription || "");
            setSubmitDisabled(() => false);
        }
    }, [blogContent?.data?.data]);

    const handleSubmitClick = async () => {
        navigate({
            pathname: `/blog/manage/${id}/checklistWriter`,
            search: searchParam.toString()
        });
    };

    const [fullScreen, setFullScreen] = useState(false);

    const handleFullScreen = () => {
        setFullScreen(true);
    };

    const onSubmit = async (data: IBlogContent) => {
        try {
            if (id === "new") {
                snackbar("Please add basic details First.", "error");
            } else {
                const payload = {
                    ...data,
                    _blog: id
                };
                if (payload.readTime === "") {
                    delete payload.readTime;
                }
                const response = await addBlogContent(payload);
                snackbar(response.message, "info");
                navigate(`/blog/manage/${id}?type=content`);
            }
            blogContentVersions.refetch();
            blogContent.refetch();
            setInitialRender(true);
            setSaveDisabled(true);
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err?.data?.message, "error");
            navigate(`/blog/manage/${id}?type=content`);
            console.log("Error in Adding Content", error);
        }
    };

    const [selectedVersion, setSelectedVersion] = useState<number | null>(null);
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
    const [selectedAccordion, setSelectedAccordion] = useState<number | null>(null);
    const [saveDisabled, setSaveDisabled] = useState<boolean | null>(true);
    const [submitDisabled, setSubmitDisabled] = useState<boolean | null>(true);


    useEffect(() => {
        const subscription = watch((values) => {
            const allFieldsEmpty = Object.values(values).every(value => value === "");
            if (allFieldsEmpty) {
                setSaveDisabled(() => true);
                setSubmitDisabled(() => true);
                return;
            }

            const edited = Object.keys(values).some(name => {
                if (name === "content" || name === "author" || name === "readTime" || name === "metaTitle" || name === "metaDescription") {
                    const fieldValue = values[name] as string;
                    if (blogContent?.data?.data) {
                        const contentFromAPI = blogContent?.data?.data[name] ?? "";
                        return fieldValue !== contentFromAPI;
                    } else {
                        return fieldValue !== "";
                    }
                }
                return false;
            });
            if (user._role.name === "writer") {
                const isPastSubmissionDate = isDateInPast(submissionDate);
                setSaveDisabled(isPastSubmissionDate || !edited);
            } else {
                setSaveDisabled(() => !edited);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, blogContent?.data?.data]);

    const handleCloseFullScreen = () => {
        setFullScreen(false);
    };

    const handleClose = () => {
        setSelectedVersion(null);
        setExpandedIndex(null);
        setSelectedAccordion(null);
        setActiveAction(!activeAction);
    };

    const handleAccordionTabClick = (index: number) => {
        setSelectedAccordion(index);
        setExpandedIndex(index !== expandedIndex ? index : null);
    };

    const [currentVersionData, setCurrentVersionData] = useState<string>("");
    const [previousVersionData, setPreviousVersionData] = useState<string>("");

    const getPreviousVersionDoc = (dataArray:IBlogContentVersion[], currentVersion: number) => {
        
        for (const dateEntry of dataArray) {
          const documents = dateEntry.data;
          for (let i = 0; i < documents.length; i++) {
            const previousVersionDoc = documents.find(doc => doc.version === currentVersion - 1);
            if (previousVersionDoc) {
              return previousVersionDoc.content;
            }
          }
        }
        return "";
      };

    const handleAccordionClick = (itemIndex: number, index: number) => {
        setCurrentVersionData(blogContentVersionsData[index]?.data[itemIndex]?.content || "");
        const getPreviosVersion = getPreviousVersionDoc(blogContentVersionsData, blogContentVersionsData[index]?.data[itemIndex].version);
        setPreviousVersionData(getPreviosVersion);
        navigate({ pathname: `/blog/manage/${id}/view-content/${blogContentVersions?.data?.data[index]?.data[itemIndex]?._id}/`, search: searchParam.toString() });
    };

    const handleProductChange = async (
        value: IAuthor | null,
    ) => {
        if (value) {
            setValue("author", value._id);
            trigger("author");
        }
    };
    const { getBlogImageList } = blogImageService();
    const blogImageList = useQuery({
        queryKey: ["blog-images-list", id],
        queryFn: () =>
          getBlogImageList({
            blogId: id,
            pagination: false,
          }),
        enabled: id !== "new"
      });
    const images = (blogImageList && blogImageList.data && blogImageList.data.data) ?? blogImageList.data?.data;
    const [showImagesDialog, setShowImagesDialog] = useState(false);

    const handleImageClick = () => {
        setShowImagesDialog(true);
    };

    const addImage = (url: string) => {
        if (editorInstance) {
            editorInstance.model.change(writer => {
                const selection = editorInstance.model.document.selection;
                const position = selection.getFirstPosition();

                if (position) {
                    const imageElement = writer.createElement("imageBlock", {
                        src: url,
                        alt: "Image"
                    });
                    writer.insert(imageElement, position);
                    writer.setAttribute("imageStyle", "full", imageElement);
                } 

            });
            const data = editorInstance.getData();
            setEditorContent(data);
            setValue("content", data);
        }
        const imgTag = `<img src="${url}" alt="Image" />`;

    setEditorContent((prevContent) => prevContent + imgTag);
        setShowImagesDialog(false);
    };

    return (
        <div className="blog-content">

            <Grid container spacing={4}>

                {/* content details  */}
                <Grid item {...((activeAction || commentActiveAction) ? { xs: 8 } : { xs: 12 })}>
                    <div className="sub-title">
                        <Typography className="title mb-2" variant="body2">Basic Details</Typography>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={4}>
                                    <Controller
                                        control={control}
                                        name={"author"}
                                        render={(prop) => <Autocomplete
                                            className="disable-text"
                                            options={authorList?.data?.data?.map(
                                                (data) => data
                                            ) || []}
                                            clearIcon={null}
                                            getOptionLabel={(option) => capitalize(option.name)}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                error={errors["author"] ? true : false}
                                                helperText={errors["author"]?.message}
                                                size={"small"}
                                                variant={"outlined"}
                                                label={<CustomLabel label="Author*" />}
                                                placeholder={"Please Select Product"}
                                            />}
                                            {...prop.field}
                                            value={authorList?.data?.data?.find(data => data._id === getValues("author")) || null}
                                            onChange={(e, value) => {
                                                handleProductChange(value);
                                            }}
                                        />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <Box display="flex" alignItems="center">
                                        <Controller
                                            control={control}
                                            name={"readTime"}
                                            render={(prop) => <TextField
                                                label="Read Time"
                                                className="disable-text"
                                                variant="outlined"
                                                size="small"
                                                error={!!errors["readTime"]}
                                                helperText={errors["readTime"]?.message}
                                                onKeyDown={(evt) => ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()}
                                                inputProps={{
                                                    pattern: "[0-9]*",
                                                    inputMode: "numeric",
                                                    maxLength: 5,
                                                    onInput: (e) => {
                                                        e.preventDefault();
                                                        const inputValue = e.currentTarget.value;
                                                        const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
                                                        const truncatedValue = sanitizedValue.slice(0, 5);
                                                        e.currentTarget.value = truncatedValue;
                                                        prop.field.onChange(truncatedValue);
                                                    },
                                                    required: true,
                                                }}
                                                {...prop.field}
                                            />}
                                        />
                                        <Typography className="ml-3" variant="body2">Minutes</Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Typography className="title mt-3 mb-2" variant="body2">Content</Typography>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={12}>
                                    <div className="editor-container mr-2 mb-2">
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={watch("content")}
                                            config={{
                                                toolbar: {
                                                    items: [
                                                        "undo",
                                                        "redo",
                                                        "|",
                                                        "sourceEditing",
                                                        "showBlocks",
                                                        "selectAll",
                                                        "|",
                                                        "heading",
                                                        "|",
                                                        "bold",
                                                        "italic",
                                                        "underline",
                                                        "code",
                                                        "|",
                                                        "link",
                                                        "insertImage",
                                                        "ckbox",
                                                        "insertTable",
                                                        "codeBlock",
                                                        "htmlEmbed",
                                                        "|",
                                                        "alignment",
                                                        "|",
                                                        "bulletedList",
                                                        "numberedList",
                                                    ],
                                                    shouldNotGroupWhenFull: false
                                                },
                                                plugins: [
                                                    Alignment,
                                                    Autoformat,
                                                    Base64UploadAdapter,
                                                    List,
                                                    ListProperties,
                                                    AutoImage,
                                                    AutoLink,
                                                    Autosave,
                                                    Bold,
                                                    CKBox,
                                                    CloudServices,
                                                    Code,
                                                    CodeBlock,
                                                    Essentials,
                                                    GeneralHtmlSupport,
                                                    Heading,
                                                    HtmlEmbed,
                                                    ImageBlock,
                                                    ImageCaption,
                                                    ImageInline,
                                                    ImageInsert,
                                                    ImageInsertViaUrl,
                                                    ImageResize,
                                                    ImageStyle,
                                                    ImageTextAlternative,
                                                    ImageToolbar,
                                                    ImageUpload,
                                                    Italic,
                                                    Link,
                                                    LinkImage,
                                                    Paragraph,
                                                    PictureEditing,
                                                    SelectAll,
                                                    ShowBlocks,
                                                    SourceEditing,
                                                    Table,
                                                    TableCaption,
                                                    TableCellProperties,
                                                    TableColumnResize,
                                                    TableProperties,
                                                    TableToolbar,
                                                    TextTransformation,
                                                    Underline,
                                                    Undo
                                                ],
                                                heading: {
                                                    options: [
                                                        { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
                                                        { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
                                                        { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
                                                        { model: "heading3", view: "h3", title: "Heading 3", class: "ck-heading_heading3" },
                                                        { model: "heading4", view: "h4", title: "Heading 4", class: "ck-heading_heading4" },
                                                        {
                                                            model: "heading5",
                                                            view: "h5",
                                                            title: "Heading 5",
                                                            class: "ck-heading_heading5"
                                                        },
                                                        {
                                                            model: "heading6",
                                                            view: "h6",
                                                            title: "Heading 6",
                                                            class: "ck-heading_heading6"
                                                        }
                                                    ]
                                                },
                                                htmlSupport: {
                                                    allow: [
                                                        {
                                                            name: /^.*$/,
                                                            styles: true,
                                                            attributes: true,
                                                            classes: true
                                                        }
                                                    ]
                                                },
                                                image: {
                                                    toolbar: [
                                                        "toggleImageCaption",
                                                        "imageTextAlternative",
                                                        "|",
                                                        "imageStyle:inline",
                                                        "imageStyle:wrapText",
                                                        "imageStyle:breakText",
                                                        "|",
                                                        "resizeImage",
                                                        "|",
                                                        "ckboxImageEdit"
                                                    ]
                                                },
                                                table: {
                                                    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableProperties", "tableCellProperties"]
                                                }
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setInitialRender(false);
                                                setEditorContent(data);
                                                setValue("content", data);
                                            }}
                                            onReady={editor => {
                                                setEditorInstance(editor);
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>

                            <Box className="full-screen-box mr-2">
                                <Box display="flex">
                                    <Typography variant="body1">Total Words:</Typography>
                                    <Typography className="ml-2" variant="body1" >{getKeywordDensity?.data?.data?.totalWords}</Typography>
                                </Box>

                                <Box className="images-box">
                                    <Button variant="outlined" onClick={handleImageClick}>
                                        Add images
                                    </Button>

                                    <Button variant="outlined" onClick={handleFullScreen}>
                                        Edit in full screen
                                    </Button>
                                </Box>

                            </Box>


                            <Typography className="title mt-2 mb-2" variant="body2">SEO</Typography>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={4}>
                                    <Controller
                                        control={control}
                                        name={"metaTitle"}
                                        render={(prop) => <TextField
                                            label={<CustomLabel label="Meta Title" required={true} />}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            placeholder="Type meta title"
                                            error={errors["metaTitle"] ? true : false}
                                            helperText={errors["metaTitle"]?.message}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <Controller
                                        control={control}
                                        name={"metaDescription"}
                                        render={(prop) => <TextField
                                            label={<CustomLabel label="Meta Description" required={true} />}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            placeholder="Type meta description"
                                            error={errors["metaDescription"] ? true : false}
                                            helperText={errors["metaDescription"]?.message}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                            </Grid>
                            <div className="mt-4">
                                <Button
                                    variant="outlined"
                                    onClick={() => reset()}
                                    disabled={resourceAllocate("cms-blog-content.write") ? false : true}
                                >Discard</Button>
                                <Button
                                    type="submit"
                                    className="ml-2"
                                    disabled={resourceAllocate("cms-blog-content.write") && !saveDisabled ? false : true}
                                >Save Changes</Button>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={handleSubmitClick}
                                    className="ml-2"
                                    disabled={resourceAllocate("cms-blog-content.write") && saveDisabled && !submitDisabled ? false : true}
                                >Submit</Button>
                            </div>
                        </form>
                    </div>
                </Grid >
                {/* content vision history */}
                {
                    activeAction && <Grid item xs={4}>
                        <div className="timeline">
                            <header>
                                <Typography variant="body1">Version History</Typography>
                                <IconButton color="primary"><CloseIcon color="action" onClick={() => handleClose()} /></IconButton>
                            </header>

                            <div className="note-container">

                                {blogContentVersions?.data?.data?.map((item: IBlogContentVersion, index: number) => (

                                    <div key={index} className="timelineBox">
                                        <div className="mt-1 mb-2" style={{ marginLeft: "12px" }}>
                                            <Typography variant="caption" className="history" >{formatHistoryDate(item._id)}</Typography>
                                        </div>
                                        <Accordion sx={{ border: "none", boxShadow: "none" }} expanded={expandedIndex === index}>
                                            {item?.data?.map((itemData: IBlogContentVersionData, itemIndex: number) => (
                                                <div key={itemIndex} className="timelineBox" >
                                                    {itemIndex === 0 ?
                                                        <div >
                                                            <AccordionSummary
                                                                sx={{
                                                                    backgroundColor:
                                                                        (index === selectedAccordion && itemIndex === selectedVersion) ||
                                                                            (selectedAccordion === null && selectedVersion === null && itemData?.currentVersion)
                                                                            ? "#f5f5f5"
                                                                            : "white",
                                                                }}
                                                                expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} onClick={() => handleAccordionTabClick(index)} />}>
                                                                <div className="accordian-details" onClick={() => handleAccordionClick(itemIndex, index)}>
                                                                    <div className="date">
                                                                        <Typography variant="caption" style={{ fontWeight: "bolder", marginRight: "4px" }}>{formatDate(itemData?.date)}</Typography>
                                                                        { !itemData?.currentVersion && <Typography variant="caption">{formatTime(itemData?.date)}</Typography> }

                                                                    </div>
                                                                    {itemData?.currentVersion && <Typography variant="caption" style={{ color: "blue" }}>current Version</Typography>}
                                                                    <Typography variant="caption">{capitalize(itemData?.createdBy?.name)}</Typography>
                                                                </div>
                                                            </AccordionSummary>
                                                        </div>
                                                        :
                                                        <div
                                                            style={{ backgroundColor: itemIndex === selectedVersion ? "#f5f5f5" : "white", }}>

                                                            <AccordionDetails
                                                                style={{ marginLeft: "20px", cursor: "pointer" }}

                                                            >
                                                                <div style={{ display: "flex", flexDirection: "column" }} onClick={() => handleAccordionClick(itemIndex, index)}>
                                                                    <div style={{ display: "flex", flexDirection: "row" }}>

                                                                        <Typography variant="caption" style={{ fontWeight: "bolder", marginRight: "4px" }}>{formatDate(itemData?.date)}</Typography>
                                                                        <Typography variant="caption">{formatTime(itemData?.date)}</Typography>

                                                                    </div>
                                                                    <Typography variant="caption">{capitalize(itemData?.createdBy?.name)}</Typography>
                                                                </div>
                                                            </AccordionDetails>

                                                        </div>
                                                    }
                                                </div>
                                            ))}
                                        </Accordion>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Grid>
                }
                {
                    commentActiveAction && <Grid item xs={4}>
                        <BlogComment
                            commentType="BLOG_CONTENT"
                            activeAction={commentActiveAction}
                            setActiveAction={setCommentActiveAction}
                        />
                    </Grid>
                }
            </Grid >
            <div>
                <Dialog
                    fullScreen
                    open={fullScreen}
                    className="fullScreenEditor"
                    onClose={handleCloseFullScreen}
                >
                    <CKEditor
                        editor={ClassicEditor}
                        data={watch("content")}
                        config={{
                            toolbar: {
                                items: [
                                    "undo",
                                    "redo",
                                    "|",
                                    "sourceEditing",
                                    "showBlocks",
                                    "selectAll",
                                    "|",
                                    "heading",
                                    "|",
                                    "bold",
                                    "italic",
                                    "underline",
                                    "code",
                                    "|",
                                    "link",
                                    "insertImage",
                                    "ckbox",
                                    "insertTable",
                                    "codeBlock",
                                    "htmlEmbed",
                                    "|",
                                    "alignment",
                                    "|",
                                    "bulletedList",
                                    "numberedList",
                                ],
                                shouldNotGroupWhenFull: false
                            },
                            plugins: [
                                Alignment,
                                Autoformat,
                                Base64UploadAdapter,
                                List,
                                ListProperties,
                                AutoImage,
                                AutoLink,
                                Autosave,
                                Bold,
                                CKBox,
                                CloudServices,
                                Code,
                                CodeBlock,
                                Essentials,
                                GeneralHtmlSupport,
                                Heading,
                                HtmlEmbed,
                                ImageBlock,
                                ImageCaption,
                                ImageInline,
                                ImageInsert,
                                ImageInsertViaUrl,
                                ImageResize,
                                ImageStyle,
                                ImageTextAlternative,
                                ImageToolbar,
                                ImageUpload,
                                Italic,
                                Link,
                                LinkImage,
                                Paragraph,
                                PictureEditing,
                                SelectAll,
                                ShowBlocks,
                                SourceEditing,
                                Table,
                                TableCaption,
                                TableCellProperties,
                                TableColumnResize,
                                TableProperties,
                                TableToolbar,
                                TextTransformation,
                                Underline,
                                Undo
                            ],
                            heading: {
                                options: [
                                    { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
                                    { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
                                    { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
                                    { model: "heading3", view: "h3", title: "Heading 3", class: "ck-heading_heading3" },
                                    { model: "heading4", view: "h4", title: "Heading 4", class: "ck-heading_heading4" },
                                    {
                                        model: "heading5",
                                        view: "h5",
                                        title: "Heading 5",
                                        class: "ck-heading_heading5"
                                    },
                                    {
                                        model: "heading6",
                                        view: "h6",
                                        title: "Heading 6",
                                        class: "ck-heading_heading6"
                                    }
                                ]
                            },
                            htmlSupport: {
                                allow: [
                                    {
                                        name: /^.*$/,
                                        styles: true,
                                        attributes: true,
                                        classes: true
                                    }
                                ]
                            },
                            image: {
                                toolbar: [
                                    "toggleImageCaption",
                                    "imageTextAlternative",
                                    "|",
                                    "imageStyle:inline",
                                    "imageStyle:wrapText",
                                    "imageStyle:breakText",
                                    "|",
                                    "resizeImage",
                                    "|",
                                    "ckboxImageEdit"
                                ]
                            },
                            table: {
                                contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableProperties", "tableCellProperties"]
                            }
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setInitialRender(false);
                            setEditorContent(data);
                            setValue("content", data);
                        }}
                    />
                    <Button variant="contained" onClick={handleCloseFullScreen} style={{borderRadius: 0}}>
                        Exit full screen
                    </Button>
                </Dialog>
            </div>
            <CustomDialog
                title={"Select Image"}
                isOpen={showImagesDialog}
                onClose={() => setShowImagesDialog(false)}
                disabled
            >
                {(images && images.length) ? <Grid container spacing={2}>
                    {images.map((image, i) => <Grid key={i} item xs={2}>
                        <img src={image.imageUrl} className="w-100 h-100 cursor-pointer" onClick={() => addImage(image.imageUrl)} />
                    </Grid>)}
                </Grid> :
                <div className="center">Unfortunately, no images are currently available!</div>
                }
            </CustomDialog>
            <Outlet context={{ reFetchContent: blogContent.refetch, reFetchVersions: blogContentVersions.refetch, getPreviousVersionData: previousVersionData, getCurrentVersionData: currentVersionData }} />
        </div >
    );
};

export default Content;