import "./style.scss";
import { FC, ReactNode } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    marginBottom: 0, 
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    marginTop: 0, 
  },
}));
export interface DialogTitleProps {
  id: string;
  children?: ReactNode;
  onClose: () => void;
}
function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
interface TestInputDialogProps {
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  title: string;
  isOpen: boolean;
  disabled?: boolean;
  confirmText?: string;
  cancelText?: string;
  onClose: () => void;
  children: ReactNode;
  primaryButton?: {
    name: string;
    color:
      | "inherit"
      | "primary"
      | "secondary"
      | "success"
      | "error"
      | "info"
      | "warning";
    onClick: () => void;
  };
  secondaryButton?: {
    name: string;
    color:
      | "inherit"
      | "primary"
      | "secondary"
      | "success"
      | "error"
      | "info"
      | "warning";
    onClick: () => void;
  };
}
const TestInputDialog: FC<TestInputDialogProps> = ({
  size,
  title,
  isOpen,
  disabled,
  confirmText,
  cancelText,
  onClose,
  children,
  primaryButton,
  secondaryButton,
}) => (
  <BootstrapDialog
    onClose={onClose}
    aria-labelledby="customized-dialog-title"
    open={isOpen}
    maxWidth={size}
  >
    <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
      {title}
    </BootstrapDialogTitle>
    <DialogContent dividers sx={{ paddingBottom: 0, marginBottom: 0 }}>
      {children}
    </DialogContent>
   {(primaryButton || secondaryButton) && <DialogActions sx={{ paddingTop: "1rem", marginTop: "1rem" }}>
     { secondaryButton && <Button
        onClick={secondaryButton.onClick}
        color={secondaryButton.color}
        disabled={disabled}
        variant="contained"
      >
        {secondaryButton.name || cancelText || "Discard"}
      </Button>
      }
      { primaryButton &&
      <Button
        onClick={primaryButton.onClick}
        sx={{
          color: (theme) => theme.palette.getContrastText("#DFE1E4"),
          backgroundColor: "#DFE1E4",
          "&:hover": {
            backgroundColor: "#DFE1E4",
          },
        }}
        disabled={disabled}
      >
        {primaryButton.name || confirmText || "Save changes"}
      </Button>
      }
    </DialogActions>
    }
  </BootstrapDialog>
);

TestInputDialog.defaultProps = {
  size: "md",
  disabled: false,
};
export default TestInputDialog;