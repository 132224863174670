import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import Editor from "@monaco-editor/react";
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { Autocomplete, Box, capitalize, Grid, MenuItem, TextField, Typography } from "@mui/material";

import { IErrorResponse, IField } from "../../../../../../interfaces";
import useSnackbar from "../../../../../../hooks/useSnackbar";
import { useOutletContext } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import CustomDialog from "../../../../../../components/mui/dialog";

import { TechnicalQuestionService } from "../../../../../../services";
import CustomLabel from "../../../../../../components/mui/custom-label";
import { addTechnicalQuestionTemplateValidation } from "../../../../../../validations";

interface IOutletProps {
	reFetch: () => void;
}

interface IAddTemplate {
	_language: string;
	content: string;
}

export interface ITemplateField extends IField {
	name: "_language" | "content";
}

const ManageQuestionTemplates = () => {
	const navigate = useNavigate();
	const { snackbar } = useSnackbar();
	const { id, templateId } = useParams();

	const { addTechnicalQuestionTemplate, updateTechnicalQuestionTemplate, getTechnicalQuestionLanguages, getTechnicalQuestionTemplate } = TechnicalQuestionService();
	const [language, setLanguage] = useState<string>("");
	const outlet = useOutletContext<IOutletProps>();
	const editorRef = useRef<{ focus: () => void; }>();
	const [editorValue, setEditorValue] = useState<string | undefined>("");
	const [searchParam] = useSearchParams();
	const hitQuery = id !== "new";
	const { handleSubmit, getValues, setValue, control, formState: { errors } } = useForm<IAddTemplate>({
		resolver: joiResolver(addTechnicalQuestionTemplateValidation),
		defaultValues: {
			_language: "",
			content: ""
		}
	});
	const languages = useQuery(
		{
			queryKey: ["languages"],
			queryFn: () => getTechnicalQuestionLanguages({ _id: id }),
			enabled: hitQuery
		}
	);

	const templateData = useQuery(
		{
			queryKey: ["template", templateId],
			queryFn: () => getTechnicalQuestionTemplate({ _id: id, _template: templateId }),
			enabled: templateId !== "new"
		}
	);
	useEffect(() => {
		if (templateData.data?.data && templateId !== "new") {
			setValue("content", templateData.data.data.content ?? "");
			setEditorValue(templateData.data.data.content ?? "");
			setLanguage(templateData.data.data.language.language ?? "");
			setValue("_language", templateData.data.data.language._id ?? "");
		}
	}, [templateData.data?.data]);
	const onMount = (editor: { focus: () => void; }) => {
		editorRef.current = editor;
		editor.focus();
	};

	const fields: ITemplateField[] = [
		{
			name: "_language",
			label: "Language",
			type: "select",
			placeholder: "Select Language",
			width: 12,
			children: (languages && languages.data && languages.data.data)?.map((language) => (
				<MenuItem key={language._id} value={language.language.toUpperCase()}>
					{capitalize(language.language)}
				</MenuItem>
			))
		},
		{
			name: "content",
			label: "Content",
			type: "input",
			placeholder: "Enter content",
			width: 12
		}
	];

	const onSubmit = async (data: IAddTemplate) => {
		try {
			let response;
			if (templateId === "new") {
				response = await addTechnicalQuestionTemplate({ _id: id, ...data });
			} else {
				response = await updateTechnicalQuestionTemplate({ _id: id, _template: templateId, ...data });
			}
			snackbar(response.message, "info");
			console.log(outlet.reFetch);
			outlet.reFetch && outlet.reFetch();
			navigate({ pathname: `/configurations/technical-questions/${id}`, search: searchParam.toString() });
		} catch (error) {
			const err = error as IErrorResponse;
			snackbar(err?.data?.message, "error");
			console.log("Error in Adding Goal", error);
		}
	};

	return (
		<CustomDialog
			title={templateId === "new" ? "Add Question Template" : "Edit Question Template"}
			isOpen={!!templateId}
			onClose={() => navigate({ pathname: `/configurations/technical-questions/${id}`, search: searchParam.toString() })}
			onSubmit={handleSubmit(onSubmit)}
		>
			<Grid container spacing={4}>
				{fields.map((field) => {
					if (field.type === "select") {
						return (
							<Grid key={field.name} item xs={field.width || 12}>
								<Controller
									control={control}
									name={field.name}
									render={(prop) => <Autocomplete
										className="disable-text"
										options={(languages && languages.data && languages.data.data || []).map(
											(data) => data
										) || []}
										clearIcon={null}
										getOptionLabel={(option) => capitalize(option.language)}
										renderInput={(params) => <TextField
											{...params}
											error={!!errors[field.name]}
											helperText={errors[field.name]?.message}
											size={"small"}
											variant={"outlined"}
											label={<CustomLabel label="Language*" />}
											placeholder={"Please Select language"}
										/>}
										{...prop.field}
										value={(languages && languages.data && languages.data.data || []).find(data => data._id === getValues("_language")) || null}
										onChange={(e, value) => {
											if (value) {
												setValue("_language", value._id ?? "");
												setLanguage(value.language);
											}
										}}
									/>}
								/>
							</Grid>
						);
					} else {
						return (
							<Grid key={field.name} item xs={field.width || 12}>
								<Typography variant="body1" fontWeight={600} mb={1}>
									{field.label}:
								</Typography>
								<Box height="calc(100vh - 450px)" style={{ overflow: "hidden" }}>
									<Editor
										height="100%"
										language={language}
										value={editorValue}
										theme="vs-dark"
										options={{
											wordWrap: "on",
											minimap: { enabled: false },
											lineNumbersMinChars: 3,
											folding: false,
											fontSize: 16,
											scrollBeyondLastLine: false,
											automaticLayout: true,
										}}
										onMount={onMount}
										onChange={(value) => {
											setEditorValue(value);
											setValue("content", value ?? "");
										}}
									/>
								</Box>
							</Grid>
						);
					}
				})}
			</Grid>
			<Outlet />
		</CustomDialog>
	);
};

export default ManageQuestionTemplates;
