import "./style.scss";
import { MouseEvent } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useQuery } from "@tanstack/react-query";
import { SkillMatrixService } from "../../../services/skill-matrix";
import { ChangeEvent, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Outlet, useNavigate, useOutletContext } from "react-router";
import { ISkillMatrixUserState } from "../../../interfaces";
import Header from "../../../components/header";
import useDebounce from "../../../hooks/useDebounce";
import WarningDialog from "../../../components/mui/warning-dialog";
import useSnackbar from "../../../hooks/useSnackbar";
import useResource from "../../../hooks/useResource";
import SkillsTable from "./skill-table";
import SkillCategoryFilters from "./filter";
interface IOutletProps {
  refetchSkillMatrix: () => void;
  reFetch:() => void
}

const EditSkillMatrix = () => {
 const {resourceAllocate} = useResource();
  const navigate = useNavigate();
  const { id } = useParams();
  const {snackbar} = useSnackbar();
  const outlet = useOutletContext<IOutletProps>();
  const { getSkillMatrix, deleteSkillMatrix,exportSkillMatrixs, getSkillMatrixItems} = SkillMatrixService();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState<string>("");
  const hitQuery = (id === "new" || id === "view") ? false : true;
  const [state, setState] = useState<ISkillMatrixUserState>({
    pagination: {
      page: 1,
      limit: 10,
      totalPages: 1  
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filters: {
      search: "",
      _skillCategory: [],
    },
    filterCount: 0,
    _user:"",
    deleteWarning:false,
    _skillItem:"",
    deleteWarningSkillItem:false,
    editEmployee: false,
    _employeeId: "",
    editSkill: false,
    _skillId: "",
    _deletedCategoryId: ""
  });

  const userSkills = useQuery({
    queryKey: [hitQuery, state.pagination.page, state.filters.search],
    queryFn: () => getSkillMatrix({
      _id: id, pagination: false, search: state.filters.search
    }), enabled: hitQuery
  });

  const skillItemsData = useQuery({
    queryKey: ["skillItemsData", state.pagination.page, state.filters._skillCategory],
    queryFn: () => getSkillMatrixItems({
      _skillParameter: id, pagination: true, limit: state.pagination.limit, page: state.pagination.page, _skillCategory: state.filters._skillCategory
    })
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";

    const _skillCategory: { key: string, value: string }[] = searchParams.get("_skillCategory") ? JSON.parse(String(searchParams.get("_skillCategory"))) : [];
    filterCount += _skillCategory.length ? 1 : 0;

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        search,
        _skillCategory: _skillCategory.map(item => item.key),
      },
      filterCount
    }));
  }, [searchParams]);

  useEffect(() => {
      navigate({ pathname: "update-skill/" + state._employeeId, search: searchParams.toString() });
  }, [state.editEmployee]);

  useEffect(() => {
      navigate({ pathname: "" + state._skillId, search: searchParams.toString() });
  }, [state.editSkill]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleDelete = (_user="") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning:!prevState.deleteWarning,
      _user
    }));
  };

  const onDelete = async () => {
    const response = await deleteSkillMatrix({_id:state._user});
    snackbar(response.message,"info");
    handleDelete();
    userSkills.refetch();
  };
  const onExport = async () => {
    try{
      const response = await exportSkillMatrixs({skillParameterId:id});
      const binaryData = atob(response?.data);
      const bytes = new Uint8Array(binaryData.length);
      for(let i = 0;i < binaryData.length; i++ ){
        bytes[i] = binaryData.charCodeAt(i);
      }
      const blob = new Blob([bytes],{type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
      const file = new File([blob], "temp.xlsx", { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const link = document.createElement("a");
      document.body.appendChild(link);
      link.setAttribute("href",window.URL.createObjectURL(file));
      link.setAttribute("download", "SkillMatrixData.xlsx");
      link.click();
    
    }catch(error){
     console.log("Error in Export of skill-matrix",error);
     snackbar("Error in Export of skill-matrix","error");
    }
  };

  return (
    <>
      {/* Add Data  */}
      <Box display={"flex"} alignItems="center">
          <Tooltip title="Back">
            <IconButton className="back mr-1" onClick={() => navigate("/skill-matrix")}>
              <ArrowBackOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Header
            className="w-100"
            searchPlaceholder="Search by employee name"
            onSearch={onSearch}
            btnText="Add Skill"
            anotherBtnText ="Add Employee"
            anotherBtnText2 = "Add Category"
            onBtnClick={resourceAllocate("skill-matrix-item.write") ?() => navigate({ pathname: "add-action", search: "?type=skill" + searchParams.toString() }): undefined}
            anotherOnBtnClick={resourceAllocate("skill-user-mapping.write") ? () =>navigate({ pathname: "add-action", search: "?type=employee" + searchParams.toString() }): undefined}
            anotherOnBtnClick2={resourceAllocate("skill-matrix-category.write") ? () =>navigate({pathname: "category/new", search: searchParams.toString()}): undefined}
            onImport={resourceAllocate("skill-matrix-item.write") ? () => navigate("import") : undefined}
            onExport= {onExport}
            onFilter={openFilter}
            filterCount={state.filterCount}
          >
            <SkillCategoryFilters
              anchorEl={state.filterDialog.anchorEl}
              isOpen={state.filterDialog.isOpen}
              OnClose={closeFilter}
              deletedCategoryId={state._deletedCategoryId}
            />
          </Header>
      </Box>
    
      {/* Show Data  */}
      <Box marginTop="10px">
        <SkillsTable 
          skillData={skillItemsData && skillItemsData.data && skillItemsData.data.data || []} 
          employees={userSkills && userSkills.data && userSkills.data.data || []} 
          onPageChange={onPageChange} 
          pagination={state.pagination}
          setState={setState}
          reFetchUserSkills={userSkills.refetch}
          reFetchSkillItemData={skillItemsData.refetch}
        />
      </Box>
      <WarningDialog
      isOpen = {state.deleteWarning}
      onClose = { () => handleDelete()}
      onConfirm={onDelete}
      title="Delete Skill Matrix"
      description="Are you sure you want to delete this skill matrix?"
      />
      <Outlet context={{ ...outlet, reFetchUserSkills: userSkills.refetch, reFetchSkillItemData :skillItemsData.refetch}} />
    </>
  );
};

export default EditSkillMatrix;  