import "./style.scss";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { ITemplate } from "../../../../interfaces";
import { Box, Tab, Tabs } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { capitalize, formatMobileNumber, displayName } from "../../../../utilities/helper";
import { BootstrapDialog, BootstrapDialogTitle, TabPanel, a11yProps } from "../../../../components/shared/mui-tabs";
import Mail from "./mail";
import CalenderInvite from "./calender-invite";
import useUser from "../../../../hooks/useUser";
import DialogContent from "@mui/material/DialogContent";
import { cpdDriveService } from "../../../../services";

const redirectionLinks = ["mail", "calendar-invite"];

const CpdDriveActionLayout = () => {
    const navigate = useNavigate();
    const [...templates] = useSelector<{ template: { list: ITemplate[] } }, ITemplate[]>(state => state.template.list) || [];
    const { id } = useParams();
    const { user } = useUser();
    const [value, setValue] = useState(0);
    const { getCpdDrive } = cpdDriveService();
    const [searchParam, setSearchParam] = useSearchParams();
    const cpdDrive = useQuery({
        queryKey: ["cpdDrive"],
        queryFn: () => getCpdDrive({ _id: id })
    });

    useEffect(() => {
        const tab = searchParam.get("type") ? String(searchParam.get("type")) : "mail";
        setValue(redirectionLinks.indexOf(tab));
    }, [searchParam]);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        searchParam.set("type", redirectionLinks[newValue]);
        setSearchParam(searchParam);
    };

    const onClose = () => {
        searchParam.delete("type");
        navigate({
            pathname: "/cpd/drives",
            search: searchParam.toString()
        });
    };

    const keywords = {
        userName: `${capitalize(displayName(user))}`,
        userNumber: formatMobileNumber(user?.phone),
        userDepartment: capitalize(user?.department?.name),
        userDesignation: user?.designation || "",
        cpdLink:`${String(process.env.REACT_APP_CPD_URL)}/?cpdId=${cpdDrive?.data?.data?.cpdId}` 
    };

    const name = cpdDrive.data?.data?.collegeId.name ? capitalize(cpdDrive.data?.data?.collegeId.name) : "CPD Drive Actions";

    return <div>
        <BootstrapDialog
            maxWidth="md"
            onClose={onClose}
            open={id ? true : false}
        >
            <BootstrapDialogTitle onClose={onClose}>
                <Box display="flex" alignItems="center">
                    {name}
                </Box>
            </BootstrapDialogTitle>
            <DialogContent dividers sx={{ height: "60vh" }}>
                <Box width="100%" >
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab className="tabs-space start-tab-space" label="Mail" {...a11yProps(0)} />
                            <Tab className="tabs-space" label="Calendar Invite" {...a11yProps(1)} />
                        </Tabs>
                    </Box>

                    {/* Mail  */}
                    <TabPanel value={value} index={0}>
                        <Mail
                            cpdDrive={cpdDrive.data?.data}
                            templates={templates.filter(template => template.type === "email" && template.tag === "general")}
                            keywords={keywords}
                        />
                    </TabPanel>

                    {/* Calender Invite  */}
                    <TabPanel value={value} index={1}>
                        <CalenderInvite
                            cpdDrive={cpdDrive.data?.data}
                            templates={templates.filter(template => template.type === "email" && template.tag === "google_calendar")}
                            keywords={keywords}
                        />
                    </TabPanel>
                </Box>
            </DialogContent>
        </BootstrapDialog>
    </div>;
};

export default CpdDriveActionLayout;
