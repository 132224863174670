import Joi from "joi";
import { required } from "../../../shared";

export const vendorNoteValidation = Joi.object({
    note: Joi.string()
        .required()
        .min(5)
        .trim()
        .label("Note")
        .messages({
            "string.empty": required,
        }),
});