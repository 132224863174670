import "../../../../../components/shared/filter/style.scss";
import { FC, useEffect, useState } from "react";
import { Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton, ListItemText, Menu, Typography } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs"; 
import { blogInsightFilterProps, IBlogsInsightFilter } from "../../../../../interfaces/blogs-insights";
import { capitalize, checkTimePeriod } from "../../../../../utilities/helper";
import { MenuItem } from "../../../../../components/shared/filter";

const BlogInsightFilter: FC<blogInsightFilterProps> = ({ anchorEl, isOpen, OnClose }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const dateOptions = [
        { key: "yesterday", value: "Yesterday" },
        { key: "today", value: "Today" },
        { key: "thisWeek", value: "Weekly" },
        { key: "thisMonth", value: "Monthly" },
        { key: "thisQuarter", value: "Quarterly" },
    ];

    const [state, setState] = useState<IBlogsInsightFilter>({ 
        selectedMenu: 0,
        date: []
    });

    useEffect(() => {
        const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];

        setState(prevState => ({
            ...prevState,
            date
        }));
    }, [searchParams]);


    const handleLeftListItem = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setState(prevState => ({
            ...prevState,
            selectedMenu: index
        }));
    };

    const handleDateListItem = (name: "date", key: string, value: string) => {
        const date = checkTimePeriod(key);
    
        const payload: Array<{
          key: string;
          value: string;
          startDate: string,
          endDate: string,
        }> = [{
          key,
          value,
          startDate: date.startDate,
          endDate: date.endDate,
        }];
    
        setState(prevState => ({
          ...prevState,
          [name]: payload
        }));
      };

      const handleDate = (e: dayjs.Dayjs | null, period: "start" | "end") => {
        const newDoj = e ? dayjs(e).toISOString() : "";
        let date: Array<{
          key: string;
          value: string;
          startDate: string,
          endDate: string,
        }> = [];
    
        if (state.date.length && period === "start") {
          date = state.date.map(e => ({ ...e, startDate: newDoj, value: "custom", key: "Custom" }));
        } else if (state.date.length && period === "end") {
          date = state.date.map(e => ({ ...e, endDate: newDoj, value: "custom", key: "Custom" }));
        } else if (!state.date.length && period === "start") {
          const currentDate = new Date();
          currentDate.setHours(23,59,59);
          date = [{
            key: "custom",
            value: "custom", 
            startDate:  dayjs(e).startOf("day").toISOString(),
            endDate: currentDate.toISOString()
          }];
        } else {
          const currentDate = new Date();
          currentDate.setHours(0,0,0,0);
          date = [{
            key: "custom",
            value: "custom",
            startDate: currentDate.toISOString(),
            endDate: dayjs(e).endOf("day").toISOString(),
          }];
        }
    
        setState(prevState => ({
          ...prevState,
          date
        }));
      };

    

    const deleteChip = (name: "date", key: string) => {
        if (name === "date") {
            const payload: Array<{
                key: string;
                value: string;
                startDate: string;
                endDate: string;
            }> = state.date.filter(ele => ele.key !== key);
    
            setState(prevState => ({
                ...prevState,
                date: payload
            }));
        } else if (name === "url") {
            setState(prevState => ({
                ...prevState,
                url: []
            }));
        }
    };


    const resetFilter = () => {
        setState({
            selectedMenu: 0,
            date: []
        });
    };

    useEffect(() => {
        if (!searchParams.get("date")) {
            const today = new Date();
            const lastWeek = new Date();
            lastWeek.setDate(today.getDate() - 7);
    
            const defaultDateFilter = [{
                key: "thisWeek",
                value: "Weekly",
                startDate: lastWeek.toISOString(),
                endDate: today.toISOString(),
            }];
    
            searchParams.set("date", JSON.stringify(defaultDateFilter));
            setSearchParams(searchParams);
        }
    }, []);

    const onApply = () => {
        searchParams.set("date", JSON.stringify(state.date));
        setSearchParams(searchParams);
        OnClose();
    };

    const onClose = () => {
        const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
    
        setState(prevState => ({
            ...prevState,
            date
        }));
        OnClose();
    };

    return <>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={OnClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            <Box id="filters-container">
                <Box className="center mb-3" justifyContent="space-between" alignItems="start">
                    <div className="active-filter mb-1">
                        {
                            (state.date.length) ?
                                <>
                                    {state.date.map(ele => <Chip 
                                        key={ele.key} 
                                        className="m-1" 
                                        icon={<CalendarMonthIcon />} 
                                        color="primary" 
                                        onDelete={() => deleteChip("date", ele.key)} 
                                        label={ele.value} 
                                        variant="outlined" 
                                    />)}
                                </>
                                :
                                <Box className="mt-2" display="flex" alignItems="center">
                                    <FilterAltOffIcon />
                                    <Typography className="ml-2">No filters are applied</Typography>
                                </Box>
                        }
                    </div>
                    <IconButton 
                        onClick={onClose}
                        style={{ marginRight: "-10px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Grid className="filter-box" container>
                    <Grid id="left" item xs={5}>
                        <List component="nav">

                            <MenuItem
                                index={0}
                                label="Date"
                                selectedMenu={state.selectedMenu === 0}
                                onChange={handleLeftListItem}
                                count={state.date}
                            />
                        </List>
                    </Grid>
                    <Divider orientation="vertical" />

                    <Grid id="right" item xs={6}>
                        <List component="nav">
                            {
                                state.selectedMenu === 0 &&
                                <>
                                {dateOptions.map((date) =>
                                    <ListItemButton
                                    key={date.key}
                                    selected={!!state.date.find(ele => ele.key === date.key)}
                                    onClick={() => handleDateListItem("date", date.key, capitalize(date.value))}
                                    >
                                    <ListItemText primary={date.value} />
                                    <Checkbox edge="end" checked={!!state.date.find(ele => ele.key === date.key)} />
                                    </ListItemButton>
                                )}
                                <Box marginTop={2}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        value={state.date[0]?.startDate ? dayjs(state.date[0]?.startDate) : null}
                                        onChange={e => handleDate(e, "start")}
                                        label="Start Date"
                                        format="LL"
                                    />
                                    </LocalizationProvider>
                                    <div className="mt-3" />
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        value={state.date[0]?.endDate ? dayjs(state.date[0]?.endDate) : null}
                                        onChange={e => handleDate(e, "end")}
                                        label="End Date"
                                        format="LL"
                                    />
                                    </LocalizationProvider>
                                </Box>
                                </>

                            }
                        </List>

                    </Grid>
                </Grid>

                <Box className="actions-btn" marginTop="8px" textAlign="end">
                    <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
                    <Button className="ml-2" onClick={onApply}>Apply</Button>
                </Box>
            </Box>
        </Menu>
    </>;
};

export default BlogInsightFilter;