import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { StatusService } from "../../../../services";
import { joiResolver } from "@hookform/resolvers/joi";
import { Controller, useForm } from "react-hook-form";
import { statusValidation } from "../../../../validations";
import { IStatusField, IStatus, IErrorResponse } from "../../../../interfaces";
import { Box, Grid, MenuItem, TextField } from "@mui/material";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import Select from "../../../../components/mui/select";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
interface outletProps {
  reFetch: () => void;
  refetchStatuss: () => void;
}

const ManageStatus = () => {
  const { id } = useParams();
  const [searchParam] = useSearchParams();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const hitQuery = (id === "new" || id === "view") ? false : true;
  const navigate = useNavigate();
  const { addStatus, getStatus, updateStatus } = StatusService();
  const status = useQuery({ queryKey: [hitQuery], queryFn: () => getStatus({ _id: id }), enabled: hitQuery });
  const { handleSubmit, control, setValue, watch, formState: { errors } } = useForm<IStatus>({
    resolver: joiResolver(statusValidation),
    defaultValues: {
      name: "",
      status: "ACTIVE",
      type: "CANDIDATE",
      category: "OPEN"
    }
  });

  useEffect(() => {
    if (id === "new") {
      console.log("new");
    } else {
      if (status.data?.data) {
        setValue("name", status.data.data?.name || "");
        setValue("type", status.data.data?.type || "");
        setValue("category", status.data.data?.category || "");
      }
    }
  }, [id, status.data]);


  const onSubmit = async (data: IStatus) => {
    try {
      if (id === "new") {
        const payload = { ...data, name: data.name.toUpperCase() };
        const add = await addStatus(payload);
        snackbar(add.message, "info");
        navigate("/configurations/status");
        outlet?.reFetch && outlet.reFetch();
        outlet?.refetchStatuss && outlet.refetchStatuss();
      } else {
        const payload = { ...data, _id: id };
        const update = await updateStatus({ ...payload, _id: id });
        snackbar(update.message, "info");
        navigate({
          pathname: "/configurations/status",
          search: searchParam.toString()
        });
        outlet?.reFetch && outlet.reFetch();
        outlet?.refetchStatuss && outlet.refetchStatuss();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log(error);
    }
  };

  const onClose = () => {
    navigate({
      pathname: "/configurations/status",
      search: searchParam.toString()
    });
  };

  const fields: IStatusField[] = [
    {
      label: "Enter Status*",
      name: "name",
      type: "input",
      placeholder: "Type status name here",
    },
    {
      label: "Type*",
      name: "type",
      type: "select",
      children: [
        <MenuItem key={"CANDIDATE"} value="CANDIDATE">Candidate</MenuItem>,
        <MenuItem key={"CALL"} value="CALL">Call</MenuItem>,
        <MenuItem key={"CPD"} value="CPD">CPD</MenuItem>,
        <MenuItem key={"JOB"} value="JOB">JOB</MenuItem>,
      ]
    },
    {
      label: "Category*",
      name: "category",
      type: "select",
      children: watch("type") === "CANDIDATE" ? [
        <MenuItem key={"OPEN"} value="OPEN">Open</MenuItem>,
        <MenuItem key={"IN-PROGRESS"} value="IN-PROGRESS">In Progress</MenuItem>,
        <MenuItem key={"ON-HOLD"} value="ON-HOLD">On Hold</MenuItem>,
        <MenuItem key={"CLOSE"} value="CLOSE">Close</MenuItem>,
      ] : [
        <MenuItem key={"OPEN"} value="OPEN">Open</MenuItem>,
        <MenuItem key={"IN-PROGRESS"} value="IN-PROGRESS">In Progress</MenuItem>,
        <MenuItem key={"CLOSE"} value="CLOSE">Close</MenuItem>,
      ]
    },
  ];

  return (
    <Box>
      <CustomDialog
        title={id !== "new" ? "Edit Status" : "Add Status"}
        isOpen={id ? true : false}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4}>
          {
            fields.map(field => {
              if (field.type === "input") {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <TextField
                      label={field.label}
                      className="disable-text"
                      variant="outlined"
                      size="small"
                      placeholder={field.placeholder}
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                      {...prop.field}
                    />}
                  />
                </Grid>
                );
              } else {
                return (<Grid key={field.label} item xs={12} md={6}>
                  <Select
                    control={control}
                    name={field.name}
                    label={field.label}
                    size="small"
                    variant="outlined"
                    error={errors[field.name] ? true : false}
                    helperText={errors[field.name]?.message}
                  >
                    {field.children}
                  </Select>
                </Grid>
                );
              }
            })
          }
        </Grid>
      </CustomDialog>
    </Box>
  );
};

export default ManageStatus;