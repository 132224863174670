import { UsersService } from "../../../../services";
import { validateEmail } from "../../../../validations/shared";

interface ContactPerson {
    name: string;
    designation: string;
    notes: string;
    email: string[];
    mobileNumber: {
        country: string;
        dialCode: string;
        iso2: string;
        number: string;
    };
}

const useCollegeValidateImport = () => {
    const { getUser } = UsersService();

    // eslint-disable-next-line
    const validateCollegeUpload = async (leads: any[]) =>
        // eslint-disable-next-line
        new Promise(async (resolve) => {
            const mandatoryFields = ["name", "shortName", "city", "state", "category"];
            const payload = [];
            const error = [];
            let row = 2;
            const contactPersons = [];
            let contactPerson: ContactPerson = {
                name: "",
                designation: "",
                notes: "",
                email: [],
                mobileNumber: {
                    country: "",
                    dialCode: "",
                    iso2: "",
                    number: ""
                }
            };

            for await (const lead of leads) {
                if (!lead?.length) {
                    ++row;
                    continue;
                }

                // eslint-disable-next-line
                const data: any = {};
                // eslint-disable-next-line
                const errMessage: any = {};

                if (typeof lead[1] === "string") {
                    data.name = lead[1];
                } else {
                    if (lead[1] === undefined) {
                        errMessage["University"] = "University is required";
                    } else {
                        errMessage["University"] = "University must be string";
                    }
                }

                if (typeof lead[2] === "string") {
                    data.shortName = lead[2];
                } else {
                    if (lead[2] === undefined) {
                        errMessage["Short Form"] = "Short Form is required";
                    }
                    else {
                        errMessage["Short Form"] = "Short Form must be string";
                    }
                }

                if (typeof lead[3] === "string") {
                    data.city = lead[3];
                } else {
                    if (lead[3] === undefined) {
                        errMessage["City"] = "City is required";
                    }
                    else {
                        errMessage["City"] = "City must be string";
                    }
                }

                if (typeof lead[4] === "string") {
                    data.state = lead[4];
                } else {
                    if (lead[4] === undefined) {
                        errMessage["State"] = "State is required";
                    }
                    else {
                        errMessage["State"] = "State must be string";
                    }
                }

                if (typeof lead[5] === "number" && lead[5]?.toString()) {
                    data.helpline = lead[5]?.toString();
                } else if (typeof lead[5] === "string" && /^\d+$/.test(lead[5])) {
                    data.helpline = lead[5];
                } else {
                    if (lead[5] !== undefined) {
                        if (/^\d+$/.test(lead[8])) {
                            errMessage["Helpline Number"] = "Helpline Number should be a number between 3 to 10 digits.";
                        } else {
                            errMessage["Helpline Number"] = "Please enter only numbers.";
                        }
                    }
                }

                if (typeof lead[6] === "string") {
                    try {
                        const user = await getUser({
                            userId: lead[6].trim(),
                        });
                        if (user.data?._id) {

                            data.assignTo = user.data?._id;

                        } else {
                            errMessage["Recruiter"] = "Please enter a valid recruiter Id";
                        }
                    } catch (error) {
                        errMessage["Recruiter"] = "Please enter a valid recruiter Id";
                    }
                } else {
                    if (lead[6] !== undefined) {
                        errMessage["Recruiter"] = "Please enter a valid recruiter Id";
                    }
                }

                if (typeof lead[7] === "string") {
                    if (["tier-1", "tier-2", "tier-3"].includes(lead[7])) {
                        data.category = lead[7];
                    } else {
                        errMessage["category"] = "Category must be tier-1 or tier-2 or tier-3";
                    }
                } else {
                    if (lead[7] === undefined) {
                        errMessage["category"] = "Category is required";
                    } else {
                        errMessage["category"] = "Category must be a string";
                    }
                }

                if (typeof lead[8] == "string") {
                    data.status = lead[8].toUpperCase().trim();
                } else {
                    if (lead[8] !== undefined) {
                        errMessage["Status"] = "Status must be string";
                    }
                }

                if (typeof lead[9] === "string") {
                    data.internshipStartMonth = lead[9];
                } else {
                    if (lead[9] !== undefined) {
                        errMessage["Internship Start Month"] = "Internship Start Month must be string";
                    }
                }

                if (typeof lead[10] === "string") {
                    data.duration = lead[10];
                } else if (typeof lead[10] === "number" && lead[10]?.toString()) {
                    data.duration = String(lead[10]);
                } else {
                    if (lead[10] !== undefined) {
                        errMessage["Duration"] = "Duration must be a string";
                    }
                }

                if (typeof lead[11] === "string") {
                    data.linkedInPage = lead[11];
                } else {
                    if (lead[11] !== undefined) {
                        errMessage["LinkedIn Page"] = "LinkedIn Page must be string";
                    }
                }

                if (typeof lead[12] === "string") {
                    data.facebookPage = lead[12];
                } else {
                    if (lead[12] !== undefined) {
                        errMessage["Facebook Page"] = "Facebook Page must be string";
                    }
                }

                if (typeof lead[13] === "string") {
                    data.offerRule = lead[13];
                } else {
                    if (lead[13] !== undefined) {
                        errMessage["Offer Rule"] = "Offer Rule must be string";
                    }
                }

                if (typeof lead[14] === "string") {
                    data.remark = lead[14].trim();
                } else {
                    if (lead[14] !== undefined) {
                        errMessage["Remarks"] = "Remarks must be a string";
                    }
                }


                if (typeof lead[15] === "string") {
                    contactPerson.name = lead[15];
                } else {
                    if (lead[15] !== undefined) {
                        errMessage["Contact Person Name"] = "Contact Person Name must be string";
                    }
                }

                if (typeof lead[16] === "string") {
                    contactPerson.mobileNumber.dialCode = lead[16];
                } else {
                    if (lead[16] !== undefined) {
                        errMessage["Dial Code"] = "Dial Code must be string";
                    }
                }

                if (typeof lead[17] === "string") {
                    contactPerson.mobileNumber.iso2 = lead[17];
                } else {
                    if (lead[17] !== undefined) {
                        errMessage["iso2"] = "iso2 must be string";
                    }
                }

                if (typeof lead[18] === "string") {
                    contactPerson.mobileNumber.country = lead[18];
                } else {
                    if (lead[18] !== undefined) {
                        errMessage["iso2"] = "iso2 must be string";
                    }
                }

                if (typeof lead[19] === "string" && lead[19]?.toString().length === 10) {
                    contactPerson.mobileNumber.number = lead[19]?.toString();
                } else {
                    if (lead[19] !== undefined) {
                        if (isNaN(lead[19])) {
                            errMessage["Contact Number"] = "Please enter only numbers for Contact Number";
                        } else {
                            errMessage["Contact Number"] = "Contact Number (Primary) 1 should be a number with 10 digits";
                        }
                    }
                }

                if (Array.isArray(lead[20])) {
                    const emailArray : string[] = lead[20].filter((email : string) => {
                        const err = validateEmail(email);
                        if (!err.error) {
                            return true;
                        } else {
                            return false;
                        }
                    });

                    if (emailArray.length > 0) {
                        contactPerson.email = emailArray;
                    } else {
                        errMessage["Email Address"] = "Please enter valid email addresses";
                    }
                } else if (typeof lead[20] === "string") {
                    const emailAddresses = lead[20].split(",").map(email => email.trim());
                    const invalidEmails = [];

                    emailAddresses.forEach(email => {
                        const err = validateEmail(email);
                        if (!err.error) {
                            contactPerson.email.push(email);
                        } else {
                            invalidEmails.push(email);
                        }
                    });
                    if (invalidEmails.length > 0) {
                        errMessage["Email Address"] = "Please enter valid email addresses";
                    }

                } else {
                    if (lead[20] !== undefined) {
                        errMessage["Email Address"] = "Please enter a valid email address";
                    }
                }

                if (typeof lead[21] === "string") {
                    contactPerson.designation = lead[21];
                } else {
                    if (lead[21] !== undefined) {
                        errMessage["Designation"] = "Designation must be string";
                    }
                }

                if (typeof lead[22] === "string") {
                    contactPerson.notes = lead[22];
                } else {
                    if (lead[22] !== undefined) {
                        errMessage["Notes"] = "Notes must be string";
                    }
                }

                contactPersons.push(contactPerson);
                contactPerson = {
                    name: "",
                    designation: "",
                    notes: "",
                    email: [],
                    mobileNumber: {
                        country: "",
                        dialCode: "",
                        iso2: "",
                        number: ""
                    }
                };

                if (typeof lead[23] === "string") {
                    contactPerson.name = lead[23];
                } else {
                    if (lead[23] !== undefined) {
                        errMessage["Contact Person Name"] = "Contact Person Name must be string";
                    }
                }

                if (typeof lead[24] === "string") {
                    contactPerson.mobileNumber.dialCode = lead[24];
                } else {
                    if (lead[24] !== undefined) {
                        errMessage["Dial Code"] = "Dial Code must be string";
                    }
                }

                if (typeof lead[25] === "string") {
                    contactPerson.mobileNumber.iso2 = lead[25];
                } else {
                    if (lead[25] !== undefined) {
                        errMessage["iso2"] = "iso2 must be string";
                    }
                }

                if (typeof lead[26] === "string") {
                    contactPerson.mobileNumber.country = lead[26];
                } else {
                    if (lead[26] !== undefined) {
                        errMessage["Country"] = "country must be string";
                    }
                }

                if (typeof lead[27] === "string" && lead[27]?.toString().length === 10) {
                    contactPerson.mobileNumber.number = lead[27]?.toString();
                } else {
                    if (lead[27] !== undefined) {
                        if (isNaN(lead[27])) {
                            errMessage["Contact Number"] = "Please enter only numbers for Contact Number";
                        } else {
                            errMessage["Contact Number"] = "Contact Number (Primary) 2 should be a number with 10 digits";
                        }
                    }
                }

                if (Array.isArray(lead[28])) {
                    const emailArray = lead[28].filter(email => {
                        const err = validateEmail(email);
                        if (!err.error) {
                            return true;
                        } else {
                            return false;
                        }
                    });

                    if (emailArray.length > 0) {
                        contactPerson.email = emailArray;
                    } else {
                        errMessage["Email Address"] = "Please enter valid email addresses";
                    }
                } else if (typeof lead[28] === "string") {
                    const emailAddresses = lead[28].split(",").map(email => email.trim());
                    const invalidEmails = [];

                    emailAddresses.forEach(email => {
                        const err = validateEmail(email);
                        if (!err.error) {
                            contactPerson.email.push(email);
                        } else {
                            invalidEmails.push(email);
                        }
                    });
                    if (invalidEmails.length > 0) {
                        errMessage["Email Address"] = "Please enter valid email addresses";
                    }

                } else {
                    if (lead[28] !== undefined) {
                        errMessage["Email Address"] = "Please enter a valid email address";
                    }
                }


                if (typeof lead[29] === "string") {
                    contactPerson.designation = lead[29];
                } else {
                    if (lead[29] !== undefined) {
                        errMessage["Designation"] = "Designation must be string";
                    }
                }

                if (typeof lead[30] === "string") {
                    contactPerson.notes = lead[30];
                } else {
                    if (lead[30] !== undefined) {
                        errMessage["Notes"] = "Notes must be string";
                    }
                }

                contactPersons.push(contactPerson);
                contactPerson = {
                    name: "",
                    designation: "",
                    notes: "",
                    email: [],
                    mobileNumber: {
                        country: "",
                        dialCode: "",
                        iso2: "",
                        number: ""
                    }
                };

                if (typeof lead[31] === "string") {
                    contactPerson.name = lead[31];
                } else {
                    if (lead[31] !== undefined) {
                        errMessage["Contact Person Name"] = "Contact Person Name must be string";
                    }
                }

                if (typeof lead[32] === "string") {
                    contactPerson.mobileNumber.dialCode = lead[32];
                } else {
                    if (lead[32] !== undefined) {
                        errMessage["Dial Code"] = "Dial Code must be string";
                    }
                }

                if (typeof lead[33] === "string") {
                    contactPerson.mobileNumber.iso2 = lead[33];
                } else {
                    if (lead[33] !== undefined) {
                        errMessage["iso2"] = "iso2 must be string";
                    }
                }

                if (typeof lead[34] === "string") {
                    contactPerson.mobileNumber.country = lead[34];
                } else {
                    if (lead[34] !== undefined) {
                        errMessage["country"] = "country must be string";
                    }
                }

                if (typeof lead[35] === "string" && lead[35]?.toString().length === 10) {
                    contactPerson.mobileNumber.number = lead[35]?.toString();
                } else {
                    if (lead[35] !== undefined) {
                        if (isNaN(lead[35])) {
                            errMessage["Contact Number"] = "Please enter only numbers for Contact Number";
                        } else {
                            errMessage["Contact Number"] = "Contact Number (Primary) 3 should be a number with 10 digits";
                        }
                    }
                }

                if (Array.isArray(lead[36])) {
                    const emailArray = lead[36].filter(email => {
                        const err = validateEmail(email);
                        if (!err.error) {
                            return true;
                        } else {
                            return false;
                        }
                    });

                    if (emailArray.length > 0) {
                        contactPerson.email = emailArray;
                    } else {
                        errMessage["Email Address"] = "Please enter valid email addresses";
                    }
                } else if (typeof lead[36] === "string") {
                    const emailAddresses = lead[36].split(",").map(email => email.trim());
                    const invalidEmails = [];

                    emailAddresses.forEach(email => {
                        const err = validateEmail(email);
                        if (!err.error) {
                            contactPerson.email.push(email);
                        } else {
                            invalidEmails.push(email);
                        }
                    });
                    if (invalidEmails.length > 0) {
                        errMessage["Email Address"] = "Please enter valid email addresses";
                    }

                } else {
                    if (lead[36] !== undefined) {
                        errMessage["Email Address"] = "Please enter a valid email address";
                    }
                }


                if (typeof lead[37] === "string") {
                    contactPerson.designation = lead[21];
                } else {
                    if (lead[37] !== undefined) {
                        errMessage["Designation"] = "Designation must be string";
                    }
                }

                if (typeof lead[38] === "string") {
                    contactPerson.notes = lead[22];
                } else {
                    if (lead[38] !== undefined) {
                        errMessage["Notes"] = "Notes must be string";
                    }
                }

                contactPersons.push(contactPerson);
                data.contactPersons = contactPersons;

                if (mandatoryFields.every((value) => Object.keys(data).includes(value))) {
                    if (Object.keys(errMessage).length) {
                        error.push({ ...errMessage, row });
                    } else {
                        payload.push(data);
                    }
                } else {
                    if (Object.keys(errMessage).length) {
                        error.push({ ...errMessage, row });
                    }
                }
                ++row;
            }

            // return all the data
            resolve({
                data: payload,
                errorMessage: error,
            });

        });

    return { validateCollegeUpload };
};

export default useCollegeValidateImport;

