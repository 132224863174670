import { ICollege, ICpdDriveBasicField, IErrorResponse } from "../../../../interfaces";
import { Box, Grid, TextField, Autocomplete, AutocompleteChangeReason } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { ICpdDrive } from "../../../../interfaces";
import { joiResolver } from "@hookform/resolvers/joi";
import { driveValidation } from "../../../../validations";
import { cpdDriveService } from "../../../../services";
import { useQuery } from "@tanstack/react-query";
import { SyntheticEvent, useEffect } from "react";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Select from "../../../../components/mui/select";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import { capitalize } from "../../../../utilities/helper";
import CustomLabel from "../../../../components/mui/custom-label";
interface outletProps {
    reFetch: () => void;
    refetchDrivess: () => void;
}

const ManageDrive = () => {
    const { id } = useParams();
    const { snackbar } = useSnackbar();
    const [searchParam] = useSearchParams();
    const { addCpdDrive, getCpdDrive, updateCpdDrive } = cpdDriveService();
    const outlet = useOutletContext<outletProps>();
    const hitQuery = (id === "new" || id === "view") ? false : true;
    const navigate = useNavigate();
    const drive = useQuery({ queryKey: [hitQuery], queryFn: () => getCpdDrive({ _id: id }), enabled: hitQuery });
    const [...colleges] = useSelector<{ college: { list: ICollege[] } }, ICollege[]>(state => state.college.list) || [];
    const { handleSubmit, control, getValues, setValue, trigger, watch, formState: { errors } } = useForm<ICpdDrive>({
        resolver: joiResolver(driveValidation),
        defaultValues: {
            _college: "",
            driveDate: "",
            startDate: "",
            endDate: "",
            notes: ""
        }
    });

    useEffect(() => {
        const collegeId = searchParam.get("collegeId");
        if (collegeId) {
            setValue("_college", collegeId);
        }
    }, [searchParam.get("collegeId")]);

    useEffect(() => {
        if (id !== "new") {
            if (drive.data?.data) {
                setValue("_college", drive.data.data.collegeId?._id);
                drive.data.data?.driveDate && setValue("driveDate", drive.data.data?.driveDate);
                drive.data.data?.startDate && setValue("startDate", drive.data.data?.startDate);
                drive.data.data?.endDate && setValue("endDate", drive.data.data?.endDate);
                setValue("notes", drive.data.data?.notes);
            }
        }
    }, [id, drive.data]);

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === "_college") {
                trigger("_college");
            } else if (name === "driveDate") {
                trigger("driveDate");
            } else if (name === "startDate") {
                trigger("startDate");
            } else if (name === "endDate") {
                trigger("endDate");
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const onSubmit = async (data: ICpdDrive) => {
        try {
            if (id === "new") {
                const payload = { ...data, collegeId: data._college, _recruiter: data.recruiterId };
                delete payload._college;
                delete payload.recruiterId;

                const add = await addCpdDrive(payload);
                snackbar(add.message, "info");
                navigate({
                    pathname: "/cpd/drives",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
                outlet?.refetchDrivess && outlet.refetchDrivess();
            } else {
                const payload = { ...data, _id: id, collegeId: data._college, _recruiter: data.recruiterId };
                delete payload._college;
                delete payload.recruiterId;
                const update = await updateCpdDrive(id ? id : "", payload);
                snackbar(update.message, "info");
                navigate({
                    pathname: "/cpd/drives",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
                outlet?.refetchDrivess && outlet.refetchDrivess();
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }
    };

    // eslint-disable-next-line
    const selectDate = (value: any, name: string) => {
        const date = value && dayjs(value).toString() !== "Invalid Date" ? dayjs(value)?.toISOString() : undefined;
        const keyExist = name === "startDate" || name === "endDate" || name === "driveDate";
        if (keyExist) {
            setValue(name, date);
            trigger(name);
            trigger("endDate");
        }
    };

    const selectCollege = (event: SyntheticEvent<Element, Event>, value: ICollege | null, reason: AutocompleteChangeReason) => {
        switch (reason) {
            case "selectOption":
                setValue("_college", value?._id);
                break;
            case "clear":
                setValue("_college", "");
                break;
            case "removeOption":
                setValue("_college", "");
                break;
        }
        trigger("_college");
    };

    const onClose = () => {
        navigate({
            pathname: "/cpd/drives",
            search: searchParam.toString()
        });
    };

    const fields: ICpdDriveBasicField[] = [
        {
            label: "College Name",
            name: "_college",
            type: "auto-complete",
            required: true
        },
        {
            label: "Drive Date",
            name: "driveDate",
            type: "date",
        },
        {
            label: "Start Date",
            name: "startDate",
            type: "date",
        },
        {
            label: "End Date",
            name: "endDate",
            type: "date",
        },
        {
            label: "Remark",
            name: "notes",
            type: "multiline",
            placeholder: "Type remark here",
            width: 12,
        },
    ];

    return (
        <Box>
            <CustomDialog
                title={id !== "new" ? "Edit Campus Placement Drive" : "Add Campus Placement Drive"}
                isOpen={id ? true : false}
                onClose={onClose}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "multiline") {
                                return (<Grid key={field.label} item xs={12} md={field.width ? field.width : 6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={<CustomLabel label={field.label} required={field?.required} />}
                                            className="disable-text"
                                            variant={"outlined"}
                                            size={"small"}
                                            placeholder={field.placeholder}
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message}
                                            multiline
                                            minRows={2}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            } else if (field.type === "date") {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker
                                            label={<CustomLabel label={field.label} required={field?.required} />}
                                            onChange={(e) => selectDate(e, field.name)}
                                            value={dayjs(getValues(field.name))}
                                            slotProps={{
                                                textField: {
                                                    error: errors[field.name] ? true : false,
                                                    helperText: errors[field.name]?.message
                                                }
                                            }}
                                            format="LL"
                                        />
                                    </LocalizationProvider>

                                </Grid>
                                );
                            } else if (field.type === "auto-complete") {
                                return <Grid key={field.label} item xs={12} md={6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <Autocomplete
                                            className="disable-text"
                                            options={colleges}
                                            getOptionLabel={(option) => capitalize(option.name)}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                error={errors._college ? true : false}
                                                helperText={errors._college ? errors._college.message : ""}
                                                size={"small"}
                                                variant={"outlined"}
                                                label={<CustomLabel label="College Name" required />}
                                                placeholder={field.placeholder}
                                            />}
                                            {...prop.field}
                                            value={colleges.find(college => college._id === getValues("_college")) || null}
                                            onChange={selectCollege}
                                        />}
                                    />
                                </Grid>;
                            } else {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Select
                                        control={control}
                                        name={field.name}
                                        label={<CustomLabel label={field.label} required={field?.required} />}
                                        size="small"
                                        variant="outlined"
                                        error={errors[field.name] ? true : false}
                                        helperText={errors[field.name]?.message}
                                    >
                                        {field.children}
                                    </Select>
                                </Grid>
                                );
                            }
                        })
                    }
                </Grid>
            </CustomDialog>
        </Box>
    );

};

export default ManageDrive;