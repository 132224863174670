import { blogContent, blogContentVersionsList } from "../../endpoints";
import HttpService from "../../http";
import { IBlogContentResponse, IBlogVersionResponses } from "../../../interfaces/content-marketing/blog/content";
const blogContentService = () => {

    const { httpRequest } = HttpService();
    const addBlogContent = async (payload: object): Promise<IBlogContentResponse> => new Promise((resolve, reject) => {
        httpRequest<IBlogContentResponse>(
            "PUT",
            `${blogContent}`,
            payload)
            .then(resolve)
            .catch(reject);
    });

    const updateBlogContentStatus = async (payload: object): Promise<IBlogContentResponse> => new Promise((resolve, reject) => {
        httpRequest<IBlogContentResponse>(
            "PUT",
            `${blogContent}/submit-content`,
            payload)
            .then(resolve)
            .catch(reject);
    });

    const getBlogContent = async (query: object): Promise<IBlogContentResponse> => new Promise((resolve, reject) => {
        httpRequest<IBlogContentResponse>(
            "GET",
            `${blogContent}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const blogContentAutoSave = async (payload: object): Promise<IBlogContentResponse> => new Promise((resolve, reject) => {
        httpRequest<IBlogContentResponse>(
            "PUT",
            `${blogContent}/auto-save-content`,
            payload,
            {},
            false
        )
            .then(resolve)
            .catch(reject);
    });


    const getVersionContent = async (query: object): Promise<IBlogContentResponse> => new Promise((resolve, reject) => {
        httpRequest<IBlogContentResponse>(
            "GET",
            `${blogContent}/version-content`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });
    const getBlogContentVersions = async (query: object): Promise<IBlogVersionResponses> => new Promise((resolve, reject) => {
        httpRequest<IBlogVersionResponses>(
            "GET",
            `${blogContentVersionsList}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateContentVersion = async (payload: object): Promise<IBlogVersionResponses> => new Promise((resolve, reject) => {
        httpRequest<IBlogVersionResponses>(
            "PUT",
            `${blogContent}/set-current-version`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { addBlogContent, getBlogContent, blogContentAutoSave, getBlogContentVersions, updateBlogContentStatus, getVersionContent,updateContentVersion };

};

export { blogContentService };