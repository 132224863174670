import { useSelector } from "react-redux";
import { UsersService } from "../../../../services";
import { isValidDate } from "../../../../utilities/helper";
import { ICollege } from "../../../../interfaces";

const useCpdValidateImport = () => {
    const { getUser } = UsersService();
    const colleges = useSelector<{ college: { list: ICollege[] } }, ICollege[]>(state => state.college.list) || [];

    // eslint-disable-next-line
    const validateCpdUpload = async (leads: any[]) =>
        // eslint-disable-next-line
        new Promise(async (resolve) => {
            const mandatoryFields = ["collegeId", "status"];
            const payload = [];
            const error = [];
            let row = 2;

            for await (const lead of leads) {
                if (!lead?.length) {
                    ++row;
                    continue;
                }

                // eslint-disable-next-line
                const data: any = {};
                // eslint-disable-next-line
                const errMessage: any = {};
                if (typeof lead[1] === "string") {
                    try {
                        const college = colleges.find(college => college.collegeId === lead[1]);
                        
                        if (college) {
                            data.collegeId = college._id;
                            data.collegeShortName = college?.shortName;
                        } else {
                            errMessage["College ID"] = "Please enter a valid College ID";
                        }
                    } catch (error) {
                        errMessage["College ID"] = "Please enter a valid College ID";
                    }
                } else {
                    if (lead[1] !== undefined) {
                        errMessage["College ID"] = "Please enter a valid College ID";
                    } else if (lead[1] === undefined) {
                        errMessage["College ID"] = "College ID is required";
                    }
                }

                if (typeof lead[2] === "string" && isValidDate(lead[2])) {
                    data.driveDate = isValidDate(lead[2].trim());
                } else {
                    if (lead[2] !== undefined) {
                        errMessage["Drive Date"] = "Please enter a valid date";
                    }
                }

                if (typeof lead[3] === "string" && isValidDate(lead[3])) {
                    data.startDate = isValidDate(lead[3].trim());
                } else {
                    if (lead[3] !== undefined) {
                        errMessage["Start Date"] = "Please enter a valid date";
                    }
                }

                if (typeof lead[4] === "string" && isValidDate(lead[4])) {
                    data.endDate = isValidDate(lead[4].trim());
                } else {
                    if (lead[4] !== undefined) {
                        errMessage["End Date"] = "Please enter a valid date";
                    }
                }

                if (typeof lead[5] === "string") {
                    data.status = lead[5].toUpperCase();
                } else {
                    if (lead[5] !== undefined) {
                        errMessage["Status"] = "Status must be String";
                    } else if (lead[5] === undefined) {
                        errMessage["Status"] = "Status is required";
                    }
                }

                if (typeof lead[6] === "string") {
                    try {
                        const user = await getUser({
                            userId: lead[6].trim(),
                        });
                        if (user?.data?._id) {
                            data._recruiter = user?.data?._id;
                        } else {
                            errMessage["Recruiter"] = "Please enter a valid recruiter Id";
                        }
                    } catch (error) {
                        errMessage["Recruiter"] = "Please enter a valid recruiter Id";
                    }
                } else {
                    if (lead[6] !== undefined) {
                        errMessage["Recruiter"] = "Please enter a valid recruiter Id";
                    }
                }

                if (typeof lead[7] === "string") {
                    data.notes = lead[7];
                } else {
                    if (lead[7] !== undefined) {
                        errMessage["Remark"] = "Remark must be a string";
                    }
                }

                if (mandatoryFields.every((value) => Object.keys(data).includes(value))) {
                    if (Object.keys(errMessage).length) {
                        error.push({ ...errMessage, row });
                    } else {
                        payload.push(data);
                    }
                } else {
                    if (Object.keys(errMessage).length) {
                        error.push({ ...errMessage, row });
                    }
                }
                ++row;
            }
            
            // return all the data
            resolve({
                data: payload,
                errorMessage: error,
            });

        });

    return { validateCpdUpload };
};

export default useCpdValidateImport;

