import { Controller, useForm } from "react-hook-form";
import { IErrorResponse } from "../../../../../interfaces";

import { Box, capitalize, Grid, TextField } from "@mui/material";
import {
  useParams,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";

import useSnackbar from "../../../../../hooks/useSnackbar";

import CustomDialog from "../../../../../components/mui/dialog";

import {
  ITestField,
  ITest,
} from "../../../../../interfaces/configuration/tests";

import { TestsService } from "../../../../../services/configuration/tests";
import { useQuery } from "@tanstack/react-query";
import { joiResolver } from "@hookform/resolvers/joi";
import { useEffect } from "react";
import { testValidation } from "../../../../../validations/configuration/test";

interface outletProps {
  reFetch: () => void;
  refetchTest: () => void;
}
const ManageTest = () => {
  const { id } = useParams();
  const [searchParam] = useSearchParams();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const { addTest,getSingleTest,updateTest } = TestsService();
    const hitQuery = (id === "new" || id === "view") ? false : true;
  const navigate = useNavigate();
     const testDetails = useQuery({ queryKey: [hitQuery], queryFn: () => getSingleTest({ _id: id }), enabled: hitQuery });
  const { handleSubmit, control ,setValue, formState: { errors } } = useForm<ITest>(
        {
        resolver: joiResolver(testValidation),
        defaultValues: {
          name: "",
          description: "",
          duration: 0
        }
      }
  );

    useEffect(() => {
        if (id !== "new" && testDetails.data?.data) {
          setValue("name", testDetails?.data.data?.name || "");
          setValue("description", testDetails?.data.data?.description || "");
          setValue("duration", testDetails?.data.data?.duration);
        }
    }, [id, testDetails.data]);

  const onSubmit = async (data: ITest) => {
    try {
      if (id === "new") {
        const add = await addTest(data);
        snackbar(add.message, "info");
        navigate({
          pathname: "/configurations/tests",
          search: searchParam.toString()
        });
        outlet?.reFetch && outlet.reFetch();
        outlet?.refetchTest && outlet.refetchTest();
      } else {
        const payload = { ...data, _id: id };
        const update = await updateTest({ ...payload, _id: id });
        snackbar(update.message, "info");
        navigate({
          pathname: "/configurations/tests",
          search: searchParam.toString(),
        });
        outlet.reFetch && outlet.reFetch();
        outlet.refetchTest && outlet.refetchTest();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };
  const onClose = () => {
    navigate({
      pathname: "/configurations/tests",
      search: searchParam.toString(),
    });
  };

  const fields: ITestField[] = [
    {
      label: "Enter Test Name*",
      name: "name",
      type: "input",
      placeholder: "Type test name here",
    },
    {
      label: "Enter Test Duration(in minutes)*",
      name: "duration",
      type: "input",
      placeholder: "Type test duration(in minutes) here",
    },
    {
      label: "Description*",
      name: "description",
      type: "input",
      placeholder: "Type test description here",
    },
  ];
  return (
    <Box>
      <CustomDialog
        title={id !== "new" ? "Edit Test" : "Add Test"}
        isOpen={!!id}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
        size={"xs"}
      >
        <Grid container spacing={4}>
          {fields.map((field) => {
            {
              if (field.name === "name") {
                return (
                  <Grid key={field.label} item xs={12} md={12}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => <TextField
                        label={field.label}
                        className="disable-text"
                        variant="outlined"
                        size="small"
                        placeholder={field.placeholder}
                        sx={{ "marginTop": "1rem" }}
                        error={errors[field.name] ? true : false}
                        helperText={errors[field.name]?.message}
                        inputProps={{
                          maxLength: 30
                        }}
                        {...prop.field}
                        onChange={(e) => prop.field.onChange(capitalize(e.target.value))}
                      />}
                    />
                  </Grid>
                );
              }else if (field.name === "description") {
                return (
                  <Grid key={field.label} item xs={12} md={12}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => <TextField
                        multiline
                        rows={5}
                        label={field.label}
                        className="disable-text"
                        variant="outlined"
                        size="medium"
                        placeholder={field.placeholder}
                        error={errors[field.name] ? true : false}
                        helperText={errors[field.name]?.message}
                        inputProps={{
                          maxLength: 60
                        }}
                        sx={{ "margin-bottom": "1rem" }}
                        {...prop.field}
                      />}
                    />
                  </Grid>
                );
              }else{
                return (
                  <Grid key={field.label} item xs={12} md={12}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => <TextField
                        label={field.label}
                        className="disable-text"
                        variant="outlined"
                        size="small"
                        placeholder={field.placeholder}
                        type="number"
                        error={errors[field.name] ? true : false}
                        helperText={errors[field.name]?.message}
                        inputProps={{
                          pattern: "[0-9]*",
                          inputMode: "numeric",
                          onInput: (e) => {
                              e.preventDefault();
                              const inputValue = e.currentTarget.value;
                              const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
                              e.currentTarget.value = sanitizedValue;
                              prop.field.onChange(sanitizedValue);
                          },
                        }}
                        {...prop.field}
                      />}
                    />
                  </Grid>
                );
              }
            }
          })}
        </Grid>
      </CustomDialog>
    </Box>
  );
};
export default ManageTest;