import {useForm , Controller} from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import { IAddPartsOfFunnel, IPartsOfFunnelField } from "../../../../interfaces/content/parts-of-funnel";
import InlineDialog from "../../../../components/mui/inlineDialogue";
import { useNavigate } from "react-router-dom";
import { partsOfFunnelValidations } from "../../../../validations/content/parts-of-funnel";
import { joiResolver } from "@hookform/resolvers/joi";
import  PartsOfFunnelService  from "../../../../services/content/parts-of-funnel";
import useSnackbar from "../../../../hooks/useSnackbar";
import { IErrorResponse } from "../../../../interfaces";
import { useParams } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import CustomLabel from "../../../../components/mui/custom-label";

interface IOutletProps{
    reFetch : () => void
}
const AddPartsOfFunnel = () => {

    const navigate = useNavigate();
    const {snackbar} = useSnackbar();
    const outlet = useOutletContext<IOutletProps>();
    const {id} = useParams();
    const {addContentPartsOfFunnel,updateContentPartsOfFunnel,getContentPartsOfFunnel} = PartsOfFunnelService();
    const {handleSubmit , setValue,control,formState:{errors}} = useForm({
        resolver:joiResolver(partsOfFunnelValidations),
        defaultValues:{
            name:""
        }
    });
    const fields:IPartsOfFunnelField[] = [
        {
            name:"name",
            type:"input",
            label:"Name",
            placeholder:"Enter Parts of funnel",
            width:12,
            required:true
        }
    ];
    const hitQuery = (id === "new") ? false : true;

    const partsOfFunnel = useQuery({
        queryKey:["partsOfFunnel", id],
        queryFn:() =>  getContentPartsOfFunnel({
            _id:id
        }),
        enabled: hitQuery
    });

    useEffect(() => {
        if(id !== "new"){
         setValue("name",partsOfFunnel?.data?.data?.name || "");
        }
    },[partsOfFunnel?.data?.data?.name]);
    
    const onSubmit = async (data:IAddPartsOfFunnel) => {
        try{
        if(id === "new"){
        const response = await addContentPartsOfFunnel(data);
        snackbar(response?.message,"info");
        navigate("/content/parts-of-funnel");
        outlet?.reFetch && outlet?.reFetch();
        }else{
        const payload = {
            _id:id,
            ...data
        };
        const response = await updateContentPartsOfFunnel(payload);
        snackbar(response?.message,"info");
        navigate("/content/parts-of-funnel");
        outlet?.reFetch && outlet?.reFetch();
        }
    }catch(error){
        const err = error as IErrorResponse;
        snackbar(err?.data?.message,"error");
        console.log("Error in Add Projects",err);
    }

    };


    return (
        <InlineDialog
        onClose={() => navigate("/content/parts-of-funnel")}
        onSubmit={handleSubmit(onSubmit)}
        >
            <Grid container spacing = {2}>
         {
            
            fields.map(field => {
                if(field.type === "input"){
                    return (
                     <Grid key = {field.name} item xs = {field.width}>
                        <Controller 
                        name = {field.name}
                        control={control}
                        render = {(prop) => <TextField 
                         placeholder={field.placeholder}
                         label={<CustomLabel label = {field.label} required = {field.required}/>}
                         inputProps={{
                            maxLength:30
                         }}
                         error = {errors[field.name] ? true :false}
                         helperText = {errors[field.name]?.message}
                         {...prop.field}
                         />}
                        />
                     </Grid>
                    );
                 }
            })
         }
        </Grid>
        </InlineDialog>
    );
};

export default AddPartsOfFunnel;