import "../style.scss";
import { Box, IconButton } from "@mui/material";
import { ChangeEvent } from "react";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { IPagination } from "../../../interfaces";
import { OfficeEmployeeImageService } from "../../../services";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { createIndex, formatDateTime } from "../../../utilities/helper";
import { IOfficeEmployeeRow, IOfficeEmployeeState, IOfficeEmployee } from "../../../interfaces";
import ImageViewer from "react-simple-image-viewer";
import Header from "../../../components/header";
import useResource from "../../../hooks/useResource";
import useDebounce from "../../../hooks/useDebounce";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";

interface IImageViewer {
  open: boolean,
  index: number,
  images: string[],
}

const OfficeEmployeeImage = () => {
  let rows: IOfficeEmployeeRow[] = [];
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const { resourceAllocate } = useResource();
  const [search, setSearch] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { getOfficeEmployeeImages, deleteOfficeEmployeeImages } = OfficeEmployeeImageService();
  const [state, setState] = useState<IOfficeEmployeeState>({
    deleteWarning: false,
    _officeEmployeeImage: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      search: ""
    }
  });
  const [imageViewer, setImageViewer] = useState<IImageViewer>({
    open: false,
    index: 0,
    images: [],
  });

  const getOfficeEmployeeImage = useQuery({
    queryKey: ["allOfficeEmployeeImages", state.pagination.page, state.filters],
    queryFn: () => getOfficeEmployeeImages({
      pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
    })
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (getOfficeEmployeeImage.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: getOfficeEmployeeImage.data.meta.page,
          totalPages: getOfficeEmployeeImage.data.meta.totalPages,
          totalRecords: getOfficeEmployeeImage.data.meta.totalRecords
        }
      }));

      const images: string[] = [];
      getOfficeEmployeeImage.data?.data.map(ele => {
        images.push(ele.image);
      });

      setImageViewer(prev => ({
        ...prev,
        images
      }));
    }
  }, [getOfficeEmployeeImage.data?.meta]);

  useEffect(() => {
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search
      }
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleDelete = (_officeEmployeeImage = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _officeEmployeeImage
    }
    ));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteOfficeEmployeeImages({ _id: state._officeEmployeeImage });
      snackbar(deleted.message, "info");
      handleDelete();
      getOfficeEmployeeImage.refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const handleImageViewer = (index?: number) => {
    if (!imageViewer.open) {
      setImageViewer({
        ...imageViewer,
        open: true,
        index: index ? index : 0,
      });
    } else {
      setImageViewer({
        ...imageViewer,
        open: false,
        index: 0,
      });
    }
  };

  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "officeEmployeeImage_image",
      label: "Image"
    },
    {
      id: "officeEmployeeImage_imageAlt",
      label: "Image Alt"
    },
    {
      id: "officeEmployeeImage_createdAt",
      label: "Created At"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];


  const createRow = (index: number, officeEmployeeImage: IOfficeEmployee, pagination: IPagination) => {
    const action = <GetActions
      icons={[
        { name: "Edit", method: () => navigate({ pathname: "" + officeEmployeeImage._id, search: searchParams.toString() }), disabled: resourceAllocate("employee-image.write") ? false : true },
        { name: "Delete", method: () => handleDelete(officeEmployeeImage._id), disabled: resourceAllocate("employee-image.remove") ? false : true },
      ]}
    />;

    return {
      id: createIndex(pagination, index),
      officeEmployeeImage_image: <IconButton onClick={() => handleImageViewer(index)}><img className="thumbnail" src={officeEmployeeImage?.image} alt="" /></IconButton>,
      officeEmployeeImage_imageAlt: (officeEmployeeImage?.altText ? officeEmployeeImage.altText : ""),
      officeEmployeeImage_createdAt: formatDateTime(officeEmployeeImage?.createdAt),
      action,
    };
  };
  if (getOfficeEmployeeImage.data?.data.length) {
    rows = getOfficeEmployeeImage.data?.data.map((officeEmployeeImage, i) => createRow(i, officeEmployeeImage, state.pagination));
  }

  return (
    <>
      {/* Add Data  */}
      <Header
        className='my-2'
        searchPlaceholder="Search by image Alt"
        onSearch={onSearch}
        btnText="ADD EMPLOYEE IMAGE"
        onBtnClick={resourceAllocate("employee-image.write") ? () => navigate("new") : undefined}
      />

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Employee Image"
        description="Are you sure you want to delete this employee image?"
      />

      {/* Preview Image */}
      {imageViewer.open && (
        <>
          <ImageViewer
            src={imageViewer.images}
            currentIndex={imageViewer.index}
            disableScroll={true}
            closeOnClickOutside={false}
            onClose={handleImageViewer}
            backgroundStyle={
              {
                color: "white",
                zIndex: 5
              }
            }
          />
        </>
      )}
      <Outlet context={{ reFetch: getOfficeEmployeeImage.refetch }} />
    </>
  );
};
export default OfficeEmployeeImage;