import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ChangeEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { ILanguage, ILanguageRow, ILanguageState, IPagination } from "../../../interfaces";
import { Outlet, useNavigate, useSearchParams, useOutletContext } from "react-router-dom";
import { capitalize, createIndex, formatDateTime } from "../../../utilities/helper";
import Header from "../../../components/header";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import useDebounce from "../../../hooks/useDebounce";
import { QuestionLanguageService } from "../../../services/configuration/question-languages";

interface outletProps {
  refetchEngagementTypes: () => void;
}

const QuestionLanguages = () => {
  let rows: ILanguageRow[] = [];
  const outlet = useOutletContext<outletProps>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getLanguages } = QuestionLanguageService();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<ILanguageState>({
    deleteWarning: false,
    _tag: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      search: ""
    }
  });

  const languages = useQuery({
    queryKey: ["allLanguages", state.pagination.page, state.filters],
    queryFn: () => getLanguages({
      pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
    })
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (languages.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: languages.data.meta.page,
          totalPages: languages.data.meta.totalPages,
          totalRecords: languages.data.meta.totalRecords
        }
      }));
    }
  }, [languages.data?.meta]);


  useEffect(() => {
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";

    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search
      }
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleDelete = (_tag = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _tag
    }
    ));
  };

  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "language_title",
      label: "Title"
    },
    {
      id: "language_createdAt",
      label: "Created At"
    },
    {
      id: "action",
      label: "Action"
    }
  ];

  const createRow = (index: number, language: ILanguage, pagination: IPagination) => {
    const action = <GetActions
    icons={[
      { name: "Edit", method: () => navigate({ pathname: "" + language._id, search: searchParams.toString() }), disabled: true },
      { name: "Delete", method: () => handleDelete(language._id), disabled: true },
    ]}
  />;

   return { 
      id: createIndex(pagination, index),
      language_title: capitalize(language.language),
      language_createdAt: formatDateTime(language.createdAt),
      action
  };
};
  if (languages.data?.data.length) {
    rows = languages.data?.data.map((tag, i) => createRow(i, tag, state.pagination));
  }
  return (
    <>
      {/* Add Data  */}
      <Header
        className='my-2'
        searchPlaceholder="Search by title"
        onSearch={onSearch}
      />

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      <Outlet context={{ ...outlet, reFetch: languages.refetch }} />
    </>
  );
};
export default QuestionLanguages;

