import Joi from "joi";
import { required } from "../../../../shared";

export const cpdDriveMailValidation = Joi.object({
    to: Joi.string()
        .email({ tlds: { allow: false } })
        .label("To")
        .required()
        .messages({
            "string.empty": required,
        }),

    template: Joi.string()
        .label("Email Template")
        .allow(""),

    ccText: Joi.string()
        .label("CC")
        .allow(""),
    bccText: Joi.string()
        .label("CC")
        .allow(""),

    cc: Joi.array()
        .items(
            Joi.string()
                .email({ tlds: { allow: false } })
        )
        .allow("")
        .label("CC"),
    bcc: Joi.array()
        .items(
            Joi.string()
                .email({ tlds: { allow: false } })
        )
        .allow("")
        .label("BCC"),
    subject: Joi.string()
        .required()
        .label("Subject")
        .messages({
            "string.empty": required,
        }),
    content: Joi.string()
        .required()
        .label("Body")
        .messages({
            "string.empty": required,
        }),
    attachments: Joi.array()
        .optional()
        .label("Attachments")
});
