import "./style.scss";
import { Box, Button, Divider, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { SyntheticEvent, useEffect, useState } from "react";
import { BootstrapDialog, BootstrapDialogTitle, TabPanel, a11yProps } from "../../../components/shared/mui-tabs";
import DialogContent from "@mui/material/DialogContent";
import SkillItem from "./add-skill-item-chip";
import Employee from "./add-employee-chip/index";
import { useForm } from "react-hook-form";
import { IEmployeeActionDetail, ISkillItemDetail } from "../../../interfaces/skill-matrix/action";
import { useQuery } from "@tanstack/react-query";
import { SkillMatrixService } from "../../../services/skill-matrix";
import { capitalize } from "../../../utilities/helper";
import useSnackbar from "../../../hooks/useSnackbar";
import { IErrorResponse } from "../../../interfaces";
import useResource from "../../../hooks/useResource";
import { EmployeeValidation, SkillItemValidation } from "../../../validations";
import { joiResolver } from "@hookform/resolvers/joi";
const redirectionLinks = ["skill", "employee"];

interface IOutletProps {
    reFetch: () => void;  
    refetchLable:() => void;
    reFetchSkillItemData: () => void;
    reFetchUserSkills: () => void;
}

const SkillMatrixActionLayout = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [value, setValues] = useState(0);
    const {resourceAllocate} = useResource();
    const { getSkillItem, addItemAndEmployee } = SkillMatrixService();
    const { snackbar } = useSnackbar();
    const [selectedCategory, setSelectedCategory] = useState<string>();
    const [selectedEmployee, setSelectedEmployee] = useState<string[]>([]);
    const [selectedUser, setSelectedUsers] = useState<string[]>([]);
    const [enteredValue, setEnteredValue] = useState<string[]>([]);
    const [searchParam, setSearchParam] = useSearchParams();
    const outlet = useOutletContext<IOutletProps>();
    const skillItems = useQuery({
        queryKey: ["skillItems"],
        queryFn: () => getSkillItem({ _id: id }),
        enabled:true
    });


    useEffect(() => {
        const tab = searchParam.get("type") ? String(searchParam.get("type")) : "skill";
        setValues(redirectionLinks.indexOf(tab) || 0);
    }, [searchParam]);

    const { control, handleSubmit, reset, getValues, setValue, trigger, resetField, formState: { errors } } = useForm<ISkillItemDetail>({
        resolver: joiResolver(SkillItemValidation),
        defaultValues: {
            skillItemsText: "",
            _skillCategory: ""
        }
    });
    const { control: EmployeeControl, handleSubmit: EmployeeSubmit, reset: EmployeeReset, setValue: EmployeesetValue, trigger: Employeetrigger, formState: { errors: EmployeeErrors } } = useForm<IEmployeeActionDetail>({
        resolver: joiResolver(EmployeeValidation),
        defaultValues: {
            employees: [],
        }
    });
    
    

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        searchParam.set("type", redirectionLinks[newValue]);  
        setSearchParam(searchParam);
    };

    const onClose = () => {
        searchParam.delete("type");
        if (location.pathname.slice(0, 20) === "/skill-matrix/manage") {
            navigate({ pathname: `/skill-matrix/manage/${id}`, search: searchParam.toString() });
        } else if (location.pathname.slice(0, 13) === "/skill-matrix") {
            navigate({ pathname: "/skill-matrix", search: searchParam.toString() });
        }
    };


    const onSubmit = async () => {
        if(searchParam.get("type") === "skill"){
            if(getValues("skillItems")===undefined){
                return snackbar("Please add skill item", "warning");
                
            }
        }
        if(searchParam.get("type") === "employee"){
            if(selectedUser.length === 0){
                return snackbar("Please add employee", "warning");
                
            }
        }
        try {
            const payload = {
                _skillParam: id,
                skillItems: (getValues("skillItems") || []).map(item => ({ name: item })),
                employees: selectedEmployee,
                _skillCategory: selectedCategory
            };
            const update = await addItemAndEmployee(payload);
            if(update && update.data && update.data.existingUsers){
                snackbar(update.message, "warning");
            }else{
                snackbar(update.message, "info");
            }
           
            outlet?.reFetchSkillItemData && outlet.reFetchSkillItemData();
            
            outlet?.reFetchUserSkills && outlet.reFetchUserSkills();
            outlet?.reFetch && outlet.reFetch();
            outlet?.refetchLable && outlet.refetchLable();
            onClose();
            
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data?.message, "warning");
            console.log(error);
        }
    };

    const name = skillItems.data?.data?.name ? (skillItems.data?.data.name) : "Skill";
    const employeeCount = skillItems.data?.data?.employeesCount ? (skillItems.data?.data.employeesCount) : 0;
    const skillItem = skillItems.data?.data?.skillItemsCount ? (skillItems.data?.data.skillItemsCount) : 0;

    return <div>
        <BootstrapDialog
            onClose={onClose}
            open={true}
            style={{ height: "450px", marginTop: "80px" }}
        >
            <BootstrapDialogTitle onClose={onClose}>
                <Box display="flex" alignItems="center">
                    <Tooltip title={name}>
                        <span>
                            {name.length > 20 ? `${name.slice(0, 20)}...` : capitalize(name)}
                        </span>
                    </Tooltip>
                </Box>
                <Box className="center" justifyContent="space-between">
                    {
                        <>
                            <Box className="center" justifyContent="space-between">
                                <Box display="flex" alignItems="center">
                                    <Typography variant="subtitle1">Skill:</Typography>
                                    <Typography className="ml-2" variant="overline" fontSize = "17px" >
                                        {skillItem}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="center" sx={{ marginRight: "10px", }}>
                                <Typography variant="subtitle1">Employees:</Typography>
                                <Typography className="ml-2" variant="overline" fontSize = "17px" >{employeeCount}</Typography>
                            </Box>
                        </>
                    }
                </Box>
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <form onSubmit={searchParam.get("type") === "employee" ? EmployeeSubmit(onSubmit) : handleSubmit(onSubmit)} onKeyDown={e => e.key === "Enter" && e.preventDefault()}>
                    <>
                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Box>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    {resourceAllocate("skill-matrix-item.read") && <Tab className="tabs-space start-tab-space" label="Skill" {...a11yProps(0)} />}
                                    {resourceAllocate("skill-user-mapping.read") &&<Tab className="tabs-space" label="Employee" {...a11yProps(1)} />}
                                </Tabs>
                            </Box>
                        </Box>

                        {/*Skilll Item*/}
                        <TabPanel
                            value={value}
                            index={0}>
                            
                            <SkillItem
                                currentTab={value}
                                reset={reset}
                                control={control}
                                errors={errors}
                                setSelectedCategory={setSelectedCategory}
                                setSelectedEmployee={setSelectedEmployee}
                                enteredValue={enteredValue}
                                setEnteredValue={setEnteredValue}
                                selectedUser={selectedUser}
                                setSelectedUsers={setSelectedUsers}
                                getValues={getValues}
                                setValue={setValue}
                                trigger={trigger}
                                resetField={resetField}
                            />
                        </TabPanel>

                        {/* Employee */}
                        <TabPanel value={value} index={1}>
                            <Employee
                                currentTab={value}
                                reset={EmployeeReset}
                                control={EmployeeControl}
                                errors={EmployeeErrors}
                                selectedEmployee={selectedEmployee}
                                setSelectedEmployee={setSelectedEmployee}
                                setEnteredValue={setEnteredValue}
                                setValue={EmployeesetValue}
                                trigger={Employeetrigger}
                                selectedUser={selectedUser}
                                setSelectedUsers={setSelectedUsers}
                            />
                        </TabPanel>

                    </>

                    <Box className="action-box">
                        <Divider sx={{ marginBottom: "20px" }} />
                        {value === 0 && resourceAllocate("skill-matrix-item.write") && <Button type="submit">Add</Button>}
                        {value === 1 && resourceAllocate("skill-user-mapping.write") && <Button type="submit">Add</Button>}
                    </Box>
                </form>

            </DialogContent>
        </BootstrapDialog>
    </div>;
};

export default SkillMatrixActionLayout;
