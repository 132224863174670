import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material";
import { ChangeEvent, FC } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import UploadImg from "../../../assets/images/upload.png";
import "./style.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

interface Props {
    isOpen: boolean;
    onClose: () => void;
    download?: {
        url: string;
        name: string
    };
    onUpload: (e: ChangeEvent<HTMLInputElement>) => void;
    onsubmit: () => void;
    fileName?: string;
    title: string;
    content: string[];
    onDownload?: () => void;
}

const ImportFile: FC<Props> = ({ isOpen, onClose, download, fileName, onUpload, onsubmit, title, content, onDownload }) => (
    <BootstrapDialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-dialog"
    >
        <div className="p-1">
            <div className="image-container">
                <img src={UploadImg} alt="import" className="upload-img" />
            </div>
            <BootstrapDialogTitle id="alert-dialog-title" onClose={onClose}>
                {title}
            </BootstrapDialogTitle>
            <DialogContent>
                {content.map((line, index) => (
                    <Typography variant="body1" key={index}>{`${index + 1}. ${line}`}</Typography>
                ))}
            </DialogContent>
            {
                !fileName && !download?.url ? (
                    <DialogActions style={{ padding: "1px 18px 15px" }}>
                        <Button
                            variant="outlined"
                            sx={{ width: "100%", maxWidth: "100%", padding: "10px" }}
                            onClick={onDownload}
                        >
                            Download Sample
                        </Button>
                        <Button className="ml-2" component="label" autoFocus sx={{ width: "100%", maxWidth: "100%", padding: "10px" }}>
                            Upload
                            <input hidden type="file" id="file" onChange={onUpload} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                        </Button>
                    </DialogActions>
                ) :
                    !fileName ?
                        <DialogActions style={{ padding: "1px 18px 15px" }}>
                            <Button variant="outlined" sx={{ width: "100%", maxWidth: "100%", padding: "10px" }}>
                                <a

                                    href={download?.url}
                                    download={download?.name}
                                    className="link-none-underline"
                                >
                                    Download Sample
                                </a>
                            </Button>

                            <Button className="ml-2" component="label" autoFocus sx={{ width: "100%", maxWidth: "100%", padding: "10px" }}>
                                Upload
                                <input hidden type="file" id="file" onChange={onUpload} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                            </Button>
                        </DialogActions>
                        :
                        <DialogActions style={{ padding: "1px 18px 15px" }}>
                            <Button variant="outlined" component="label" autoFocus sx={{ width: "100%", maxWidth: "100%", padding: "10px" }}>
                                Re-Upload
                                <input hidden type="file" id="file" onChange={onUpload} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                            </Button>

                            <Button onClick={onsubmit} className="ml-2" autoFocus sx={{ width: "100%", maxWidth: "100%", padding: "10px" }}>
                                Submit
                            </Button>
                        </DialogActions>
            }
            {
                fileName && <div className="center mb-2">
                    <Typography variant="caption">{fileName}</Typography>
                </div>
            }
        </div>
    </BootstrapDialog>
);

export default ImportFile;

