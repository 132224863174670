import { LOAD_CMS_PRODUCT } from "../../../actions";
interface IStatus{
    
        _id:string;
        name:string
    
}
interface IAction {
    type: "LOAD_CMS_PRODUCT",
    payload: IStatus
}

interface IState {
    list: IStatus[]
}

const initialState: IState = {
    list: [
        {
            name:"",
            _id: ""
        }
    ]
};

const cmsProductReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case LOAD_CMS_PRODUCT: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
};

export { cmsProductReducer };
