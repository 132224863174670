import { FC, KeyboardEvent, useEffect } from "react";
import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import { cpdDriveService } from "../../../../../services";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { validateEmail } from "../../../../../validations/shared";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { capitalize, replaceHtmlKeyword } from "../../../../../utilities/helper";
import { useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { ICpdDrive, IErrorResponse, ITemplate } from "../../../../../interfaces";
import { calenderInviteValidation } from "../../../../../validations/cpd/drive/action";
import { Box, Button, Chip, Divider, Grid, MenuItem } from "@mui/material";
import { ICpdCalenderInvite, ICpdCalenderInviteField } from "../../../../../interfaces/cpd/drive/action";
import dayjs from "dayjs";
import useUser from "../../../../../hooks/useUser";
import Select from "../../../../../components/mui/select";
import useSnackbar from "../../../../../hooks/useSnackbar";
import EmailSuggestion from "../../../../../components/mui/email-suggestion";
import CustomLabel from "../../../../../components/mui/custom-label";

interface props {
    templates: ITemplate[];
    cpdDrive: ICpdDrive | undefined;
    keywords: { [key: string]: string | number }
}

interface outletProps {
    reFetch: () => void
}

const CalenderInvite: FC<props> = ({ templates, cpdDrive, keywords }) => {
    const { calenderInvite } = cpdDriveService();
    const navigate = useNavigate();
    const { user } = useUser();
    const [searchParam] = useSearchParams();
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();
    const { control, getValues, setValue, resetField, trigger, watch, handleSubmit, reset, formState: { errors } } = useForm<ICpdCalenderInvite>({
        resolver: joiResolver(calenderInviteValidation),
        defaultValues: {
            emailText: "",
            template: "",
            startDateTime: "",
            endDateTime: "",
        }
    });

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === "template") {
                const template = templates.find(template => template._id === value.template);
                setValue("googleTemplate.subject", String(template?.email.subject));
                setValue("googleTemplate.content", String(template?.email.content));
            } trigger(name);
        });
        return () => subscription.unsubscribe();
    }, [watch, templates]);

    useEffect(() => {
        if (cpdDrive) {
            if (cpdDrive?.collegeId?.email.length > 0) {
                const attendeesEmails = cpdDrive?.collegeId?.email;
                setValue("attendees", attendeesEmails);
            }
        }
        if (user) {
            const attendees: string[] = getValues("attendees") || [];
            attendees.push(user?.email);
            setValue("attendees", [...new Set(attendees)]);
            trigger("attendees");
        }
    }, [cpdDrive, user]);

    const addEmail = (e: KeyboardEvent<HTMLDivElement>, key: string) => {
        let payload: string[] = [];

        if (key === "emailText") {
            const err = validateEmail(getValues(key));
            if (err.error) {
                return;
            }

            const enteredEmail = getValues(key) ? String(getValues(key)) : "";

            if (enteredEmail.trim() !== "" && e.key === "Enter") {
                const prev = getValues("attendees") ? getValues("attendees") : [];
                payload = [...prev, enteredEmail];
                setValue("attendees", [...new Set(payload)]);
                resetField(key);
            }
        }
    };

    const handleEmailSelect = (email: string, name: string) => {
        let payload: string[] = [];
        if (name === "emailText") {
            const prev = getValues("attendees") ? getValues("attendees") : [];
            payload = [...prev, email];
            setValue("attendees", [...new Set(payload)]);
            resetField(name);

        }
    };

    const removeEmail = (value: string) => {
        let payload = getValues("attendees");
        payload = payload.filter(email => email !== value);
        setValue("attendees", payload);
        trigger("attendees");
    };

    // eslint-disable-next-line
    const selectStartDate = (value: Date | null) => {
        const date = value && dayjs(value) ? dayjs(value)?.toISOString() : undefined;
        setValue("startDateTime", date);
        trigger("startDateTime");
    };

    // eslint-disable-next-line
    const selectEndDate = (value: Date | null) => {
        const date = value && dayjs(value) ? dayjs(value)?.toISOString() : undefined;
        setValue("endDateTime", date);
        trigger("endDateTime");
    };

    const onSubmit = async (data: ICpdCalenderInvite) => {
        const selectedStartTime = dayjs(data.startDateTime);
        const selectedEndTime = dayjs(data.endDateTime);
        const currentTime = dayjs();

        if (selectedStartTime.isAfter(selectedEndTime)) {
            snackbar("End date must be equal or greater than the start date", "warning");
            return;
        }

        if (selectedStartTime.isBefore(currentTime, "minute")) {
            snackbar("Calendar Invite start time cannot be in the past. Please select a current or future time", "warning");
            return;
        }

        if (selectedEndTime.isBefore(currentTime, "minute")) {
            snackbar("Calendar Invite end time cannot be in the past. Please select a current or future time", "warning");
            return;
        }

        const moreKeywords = {
            ...keywords,
        };
        const payload = {
            ...data,
            startDateTime: data.startDateTime,
            endDateTime: data.endDateTime,
            googleTemplate: {
                subject: replaceHtmlKeyword(data.googleTemplate?.subject, moreKeywords),
                content: replaceHtmlKeyword(data.googleTemplate?.content, moreKeywords)
            }
        };
        delete payload.emailText;
        delete payload.template;

        try {
            const interviewScheduled = await calenderInvite({
                ...payload,
                _cpd: cpdDrive?._id,
            });
            navigate({
                pathname: "/cpd/drives",
                search: searchParam.toString()
            });
            snackbar(interviewScheduled.message, "info");
            outlet.reFetch();
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }
    };

    const fields: ICpdCalenderInviteField[] = [
        {
            type: "input",
            name: "emailText",
            label: "Attendees",
            placeholder: "Type email address and press enter"
        },
        {
            type: "select",
            name: "template",
            label: "Template",
            required: true,
            children: templates.map(template => <MenuItem key={template._id} value={template._id}>{capitalize(template.title)}</MenuItem>)
        },
        {
            type: "date",
            name: "startDateTime",
            label: "Calendar Invite Start Date and Time",
            required: true
        },
        {
            type: "date",
            name: "endDateTime",
            label: "Calendar Invite End Date and Time",
            required: true
        }
    ];
    const onCopy = (url:string,label: string) => {
        navigator.clipboard.writeText(url);
        snackbar(`${capitalize(label)} ID copied to clipboard`, "info");
    };

    console.log(errors);
    return (
        <Box paddingTop="10px">
            <Box sx={{ height: "37vh", overflow: "auto", padding: "10px 4px 0px" }}>
                <form onSubmit={handleSubmit(onSubmit)} onKeyDown={e => e.key === "Enter" && e.preventDefault()}>
                    <Grid container spacing={4}>
                        {
                            fields.map(field => {
                                if (field.type === "input" && field.name === "emailText") {
                                    return (<Grid key={field.label} item xs={12} md={field.width ? field.width : 6}>
                                        <EmailSuggestion
                                            control={control}
                                            label={<CustomLabel label={field.label} required={field?.required} />}
                                            name={field.name}
                                            value={getValues(field.name)}
                                            placeholder={field.placeholder}
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message}
                                            onEmailSelect={handleEmailSelect}
                                            onKeyUp={addEmail}
                                        />
                                        {
                                            <Box>
                                                {
                                                    field.name === "emailText" && getValues("attendees") &&
                                                    getValues("attendees").map(email => <Chip
                                                        key={email}
                                                        label={email}
                                                        onDelete={() => removeEmail(email)}
                                                        color="primary"
                                                        variant="outlined"
                                                        sx={{ margin: "5px" }}
                                                        onClick = {() => onCopy(email,"email")}
                                                    />)
                                                }
                                            </Box>
                                        }
                                    </Grid>
                                    );
                                } else if (field.name === "startDateTime") {
                                    return (<Grid key={field.label} item xs={12} md={6}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDateTimePicker
                                                label={<CustomLabel label={field.label} required={field?.required} />}
                                                onChange={(e: Date | null) => selectStartDate(e)}
                                                slotProps={{
                                                    textField: {
                                                        error: errors[field.name] ? true : false,
                                                        helperText: errors[field.name]?.message
                                                    }
                                                }}
                                                shouldDisableDate={(date) => dayjs(date).isBefore(dayjs(), "day")}
                                                format="LLL"
                                            />
                                        </LocalizationProvider>

                                    </Grid>
                                    );
                                } else if (field.name === "endDateTime") {
                                    return (<Grid key={field.label} item xs={12} md={6}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDateTimePicker
                                                label={<CustomLabel label={field.label} required={field?.required} />}
                                                onChange={(e: Date | null) => selectEndDate(e)}
                                                slotProps={{
                                                    textField: {
                                                        error: errors[field.name] ? true : false,
                                                        helperText: errors[field.name]?.message
                                                    }
                                                }}
                                                shouldDisableDate={(date) => dayjs(date).isBefore(dayjs(), "day")}
                                                format="LLL"
                                            />
                                        </LocalizationProvider>

                                    </Grid>
                                    );
                                }
                                else {
                                    return (<Grid key={field.label} item xs={12} md={6}>
                                        <Select
                                            control={control}
                                            className="disable-text"
                                            name={field.name}
                                            label={<CustomLabel label={field.label} required={field?.required} />}
                                            size={"small"}
                                            variant={"outlined"}
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message}
                                        >
                                            {field.children}
                                        </Select>
                                    </Grid>
                                    );
                                }
                            })
                        }
                    </Grid>
                    <Box className="action-box">
                        <Divider sx={{ marginBottom: "16px" }} />
                        <Button variant="outlined" onClick={() => reset()}>clear</Button>
                        <Button type="submit">Schedule</Button>
                    </Box>
                </form>
            </Box>
        </Box>
    );
};

export default CalenderInvite;
