import Joi from "joi";
import { required } from "../../shared";

export const OfficeEmployeeInageValidation = Joi.object({
    image: Joi.string()
        .required()
        .label("Image")
        .messages({
            "string.empty": required
        }),
    altText: Joi.string()
        .min(3)
        .max(200)
        .trim()
        .required()
        .label("Name")
        .messages({
            "string.empty": required
        }),
});