import HttpService from "../../http";
import { ICoderByteLinksResponse, ICoderByteLinkResponse } from "../../../interfaces";
import { coderByteLink } from "../../endpoints";

const CoderByteLinkService = () => {
    const { httpRequest } = HttpService();
    const addCoderByteLink = async (payload: object): Promise<ICoderByteLinkResponse> => new Promise((resolve, reject) => {
        httpRequest<ICoderByteLinkResponse>(
            "POST",
            `${coderByteLink}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getCoderByteLinks = async (search: object): Promise<ICoderByteLinksResponse> => new Promise((resolve, reject) => {
        httpRequest<ICoderByteLinksResponse>(
            "GET",
            `${coderByteLink}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getCoderByteLink = async (query: object): Promise<ICoderByteLinkResponse> => new Promise((resolve, reject) => {
        httpRequest<ICoderByteLinkResponse>(
            "GET",
            `${coderByteLink}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateCoderByteLink = async (payload: object): Promise<ICoderByteLinkResponse> => new Promise((resolve, reject) => {
        httpRequest<ICoderByteLinkResponse>(
            "PUT",
            `${coderByteLink}`,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteCoderByteLink = async (payload: object): Promise<ICoderByteLinkResponse> => new Promise((resolve, reject) => {
        httpRequest<ICoderByteLinkResponse>(
            "DELETE",
            `${coderByteLink}`,
            {},
            payload
        )
            .then(resolve)
            .catch(reject);
    });


    return { addCoderByteLink, getCoderByteLinks, getCoderByteLink, updateCoderByteLink, deleteCoderByteLink};
};

export { CoderByteLinkService };
