import { Box, Button, Grid, TextField, Chip, SelectChangeEvent } from "@mui/material";
import { FC, useState, KeyboardEvent } from "react";
import CustomTable from "../../../../../components/mui/table";
import { IColumn, IContactPersonDetail, IContactPerson, IContactPersonState } from "../../../../../interfaces";
import GetActions from "../../../../../components/get-actions";
import { Controller } from "react-hook-form";
import useSnackbar from "../../../../../hooks/useSnackbar";
import WarningDialog from "../../../../../components/mui/warning-dialog";
import { validateEmail } from "../../../../../validations/shared";
import MobileNumber from "../../../../../components/mui/mobile-number";
import { capitalize, formatMobileNumber } from "../../../../../utilities/helper";
import CustomTypography from "../../../../../components/mui/max-length-limit";
import ManageContactPerson from "./manage";
import { Control, FieldErrors, UseFormGetValues, UseFormHandleSubmit, UseFormReset, UseFormResetField, UseFormSetValue, UseFormTrigger } from "react-hook-form";

interface props {
  currentTab: number;
  handleSubmit: UseFormHandleSubmit<IContactPersonDetail>;
  getValues: UseFormGetValues<IContactPersonDetail>;
  trigger: UseFormTrigger<IContactPersonDetail>;
  control: Control<IContactPersonDetail>;
  errors: FieldErrors<IContactPersonDetail>;
  setValue: UseFormSetValue<IContactPersonDetail>;
  resetField: UseFormResetField<IContactPersonDetail>;
  reset: UseFormReset<IContactPersonDetail>;
  tableData: IContactPersonDetail[];
  setTableData: React.Dispatch<React.SetStateAction<IContactPersonDetail[]>>
}

const AddContactPersonDetail: FC<props> = ({ currentTab, handleSubmit, getValues, trigger, control, errors, setValue, reset, resetField, tableData, setTableData }) => {
  const { snackbar } = useSnackbar();
  const [state, setState] = useState<IContactPersonState>({
    list: [],
    deleteWarning: false,
    manageContactPerson: {
      isOpen: false,
      contactPerson: {
        name: "",
        email: [],
        mobileNumber: {
          country: "",
          dialCode: "",
          iso2: "",
          number: "",
        },
        _id: "",
        designation: "",
        notes: "",
      }
    },
    _contactPerson: "",
    deleteIndex: -1,
  });

  const [mobileNumber, setMobileNumber] = useState({
    country: "INDIA",
    dialCode: "+91",
    iso2: "IN"
  });

  const onNumberChange = (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    const data = value.split(":");
    setMobileNumber({
      country: data[0],
      iso2: data[1],
      dialCode: data[2]
    });
  };

  const addEmail = (e: KeyboardEvent<HTMLDivElement>, key: string) => {
    let payload: string[] = [];

    if (key === "emailText") {
      const err = validateEmail(getValues(key));
      if (err.error) {
        return;
      }
      const enteredEmail = getValues(key) ? String(getValues(key)) : "";
      if (enteredEmail.trim() !== "" && e.key === "Enter") {
        const prev = getValues("email") ? getValues("email") : [];
        payload = [...prev, enteredEmail];
        setValue("email", [...new Set(payload)]);
        resetField(key);
      }
    }
  };

  const removeEmail = (value: string) => {
    let payload = getValues("email");
    payload = payload.filter(email => email !== value);
    setValue("email", payload);
    trigger("email");
  };

  const handleDelete = (indexToDelete: number) => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      deleteIndex: indexToDelete,
    }));
  };

  const onDelete = () => {
    const { deleteIndex } = state;
    if (deleteIndex !== -1) {
      try {
        const updatedDataArray = [...tableData];
        updatedDataArray.splice(deleteIndex, 1);
        setTableData(updatedDataArray);
        snackbar("Record Deleted successfully", "info");
        handleDelete(-1);
      } catch (error) {
        snackbar("An error occurred", "warning");
        handleDelete(-1);
        console.log({ "Error in delete contact person": error });
      }
    }
  };

  const handleEdit = (indexToDelete: number) => {

    const updatedDataArray = [...tableData];
    const contactPerson =  updatedDataArray[indexToDelete];
    const index = indexToDelete;
    setState(prevState => ({
      ...prevState,
      deleteIndex: index,
      manageContactPerson:{
        ...prevState.manageContactPerson,
        contactPerson:contactPerson,
        isOpen : !prevState.manageContactPerson.isOpen,
      }
    }));
  };

  
  const onSubmit = (data: IContactPersonDetail) => {
    try {
      const payload = {
        name: data?.name,
        email: data?.email,
        mobileNumber: {
          ...mobileNumber,
          number: data?.number,
        },
        designation: data?.designation,
        notes: data?.notes,
      };
      const updatedDataArray = [...tableData, payload as unknown as IContactPersonDetail];
      setTableData(updatedDataArray);
      reset();
      snackbar("Record Created successfully", "info");
    } catch (error) {
      console.error("Error in contact detail", error);
      snackbar("An error occurred", "warning");
    }
  };

  const fields: IContactPerson[] = [
    {
      type: "input",
      name: "name",
      label: "Name*",
      placeholder: "Type name here"
    },
    {
      type: "mobile-number",
      name: "number",
      label: "Mobile Number*",
      placeholder: "Type mobile number here"
    },
    {
      type: "input",
      name: "emailText",
      label: "Email*",
      placeholder: "Type email address and press enter"
    },
    {
      type: "input",
      name: "designation",
      label: "Designation*",
      placeholder: "Type designation here"
    },
    {
      type: "input",
      name: "notes",
      label: "Note",
      placeholder: "Type note here"
    },
  ];

  const columns: IColumn[] = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "contactNumber",
      label: "Mobile Number"
    },
    {
      id: "email",
      label: "Email"
    },
    {
      id: "designation",
      label: "Designation"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

      // eslint-disable-next-line
  const createRow = (index: number, contactDetail: any) => {
    const action = <GetActions
      icons={[
        { name: "Edit", method: () => handleEdit(index) },
        { name: "Delete", method: () => handleDelete(index) },
      ]}
    />;

    const emails = contactDetail?.email;
    let emailText = "";

    if (emails?.length > 0) {
      emailText = emails[0] + (emails?.length > 1 ? `+${emails?.length - 1}` : "");
    }

    return {
      id: index + 1,
      name: (
        <CustomTypography
          limit={30}
          label={capitalize(contactDetail?.name)}
        />
      ),
      email: emailText,
      contactNumber: formatMobileNumber(contactDetail?.mobileNumber) || "",
      designation: capitalize(contactDetail?.designation),
      action
    };
  };

  const isActionActive = currentTab === 1;
  return (
    <Box paddingTop="10px">
      <Box height="56vh" overflow="auto" paddingTop="10px">

        {/* Add Data  */}
        {
          isActionActive &&
          <Box marginBottom="20px">
            <form onSubmit={handleSubmit(onSubmit)} onKeyDown={e => e.key === "Enter" && e.preventDefault()}>
              <Grid container spacing={4}>
                {
                  fields.map(field => {
                    if (field.type === "input") {
                      return (<Grid key={field.label} item xs={4}>
                        <Controller
                          control={control}
                          name={field.name}
                          render={(prop) => <TextField
                            label={field.label}
                            className="disable-text"
                            variant={"outlined"}
                            size={"small"}
                            placeholder={field.placeholder}
                            error={errors[field.name] ? true : false}
                            helperText={errors[field.name]?.message}
                            {...prop.field}
                            onKeyUp={e => addEmail(e, field.name)}
                          />}
                        />
                        {
                          <Box>
                            {
                              field.name === "emailText" && getValues("email") &&
                              getValues("email").map(email => <Chip
                                key={email}
                                label={email}
                                onDelete={() => removeEmail(email)}
                                color="primary"
                                variant="outlined"
                                sx={{ margin: "5px" }}
                              />)
                            }
                          </Box>
                        }
                      </Grid>
                      );
                    } else if (field.type === "mobile-number") {
                      return <Grid key={field.label} item xs={4}>
                        <Controller
                          control={control}
                          name={field.name}
                          render={(prop) => <MobileNumber
                            key={field.label}
                            NumberFieldLabel={field.label}
                            dialCodeValue={`${mobileNumber?.country}:${mobileNumber?.iso2}:${mobileNumber?.dialCode}`}
                            onChange={onNumberChange}
                            placeholder={field.placeholder}
                            error={errors[field.name] ? true : false}
                            helperText={errors[field.name]?.message}
                            other={prop.field}
                          />}
                        />
                      </Grid>;
                    }
                  })
                }
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    type="submit"
                  >
                    Add Contact Person
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        }

        {/* Show Data  */}
        <CustomTable
          columns={columns}
          rows={tableData.map((item, index) => createRow(index, item))}
          height={"calc(100% - 135px)"}
          width="calc(100% - 2px)"
        />

        {/* Delete Data  */}
        <WarningDialog
          isOpen={state.deleteWarning}
          onClose={() => handleDelete(-1)}
          onConfirm={() => onDelete()}
          title="Delete Contact Person"
          description="Are you sure you want to delete this contact person?"
        />

        {/* Manage Data  */}
        <ManageContactPerson
          contactPerson={state.manageContactPerson.contactPerson}
          isOpen={state.manageContactPerson.isOpen}
          onClose={() => handleEdit(-1)}
          tableData={tableData}
          index={state.deleteIndex}
          setTableData={setTableData}
        />
      </Box>
    </Box>
  );
};

export default AddContactPersonDetail;