import HttpService from "../../http";
import { ITeamsResponse, ITeamResponse } from "../../../interfaces/configuration/team";
import { team } from "../../endpoints";

const TeamsService = () => {
    const { httpRequest } = HttpService();
    const addTeam = async (payload: object): Promise<ITeamsResponse> => new Promise((resolve, reject) => {
        httpRequest<ITeamsResponse>(
            "POST",
            `${team}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getPartialTeams = async (): Promise<ITeamsResponse> => new Promise((resolve, reject) => {
        httpRequest<ITeamsResponse>(
            "GET",
            `${team}/list/partial`,
            {},
            {}
        )
            .then(resolve)
            .catch(reject);
    });

    const getTeams = async (search: object): Promise<ITeamsResponse> => new Promise((resolve, reject) => {
        httpRequest<ITeamsResponse>(
            "GET",
            `${team}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getTeam = async (query: object): Promise<ITeamResponse> => new Promise((resolve, reject) => {
        httpRequest<ITeamResponse>(
            "GET",
            `${team}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateTeam = async (id: string, payload: object): Promise<ITeamResponse> => new Promise((resolve, reject) => {
        httpRequest<ITeamResponse>(
            "PUT",
            `${team}`,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteTeam = async (query: object): Promise<ITeamsResponse> => new Promise((resolve, reject) => {
        httpRequest<ITeamsResponse>(
            "DELETE",
            `${team}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });
    return { getTeams, getPartialTeams, addTeam, getTeam, updateTeam, deleteTeam };
};

export { TeamsService };
