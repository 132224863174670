import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import "./style.scss";

const modules = [
  {
    title: "Candidate Module",
    route: "candidate",
    description: "Automate email, SMS, and WhatsApp templates when candidate applied, haven't interaction with candidate profile etc...",
    icon: <SupervisedUserCircleIcon fontSize="large" color="action" />
  },
  {
    title: "CPD Module",
    route: "cpd",
    description: "Automate email, SMS, and WhatsApp templates for status changes or notify end-users and employees if statuses haven't changed for a long time.",
    icon: <SchoolIcon fontSize="large" color="action" />
  },
  {
    title: "Job Module",
    route: "job",
    description: "Automate email, SMS, and WhatsApp templates for status changes or notify end-users and employees if statuses haven't changed for a long time.",
    icon: <WorkIcon fontSize="large" color="action" /> 
  },
];

const Automation = () => {
  const navigate = useNavigate();

  return (
    <Box className="automation-layout">
      <Typography className="ml-1" variant="h5">Automation Models</Typography>
      {
        modules.map(module => <div key={module.title} className="module-boxes mt-3 mb-2">
          <div className="module-box" onClick={() => navigate(`/configurations/automation/list?module=${module.route}`)}>
            <Box display="flex" alignItems="center">
              <div className="left">
                {module.icon}
              </div>
              <div className="right ml-3">
                <Typography variant="h6">{module.title}</Typography>
                <Typography variant="caption">{module.description}</Typography>
              </div>
            </Box>
          </div>
        </div>
        )
      }

    </Box>
  );
};

export default Automation;