import Joi from "joi";
import { required, only_string } from "../../shared";

export const dailyReportValidation = Joi.object({
    reportType: Joi.string()
        .required()
        .label("Report Type")
        .max(80)
        .min(3)
        .trim()
        .messages({
            "string.empty": required,
            "string.pattern.base": only_string,
            "string.max": "Maximum 80 characters are allowed"
        }),
    _roles: Joi.array().items(Joi.string()
        .required()
        .label("Role")
        .max(80)
        .min(3)
        .messages({
            "string.empty": required,
            "string.pattern.base": only_string,
            "string.max": "Maximum 80 characters are allowed"
        }))
        .required()
        .label("Role")
        .messages({
            "string.empty" : required
        }),
    duration: Joi.string()
        .required()
        .label("Time Period")
        .max(80)
        .min(3)
        .messages({
            "string.empty": required,
            "string.pattern.base": only_string,
            "string.max": "Maximum 80 characters are allowed"
        }),
    bcc: Joi.array().items(
        Joi.string()
        .optional()
        .allow("")
    ),
    cc: Joi.array().items(
        Joi.string()
        .optional()
        .allow("")
    ),
    bccText: Joi.string()
        .optional()
        .allow(""),
    ccText: Joi.string()
        .optional()
        .allow("")
});
