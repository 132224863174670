import "../../../../components/shared/filter/style.scss";
import { FC, useEffect, useState } from "react";
import { Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton, ListItemText, Menu, Typography } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MenuItem } from "../../../../components/shared/filter";
import { useQuery } from "@tanstack/react-query";
import { VendorCategoryService } from "../../../../services/vendor/vendor-category";
import { capitalize } from "../../../../utilities/helper";
import { IVendorFilter } from "../../../../interfaces/vendor";
import { VendorServiceService } from "../../../../services/vendor/vendor-service";


interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    OnClose: () => void;
}

const VendorFilters: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { getVendorPartialCategory } = VendorCategoryService();
    const { getVendorServices } = VendorServiceService();

    const categories = useQuery({
      queryKey: ["allCategories"],
      queryFn: () =>
        getVendorPartialCategory({
          type: "SERVICE",
        }),
    });

    const categoriesData = categories?.data?.data; 

    const vendorsService = useQuery({
        queryKey: ["allServices"],
        queryFn: () =>
          getVendorServices({
          }),
      });

    const servicesData = vendorsService?.data?.data;

    const statusList = [
        { value: "ACTIVE", key: "ACTIVE" },
        { value: "INACTIVE", key: "INACTIVE" },
    ];

    const ratingList = [
        { key: "1", value: "Below 1" },
        { key: "2", value: "Between 1 and 2" },
        { key: "3", value: "Between 2 and 3" },
        { key: "4", value: "Between 3 and 4" },
        { key: "5", value: "Above 4" }
    ];

    const [state, setState] = useState<IVendorFilter>({ 
        selectedMenu: 0,
        categories: [],
        primaryServices: [],
        secondaryServices: [],
        status: [],
        rating: []
    });

    useEffect(() => {
        resetFilter(); 
    }, []);


    useEffect(() => {
        const categories: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
        const primaryServices: { key: string, value: string }[] = searchParams.get("primaryServices") ? JSON.parse(String(searchParams.get("primaryServices"))) : [];
        const secondaryServices: { key: string, value: string }[] = searchParams.get("secondaryServices") ? JSON.parse(String(searchParams.get("secondaryServices"))) : [];
        const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
        const rating: { key: string, value: string }[] = searchParams.get("rating") ? JSON.parse(String(searchParams.get("rating"))) : [];

        setState(prevState => ({
            ...prevState,
            categories,
            primaryServices,
            secondaryServices,
            status,
            rating
        }));
    }, [searchParams]);


    const handleLeftListItem = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setState(prevState => ({
            ...prevState,
            selectedMenu: index
        }));
    };


    const handleRightListItem = (name: "categories" | "primaryServices" | "secondaryServices" | "status" | "rating" , key: string, value: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];
        const isExist = state[name].find(ele => ele.key === key) ? true : false;
        if (isExist) {
            payload = state[name].filter(ele => ele.key !== key);
        } else {
            payload = state[name];
            payload.push({
                key,
                value
            });
        }


        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const deleteChip = (name:  "categories" | "status" | "primaryServices" | "secondaryServices" | "rating", key: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        payload = state[name].filter(ele => ele.key !== key);
        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };


    const resetFilter = () => {
        setState({
            selectedMenu: 0,
            categories: [],
            primaryServices: [],
            secondaryServices: [],
            status: [],
            rating: []
        });
    };

    const onApply = () => {
        searchParams.set("category", JSON.stringify(state.categories));
        searchParams.set("primaryServices", JSON.stringify(state.primaryServices));
        searchParams.set("secondaryServices", JSON.stringify(state.secondaryServices));
        searchParams.set("status", JSON.stringify(state.status));
        searchParams.set("rating", JSON.stringify(state.rating));


        searchParams.set("page", "1");
        navigate({
            pathname: "/directory/vendors",
            search: searchParams.toString()
        });
        OnClose();
    };

    const onClose = () => {
        const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
        const primaryServices: { key: string, value: string }[] = searchParams.get("primaryServices") ? JSON.parse(String(searchParams.get("primaryServices"))) : [];
        const secondaryServices: { key: string, value: string }[] = searchParams.get("secondaryServices") ? JSON.parse(String(searchParams.get("secondaryServices"))) : [];
        const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
        const rating: { key: string, value: string }[] = searchParams.get("rating") ? JSON.parse(String(searchParams.get("rating"))) : [];
        setState(prevState => ({
            ...prevState,
            category,
            primaryServices,
            secondaryServices,
            status,
            rating
        }));
        OnClose();
    };

    return <>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={OnClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            <Box id="filters-container">
                <Box className="center mb-3" justifyContent="space-between" alignItems="start">
                    <div className="active-filter mb-1">
                        {
                            (state.categories.length || state.status.length || state.primaryServices.length || state.secondaryServices.length || state.rating.length) ?
                                <>

                                    {state.categories.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("categories", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.primaryServices.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("primaryServices", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.secondaryServices.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("secondaryServices", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.status.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("status", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.rating.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("rating", ele.key)} label={ele.value} variant="outlined" />)}
                  
                                </>
                                :
                                <Box className="mt-2" display="flex" alignItems="center">
                                    <FilterAltOffIcon />
                                    <Typography className="ml-2">No filters are applied</Typography>
                                </Box>
                        }
                    </div>
                    <IconButton 
                        onClick={onClose}
                        style={{ marginRight: "-10px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Grid className="filter-box" container>
                    <Grid id="left" item xs={5}>
                        <List component="nav">
                            <MenuItem
                                index={0}
                                label="Category"
                                selectedMenu={state.selectedMenu === 0}
                                onChange={handleLeftListItem}
                                count={state.categories}
                            />

                            <MenuItem
                                index={1}
                                label="Primary Services"
                                selectedMenu={state.selectedMenu === 1}
                                onChange={handleLeftListItem}
                                count={state.primaryServices}
                            />

                            <MenuItem
                                index={2}
                                label="Secondary Services"
                                selectedMenu={state.selectedMenu === 2}
                                onChange={handleLeftListItem}
                                count={state.secondaryServices}
                            />

                            <MenuItem
                                index={3}
                                label="Status"
                                selectedMenu={state.selectedMenu === 3}
                                onChange={handleLeftListItem}
                                count={state.status}
                            />  

                            <MenuItem
                                index={4}
                                label="Rating"
                                selectedMenu={state.selectedMenu === 4}
                                onChange={handleLeftListItem}
                                count={state.rating}
                            />  

                        </List>
                    </Grid>
                    <Divider orientation="vertical" />

                    <Grid id="right" item xs={6}>
                        <List component="nav">
                            {
                                state.selectedMenu === 0 &&
                                categoriesData && categoriesData.map(category => <ListItemButton
                                    key={category?._id}
                                    selected={state.categories.find(ele => ele.key === category._id) ? true : false}
                                    onClick={() => handleRightListItem("categories", category._id, category.name)}
                                >
                                    <ListItemText primary={capitalize(category.name)} />
                                    <Checkbox edge="end" checked={state.categories.find(ele => ele.key === category._id) ? true : false} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 1 &&
                                servicesData && servicesData.map(service => <ListItemButton
                                    key={service?._id}
                                    selected={state.primaryServices.find(ele => ele.key === service._id) ? true : false}
                                    onClick={() => handleRightListItem("primaryServices", service._id, service.name)}
                                >
                                    <ListItemText primary={capitalize(service.name)} />
                                    <Checkbox edge="end" checked={state.primaryServices.find(ele => ele.key === service._id) ? true : false} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 2 &&
                                servicesData && servicesData.map(service => <ListItemButton
                                    key={service?._id}
                                    selected={state.secondaryServices.find(ele => ele.key === service._id) ? true : false}
                                    onClick={() => handleRightListItem("secondaryServices", service._id, service.name)}
                                >
                                    <ListItemText primary={capitalize(service.name)} />
                                    <Checkbox edge="end" checked={state.secondaryServices.find(ele => ele.key === service._id) ? true : false} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 3 &&
                                statusList.map(category => <ListItemButton
                                    key={category?.key}
                                    selected={state.status.find(ele => ele.key === category.key) ? true : false}
                                    onClick={() => handleRightListItem("status", category.key, category.value)}
                                >
                                    <ListItemText primary={capitalize(category.value)} />
                                    <Checkbox edge="end" checked={state.status.find(ele => ele.key === category.key) ? true : false} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 4 &&
                                ratingList.map(category => <ListItemButton
                                    key={category?.key}
                                    selected={state.rating.find(ele => ele.key === category.key) ? true : false}
                                    onClick={() => handleRightListItem("rating", category.key, category.value)}
                                >
                                    <ListItemText primary={capitalize(category.value)} />
                                    <Checkbox edge="end" checked={state.rating.find(ele => ele.key === category.key) ? true : false} />
                                </ListItemButton>)
                            }
                        </List>

                    </Grid>
                </Grid>

                <Box className="actions-btn" marginTop="8px" textAlign="end">
                    <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
                    <Button className="ml-2" onClick={onApply}>Apply</Button>
                </Box>
            </Box>
        </Menu>
    </>;
};

export default VendorFilters;