import useUser from "./useUser";

const useResource = () => {
    const { user } = useUser();

    const resourceAllocate = (resource: string): boolean => {
        if (user) {
            return user._role?.resources.includes(resource) ? true : false;
        } else {
            return false;
        }
    };

    const allowPermission = (id: string): boolean => {
        if (user && user._role?.role === "SUPERADMIN") {
            return true;
        } else if (user && user._role.role === "ADMIN") {
            return user._id === id ? true : false;
        } else {
            return false;
        }
    };

    const isSuperAdmin = (): boolean => {
        if (user && user._role?.role === "SUPERADMIN") {
            return true;
        } else {
            return false;
        }
    };

    return { resourceAllocate, allowPermission, isSuperAdmin };
};

export default useResource;