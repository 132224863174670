import { FC, useEffect, useState } from "react";
import { Box, Button, capitalize, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton, ListItemText, Menu, Typography } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { MenuItem } from "../../../components/shared/filter";
import { IRole } from "../../../interfaces";
import { useSelector } from "react-redux";


interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    OnClose: () => void;
}

export interface IRequestedSkillFilter {
    selectedMenu: number;
    status: {
        key: string,
        value: string
    }[];
    roles: {
        key: string,
        value: string
    }[];
}

const RequestedSkillFilter: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const roles = useSelector<{ role: { list: IRole[] } }, IRole[]>((state) => state.role.list);

    const [state, setState] = useState<IRequestedSkillFilter>({
        selectedMenu: 0,
        status: [],
        roles: [],
    });

    useEffect(() => {
        const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
        const roles: { key: string, value: string }[] = searchParams.get("roles") ? JSON.parse(String(searchParams.get("roles"))) : [];

        setState(prevState => ({
            ...prevState,
            status,
            roles,
        }));
    }, [searchParams]);


    const handleLeftListItem = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setState(prevState => ({
            ...prevState,
            selectedMenu: index
        }));
    };


    const handleRightListItem = (name: "status" | "roles", key: string, value: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];
        const isExist = !!state[name].find(ele => ele.key === key);
        if (isExist) {
            payload = state[name].filter(ele => ele.key !== key);
        } else {
            payload = state[name];
            payload.push({
                key,
                value
            });
        }


        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const deleteChip = (name: "status" | "roles", key: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        payload = state[name].filter(ele => ele.key !== key);
        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };


    const resetFilter = () => {
        setState({
            selectedMenu: 0,
            status: [],
            roles: [],
        });
    };

    const onApply = () => {
        searchParams.set("status", JSON.stringify(state.status));
        searchParams.set("roles", JSON.stringify(state.roles));

        searchParams.set("page", "1");
        navigate({
            pathname: location.pathname,
            search: searchParams.toString()
        });
        OnClose();
    };

    const statusList = [
        { key: "APPROVED", value: "Approved" },
        { key: "PENDING", value: "Pending" },
        { key: "REJECTED", value: "Rejected" },
    ];

    const handleClose = () => {
        const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
        const roles: { key: string, value: string }[] = searchParams.get("roles") ? JSON.parse(String(searchParams.get("roles"))) : [];
        setState(prevState => ({
            ...prevState,
            status,
            roles,
        }));
        OnClose();
    };

    return <>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={OnClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            <Box id="filters-container">
                <Box className="center mb-3" justifyContent="space-between" alignItems="start">
                    <div className="active-filter mb-1">
                        {
                            (state.roles.length || state.status.length) ?
                                <>

                                    {state.status.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("status", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.roles.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("roles", ele.key)} label={ele.value} variant="outlined" />)}

                                </>
                                :
                                <Box className="mt-2" display="flex" alignItems="center">
                                    <FilterAltOffIcon />
                                    <Typography className="ml-2">No filters are applied</Typography>
                                </Box>
                        }
                    </div>
                    <IconButton
                        onClick={handleClose}
                        style={{ marginRight: "-10px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Grid className="filter-box" container>
                    <Grid id="left" item xs={5}>
                        <List component="nav">
                            <MenuItem
                                index={0}
                                label="Status"
                                selectedMenu={state.selectedMenu === 0}
                                onChange={handleLeftListItem}
                                count={state.status}
                            />
                            <MenuItem
                                index={1}
                                label="Roles"
                                selectedMenu={state.selectedMenu === 1}
                                onChange={handleLeftListItem}
                                count={state.roles}
                            />
                        </List>
                    </Grid>
                    <Divider orientation="vertical" />

                    <Grid id="right" item xs={6}>
                        <List component="nav">
                            {
                                state.selectedMenu === 0 &&
                                statusList.map(status => <ListItemButton
                                    key={status.key}
                                    selected={!!state.status.find(ele => ele.key === status.key)}
                                    onClick={() => handleRightListItem("status", status.key, status.value)}
                                >
                                    <ListItemText primary={status.value} />
                                    <Checkbox edge="end" checked={!!state.status.find(ele => ele.key === status.key)} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 1 &&
                                roles.map(i => <ListItemButton
                                    key={i.name}
                                    selected={!!state.roles.find(ele => ele.key === i.name)}
                                    onClick={() => handleRightListItem("roles", i.name, capitalize(i.name))}
                                >
                                    <ListItemText primary={capitalize(i.name)} />
                                    <Checkbox edge="end" checked={!!state.roles.find(ele => ele.key === i.name)} />
                                </ListItemButton>)
                            }
                        </List>

                    </Grid>
                </Grid>

                <Box className="actions-btn" marginTop="8px" textAlign="end">
                    <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
                    <Button className="ml-2" onClick={onApply}>Apply</Button>
                </Box>
            </Box>
        </Menu>
    </>;
};

export default RequestedSkillFilter;