import { Box, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { CandidateService } from "../../services";
import { useQuery } from "@tanstack/react-query";
import "./style.scss";
import useDebounce from "../../hooks/useDebounce";
import { ICandidate } from "../../interfaces";
import ErrorMessage from "../shared/error-message";
import { capitalize, formateEngagementType } from "../../utilities/helper";
import { useNavigate } from "react-router-dom";
import CustomTypography from "../mui/max-length-limit";


const SearchCandidate = () => {
    let results: ICandidate[] = [];
    const navigate = useNavigate();
    const { getCandidates } = CandidateService();
    const [state, setState] = useState({
        isFocused: false,
        query: ""
    });
    const searchRecord = useDebounce(String(state.query), 1000);
    const candidates = useQuery({
        queryKey: ["search-candidate", searchRecord],
        queryFn: () => getCandidates(
            { page: state.query.length ? 1 : 0, limit: 10 },
            { search: state.query }
        ),
        enabled: !!state.query 
    });

    const navigateToCandidate = (candidate: ICandidate) => {
        setState(prev => ({ ...prev, query: "" }));

        const nav = `/candidates/${candidate.typeOfLead?.toLowerCase()}/manage/${candidate._id}`;
        navigate(nav);
    };

    if (candidates?.data?.data.length) {
        results = candidates?.data?.data;
    }

    return (
        <>
            <TextField
                placeholder="Search by candidate name, email and number"
                onChange={(e) => setState(prev => ({ ...prev, query: e.target.value }))}
                sx={{ width: 400 }}
                value={state.query}
                onFocus={() => setState(prev => ({ ...prev, isFocused: true }))}
                onBlur={() => {
                    setTimeout(() => {
                        setState(prev => ({ ...prev, isFocused: false }));
                    }, 200);
                }}
                InputProps={
                    {
                        endAdornment: (
                            <IconButton
                                disableRipple
                            >
                                <SearchIcon />
                            </IconButton>
                        )
                    }
                }
            />

            {
                state.isFocused &&
                <Box id="search-result">
                    <Box overflow="auto" height="100%">
                        {
                            results.length && state.query?.length
                                ?
                                results.map((result, index) => <MenuItem key={index} onClick={() => navigateToCandidate(result)}>
                                    <Box>
                                        <Box display="flex">
                                            <CustomTypography limit={25} label={capitalize(result.name)} />
                                            <Typography className="ml-1" sx={{ marginTop: "-2px" }}>. &nbsp; </Typography>
                                            <CustomTypography limit={20} label={result.email} />
                                        </Box>
                                        <Typography variant="caption">{formateEngagementType(result.typeOfLead)}</Typography>
                                    </Box>
                                </MenuItem>
                                )
                                :
                                <div className="center h-100">
                                    <ErrorMessage errorMessage={`Search result will shown based query! ${state.query}`} />
                                </div>
                        }
                    </Box>
                </Box >
            }
        </>
    );
};

export default SearchCandidate;