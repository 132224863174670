import "../../../components/shared/filter/style.scss";
import { FC, useEffect, useState } from "react";
import { Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton, ListItemText, Menu, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { IInterviewFilter, IEngagement, IJobTitle } from "../../../interfaces";
import { capitalize } from "../../../utilities/helper";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { MenuItem } from "../../../components/shared/filter";
interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    OnClose: () => void;
}

const InterviewFilter: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
    const navigate = useNavigate();
    const { type } = useParams();
    const [searchParams] = useSearchParams();
    const jobTitles = useSelector<{ jobTitle: { list: IJobTitle[] } }, IJobTitle[]>(state => state.jobTitle.list);
    const engagementTypes = useSelector<{ engagementType: { list: IEngagement[] } }, IEngagement[]>(state => state.engagementType.list) || [];
    const [state, setState] = useState<IInterviewFilter>({
        selectedMenu: 0,
        typeOfLead: [],
        jobId: [],
        interviewStatus: [],
    });

    useEffect(() => {
        const typeOfLead: { key: string, value: string }[] = searchParams.get("typeOfLead") ? JSON.parse(String(searchParams.get("typeOfLead"))) : [];
        const jobId: { key: string, value: string }[] = searchParams.get("jobId") ? JSON.parse(String(searchParams.get("jobId"))) : [];
        const interviewStatus: { key: string, value: string }[] = searchParams.get("interviewStatus") ? JSON.parse(String(searchParams.get("interviewStatus"))) : [];
        setState(prevState => ({
            ...prevState,
            typeOfLead,
            jobId,
            interviewStatus,
        }));
    }, [searchParams]);


    const handleLeftListItem = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setState(prevState => ({
            ...prevState,
            selectedMenu: index
        }));
    };


    const handleRightListItem = (name: "typeOfLead" | "jobId" | "interviewStatus", key: string, value: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        const isExist = state[name].find(ele => ele.key === key) ? true : false;
        if (isExist) {
            payload = state[name].filter(ele => ele.key !== key);
        } else {
            payload = state[name];
            payload.push({
                key,
                value
            });
        }

        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const deleteChip = (name: "typeOfLead" | "jobId" | "interviewStatus", key: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        payload = state[name].filter(ele => ele.key !== key);
        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const resetFilter = () => {
        setState({
            selectedMenu: 0,
            typeOfLead: [],
            jobId: [],
            interviewStatus: [],
        });
    };

    const onApply = () => {
        searchParams.set("typeOfLead", JSON.stringify(state.typeOfLead));
        searchParams.set("jobId", JSON.stringify(state.jobId));
        searchParams.set("interviewStatus", JSON.stringify(state.interviewStatus));

        searchParams.set("page", "1");
        navigate({
            pathname: "/assessments/interview/" + type,
            search: searchParams.toString()
        });
        OnClose();
    };

    const onClose = () => {
        const typeOfLead: { key: string, value: string }[] = searchParams.get("typeOfLead") ? JSON.parse(String(searchParams.get("typeOfLead"))) : [];
        const jobId: { key: string, value: string }[] = searchParams.get("jobId") ? JSON.parse(String(searchParams.get("jobId"))) : [];
        const interviewStatus: { key: string, value: string }[] = searchParams.get("interviewStatus") ? JSON.parse(String(searchParams.get("interviewStatus"))) : [];
        setState(prevState => ({
            ...prevState,
            typeOfLead,
            jobId,
            interviewStatus,
        }));
        OnClose();
    };

    const defaultStatus = type === "upcoming"
        ? [
            {
                key: "TA SCHEDULED",
                name: "TA Scheduled"
            },
            {
                key: "HR SCHEDULED",
                name: "HR Scheduled"
            },
            {
                key: "ROHIT SCHEDULED",
                name: "Rohit Scheduled"
            }
        ]
        : [
            {
                key: "TA REJECTED",
                name: "TA Rejected"
            },
            {
                key: "HR REJECTED",
                name: "HR Rejected"
            },
            {
                key: "ROHIT REJECTED",
                name: "Rohit Rejected"
            },
            {
                key: "TA SELECTED",
                name: "TA Selected"
            },
            {
                key: "HR SELECTED",
                name: "HR Selected"
            },
            {
                key: "ROHIT SELECTED",
                name: "Rohit Selected"
            }
        ];


    return <>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={OnClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            <Box id="filters-container">
                <Box className="center mb-3" justifyContent="space-between" alignItems="start">
                    <div className="active-filter mb-1">
                        {
                            (state.typeOfLead.length || state.jobId.length || state.interviewStatus.length) ?
                                <>
                                    {state.typeOfLead.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("typeOfLead", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.jobId.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("jobId", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.interviewStatus.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("interviewStatus", ele.key)} label={ele.value} variant="outlined" />)}
                                </>
                                :
                                <Box className="mt-2" display="flex" alignItems="center">
                                    <FilterAltOffIcon />
                                    <Typography className="ml-2">No filters are applied</Typography>
                                </Box>
                        }
                    </div>
                    <IconButton
                        onClick={onClose}
                        style={{ marginRight: "-10px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Grid className="filter-box" container>
                    <Grid id="left" item xs={5}>
                        <List component="nav">

                            <MenuItem
                                index={0}
                                label="Engagement Type"
                                selectedMenu={state.selectedMenu === 0}
                                onChange={handleLeftListItem}
                                count={state.typeOfLead}
                            />

                            <MenuItem
                                index={1}
                                label="Job Title"
                                selectedMenu={state.selectedMenu === 1}
                                onChange={handleLeftListItem}
                                count={state.jobId}
                            />

                            <MenuItem
                                index={2}
                                label="Status"
                                selectedMenu={state.selectedMenu === 2}
                                onChange={handleLeftListItem}
                                count={state.interviewStatus}
                            />

                        </List>
                    </Grid>
                    <Divider orientation="vertical" />

                    <Grid id="right" item xs={6}>
                        <List component="nav">
                            {
                                state.selectedMenu === 0 &&
                                engagementTypes.map((engagementType) =>
                                    <ListItemButton
                                        key={engagementType._id}
                                        selected={state.typeOfLead.find(ele => ele.key === engagementType.name.toUpperCase()) ? true : false}
                                        onClick={() => handleRightListItem("typeOfLead", engagementType.name.toUpperCase(), capitalize(engagementType.name))}
                                    >
                                        <ListItemText primary={capitalize(engagementType.name)} />
                                        <Checkbox edge="end" checked={state.typeOfLead.find(ele => ele.key === engagementType.name.toUpperCase()) ? true : false} />
                                    </ListItemButton>
                                )
                            }
                            {
                                state.selectedMenu === 1 &&
                                jobTitles.map(jobTitle => <ListItemButton
                                    key={jobTitle._id}
                                    selected={state.jobId.find(ele => ele.key === jobTitle.name) ? true : false}
                                    onClick={() => handleRightListItem("jobId", jobTitle.name, jobTitle.name)}
                                >
                                    <ListItemText primary={jobTitle.name} />
                                    <Checkbox edge="end" checked={state.jobId.find(ele => ele.key === jobTitle.name) ? true : false} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 2 &&
                                defaultStatus.map(status => <ListItemButton
                                    key={status.key}
                                    selected={state.interviewStatus.find(ele => ele.key === status.key) ? true : false}
                                    onClick={() => handleRightListItem("interviewStatus", status.key, status.name)}
                                >
                                    <ListItemText primary={status.name} />
                                    <Checkbox edge="end" checked={state.interviewStatus.find(ele => ele.key === status.key) ? true : false} />
                                </ListItemButton>)
                            }
                        </List>

                    </Grid>
                </Grid>

                <Box className="actions-btn"  marginTop="8px" textAlign="end">
                    <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
                    <Button className="ml-2" onClick={onApply}>Apply</Button>
                </Box>
            </Box>
        </Menu>
    </>;
};

export default InterviewFilter;