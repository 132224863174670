import HttpService from "../http";
import { IKpisResponse, IKpiResponse } from "../../interfaces/kpi";
import { kpi } from "../endpoints";

const KpiService = () => {
    const { httpRequest } = HttpService();
    const addKpis = async (payload: object): Promise<IKpisResponse> => new Promise((resolve, reject) => {
        httpRequest<IKpisResponse>(
            "POST",
            `${kpi}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getKpis = async (search: object): Promise<IKpisResponse> => new Promise((resolve, reject) => {
        httpRequest<IKpisResponse>(
            "GET",
            `${kpi}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getkpi = async (payload: object): Promise<IKpiResponse> => new Promise((resolve, reject) => {
        httpRequest<IKpiResponse>(
            "GET",
            `${kpi}`,
            {},
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updatekpi = async (id: string, payload: object): Promise<IKpiResponse> => new Promise((resolve, reject) => {
        httpRequest<IKpiResponse>(
            "PUT",
            `${kpi}`,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteKpis = async (payload: object): Promise<IKpisResponse> => new Promise((resolve, reject) => {
        httpRequest<IKpisResponse>(
            "DELETE",
            `${kpi}`,
            {},
            payload
        )
            .then(resolve)
            .catch(reject);
    });
    return { addKpis, getKpis, getkpi, updatekpi, deleteKpis };
};

export default KpiService;