import { Box, Button, Tab, Tabs } from "@mui/material";
import { useNavigate, useOutletContext, useParams, useSearchParams, Outlet } from "react-router-dom";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import EditIcon from "@mui/icons-material/Edit";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import BasicDetail from "./basic-details";
import { UsersService } from "../../../../services";
import { a11yProps, BootstrapDialog, BootstrapDialogTitle, TabPanel } from "../../../../components/shared/mui-tabs";
import useResource from "../../../../hooks/useResource";
import { capitalize, displayName } from "../../../../utilities/helper";
import UserSkillMatrices from "./skill-matrix";
import AddIcon from "@mui/icons-material/Add";
import UserTimeline from "./timeline";

interface outletProps {
  reFetch: () => void
}

interface SideButtonProps {
  index: number;
  setActiveAction: (e: boolean) => void;
}

interface IState {
  redirectionLinks: string[];
}

const SideButton: FC<SideButtonProps> = ({ index, setActiveAction }) => {
  let label = "";

  if (index === 1) {
    label = "Assign Skill Matrix";
  } else {
    return null;
  }

  return (
    <Button
      variant="text"
      size="small"
      startIcon={<AddIcon />}
      onClick={() => setActiveAction(true)}
    >
      {label}
    </Button>
  );
};

const ManageUserLayout = () => {
  const navigate = useNavigate();
  const { resourceAllocate } = useResource();
  const outlet = useOutletContext<outletProps>();
  const { id } = useParams();
  const [activeAction, setActiveAction] = useState<boolean>(false);
  const [searchParam, setSearchParam] = useSearchParams();
  const [isDisable, setIsDisable] = useState(true);
  const [value, setValue] = useState(0);
  const { getUser } = UsersService();

  const [state, setState] = useState<IState>({
    redirectionLinks: [],
  });

  useEffect(() => {
    const redirectionLinks = [...state.redirectionLinks];
    if (!redirectionLinks.length) {
    if(resourceAllocate("user.read") || resourceAllocate("user.readAssigned")){
      redirectionLinks.push("basic-detail");
    }
    if(resourceAllocate("skill-user-mapping.read")){    
      redirectionLinks.push("skill-matrix");
    }
    if(resourceAllocate("user-activity.read")){
      redirectionLinks.push("timeline");
    }

      setState((prev) => ({
        ...prev,
        redirectionLinks,
      }));
    }

    const tab = searchParam.get("type") ? String(searchParam.get("type")) : "basic-detail";
    setValue(redirectionLinks.indexOf(tab));
  }, [state.redirectionLinks, searchParam]);
  
  const user = useQuery({
    queryKey: ["user-details"],
    queryFn: () => getUser({ id }),
    enabled: !!id,
  });
  

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    searchParam.set("type", state.redirectionLinks[newValue]);
    setSearchParam(searchParam);
    setActiveAction(false);
  };

  const onClose = () => {
    searchParam.delete("type");
    navigate({ pathname: "/users", search: searchParam.toString() });
  };

  const name = user.data?.data?.name ? capitalize(displayName(user.data.data)) : "User Detail";
  const assignToMe = user && user.data && user.data.data && user.data.data.assignedToMe;
  return <div>
    <BootstrapDialog
      maxWidth="lg"
      onClose={onClose}
      open={!!id}
    >
      <BootstrapDialogTitle onClose={onClose}>
        <Box display="flex" alignItems="center">
          {name} {<IconButton sx={{ visibility: value === 0 && (resourceAllocate("user.edit") || (resourceAllocate("user.editAssigned") && assignToMe)) ? "visible" : "hidden" }} className="ml-2" color="primary" onClick={() => setIsDisable(false)} ><EditIcon /></IconButton>}
        </Box>
      </BootstrapDialogTitle>
      <DialogContent dividers sx={{ height: "65vh" }}>
        <Box width="100%" height="100%">
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ borderBottom: 1, borderColor: "divider" }}>
            
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                {(resourceAllocate("user.read") || resourceAllocate("user.readAssigned"))&& <Tab className="tabs-space start-tab-space" label="Basic Detail" {...a11yProps(0)} />}
                {resourceAllocate("skill-user-mapping.read") && <Tab className="tabs-space" label="Skill Matrix Details" {...a11yProps(1)} />}
                {resourceAllocate("user-activity.read") && <Tab className="tabs-space" label="Timeline" {...a11yProps(2)} />}
              </Tabs>
              {!activeAction && resourceAllocate("skill-user-mapping.write") && (
                <SideButton index={value} setActiveAction={setActiveAction} />
              )}
            
          </Box>

          {/* Basic detail  */}
          <TabPanel
            style={{
              height: isDisable ? "calc(100% - 60px)" : "calc(100% - 135px)",
              overflow: "auto",
              marginTop: 10
            }}
            value={value}
            index={state.redirectionLinks.indexOf("basic-detail")}>
            <BasicDetail
              user={user.data?.data}  
              isDisable={isDisable}
              setIsDisable={setIsDisable}
            />
          </TabPanel>

            {/* Skill Matrix detail  */}
          <TabPanel
            style={{
              height: isDisable ? "calc(100% - 60px)" : "calc(100% - 135px)",
              overflow: "auto",
              marginTop: 10
            }}
            value={value}
            index={state.redirectionLinks.indexOf("skill-matrix")}>
            <UserSkillMatrices
              activeAction={activeAction}
              setActiveAction={setActiveAction}
            />
          </TabPanel>

          {/* Timeline details  */}
          <TabPanel
            style={{
              height: isDisable ? "calc(100% - 60px)" : "calc(100% - 135px)",
              overflow: "auto",
              marginTop: 10
            }}
            value={value}
            index={state.redirectionLinks.indexOf("timeline")}>
            <UserTimeline />
          </TabPanel>
        </Box>
      </DialogContent>
    </BootstrapDialog>

    <Outlet context={{...outlet}}/>
  </div>;
};

export default ManageUserLayout;
