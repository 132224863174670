import HttpService from "../../http";
import {  IBlogAssignmentResponse } from "../../../interfaces";
const blogAssignmentService = () => {

    const { httpRequest } = HttpService();

    const updateBlogAssignment = async (payload: object): Promise<IBlogAssignmentResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IBlogAssignmentResponse>("PUT", "cms/blog-assignment", payload)
                .then(resolve)
                .catch(reject);
        });

        const bulkBlogAssignment = async (payload: object): Promise<IBlogAssignmentResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IBlogAssignmentResponse>("POST", "cms/blog-assignment/bulk-assign", payload)
                .then(resolve)
                .catch(reject);
        });

    const getBlogAssignment = async (query: object): Promise<IBlogAssignmentResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IBlogAssignmentResponse>("GET", "cms/blog-assignment", {}, query)
                .then(resolve)
                .catch(reject);
        });
    return { getBlogAssignment, updateBlogAssignment,bulkBlogAssignment };

};

export { blogAssignmentService };