import {  IGoalsResponse ,IGoalResponse} from "../../../interfaces/content/goals";
import { goalsList,goal } from "../../endpoints";
import HttpService from "../../http";

const goalsService = () => {

    const { httpRequest } = HttpService();
    const getContentsGoals = async (search:object):Promise<IGoalsResponse> => 
    new Promise((resolve,reject) => {
        httpRequest<IGoalsResponse>(
            "GET",
            `${goalsList}`,
            {},
            search
            )
            .then(resolve)
            .catch(reject);
    });


    const addContentGoal = async (payload : object) :Promise<IGoalResponse>=> new Promise((resolve,reject) => {
        httpRequest<IGoalResponse>(
        "POST",
        `${goal}`,
        payload )
        .then(resolve)
        .catch(reject);
    });

    

    const getContentGoal = async (query:object) : Promise<IGoalResponse> =>  new Promise((resolve,reject) => {
        httpRequest<IGoalResponse>(
            "GET",
            `${goal}`,
            {},
            query
        )
        .then(resolve)
        .catch(reject);
    });

    const getContentGoalPartial = async (query:object) : Promise<IGoalResponse> =>  new Promise((resolve,reject) => {
      httpRequest<IGoalResponse>(
          "GET",
          `${goal}/partial`,
          {},
          query
      )
      .then(resolve)
      .catch(reject);
  });

    const deleteContentGoal = async(payload:object):Promise<IGoalResponse> =>
    new Promise((resolve,reject) => {
      httpRequest<IGoalResponse>("DELETE",`${goal}`,payload)
      .then(resolve)
      .catch(reject);
    });
      
    const updateContentGoal = async(payload:object):Promise<IGoalResponse> =>
      new Promise((resolve,reject) => {
        httpRequest<IGoalResponse>("PUT",`${goal}`,payload)
        .then(resolve)
        .catch(reject);
      });



    return {getContentsGoals,addContentGoal,getContentGoal,deleteContentGoal,updateContentGoal,getContentGoalPartial};

};

export default goalsService;