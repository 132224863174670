import { useEffect } from "react";
import useUser from "../../hooks/useUser";
import { useNavigate } from "react-router-dom";

const Redirect = () => {
    const { user } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        const currentPath = localStorage.getItem("currentPath");
         if (user && user?._role?.resources) {
            const resources = user?._role?.resources;
            if(currentPath && currentPath !== "/") {
                localStorage.removeItem("currentPath");
                navigate(currentPath);
            }
            else if (resources && resources?.includes("dashboard"))
                navigate("/dashboard");
            else if (resources.includes("lead"))
                navigate("/candidates/intern");
            else if (resources.includes("cpd-dashboard"))
                navigate("/cpd/dashboard");
            else if (resources.includes("cpd-drives"))
                navigate("/cpd/drives");
            else if (resources.includes("college"))
                navigate("/cpd/college");
            else if (resources.includes("interview"))
                navigate("/assessments/interview/upcoming");
            else if (resources.includes("review"))
                navigate("/assessments/review/pending");
            else if (resources.includes("job"))
                navigate("/careers/jobs");
            else if (resources.includes("broadcast-group"))
                navigate("/broadcast-group");
            else if (resources.includes("offer"))
                navigate("/offered");
            else if (resources.includes("report-analytics"))
                navigate("/reports/overview");
            else if (resources.includes("trash"))
                navigate("/trash");
            else if (resources.includes("user"))
                navigate("/users");
            else if (resources.includes("organisation-tree"))
                navigate("/organistion-tree");
            else if (resources.includes("skill-matrix")){
                navigate("/skill-matrix");
            } else if(resources.includes("skill-matrix-request")) {
                navigate("/requested-skill");
            }
            else if (resources.includes("cms-home"))
                navigate("/home");
            else if (resources.includes("cms-product-title"))
                navigate("/content/products");
            else if (resources.includes("cms-blog"))
                navigate("/blog");
            else if (resources.includes("cms-quarter"))
                navigate("/planner");
            else if (resources.includes("cms-blog-analytics"))
                navigate("/reports/cms");
            else if (resources.includes("common.read"))
                navigate("/settings/profile");
            else
                navigate("/not-found");
        }
    }, [user]);

    return <></>;
};

export default Redirect;