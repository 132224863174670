import Joi from "joi";
import { required, valid_string } from "../../../shared";

export const candidateReviewValidation = Joi.object({
  _reviewer: Joi.string()
    .label("Reviewer")
    .required()
    .messages({
      "string.empty": valid_string,
    }),

  template: Joi.string()
    .label("Email Template")
    .allow(""),
  ccText: Joi.string()
    .label("CC")
    .allow(""),
  bccText: Joi.string()
    .label("CC")
    .allow(""),

  cc: Joi.array()
    .items(
      Joi.string()
        .email({ tlds: { allow: false } })
    )
    .allow("")
    .label("CC"),
  bcc: Joi.array()
    .items(
      Joi.string()
        .email({ tlds: { allow: false } })
    )
    .allow("")
    .label("BCC"),
  acknowledgedEmail: Joi.array()
    .items(
      Joi.string()
        .email({ tlds: { allow: false } })
    )
    .allow("")
    .label("Acknowledged Email"),
  subject: Joi.string()
    .required()
    .label("Subject")
    .messages({
      "string.empty": required,
    }),
  content: Joi.string()
    .required()
    .label("Body")
    .messages({
      "string.empty": required,
    }),

});
