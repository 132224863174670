export const insightColumns = [
    {
      id: "id",
      label: "S. No.",
    },
    {
      id: "keyword",
      label: "Keyword",
    },
    {
      id: "impressions",
      label: "Impressions",
    },
    {
      id: "clicks",
      label: "Clicks",
    }
];