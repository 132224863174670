import CategoryService from "../../../../services/content/category";
import ProjectsService from "../../../../services/content/projects-products";
import SubCategoryService from "../../../../services/content/sub-category";


const usevalidateKeywords = () => {
    const {getContentProjectPartial} = ProjectsService();
    const { getContentCategoryPartial } = CategoryService();
    const { getContentSubCategoryPartial } = SubCategoryService();

    // eslint-disable-next-line
    const validateKeyword = async (leads: any[]) =>
        // eslint-disable-next-line
        new Promise(async (resolve) => {
            const mandatoryFields = ["name", "_product", "_category", "_subCategory"];
            const payload = [];
            const error = [];
            let row = 2;
            for await (const lead of leads) {
                if (!lead?.length || lead[0] === "Name*") {
                    ++row;
                    continue;
                }

                // eslint-disable-next-line
                const data: any = {};
                const subCategories = [];
                // eslint-disable-next-line
                const errMessage: any = {};

                if (String(lead[0]).trim() === "") {
                    errMessage["name"] = "Name is required";
                } else {
                    data.name = String(lead[0]).trim();
                }

                if (typeof lead[1] === "string") {
                    try {
                        const project = await getContentProjectPartial({
                            search: lead[1].trim(),
                        });
                        if (project.data?._id) {

                            data._product = project.data?._id;

                        } else {
                            errMessage["Product"] = "Please enter a valid product name";
                        }
                    } catch (error) {
                        errMessage["Product"] = "Please enter a valid product name";
                    }
                } else {
                    if (lead[1] !== undefined) {
                        errMessage["Product"] = "Please enter a valid product name";
                    }
                }

                if (typeof lead[2] === "string") {
                    try {
                        const category = await getContentCategoryPartial({
                            search: lead[2].trim(),
                        });
                        if (category.data?._id) {

                            data._category = category.data?._id;

                        } else {
                            errMessage["Category"] = "Please enter a valid category name";
                        }
                    } catch (error) {
                        errMessage["Category"] = "Please enter a valid category name";
                    }
                } else {
                    if (lead[2] !== undefined) {
                        errMessage["Category"] = "Please enter a valid category name";
                    }
                }

                if(typeof lead[3] !== "string" && lead[3] !== undefined){
                    errMessage["Sub Category"] = "Please enter a valid subcategory name";
                }else{
                    let ind = 3;
                    try {
                        while(ind < lead.length){
                            if(lead[ind]){
                                const subCategory = await getContentSubCategoryPartial({
                                    search: lead[ind].trim(),
                                });
                                if (subCategory.data?._id) {
                                    subCategories.push( subCategory.data?._id);
                                } else {
                                    errMessage["Sub Category"] = "Please enter a valid subcategory name";
                                }
                            }
                            ind++;
                        }
                        data._subCategory = subCategories;
                    } catch (error) {
                        errMessage["Sub Category"] = "Please enter a valid subcategory name";
                    }
                }


                if (mandatoryFields.every((value) => Object.keys(data).includes(value))) {
                    if (Object.keys(errMessage).length) {
                        error.push({ ...errMessage, row });
                    } else {
                        payload.push(data);
                    }
                } else {
                    if (Object.keys(errMessage).length) {
                        error.push({ ...errMessage, row });
                    }
                }
                ++row;
            }

            // return all the data
            resolve({
                data: payload,
                errorMessage: error,
            });

        });

    return { validateKeyword };
};
export default usevalidateKeywords;
