import { Box, Button, Grid, TextField, Chip, SelectChangeEvent, Tooltip } from "@mui/material";
import { FC, useState, KeyboardEvent, useEffect } from "react";
import CustomTable from "../../../../../components/mui/table";
import { IColumn, IErrorResponse, IContactPersonDetail, ICollege, IContactPerson, IContactPersonState } from "../../../../../interfaces";
import GetActions from "../../../../../components/get-actions";
import { Controller, useForm } from "react-hook-form";
import { ContactPersonValidation } from "../../../../../validations";
import { joiResolver } from "@hookform/resolvers/joi";
import useSnackbar from "../../../../../hooks/useSnackbar";
import WarningDialog from "../../../../../components/mui/warning-dialog";
import { validateEmail } from "../../../../../validations/shared";
import { CollegeService } from "../../../../../services";
import MobileNumber from "../../../../../components/mui/mobile-number";
import { capitalize, formatMobileNumber } from "../../../../../utilities/helper";
import CustomTypography from "../../../../../components/mui/max-length-limit";
import ManageContactPerson from "./manage";

interface props {
  college: ICollege | undefined;
  currentTab: number;
  activeAction: boolean;
  contactDetailRefetch: () => void;
  contactListRefetch: () => void;
}

const ContactPersonDetail: FC<props> = ({ college, currentTab, activeAction, contactDetailRefetch, contactListRefetch }) => {
  const getcontactPersons = college && college.contactPersons && [...college.contactPersons];
  const { snackbar } = useSnackbar();
  const { addContactPerson, deleteContactPerson } = CollegeService();
  const [state, setState] = useState<IContactPersonState>({
    list: [],
    deleteWarning: false,
    manageContactPerson: {
      isOpen: false,
      contactPerson: {
        name: "",
        email: [],
        mobileNumber: {
          country: "",
          dialCode: "",
          iso2: "",
          number: "",
        },
        _id: "",
        designation: "",
        notes: "",
      }
    },
    _contactPerson: "",
    deleteIndex: -1
  });

  const { control, handleSubmit, reset, trigger, resetField, setValue, getValues, formState: { errors } } = useForm<IContactPersonDetail>({
    resolver: joiResolver(ContactPersonValidation),
    defaultValues: {
      name: "",
      emailText: "",
      number: "",
      designation: "",
      notes: "",
    }
  });

  const [mobileNumber, setMobileNumber] = useState({
    country: "INDIA",
    dialCode: "+91",
    iso2: "IN"
  });

  const onNumberChange = (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    const data = value.split(":");
    setMobileNumber({
      country: data[0],
      iso2: data[1],
      dialCode: data[2]
    });
  };

  useEffect(() => {
    if (getcontactPersons) {
      const list = getcontactPersons.map((contactPerson, i) => createRow(i, contactPerson));
      setState(prevState => (
        {
          ...prevState,
          list
        }
      ));
    }
  }, [college?.contactPersons]);


  const addEmail = (e: KeyboardEvent<HTMLDivElement>, key: string) => {
    let payload: string[] = [];

    if (key === "emailText") {
      const err = validateEmail(getValues(key));
      if (err.error) {
        return;
      }
      const enteredEmail = getValues(key) ? String(getValues(key)) : "";
      if (enteredEmail.trim() !== "" && e.key === "Enter") {
        const prev = getValues("email") ? getValues("email") : [];
        payload = [...prev, enteredEmail];
        setValue("email", [...new Set(payload)]);
        resetField(key);
      }
    }
  };

  const removeEmail = (value: string) => {
    let payload = getValues("email");
    payload = payload.filter(email => email !== value);
    setValue("email", payload);
    trigger("email");
  };

  const handleDelete = (_id?: string) => setState(prevState => ({
    ...prevState,
    deleteWarning: !prevState.deleteWarning,
    _contactPerson: _id ? _id : ""
  }));

  const onDelete = async () => {
    try {
      const id = {
        _college: college?._id,
        _contactPerson: state._contactPerson
      };
      const deletedCandidate = await deleteContactPerson(id);
      snackbar(deletedCandidate.message, "info");
      handleDelete();
      contactDetailRefetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      handleDelete();
      console.log({ "Error in delete contact person": error });
    }
  };

  const handleEdit = (contactPerson?: IContactPersonDetail) => setState(prevState => {
    const newState = {
      ...prevState,
      manageContactPerson: {
        ...prevState.manageContactPerson,
        isOpen: !prevState.manageContactPerson.isOpen,
        contactPerson: contactPerson ? contactPerson : null,
      },
      _contactPerson: contactPerson ? contactPerson._id : "",
    } as IContactPersonState;

    return newState;
  });

  const handleEmailCopyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content);
      snackbar("Email ID copied to clipboard", "info");
  };

  const onSubmit = async (data: IContactPersonDetail) => {
    reset();
    try {
      const payload = {
        _id: college?._id,
        contactPerson: {
          name: data.name,
          email: [data.emailText],
          mobileNumber: {
            ...mobileNumber,
            number: data.number,
          },
          designation: data.designation,
          notes: data.notes,
        },
      };
      const contactDetail = await addContactPerson({
        ...payload,
        _id: college?._id,
      });
      reset();
      snackbar(contactDetail?.message, "info");
      contactDetailRefetch();
      contactListRefetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "warning");
      console.log("error in contact detail", error);
    }
  };

  const fields: IContactPerson[] = [
    {
      type: "input",
      name: "name",
      label: "Name*",
      placeholder: "Type name here"
    },
    {
      type: "mobile-number",
      name: "number",
      label: "Mobile Number*",
      placeholder: "Type mobile number here"
    },
    {
      type: "input",
      name: "emailText",
      label: "Email*",
      placeholder: "Type email address and press enter"
    },
    {
      type: "input",
      name: "designation",
      label: "Designation*",
      placeholder: "Type designation here"
    },
    {
      type: "input",
      name: "notes",
      label: "Note",
      placeholder: "Type note here"
    },
  ];

  const columns: IColumn[] = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "contactNumber",
      label: "Mobile Number"
    },
    {
      id: "email",
      label: "Email"
    },
    {
      id: "designation",
      label: "Designation"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

  const createRow = (index: number, contactDetail: IContactPersonDetail,) => {
    const action = <GetActions
      icons={[
        { name: "Edit", method: () => handleEdit(contactDetail) },
        { name: "Delete", method: () => handleDelete(contactDetail._id) },
      ]}
    />;

    const emails = 
      <Tooltip title={contactDetail && contactDetail.email && (contactDetail.email || []).map(em => em).join(", ")}>
      <span>
        {contactDetail && contactDetail.email && (contactDetail.email || []).length ? (
          <>
            {contactDetail.email[0]}{" "}
            {contactDetail.email.length > 1 && (
              <span style={{ color: "blue" }}>+{contactDetail.email.length - 1}</span>
            )}
          </>
        ) : (
          "No"
        )}
      </span>
    </Tooltip>;

    return {
      id: index + 1,
      name: (
        <CustomTypography
          limit={30}
          label={capitalize(contactDetail?.name)}
        />
      ),
      email: emails,
      contactNumber: formatMobileNumber(contactDetail?.mobileNumber) || "",
      designation: capitalize(contactDetail.designation),
      action
    };
  };

  const isActionActive = currentTab === 1 && activeAction;
  return (
    <Box paddingTop="10px">
      <Box height="56vh" overflow="auto" paddingTop="10px">

        {/* Add Data  */}
        {
          isActionActive &&
          <Box marginBottom="20px">
            <form onSubmit={handleSubmit(onSubmit)} onKeyDown={e => e.key === "Enter" && e.preventDefault()}>
              <Grid container spacing={4}>
                {
                  fields.map(field => {
                    if (field.type === "input") {
                      return (<Grid key={field.label} item xs={4}>
                        <Controller
                          control={control}
                          name={field.name}
                          render={(prop) => <TextField
                            label={field.label}
                            className="disable-text"
                            variant={"outlined"}
                            size={"small"}
                            placeholder={field.placeholder}
                            error={errors[field.name] ? true : false}
                            helperText={errors[field.name]?.message}
                            {...prop.field}
                            onKeyUp={e => addEmail(e, field.name)}
                          />}
                        />
                        {
                          <Box>
                            {
                              field.name === "emailText" && getValues("email") &&
                              getValues("email").map(email => <Chip
                                key={email}
                                label={email}
                                onClick={() => handleEmailCopyToClipboard(email)}
                                onDelete={() => removeEmail(email)}
                                color="primary"
                                variant="outlined"
                                sx={{ margin: "5px" }}
                              />)
                            }
                          </Box>
                        }
                      </Grid>
                      );
                    } else if (field.type === "mobile-number") {
                      return <Grid key={field.label} item xs={4}>
                        <Controller
                          control={control}
                          name={field.name}
                          render={(prop) => <MobileNumber
                            key={field.label}
                            NumberFieldLabel={field.label}
                            dialCodeValue={`${mobileNumber?.country}:${mobileNumber?.iso2}:${mobileNumber?.dialCode}`}
                            onChange={onNumberChange}
                            placeholder={field.placeholder}
                            error={errors[field.name] ? true : false}
                            helperText={errors[field.name]?.message}
                            other={prop.field}
                          />}
                        />
                      </Grid>;
                    }
                  })
                }
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    type="submit"
                  >
                    Add Contact Person
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        }

        {/* Show Data  */}
        <CustomTable
          columns={columns}
          rows={state.list}
          height={isActionActive ? "calc(100% - 145px)" : "calc(100% - 13px)"}
          width="calc(100% - 2px)"
        />

        {/* Delete Data  */}
        <WarningDialog
          isOpen={state.deleteWarning}
          onClose={() => handleDelete()}
          onConfirm={onDelete}
          title="Delete Contact Person"
          description="Are you sure you want to delete this contact person?"
        />

        {/* Manage Data  */}
        <ManageContactPerson
          contactPerson={state.manageContactPerson.contactPerson}
          college={college}
          isOpen={state.manageContactPerson.isOpen}
          onClose={handleEdit}
          contactDetailRefetch={contactDetailRefetch}
        />
      </Box>
    </Box>
  );
};

export default ContactPersonDetail;