import { Box, capitalize, FormControl, MenuItem, Select, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ChangeEvent } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import {
  createIndex,
  formatDateTime,
} from "../../../utilities/helper";
import { IErrorResponse, IPagination } from "../../../interfaces";
import Header from "../../../components/header";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import useDebounce from "../../../hooks/useDebounce";
import CustomTypography from "../../../components/mui/max-length-limit";
import useResource from "../../../hooks/useResource";
import { IAssessment, IAssessmentRow, IAssessmentState } from "../../../interfaces/configuration/assessments";
import { AssessmentService } from "../../../services/configuration/assessments";

interface outletProps {
  refetchTests: () => void;
}
const Assessment = () => {
  let rows: IAssessmentRow[] = [];
  const { snackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getAssessments, deleteAssessments } = AssessmentService();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IAssessmentState>({
    deleteWarning: false,
    _question: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filters: {
      search: "",
    },
  });
  const assessments = useQuery({
    queryKey: ["all-assessments", state.pagination.page, state.filters],
    queryFn: () =>
      getAssessments({
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page,
        search: state.filters.search,
      }),
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    searchRecord.length
      ? setSearchParams((prev) => ({
          ...prev,
          page: 1,
          search: searchRecord,
        }))
      : (searchParams.delete("search"), setSearchParams(searchParams));
  }, [searchRecord]);

  useEffect(() => {
    assessments.data &&
      assessments.data.data &&
      assessments.data.data.length &&
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: assessments.data.meta.page,
          totalPages: assessments.data.meta.totalPages,
          totalRecords: assessments.data.meta.totalRecords,
        },
      }));
  }, [assessments.data && assessments.data.data && assessments.data.meta]);

  useEffect(() => {
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
      },
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const handleDelete = (_test = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _test,
    }));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteAssessments({ ids: [state._question] });
      snackbar(deleted.message, "info");
      handleDelete();
      assessments.refetch();
      outlet.refetchTests && outlet.refetchTests();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
    }
  };

  const columns = [
    {
      id: "id",
      label: "S No.",
    },
    {
      id: "assessment_title",
      label: "Title",
    },
    {
      id: "assessment_description",
      label: "Description",
    },
    {
      id: "assessment_tests",
      label: "PSY Tests",
    },
    {
      id: "assessment_coding_challanges",
      label: "Coding Challenges",
    },
    {
      id: "assessment_duration",
      label: "Duration",
    },
    {
      id: "assessment_status",
      label: "Status",
    },
    {
      id: "assessment_createdBy",
      label: "Created By",
    },
    {
      id: "assessment_createdAt",
      label: "Created At",
    },
    {
      id: "action",
      label: "Actions",
    },
  ];
  const createRow = (index: number, assessment: IAssessment, pagination: IPagination) => {
    const action = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () =>
              navigate({
                pathname: "" + assessment._id,
                search: searchParams.toString(),
              }),
            disabled: resourceAllocate("assessment.write") && assessment.status !== "ARCHIVED" && assessment.status !== "PUBLISHED" ? false : true,
          },
        ]}
      />
    );


    const status =
      <FormControl
        sx={{ width: 150 }}
      >
        <Select
          size="small"
          name="status"
          value={assessment.status}
          disabled={true}
        >
          <MenuItem disabled value="none">Select</MenuItem>
          <MenuItem value="CREATED">Created</MenuItem>
          <MenuItem value="ARCHIVED">Archived</MenuItem>
          <MenuItem value="PUBLISHED">Published</MenuItem>
          <MenuItem value="DELETED">Deleted</MenuItem>
        </Select>
      </FormControl>;

    const psyTests =
      <Tooltip title={(assessment._tests || []).map(sub => sub.name).join(", ")}>
        <span>
          {assessment && assessment._tests && assessment._tests.length ? (
            <>
              {assessment._tests[0].name}{" "}
              {assessment._tests.length > 1 && (
                <span style={{ color: "blue" }}>+{assessment._tests.length - 1}</span>
              )}
            </>
          ) : (
            ""
          )}
        </span>
      </Tooltip>;

      const codingChallanges =  <Tooltip title={(assessment._codingChallenges || []).map(sub => sub.title).join(", ")}>
      <span>
        {assessment && assessment._codingChallenges && assessment._codingChallenges.length ? (
          <>
            {assessment._codingChallenges[0].title}{" "}
            {assessment._codingChallenges.length > 1 && (
              <span style={{ color: "blue" }}>+{assessment._codingChallenges.length - 1}</span>
            )}
          </>
        ) : (
          ""
        )}
      </span>
    </Tooltip>;


    return {
      id: createIndex(pagination, index),
      assessment_title: (
        <CustomTypography
          limit={50}
          label={assessment.title ?? ""}
        />
      ),
      assessment_description:
        <Tooltip title={assessment.description ?? ""}>
          <span>
            {assessment.description && assessment.description.length > 60
              ? `${assessment.description.slice(0, 60)}...`
              : assessment.description}
          </span>
        </Tooltip>,
      assessment_tests: psyTests,
      assessment_coding_challanges: codingChallanges,
      assessment_duration: assessment.duration,
      assessment_status: status,
      assessment_createdBy: capitalize(assessment.createdBy.name),
      assessment_createdAt: formatDateTime(assessment?.createdAt),
      action,
    };
  };

  if (assessments.data && assessments.data.data && assessments.data.data.length) {
    rows = ((assessments.data && assessments.data.data && assessments.data.data) || []).map(
      (test, i) => createRow(i, test, state.pagination)
    );
  }

  return (
    <>
      <Header
        className="my-2"
        searchPlaceholder="Search by title"
        onSearch={onSearch}
        btnText="ADD NEW ASSESSMENT"
        onBtnClick={
          resourceAllocate("assessment.write") ? () => navigate("new") : undefined
        }
      />
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Technical Question"
        description="Are you sure you want to delete this question?"
      />
      <Outlet context={{ ...outlet, reFetch: assessments.refetch }} />
    </>
  );
};
export default Assessment;