import Joi from "joi";
import { required } from "../../shared";

export const templateEmailValidation = Joi.object({
    title: Joi.string()
        .min(3)
        .max(40)
        .trim()
        .required()
        .label("Title")
        .messages({
            "string.empty": required
        }),
    type: Joi.string()
        .valid("email", "linkedinMessage")
        .required(),
    tag: Joi.string()
        .label("Tag")
        .messages({
            "string.empty": required
        }),

    email: Joi.object({

        subject: Joi.string()
            .label("Subject")
            .required()
            .min(3)
            .trim()
            .messages({
                "string.empty": required
            }),
        content: Joi.string()
            .label("Content")
            .required()
            .min(3)
            .trim()
            .messages({
                "string.empty": required
            }),
        attachments: Joi.array()
            .optional()
            .label("Attachments")
    })
})
    .options({ abortEarly: false });

export const templateLinkedinValidation = Joi.object({
    title: Joi.string()
        .required()
        .label("Title")
        .min(3)
        .trim()
        .max(40)
        .messages({
            "string.empty": required
        }),
    type: Joi.string().valid("email", "linkedinMessage").required(),
    tag: Joi.string()
        .label("Tag")
        .messages({
            "string.empty": required
        }),
    linkedinMessage: Joi.string()
        .label("Linkedin Message")
        .when("type", { is: "linkedin", then: Joi.string().required().trim() })
})
    .options({ abortEarly: false });