import Joi from "joi";

export const instructionValidation = Joi.object({
  name: Joi.string()
    .required()
    .min(3)
    .max(25)
    .trim()
    .messages({
      "string.empty": "Name is required",
      "string.max": "Maximum 25 characters are allowed"
    }),
  instructions: Joi.string()
    .min(3)
    .trim()
    .required()
    .messages({
      "string.empty": "Instructions are required",
    })
});