import HttpService from "../../http";
import { ICpdDashboardsResponse, ICpdDashboardResponse } from "../../../interfaces";
import { cpdDashboard } from "../../endpoints";

const cpdDashboardService = () => {
    const { httpRequest } = HttpService();
    const getCpdDashboards = async (search: object): Promise<ICpdDashboardsResponse> => new Promise((resolve, reject) => {
        httpRequest<ICpdDashboardsResponse>(
            "GET",
            `${cpdDashboard}/dashboard`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getCpdDashboard = async (query: object): Promise<ICpdDashboardResponse> => new Promise((resolve, reject) => {
        httpRequest<ICpdDashboardResponse>(
            "GET",
            `${cpdDashboard}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    return { getCpdDashboards, getCpdDashboard };

};

export { cpdDashboardService };