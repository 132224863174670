import { IProjectProductResponse, IProjectsProductsResponse } from "../../../interfaces/content/projects-products";
import { product, productList } from "../../endpoints";
import HttpService from "../../http";

const ProjectsService = () => {
 
    const {httpRequest} = HttpService();

    const getContentProjects = async (search:object):Promise<IProjectsProductsResponse> => 
    new Promise((resolve,reject) => {
        httpRequest<IProjectsProductsResponse>(
            "GET",
            `${productList}`,
            {},
            search
            )
            .then(resolve)
            .catch(reject);
    });

    const getContentProject = async (query:object) : Promise<IProjectProductResponse> =>  new Promise((resolve,reject) => {
        httpRequest<IProjectProductResponse>(
            "GET",
            `${product}`,
            {},
            query
        )
        .then(resolve)
        .catch(reject);
    });

    const getContentProjectPartial = async (query:object) : Promise<IProjectProductResponse> =>  new Promise((resolve,reject) => {
        httpRequest<IProjectProductResponse>(
            "GET",
            `${product}/partial`,
            {},
            query
        )
        .then(resolve)
        .catch(reject);
    });

    const addContentProjects = async (payload : object) :Promise<IProjectProductResponse>=> new Promise((resolve,reject) => {
        httpRequest<IProjectProductResponse>(
        "POST",
        `${product}`,
        payload )
        .then(resolve)
        .catch(reject);
    });

    const updateContentProjects = async (payload : object) :Promise<IProjectProductResponse>=> new Promise((resolve,reject) => {
        httpRequest<IProjectProductResponse>(
        "PUT",
        `${product}`,
        payload )
        .then(resolve)
        .catch(reject);
    });
    
    const deleteContentProject = async(payload:object):Promise<IProjectProductResponse> =>
    new Promise((resolve,reject) => {
      httpRequest<IProjectProductResponse>("DELETE",`${product}`,payload)
      .then(resolve)
      .catch(reject);
    });

    return {getContentProjects,getContentProjectPartial,addContentProjects,updateContentProjects,getContentProject,deleteContentProject};
};

export default ProjectsService;