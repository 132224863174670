import { useState, SyntheticEvent, useEffect } from "react";
import { useNavigate, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import useResource from "../../hooks/useResource";
import useUser from "../../hooks/useUser";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface IState {
  redirectionLinks: string[]
}

const CpdLayout = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const outlet = useOutletContext();
  const { user } = useUser();
  const { resourceAllocate } = useResource();
  const [state, setState] = useState<IState>({
    redirectionLinks: []
  });

  useEffect(() => {
    const redirectionLinks = [...state.redirectionLinks];
    if (resourceAllocate("cpd-analytics.read")) {
      redirectionLinks.push("dashboard");
    }
    if (resourceAllocate("college-recruitment.read")) {
      redirectionLinks.push("drives");
    }
    if (resourceAllocate("college.read")) {
      redirectionLinks.push("college");
    }
    setState(prev => ({
      ...prev,
      redirectionLinks
    }));
  }, [user]);

  useEffect(() => {
    const index = state.redirectionLinks.indexOf(location.pathname.split("/")[2]);
    setValue(index < 0 ? 0 : index);
  }, [location.pathname, state.redirectionLinks]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    navigate(state.redirectionLinks[newValue]);
  };

  return (
    <Box width="100%" >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {resourceAllocate("cpd-analytics.read") && <Tab className="tabs-space start-tab-space" label="Dashboard" {...a11yProps(0)} />}
          {resourceAllocate("college-recruitment.read") && <Tab className="tabs-space" label="Drives" {...a11yProps(1)} />}
          {resourceAllocate("college.read") && <Tab className="tabs-space" label="College" {...a11yProps(2)} />}
        </Tabs>
      </Box>
      <Outlet context={outlet} />
    </Box>
  );
};

export default CpdLayout;