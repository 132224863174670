import React from "react";
import { Container, List, ListItem, Card, CardContent, Typography } from "@mui/material";
import CustomDialog from "../../../mui/dialog";
import { useQuery } from "@tanstack/react-query";
import { TestSessionService } from "../../../../services";
import { ICopiedTextDisplayProps } from "../../../../interfaces";
import "./style.scss"; // Import the SCSS file

const CopiedTextDisplay: React.FC<ICopiedTextDisplayProps> = ({ session_id, onChangeModal, isOpen }) => {
  const { getCopiedText } = TestSessionService();
  const copiedTextsRes = useQuery({
    queryKey: ["copied-texts"],
    queryFn: () => getCopiedText({ _id: session_id })
  });

  const copiedTexts = copiedTextsRes.data?.data?.copiedTexts;

  return (
    <CustomDialog
      title="Copied Texts"
      isOpen={isOpen}
      onClose={onChangeModal}
      size="md"
      hideDiscard={true}
      confirmText="Close"
      hideSubmit={true}
    >
      <Container className="copied-text-display container">
        <List>
          {copiedTexts && copiedTexts.map((text: string, index: number) => (
            <ListItem key={index} className="list-item">
              <Typography variant="body1" className="index">
                {index + 1}.
              </Typography>
              <Card variant="outlined" className="card">
                <CardContent className="card-content">
                  <div dangerouslySetInnerHTML={{ __html: text }} />
                </CardContent>
              </Card>
            </ListItem>
          ))}
        </List>
      </Container>
    </CustomDialog>
  );
};

export default CopiedTextDisplay;
