import "../../../../components/shared/filter/style.scss";
import { useSelector } from "react-redux";
import { FC, useEffect, useState } from "react";
import { capitalize, displayName } from "../../../../utilities/helper";
import { ICollege, ICollegeFilter, IUser } from "../../../../interfaces";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MenuItem } from "../../../../components/shared/filter";
import { Autocomplete, Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton, ListItemText, Menu, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
interface props {
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  OnClose: () => void;
}

const defaultStatus = [
  {
    key: "ACTIVE",
    name: "Active"
  },
  {
    key: "INACTIVE",
    name: "Inactive"
  },
];

const defaultCategory = [
  {
    key: "tier-1",
    name: "Tier 1"
  },
  {
    key: "tier-2",
    name: "Tier 2"
  },
  {
    key: "tier-3",
    name: "Tier 3"
  },
];

const CollegeFilters: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(state => state.user.list);
  const colleges = useSelector<{ college: { list: ICollege[] } }, ICollege[]>(state => state.college.list);
  const [state, setState] = useState<ICollegeFilter>({
    selectedMenu: 0,
    assignTo: [],
    status: [],
    category: [],
    college: []
  });

  useEffect(() => {
    const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
    const assignTo: { key: string, value: string }[] = searchParams.get("assignTo") ? JSON.parse(String(searchParams.get("assignTo"))) : [];
    const category: { key: string; value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
    const college: { key: string; value: string }[] = searchParams.get("college") ? JSON.parse(String(searchParams.get("college"))) : [];
    setState(prevState => ({
      ...prevState,
      status,
      assignTo,
      college,
      category
    }));
  }, [searchParams]);


  const handleLeftListItem = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setState(prevState => ({
      ...prevState,
      selectedMenu: index
    }));
  };


  const handleRightListItem = (name: "assignTo" | "status" | "category" | "college", key: string, value: string) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];

    const isExist = state[name].find(ele => ele.key === key) ? true : false;
    if (isExist) {
      payload = state[name].filter(ele => ele.key !== key);
    } else {
      payload = state[name];
      payload.push({
        key,
        value
      });
    }

    setState(prevState => ({
      ...prevState,
      [name]: payload
    }));
  };

  const deleteChip = (name: "assignTo" | "category" | "status" | "college", key: string) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];

    payload = state[name].filter(ele => ele.key !== key);
    setState(prevState => ({
      ...prevState,
      [name]: payload
    }));
  };

  const resetFilter = () => {
    setState({
      selectedMenu: 0,
      assignTo: [],
      category: [],
      status: [],
      college: [],
    });
  };

  const onClose = () => {
    const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
    const assignTo: { key: string, value: string }[] = searchParams.get("assignTo") ? JSON.parse(String(searchParams.get("assignTo"))) : [];
    const category: { key: string; value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))):  [];
    const college: { key: string; value: string }[] = searchParams.get("college") ? JSON.parse(String(searchParams.get("college"))): [];
    setState(prevState => ({
      ...prevState,
      status,
      assignTo,
      college,
      category
    }));
    OnClose();
};
  const onApply = () => {
    searchParams.set("assignTo", JSON.stringify(state.assignTo));
    searchParams.set("status", JSON.stringify(state.status));
    searchParams.set("category", JSON.stringify(state.category));
    searchParams.set("college", JSON.stringify(state.college));
    searchParams.set("page", "1");
    navigate({
      pathname: "/cpd/college",
      search: searchParams.toString()
    });
    OnClose();
  };

  return <>
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={isOpen}
      onClose={OnClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <Box id="filters-container">
        <Box className="center mb-3" justifyContent="space-between" alignItems="start">
          <div className="active-filter mb-1">
            {
              (state.assignTo.length || state.college.length || state.status.length || state.category.length) ?
                <>
                  {state.assignTo.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("assignTo", ele.key)} label={ele.value} variant="outlined" />)}
                  {state.status.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("status", ele.key)} label={ele.value} variant="outlined" />)}
                  {state.category.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("category", ele.key)} label={ele.value} variant="outlined" />)}
                  {state.college.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("college", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                </>
                :
                <Box className="mt-2" display="flex" alignItems="center">
                  <FilterAltOffIcon />
                  <Typography className="ml-2">No filters are applied</Typography>
                </Box>
            }
          </div>
          <IconButton
            onClick={onClose}
            style={{ marginRight: "-10px" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Grid className="filter-box" container>
          <Grid id="left" item xs={5}>
            <List component="nav">
              <MenuItem
                index={0}
                label="Assign To"
                selectedMenu={state.selectedMenu === 0}
                onChange={handleLeftListItem}
                count={state.assignTo}
              />

              <MenuItem
                index={1}
                label="Status"
                selectedMenu={state.selectedMenu === 1}
                onChange={handleLeftListItem}
                count={state.status}
              />

              <MenuItem
                index={2}
                label="Category"
                selectedMenu={state.selectedMenu === 2}
                onChange={handleLeftListItem}
                count={state.category}
              />

              <MenuItem
                  index={3}
                  label="College"
                  selectedMenu={state.selectedMenu === 3}
                  onChange={handleLeftListItem}
                  count={state.college}
                />
            </List>
          </Grid>
          <Divider orientation="vertical" />

          <Grid id="right" item xs={6}>
            <List component="nav">
              {
                state.selectedMenu === 0 &&
                users.map((user) =>
                  <ListItemButton
                    key={user._id}
                    selected={state.assignTo.find(ele => ele.key === user._id) ? true : false}
                    onClick={() => handleRightListItem("assignTo", user._id, `${capitalize(displayName(user))}`)}
                  >
                    <ListItemText primary={`${capitalize(displayName(user))}`} />
                    <Checkbox edge="end" checked={state.assignTo.find(ele => ele.key === user._id) ? true : false} />
                  </ListItemButton>
                )
              }
              {
                state.selectedMenu === 1 &&
                defaultStatus.map(status => <ListItemButton
                  key={status.key}
                  selected={state.status.find(ele => ele.key === status.key) ? true : false}
                  onClick={() => handleRightListItem("status", status.key, status.name)}
                >
                  <ListItemText primary={status.name} />
                  <Checkbox edge="end" checked={state.status.find(ele => ele.key === status.key) ? true : false} />
                </ListItemButton>)
              }
              {
                state.selectedMenu === 2 &&
                defaultCategory.map(category => <ListItemButton
                  key={category.key}
                  selected={state.category.find(ele => ele.key === category.key) ? true : false}
                  onClick={() => handleRightListItem("category", category.key, category.name)}
                >
                  <ListItemText primary={category.name} />
                  <Checkbox edge="end" checked={state.category.find(ele => ele.key === category.key) ? true : false} />
                </ListItemButton>)
              }
              {state.selectedMenu === 3 &&
                  <Autocomplete
                  options={colleges.map((college) => ({
                    label: college.name,
                    value: college._id
                  }))} 
                  getOptionLabel={(option) => option.label} 
                  onChange={(event, value) =>
                    handleRightListItem("college", value ? value.value : "", value ? value.label : "") 
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="College" variant="outlined" />
                  )} 
                  />
              }
            </List>
          </Grid>
        </Grid>

        <Box className="actions-btn" marginTop="8px" textAlign="end">
          <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
          <Button className="ml-2" onClick={onApply}>Apply</Button>
        </Box>
      </Box>
    </Menu>
  </>;
};

export default CollegeFilters;