import HttpService from "../../http";
import { IHighlightResponse,IHighlightsResponse } from "../../../interfaces";
import { highlight } from "../../endpoints";

const HighLightService = () => {
    const { httpRequest } = HttpService();
    const addHighLight = async (payload: object): Promise<IHighlightResponse> => new Promise((resolve, reject) => {
        httpRequest<IHighlightResponse>(
            "POST",
            `${highlight}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });
    const getHighlights = async (search: object): Promise<IHighlightsResponse> => new Promise((resolve, reject) => {
        httpRequest<IHighlightsResponse>(
            "GET",
            `${highlight}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getHightLight = async (query: object): Promise<IHighlightResponse> => new Promise((resolve, reject) => {
        httpRequest<IHighlightResponse>(
            "GET",
            `${highlight}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateHightLight = async (id: string, payload: object): Promise<IHighlightResponse> => new Promise((resolve, reject) => {
        httpRequest<IHighlightResponse>(
            "PUT",
            `${highlight}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });
    const deleteHighLight = async (payload: object): Promise<IHighlightResponse> => new Promise((resolve, reject) => {
        httpRequest<IHighlightResponse>(
            "DELETE",
            `${highlight}`,
            {},
            payload
        )
            .then(resolve)
            .catch(reject);
    });
    return { addHighLight, getHighlights, getHightLight, updateHightLight, deleteHighLight };
};

export { HighLightService };