export const responseScreen3 = {
    success: true,
    message: "Test users fetched successfully",
    data: [
        {
            name: "John Doe",
            email: "john.doe@example.com",
            mobile: 1234567890,
            low: {
                min: 0,
                max: 50
            },
            moderate: {
                min: 0,
                max: 50
            },
            high: {
                min: 0,
                max: 50
            },
            isDeleted: false,
            _test: "test123",
            numberOfQuestions: 5,
            totalScore: 50,
            opened: "2024-05-20T08:00:00.000Z",
            submitted: "2024-05-20T08:30:00.000Z"
        },
        {
            name: "Jane Smith",
            email: "jane.smith@example.com",
            mobile: 9876543210,
            low: {
                min: 10,
                max: 60
            },
            moderate: {
                min: 20,
                max: 70
            },
            high: {
                min: 30,
                max: 80
            },
            isDeleted: false,
            _test: "test456",
            numberOfQuestions: 8,
            totalScore: 90,
            opened: "2024-05-21T09:00:00.000Z",
            submitted: "2024-05-21T09:45:00.000Z"
        }
    ],
    "meta": {
        "page": 1,
        "totalPages": 1,
        "totalRecords": 3
    }
};

export const responseSingleDetail5 = {
    success: true,
    message: "Test users fetched successfully",
    data:
    {
        name: "John Doe",
        email: "john.doe@example.com",
        mobile: 1234567890,
        isDeleted: false,
        _test: "test123",
        numberOfQuestions: 5,
        totalScore: 50,
        opened: "2024-05-20T08:00:00.000Z",
        submitted: "2024-05-20T08:30:00.000Z",
        home: "This is the Sample HOme Address",
    }
};

export const responseQuestionsScreen7 = {
    success: true,
    message: "Test users fetched successfully",
    data: [
        {
            _id: "sampleId",
            question: "What is the capital of France?",
            selectedOption: "Paris",
            totalMarks: 10,
            marksObtained: 8,
            submissionDate: "2022-05-12",
            category: "Category 1",
        },
        {
            _id: "sampleasdfasdfsdfsdaId",
            question: "Who wrote 'Romeo and Juliet'?",
            selectedOption: "William Shakespeare",
            totalMarks: 10,
            marksObtained: 9,
            submissionDate: "2022-05-13",
            category: "Category 2",
        },
        {
            _id: "sampasfasdfasdfsdfleId",
            question: "What is the chemical symbol for water?",
            selectedOption: "H2O",
            totalMarks: 10,
            marksObtained: 10,
            submissionDate: "2022-05-14",
            category: "Category 3",
        }
    ],

    "meta": {
        "page": 1,
        "totalPages": 1,
        "totalRecords": 3
    }
};

export const resultsScreen7 = {
    success: true,
    message: "Test users fetched successfully",
    data: {
        name: "",
        email: "",
        percentage: "",
        duration: "",
        totalMarks: 0,
        marksObtained: 0,
        category: [
            {
                name: "",
                marksObtained: 0,
                totalMarks: 30
            }, {
                name: "",
                marksObtained: 0,
                totalMarks: 30
            }, {
                name: "",
                marksObtained: 0,
                totalMarks: 30
            },
        ]
    }

};

// single Questions 

export const singleQuestions = [
    {
        question: "What is the largest planet in our solar system?",
        answer: "Jupiter",
        isCorrect: true,
    },
    {
        question: "Who wrote 'Romeo and Juliet'?",
        answer: "William Shakespeare",
        isCorrect: false,
    },
    {
        question: "What is the chemical symbol for gold?",
        answer: "Au",
        isCorrect: true,
    },
    {
        question: "What is the capital of Australia?",
        answer: "Canberra",
        isCorrect: true,
    },
    {
        question: "What is the boiling point of water in Celsius?",
        answer: "100",
        isCorrect: true,
    },
    {
        question: "What is the boiling point of water in Celsius?",
        answer: "100",
        isCorrect: true,
    },
];