import ImportFile from "../../../../components/mui/import-candidate";
import useSnackbar from "../../../../hooks/useSnackbar";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { ChangeEvent, useState } from "react";
import ImportFileError from "../../../../components/mui/import-candidate/erros";
import PartsOfFunnelService from "../../../../services/content/parts-of-funnel";
import { useOutletContext } from "react-router-dom";
import { IErrorResponse } from "../../../../interfaces";
import { IPartsOfFunnel } from "../../../../interfaces/content/parts-of-funnel";
import usevalidatePartsOfFunnel from "./parts-of-funnel-validations";
interface IOutletProps {
  reFetch: () => void;
}

const ImportPartsOfFunnel = () => {
  const {validatePartsOfFunnel} = usevalidatePartsOfFunnel();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<IOutletProps>();
  const navigate = useNavigate();
  const { importContentPartsOfFunnel } = PartsOfFunnelService();
  const [state, setState] = useState({
    fileName: "",
    data: [],
    showError: false,
    errorMessage: [],
  });

  const closeImport = () => {
    navigate("/content/parts-of-funnel");
  };

  const onUpload = (event: ChangeEvent<HTMLInputElement>) => {
    // create a file
    const formData = new FormData();
    // define file type
    const fileType = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];
    // get selected file
    const selectedFile = event.target.files ? event.target.files[0] : false;

    if (selectedFile && fileType.includes(selectedFile.type)) {
      // appending file to form data to send it to server
      formData.append("file", selectedFile);

      // creating a file Reader
      const reader = new FileReader();
      // read the data as araryBuffer
      reader.readAsArrayBuffer(selectedFile);
      reader.onload = async (e) => {
        if (e.target) {
          // extracting the array of buffer representings the contents of file
          const data = e.target.result;
          // use the XLSX library to read the data and interpret it as an Excel sheet
          const readedData = XLSX.read(data, { type: "binary" });
          // get the 1st sheet name
          const wsname = readedData.SheetNames[0];
          // get the contents of first sheet
          const ws = readedData.Sheets[wsname];
          // convert the sheet data to Array of strngs and number 
          let dataParse: (string | number)[][] = XLSX.utils.sheet_to_json(ws, {
            header: 1,
          });

          // remove the first row which is an header
          dataParse = dataParse?.slice(1);
          
          const partsOfFunnelData = await validatePartsOfFunnel(dataParse) as IPartsOfFunnel[];

          setState((prev) => ({
            ...prev,
            ...partsOfFunnelData,
            fileName: selectedFile.name,
          }));
        }
      };
    } else {
      snackbar("Upload excel or xls file", "warning");
    }
  };

  const onSubmit = async () => {
    try {
      if (state.data.length) {
        const skillItems = await importContentPartsOfFunnel(state.data);
        snackbar(skillItems.message, "info");
        outlet?.reFetch && outlet.reFetch();
        navigate(-1);
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }

    if (state.errorMessage.length) {
      setState((prev) => ({
        ...prev,
        showError: true,
      }));
    } else {
      closeErrorDialog();
    }
  };

  const closeErrorDialog = () => {
    setState((prev) => ({
      ...prev,
      fileName: "",
      data: [],
      showError: false,
      errorMessage: [],
    }));

    navigate(-1);
  };

  return (
    <>
      <ImportFile
        isOpen={true}
        onClose={closeImport}
        onUpload={onUpload}
        onsubmit={onSubmit}
        fileName={state.fileName}
        download={{
          url: "/cms_category.xlsx",
          name: "Parts Of Funnel | List | Sample",
        }}
        title="Before you start upload, please make sure:"
        content={[
          "Download the sample excel file.",
          "Read all the fields in the 1st row and do not change the text.",
          "Start uploading the data from 2nd row and onwards.",
          "In each row, there will be data for one part of funnel.",
          "All variants are mapped to their field by field title.",
          "Once sheet is prepared, upload it.",
          "Now sit back and relax!",
        ]}
      />

      <ImportFileError
        title="Excel file Errors"
        isOpen={state.showError}
        onClose={closeErrorDialog}
        errorMessage={state.errorMessage}
      />
    </>
  );
};

export default ImportPartsOfFunnel;
