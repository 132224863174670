import InlineDialog from "../../../../components/mui/inlineDialogue";
import { FC, useEffect, useState } from "react";
import { Autocomplete, Box, capitalize, Grid, TextField } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { IErrorResponse } from "../../../../interfaces";
import useSnackbar from "../../../../hooks/useSnackbar";
import { joiResolver } from "@hookform/resolvers/joi";
import { IAddAuthor, IStrapiAuthor } from "../../../../interfaces/content/author";
import AuthorService from "../../../../services/content/author";
import { authorValidation } from "../../../../validations/content/author";
import ProjectsService from "../../../../services/content/projects-products";
import CustomLabel from "../../../../components/mui/custom-label";
import { IProjectData } from "../../../../interfaces/content/projects-products";



interface IoutletProps {
  reFetch: () => void;
}

const AddCategory: FC = () => {
  const navigate = useNavigate();
  const outlet = useOutletContext<IoutletProps>();
  const { id } = useParams();
  const [searchParam] = useSearchParams();
  const { snackbar } = useSnackbar();
  const { getContentProjects } = ProjectsService();
  const hitQuery = id === "new" ? false : true;
  const { addContentAuthor, getContentAuthor, updateContentAuthor, getStrapiAuthors } = AuthorService();

  const author = useQuery({
    queryKey: ["author-details", id],
    queryFn: () => getContentAuthor({ _id: id }),
    enabled: hitQuery,
  });


  const [selectedProductId, setSelectedProductId] = useState<string | null>(null);

  const projectList = useQuery({
    queryKey: ["projectList"],
    queryFn: async () => {
      try {
        const data = await getContentProjects({isActive: true});
        return data;
      } catch (error) {
        const err = error as IErrorResponse;
        console.error("Error fetching project list:", error);
        snackbar(err.data.message, "error");
      }
    },
  });

  const authorList = useQuery({
    queryKey: ["authorList", selectedProductId],
    queryFn: async () => {
      try {
       const data = await getStrapiAuthors({ productId: selectedProductId });
       return data;
      }
      catch (error) {
        const err = error as IErrorResponse;
        console.error("Error fetching author list:", error);
        snackbar(err.data.message, "error");
      }
    },
    enabled: selectedProductId !== null ? true : false
  });

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<IAddAuthor>({
    resolver: joiResolver(authorValidation),
    defaultValues: {
      name: "",
      authorId: undefined,
      _productId: ""
    },
  });

  useEffect(() => {
    if (id !== "new") {
      if (author?.data?.data) {
        setValue("_productId", author?.data?.data?._productId || "");
        trigger("_productId");
        setSelectedProductId(author?.data?.data?._productId ?? "");
        setValue("authorId", author?.data?.data?.authorId ?? 0);
        trigger("authorId");
        setValue("name", author?.data?.data?.name || "");
        trigger("name");
      }
    }
  }, [author.data]);


  const onSubmit = async (data: IAddAuthor) => {
    try {
      if (id === "new") {
        const add = await addContentAuthor(data);
        snackbar(add.message, "info");
        navigate("/content/author");
        outlet?.reFetch && outlet?.reFetch();
      } else {
        const update = await updateContentAuthor({ ...data, _id: id });
        snackbar(update.message, "info");
        navigate({
          pathname: "/content/author",
          search: searchParam.toString(),
        });
        outlet?.reFetch && outlet?.reFetch();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "error");
      console.log(err);
    }
  };

  const handleProductChange = async (value: IProjectData | null) => {
    if (value) {
      setSelectedProductId(value?._id);
      setValue("_productId", value._id);
      trigger("_productId");
    }
  };


  const handleCategoryChange = async (value: IStrapiAuthor | null) => {
    if (value) {
      setValue("authorId", value?.id);
      trigger("authorId");
      setValue("name", value?.name);
      trigger("name");
    }
  };

  return (
    <Box>
      <InlineDialog
        onClose={() => navigate("/content/author")}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={"_productId"}
              render={(prop) => <Autocomplete
                className="disable-text"
                options={projectList?.data?.data?.map(
                  (data) => data
                ) || []}
                clearIcon={null}
                getOptionLabel={(option) => capitalize(option.name)}
                renderInput={(params) => <TextField
                  {...params}
                  error={errors["_productId"] ? true : false}
                  helperText={errors["_productId"]?.message}
                  size={"small"}
                  variant={"outlined"}
                  label={<CustomLabel label="Product*" />}
                  placeholder={"Please Select Product"}
                />}
                {...prop.field}
                value={projectList?.data?.data?.find(data => data._id === getValues("_productId")) || null}
                onChange={(e, value) => {
                  handleProductChange(value);
                }}
              />}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={"authorId"}
              render={(prop) => <Autocomplete
                className="disable-text"
                options={authorList?.data?.data?.map(
                  (data) => data
                ) || []}
                clearIcon={null}
                getOptionLabel={(option) => capitalize(option.name)}
                renderInput={(params) => <TextField
                  {...params}
                  error={errors["authorId"] ? true : false}
                  helperText={errors["authorId"]?.message}
                  size={"small"}
                  variant={"outlined"}
                  label={<CustomLabel label="Author*" />}
                  placeholder={"Please Select Author"}
                />}
                {...prop.field}
                value={authorList?.data?.data?.find(data => data.id === getValues("authorId")) || null}
                onChange={(e, value) => {
                  handleCategoryChange(value);
                }}
              />}
            />
          </Grid>
        </Grid>
      </InlineDialog>
    </Box>
  );
};

export default AddCategory;
