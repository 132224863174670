import Joi from "joi";
import { required, only_string } from "../../shared";

export const skillItemCategoryValidation = Joi.object({
    name: Joi.string()
        .required()
        .label("Name")
        .max(60)
        .min(3)
        .trim()
        .messages({
            "string.empty": required,
            "string.pattern.base": only_string
        }),
});
