import "../style.scss";
import { ChangeEvent } from "react";
import { Box, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { IPagination } from "../../../interfaces";
import { createIndex } from "../../../utilities/helper";
import { EmployeeReviewService } from "../../../services";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { IEmployeeReviewRow, IEmployeeReviewState, IEmployeeReview } from "../../../interfaces";
import ImageViewer from "react-simple-image-viewer";
import Header from "../../../components/header";
import useResource from "../../../hooks/useResource";
import useDebounce from "../../../hooks/useDebounce";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";


interface IImageViewer {
  open: boolean,
  index: number,
  images: string[],
}

const EmployeeReview = () => {
  let rows: IEmployeeReviewRow[] = [];
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const { resourceAllocate } = useResource();
  const { getEmployeeReviews, deleteEmployeeReview } = EmployeeReviewService();
  const [search, setSearch] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState<IEmployeeReviewState>({
    deleteWarning: false,
    _employeeReview: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      search: ""
    }
  });
  const [imageViewer, setImageViewer] = useState<IImageViewer>({
    open: false,
    index: 0,
    images: [],
  });

  const getEmployeeReview = useQuery({
    queryKey: ["allEmployeeReviews", state.pagination.page, state.filters],
    queryFn: () => getEmployeeReviews({
      pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
    })
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (getEmployeeReview.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: getEmployeeReview.data.meta.page,
          totalPages: getEmployeeReview.data.meta.totalPages,
          totalRecords: getEmployeeReview.data.meta.totalRecords
        }
      }));

      const images: string[] = [];
      getEmployeeReview.data?.data.map(review => {
        images.push(review.image);
      });

      setImageViewer(prev => ({
        ...prev,
        images
      }));
    }
  }, [getEmployeeReview.data?.meta]);

  useEffect(() => {
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search
      }
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleDelete = (_employeeReview = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _employeeReview
    }
    ));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteEmployeeReview({ _id: state._employeeReview });
      snackbar(deleted.message, "info");
      handleDelete();
      getEmployeeReview.refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const handleImageViewer = (index?: number) => {
    if (!imageViewer.open) {
      setImageViewer({
        ...imageViewer,
        open: true,
        index: index ? index : 0,
      });
    } else {
      setImageViewer({
        ...imageViewer,
        open: false,
        index: 0,
      });
    }
  };

  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "employeeReview_profilePic",
      label: "Profile Pic"
    },
    {
      id: "employeeReview_name",
      label: "Name"
    },
    {
      id: "employeeReview_position",
      label: "Position"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

  const createRow = (index: number, EmployeeReview: IEmployeeReview, pagination: IPagination) => {
    const action = <GetActions
      icons={[
        { name: "Edit", method: () => navigate({ pathname: "" + EmployeeReview._id, search: searchParams.toString() }), disabled: resourceAllocate("employee-review.write") ? false : true },
        { name: "Delete", method: () => handleDelete(EmployeeReview._id), disabled: resourceAllocate("employee-review.remove") ? false : true },
      ]}
    />;

    return {
      id: createIndex(pagination, index),
      employeeReview_profilePic: <IconButton onClick={() => handleImageViewer(index)}><img className="thumbnail" src={EmployeeReview?.image} alt="" /></IconButton>,
      employeeReview_name: (EmployeeReview?.name),
      employeeReview_position: (EmployeeReview?.position),
      action,
    };
  };
  if (getEmployeeReview.data?.data.length) {
    rows = getEmployeeReview.data?.data.map((EmployeeReview, i) => createRow(i, EmployeeReview, state.pagination));
  }

  return (
    <>
      {/* Add Data  */}
      <Header
        className='my-2'
        searchPlaceholder="Search by name"
        onSearch={onSearch}
        btnText="ADD REVIEW"
        onBtnClick={resourceAllocate("employee-review.write") ? () => navigate("new") : undefined}
      />

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Review"
        description="Are you sure you want to delete this review?"
      />

      {/* Preview Image */}
      {imageViewer.open && (
        <>
          <ImageViewer
            src={imageViewer.images}
            currentIndex={imageViewer.index}
            disableScroll={true}
            closeOnClickOutside={false}
            onClose={handleImageViewer}
            backgroundStyle={
              {
                color: "white",
                zIndex: 5
              }
            }
          />
        </>
      )}
      <Outlet context={{ reFetch: getEmployeeReview.refetch }} />
    </>
  );
};
export default EmployeeReview;