import { ICollege } from "../../../../interfaces";
import { LOAD_COLLEGE } from "../../../actions";

interface IAction {
    type: "LOAD_COLLEGE",
    payload: ICollege
}

interface IState {
    list: ICollege[]
}

const initialState: IState = {
    list: []
};

const collegeReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case LOAD_COLLEGE: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
};

export { collegeReducer };
