import Joi from "joi";
import { required } from "../../shared";

export const roleValidation = Joi.object({
    name: Joi.string()
        .required()
        .min(2)
        .max(40)
        .trim()
        .label("Name")
        .messages({
            "string.empty": required
        }),
    description: Joi.string()
        .required()
        .min(3)
        .trim()
        .label("Description")
        .messages({
            "string.empty": required
        }),
    _department: Joi.string()
        .required()
        .label("Department")
        .messages({
            "string.empty": required
        }),
    resources: Joi.array()
        .items(Joi.string())
        .min(1)
        .required()
        .label("Role Permissions")
        .messages({
            "string.empty": required,
            "array.min": "Please select at least one {{#label}}"
        }),
});
