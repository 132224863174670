import { useEffect, useState } from "react";
import { Box, Tooltip, Switch } from "@mui/material";
import { ChangeEvent, MouseEvent } from "react";
import { StatusService } from "../../../services";
import { useQuery } from "@tanstack/react-query";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import { capitalize } from "../../../utilities/helper";
import { IStatus, IStatusState, IStatusRow } from "../../../interfaces";
import DragIndicatorIcon from "@mui/icons-material/DragHandle";
import Header from "../../../components/header";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import useDebounce from "../../../hooks/useDebounce";
import { DropResult } from "react-beautiful-dnd";
import useResource from "../../../hooks/useResource";
import StatusFilter from "./filter";

interface outletProps {
  refetchStatuss: () => void;
}

const Status = () => {
  let rows: IStatusRow[] = [];
  const { snackbar } = useSnackbar();
  const { resourceAllocate } = useResource();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getStatuss, updateStatusPosition, deleteStatus, updateStatus } =
    StatusService();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IStatusState>({
    deleteWarning: false,
    _status: "",
    filters: {
      search: "",
      type: [],
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false,
    },
    filterCount: 0,
    rows: [],
  });
  const statuss = useQuery({
    queryKey: ["allStatuss", state.filters],
    queryFn: () =>
      getStatuss({
        ...state.filters,
      }),
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (statuss.data?.data.length) {
      setState((prevState) => ({
        ...prevState,
        rows: statuss.data?.data.map((status, i) =>
          createRow(i, status)
        ),
      }));
    }
  }, [statuss.data?.meta]);

  useEffect(() => {
    let filterCount = 0;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";
    const type: { key: string; value: string }[] = searchParams.get("type")
      ? JSON.parse(String(searchParams.get("type")))
      : [];
    filterCount += type.length ? 1 : 0;
    setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        search,
        type: type.map((type) => type.key),
      },
      filterCount,
    }));
  }, [searchParams]);

  const openFilter = (e: MouseEvent<HTMLButtonElement>) =>
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        anchorEl: e.currentTarget,
        isOpen: !state.filterDialog.isOpen,
      },
    }));

  const closeFilter = () => {
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false,
      },
    }));
  };

  const onDrag = async (e: DropResult) => {
    const payload = [...state.rows];
    const row = payload[e.source.index];
    if (e.destination?.droppableId) {
      payload.splice(e.source.index, 1);
      payload.splice(e.destination?.index, 0, row);
      setState((prevState) => ({
        ...prevState,
        rows: payload,
      }));

      const position: { _id: string; position: number }[] = [];
      payload.forEach((status, index) =>
        position.push({ _id: status._id, position: index })
      );
      try {
        const updated = await updateStatusPosition({ statuses: position });
        snackbar(updated.message, "info");
        statuss.refetch();
      } catch (error) {
        console.log(error);
      }
    }
  };


  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const handleDelete = (_status = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _status,
    }));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteStatus({ _id: state._status });
      snackbar(deleted.message, "info");
      handleDelete();
      statuss.refetch();
      outlet?.refetchStatuss && outlet.refetchStatuss();
    } catch (error) {
      console.log(error);
    }
  };

  const onStatusUpdate = async (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    id: string,
    name: string,
    type: string,
    position: number | string
  ) => {
    const status = checked ? "ACTIVE" : "INACTIVE";
    try {
      const statusUpdate = await updateStatus({
        _id: id,
        status,
        name,
        type,
        position,
      });
      snackbar(statusUpdate.message, "info");
      statuss.refetch();
      outlet?.refetchStatuss && outlet.refetchStatuss();
    } catch (error) {
      console.log("Error in  status update", error);
    }
  };

  const columns = [
    {
      id: "drag",
      label: <DragIndicatorIcon />,
    },
    {
      id: "id",
      label: "S No.",
    },
    {
      id: "status_status",
      label: "Status",
    },
    {
      id: "status_type",
      label: "Type",
    },
    {
      id: "status_category",
      label: "Category",
    },
    {
      id: "status_toggle",
      label: "Toggle",
    },
    {
      id: "action",
      label: "Actions",
    },
  ];

  const createRow = (
    index: number,
    status: IStatus,
  ) => {
    const action = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () =>
              navigate({
                pathname: "" + status._id,
                search: searchParams.toString(),
              }),
            disabled: resourceAllocate("status.write") ? false : true,
          },
          {
            name: "Delete",
            method: () => handleDelete(status._id),
            disabled: resourceAllocate("status.remove") ? false : true,
          },
        ]}
      />
    );

    const status_toggle = (
      <Switch
        disabled={resourceAllocate("status.write") ? false : true}
        onChange={(event, checked) =>
          onStatusUpdate(
            event,
            checked,
            status._id,
            status.name,
            status.type,
            status.position
          )
        }
        checked={status.status === "ACTIVE" ? true : false}
      />
    );

    return {
      drag: (
        <Tooltip title="Drag row">
          <DragIndicatorIcon />
        </Tooltip>
      ),
      _id: status._id,
      id: index + 1,
      status_status: capitalize(status.name),
      status_type: capitalize(status.type),
      status_category: capitalize(status.category),
      status_toggle,
      action,
    };
  };

  if (statuss.data?.data.length) {
    rows = statuss.data?.data.map((status, i) =>
      createRow(i, status)
    );
  }

  return (
    <>
      {/* Add Data  */}
      <Header
        className="my-2"
        searchPlaceholder="Search by status"
        onSearch={onSearch}
        onFilter={openFilter}
        filterCount={state.filterCount}
        btnText="ADD STATUS"
        onBtnClick={
          resourceAllocate("status.write") ? () => navigate("new") : undefined
        }
      >
        <StatusFilter
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnClose={closeFilter}
        />
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          isDND={true}
          draggableId="drag"
          onDrag={onDrag}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Status"
        description="Are you sure you want to delete this status?"
      />

      <Outlet context={{ ...outlet, reFetch: statuss.refetch }} />
    </>
  );
};
export default Status;
