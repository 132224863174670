import { FC, useEffect } from "react";
import CustomDialog from "../../mui/dialog";
import { TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
interface props {
    note: string;
    isOpen: boolean;
    onClose: () => void;
    onUpdate: (data: { note: string }) => void
}

const ManageNote: FC<props> = ({ note, isOpen, onClose, onUpdate }) => {
    const { handleSubmit, control, setValue, formState: { errors } } = useForm<{ note: string }>({
        resolver: joiResolver(Joi.object({ note: Joi.string().required().min(3).trim().label("Note") })),
        defaultValues: {
            note: "",
        }
    });

    useEffect(() => {
        if (note) {
            setValue("note", note);
        }
    }, [isOpen]);

    return (
        <CustomDialog
            title="Manage Note"
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleSubmit(onUpdate)}
            size="sm"
        >
            <Controller
                control={control}
                name="note"
                render={(prop) => <TextField
                    label="Note"
                    multiline
                    minRows={2}
                    error={errors.note ? true : false}
                    helperText={errors["note"]?.message}
                    {...prop.field}
                />
                }
            />

        </CustomDialog>
    );
};

ManageNote.defaultProps = {
    isOpen: false
};

export default ManageNote;