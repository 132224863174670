import "../../../../components/shared/filter/style.scss";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton, ListItemText, Menu, Typography, TextField, Select, MenuItem as DropdownMenuItem, Tabs, Tab } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { MenuItem } from "../../../../components/shared/filter";
import { capitalize, checkTimePeriod, getPreviousTimePeriod } from "../../../../utilities/helper";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import { IBlogsInsightsFilter } from "../../../../interfaces/blogs-insights";

interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    OnClose: () => void;
}

const BlogInsightFilters: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedTab, setSelectedTab] = useState<number>(0);
    const location = useLocation();
    const insightType = location.pathname.split("/")[2];

    const dateOptions = [
        { key: "yesterday", value: "Yesterday" },
        { key: "today", value: "Today" },
        { key: "thisWeek", value: "Weekly" },
        { key: "thisMonth", value: "Monthly" },
        { key: "thisQuarter", value: "Quarterly" },
    ];

    const compareDateOptions = [
        { key: "thisComparisonWeek", value: "Weekly" },
        { key: "thisComparisonMonth", value: "Monthly" },
        { key: "thisComparisonQuarter", value: "Quarterly" },
    ];

    const urlOptions = [
        { key: "select", value: "Select operator", disabled: true },
        { key: "equals", value: "Equals" },
        { key: "contains", value: "Contains" },
        { key: "notContains", value: "Not Contains" },
    ];

    const [state, setState] = useState<IBlogsInsightsFilter>({
        selectedMenu: 0,
        date: [],
        url: [],
        comparisonDate: []
    });

    useEffect(() => {
        const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
        const url: { key: string, value: string, expression: string, operator: string }[] = searchParams.get("url") ? JSON.parse(String(searchParams.get("url"))) : [];
        const comparisonDate: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("comparisonDate") ? JSON.parse(String(searchParams.get("comparisonDate"))) : [];

        setState(prevState => ({
            ...prevState,
            date,
            url,
            comparisonDate
        }));
    }, [searchParams]);

    const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
        if(newValue === 0){
            setState(prevState => ({
                ...prevState,
                comparisonDate: []
            }));
        }else{
            setState(prevState => ({
                ...prevState,
                date: []
            }));
        }
    };

    const handleLeftListItem = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setState(prevState => ({
            ...prevState,
            selectedMenu: index
        }));
    };

    const handleDateListItem = (name: "date" | "comparisonDate", key: string, value: string) => {
        const date = checkTimePeriod(key);
        const previousDate = getPreviousTimePeriod(key);
    
        const payload: Array<{
            key: string;
            value: string;
            startDate: string,
            endDate: string,
        }> = name === "date"
            ? [{
                key,
                value,
                startDate: date.startDate,
                endDate: date.endDate,
            }]
            : [
                {
                    key,
                    value,
                    startDate: date.startDate,
                    endDate: date.endDate,
                },
                {
                    key: `${key}_prev`,
                    value: `${value}_prev`,
                    startDate: previousDate.startDate,
                    endDate: previousDate.endDate,
                }
            ];
        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const handleDate = (e: dayjs.Dayjs | null, period: "start" | "end", name: "date" | "comparisonDate", index = 0) => {
        const newDoj = e ? dayjs(e).toISOString() : "";
        const date = [...state[name]];
    
        if (date.length && period === "start") {
            date[index] = { ...date[index], startDate: newDoj, value: "custom", key: "Custom" };
        } else if (date.length && period === "end") {
            date[index] = { ...date[index], endDate: newDoj, value: "custom", key: "Custom" };
        } else {
            const currentDate = new Date();
            currentDate.setHours(period === "start" ? 0 : 23, 59, 59);
            date[index] = {
                key: "custom",
                value: "custom",
                startDate: period === "start" ? newDoj : currentDate.toISOString(),
                endDate: period === "end" ? newDoj : currentDate.toISOString(),
            };
        }
    
        setState(prevState => ({
            ...prevState,
            [name]: date
        }));
    };

    const handleOperatorChange = (value: string) => {
        setState(prevState => ({
            ...prevState,
            url: [{
                ...prevState.url[0],
                operator: value,
            }]
        }));
    };

    const handleExpressionChange = (value: string) => {
        setState(prevState => ({
            ...prevState,
            url: [{
                ...prevState.url[0],
                expression: value,
            }]
        }));
    };


    const deleteChip = (name: "date" | "url"| "comparisonDate", key?: string) => {
        if (name === "date") {
            const payload = state.date.filter(ele => ele.key !== key);
            setState(prevState => ({
                ...prevState,
                date: payload
            }));
        } else if (name === "url") {
            setState(prevState => ({
                ...prevState,
                url: []
            }));
        } else if (name === "comparisonDate") {
            setState(prevState => ({
                ...prevState,
                comparisonDate: []
            }));
        }
    };


    const resetFilter = () => {
        setState({
            selectedMenu: 0,
            date: [],
            url: [],
            comparisonDate: []
        });
    };

    useEffect(() => {
        if (!searchParams.get("date") && !searchParams.get("url") ) {
            const today = new Date();
            const lastWeek = new Date();
            lastWeek.setDate(today.getDate() - 7);

            const defaultDateFilter = [{
                key: "thisWeek",
                value: "Weekly",
                startDate: lastWeek.toISOString(),
                endDate: today.toISOString(),
            }];

            const defaultUrlFilter = [{
                key: "contains",
                value: "Contains",
                operator: "contains",
                expression: "https://botpenguin.com",
            }];

            searchParams.set("date", JSON.stringify(defaultDateFilter));
            insightType == "pages" && searchParams.set("url", JSON.stringify(defaultUrlFilter));
            setSearchParams(searchParams);
        }
    }, []);

    const onApply = () => {
        searchParams.set("date", JSON.stringify(state.date));
        searchParams.set("url", JSON.stringify(state.url));
        searchParams.set("comparisonDate", JSON.stringify(state.comparisonDate));

        navigate({
            pathname: `/blogs-analytics/${insightType}`,
            search: searchParams.toString()
        });
        OnClose();
    };

    const onClose = () => {
        const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
        const url: { key: string, value: string, expression: string, operator: string }[] = searchParams.get("url") ? JSON.parse(String(searchParams.get("url"))) : [];
        const comparisonDate: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("comparisonDate") ? JSON.parse(String(searchParams.get("comparisonDate"))) : [];

        setState(prevState => ({
            ...prevState,
            date,
            url,
            comparisonDate
        }));
        setSelectedTab(0);
        OnClose();
    };
    return <>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={OnClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            <Box id="filters-container">
                <Box className="center mb-3" justifyContent="space-between" alignItems="start">
                    <div className="active-filter mb-1">
                        {
                            (state.date.length || (state.url.length && state.url[0].operator &&  state.url[0].expression) || state.comparisonDate.length) ?
                                <>
                                    {state.date.map(ele => <Chip key={ele.key} className="m-1" icon={<CalendarMonthIcon />} color="primary" onDelete={() => deleteChip("date", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.comparisonDate.length ? <Chip key={state.comparisonDate[0].key} className="m-1" icon={<CalendarMonthIcon />} color="primary" onDelete={() => deleteChip("comparisonDate")} label={"Comparison: " + state.comparisonDate[0].value + ""} variant="outlined" /> : null}
                                    {(state?.url[0]?.expression && state?.url[0]?.operator) ? <Chip 
                                                                                                className="m-1" 
                                                                                                color="primary" 
                                                                                                onDelete={() => deleteChip("url")} 
                                                                                                label={`${capitalize(state.url[0].operator)}: ${state.url[0].expression.length > 50 ? state.url[0].expression.slice(0, 50) + "..." : state.url[0].expression}`} 
                                                                                                variant="outlined" 
                                                                                              /> : null}
                                </>
                                :
                                <Box className="mt-2" display="flex" alignItems="center">
                                    <FilterAltOffIcon />
                                    <Typography className="ml-2">No filters are applied</Typography>
                                </Box>
                        }
                    </div>
                    <IconButton
                        onClick={onClose}
                        style={{ marginRight: "-10px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Grid className="filter-box" container>
                    <Grid id="left" item xs={5}>
                        <List component="nav">

                            <MenuItem
                                index={0}
                                label="Date"
                                selectedMenu={state.selectedMenu === 0}
                                onChange={handleLeftListItem}
                                count={state.date}
                            />
                            <MenuItem
                                index={1}
                                label={insightType === "pages" ? "Url" : "Keyword"}
                                selectedMenu={state.selectedMenu === 1}
                                onChange={handleLeftListItem}
                                count={state.url.length && state.url[0].operator &&  state.url[0].expression ? state.url : []}
                            />
                        </List>
                    </Grid>
                    <Divider orientation="vertical" />

                    <Grid id="right" item xs={6}>
                        <List component="nav">
                            {state.selectedMenu === 0 && (
                                <>
                                    <Tabs value={selectedTab} onChange={handleTabChange}>
                                        <Tab label="Filter" />
                                        <Tab label="Comparision" />
                                    </Tabs>

                                    {selectedTab === 0 && (
                                        <>
                                            {dateOptions.map((date) => (
                                                <ListItemButton
                                                    key={date.key}
                                                    selected={!!state.date.find((ele) => ele.key === date.key)}
                                                    onClick={() => handleDateListItem("date", date.key, capitalize(date.value))}
                                                >
                                                    <ListItemText primary={date.value} />
                                                    <Checkbox edge="end" checked={!!state.date.find((ele) => ele.key === date.key)} />
                                                </ListItemButton>
                                            ))}
                                            <Box marginTop={2}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MobileDatePicker
                                                        value={state.date[0]?.startDate ? dayjs(state.date[0]?.startDate) : null}
                                                        onChange={(e) => handleDate(e, "start", "date")}
                                                        label="Start Date"
                                                        format="LL"
                                                    />
                                                </LocalizationProvider>
                                                <div className="mt-3" />
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MobileDatePicker
                                                        value={state.date[0]?.endDate ? dayjs(state.date[0]?.endDate) : null}
                                                        onChange={(e) => handleDate(e, "end", "date")}
                                                        label="End Date"
                                                        format="LL"
                                                    />
                                                </LocalizationProvider>
                                            </Box>
                                        </>
                                    )}

                                    {selectedTab === 1 && (
                                        <div>
                                            {compareDateOptions.map((date) => (
                                                <ListItemButton
                                                    key={date.key}
                                                    selected={!!state.comparisonDate.find((ele) => ele.key === date.key)}
                                                    onClick={() => handleDateListItem("comparisonDate", date.key, capitalize(date.value))}
                                                >
                                                    <ListItemText primary={date.value} />
                                                    <Checkbox edge="end" checked={!!state.comparisonDate.find((ele) => ele.key === date.key)} />
                                                </ListItemButton>
                                            ))}
                                            <Box marginTop={2}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MobileDatePicker
                                                        value={state.comparisonDate[0]?.startDate ? dayjs(state.comparisonDate[0]?.startDate) : null}
                                                        onChange={(e) => handleDate(e, "start", "comparisonDate", 0)}
                                                        label="Start Date"
                                                        format="LL"
                                                    />
                                                </LocalizationProvider>
                                                <div className="mt-3" />
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MobileDatePicker
                                                        value={state.comparisonDate[0]?.endDate ? dayjs(state.comparisonDate[0]?.endDate) : null}
                                                        onChange={(e) => handleDate(e, "end", "comparisonDate", 0)}
                                                        label="End Date"
                                                        format="LL"
                                                    />
                                                </LocalizationProvider>
                                            </Box>
                                            <Box marginTop={2}>
                                                VS
                                            </Box>
                                            <Box marginTop={2} textAlign="center">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MobileDatePicker
                                                        value={state.comparisonDate[1]?.startDate ? dayjs(state.comparisonDate[1]?.startDate) : null}
                                                        onChange={(e) => handleDate(e, "start", "comparisonDate", 1)}
                                                        label="Start Date"
                                                        format="LL"
                                                    />
                                                </LocalizationProvider>
                                                <div className="mt-3" />
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MobileDatePicker
                                                        value={state.comparisonDate[1]?.endDate ? dayjs(state.comparisonDate[1]?.endDate) : null}
                                                        onChange={(e) => handleDate(e, "end", "comparisonDate", 1)}
                                                        label="End Date"
                                                        format="LL"
                                                    />
                                                </LocalizationProvider>
                                            </Box>
                                        </div>
                                    )}
                                </>
                            )}
                            {state.selectedMenu === 1 && (
                                <Box>
                                    <List component="div">
                                        <Select
                                            size="small"
                                            fullWidth
                                            defaultValue={urlOptions[0].key}
                                            value={state.url[0] ? state.url[0].operator : "select"}
                                            onChange={(e) => handleOperatorChange(e.target.value)}
                                        >
                                            {urlOptions.map((option) => (
                                                <DropdownMenuItem key={option.key} value={option.key} disabled={option?.disabled}>
                                                    {option.value}
                                                </DropdownMenuItem>
                                            ))}
                                        </Select>
                                        <TextField
                                            fullWidth
                                            className="mt-4"
                                            label={insightType === "pages" ? "Enter URL" : "Enter Keyword"}
                                            value={state.url[0] ? state.url[0].expression : ""}
                                            onChange={(e) => handleExpressionChange(e.target.value)}
                                            placeholder={insightType === "pages" ? "Enter URL" : "Enter Keyword"}
                                        />
                                    </List>
                                </Box>
                            )}
                        </List>

                    </Grid>
                </Grid>

                <Box className="actions-btn" marginTop="8px" textAlign="end">
                    <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
                    <Button className="ml-2" onClick={onApply}>Apply</Button>
                </Box>
            </Box>
        </Menu>
    </>;
};

export default BlogInsightFilters;