import Joi from "joi";
import { required } from "../../shared";

export const newJoineeValidation = Joi.object({
    to: Joi.string()
        .email({ tlds: { allow: false } })
        .label("To")
        .required()
        .messages({
            "string.empty": required,
        }),
    ccText: Joi.string()
        .label("CC")
        .allow(""),
    cc: Joi.array()
        .items(
            Joi.string()
                .email({ tlds: { allow: false } })
        )
        .allow("")
        .label("CC"),
    systemRequired: Joi.string()
        .label("System Required")
        .allow(""),
    doj: Joi.string()
        .label("DOJ")
        .required()
        .messages({
            "string.empty": required,
        }),
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .label("Email")
        .allow(""),
});