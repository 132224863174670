import { Box, Grid, Typography, capitalize, Tooltip, Badge, Button } from "@mui/material";
import { useEffect, useState, MouseEvent } from "react";
import ReportService from "../../../services/report";
import { useSearchParams } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useQuery } from "@tanstack/react-query";
import { DonutGraph, BarGraph } from "../../../components/graphs";
import CustomTable from "../../../components/mui/table";
import { IReportFilter, IReportFilterDialog, ISourcePerformanceRow } from "../../../interfaces";
import ReportFilters from "../components/filter";
import "./style.scss";
const sourceColumns = [
  { id: "sourceName", label: "Source Name", minWidth: 100, maxWidth: 100 },
  { id: "candidateSource", label: "Candidate Imported", minWidth: 100, maxWidth: 100 },
  { id: "applied", label: "Applied", minWidth: 100, maxWidth: 100 },
  { id: "interviewScheduled", label: "Interview Scheduled", minWidth: 100, maxWidth: 100 },
  { id: "hired", label: "Hired", minWidth: 100, maxWidth: 100 },
  { id: "rejected", label: "Rejected", minWidth: 100, maxWidth: 100 },
  { id: "rateOfHiring", label: "Rate Of Hiring", minWidth: 100, maxWidth: 100 },
];

/**
  * It map table column keys with table row data 
  * @params are the candidate source performance
  * All the map keys will pass in table rows
  **/
const createSourceData = (sourceName: string, candidateSource: number, applied: number, interviewScheduled: number, hired: number, rejected: number, rateOfHiring: number) => {
  if (rateOfHiring?.toString() === "Infinity") {
    rateOfHiring = 100;
  } else if (rateOfHiring?.toString() === "NaN") {
    rateOfHiring = 0;
  }

  return {
    sourceName,
    candidateSource,
    applied,
    interviewScheduled,
    hired,
    rejected,
    rateOfHiring: rateOfHiring + "%"
  };
};

const Candidates = () => {
  let sourcePerformanceRows: ISourcePerformanceRow[] = [];
  const { candidateReport } = ReportService();
  const [searchParams] = useSearchParams();
  const [filtersCount, setFiltersCount] = useState(0);
  const [filters, setFilters] = useState<IReportFilter>({
    department: [],
    job: [],
    typeOfLead: [],
    assignTo: []
  });
  const getCandidate = useQuery({
    queryKey: ["report-overview", filters],
    queryFn: () => candidateReport({ ...filters })
  });

  useEffect(() => {
    let filterCount = 0;
    const typeOfLead: { key: string, value: string }[] = searchParams.get("typeOfLead") ? JSON.parse(String(searchParams.get("typeOfLead"))) : [];
    const job: { key: string, value: string }[] = searchParams.get("job") ? JSON.parse(String(searchParams.get("job"))) : [];
    const department: { key: string, value: string }[] = searchParams.get("department") ? JSON.parse(String(searchParams.get("department"))) : [];
    const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
    const assignTo: { key: string, value: string }[] = searchParams.get("assignTo") ? JSON.parse(String(searchParams.get("assignTo"))) : [];
    let createdAt: {
      startDate: string;
      endDate: string;
    } | undefined = undefined;
    if (date?.length) {
      createdAt = {
        startDate: date[0]?.startDate,
        endDate: date[0]?.endDate,
      };
    }
    filterCount += typeOfLead.length ? 1 : 0;
    filterCount += job.length ? 1 : 0;
    filterCount += department.length ? 1 : 0;
    filterCount += assignTo.length ? 1 : 0;
    filterCount += date.length ? 1 : 0;
    setFiltersCount(filterCount);
    setFilters(prev => ({
      ...prev,
      job: job.map((item) => item.key),
      assignTo: assignTo.map((item) => item.key),
      department: department.map((item) => item.value),
      typeOfLead: typeOfLead.map((item) => item.key),
      date: createdAt
    }));
  }, [searchParams]);

  const [state, setState] = useState<IReportFilterDialog>({
    filterDialog: {
      anchorEl: null,
      isOpen: false
    }
  });
  
  const openFilter = (e: MouseEvent<HTMLButtonElement>) => {
    const anchorElement = e.currentTarget;

    setState((prevState) => ({
        ...prevState,
        filterDialog: {
            ...prevState.filterDialog,
            anchorEl: anchorElement,
            isOpen: !state.filterDialog.isOpen
        }
    }));
};

  const closeFilter = () => {
    setState((prevState) => ({
        ...prevState,
        filterDialog: {
            ...prevState.filterDialog,
            isOpen: false
        }
    }));
};


  const showData = (label: string) => {
    const multiColor = ["rgba(153, 102, 255, 0.3)", "rgba(54, 162, 235, 0.3)", "rgba(255, 99, 132, 0.3)", "rgba(255, 206, 86, 0.3)", "rgba(75, 192, 192, 0.2)", "rgba(254, 234, 230, 0.9)", "rgba(98, 54, 114, 0.3)", "rgba(54, 114, 61, 0.3)"];
    const data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: ["rgba(17, 140, 252, 0.3)"],
        },
      ],
    };

    const jobsLabel: string[] = [];
    const jobsDataSet: { label: string, data: number[], backgroundColor: string }[] = [];
    getCandidate.data?.data?.jobPerformance?.forEach((candidate) => {
      jobsLabel.push(candidate._id);
      let isExist = false;
      jobsDataSet.map((data, index) => {
        if (data.label === "Hired") {
          isExist = true;
          jobsDataSet[index]?.data.push(candidate.hired);
        } else if (data.label === "Rejected") {
          isExist = true;
          jobsDataSet[index]?.data.push(candidate.rejected);
        } else if (data.label === "Source") {
          isExist = true;
          jobsDataSet[index]?.data.push(candidate.source);
        } else if (data.label === "Applied") {
          isExist = true;
          jobsDataSet[index]?.data.push(candidate.applied);
        }
      });
      if (!isExist) {
        jobsDataSet.push({
          label: "Hired",
          data: [candidate.hired],
          backgroundColor: "rgba(153, 102, 255, 0.3)"
        });
        jobsDataSet.push({
          label: "Rejected",
          data: [candidate.rejected],
          backgroundColor: "rgba(54, 162, 235, 0.3)"
        });
        jobsDataSet.push({
          label: "Source",
          data: [candidate.source],
          backgroundColor: "rgba(255, 99, 132, 0.3)"
        });
        jobsDataSet.push({
          label: "Applied",
          data: [candidate.applied],
          backgroundColor: "rgba(255, 206, 86, 0.3)"
        });
      }
    });

    const pipelineLabel: string[] = [];
    const pipelineData: number[] = [];
    getCandidate.data?.data?.pipeline?.forEach(candidate => {
      pipelineLabel.push(candidate._id);
      pipelineData.push(candidate.count);
    });

    const sourceLabel: string[] = [];
    const sourceData: number[] = [];
    getCandidate.data?.data?.totalSource?.forEach(candidate => {
      sourceLabel.push(candidate._id);
      sourceData.push(candidate.count);
    });

    const hiredLabel: string[] = [];
    const hiredData: number[] = [];
    getCandidate.data?.data?.hiredSource?.forEach(candidate => {
      hiredLabel.push(candidate._id);
      hiredData.push(candidate.count);
    });

    if (label === "overview") {
      return {
        ...data,
        labels: jobsLabel,
        datasets: jobsDataSet
      };
    } else if (label === "pipeline") {
      return {
        ...data,
        labels: pipelineLabel,
        datasets: [
          {
            label: "Candidates",
            data: pipelineData,
            backgroundColor: ["rgba(17, 140, 252, 0.3)"],
          },
        ],
      };
    } else if (label === "source") {
      return {
        ...data,
        labels: sourceLabel,
        datasets: [
          {
            label: "Candidates",
            data: sourceData,
            backgroundColor: multiColor,
          },
        ],
      };
    } else if (label === "hired") {
      return {
        ...data,
        labels: hiredLabel,
        datasets: [
          {
            label: "Candidates",
            data: hiredData,
            backgroundColor: multiColor,
          },
        ],
      };
    } else {
      return data;
    }
  };

  if (getCandidate.data?.data?.sourcePerformance) {
    sourcePerformanceRows = getCandidate.data?.data?.sourcePerformance?.map(candidate => createSourceData(
      capitalize(candidate._id),
      candidate.source,
      candidate.applied,
      candidate.scheduled,
      candidate.hired,
      candidate.rejected,
      Math.round((candidate.hired / (candidate.source + candidate.applied)) * 100)
    )
    );
  }


  return (
    <>
      {/* Show Data  */}
      <Box marginTop="10px" height="calc(100vh - 145px)">
      <Box className="tab-header" marginBottom="30px">
          <Box className="tab-header-box">
            <Box display="flex" alignItems="center" className="filter-box">
              <Tooltip title='Filter'>
                <Badge
                  className="h-100 ml-2"
                  color="primary"
                  badgeContent={filtersCount}
                  invisible={!filtersCount}
                >
                  <Button
                    className={filtersCount ? "filter active" : "filter default"}
                    variant='outlined'
                    onClick={openFilter}
                  >
                    <FilterListIcon />
                  </Button>
                </Badge>
              </Tooltip>
            </Box>
            <ReportFilters
              anchorEl={state.filterDialog.anchorEl}
              isOpen={state.filterDialog.isOpen}
              OnClose={closeFilter}
            />
          </Box>
        </Box> 

        <Box marginTop="16px" height="calc(100% - 70px)" overflow="auto">
          {/* show graph  */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <BarGraph
                label="Candidate Overview"
                data={showData("overview")}
                isStacked
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BarGraph
                label="Candidate Pipeline"
                data={showData("pipeline")}
                isStacked
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DonutGraph
                label="Candidate's source of application"
                data={showData("source")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DonutGraph
                label="Hires per Source"
                data={showData("hired")}
              />
            </Grid>
            <Grid item xs={12}>
              <Box className="report-container">
                <Typography className="mb-1" variant="h6">
                  Recruiter Performance
                </Typography>
                <CustomTable
                  columns={sourceColumns}
                  rows={sourcePerformanceRows}
                  width={"calc(100% - 2px)"}
                  height="100%"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

      </Box>
    </>
  );
};
export default Candidates;