import Joi from "joi";
import { required } from "../../shared";

export const vendorServiceValidation = Joi.object({
    name: Joi.string()
        .required()
        .trim()
        .min(3)
        .max(30)
        .label("Name")
        .messages({
            "string.empty": required,
        }),
    _category: Joi.string()
        .required()
        .label("Category")
        .messages({
            "string.empty": required,
        }),
});