import Joi from "joi";
import { required } from "../../shared";

export const EmployeeReviewValidation = Joi.object({
    image: Joi.string()
        .required()
        .label("Image")
        .messages({
            "string.empty": required
        }),
    name: Joi.string()
        .min(3)
        .max(60)
        .trim()
        .required()
        .label("Name")
        .messages({
            "string.empty": required
        }),
    position: Joi.string()
        .min(3)
        .max(60)
        .trim()
        .required()
        .label("Position")
        .messages({
            "string.empty": required
        }),
    review: Joi.string()
        .trim()
        .min(3)
        .required()
        .label("Review")
        .messages({
            "string.empty": required
        }),
});