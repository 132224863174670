import "./style.scss";
import { Box, Button, Checkbox, Grid, IconButton, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import Header from "../../../../../components/header";
import CustomTable from "../../../../../components/mui/table";
import { IBlogImageForm, IBlogImageField, IErrorResponse, IPagination, IColumn } from "../../../../../interfaces";
import { Controller, useForm } from "react-hook-form";
import ImageViewer from "react-simple-image-viewer";
import { ChangeEvent, FC, useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import UploadIcon from "@mui/icons-material/Upload";
import BlogComment from "../../../../../components/blog-comments";
import { joiResolver } from "@hookform/resolvers/joi";
import { blogImagesValidation } from "../../../../../validations";
import HttpService from "../../../../../services/http";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { blogImageService } from "../../../../../services/blog";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import Select from "../../../../../components/mui/select";
import GetActions from "../../../../../components/get-actions";
import { handleSelect, handleSelectAll } from "../../../../content-layout/common/helper";
import { createIndex } from "../../../../../utilities/helper";
import { useQuery } from "@tanstack/react-query";
import useResource from "../../../../../hooks/useResource";
import { IBlogImage, IBlogImageRow, IBlogImageState } from "../../../../../interfaces/content-marketing/blog/image";
import WarningDialog from "../../../../../components/mui/warning-dialog";


const initiateFields: IBlogImageField[] = [{
  label1: "Image Type*",
  name1: "imageType",
  type1: "input",
  placeholder1: "Type your image type",
  width1: 4,
  disabled1: false,
  label2: "Upload Image*",
  name2: "imageUrl",
  type2: "input",
  placeholder2: "Upload your image",
  width2: 8,
  disabled2: false,
}];

interface IImageViewer {
  open: boolean,
  index: number,
  images: string[],
}

interface props {
  activeAction: boolean;
  setActiveAction: (value: boolean) => void;
}

const BlogImage: FC<props> = ({ activeAction, setActiveAction }) => {
  const [state, setState] = useState<IBlogImageState>({
    fields: initiateFields,
    shownManageBox: false,
    uploadedFileNames: [],
    type: "",
    selectAll: [],
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
      totalRecords: 0,
    },
    updateId: "",
    deleteWarning: false,
    deleteId: "",
    multiDeleteWarning: false
  });
  const [imageViewer, setImageViewer] = useState<IImageViewer>({
    open: false,
    index: 0,
    images: [],
  });
  const { httpFormRequest } = HttpService();
  const { id } = useParams();
  const { snackbar } = useSnackbar();
  const { addBlogImage, getBlogImageList, getBlogImage, updateBlogImage, deleteBlogImage } = blogImageService();
  const { resourceAllocate } = useResource();
  const navigate = useNavigate();
  const { handleSubmit, getValues, setValue, unregister, control, formState: { errors } } = useForm<IBlogImageForm>({
    resolver: joiResolver(blogImagesValidation),
    defaultValues: {
      form: [{
        imageType: "",
        imageUrl: ""
      }]
    },
  });
  const hitQuery = state.updateId !== "" ? true : false;

  const blogImageList = useQuery({
    queryKey: ["blog-images", id],
    queryFn: () =>
      getBlogImageList({
        blogId: id,
        pagination: true,
        page: state.pagination.page,
        limit: state.pagination.limit
      }),
    enabled: id !== "new" ? true : false
  });

  useEffect(() => {
    if (blogImageList?.data?.data) {
      setImageViewer(prev => ({
        ...prev,
        images: blogImageList.data.data.map(item => item.imageUrl),
      }));
    }
  }, [blogImageList?.data]);

  const imageData = useQuery({
    queryKey: ["blog-image"],
    queryFn: () =>
      getBlogImage({ _id: state.updateId }),
    enabled: hitQuery
  });

  useEffect(() => {
    if (state.updateId !== "" && imageData?.data) {
      setValue("form.0.imageType", imageData?.data?.data?.imageType);
      setValue("form.0.imageUrl", imageData?.data?.data?.imageUrl);
      setState(prev => ({ ...prev, uploadedFileNames: [imageData?.data?.data?.name] }));
    }
  }, [state.updateId, imageData?.data]);

  const handleImageViewer = (index?: number) => {
    if (!imageViewer.open) {
      setImageViewer({
        ...imageViewer,
        open: true,
        index: index ? index : 0,
      });
    } else {
      setImageViewer({
        ...imageViewer,
        open: false,
        index: 0,
      });
    }
  };

  const onSubmit = async (data: IBlogImageForm) => {
    try {
      const payload = data.form.map((res, index) => ({ _blog: id, name: state.uploadedFileNames[index], ...res }));
      if (id === "new") {
        snackbar("Please add basic details First.", "error");
      }
      else if (state.type === "edit" && state.updateId !== "") {
        setState(prev => ({ ...prev, shownManageBox: false, uploadedFileNames: [] }));

        const response = await updateBlogImage({ _id: state.updateId, ...payload[0] });
        snackbar(response.message, "info");
        navigate(`/blog/manage/${id}?type=blog-image`);
        blogImageList.refetch();
      }
      else {
        const response = await addBlogImage(payload);
        snackbar(response.message, "info");
        setState(prev => ({ ...prev, shownManageBox: false, uploadedFileNames: [] }));

        navigate(`/blog/manage/${id}?type=blog-image`);
        blogImageList.refetch();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
      setState(prev => ({ ...prev, shownManageBox: false, uploadedFileNames: [] }));

      console.log("Error in Adding Blog-Image", error);
    }
  };

  const handleDelete = (deleteId = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      deleteId,
    }));
  };

  const isChecked = (blogImageList.data?.data?.length && state.selectAll?.length === blogImageList.data?.data?.length) ? true : false;
  const isIndeterminateChecked = (state.selectAll.length > 0 && state.selectAll.length < Number(blogImageList.data?.data.length)) ? true : false;

  const columns: IColumn[] = [
    {
      id: "all",
      label: <Checkbox onChange={e => handleSelectAll(e, blogImageList?.data?.data ? blogImageList?.data?.data : [], setState)} checked={isChecked} indeterminate={isIndeterminateChecked} />
    },
    {
      id: "blog_image",
      label: "Image",
      minWidth: 120
    },
    {
      id: "image_type",
      label: "Image Type",
      minWidth: 160
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

  const toggleManageBox = (isOpen: boolean, type: string, id?: string) => {
    setState(prev => {
      if (isOpen) {
        return { ...prev, shownManageBox: true, fields: initiateFields, type, updateId: id };
      }
      const index = state.fields.length;
      for (let i = 0; i < index; i++) {
        setValue(`form.${i}.imageType`, "");
        setValue(`form.${i}.imageUrl`, "");
      }

      return { ...prev, shownManageBox: false, type, updateId: id, uploadedFileNames: [] };
    });
  };

  const addField = () => {
    const index = state.fields.length;
    setState(prev => ({ ...prev, fields: [...prev.fields, ...initiateFields] }));
    setValue(`form.${index}.imageType`, "");
    setValue(`form.${index}.imageUrl`, "");
  };

  const removeField = () => {
    unregister(`form.${state.fields.length - 1}`);
    const formPayload = getValues().form.filter(val => (val !== undefined));
    setValue("form", formPayload);
    setState(prev => {
      const fields = prev.fields;
      fields.pop();
      return { ...prev, fields };
    });
  };
  const uploadFile = async (e: ChangeEvent<HTMLInputElement>, type: string, index: number) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const uploaded = await httpFormRequest<{ data: string }>(
          e.target.files,
          e.target.files[0].name,
          ["webp"],
          1
        );
        const keyExist = type === "imageUrl";
        if (keyExist) {
          setValue(`form.${index}.imageUrl`, uploaded?.data);
          setState((prev) => {
            const uploadedFileNames = [...prev.uploadedFileNames];
            uploadedFileNames[index] = (e?.target?.files && e?.target?.files[0]?.name) ? e?.target?.files[0]?.name : "";
            return { ...prev, uploadedFileNames };
          });
        }
      }
    } catch (error) {
      console.log("error in uploading file", error);
    }
  };

  const onDownloadClick = (url: string) => {
    const a = document.createElement("a");
    const parts = url.split("/");
    const filename = parts[parts.length - 1];
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const onDelete = async () => {
    try {
      const response = await deleteBlogImage({ ids: [state.deleteId] });
      snackbar(response.message, "info");
      handleDelete();
      blogImageList.refetch();

    } catch (error) {
      const err = error as IErrorResponse;
      console.log("Error in delete Image", err);

    }
  };

  const handleMultiDelete = () => setState(prevState => ({
    ...prevState,
    multiDeleteWarning: !prevState.multiDeleteWarning
  }));

  const onMultiDelete = async () => {
    try {
      const goal = await deleteBlogImage({ ids: state.selectAll });
      snackbar(goal.message, "info");
      blogImageList.refetch();
      setState(prevState => ({
        ...prevState,
        multiDeleteWarning: false,
        selectAll: []
      }));
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      handleMultiDelete();
      console.log({ "Error in delete Image": error });
    }
  };

  const createRows = (
    index: number,
    selectedAll: string[],
    data: IBlogImage,
    pagination: IPagination
  ) => {
    const action = (
      <GetActions
        icons={[
          {
            name: "Export", method: () => onDownloadClick(data?.imageUrl)
          },
          {
            name: "Edit",
            method: () => {
              toggleManageBox(true, "edit", data?._id);
            },
            disabled: resourceAllocate("cms-blog-image.write") ? false : true,
          },
          { name: "Delete", method: () => handleDelete(data._id), disabled: resourceAllocate("cms-blog-image.remove") ? false : true },
        ]}
      />
    );


    return {
      all: <Checkbox onChange={e => handleSelect(e, data._id, state, setState)} checked={selectedAll.includes(data._id)} />,
      id: createIndex(pagination, index),
      blog_image: <IconButton onClick={() => handleImageViewer(index)}><img className="thumbnail" src={data?.imageUrl} alt="" /></IconButton>,
      image_type: data?.imageType,
      action,
    };
  };

  let rows: IBlogImageRow[] = [];
  if (blogImageList?.data?.data?.length) {
    rows = blogImageList?.data?.data?.map(
      (data: IBlogImage, i: number) =>
        createRows(i, state.selectAll, data, state.pagination)
    );
  }
  const onDeleteProp = resourceAllocate("cms-blog-image.remove") ? { onDelete: handleMultiDelete } : {};
  return (
    <div className="blog-image">
      <Grid container spacing={4}>

        {/* blog image deatils  */}
        <Grid item {...(activeAction ? { xs: 8 } : { xs: 12 })}>
          {
            state.shownManageBox &&
            <div className="form-border mb-2">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={4}>
                  {
                    state.fields.map((field, index) => {
                      if (field.type1 === "input") {
                        return (
                          <>
                            <Grid key={index} item xs={12} md={field.width1}>
                              <Select
                                control={control}
                                className="disable-text"
                                name={`form.${index}.${field.name1}`}
                                label="Image Type*"
                                size={"small"}
                                variant={"outlined"}
                                error={(errors["form"] && errors["form"][index] && errors["form"][index]?.[field.name1]) ? true : false}
                                helperText={(errors["form"] && errors["form"][index]) && errors["form"][index]?.[field.name1]?.message}
                              >
                                {[{ key: 1, value: "FEATURED" }, { key: 2, value: "OTHERS" }].map((data) => (
                                  <MenuItem key={data.key} value={data.value}>
                                    {data.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Grid>

                            <Grid key={field.label2} item xs={12} md={field.width2}>
                              <Box display="flex" alignItems="start" >
                                <div className="w-100" onClick={() => {
                                  const fileInput = document.getElementById(`fileInput-${index}-${field.name2}`);
                                  if (fileInput) {
                                    fileInput.click();
                                  }
                                }}>
                                  <Controller
                                    control={control}
                                    name={`form.${index}.${field.name2}`}
                                    render={(prop) => <TextField
                                      label={field.label2}
                                      className="disable-text"
                                      variant="outlined"
                                      size="small"
                                      placeholder={field.placeholder2}
                                      style={{ cursor: "pointer" }}
                                  {...prop.field}
                                  value={state.uploadedFileNames[index] || ""}
                                  error={(errors["form"] && errors["form"][index] && errors["form"][index]?.[field.name2]) ? true : false}
                                  helperText={(errors["form"] && errors["form"][index]) && errors["form"][index]?.[field.name2]?.message}
                                  InputProps={
                                    {
                                      endAdornment:
                                        <>
                                          <Tooltip title="Upload">
                                            <IconButton
                                              color="primary"
                                              component="label"
                                            >
                                              <UploadIcon color="action" />

                                            </IconButton>
                                          </Tooltip>
                                        </>
                                    }
                                  }
                                    />}
                                  />
                                  <input
                                    id={`fileInput-${index}-${field.name2}`}
                                    type="file"
                                    accept="image/*"
                                    style={{ display: "none", cursor: "pointer" }}
                                    onChange={e => { uploadFile(e, field.name2, index); }}
                                  />
                                </div>
                                {
                                  state.type === "new" && state.fields.length - 1 === index &&
                                  <div className="add-more">
                                    {state.fields.length > 1 &&
                                      <Button className="mr-3" variant="outlined" color="error" onClick={removeField}><RemoveCircleIcon color="error" /></Button>
                                    }
                                    <Button variant="outlined" color="primary" onClick={addField} ><AddCircleIcon color="primary" /></Button>
                                  </div>
                                }

                              </Box>
                            </Grid>
                          </>

                        );
                      }
                    })
                  }
                </Grid>
                <div className="form-action-btn">
                  <Button variant="outlined" onClick={() => toggleManageBox(false, "close", "")}>Discard</Button>
                  <Button type="submit" className="ml-2">{state.updateId !== "" ? "Update Blog Image" : "Add Blog Image"}</Button>
                </div>
              </form>
            </div>
          }

          <Header
            btnText="Add Image"
            onBtnClick={resourceAllocate("cms-blog-image.write") ? () => toggleManageBox(true, "new", "") : undefined}
            isDeleteDisable={state.selectAll.length ? false : true}
            {...onDeleteProp}
          />
          <Box display="flex" className="mb-2" justifyContent="flex-end" alignItems="center">
            <Box display="flex" alignItems="center">
              <Typography variant="body1">Total Images:</Typography>
              <Typography className="ml-3" variant="body1" >{blogImageList?.data?.meta?.totalRecords}</Typography>
            </Box>
          </Box>
          <WarningDialog
            isOpen={state.deleteWarning}
            onClose={() => handleDelete()}
            onConfirm={onDelete}
            title="Delete blog-image"
            description="Are you sure you want to delete this blog-image?"
          />
          {/* Multiple Delete  */}
          <WarningDialog
            isOpen={state.multiDeleteWarning}
            onClose={() => handleMultiDelete()}
            onConfirm={onMultiDelete}
            title="Delete All blog-images"
            description={`Are you sure you want to delete ${state.selectAll.length} selected ${state.selectAll.length > 1 ? "blog-images" : "blog-image"}?`}
          />
          {/* Show Data  */}
          <Box marginTop="10px">
            <CustomTable
              columns={columns}
              rows={rows}
              height="calc(100vh - 338px)"
            />
          </Box>
        </Grid>
        {imageViewer.open && (
          <>
            <ImageViewer
              src={imageViewer.images}
              currentIndex={imageViewer.index}
              disableScroll={true}
              closeOnClickOutside={false}
              onClose={handleImageViewer}
            />
          </>
        )}
        {/* comments  */}
        {activeAction && <Grid item xs={4}>
          <BlogComment
            commentType="BLOG_IMAGE"
            activeAction={activeAction}
            setActiveAction={setActiveAction}
          />
        </Grid>
        }
      </Grid>
      <Outlet />
    </div>
  );
};

export default BlogImage;