import { FC, useEffect, useState } from "react";
import { Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton, ListItemText, Menu, Typography } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { IQuestionFilterState } from "../../../../../../../interfaces/configuration/tests";
import { MenuItem } from "../../../../../../../components/shared/filter";
import { useQuery } from "@tanstack/react-query";
import { TestsService } from "../../../../../../../services/configuration/tests";
import "../../../../../../../components/shared/filter/style.scss";


interface props {
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  OnClose: () => void;
}

const Filters: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [state, setState] = useState<IQuestionFilterState>({ 
    selectedMenu: 0,
    category: [],
   });

  const { getTestCategoryList } = TestsService();
  const { testId } = useParams();
  useEffect(() => {
    const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];

    setState(prevState => ({
      ...prevState,
      category,
    }));
  }, [searchParams]);


  const handleLeftListItem = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setState(prevState => ({
      ...prevState,
      selectedMenu: index
    }));
  };

  const categories = useQuery({
    queryKey: ["test-category"],
    
    queryFn: () => getTestCategoryList({
      _test: testId,
    })
  });
  const handleRightListItem = (name: "category", key: string, value: string) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];

    const isExist = state[name].find(ele => ele.key === key) ? true : false;
    if (isExist) {
      payload = state[name].filter(ele => ele.key !== key);
    } else {
      payload = state[name];
      payload.push({
        key,
        value
      });
    }

    setState(prevState => ({
      ...prevState,
      [name]: payload
    }));
  };

  const deleteChip = (name: "category", key: string) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];

    payload = state[name].filter(ele => ele.key !== key);
    setState(prevState => ({
      ...prevState,
      [name]: payload
    }));
  };

  const resetFilter = () => {
    setState({
      selectedMenu: 0,
      category: [],
    });
  };

  const onClose = () => {
    const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
    setState(prevState => ({
      ...prevState,
      category
    }));
    OnClose();
};

  const onApply = () => {
    searchParams.set("category", JSON.stringify(state.category));

    searchParams.set("page", "1");
    navigate({
      pathname: "",
      search: searchParams.toString()
    });
    OnClose();
  };

  return <>
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={isOpen}
      onClose={OnClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <Box id="filters-container">
        <Box className="center mb-3" justifyContent="space-between" alignItems="start">
          <div className="active-filter mb-1">
          {
              (state.category.length) ?
                <>
                  {state.category.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("category", ele.key)} label={ele.value} variant="outlined" />)}
                </>
                :
                <Box className="mt-2" display="flex" alignItems="center">
                  <FilterAltOffIcon />
                  <Typography className="ml-2">No filters are applied</Typography>
                </Box>
            }
          </div>
          <IconButton
            onClick={onClose}
            style={{ marginRight: "-10px" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Grid className="filter-box" container>
          <Grid id="left" item xs={5}>
            <List component="nav">

            <MenuItem
                index={0}
                label="Category"
                selectedMenu={state.selectedMenu === 0}
                onChange={handleLeftListItem}
                count={state.category}
              />

            </List>
          </Grid>
          <Divider orientation="vertical" />

          <Grid id="right" item xs={6}>
            <List component="nav">
            {
                state.selectedMenu === 0 &&
                categories?.data?.data.map(category => <ListItemButton
                  key={category._id}
                  selected={state.category.find(ele => ele.key === category._id) ? true : false}
                  onClick={() => handleRightListItem("category", category._id, category.name)}
                >
                  <ListItemText primary={category.name} />
                  <Checkbox edge="end" checked={state.category.find(ele => ele.key === category._id) ? true : false} />
                </ListItemButton>)
              }
            </List>

          </Grid>
        </Grid>

        <Box className="actions-btn" marginTop="8px" textAlign="end">
          <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
          <Button className="ml-2" onClick={onApply}>Apply</Button>
        </Box>
      </Box>
    </Menu>
  </>;
};

export default Filters;