import { Alert, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { FC } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

interface Props {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    // eslint-disable-next-line
    errorMessage: any[];
}

const ImportFileError: FC<Props> = ({ isOpen, onClose, title, errorMessage }) => (
    <BootstrapDialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-dialog"
    >
        <BootstrapDialogTitle id="alert-dialog-title" onClose={onClose}>
            {title}
        </BootstrapDialogTitle>
        <DialogContent >
            {errorMessage?.map((error, index) => {
                const keys = Object.keys(error).reverse();
                return (

                    <Alert className="mb-3" key={index} severity="warning">
                        {keys.map((key, i) => (
                            <div key={i} >
                                <span className="fw-bold">{key}:</span> {error[key]}
                            </div>
                        )
                        )}
                    </Alert>
                );
            })}
        </DialogContent>
    </BootstrapDialog>
);

export default ImportFileError;

