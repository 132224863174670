import { Autocomplete, Box, Chip, Grid, TextField } from "@mui/material";
import { joiResolver } from "@hookform/resolvers/joi";
import CustomDialog from "../../../../components/mui/dialog";
import { useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import CustomLabel from "../../../../components/mui/custom-label";
import { IDailyReport } from "../../../../interfaces/configuration/daily-report";
import { writerReportRolesValidation } from "../../../../validations";
import { IErrorResponse, IRole, IWriterReportRolesFields } from "../../../../interfaces";
import useSnackbar from "../../../../hooks/useSnackbar";
import { WriterReportRoles, RoleService } from "../../../../services";
import { capitalize } from "../../../../utilities/helper";
import { useState, SyntheticEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useEffect, KeyboardEvent } from "react";
import { validateEmail } from "../../../../validations/shared";
import EmailSuggestion from "../../../../components/mui/email-suggestion";


interface outletProps {
  refetchWriterReportRoles: () => void;
}

const ManageWriterReportRoles = () => {

  const { updateWriterReportRoles, getWriterReportRole } = WriterReportRoles();
  const { control, getValues, handleSubmit, setValue, watch, resetField, trigger, formState: { errors } } = useForm<IDailyReport>({
    resolver: joiResolver(writerReportRolesValidation),
    defaultValues: {
      _roles: [],
      cc: [],
      bcc: [],
      ccText: "",
      bccText: ""
    }
  });
  const [searchParams ] = useSearchParams();
  const { getPartialRoles } = RoleService();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const navigate = useNavigate();
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const { id } = useParams();
  const rolesData = useQuery({
    queryKey: ["partialRoles"],
    queryFn: () => getPartialRoles({ admin: true }),
  });
  const roles = rolesData && rolesData.data && rolesData.data.data || [];
  const onClose = () => {
    navigate({pathname: "/content/writer-report-roles", search: searchParams.toString()});
	};

  const reportData = useQuery({
    queryKey: ["writer-report-roles"],
    queryFn: () => getWriterReportRole({
      _id: id })
  });

  const fields: IWriterReportRolesFields[] = [
    {
      label: "Roles",
      name: "_roles",
      type: "select",
      required: true,
      displayFieldKey: "value",
      storeFieldKey: "key",
    },
    {
      type: "input",
      name: "ccText",
      label: "CC",
      placeholder: "Type email address and press enter"
    },
    {
      type: "input",
      name: "bccText",
      label: "BCC",
      placeholder: "Type email address and press enter"
    },
  ];

  const addEmail = (e: KeyboardEvent<HTMLDivElement>, key: string) => {
    let payload: string[] = [];

    if (key === "ccText" || key === "bccText") {
        const err = validateEmail(getValues(key));
        if (err.error) {
            return;
        }

        const enteredEmail = getValues(key) ? String(getValues(key)) : "";

        if (enteredEmail.trim() !== "" && e.key === "Enter") {
            if (key === "ccText") {
                const prev = getValues("cc") ? getValues("cc") : [];
                payload = [...prev, enteredEmail];
                setValue("cc", [...new Set(payload)]);
                resetField(key);
            } else if (key === "bccText") {
                const prev = getValues("bcc") ? getValues("bcc") : [];
                payload = [...prev, enteredEmail];
                setValue("bcc", [...new Set(payload)]);
                resetField(key);
            }
        }
    }
  };

  const handleEmailSelect = (email: string, name: string) => {
      let payload: string[] = [];
      if (["ccText", "bccText"].includes(name)) {
          if (name === "ccText") {
              const prev = getValues("cc") ? getValues("cc") : [];
              payload = [...prev, email];
              setValue("cc", [...new Set(payload)]);
              resetField(name);

          } else if (name === "bccText") {
              const prev = getValues("bcc") ? getValues("bcc") : [];
              payload = [...prev, email];
              setValue("bcc", [...new Set(payload)]);
              resetField(name);
          }
      }
  };

  const removeEmail = (key: string, value: string) => {
      if (key === "ccText") {
          let payload = getValues("cc");
          payload = payload.filter(email => email !== value);
          setValue("cc", payload);
          trigger("cc");
      } else if (key === "bccText") {
          let payload = getValues("bcc");
          payload = payload.filter(email => email !== value);
          setValue("bcc", payload);
          trigger("bcc");
      }
  };

  const onCopy = (url: string, label: string) => {
    navigator.clipboard.writeText(url);
    snackbar(`${capitalize(label)} ID copied to clipboard`, "info");
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
        if (name && ["ccText", "bccText"].includes(name)) {
            trigger(name);
        }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const data = reportData && reportData.data && reportData.data.data;
    if(data) {
      const roleIds = data._roles.map((role) => role._id);
      setValue("_roles", roleIds || []);
      setUserRoles(roleIds || []);
      setValue("cc", data.cc || []);
      setValue("bcc", data.bcc || []);
    }
  }, [(reportData && reportData.data && reportData.data.data )]);
  
  const onSubmit = async (data: IDailyReport) => {
    try {
      const payload = {
        _id: id,
        _roles: userRoles,
        cc: data.cc,
        bcc: data.bcc
      };
      const response = await updateWriterReportRoles(payload);
      snackbar(response.message, "info");
      outlet.refetchWriterReportRoles();
      navigate({pathname: "/content/writer-report-roles", search: searchParams.toString()});
    } catch(error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "error");
    }
  };

  const removeChip = (field: string, data: string) => {
    if(field === "_roles") {
      setUserRoles(userRoles.filter((item) => item !== data));
    }
  };

  const setRolesInArray = (event: SyntheticEvent<Element, Event>, value: IRole[], field: string ) => {
    if(field === "_roles" && !userRoles.includes(value[0]._id)) {
      const roleIds = value.map((role) => role._id);
      setUserRoles([...userRoles, ...roleIds]);
    }
  };

  const handleKeyDown = (event : React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
        event.preventDefault();
    }
  };

  return (
    <CustomDialog
      title="Edit Writer Report Roles"
      isOpen={!! id}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      size="sm"
    >
      <Grid container spacing={4} onKeyDown={handleKeyDown}>
      
          {
            fields.map(field => {
              if(field.type === "select") {
                return (<Grid key={field.label} item xs={12}>
                  <Autocomplete
                    fullWidth
                    options={roles || []}
                    getOptionLabel={(option) => capitalize(option.name)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        label={<CustomLabel label={field.label} required={field?.required} />}
                        placeholder={field.placeholder}
                        disabled={!! field.disabled}
                        error={errors[field.name] && true}
                        helperText={errors[field.name]?.message}
                      />
                    )}
                    disabled={!!field.disabled}
                    value={[]}
                    onChange={(e, value) =>
                      setRolesInArray(e, value, field.name)
                    }
                    multiple
                  />
                  {
                    userRoles.map(data => (
                      <Chip
                            key={data}
                            label={capitalize(roles.filter((role) => role._id === data)?.[0]?.name)}
                            onDelete={() => removeChip(field.name, data)}
                            color="primary"
                            variant="outlined"
                            sx={{ margin: "5px" }}
                      />
                    ))
                  }
              </Grid>
              );
              }else{
                return (<Grid key={field.label} item xs={12} md={field.width ? field.width : 6}>
                  <EmailSuggestion
                      control={control}
                      label={<CustomLabel label={field.label} required={field?.required} />}
                      name={field.name}
                      value={getValues(field.name) as string}
                      placeholder={field.placeholder}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      onEmailSelect={handleEmailSelect}
                      onKeyUp={addEmail}
                  />
                  {
                    <Box>
                      {
                        field.name === "ccText" && getValues("cc") &&
                        getValues("cc").map(email => <Chip
                          key={email}
                          label={email}
                          onDelete={() => removeEmail(field.name, email)}
                          color="primary"
                          variant="outlined"
                          sx={{ margin: "5px" }}
                          onClick={() => onCopy(email, "email")}
                        />)
                        ||
                        field.name === "bccText" && getValues("bcc") &&
                        getValues("bcc").map(email => <Chip
                          key={email}
                          label={email}
                          onDelete={() => removeEmail(field.name, email)}
                          color="primary"
                          variant="outlined"
                          sx={{ margin: "5px" }}
                          onClick={() => onCopy(email, "email")}
                        />)
                      }
                    </Box>
                  }
              </Grid>
              );
              }
            })
          }
        
      </Grid>
    </CustomDialog>
  );
};

export default ManageWriterReportRoles;