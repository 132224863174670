import * as React from "react";
import {
  Unstable_NumberInput as BaseNumberInput,
  NumberInputProps,
  numberInputClasses,
} from "@mui/base/Unstable_NumberInput";
import { styled } from "@mui/system";

const CustomNumberInput = React.forwardRef(function CustomNumberInput(
  props: NumberInputProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <BaseNumberInput
      min={0}
      readOnly={true}
      slots={{
        root: StyledInputRoot,
        input: StyledInputElement,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      slotProps={{
        incrementButton: {
          children: "▴",
        },
        decrementButton: {
          children: "▾",
        },
      }}
      {...props}
      ref={ref}
    />
  );
});
interface NumberInputIntroductionProps {
  onChange?: (event: number | null, index: number) => void;
  index: number;
  initialValue?: number;
}

const NumberInputIntroduction: React.FC<NumberInputIntroductionProps> =({ onChange, index, initialValue }) => 
   (
<CustomNumberInput
  aria-label="Demo number input"
  disabled={!onChange}
  onChange={(event, value: number | null) => onChange && onChange(value, index)}
  value={initialValue}
/>

  );

export default NumberInputIntroduction;

const StyledInputRoot = styled("div")(
  () => `
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  border-bottom: 1px solid #CCCCCC;
  display: grid;
  grid-template-columns: 1fr 19px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  column-gap: 8px;
  padding: 4px;


  &:focus-visible {
    outline: 0;
  }
`,
);

const StyledInputElement = styled("input")(
  () => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  width:26px;
  grid-column: 1/2;
  grid-row: 1/3;
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 0;
  outline: 0;
`,
);

const StyledButton = styled("button")(
  () => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  padding: 0;
  width: 19px;
  font-family: system-ui, sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  box-sizing: border-box;
  border: 0;
  transition-property: all;

  &:hover {
    cursor: pointer;
  }

  &.${numberInputClasses.incrementButton} {
    grid-column: 2/3;
    grid-row: 1/2;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 0;
    background-color:#CCCCCC;
    &:hover {
      cursor: pointer;
    }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
  }

  &.${numberInputClasses.decrementButton} {
    grid-column: 2/3;
    grid-row: 2/3;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
  }

  &:hover {
    cursor: pointer;
  }

  & .arrow {
    transform: translateY(-1px);
  }

  & .arrow {
    transform: translateY(-1px);
  }
`,
);
