import HttpService from "../../http";
import { IUsersResponse, IUserResponse, IUserSampleResponse, IUserSkillMatricesCategoriesResponse, IUserSkillMatricesSkillsResponse, IUserSkillMatricesResponse, IUnassignedSkillMatrixResponse, IUerActivityResponse } from "../../../interfaces";
import { user } from "../../endpoints";

const UsersService = () => {
    const { httpRequest } = HttpService();
    const addUser = async (payload: object): Promise<IUsersResponse> => new Promise((resolve, reject) => {
        httpRequest<IUsersResponse>(
            "POST",
            `${user}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });
    const addUsers = async (payload: object): Promise<IUsersResponse> => new Promise((resolve, reject) => {
        httpRequest<IUsersResponse>(
            "POST",
            `${user}/many`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getPartialUsers = async (): Promise<IUsersResponse> => new Promise((resolve, reject) => {
        httpRequest<IUsersResponse>(
            "GET",
            `${user}/list/partial`,
            {},
            {}
        )
            .then(resolve)
            .catch(reject);
    });

    const getUsers = async (search: object): Promise<IUsersResponse> => new Promise((resolve, reject) => {
        httpRequest<IUsersResponse>(
            "GET",
            `${user}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getUser = async (query: object): Promise<IUserResponse> => new Promise((resolve, reject) => {
        httpRequest<IUserResponse>(
            "GET",
            user,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateUser = async (payload: object): Promise<IUserResponse> => new Promise((resolve, reject) => {
        httpRequest<IUserResponse>(
            "PUT",
            user,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteUser = async (query: object): Promise<IUserResponse> => new Promise((resolve, reject) => {
        httpRequest<IUserResponse>(
            "DELETE",
            `${user}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const myProfile = async (): Promise<IUserResponse> => new Promise((resolve, reject) => {
        httpRequest<IUserResponse>(
            "GET",
            `${user}/my-profile`
        )
            .then(resolve)
            .catch(reject);
    });

    const myProfileUpdate = async (payload: object): Promise<IUserResponse> => new Promise((resolve, reject) => {
        httpRequest<IUserResponse>(
            "PUT",
            `${user}/my-profile`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getRoleBasedUser = async (query: object): Promise<IUsersResponse> => new Promise((resolve, reject) => {
        httpRequest<IUsersResponse>(
            "GET",
            `${user}/role-based-users`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const getUserSample = async (search: object): Promise<IUserSampleResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IUserSampleResponse>(
                "GET",
                `${user}/sample`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });

        const getUserAssignedSkillMatrices = async (query: object): Promise<IUserSkillMatricesResponse> => new Promise((resolve, reject) => {
            httpRequest<IUserSkillMatricesResponse>(
                "GET",
                `${user}/skill-matrix`,
                {},
                query
            )
                .then(resolve)
                .catch(reject);
        });

        const getUserAssignedSkillMatricesCategories = async (query: object): Promise<IUserSkillMatricesCategoriesResponse> => new Promise((resolve, reject) => {
            httpRequest<IUserSkillMatricesCategoriesResponse>(
                "GET",
                `${user}/skill-category`,
                {},
                query
            )
                .then(resolve)
                .catch(reject);
        });

        const getUserAssignedSkillMatricesCategoriesItems = async (query: object): Promise<IUserSkillMatricesSkillsResponse> => new Promise((resolve, reject) => {
            httpRequest<IUserSkillMatricesSkillsResponse>(
                "GET",
                `${user}/skill-item`,
                {},
                query
            )
                .then(resolve)
                .catch(reject);
        });

        const getAllUnassignedSkillMatrices = async (query: object): Promise<IUnassignedSkillMatrixResponse> => new Promise((resolve, reject) => {
            httpRequest<IUnassignedSkillMatrixResponse>(
                "GET",
                `${user}/skill-matrix/list`,
                {},
                query
            )
                .then(resolve)
                .catch(reject);
        });

        const assignSkillMatrix = async (payload: object): Promise<IUnassignedSkillMatrixResponse> => new Promise((resolve, reject) => {
            httpRequest<IUnassignedSkillMatrixResponse>(
                "POST",
                `${user}/assign-skill-matrix`,
                payload
            )
                .then(resolve)
                .catch(reject);
        });

        const getUserTimeline = async (query: object): Promise<IUerActivityResponse> => new Promise((resolve, reject) => {
            httpRequest<IUerActivityResponse>(
                "GET",
                `${user}/activity`,
                {},
                query
            )
                .then(resolve)
                .catch(reject);
        });

        const logout = () => new Promise<IUserResponse>((resolve, reject) => 
            httpRequest<IUserResponse>(
                "GET",
                `${user}/logout`,
            )
                .then(resolve)
                .catch(reject)
        );

    return { 
        addUser, addUsers, getPartialUsers, getUsers, getRoleBasedUser, getUser, updateUser, deleteUser, myProfile , getUserSample
        , getUserAssignedSkillMatrices, getUserAssignedSkillMatricesCategories, getUserAssignedSkillMatricesCategoriesItems, 
        getAllUnassignedSkillMatrices, assignSkillMatrix, getUserTimeline, myProfileUpdate, logout
    };
};

export { UsersService };