import Joi from "joi";
import { required } from "../../shared";

export const QuarterTargetValidations = Joi.object({
    form: Joi.array()
        .items({
            _category: Joi.string()
                .hex()
                .required()
                .label("Category")
                .messages({
                    "string.empty": required
                }),

            _subCategory: Joi.string()
                .hex()
                .required()
                .label("Sub Category")
                .messages({
                    "string.empty": required
                }),
            targets: Joi.object()
                .pattern(
                    Joi.string(),
                    Joi.string().allow(""))
                .label("Dynamic Fields"),
            children2: Joi.array()
        })

        .required()
        .label("Form"),
});