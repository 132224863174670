import HttpService from "../http";
import { INotificationsResponse, ICountNotificationResponse } from "../../interfaces";
import { notification } from "../endpoints";

const NotificationService = () => {
    const { httpRequest } = HttpService();
    const getNotifications = async (search: object): Promise<INotificationsResponse> => new Promise((resolve, reject) => {
        httpRequest<INotificationsResponse>(
            "GET",
            `${notification}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });


    const countNotifications = async (): Promise<ICountNotificationResponse> => new Promise((resolve, reject) => {
        httpRequest<ICountNotificationResponse>(
            "GET",
            `${notification}/count`
        )
            .then(resolve)
            .catch(reject);
    });

    const updateNotification = async (payload: object): Promise<INotificationsResponse> => new Promise((resolve, reject) => {
        httpRequest<INotificationsResponse>(
            "PUT",
            notification,
            payload
        )
            .then(resolve)
            .catch(reject);
    });


    return { getNotifications, countNotifications, updateNotification };
};

export { NotificationService };