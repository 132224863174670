import { Box, Tabs, Tab } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { IProject } from "../../interfaces/content/projects-products";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PlannerLayout = () => {

  const navigate = useNavigate();
  const location = useLocation();


  const projects = useSelector<{ cmsProduct: { list: IProject[] } }, IProject[]>(state => state?.cmsProduct?.list) || [];
  const [value, setValue] = useState(0);

  useEffect(() => {
    const index = projects?.findIndex((obj) => obj._id === location.pathname.split("/")[2]);
    const newIndex = index !== -1 ? index : 0;
    setValue(newIndex);
  }, [location.pathname]);


  const handleChange = (event: SyntheticEvent, newValue: number) => {
    const selectedLink = projects && projects[newValue];
    const pathOfTabs = selectedLink ? selectedLink._id : "/dashboard";
    navigate(pathOfTabs);
  };

  return (
    <Box width="100%">
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
          <Box>
        <Tabs value={value} variant="scrollable" onChange={handleChange} aria-label="basic tabs example">
          {projects?.map((tab, index) => <Tab key={tab._id} className={`tabs-space ${index === 0 ? "start-tab-space" : ""}`} {...a11yProps(index)} label={tab.name} />)}
        </Tabs>
        </Box>
      </Box>
      <Outlet />
    </Box>
  );
};

export default PlannerLayout;
