import { Badge, Box, Button, Grid, Tooltip } from "@mui/material";
import { useEffect, useState, MouseEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import AnalyticsCard from "../components/analytics-card";
import { BarGraph } from "../../../components/graphs";
import ReportService from "../../../services/report";
import FilterListIcon from "@mui/icons-material/FilterList";
import ReportFilters from "../components/filter";
import { IReportFilter, IReportFilterDialog } from "../../../interfaces";


const Overview = () => {
  const { overview } = ReportService();
  const [searchParams] = useSearchParams();
  const [filtersCount, setFiltersCount] = useState(0);
  const [filters, setFilters] = useState<IReportFilter>({
    department: [],
    job: [],
    typeOfLead: [],
    assignTo: []
  });
  const getOverview = useQuery({
    queryKey: ["report-overview", filters],
    queryFn: () => overview({ ...filters })
  });

  useEffect(() => {
    let filterCount = 0;
    const typeOfLead: { key: string, value: string }[] = searchParams.get("typeOfLead") ? JSON.parse(String(searchParams.get("typeOfLead"))) : [];
    const job: { key: string, value: string }[] = searchParams.get("job") ? JSON.parse(String(searchParams.get("job"))) : [];
    const department: { key: string, value: string }[] = searchParams.get("department") ? JSON.parse(String(searchParams.get("department"))) : [];
    const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
    const assignTo: { key: string, value: string }[] = searchParams.get("assignTo") ? JSON.parse(String(searchParams.get("assignTo"))) : [];
    let createdAt: {
      startDate: string;
      endDate: string;
    } | undefined = undefined;
    if (date?.length) {
      createdAt = {
        startDate: date[0]?.startDate,
        endDate: date[0]?.endDate,
      };
    }

    filterCount += typeOfLead.length ? 1 : 0;
    filterCount += job.length ? 1 : 0;
    filterCount += department.length ? 1 : 0;
    filterCount += assignTo.length ? 1 : 0;
    filterCount += date.length ? 1 : 0;
    setFiltersCount(filterCount);
    setFilters(prev => ({
      ...prev,
      job: job.map((item) => item.key),
      assignTo: assignTo.map((item) => item.key),
      department: department.map((item) => item.value),
      typeOfLead: typeOfLead.map((item) => item.key),
      date: createdAt
    }));
  }, [searchParams]);

  const countData = [
    {
      title: "Candidates imported",
      subtitle: "No. of Candidates imported",
      count: getOverview.data?.data?.importedCandidates || 0,
      isMoreSubtitle: false,
    },
    {
      title: "Candidates applied",
      subtitle: "No. of Candidates applied",
      count: getOverview.data?.data?.appliedCandidates || 0,
      isMoreSubtitle: false,
    },
    {
      title: "Candidates in pipeline",
      subtitle: "No. of Candidates with live process",
      count: getOverview.data?.data?.pipelineCandidates || 0,
      isMoreSubtitle: false,
    },
    {
      title: "Candidates hired",
      subtitle: "No. of Candidates hired",
      count: getOverview.data?.data?.hiredcandidates || 0,
      isMoreSubtitle: false,
    },
    {
      title: "Interviews scheduled",
      subtitle: "No. of interviews scheduled in selected time",
      count: Number(getOverview.data?.data?.interviewScheduled) || 0,
      isMoreSubtitle: false,
    },
    {
      title: "Jobs posted",
      subtitle: "No. of jobs posted",
      count: getOverview?.data?.data?.totalPostedJobs || 0,
      isMoreSubtitle: false,
    },
    {
      title: "Active jobs",
      subtitle: "No. of jobs with live process",
      isMoreSubtitle: true,
      subtitle1: "Total jobs",
      count1: getOverview.data?.data?.totalActiveJobs || 0,
      subtitle2: "Total openings",
      count2: getOverview.data?.data?.totalOpenings || 0,
    },
    {
      title: "Candidates rejected",
      subtitle: "No. of candidates rejected",
      isMoreSubtitle: true,
      subtitle1: "Not Interested",
      count1: getOverview.data?.data?.directlyRejected || 0,
      subtitle2: "Total rejections",
      count2: getOverview.data?.data?.rejectedcandidates || 0,
    },
  ];

  const showData = (label: string) => {
    const data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: ["rgba(17, 140, 252, 0.3)"],
        },
      ],
    };

    const labels: string[] = [];
    const count: number[] = [];

    getOverview.data?.data?.allCandidatesByDate && getOverview.data?.data?.allCandidatesByDate.map(candidate => {

      labels.push(candidate?._id) || "";
      count.push(candidate?.count || 0);
    });

    if (label === "allCandidates") {
      return {
        ...data,
        labels: labels,
        datasets: [
          {
            label: "Candidates",
            data: count,
            backgroundColor: ["rgba(17, 140, 252, 0.3)"],
          },
        ],
      };
    } else {
      return data;
    }
  };

  const [state, setState] = useState<IReportFilterDialog>({
    filterDialog: {
      anchorEl: null,
      isOpen: false
    }
  });
  
  const openFilter = (e: MouseEvent<HTMLButtonElement>) => {
    const anchorElement = e.currentTarget;

    setState((prevState) => ({
        ...prevState,
        filterDialog: {
            ...prevState.filterDialog,
            anchorEl: anchorElement,
            isOpen: !state.filterDialog.isOpen
        }
    }));
};

  const closeFilter = () => {
    setState((prevState) => ({
        ...prevState,
        filterDialog: {
            ...prevState.filterDialog,
            isOpen: false
        }
    }));
};
  return (
    <>
      {/* Show Data  */}
      <Box marginTop="10px" height="calc(100vh - 145px)">
        <Box className="tab-header" marginBottom="30px">
          <Box className="tab-header-box">
            <Box display="flex" alignItems="center" className="filter-box">
              <Tooltip title='Filter'>
                <Badge
                  className="h-100 ml-2"
                  color="primary"
                  badgeContent={filtersCount}
                  invisible={!filtersCount}
                >
                  <Button
                    className={filtersCount ? "filter active" : "filter default"}
                    variant='outlined'
                    onClick={openFilter}
                  >
                    <FilterListIcon />
                  </Button>
                </Badge>
              </Tooltip>
            </Box>
            <ReportFilters
              anchorEl={state.filterDialog.anchorEl}
              isOpen={state.filterDialog.isOpen}
              OnClose={closeFilter}
            />
          </Box>
        </Box> 

        <Box marginTop="16px" height="calc(100% - 70px)" overflow="auto">
          <Grid container spacing={2}>
            {/* show count  */}
            {
              countData.map((info, index) => (
                <Grid key={index} item xs={6} md={4} lg={3}>
                  <AnalyticsCard
                    index={index}
                    title={info.title}
                    subtitle={info.subtitle}
                    count={info.count}
                    isMoreSubtitle={info.isMoreSubtitle}
                    primarySubTitle={info.subtitle1}
                    count1={info.count1}
                    secondarySubTitle={info.subtitle2}
                    count2={info.count2}
                  />
                </Grid>
              ))
            }

            {/* graph  */}
            <Grid item xs={12}>
              <BarGraph
                label="Candidates sourced over time"
                data={showData("allCandidates")}
              />
            </Grid>
          </Grid>
        </Box>

      </Box>
    </>
  );
};
export default Overview;