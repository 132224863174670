import HttpService from "../../http";
import { onboardUser } from "../../endpoints";
import { IOnboardUsersResponse, IOnboardUserResponse } from "../../../interfaces";


const OnboardUserService = () => {
    const { httpRequest } = HttpService();

    const getOnBoardUsers = async (search: object): Promise<IOnboardUsersResponse> => new Promise((resolve, reject) => {
        httpRequest<IOnboardUsersResponse>(
            "GET",
            `${onboardUser}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const addOnBoardUser = async (payload: object): Promise<IOnboardUsersResponse> => new Promise((resolve, reject) => {
        httpRequest<IOnboardUsersResponse>(
            "POST",
            onboardUser,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getOnBoardUser = async (query: object): Promise<IOnboardUserResponse> => new Promise((resolve, reject) => {
        httpRequest<IOnboardUserResponse>(
            "GET",
            onboardUser,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateOnBoardUser = async (payload: object): Promise<IOnboardUserResponse> => new Promise((resolve, reject) => {
        httpRequest<IOnboardUserResponse>(
            "PUT",
            onboardUser,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteOnBoardUser = async (query: object): Promise<IOnboardUserResponse> => new Promise((resolve, reject) => {
        httpRequest<IOnboardUserResponse>(
            "DELETE",
            onboardUser,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    return { getOnBoardUsers, addOnBoardUser, getOnBoardUser, updateOnBoardUser, deleteOnBoardUser };

};

export { OnboardUserService };