import { Grid, TextField } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { CandidateService } from "../../../../../../../../services";
import { capitalize } from "../../../../../../../../utilities/helper";

function ResponseBasicDetail() {
  const { getCandidate } = CandidateService();
  const { leadId } = useParams();
  const candidateDetails = useQuery({
    queryKey: ["candidate-details"],
    queryFn: () => getCandidate({ _id: leadId })
  });
  return (
    <div>
      <Grid container spacing={2} sx={{ padding: "2rem" }}>
        <Grid item xs={6}>
          <TextField
            margin="dense"
            label="Name"
            name="category_name"
            variant="outlined"
            placeholder="simple placeholder"
            fullWidth
            disabled
            size="medium"
            value={capitalize(candidateDetails.data?.data.name ?? "")}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="dense"
            label="Email Id"
            name="grade_selection"
            variant="outlined"
            placeholder="simple placeholder"
            value={candidateDetails?.data?.data?.email ?? ""}
            fullWidth
            disabled
            size="medium"
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            margin="dense"
            label="Phone (Primary)"
            name="name4"
            variant="outlined"
            placeholder="simple placeholder"
            value={
              candidateDetails?.data?.data?.mobileNumber ?
                candidateDetails?.data?.data?.mobileNumber.dialCode + " " + candidateDetails?.data?.data?.mobileNumber.number :
                ""
            }
            fullWidth
            disabled
            size="medium"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="dense"
            label="Phone (Secondary)"
            name="name4"
            variant="outlined"
            placeholder="simple placeholder"
            value={
              candidateDetails?.data?.data?.mobileNumberSecondary?.dialCode &&
              candidateDetails?.data?.data?.mobileNumberSecondary?.number
                ? `${candidateDetails.data.data.mobileNumberSecondary.dialCode} ${candidateDetails.data.data.mobileNumberSecondary.number}`
                : ""
            }
            fullWidth
            disabled
            size="medium"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            label="Home Location"
            name="name3"
            variant="outlined"
            placeholder="simple placeholder"
            value={candidateDetails?.data?.data?.homeLocation ?? ""}
            fullWidth
            disabled
            size="medium"
          />
        </Grid>
      </Grid>
    </div>
  );
}
export default ResponseBasicDetail;