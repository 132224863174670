import { Box, Switch, Typography } from "@mui/material";
import Header from "../../../components/header";
import { ChangeEvent, useEffect, useState, MouseEvent } from "react";
import { useNavigate, useSearchParams, Outlet } from "react-router-dom";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import useSnackbar from "../../../hooks/useSnackbar";
import {
  ICategory,
  ICategoryStatus,
  ICategoryRow,
} from "../../../interfaces/content/category";
import useResource from "../../../hooks/useResource";
import useDebounce from "../../../hooks/useDebounce";
import CategoryService from "../../../services/content/category";
import { useQuery } from "@tanstack/react-query";
import { IColumn, IErrorResponse, IProjectData } from "../../../interfaces";
import {
  capitalize,
  formatDateTime,
} from "../../../utilities/helper";
import BlogCategoryFilter from "./filter";
import { useSelector } from "react-redux";

const CategoryList = () => {
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const { getContentCategorys, deleteContentCategory, updateContentCategory } = CategoryService();
  const [search, setSearch] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
  const { resourceAllocate } = useResource();
  const [addState, setAddState] = useState(false);
  let rows: ICategoryRow[] = [];
  const [state, setState] = useState<ICategoryStatus>({
    deleteWarning: false,
    selectAll: [],
    _category: "",
    pagination: {
      page: page,
      limit: 20,
      totalPages: 1,
      totalRecords: 0,
    },
    filters: {
      search: "",
      productId: [],
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false,
    },
    filterCount: 0,
    multiDeleteWarning: false
  });

  const categorys = useQuery({
    queryKey: ["category", state.pagination.page, state.filters],
    queryFn: () =>
      getContentCategorys({
        pagination: true,
        page: state.pagination.page,
        limit: state.pagination.limit,
        ...state.filters,
      }),
  });

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);
  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord?.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);
  useEffect(() => {
    if (categorys?.data?.data?.length) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: categorys.data.meta.page,
          totalPages: categorys.data.meta.totalPages,
          totalRecords: categorys.data.meta.totalRecords,
        },
      }));
    }
  }, [categorys?.data?.meta]);

  const productList = useSelector<{ cmsProduct: { list: IProjectData[] } }, IProjectData[]>(
    (state) => state.cmsProduct.list
  ) || [];

  const applyDefaultFilter = () => {
    const selectedFilterProductId = localStorage.getItem("selectedFilterProductId") || "";
    const selectedProduct = productList.find((item) => item._id === selectedFilterProductId);
    const product = [];
  
    if (selectedProduct) {
      product.push({ key: selectedProduct._id, value: selectedProduct.name });
    } else {
      product.push({ key: "", value: "" });
    }
    if(product[0].value !== ""){
      searchParams.set("productId", JSON.stringify(product));
      setSearchParams(searchParams);
    }
  };
  
  useEffect(() => {
    applyDefaultFilter();
  }, []);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";

    const productId : { key: string; value: string }[] = searchParams.get(
      "productId"
    )
      ? JSON.parse(String(searchParams.get("productId")))
      : [];

    filterCount += productId.length ? 1 : 0;

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
        productId: productId.map((item) => item.key),
      },
      filterCount,
    }));
  }, [searchParams]);



  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
    navigate(`?${searchParams.toString()}`);
  };


  const handleDelete = (_category = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _category,
    }));
  };

  const columns: IColumn[] = [
    {
      id: "category_name",
      label: "Category",
    },
    {
      id: "product_name",
      label: "Product",
    },
    {
      id: "no_of_blogs",
      label: "No. of Blogs",
    },
    {
      id: "no_of_sub_category",
      label: "No. of Sub Categories",
    },
    {
      id: "date",
      label: "Creation Date",
    },
    {
      id: "created_by",
      label: "Created by",
    },
    {
      id: "isActive",
      label: "Status",
    },
    {
      id: "action",
      label: "Action",
      align: "right"
    },
  ];

  const onStatsusUpdate = async (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    id: string
  ) => {
    const isActive = checked ? "true" : "false";
    try {
      const response = await updateContentCategory({ _id: id, isActive });
      snackbar(response.message, "info");
      categorys.refetch();

    } catch (err) {
      console.log("Error in Category Update", err);
    }
  };
  const createRows = (
    category: ICategory
  ) => {
    const action = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () => {
              setAddState(true);
              navigate({
                pathname: "" + category._id,
                search: searchParams.toString(),
              });
            },
            disabled: resourceAllocate("cms-category.write") ? false : true,
          }
        ]}
      />
    );
    const isActive = <Switch
      disabled={resourceAllocate("cms-category.write") ? false : true}
      checked={category?.isActive}
      onChange={(event, checked) => onStatsusUpdate(event, checked, category._id)}
    />;
    return {
      category_name: category?.name,
      product_name: (category && category._productId && category._productId.name) ?? "",
      no_of_blogs: category?.no_of_blogs ?? 0,
      no_of_sub_category: category?.no_of_sub_category ?? 0,
      date: formatDateTime(category?.createdAt),
      created_by: capitalize(category?.createdBy?.name ?? ""),
      isActive,
      action,
    };
  };

  if (categorys.data?.data?.length) {
    rows = categorys.data?.data?.map((category: ICategory) =>
      createRows(category)
    );
  }

  const onMultiDelete = async () => {
    try {
      const category = await deleteContentCategory({ ids: state.selectAll });
      snackbar(category.message, "info");
      categorys.refetch();
      setState(prevState => ({
        ...prevState,
        multiDeleteWarning: false,
        selectAll: []
      }));
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      handleMultiDelete();
      console.log({ "Error in delete category": error });
    }
  };

  const handleMultiDelete = () => setState(prevState => ({
    ...prevState,
    multiDeleteWarning: !prevState.multiDeleteWarning
  }));

  const onDelete = async () => {
    try {
      const deleted = await deleteContentCategory({ ids: [state._category] });
      snackbar(deleted.message, "info");
      handleDelete();
      categorys.refetch();
    } catch (error) {
      console.log("Error in Deleting Category", error);
    }
  };

  const openAddBox = () => {
    setAddState(true);
    navigate("/content/category/new");
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) =>
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        anchorEl: e.currentTarget,
        isOpen: !state.filterDialog.isOpen,
      },
    }));

  const closeFilter = () => {
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false,
      },
    }));
  };

  return (
    <div>
      {addState && <Outlet context={{ ...Outlet, reFetch: categorys.refetch }} />}
      <Header
        className="my-2"
        searchPlaceholder="Search by name"
        onSearch={onSearch}
        btnText="Add Category"
        onBtnClick={resourceAllocate("cms-category.write") ? () => openAddBox() : undefined}
        onFilter={openFilter}
        filterCount={state.filterCount}
      >
        <BlogCategoryFilter
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnClose={closeFilter}
        />
      </Header>
      <Box display="flex" className="mb-2" justifyContent="flex-end" alignItems="center">
        <Box display="flex" alignItems="center">
          <Typography variant="body1">Total Categories:</Typography>
          <Typography className="ml-3" variant="body1" >{categorys?.data?.meta?.totalRecords}</Typography>
        </Box>
      </Box>
      <Box id="custom-table" marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 285px)"
          errorMessage="Add user to see the data here"
          pagination={{
            page: state.pagination.page,
            totalPages: state.pagination.totalPages,
          }}
          onPageChange={onPageChange}
        />
      </Box>

      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Category"
        description="Are you sure you want to delete this category?"
      />

      {/* Multiple Delete  */}
      <WarningDialog
        isOpen={state.multiDeleteWarning}
        onClose={() => handleMultiDelete()}
        onConfirm={onMultiDelete}
        title="Delete All Categories"
        description={`Are you sure you want to delete ${state.selectAll.length} selected ${state.selectAll.length > 1 ? "categories" : "category"}?`}
      />
    </div>
  );
};
export default CategoryList;
