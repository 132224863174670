import { Box, Tab, Tabs } from "@mui/material";
import { SyntheticEvent, useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, Outlet, useOutletContext } from "react-router-dom";
import useResource from "../../hooks/useResource";
import useUser from "../../hooks/useUser";

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

interface IState {
    redirectionLinks: string[]
  }

const ContentLayout = () => {
    const [value, setValue] = useState(0);
    const outlet = useOutletContext();
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useUser();
    const { resourceAllocate } = useResource();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [state, setState] = useState<IState>({
        redirectionLinks: []
      });

      useEffect(() => {
        const redirectionLinks = [...state.redirectionLinks];
        if (resourceAllocate("cms-product-title.read")) {
          redirectionLinks.push("products");
        }
        if (resourceAllocate("cms-category.read")) {
          redirectionLinks.push("category");
        }
        if (resourceAllocate("cms-sub-category.read")) {
          redirectionLinks.push("sub-category");
        }
        if (resourceAllocate("cms-keyword.read")) {
          redirectionLinks.push("keywords");
        }
        if (resourceAllocate("cms-funnel-parts.read")) {
          redirectionLinks.push("parts-of-funnel");
        }
        if (resourceAllocate("cms-goal.read")) {
          redirectionLinks.push("goals");
        }
        if (resourceAllocate("cms-content-types.read")) {
          redirectionLinks.push("type-of-content");
        }
        if (resourceAllocate("cms-score-data.read")) {
          redirectionLinks.push("score-data");
        }
        if (resourceAllocate("cms-product-mapping.read")) {
          redirectionLinks.push("product-mapping");
        }
        if (resourceAllocate("cms-author.read")) {
          redirectionLinks.push("author");
        }

        if (resourceAllocate("cms-writer-report-roles.read")) {
          redirectionLinks.push("writer-report-roles");
        }
        setState(prev => ({
          ...prev,
          redirectionLinks
        }));
      }, [user]);
    useEffect(() => {
        setValue(state.redirectionLinks.indexOf(location.pathname.split("/")[2]));
    }, [location.pathname,state.redirectionLinks]);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        navigate(state.redirectionLinks[newValue]);
        if (inputRef && inputRef?.current) {

            inputRef.current.value = "";
        }
    };

    return (
        <Box width="100%" >
                 <Box sx={{ borderBottom: 1, borderColor: "divider" }}>  
                    <Tabs value={value} onChange={handleChange} variant="scrollable" aria-label="basic tabs example" >
                        {resourceAllocate("cms-product-title.read") && <Tab className="tabs-space start-tab-space" label="Project/Product" {...a11yProps(0)} />}
                        {resourceAllocate("cms-category.read") && <Tab className="tabs-space " label="Category" {...a11yProps(1)} />}
                        {resourceAllocate("cms-sub-category.read") && <Tab className="tabs-space" label="Sub Category" {...a11yProps(2)} />}
                        {resourceAllocate("cms-keyword.read") && <Tab className="tabs-space" label="Keywords" {...a11yProps(3)} />}
                        {resourceAllocate("cms-funnel-parts.read") && <Tab className="tabs-space" label="Parts of funnel" {...a11yProps(4)} />}
                        {resourceAllocate("cms-goal.read") && <Tab className="tabs-space" label="Goals" {...a11yProps(5)} />}
                        {resourceAllocate("cms-content-types.read") && <Tab className="tabs-space" label="Type of Content" {...a11yProps(6)} />}
                        {resourceAllocate("cms-score-data.read") && <Tab className="tabs-space" label="Score Data" {...a11yProps(7)} />}
                        {resourceAllocate("cms-product-mapping.read") && <Tab className="tabs-space" label="Product Mapping" {...a11yProps(8)} />}
                        {resourceAllocate("cms-author.read") && <Tab className="tabs-space" label="Author" {...a11yProps(9)} />}
                        {resourceAllocate("cms-writer-report-roles.read") && <Tab className="tabs-space" label="Writer Report Roles" {...a11yProps(9)} />}
                    </Tabs>
            </Box>
            <Outlet  context={outlet}/>
        </Box>
    );
};

export default ContentLayout;