import { IVendorResponse, IVendorsResponse } from "../../interfaces/vendor";
import HttpService from "../../services/http";
import { vendor } from "../endpoints";

const VendorsService = () => {
    const { httpRequest } = HttpService();

    const getVendors = async (search: object): Promise<IVendorsResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsResponse>(
            "GET",
            `${vendor}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getVendor = async (search: object): Promise<IVendorResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorResponse>(
            "GET",
            `${vendor}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const addVendor = async (payload: object): Promise<IVendorResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorResponse>(
            "POST",
            `${vendor}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updateVendor = async (payload: object): Promise<IVendorResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorResponse>(
            "PUT",
            `${vendor}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteVendor = async (payload: object): Promise<IVendorsResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsResponse>(
            "DELETE",
            `${vendor}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updateVendorStatus = async (payload: object): Promise<IVendorsResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsResponse>(
            "PUT", 
            `${vendor}/status`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { getVendors, updateVendor, getVendor, addVendor, deleteVendor, updateVendorStatus };
};

export { VendorsService };