import "../style.scss";
import { Autocomplete, AutocompleteChangeReason, Box, Button, Divider, Grid, MenuItem, TextField } from "@mui/material";
import { ICandidateDetails,ICandidate, ICpdDrive, IErrorResponse, IInternCareersField, TInternCareersName } from "../../../interfaces";
import { Controller, useForm } from "react-hook-form";
import { FC, SyntheticEvent, useEffect } from "react";
import { useSelector } from "react-redux";
import { internCareerValidation } from "../../../validations";
import { joiResolver } from "@hookform/resolvers/joi";
import { CandidateService } from "../../../services";
import Select from "../../mui/select";
import useSnackbar from "../../../hooks/useSnackbar";
import { getGraduationYears } from "../../../utilities/helper";

interface props {
  candidate: ICandidateDetails | undefined;
  isDisable: boolean;
  setIsDisable: (e: boolean) => void;
  onClose: () => void;
  reFetch: () => void
}

interface ICollege {
  _id: string;
  name: string;
}

const InternCareers: FC<props> = ({ candidate, isDisable, setIsDisable, onClose, reFetch }) => {
  const [...colleges] = useSelector<{ college: { list: ICollege[] } }, ICollege[]>(state => state.college.list) || [];
  const drives = useSelector<{ cpdDrive: { list: ICpdDrive[] } }, ICpdDrive[]>(state => state.cpdDrive.list);
  const { updateCandidate } = CandidateService();
  const { snackbar } = useSnackbar();
  const currentYear = new Date().getFullYear();
  const years = getGraduationYears(currentYear);
  const { control, handleSubmit, setValue, getValues, trigger, watch, formState: { errors } } = useForm<ICandidate>({
    resolver: joiResolver(internCareerValidation),
    defaultValues: {
      _cpd: "",
      collegeName: "",
      degree: "",
      comments: ""
    }
  });

  useEffect(() => {
    if (candidate && Object.keys(candidate).length) {
      const keys: TInternCareersName[] = ["_cpd", "collegeName", "graduationYear", "degree", "comments"];
      keys.forEach(key => {
        switch (key) {
          case "collegeName": {
            setValue(key, candidate[key]);
            trigger("collegeName");
            break;
          }
          default:
            setValue(key, candidate[key]);
            break;
        }
      });
    }
  }, [candidate]);

  useEffect(() => {
    if (candidate && Object.keys(candidate).length) {
      const keys: TInternCareersName[] = ["_cpd", "collegeName", "graduationYear", "degree", "comments"];
      for (const key of keys) {
        if (key === "collegeName") {
          continue;
        }
        if(candidate[key]){
          setValue(key, candidate[key]);
        }
      }
    }
  }, [isDisable]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "_cpd") {
        const drive = drives.find(drive => drive.cpdId === value._cpd);
        setValue("collegeName", drive?.collegeId.name);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, drives]);

  const onChangeAutoComplete = (event: SyntheticEvent<Element, Event>, value: ICollege | null, reason: AutocompleteChangeReason,) => {
    switch (reason) {
      case "selectOption":
        setValue("collegeName", value?.name);
        break;
      case "clear":
        setValue("collegeName", "");
        break;
      case "removeOption":
        setValue("collegeName", "");
        break;
    }
    trigger("collegeName");
  };

  const onSubmit = async (data: ICandidate) => {
    try {
      const updatedCandidate = await updateCandidate({ ...data, _lead: candidate?._id });
      snackbar(updatedCandidate.message, "info");
      onClose();
      reFetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log("error in candidate detail update", error);
    }
  };

  const fields: IInternCareersField[] = [
    {
      label: "CPD",
      name: "_cpd",
      type: "select",
      children: drives.map(drive => <MenuItem key={drive._id} value={drive.cpdId} >{drive.cpdId}</MenuItem>)
    },
    {
      label: "College",
      name: "collegeName",
      type: "auto-complete",
    },
    {
      label: "Graduation Year",
      name: "graduationYear",
      type: "select",
      children: years.map(year => <MenuItem key={year.value} value={year.value} >{year.value}</MenuItem>),
      placeholder: "NA",
    },
    {
      label: "Degree",
      name: "degree",
      type: "input",
      placeholder: "NA",
    },
    {
      label: "Comment",
      name: "comments",
      type: "multiline",
      placeholder: "NA",
    }
  ];

  return (
    <Box paddingTop="10px">
      <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4}>
            {
              fields.map(field => {
                if (field.type === "input") {
                  return (<Grid key={field.label} item xs={12} md={6}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => <TextField
                        {...prop.field}
                        label={field.label}
                        className="disable-text"
                        variant={isDisable ? "standard" : "outlined"}
                        disabled={isDisable}
                        placeholder={field.placeholder}
                        size={isDisable ? "medium" : "small"}
                        error={errors[field.name] ? true : false}
                        helperText={errors[field.name]?.message}
                      />}
                    />
                  </Grid>
                  );
                }
                else if (field.type === "multiline") {
                  return (<Grid key={field.label} item xs={12}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => <TextField
                        {...prop.field}
                        label={field.label}
                        className="disable-text"
                        variant={isDisable ? "standard" : "outlined"}
                        disabled={isDisable}
                        placeholder={field.placeholder}
                        size={isDisable ? "medium" : "small"}
                        error={errors[field.name] ? true : false}
                        helperText={errors[field.name]?.message}
                        multiline
                        minRows={4}
                      />}
                    />
                  </Grid>
                  );
                }
                else if (field.type === "auto-complete") {
                  return <Grid key={field.label} item xs={12} md={6}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => <Autocomplete
                        disablePortal
                        fullWidth
                        disabled={isDisable}
                        className="disable-text"
                        options={colleges}
                        getOptionLabel={(option) => (option.name)}
                        renderInput={(params) => <TextField
                          {...prop.field}
                          {...params}
                          error={errors.collegeName ? true : false}
                          helperText={errors.collegeName ? errors.collegeName.message : ""}
                          size={isDisable ? "medium" : "small"}
                          variant={isDisable ? "standard" : "outlined"}
                          label="Colleges"
                        />}
                        value={colleges.find(college => college.name === getValues("collegeName")) || null}
                        onChange={onChangeAutoComplete}
                      />}
                    />
                  </Grid>;
                }else {
                  return (<Grid key={field.label} item xs={12} md={6}>
                    <Select
                      control={control}
                      className="disable-text"
                      name={field.name}
                      label={field.label}
                      disabled={isDisable}
                      size={isDisable ? "medium" : "small"}
                      variant={isDisable ? "standard" : "outlined"}
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                    >
                      {field.children}
                    </Select>
                  </Grid>
                  );
                }
              })
            }
          </Grid>
        {
          !isDisable &&
          <>
            <Box className="action-box">
              <Divider sx={{ marginBottom: "20px" }} />
              <Button variant="outlined" onClick={() => setIsDisable(true)}>Cancel</Button>
              <Button type="submit">Save</Button>
            </Box>
          </>
        }
      </form>
    </Box>
  );
};

export default InternCareers;