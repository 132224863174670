import { Box, Typography } from "@mui/material";
import React, { FC } from "react";

interface props {
    name: string;
    count: number;
    className?: string;
    icon: JSX.Element
    iconColor: "blue" | "green" | "red"
}

const Tag: FC<props> = ({ className, name, count, icon, iconColor }) => (
    <div
        className={`count-box ${className}`}
    >
        <Box display="flex" alignItems="start" justifyContent="space-between">
        <Typography className="cards-title" variant="body1">{name}</Typography>
        <div className={`icon ${iconColor}`}>{icon}</div>
        </Box>
        <Typography className="analytics" variant="h4">
            {count || 0}
        </Typography>
    </div>
);

export default Tag;