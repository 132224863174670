import { Badge, Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import ReportService from "../../../services/report";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState, MouseEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import FilterListIcon from "@mui/icons-material/FilterList";
import AnalyticsCard from "../components/analytics-card";
import { DonutGraph, BarGraph } from "../../../components/graphs";
import { IJobAvgTimeRow, IReportFilter, IReportFilterDialog } from "../../../interfaces";
import CustomTable from "../../../components/mui/table";
import ReportFilters from "../components/filter";

const averageTimeColumns = [
  {
    id: "jobProfile",
    label: "Job Profile",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    id: "pipelineAverage",
    label: "Average Time Of Active Jobs (In Days)",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    id: "closingAverage",
    label: "Average Time To Close (In Days)",
    minWidth: 100,
    maxWidth: 100,
  }
];

const Jobs = () => {
  let jobAverageTime: IJobAvgTimeRow[] = [];
  const { jobReport } = ReportService();
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState<IReportFilter>({
    department: [],
    job: [],
    typeOfLead: [],
    assignTo: []
  });
  const [filtersCount, setFiltersCount] = useState(0);
  const getJobReport = useQuery({
    queryKey: ["report-overview", filters],
    queryFn: () => jobReport({ ...filters })
  });
  
  useEffect(() => {
    let filterCount = 0;
    const typeOfLead: { key: string, value: string }[] = searchParams.get("typeOfLead") ? JSON.parse(String(searchParams.get("typeOfLead"))) : [];
    const job: { key: string, value: string }[] = searchParams.get("job") ? JSON.parse(String(searchParams.get("job"))) : [];
    const department: { key: string, value: string }[] = searchParams.get("department") ? JSON.parse(String(searchParams.get("department"))) : [];
    const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
    const assignTo: { key: string, value: string }[] = searchParams.get("assignTo") ? JSON.parse(String(searchParams.get("assignTo"))) : [];
    let createdAt: {
      startDate: string;
      endDate: string;
    } | undefined = undefined;
    if (date?.length) {
      createdAt = {
        startDate: date[0]?.startDate,
        endDate: date[0]?.endDate,
      };
    }

    filterCount += typeOfLead.length ? 1 : 0;
    filterCount += job.length ? 1 : 0;
    filterCount += department.length ? 1 : 0;
    filterCount += assignTo.length ? 1 : 0;
    filterCount += date.length ? 1 : 0;
    setFiltersCount(filterCount);
    setFilters(prev => ({
      ...prev,
      job: job.map((item) => item.key),
      assignTo: assignTo.map((item) => item.key),
      department: department.map((item) => item.value),
      typeOfLead: typeOfLead.map((item) => item.key),
      date: createdAt
    }));
  }, [searchParams]);

  const [state, setState] = useState<IReportFilterDialog>({
    filterDialog: {
      anchorEl: null,
      isOpen: false
    }
  });
  
  const openFilter = (e: MouseEvent<HTMLButtonElement>) => {
    const anchorElement = e.currentTarget;

    setState((prevState) => ({
        ...prevState,
        filterDialog: {
            ...prevState.filterDialog,
            anchorEl: anchorElement,
            isOpen: !state.filterDialog.isOpen
        }
    }));
};

  const closeFilter = () => {
    setState((prevState) => ({
        ...prevState,
        filterDialog: {
            ...prevState.filterDialog,
            isOpen: false
        }
    }));
};
  const countData = [
    {
      title: "Active jobs",
      subtitle: "",
      isMoreSubtitle: true,
      subtitle1: "No. of active jobs",
      count1: getJobReport.data?.data?.totalOpenJobs || 0,
      subtitle2: "No. of job openings",
      count2: getJobReport.data?.data?.totalOpenings || 0,
    },
    {
      title: "Closed jobs",
      subtitle: "No. of jobs closed",
      count: getJobReport.data?.data?.totalCloseJobs || 0,
      isMoreSubtitle: false,
    },
    {
      title: "Avg. time to hire",
      subtitle: "Average time taken to hire in days",
      count: `${getJobReport.data?.data?.hiredAverage || 0} days`,
      isMoreSubtitle: false,
    },
    {
      title: "Avg. time to reject",
      subtitle: "Average time taken to reject in days",
      count: `${getJobReport.data?.data?.rejectAverage || 0} days`,
      isMoreSubtitle: false,
    },
  ];

  const showData = (label: string) => {
    const multiColor = ["rgba(153, 102, 255, 0.3)", "rgba(54, 162, 235, 0.3)", "rgba(255, 99, 132, 0.3)", "rgba(255, 206, 86, 0.3)", "rgba(75, 192, 192, 0.2)", "rgba(254, 234, 230, 0.9)", "rgba(98, 54, 114, 0.3)", "rgba(54, 114, 61, 0.3)"];
    const data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: ["rgba(17, 140, 252, 0.3)"],
        },
      ],
    };

    const sourceLabels: string[] = [];
    const sourceCount: number[] = [];
    if (getJobReport.data?.data?.sourceImport) {
      getJobReport.data?.data?.sourceImport.forEach(candidate => {
        sourceLabels.push(candidate._id);
        sourceCount.push(candidate.count);
      });
    }

    const importLabels: string[] = [];
    const importCount: number[] = [];
    if (getJobReport.data?.data?.sourceApplied) {
      getJobReport.data?.data?.sourceApplied.forEach(candidate => {
        importLabels.push(candidate._id);
        importCount.push(candidate.count);
      });
    }

    const interviewLabels: string[] = [];
    const interviewCount: number[] = [];
    if (getJobReport.data?.data?.interviewScheduled) {
      Array.isArray(getJobReport.data?.data?.interviewScheduled) && getJobReport.data?.data?.interviewScheduled.forEach(candidate => {
        interviewLabels.push(candidate._id);
        interviewCount.push(candidate.count);
      });
    }

    if (label === "sourceImport") {
      return {
        ...data,
        labels: sourceLabels,
        datasets: [
          {
            label: "Candidates",
            data: sourceCount,
            backgroundColor: ["rgba(17, 140, 252, 0.3)"],
          },
        ],
      };
    } else if (label === "sourceApplied") {
      return {
        ...data,
        labels: importLabels,
        datasets: [
          {
            label: "Candidates",
            data: importCount,
            backgroundColor: multiColor,
          },
        ],
      };
    } else if (label === "interviewScheduled") {
      return {
        ...data,
        labels: interviewLabels,
        datasets: [
          {
            label: "Candidates",
            data: interviewCount,
            backgroundColor: ["rgba(17, 140, 252, 0.3)"],
          },
        ],
      };
    } else {
      return data;
    }
  };

  if (getJobReport.data?.data?.averageToCloseJob) {
    getJobReport.data?.data?.averageToCloseJob?.map(job => {
      jobAverageTime.push(
        {
          jobProfile: job._id,
          closingAverage: job.closingAverage
        }
      );
    });
  }

  if (getJobReport.data?.data?.averageJobPipeline) {
    getJobReport.data?.data?.averageJobPipeline?.forEach(job => {
      let isExist = false;
      jobAverageTime.forEach((avgJob, index) => {
        if (job._id === avgJob.jobProfile) {
          isExist = true;
          jobAverageTime[index].pipelineAverage = job.pipelineAverage;
        }
      });
      if (!isExist) {
        jobAverageTime.push(
          {
            jobProfile: job._id,
            pipelineAverage: job.pipelineAverage
          }
        );
      }
    });
  }

  if( jobAverageTime.length ) {
    jobAverageTime = jobAverageTime.map(job =>{
      if ( !job.pipelineAverage ) {
        job.pipelineAverage = "N/A";
      } else if (!job.closingAverage) {
        job.closingAverage = "N/A";
      }

      return job;
    });
  }

  return (
    <>
      {/* Show Data  */}
      <Box marginTop="10px" height="calc(100vh - 145px)">
        <Box className="tab-header" marginBottom="30px">
          <Box className="tab-header-box">
            <Box display="flex" alignItems="center" className="filter-box">
              <Tooltip title='Filter'>
                <Badge
                  className="h-100 ml-2"
                  color="primary"
                  badgeContent={filtersCount}
                  invisible={!filtersCount}
                >
                  <Button
                    className={filtersCount ? "filter active" : "filter default"}
                    variant='outlined'
                    onClick={openFilter}
                  >
                    <FilterListIcon />
                  </Button>
                </Badge>
              </Tooltip>
            </Box>
            <ReportFilters
              anchorEl={state.filterDialog.anchorEl}
              isOpen={state.filterDialog.isOpen}
              OnClose={closeFilter}
            />
          </Box>
        </Box> 
        <Box marginTop="16px" height="calc(100% - 70px)" overflow="auto">
          <Grid container spacing={2}>
            {/* show count  */}
            {
              countData.map((info, index) => (
                <Grid key={index} item xs={6} md={4} lg={3}>
                  <AnalyticsCard
                    index={index}
                    title={info.title}
                    subtitle={info.subtitle}
                    count={info.count}
                    isMoreSubtitle={info.isMoreSubtitle}
                    primarySubTitle={info.subtitle1}
                    count1={info.count1}
                    secondarySubTitle={info.subtitle2}
                    count2={info.count2}
                  />
                </Grid>
              ))
            }
          </Grid>

          <Box marginTop="16px">
            <Grid container spacing={2}>
              {/* graph  */}
              <Grid item md={6}>
                <BarGraph
                  label="Candidates Imported"
                  data={showData("sourceImport")}
                  height={390}
                />
              </Grid>
              <Grid item md={6}>
                <DonutGraph
                  label="Candidates Applied"
                  data={showData("sourceApplied")}
                  height={390}
                />
              </Grid>
              <Grid item md={12}>
                <Box className="report-container">
                  <Typography className="mb-1" variant="h6">
                  Job Average Time
                  </Typography>
                  <CustomTable
                    columns={averageTimeColumns}
                    rows={jobAverageTime}
                    width={"calc(100% - 2px)"}
                    height="100%"
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <BarGraph
                  label="Interviews Scheduled"
                  data={showData("interviewScheduled")}
                  height={390}
                />
              </Grid>
            </Grid>
          </Box>

        </Box>
      </Box>
    </>
  );
};
export default Jobs;