import HttpService from "../http";
import { ICmsCategoryResponse, ICmsPlannedVsActualResponse, ICmsAssignedBlogResponse, ICmsOverviewResponse, ICmsKeywordResponse, ICmsTopPerformanceResponse, ICmsWeeklyPerformanceResponse, ICmsFunnelResponse, IBlogGoogleAnalyticsResponse } from "../../interfaces";
import { cmsReport } from "../endpoints";

const CmsReportService = () => {
    const { httpRequest } = HttpService();
    const cmsOverviewStats = async (search: object): Promise<ICmsOverviewResponse> => new Promise((resolve, reject) => {
        httpRequest<ICmsOverviewResponse>(
            "GET",
            `${cmsReport}/overview`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const cmsFunnelStats = async (search: object): Promise<ICmsFunnelResponse> => new Promise((resolve, reject) => {
        httpRequest<ICmsFunnelResponse>(
            "GET",
            `${cmsReport}/count-blog-status`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const plannedVsActualReport = async (search: object): Promise<ICmsPlannedVsActualResponse> => new Promise((resolve, reject) => {
        httpRequest<ICmsPlannedVsActualResponse>(
            "GET",
            `${cmsReport}/planned-vs-actual`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const assignedBlogsStats = async (search: object): Promise<ICmsAssignedBlogResponse> => new Promise((resolve, reject) => {
        httpRequest<ICmsAssignedBlogResponse>(
            "GET",
            `${cmsReport}/assigned`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const blogGoogleAnalytics = async (search: object): Promise<IBlogGoogleAnalyticsResponse> => new Promise((resolve, reject) => {
        httpRequest<IBlogGoogleAnalyticsResponse>(
            "GET",
            `${cmsReport}/blog-google-analytics`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const keywordStats = async (search: object): Promise<ICmsKeywordResponse> => new Promise((resolve, reject) => {
        httpRequest<ICmsKeywordResponse>(
            "GET",
            `${cmsReport}/keywords`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const categoryStats = async (search: object): Promise<ICmsCategoryResponse> => new Promise((resolve, reject) => {
        httpRequest<ICmsCategoryResponse>(
            "GET",
            `${cmsReport}/category`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const topPerformanceStats = async (search: object): Promise<ICmsTopPerformanceResponse> => new Promise((resolve, reject) => {
        httpRequest<ICmsTopPerformanceResponse>(
            "GET",
            `${cmsReport}/top-performance`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const weeklyPerformanceStats = async (search: object): Promise<ICmsWeeklyPerformanceResponse> => new Promise((resolve, reject) => {
        httpRequest<ICmsWeeklyPerformanceResponse>(
            "GET",
            `${cmsReport}/weekly-performance`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });
    return { cmsOverviewStats, cmsFunnelStats, topPerformanceStats, plannedVsActualReport, assignedBlogsStats, keywordStats, categoryStats,weeklyPerformanceStats, blogGoogleAnalytics };
};

export default CmsReportService;