import { FC, useState } from "react";
import { ISkillItem, ISkillUserMappingRows } from "../../../../../interfaces";
import { UsersService } from "../../../../../services/configuration/user";
import { useQuery } from "@tanstack/react-query";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, DialogActions, Divider, FormControlLabel, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomTable from "../../../../../components/mui/table";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../../components/shared/mui-tabs";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { capitalize, formatDate } from "../../../../../utilities/helper";

interface props {
  setActiveAction: (e: boolean) => void;
  activeAction: boolean;
}

const UserSkillMatrices: FC<props> = ({ setActiveAction, activeAction }) => {
  const { id } = useParams();
  const { snackbar } = useSnackbar();
  let rows : ISkillUserMappingRows[] = [];
  const [selectedMatrix, setSelectedMatrix] = useState<string>(""); 
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedSkillMatrices, setSelectedSkillMatrices] = useState<string[]>([]);

  const { getUserAssignedSkillMatrices, getUserAssignedSkillMatricesCategories, getUserAssignedSkillMatricesCategoriesItems, getAllUnassignedSkillMatrices, assignSkillMatrix } = UsersService();

  const userSkillMatrixData = useQuery({
    queryKey: ["user-skill-matrices"],
    queryFn: () =>
      getUserAssignedSkillMatrices({
        _user: id,
      }),
  });
  const userSkillMatrixCategoriesData = useQuery({
    queryKey: ["user-skill-matrices-categories", selectedMatrix],
    queryFn: () =>
      getUserAssignedSkillMatricesCategories({
        _skillParameter: selectedMatrix,
      }),
    enabled: !!selectedMatrix, 
  });

  const userSkillMatrixItemsData = useQuery({
    queryKey: ["user-skill-matrices-items", selectedCategory],
    queryFn: () =>
      getUserAssignedSkillMatricesCategoriesItems({
        _skillCategory: selectedCategory,
        _skillParam: selectedMatrix,
        _user: id
      }),
    enabled: !!selectedCategory, 
  });

  const unassignedSkillMatrices = useQuery({
    queryKey: ["unassignedSkillMatrices"],
    queryFn: () => getAllUnassignedSkillMatrices({ _user: id }),
    enabled: !!activeAction
  });

  const handleAssignment = async () => {
    try {
      await assignSkillMatrix({
        _skillParams: selectedSkillMatrices,
        _user: id,
      });
      setSelectedSkillMatrices([]);
      setActiveAction(false);
      userSkillMatrixData.refetch();
      snackbar("Skill matrix assigned", "info");
    } catch (error) {
      console.log("Error in assignment delete", error);
    }
  };

  const skillMatrices = userSkillMatrixData?.data?.data || [];
  const categories = userSkillMatrixCategoriesData?.data?.data || [];
  const items = userSkillMatrixItemsData?.data?.data;

  const createRow = (item: ISkillItem) => ({
    skillItem: item._itemId.name,
    score: item.nextScore,
    updatedAt: item.updatedOn && formatDate(item.updatedOn) || "",
    updatedBy: item.updatedBy && item.updatedBy.name && capitalize(item.updatedBy.name) || "",
  });

  if (items) {
    rows = items && items[0].skillItems && items[0].skillItems.map((item) =>
      createRow(item)
    );
  }

  const columns = [
    {
      id: "skillItem",
      label: "Skill Item",
    },
    {
      id: "score",
      label: "Score",
    },
    {
      id: "updatedAt",
      label: "Updated At",
    },
    {
      id: "updatedBy",
      label: "Updated By",
    }
  ];

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, skillId: string) => {
    if (event.target.checked) {
      setSelectedSkillMatrices((prevSelectedSkillMatrices) => [...prevSelectedSkillMatrices, skillId]);
    } else {
      setSelectedSkillMatrices((prevSelectedSkillMatrices) =>
        prevSelectedSkillMatrices.filter((id) => id !== skillId)
      );
    }
  };

  return (
    <>
      <Box paddingTop="10px">
        <Typography variant="h6" fontWeight="bold" paddingBottom="10px">
          Assigned Skill Matrices -{" "}
        </Typography>
        {skillMatrices.length > 0 ? (
          skillMatrices.map((matrix) => (
            <Accordion
              sx={{ border: "1px solid #ccc", marginBottom: "10px" }}
              key={matrix._id}
              expanded={selectedMatrix === matrix._id}
              onChange={() => {
                selectedMatrix === matrix._id
                  ? setSelectedMatrix("")
                  : setSelectedMatrix(matrix._id);
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${matrix._id}-content`}
                id={`panel-${matrix._id}-header`}
              >
                <Typography>{matrix.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {selectedMatrix === matrix._id && categories.length > 0 && (
                  <Box>
                    {categories.map((category) => (
                      <Accordion
                        sx={{ border: "1px solid #ccc", marginBottom: "10px" }}
                        key={category._id}
                        onChange={() => {selectedCategory === category._id ? setSelectedCategory("") : setSelectedCategory(category._id);}}
                        expanded={selectedCategory === category._id}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel-${category._id}-content`}
                          id={`panel-${category._id}-header`}
                        >
                          <Typography>{category.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <CustomTable
                            columns={columns}
                            rows={rows}
                            height="400px"
                          />
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Box>
                )}

                {selectedMatrix === matrix._id &&
                  categories.length === 0 &&
                  !userSkillMatrixCategoriesData.isFetching && (
                    <Typography>No categories found.</Typography>
                  )}
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Typography>No skill matrices assigned.</Typography>
        )}
      </Box>
      <BootstrapDialog
        maxWidth="sm"
        onClose={() => {
          setActiveAction(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={activeAction}
        scroll="paper"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setActiveAction(false);
          }}
        >
          {"Please select the skill matrices you want to assign."}
        </BootstrapDialogTitle>

        <Box sx={{ width: "100%" }}>
          <Divider />
          <Box p={3} sx={{ maxHeight: "300px", overflowY: "auto" }}>
            {unassignedSkillMatrices?.data?.data?.length ? (
              unassignedSkillMatrices.data.data.map((item) => (
                <Box key={item._id}>
                  <FormControlLabel
                    key={item._id}
                    control={
                      <Checkbox
                        onChange={(event) =>
                          handleCheckboxChange(event, item._id)
                        }
                        name={item.name}
                      />
                    }
                    label={item.name}
                  />
                </Box>
              ))
            ) : (
              <Typography>No skill matrices available</Typography>
            )}
          </Box>
        </Box>

        <Divider />
        <DialogActions style={{ marginBottom: "10px", marginTop: "10px" }}>
          <Button onClick={() => setActiveAction(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleAssignment}
            color="primary"
            disabled={selectedSkillMatrices.length === 0}
          >
            Assign
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default UserSkillMatrices;

