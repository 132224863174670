import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { InterviewService } from "../../../services";
import { ICandidateInterviewRescheduleExtra, IErrorResponse } from "../../../interfaces";
import { joiResolver } from "@hookform/resolvers/joi";
import { Controller, useForm } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Box, MenuItem, Grid, TextField, Divider, DialogContent, Typography, Button } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { InterviewRescheduleValidation } from "../../../validations";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { IRescheduleInterviewField, IInterview } from "../../../interfaces";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import dayjs from "dayjs";
import Select from "../../../components/mui/select";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomDialog from "../../../components/mui/dialog";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../components/shared/mui-tabs";

interface outletProps {
    reFetch: () => void
}

const RescheduleInterview = () => {
    const { id } = useParams();
    const [searchParam] = useSearchParams();
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();
    const { rescheduleInterviews, getInterview } = InterviewService();
    const { handleSubmit, control, setValue, trigger, formState: { errors } } = useForm<IInterview>({
        resolver: joiResolver(InterviewRescheduleValidation),
        defaultValues: {
            title: "",
            interviewDateTime: "",

        }
    });

    const [open, setOpen] = useState(false);
    const [interviewScheduleForcefully, setInterviewScheduleForcefully] = useState<ICandidateInterviewRescheduleExtra>({} as ICandidateInterviewRescheduleExtra);

    const getInterviewDetail = useQuery({
        queryKey: ["interview"],
        queryFn: () => getInterview({
            _id: id
        })
    });


    const handleClose = () => {
        setOpen(false);
    };

    const onStatusUpdate = async () => {
        const interviewScheduled = await rescheduleInterviews(id ? id : "", interviewScheduleForcefully);
        navigate("/assessments/interview/upcoming");
        snackbar(interviewScheduled.message, "info");
        outlet.reFetch();
    };

    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (getInterviewDetail.data?.data) {
            setValue("title", getInterviewDetail.data?.data.title);
        }
    }, [getInterviewDetail.data]);

    const onSubmit = async (data: IInterview) => {
        try {
            if (id) {
                const selectedTime = dayjs(data.interviewDateTime);
                const currentTime = dayjs();
                if (selectedTime.isBefore(currentTime, "minute")) {
                    snackbar("Interview time cannot be in the past. Please select a current or future time", "warning");
                    return;
                }
                setInterviewScheduleForcefully({
                    ...data,
                    _id: id,
                    interviewScheduleForcefully: true
                });
                const payload = { ...data, _id: id };
                const reschedule = await rescheduleInterviews(id, payload);
                snackbar(reschedule.message, "info");
                onClose();
                outlet?.reFetch && outlet.reFetch();
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            if (err?.data?.message === "Slot already booked") {
                handleOpen();
            }
            console.log(error);
        }
    };

    // eslint-disable-next-line
    const selectDate = (value: Date | null) => {
        const date = value && dayjs(value) ? dayjs(value)?.toISOString() : undefined;
        setValue("interviewDateTime", date);
        trigger("interviewDateTime");
    };

    const onClose = () => {
        navigate({
            pathname: "/assessments/interview/upcoming",
            search: searchParam.toString()
        });
    };

    const fields: IRescheduleInterviewField[] = [
        {
            label: "Round",
            name: "title",
            type: "select",
            children: [
                <MenuItem key={"HR SCHEDULED"} value="HR SCHEDULED">HR Scheduled</MenuItem>,
                <MenuItem key={"TA SCHEDULED"} value="TA SCHEDULED">TA Scheduled</MenuItem>,
                <MenuItem key={"ROHIT SCHEDULED"} value="ROHIT SCHEDULED">Rohit Scheduled</MenuItem>
            ]
        },
        {
            label: "Date*",
            name: "interviewDateTime",
            type: "date",
        },
    ];

    return (
        <>
            <Box>
                <CustomDialog
                    title={"Reschedule Interview"}
                    isOpen={id ? true : false}
                    onClose={onClose}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Grid container spacing={4}>
                        {
                            fields.map(field => {
                                if (field.type === "input") {
                                    return (<Grid key={field.label} item xs={12} md={6}>
                                        <Controller
                                            control={control}
                                            name={field.name}
                                            render={(prop) => <TextField
                                                label={field.label}
                                                className="disable-text"
                                                variant="outlined"
                                                size="small"
                                                error={errors[field.name] ? true : false}
                                                helperText={errors[field.name]?.message}
                                                {...prop.field}
                                            />}
                                        />
                                    </Grid>
                                    );
                                } else if (field.type === "date") {
                                    return (<Grid key={field.label} item xs={12} md={6}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDateTimePicker
                                                label={field.label}
                                                onChange={selectDate}
                                                slotProps={{
                                                    textField: {
                                                        error: errors[field.name] ? true : false,
                                                        helperText: errors[field.name]?.message
                                                    }
                                                }}
                                                format="LLL"
                                                shouldDisableDate={(date) => dayjs(date).isBefore(dayjs(), "day")}
                                            />
                                        </LocalizationProvider>

                                    </Grid>
                                    );
                                }
                                else {
                                    return (<Grid key={field.label} item xs={12} md={6}>
                                        <Select
                                            control={control}
                                            name={field.name}
                                            label={field.label}
                                            size="small"
                                            variant="outlined"
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message}
                                        >
                                            {field.children}
                                        </Select>
                                    </Grid>
                                    );
                                }
                            })
                        }
                    </Grid>
                </CustomDialog>
            </Box>

            <BootstrapDialog
                open={open}
                onClose={handleClose}
                style={{ height: "450px" }}
            >
                <BootstrapDialogTitle
                    onClose={onClose} />
                <Divider sx={{ marginTop: "10px" }} />
                <DialogContent>
                    <Typography sx={{ height: "120px" }}>The slot is already booked. Are you sure you want to schedule the interview forcefully?</Typography>
                    <Box className="action-box">
                        <Divider sx={{ marginBottom: "10px" }} />
                        <Button onClick={() => onStatusUpdate()}>Schedule</Button>
                        <Button onClick={handleClose}>Cancel</Button>
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        </>
    );

};

export default RescheduleInterview;