import HttpService from "../../http";
import { IVendorsPaymentModeResponse, IVendorsPaymentModesResponse } from "../../../interfaces/vendor/payment-mode";
import { vendorPaymentMode } from "../../endpoints";

const VendorPaymentModeService = () => {
    const { httpRequest } = HttpService();

    const addPaymentMode = async (payload: object): Promise<IVendorsPaymentModesResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsPaymentModesResponse>(
            "POST",
            `${vendorPaymentMode}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updatePaymentMode = async (payload: object): Promise<IVendorsPaymentModeResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsPaymentModeResponse>(
            "PUT",
            `${vendorPaymentMode}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const deletePaymentMode = async (search: object): Promise<IVendorsPaymentModeResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsPaymentModeResponse>(
            "DELETE",
            `${vendorPaymentMode}`,
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getPaymentModes = async (search: object): Promise<IVendorsPaymentModesResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsPaymentModesResponse>(
            "GET",
            `${vendorPaymentMode}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getPaymentMode = async (search: object): Promise<IVendorsPaymentModeResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsPaymentModeResponse>(
            "GET",
            `${vendorPaymentMode}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    return { addPaymentMode, updatePaymentMode, deletePaymentMode, getPaymentMode, getPaymentModes };
};

export { VendorPaymentModeService };