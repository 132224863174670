import { FC, ReactNode } from "react";
import { Box,Button} from "@mui/material";
import "./style.scss";
export interface InlineDialogProps {
  disabled?: boolean;
  confirmText?: string;
  onConfirm?: () => void;
  onSubmit?: () => void;
  onClose: () => void;
  children: ReactNode;
}

const InlineDialog: FC<InlineDialogProps> = ({
  disabled,
  confirmText,
  onConfirm,
  onSubmit,
  children,
  onClose,
}) => (
  <Box className="form-border">
      <form id="inline-dialog-form" onSubmit={onSubmit}>
        <Box>{children}</Box>
        {!disabled && (
          <Box className="inline-dialog-footer">
            <Button type="button" onClick={onClose}>
              Discard
            </Button>
            <Button type="submit" autoFocus onClick={() => onConfirm && onConfirm()}>
              {confirmText ? confirmText : "Save changes"}
            </Button>
          </Box>
        )}
      </form>
  </Box>
);

InlineDialog.defaultProps = {
  disabled: false,
};

export default InlineDialog;
