import Joi from "joi";
import { required } from "../../shared";

export const resourceRoleValidation = Joi.object({
    _roles: Joi.array()
        .items(Joi.string())
        .min(1)
        .required()
        .label("Resource")
        .messages({
            "string.empty": required,
            "array.min": "Please select at least one {{#label}}"
        }),
});
