import {
    Menu,
    Box,
    Chip,
    Typography,
    IconButton,
    Grid,
    List,
    Divider,
    ListItemButton,
    ListItemText,
    Checkbox,
    Button,
  } from "@mui/material";
  import { FC, useState } from "react";
  import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
  import CloseIcon from "@mui/icons-material/Close";
  import { MenuItem } from "../../../components/shared/filter";
  import { useSearchParams } from "react-router-dom";
  import { useNavigate } from "react-router-dom";

  interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    onClose: () => void;
  }
  

interface IStateFilter {
  key: string;
  value: string;
}

interface IHomeFilterState {
  selectedMenu: number;
  status: IStateFilter[]
}

const stateFilters: IStateFilter[] = [
  { key: "UPCOMING", value: "Upcoming" },
  { key: "PAST", value: "Pending" },
  { key: "COMPLETED", value: "Completed" },
];


const CmsActivityFilter: FC<props> = ({ anchorEl, isOpen, onClose }) => {
  const [state, setState] = useState<IHomeFilterState>({
    selectedMenu:0,
    status: [],
  });
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const resetFilter = () => {
    setState({
      selectedMenu: 0,
      status: [],
    });
  };

  const onApply = () => {
    searchParams.set("status", JSON.stringify(state.status));
    searchParams.set("page", "1");
    navigate({
      pathname: "/home",
      search: searchParams.toString(),
    });
    onClose();
  };

  const deleteChip = (name: "status", key: string) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];

    payload = state[name].filter((ele) => ele.key !== key);
    setState((prevState) => ({
      ...prevState,
      [name]: payload,
    }));
  };

  const handleLeftListItem = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setState((prevState) => ({
      ...prevState,
      selectedMenu: index,
    }));
  };

  const handleRightListItem = (
    name: "status",
    key: string,
    value: string
  ) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];

    const isExist = state[name].find((ele) => ele.key === key) ? true : false;
    if (isExist) {
      payload = state[name].filter((ele) => ele.key !== key);
    } else {
      payload = state[name];
      payload.push({
        key,
        value,
      });
    }

    setState((prevState) => ({
      ...prevState,
      [name]: payload,
    }));
  };

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box id="filters-container">
          <Box
            className="center mb-3"
            justifyContent="space-between"
            alignItems="start"
          >
            <div className="active-filter mb-1">
              {state.status.length ? (
                <>
                  {state.status.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("status", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                </>
              ) : (
                <Box
                  className="mt-2"
                  display="flex"
                  alignItems="center"
                >
                  <FilterAltOffIcon />
                  <Typography className="ml-2">
                    No filters are applied
                  </Typography>
                </Box>
              )}
            </div>
            <IconButton
              onClick={onClose}
              style={{ marginRight: "-10px" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Grid
            className="filter-box"
            container
          >
            <Grid
              id="left"
              item
              xs={5}
            >
              <List component="nav">
                <MenuItem
                  index={0}
                  label="Task Status"
                  selectedMenu={state.selectedMenu === 0}
                  onChange={handleLeftListItem}
                  count={state.status}
                />
              </List>
            </Grid>
            <Divider orientation="vertical" />

            <Grid
              id="right"
              item
              xs={6}
            >
              <List component="nav">
                {state.selectedMenu === 0 &&
                  stateFilters.map((stateFilter) => (
                    <ListItemButton
                      key={stateFilter.key}
                      selected={
                        state.status.find((ele) => ele.key === stateFilter.key)
                          ? true
                          : false
                      }
                      onClick={() =>
                        handleRightListItem(
                          "status",
                          stateFilter.key,
                          stateFilter.value
                        )
                      }
                    >
                      <ListItemText primary={stateFilter.value} />
                      <Checkbox
                        edge="end"
                        checked={
                          state.status.find(
                            (ele) => ele.key === stateFilter.key
                          )
                            ? true
                            : false
                        }
                      />
                    </ListItemButton>
                  ))}
              </List>
            </Grid>
          </Grid>

          <Box
            className="actions-btn"
            marginTop="12px"
            textAlign="end"
          >
            <Button
              variant="outlined"
              color="error"
              onClick={() => resetFilter()}
            >
              Clear All
            </Button>
            <Button
              className="ml-2"
              onClick={onApply}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default CmsActivityFilter;
