import { combineReducers } from "redux";
import { userReducer } from "./configuration/user";
import { roleReducer } from "./configuration/role";
import { statusReducer } from "./configuration/status";
import { engagementTypeReducer } from "./configuration/engagementType";
import { jobPositionReducer } from "./configuration/jobPosition";
import { teamReducer } from "./configuration/team";
import { jobTitleReducer } from "./configuration/jobTitle";
import { collegeReducer } from "./configuration/college";
import { templateReducer } from "./configuration/template";
import { jobReducer } from "./careers";
import { cpdDriveReducer } from "./cpd";
import {cmsProductReducer} from "./cms/product";

const reducer = combineReducers({
    job: jobReducer,
    user: userReducer,
    role: roleReducer,
    team: teamReducer,
    engagementType: engagementTypeReducer,
    jobPosition: jobPositionReducer,
    jobTitle: jobTitleReducer,
    status: statusReducer,
    template: templateReducer,
    college: collegeReducer,
    cpdDrive: cpdDriveReducer,
    cmsProduct: cmsProductReducer
});

export { reducer };