import "./style.scss";
import { Box, Button, Tab, Tabs } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { BootstrapDialog, BootstrapDialogTitle, TabPanelProps, a11yProps } from "../../../../components/shared/mui-tabs";
import DialogContent from "@mui/material/DialogContent";
import { useQuery } from "@tanstack/react-query";
import { cpdDriveService } from "../../../../services";
import CpdCollegeDetail from "./college-detail";
import { capitalize } from "../../../../utilities/helper";
import CpdBasicDetail from "./basic-detail";
import Notes from "./notes";
import Timeline from "./timeline";
import AddIcon from "@mui/icons-material/Add";

const redirectionLinks = ["basic-detail", "college-detail", "notes", "timeline"];

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{
                height: "calc(100% - 50px)",
                overflow: "auto",
                marginTop: 10
            }}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

interface SideButtonProps {
    index: number;
    setActiveAction: (e: boolean) => void;
}

const SideButton: FC<SideButtonProps> = ({ index, setActiveAction }) => {
    let label = "";

    if (index === 2) {
        label = "Add Note";
    } else {
        return <></>;
    }

    return <Button
        variant="text"
        size="small"
        startIcon={<AddIcon />}
        onClick={() => setActiveAction(true)}
    >
        {label}
    </Button>;
};

const CpdDriveLayout = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [value, setValue] = useState(0);
    const [activeAction, setActiveAction] = useState<boolean>(false);
    const { getCpdDrive } = cpdDriveService();
    const [searchParam, setSearchParam] = useSearchParams();
    const cpdDrive = useQuery({
        queryKey: ["colleges"],
        queryFn: () => getCpdDrive({ _id: id })
    });

    useEffect(() => {
        const tab = searchParam.get("type") ? String(searchParam.get("type")) : "basic-detail";
        setValue(redirectionLinks.indexOf(tab));
    }, [searchParam]);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        searchParam.set("type", redirectionLinks[newValue]);
        setSearchParam(searchParam);
        setActiveAction(false);
    };

    const onClose = () => {
        searchParam.delete("type");
        navigate({
            pathname: "/cpd/drives",
            search: searchParam.toString()
        });
    };

    const name = cpdDrive.data?.data?.collegeId?.name ? capitalize(cpdDrive.data?.data?.collegeId?.name) : "CPD Actions";

    return <div>
        <BootstrapDialog
            maxWidth="md"
            onClose={onClose}
            open={id ? true : false}
        >
            <BootstrapDialogTitle onClose={onClose}>
                <Box display="flex" alignItems="center">
                    {name}
                </Box>
            </BootstrapDialogTitle>
            <DialogContent dividers sx={{ height: "67vh" }}>
                <Box width="100%" height="100%" >
                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Box>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab className="tabs-space start-tab-space" label="Basic Detail" {...a11yProps(0)} />
                                <Tab className="tabs-space" label="College Detail" {...a11yProps(1)} />
                                <Tab className="tabs-space" label="Notes" {...a11yProps(1)} />
                                <Tab className="tabs-space" label="Timeline" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        {!activeAction && <SideButton index={value} setActiveAction={setActiveAction} />}
                    </Box>

                    {/* Cpd Basic Detail */}
                    <TabPanel value={value} index={0}>
                        <CpdBasicDetail
                            college={cpdDrive.data?.data}
                            activeAction={activeAction}
                            currentTab={value}
                        />
                    </TabPanel>

                    {/* Cpd College Detail */}
                    <TabPanel value={value} index={1}>
                        <CpdCollegeDetail
                            college={cpdDrive.data?.data}
                            activeAction={activeAction}
                            currentTab={value}
                        />
                    </TabPanel>

                    {/* Notes  */}
                    <TabPanel value={value} index={2}>
                        <Notes
                            college={cpdDrive.data?.data}
                            currentTab={value}
                            activeAction={activeAction}
                            collegeDetailRefetch={cpdDrive.refetch}
                        />
                    </TabPanel>

                    {/* TimeLine  */}
                    <TabPanel value={value} index={3}>
                        <Timeline
                            college={cpdDrive.data?.data}
                        />
                    </TabPanel>
                </Box>
            </DialogContent>
        </BootstrapDialog>
    </div>;
};

export default CpdDriveLayout;
