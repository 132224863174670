import HttpService from "../../http";
import { IJobsResponse, IJobResponse, IGenerateJobDescriptionsResponse, IGenerateJobDescriptions, IJobSlugResponse, IJobActivityResponse, IJobDataResponse } from "../../../interfaces";
import { job } from "../../endpoints";

const JobService = () => {
    const { httpRequest } = HttpService();
    const addJob = async (payload: object): Promise<IJobsResponse> => new Promise((resolve, reject) => {
        httpRequest<IJobsResponse>(
            "POST",
            `${job}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });




    const generateJobDescription = async(payload:IGenerateJobDescriptions):Promise<IGenerateJobDescriptionsResponse> => new Promise((resolve,reject) => {
        httpRequest<IGenerateJobDescriptionsResponse>(
            "POST",
            "ai/search",
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getPartialJobs = async (): Promise<IJobsResponse> => new Promise((resolve, reject) => {
        httpRequest<IJobsResponse>(
            "GET",
            `${job}/list/partial`
        )
            .then(resolve)
            .catch(reject);
    });

    const getJobs = async (search: object, data = {}): Promise<IJobsResponse> => new Promise((resolve, reject) => {
        httpRequest<IJobsResponse>(
            "POST",
            `${job}/list`,
            data,
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getJob = async (query: object): Promise<IJobDataResponse> => new Promise((resolve, reject) => {
        httpRequest<IJobDataResponse>(
            "GET",
            `${job}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const getJobTimeline = async (query: object): Promise<IJobActivityResponse> => new Promise((resolve, reject) => {
        httpRequest<IJobActivityResponse>(
            "GET",
            `${job}/activity`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const getJobSlugSugession = async (query: object): Promise<IJobSlugResponse> => new Promise((resolve, reject) => {
        httpRequest<IJobSlugResponse>(
            "GET",
            `${job}/slug-suggestion`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateJob = async (id: string, payload: object): Promise<IJobResponse> => new Promise((resolve, reject) => {
        httpRequest<IJobResponse>(
            "PUT",
            `${job}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });


    const updateJobStatusAssignTo = async (id: string, payload: object): Promise<IJobResponse> => new Promise((resolve, reject) => {
        httpRequest<IJobResponse>(
            "PUT",
            `${job}/status-assignto`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteJob = async (query: object): Promise<IJobsResponse> => new Promise((resolve, reject) => {
        httpRequest<IJobsResponse>(
            "DELETE",
            `${job}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    return { addJob, getJobs, generateJobDescription,getJob,getJobTimeline,getJobSlugSugession, getPartialJobs, updateJob, updateJobStatusAssignTo, deleteJob };
};

export { JobService };
