import HttpService from "../../http";
import { ICpdDrivesResponse, ICpdDriveResponse, IExportCpdDataResponse, ICpdSampleResponse } from "../../../interfaces/cpd/drive";
import { cpdDrive } from "../../endpoints";

const cpdDriveService = () => {
    const { httpRequest } = HttpService();
    const addCpdDrive = async (payload: object): Promise<ICpdDrivesResponse> => new Promise((resolve, reject) => {
        httpRequest<ICpdDrivesResponse>(
            "POST",
            cpdDrive,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const addCpdDrives = async (payload: object): Promise<ICpdDrivesResponse> => new Promise((resolve, reject) => {
        httpRequest<ICpdDrivesResponse>(
            "POST",
            `${cpdDrive}/create-many`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getCpdDrives = async (search: object): Promise<ICpdDrivesResponse> => new Promise((resolve, reject) => {
        httpRequest<ICpdDrivesResponse>(
            "GET",
            `${cpdDrive}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getCpdDrive = async (query: object): Promise<ICpdDriveResponse> => new Promise((resolve, reject) => {
        httpRequest<ICpdDriveResponse>(
            "GET",
            `${cpdDrive}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateCpdDrive = async (id: string, payload: object): Promise<ICpdDriveResponse> => new Promise((resolve, reject) => {
        httpRequest<ICpdDriveResponse>(
            "PUT",
            `${cpdDrive}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteCpdDrives = async (query: object): Promise<ICpdDrivesResponse> => new Promise((resolve, reject) => {
        httpRequest<ICpdDrivesResponse>(
            "DELETE",
            `${cpdDrive}`,
            {},
            query,
        )
            .then(resolve)
            .catch(reject);
    });

    const addCpdNote = async (payload: object): Promise<ICpdDrivesResponse> => new Promise((resolve, reject) => {
        httpRequest<ICpdDrivesResponse>(
            "POST",
            `${cpdDrive}/note`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updateCpdNote = async (payload: object): Promise<ICpdDrivesResponse> => new Promise((resolve, reject) => {
        httpRequest<ICpdDrivesResponse>(
            "PUT",
            `${cpdDrive}/note`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteCpdNote = async (search: object): Promise<ICpdDrivesResponse> => new Promise((resolve, reject) => {
        httpRequest<ICpdDrivesResponse>(
            "DELETE",
            `${cpdDrive}/note`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const calenderInvite = async (payload: object): Promise<ICpdDrivesResponse> => new Promise((resolve, reject) => {
        httpRequest<ICpdDrivesResponse>(
            "POST",
            `${cpdDrive}/calendar-invite`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const sendEmail = async (payload: object): Promise<ICpdDriveResponse> => new Promise((resolve, reject) => {
        httpRequest<ICpdDriveResponse>(
            "POST",
            `${cpdDrive}/email`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const downloadCpDriveData = async (query: object): Promise<IExportCpdDataResponse> => new Promise((resolve, reject) => {
        httpRequest<IExportCpdDataResponse>(
            "GET",
            `${cpdDrive}/export`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const getCpdSample = async (search: object): Promise<ICpdSampleResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<ICpdSampleResponse>(
                "GET",
                `${cpdDrive}/sample`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });

    return { addCpdDrive, getCpdDrives, getCpdDrive, updateCpdDrive, deleteCpdDrives, addCpdDrives, addCpdNote, updateCpdNote, deleteCpdNote, calenderInvite, sendEmail,downloadCpDriveData, getCpdSample};
};

export { cpdDriveService };
