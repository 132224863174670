import { useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Box, Grid, TextField } from "@mui/material";
import { ICoderByteField, ICoderByteLink, IErrorResponse, ITeam, ITemplate } from "../../../../interfaces";
import CustomDialog from "../../../../components/mui/dialog";
import SearchSelect from "../../../../components/mui/search-select";
import CustomLabel from "../../../../components/mui/custom-label";
import { joiResolver } from "@hookform/resolvers/joi";
import { coderByteLinkValidation } from "../../../../validations";
import { useSelector } from "react-redux";
import { CoderByteLinkService } from "../../../../services";
import { useQuery } from "@tanstack/react-query";
import useSnackbar from "../../../../hooks/useSnackbar";
import { useEffect } from "react";

interface outletProps {
    reFetchCoderByteLinks: () => void;
}


const ManageCoderByteLink = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();
    const [searchParam] = useSearchParams();
    const { getCoderByteLink, addCoderByteLink, updateCoderByteLink } = CoderByteLinkService();
    const templates = useSelector<{ template: { list: ITemplate[] } }, ITemplate[]>(state => state.template.list) || [];
    const teams = useSelector<{ team: { list: ITeam[] } }, ITeam[]>(state => state.team.list) || [];
    const hitQuery = (id === "new" || id === "view") ? false : true;
    const coderByteLink = useQuery({ queryKey: [hitQuery], queryFn: () => getCoderByteLink({ _id: id }), enabled: hitQuery });
    const { handleSubmit, control, setValue, getValues, trigger, formState: { errors } } = useForm<ICoderByteLink>({
        resolver: joiResolver(coderByteLinkValidation),
        defaultValues: {
            link: "",
            department:"",
            template:""
        }
    });

    useEffect(() => {
        if (id !== "new") {
            if (coderByteLink.data?.data) {
                setValue("link", coderByteLink.data.data.link);
                setValue("department", coderByteLink.data.data._department?._id);
                setValue("template", coderByteLink.data.data._template?._id);
                trigger("department");
                trigger("template");
            }
        }
    }, [id, coderByteLink.data]);
    

    const onClose = () => {
        navigate({
            pathname: "/configurations/coder-byte",
            search: searchParam.toString()
        });
    };

    const onSubmit = async (data: ICoderByteLink) => {
        const payload = { 
            ...data,
            _template: data.template,
            _department: data.department 
        };

        delete payload.department;
        delete payload.template;
        try {
            if (id === "new") {
                const add = await addCoderByteLink(payload);
                snackbar(add.message, "info");
                onClose();
                outlet?.reFetchCoderByteLinks && outlet.reFetchCoderByteLinks();
            }
            else {
                const update = await updateCoderByteLink({ ...payload, _id: id });
                snackbar(update.message, "info");
                onClose();
                outlet?.reFetchCoderByteLinks && outlet.reFetchCoderByteLinks();
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }
    };

    const fields: ICoderByteField[] = [
        {
            label: "Link*",
            name: "link",
            type: "input",
            placeholder: "Type logical assessment link here",
            width: 12
        },
        {
            label: "Template",
            name: "template",
            type: "select",
            required: true,
            options: templates.filter(template=>template.tag === "coderbyte"),
            displayFieldKey: "title",
            storeFieldKey: "_id",
            width: 6
        },
        {
            label: "Department",
            name: "department",
            type: "select",
            children: [],
            required: true,
            options: teams,
            displayFieldKey: "name",
            storeFieldKey: "_id",
            capitalize:true,
            width: 6
        },
    ];

    return (
        <Box>
            <CustomDialog
                size="sm"
                title={id !== "new" ? "Edit Logical Assessment Link" : "Add Logical Assessment Link"}
                isOpen={id ? true : false}
                onClose={onClose}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "input") {
                                return (<Grid key={field.label} item xs={12} md={field.width}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={field.label}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            placeholder={field.placeholder}
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            } else {
                                return (<Grid key={field.label} item xs={12} md={field.width}>
                                    <SearchSelect
                                        name={field.name}
                                        label={<CustomLabel label={field.label} required={field?.required} />}
                                        error={errors[field.name] ? true : false}
                                        helperText={errors[field.name]?.message}
                                        options={field.options}
                                        displayFieldKey={field.displayFieldKey ? field.displayFieldKey : ""}
                                        storeFieldKey={field.storeFieldKey ? field.storeFieldKey : ""}
                                        displayUserName={field.displayUserName}
                                        capitalize={field.capitalize}
                                        trigger={trigger}
                                        setValue={setValue}
                                        getValues={getValues}
                                    />
                                </Grid>
                                );
                            }
                        })
                    }
                </Grid>
            </CustomDialog>
        </Box>
    );
};

export default ManageCoderByteLink;