import HttpService from "../../http";
import { IEngagementsResponse, IEngagementResponse } from "../../../interfaces/configuration/engagement";
import { engagement } from "../../endpoints";

const EngagementService = () => {
    const { httpRequest } = HttpService();
    const addEngagement = async (payload: object): Promise<IEngagementsResponse> => new Promise((resolve, reject) => {
        httpRequest<IEngagementsResponse>(
            "POST",
            `${engagement}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });


    const getPartialEngagements = async (): Promise<IEngagementsResponse> => new Promise((resolve, reject) => {
        httpRequest<IEngagementsResponse>(
            "GET",
            `${engagement}/list/partial`,
            {},
            {}
        )
            .then(resolve)
            .catch(reject);
    });

    const getEngagements = async (search: object): Promise<IEngagementsResponse> => new Promise((resolve, reject) => {
        httpRequest<IEngagementsResponse>(
            "GET",
            `${engagement}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getEngagement = async (query: object): Promise<IEngagementResponse> => new Promise((resolve, reject) => {
        httpRequest<IEngagementResponse>(
            "GET",
            `${engagement}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateEngagement = async (id: string, payload: object): Promise<IEngagementResponse> => new Promise((resolve, reject) => {
        httpRequest<IEngagementResponse>(
            "PUT",
            `${engagement}`,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteEngagement = async (query: object): Promise<IEngagementsResponse> => new Promise((resolve, reject) => {
        httpRequest<IEngagementsResponse>(
            "DELETE",
            `${engagement}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });
    return { addEngagement, getEngagements, getPartialEngagements, getEngagement, updateEngagement, deleteEngagement };
};

export { EngagementService };
