import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ChangeEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { IPagination } from "../../interfaces/";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { capitalize, createIndex, formatDate } from "../../utilities/helper";
import { IKpiRow, IKpiState, IKpi } from "../../interfaces/kpi";
import Header from "../../components/header";
import useSnackbar from "../../hooks/useSnackbar";
import CustomTable from "../../components/mui/table";
import useResource from "../../hooks/useResource";
import GetActions from "../../components/get-actions";
import WarningDialog from "../../components/mui/warning-dialog";
import KpiService from "../../services/kpi";
import useDebounce from "../../hooks/useDebounce";

const KPI = () => {
  let rows: IKpiRow[] = [];
  const { getKpis, deleteKpis } = KpiService();
  const { snackbar } = useSnackbar();
  const { resourceAllocate } = useResource();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IKpiState>({
    deleteWarning: false,
    _kpi: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      search: ""
    }
  });

  const getKpiData = useQuery({
    queryKey: ["allKpis", state.pagination.page, state.filters],
    queryFn: () => getKpis({
      pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
    })
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (getKpiData.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: getKpiData.data.meta.page,
          totalPages: getKpiData.data.meta.totalPages,
          totalRecords: getKpiData.data.meta.totalRecords
        }
      }));
    }
  }, [getKpiData.data?.meta]);

  useEffect(() => {
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";

    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search
      }
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleDelete = (_kpi = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _kpi
    }
    ));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteKpis({ _id: state._kpi });
      snackbar(deleted.message, "info");
      handleDelete();
      getKpiData.refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "kpi_recruiterName",
      label: "Recruiter Name"
    },
    {
      id: "kpi_jobTitle",
      label: "Job Title"
    },
    {
      id: "kpi_engagementType",
      label: "Engagement Type"
    },
    {
      id: "kpi_target",
      label: "Target"
    },
    {
      id: "kpi_assigning_date",
      label: "Assigning Date"
    },
    {
      id: "kpi_deadline",
      label: "Deadline"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

  const createRow = (index: number, kpi: IKpi, pagination: IPagination) => {
    const action = <GetActions
      icons={[
        { name: "Edit", method: () => navigate({ pathname: "" + kpi._id, search: searchParams.toString() }), disabled: resourceAllocate("kpi.write") ? false : true },
        { name: "Delete", method: () => handleDelete(kpi._id), disabled: resourceAllocate("kpi.remove") ? false : true },
      ]}
    />;

    return {
      id: createIndex(pagination, index),
      kpi_recruiterName: typeof kpi._recruiter !== "string" ? capitalize(`${kpi._recruiter.firstName} ${kpi._recruiter.lastName}`) : "",
      kpi_jobTitle: typeof kpi._jobId !== "string" ? capitalize(kpi._jobId.title) : "",
      kpi_engagementType: typeof kpi._jobId !== "string" ? capitalize(kpi._jobId.type) : "",
      kpi_target: (kpi.target),
      kpi_assigning_date: (formatDate(kpi?.createdAt)),
      kpi_deadline: (formatDate(kpi?.deadline)),
      kpi_completed: (kpi?.completed || 0),
      action,
    };
  };
  if (getKpiData.data?.data.length) {
    rows = getKpiData.data?.data.map((kpi, i) => createRow(i, kpi, state.pagination));
  }

  return (
    <>
      {/* Add Data  */}
      <Header
        searchPlaceholder="Search by recruiter name"
        onSearch={onSearch}
        btnText="Add Target"
        onBtnClick={resourceAllocate("kpi.write") ? () => navigate("new") : undefined}
      />

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 191.3px)"
          errorMessage="Add target to see the data here"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete KPI Target"
        description="Are you sure you want to delete this kpi target?"
      />
      <Outlet context={{ reFetch: getKpiData.refetch }} />
    </>
  );
};
export default KPI;