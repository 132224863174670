import React, { FC, useEffect, useState } from "react";
import {
  Menu,
  Box,
  Typography,
  IconButton,
  Grid,
  List,
  Divider,
  Button,
  ListItemButton,
  Checkbox,
  ListItemText,
  Chip,
} from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { MenuItem } from "../../../../components/shared/filter";

interface Props {
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  onClose: () => void;
}

export interface ICmsReportFilter {
  selectedMenu: number;
  futureQuarters: boolean;
}

const CmsQuarterFilter: FC<Props> = ({ anchorEl, isOpen, onClose }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [state, setState] = useState<ICmsReportFilter>({
    selectedMenu: 0,
    futureQuarters: true,
  });

  const { id } = useParams();

  useEffect(() => {

    const futureQuarters: boolean | null = searchParams.get("futureQuarters") ? searchParams.get("futureQuarters") === "true" : false;

    setState(prevState => ({
      ...prevState,
      futureQuarters,
    }));
  }, [searchParams]);

  const toggleFutureQuarters = () => {
    setState(prevState => ({
      ...prevState,
      futureQuarters: !prevState.futureQuarters,
    }));
  };

  const onApply = () => {
    searchParams.set("futureQuarters", String(state.futureQuarters));
    searchParams.set("page", "1");
    navigate({
      pathname: `/planner/${id}`,
      search: searchParams.toString(),
    });
    onClose();
  };

  const OnClose = () => {
    const futureQuarters: boolean | null = searchParams.get("futureQuarters") ? searchParams.get("futureQuarters") === "true" : false;
    setState(prevState => ({
      ...prevState,
      futureQuarters
  }));
    onClose();
  };

  const resetFilter = () => {
    setState({
      selectedMenu: 0,
      futureQuarters: false,
    });
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <Box id="filters-container">
        <Box
          className="center mb-3"
          justifyContent="space-between"
          alignItems="start"
        >
          <div className="active-filter mb-1">

          {
            (state.futureQuarters) ?
              <>
                <Chip className="m-1" color="primary" onDelete={() => setState({ ...state, futureQuarters: false })} label="Show Future Quarters" variant="outlined" />           
              </>
            :
              <Box className="mt-2" display="flex" alignItems="center">
                <FilterAltOffIcon />
                <Typography className="ml-2">No filters are applied</Typography>
              </Box>
          }

          </div>
          <IconButton onClick={OnClose} style={{ marginRight: "-10px" }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Grid className="filter-box" container>
          <Grid id="left" item xs={5}>
            <List component="nav">
              <MenuItem
                index={0}
                label="Future Quarters"
                selectedMenu={state.selectedMenu === 0}
                onChange={() => setState({ ...state, selectedMenu: 0 })}
                count={state.futureQuarters ? [{ value: "1" }] : []}
              />
            </List>
          </Grid>
          <Divider orientation="vertical" />
          <Grid id="right" item xs={6}>
            <List component="nav">
              {state.selectedMenu === 0 && (
                <>
                  <ListItemButton onClick={toggleFutureQuarters}>
                    <ListItemText primary="Show" />
                    <Checkbox
                      checked={state.futureQuarters}
                    />
                  </ListItemButton>
                </>
              )}
            </List>
          </Grid>
        </Grid>

        <Box className="actions-btn" textAlign="end">
          <Button
            variant="outlined"
            color="error"
            onClick={resetFilter}
          >
            Clear All
          </Button>
          <Button className="ml-2" onClick={onApply}>
            Apply
          </Button>
        </Box>
      </Box>
    </Menu>
  );
};

export default CmsQuarterFilter;
