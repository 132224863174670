import { useForm, Controller } from "react-hook-form";
import { IQuarter, IQuarterField } from "../../../../interfaces/planner";
import InlineDialog from "../../../../components/mui/inlineDialogue";
import { Grid, TextField } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { joiResolver } from "@hookform/resolvers/joi";
import { quarterValidations } from "../../../../validations/planner";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import plannerService from "../../../../services/planner";
import { IErrorResponse } from "../../../../interfaces";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomLabel from "../../../../components/mui/custom-label";

interface IOutletProps {
  reFetch: () => void;
}

const ManageForm = () => {
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<IOutletProps>();
  const location = useLocation();
  const { getQuarter, addQuarter, updateQuarter } = plannerService();
  const id = location.pathname.split("/")[3];

  const moduleId = location.pathname.split("/")[2];


  const hitQuery = id === "new" ? false : true;
  const {
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    setValue,
    trigger,
  } = useForm<IQuarter>({
    resolver: joiResolver(quarterValidations),
    defaultValues: {
      name: "",
      startDate: "",
      endDate: "",
    },
  });

  const quarter = useQuery({
    queryKey: ["hitQuery"],
    queryFn: () => getQuarter({ _id: id }),
    enabled: hitQuery
  });


  const fields: IQuarterField[] = [
    {
      name: "name",
      label: "Title",
      type: "input",
      placeholder: "Enter Quarter Name",
    },
    {
      name: "startDate",
      label: "Start Date",
      type: "select",
    },
    {
      name: "endDate",
      label: "End Date",
      type: "select",
    },
  ];

  useEffect(() => {
    if (id !== "new" && quarter?.data?.data) {
      setValue("name", quarter?.data?.data?.name || "");
      quarter?.data?.data?.startDate && setValue("startDate", quarter?.data?.data?.startDate);
      trigger("startDate");
      quarter?.data?.data?.endDate && setValue("endDate", quarter?.data?.data?.endDate);
      trigger("endDate");
    }
  }, [id, quarter.data]);
  /*eslint-disable-next-line*/
  const onSubmit = async (data: any) => {
    try {
      if (id === "new") {
        const response = await addQuarter({ _productTitle: moduleId, ...data });
        snackbar(response.message, "info");
        navigate(`/planner/${moduleId}`);
        outlet.reFetch && outlet.reFetch();
      } else {
        const response = await updateQuarter({ _id: id, ...data });
        snackbar(response.message, "info");
        navigate(`/planner/${moduleId}`);
        outlet.reFetch && outlet.reFetch();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
      console.log("Error in Adding Goal", error);
    }
  };

 
    // eslint-disable-next-line
    const selectDate = (value: any, name: string) => {
      const date = value && dayjs(value).toString() !== "Invalid Date" ? dayjs(value)?.toISOString() : undefined;
      const keyExist = name === "startDate" || name === "endDate";
      if (keyExist) {
          setValue(name, date ? date : "");
          trigger(name);
      }
  };

  return (
    <><Outlet context={{ ...Outlet}} /><InlineDialog
      onClose={() => navigate(`/planner/${moduleId}`)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={4}>
        {fields.map((field, index) => {
          if (field.type === "input") {
            return (
              <Grid key={index} item xs={4}>
                <Controller
                  name={field.name}
                  control={control}
                  render={(prop) => (
                    <TextField
                      label={field.label}
                      placeholder={field.placeholder}
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                      {...prop.field} />
                  )} />
              </Grid>
            );
          } else {
            return(
            <Grid key={field.label} item xs={4}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker
                                            label={<CustomLabel label={field.label} required={field?.required} />}
                                            onChange={(e) => selectDate(e, field.name)}
                                            value={dayjs(getValues(field.name))}
                                            slotProps={{
                                                textField: {
                                                    error: errors[field.name] ? true : false,
                                                    helperText: errors[field.name]?.message
                                                }
                                            }}
                                            format="LL"
                                        />
                                    </LocalizationProvider>

                                </Grid>
            );
          }
        })}
      </Grid>
    </InlineDialog></>
  );
};

export default ManageForm;
