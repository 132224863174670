import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { TeamsService } from "../../../../services";
import { Box, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { IErrorResponse, ITeam, ITeamField } from "../../../../interfaces";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { teamValidation } from "../../../../validations";
import Select from "../../../../components/mui/select";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import { capitalize } from "../../../../utilities/helper";
interface outletProps {
    reFetch: () => void;
    refetchTeams: () => void;
}

const ManageTeam = () => {
    const { id } = useParams();
    const [searchParam] = useSearchParams();
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();
    const hitQuery = (id === "new" || id === "view") ? false : true;
    const navigate = useNavigate();
    const { getTeam, addTeam, updateTeam } = TeamsService();
    const team = useQuery({ queryKey: [hitQuery], queryFn: () => getTeam({ _id: id }), enabled: hitQuery });
    const { handleSubmit, control, setValue, formState: { errors } } = useForm<ITeam>({
        resolver: joiResolver(teamValidation),
        defaultValues: {
            name: "",
        }
    });

    useEffect(() => {
        if (id !== "new") {
            if (team.data?.data) {
                setValue("name", capitalize(team.data.data?.name) || "");
            }
        }
    }, [id, team.data]);

    const onSubmit = async (data: ITeam) => {
        try {
            if (id === "new") {
                const payload = { ...data };
                const add = await addTeam(payload);
                snackbar(add.message, "info");
                navigate({
                    pathname: "/configurations/departments",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
                outlet?.refetchTeams && outlet.refetchTeams();
            }
            else {
                const payload = { ...data, _id: id };
                const update = await updateTeam(id ? id : "", payload);
                snackbar(update.message, "info");
                navigate({
                    pathname: "/configurations/departments",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
                outlet?.refetchTeams && outlet.refetchTeams();
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }

    };

    const onClose = () => {
        navigate({
            pathname: "/configurations/departments",
            search: searchParam.toString()
        });
    };

    const fields: ITeamField[] = [
        {
            label: "Name*",
            name: "name",
            type: "input",
            placeholder: "Type your department name here",
        },
    ];

    return (
        <Box>
            <CustomDialog
                size='sm'
                title={id !== "new" ? "Edit Department" : "Add Department"}
                isOpen={id ? true : false}
                onClose={onClose}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "input") {
                                return (<Grid key={field.label} item xs={12}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={field.label}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            placeholder={field.placeholder}
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message}
                                            inputProps={{maxLength: 20}}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            } else {
                                return (<Grid key={field.label} item xs={12}>
                                    <Select
                                        control={control}
                                        name={field.name}
                                        label={field.label}
                                        size="small"
                                        variant="outlined"
                                        error={errors[field.name] ? true : false}
                                        helperText={errors[field.name]?.message}
                                    >
                                        {field.children}
                                    </Select>
                                </Grid>
                                );
                            }
                        })
                    }
                </Grid>
            </CustomDialog>
        </Box>
    );
};

export default ManageTeam;