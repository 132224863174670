import HttpService from "../../http";
import { technicalQuestion } from "../../endpoints";
import { ITechnicalQuestionLanguagesResponse, ITechnicalQuestionResponse, ITechnicalQuestionsResponse, ITechnicalQuestionTemplateResponse, ITechnicalQuestionTestCaseResponse } from "../../../interfaces";

const TechnicalQuestionService = () => {
	const { httpRequest } = HttpService();
	const addTechnicalQuestion = async (payload: object): Promise<ITechnicalQuestionResponse> => new Promise((resolve, reject) => {
		httpRequest<ITechnicalQuestionResponse>(
			"POST",
			`${technicalQuestion}`,
			payload
		)
			.then(resolve)
			.catch(reject);
	});

	const addTechnicalQuestionTemplate = async (payload: object): Promise<ITechnicalQuestionResponse> => new Promise((resolve, reject) => {
		httpRequest<ITechnicalQuestionResponse>(
			"POST",
			`${technicalQuestion}/template`,
			payload
		)
			.then(resolve)
			.catch(reject);
	});

	const addTechnicalQuestionTestCase = async (payload: object): Promise<ITechnicalQuestionTestCaseResponse> => new Promise((resolve, reject) => {
		httpRequest<ITechnicalQuestionTestCaseResponse>(
			"POST",
			`${technicalQuestion}/test-case`,
			payload
		)
			.then(resolve)
			.catch(reject);
	});
	const updateTechnicalQuestionTemplate = async (payload: object): Promise<ITechnicalQuestionResponse> => new Promise((resolve, reject) => {
		httpRequest<ITechnicalQuestionResponse>(
			"PUT",
			`${technicalQuestion}/template`,
			payload
		)
			.then(resolve)
			.catch(reject);
	});

	const updateTechnicalQuestionTestCase = async (payload: object): Promise<ITechnicalQuestionTestCaseResponse> => new Promise((resolve, reject) => {
		httpRequest<ITechnicalQuestionTestCaseResponse>(
			"PUT",
			`${technicalQuestion}/test-case`,
			payload
		)
			.then(resolve)
			.catch(reject);
	});
	const getPartialTechnicalQuestions = async (): Promise<ITechnicalQuestionsResponse> => new Promise((resolve, reject) => {
		httpRequest<ITechnicalQuestionsResponse>(
			"GET",
			`${technicalQuestion}/list/partial`,
			{},
			{}
		)
			.then(resolve)
			.catch(reject);
	});

	const getTechnicalQuestionLanguages = async (query: object): Promise<ITechnicalQuestionLanguagesResponse> => new Promise((resolve, reject) => {
		httpRequest<ITechnicalQuestionLanguagesResponse>(
			"GET",
			`${technicalQuestion}/languages`,
			{},
			query
		)
			.then(resolve)
			.catch(reject);
	});

	const getTechnicalQuestionTemplate = async (query: object): Promise<ITechnicalQuestionTemplateResponse> => new Promise((resolve, reject) => {
		httpRequest<ITechnicalQuestionTemplateResponse>(
			"GET",
			`${technicalQuestion}/template`,
			{},
			query
		)
			.then(resolve)
			.catch(reject);
	});

	const deleteTechnicalQuestionTemplate = async (payload: object): Promise<ITechnicalQuestionTemplateResponse> => new Promise((resolve, reject) => {
		httpRequest<ITechnicalQuestionTemplateResponse>(
			"DELETE",
			`${technicalQuestion}/template`,
			payload
		)
			.then(resolve)
			.catch(reject);
	});

	const getTechnicalQuestionTestCase = async (query: object): Promise<ITechnicalQuestionTestCaseResponse> => new Promise((resolve, reject) => {
		httpRequest<ITechnicalQuestionTestCaseResponse>(
			"GET",
			`${technicalQuestion}/test-case`,
			{},
			query
		)
			.then(resolve)
			.catch(reject);
	});

	const deleteTechnicalQuestionTestCase = async (payload: object): Promise<ITechnicalQuestionTestCaseResponse> => new Promise((resolve, reject) => {
		httpRequest<ITechnicalQuestionTestCaseResponse>(
			"DELETE",
			`${technicalQuestion}/test-case`,
			payload
		)
			.then(resolve)
			.catch(reject);
	});
	const getTechnicalQuestions = async (search: object): Promise<ITechnicalQuestionsResponse> => new Promise((resolve, reject) => {
		httpRequest<ITechnicalQuestionsResponse>(
			"GET",
			`${technicalQuestion}/list`,
			{},
			search
		)
			.then(resolve)
			.catch(reject);
	});

	const getTechnicalQuestion = async (query: object): Promise<ITechnicalQuestionResponse> => new Promise((resolve, reject) => {
		httpRequest<ITechnicalQuestionResponse>(
			"GET",
			`${technicalQuestion}`,
			{},
			query
		)
			.then(resolve)
			.catch(reject);
	});

	const updateTechnicalQuestion = async (payload: object): Promise<ITechnicalQuestionResponse> => new Promise((resolve, reject) => {
		httpRequest<ITechnicalQuestionResponse>(
			"PUT",
			`${technicalQuestion}`,
			payload,
		)
			.then(resolve)
			.catch(reject);
	});

	const deleteTechnicalQuestion = async (payload: object): Promise<ITechnicalQuestionResponse> => new Promise((resolve, reject) => {
		httpRequest<ITechnicalQuestionResponse>(
			"DELETE",
			`${technicalQuestion}`,
			payload
		)
			.then(resolve)
			.catch(reject);
	});
	return {
		addTechnicalQuestion, addTechnicalQuestionTemplate, addTechnicalQuestionTestCase, getTechnicalQuestionTestCase,
		deleteTechnicalQuestionTestCase, getTechnicalQuestionTemplate, deleteTechnicalQuestionTemplate, updateTechnicalQuestionTemplate,
		getTechnicalQuestionLanguages, getPartialTechnicalQuestions, getTechnicalQuestions, getTechnicalQuestion, updateTechnicalQuestion,
		deleteTechnicalQuestion, updateTechnicalQuestionTestCase
	};
};

export { TechnicalQuestionService };
