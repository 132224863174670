import CategoryService from "../../../../services/content/category";
import SubCategoryService from "../../../../services/content/sub-category";


const usevalidateQuarterTarget = () => {
    const { getContentCategoryPartial } = CategoryService();
    const { getContentSubCategoryPartial } = SubCategoryService();

    // eslint-disable-next-line
    const validateQuarterTarget = async (leads: any[]) =>
        // eslint-disable-next-line
        new Promise(async (resolve) => {
            const mandatoryFields = ["_category", "_subCategory"];
            const payload = [];
            const error = [];
            let row = 2;
            const fieldNames = leads[0];
            for await (const lead of leads.slice(1)) {
                if (!lead?.length) {
                    ++row;
                    continue;
                }

                // eslint-disable-next-line
                const data: any = {};
                // eslint-disable-next-line
                const errMessage: any = {};


                if (typeof lead[0] === "string") {
                    try {
                        const category = await getContentCategoryPartial({
                            search: lead[0].trim(),
                        });
                        if (category.data?._id) {

                            data._category = category.data?._id;

                        } else {
                            errMessage["Category"] = "Please enter a valid category name";
                        }
                    } catch (error) {
                        errMessage["Category"] = "Please enter a valid category name";
                    }
                } else {
                    if (lead[0] !== undefined) {
                        errMessage["Category"] = "Please enter a valid category name";
                    }
                }

                if (typeof lead[1] === "string") {
                    try {
                        const subCategory = await getContentSubCategoryPartial({
                            search: lead[1].trim(),
                        });
                        if (subCategory.data?._id) {

                            data._subCategory = subCategory.data?._id;

                        } else {
                            errMessage["Sub Category"] = "Please enter a valid subcategory name";
                        }
                    } catch (error) {
                        errMessage["Sub Category"] = "Please enter a valid subcategory name";
                    }
                } else {
                    if (lead[1] !== undefined) {
                        errMessage["Sub Category"] = "Please enter a valid subcategory name";
                    }
                }

                const dynamicFields = [];
                for (let i = mandatoryFields.length; i < lead.length; i++) {
                    const fieldName = fieldNames[i];
                    if (typeof lead[i] === "number") {
                        dynamicFields.push({ name: fieldName, value: lead[i] });
                    } else {
                        if (lead[i] === undefined) {
                            errMessage[fieldName] = `${fieldName} is required`;
                        } else {
                            errMessage[fieldName] = `${fieldName} must be number`;
                        }
                    }
                }
                data.targets = dynamicFields;

                if (mandatoryFields.every((value) => Object.keys(data).includes(value))) {
                    if (Object.keys(errMessage).length) {
                        error.push({ ...errMessage, row });
                    } else {
                        payload.push(data);
                    }
                } else {
                    if (Object.keys(errMessage).length) {
                        error.push({ ...errMessage, row });
                    }
                }
                ++row;
            }

            // return all the data
            resolve({
                data: payload,
                errorMessage: error,
            });

        });

    return { validateQuarterTarget };
};
export default usevalidateQuarterTarget;
