import { Box, Grid, SelectChangeEvent, TextField } from "@mui/material";
import CustomDialog from "../../../../../components/mui/dialog";
import { Controller, useForm } from "react-hook-form";
import { IErrorResponse } from "../../../../../interfaces/shared/response";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { VendorContactPersonService } from "../../../../../services/vendor/contact-person";
import { FC, useEffect, useState } from "react";
import { capitalize } from "../../../../../utilities/helper";
import MobileNumber from "../../../../../components/mui/mobile-number";
import CustomLabel from "../../../../../components/mui/custom-label";
import { useQuery } from "@tanstack/react-query";
import { IContactPerson, IContactPersonDetail } from "../../../../../interfaces/vendor/contact-person";
import { vendorContactPersonValidation } from "../../../../../validations/vendor/manage-vendor/contact-person";
import { joiResolver } from "@hookform/resolvers/joi";

interface props {
  contactPerson: string;
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}

const ManageVendorContactPerson: FC<props> = ({ contactPerson, isOpen, onClose, refetch }) => {
    const { updateContactPerson, getContactPerson } = VendorContactPersonService();
    const { snackbar } = useSnackbar();
    const { handleSubmit, control, setValue, formState: { errors } } = useForm<IContactPersonDetail>({
      resolver: joiResolver(vendorContactPersonValidation),
      defaultValues: {
        name: "",
        email: "",
        designation: "",
        phoneNumber: ""
      }
    });

  const [state, setState] = useState({
      phoneNumber: {
          country: "INDIA",
          dialCode: "+91",
          iso2: "IN"
      },
  });

    const onNumberChange = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target;
        const data = value.split(":");

        setState(prev => ({
            ...prev,
            [name]: {
                country: data[0],
                iso2: data[1],
                dialCode: data[2]
            }
        }));
    };

    const contactPersonData = useQuery({
      queryKey: ["contactPerson", contactPerson],
      queryFn: () =>
        getContactPerson({
          _id: contactPerson
        }),
      enabled:  !!contactPerson && isOpen,
    });


    useEffect(() => {
      if(contactPersonData?.data?.data){
        const data = contactPersonData.data.data;
        setValue("name", capitalize(data.name));
        setValue("note", data.note);
        setValue("designation", data.designation);
        setValue("email", data.email);
        setValue("phoneNumber", data?.mobileNumber?.number);
      }
    }, [contactPersonData?.data?.data, isOpen]);

  const onSubmit = async (data: IContactPersonDetail) => {
    const payload = {
      ...data,
      _id: contactPersonData?.data?.data?._id,
      mobileNumber: {
        ...state.phoneNumber,
        number: data.phoneNumber,
    },
    };
    delete payload.phoneNumber;
    try {
      const user = await updateContactPerson(payload);
      snackbar(user.message, "info");
      contactPersonData.refetch();
      refetch();
      onClose();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log("error in manage contact person", error);
    }
  };

  const fields: IContactPerson[] = [
    {
      type: "input",
      name: "name",
      label: "Name",
      placeholder: "Type name here",
      required: true
    },
    {
      type: "mobile-number",
      name: "phoneNumber",
      label: "Mobile Number",
      placeholder: "Type mobile number here",
      required: true
    },
    {
      type: "input",
      name: "email",
      label: "Email",
      placeholder: "Type email address here",
    },
    {
      type: "input",
      name: "designation",
      label: "Designation",
      placeholder: "Type designation here",
      required: true,
    },
    {
      type: "input",
      name: "note",
      label: "Note",
      placeholder: "Type note here"
    }
  ];

  return (
    <Box>
      <CustomDialog
        title={"Edit Contact Person Detail"}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4}>
          {
            fields.map(field => {
              if (field.type === "input") {
                return (<Grid key={field.label} item xs={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <TextField
                      label={<CustomLabel label={field.label} required={field?.required} />}
                      className="disable-text"
                      variant={"outlined"}
                      size={"small"}
                      placeholder={field.placeholder}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      {...prop.field}
                    />}
                  />
                </Grid>
                );
              }else if (field.type === "mobile-number") {
                const numberState = "phoneNumber";
                return <Grid key={field.label} item xs={12} md={6}>
                    <Controller
                        control={control}
                        name={field.name}
                        render={(prop) => <MobileNumber
                            key={field.label}
                            className="disable-text"
                            NumberFieldLabel={<CustomLabel label={field.label} required={field?.required} />}
                            dialCodeValue={`${state[numberState].country}:${state[numberState].iso2}:${state[numberState].dialCode}`}
                            dialCodeName={field.name}
                            onChange={onNumberChange}
                            error={!!errors[field.name]}
                            helperText={errors[field.name]?.message}
                            other={prop.field}
                        />}
                    />
                </Grid>;
            }
              }
            )}
        </Grid>
      </CustomDialog>
    </Box>
  );
};

ManageVendorContactPerson.defaultProps = {
  isOpen: false
};

export default ManageVendorContactPerson;
