import "./style.scss";
import { Box, Button, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { formatMonthDate, formatTime } from "../../../../utilities/helper";
import writeIcon from "../../../../assets/images/activity-write.svg";
import imageIcon from "../../../../assets/images/activity-image.svg";
import publishIcon from "../../../../assets/images/activity-publish.svg";
import assignIcon from "../../../../assets/images/activity-assign.svg";
import addIcon from "../../../../assets/images/blog-create.svg";
import statusIcon from "../../../../assets/images/activity-status.svg";
import reviewIcon from "../../../../assets/images/activity-review.svg";




import { useQuery } from "@tanstack/react-query";
import CmsHomeService from "../../../../services/cms-home";
import { ICmsActivity, ICmsActivityData } from "../../../../interfaces/cms-home";
import { formatBlogActivity } from "../../../content-marketing-layout/blog/blog-timeline/timeline";

interface props {
    activeAction: boolean;
    setActiveAction: (value: boolean) => void;
}
const getActivityIcon = (activityType: string) => {
    switch (activityType) {
        case "image":
            return imageIcon;
        case "content":
            return writeIcon;
        case "status":
            return statusIcon;
        case "write":
            return addIcon;
        case "publish":
            return publishIcon;
        case "assign":
            return assignIcon;
        case "review":
            return reviewIcon;
        default:
            return publishIcon;
    }
};
const CmsActivity: FC<props> = ({ activeAction, setActiveAction }) => {
    const { cmsHomeActivityData } = CmsHomeService();
    const [currentPage, setCurrentPage] = useState(1);

    const [loading, setLoading] = useState<boolean>(false);

    const activityDataResponse = useQuery({
        queryKey: ["cms-home-activity", currentPage],
        queryFn: () => {
            setLoading(true);
            return cmsHomeActivityData({ pagination: true, page: currentPage });
        },
        onSuccess: () => setLoading(false),
    });

    const [activityData, setActivityData] = useState<ICmsActivityData[] | null>(null);
    useEffect(() => () => {
        activityDataResponse.remove();
        setActivityData(null);
    }
        , []);

    useEffect(() => {
        if (activityDataResponse.data?.success && activityDataResponse.data?.data) {
            setActivityData((prevData) => {
                if (!prevData) {
                    return activityDataResponse.data.data as ICmsActivityData[];
                } else {
                    if (Array.isArray(activityDataResponse.data.data)) {
                        const newData = activityDataResponse.data.data as ICmsActivityData[];

                        newData.forEach((newItem) => {
                            const index = prevData.findIndex((prevItem) => prevItem._id === newItem._id);
                            if (index !== -1) {
                                prevData[index] = {
                                    ...prevData[index],
                                    data: prevData[index].data ? [...prevData[index].data, ...newItem.data] : newItem.data
                                };
                            } else {
                                prevData.push(newItem);
                            }
                        });

                        return [...prevData];
                    } else {
                        return prevData;
                    }
                }
            });
        }

    }, [activityDataResponse?.data?.data]);


    const handleLoadMore = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    return (

        <div id="comments">
            <header>
                <Typography variant="body1">Recent Activities</Typography>
                <IconButton color="primary" onClick={() => {
                    setActivityData(null);
                    setActiveAction(!activeAction);
                }}>
                    <CloseIcon color="action" />
                </IconButton>
            </header>
            <div className="note-container">
                {(activityData || [])?.map((item: ICmsActivityData, index: number) => (
                    <div key={index}>
                        <div className="mt-1">
                            <Typography variant="caption" className="history" >{formatMonthDate(new Date(item._id))}</Typography>
                        </div>
                        {item?.data?.map((data: ICmsActivity, index: number) => (
                            <div key={index}>
                                <div className="form-border mb-3">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box className="mb-2" display="flex" alignItems="center">

                                                <img src={getActivityIcon(data.activityType)} alt="logo" className="mr-2" />
                                                <div className="d-flex flex-column" style={{ width: "100%" }}>
                                                    {formatBlogActivity(data)}
                                                </div>
                                            </Box>
                                            <div className="activity-time">
                                                <Typography variant="caption" className="history">{formatTime(new Date(data.createdAt))}</Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        ))}

                    </div>))}
                <div className="load-more">
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        activityDataResponse?.data?.meta?.totalPages && currentPage < activityDataResponse?.data?.meta?.totalPages && (
                            <Button variant="contained" color="primary" onClick={handleLoadMore}>
                                Load More
                            </Button>
                        )
                    )}
                </div>
            </div>
        </div>

    );
};

export default CmsActivity;