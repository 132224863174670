import { useSelector } from "react-redux";
import { FC, useEffect, useState } from "react";
import { capitalize } from "../../../utilities/helper";
import { MenuItem } from "../../../components/shared/filter";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { IIReviewFilter, IEngagement, IJobTitle } from "../../../interfaces";
import {
    Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton,
    ListItemText, Menu, Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../../components/shared/filter/style.scss";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    OnClose: () => void;
}

const ReviewFilter: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
    const navigate = useNavigate();
    const { types } = useParams();
    const [searchParams] = useSearchParams();
    const jobTitles = useSelector<{ jobTitle: { list: IJobTitle[] } }, IJobTitle[]>(state => state.jobTitle.list);
    const engagementTypes = useSelector<{ engagementType: { list: IEngagement[] } }, IEngagement[]>(state => state.engagementType.list) || [];
    const [state, setState] = useState<IIReviewFilter>({
        selectedMenu: 0,
        typeOfLead: [],
        jobId: [],
        reviewStatus: [],
    });

    useEffect(() => {
        const typeOfLead: { key: string, value: string }[] = searchParams.get("typeOfLead") ? JSON.parse(String(searchParams.get("typeOfLead"))) : [];
        const jobId: { key: string, value: string }[] = searchParams.get("jobId") ? JSON.parse(String(searchParams.get("jobId"))) : [];
        const reviewStatus: { key: string, value: string }[] = searchParams.get("reviewStatus") ? JSON.parse(String(searchParams.get("reviewStatus"))) : [];
        setState(prevState => ({
            ...prevState,
            typeOfLead,
            jobId,
            reviewStatus,
        }));
    }, [searchParams]);

    const handleLeftListItem = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setState(prevState => ({
            ...prevState,
            selectedMenu: index
        }));
    };

    const handleRightListItem = (name: "typeOfLead" | "jobId" | "reviewStatus", key: string, value: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        const isExist = state[name].find(ele => ele.key === key) ? true : false;
        if (isExist) {
            payload = state[name].filter(ele => ele.key !== key);
        } else {
            payload = state[name];
            payload.push({
                key,
                value
            });
        }

        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const deleteChip = (name: "typeOfLead" | "jobId" | "reviewStatus", key: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        payload = state[name].filter(ele => ele.key !== key);
        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const resetFilter = () => {
        setState({
            selectedMenu: 0,
            typeOfLead: [],
            jobId: [],
            reviewStatus: [],
        });
    };

    const onApply = () => {
        searchParams.set("typeOfLead", JSON.stringify(state.typeOfLead));
        searchParams.set("jobId", JSON.stringify(state.jobId));
        searchParams.set("reviewStatus", JSON.stringify(state.reviewStatus));
        searchParams.set("page", "1");
        navigate({
            pathname: "/assessments/review/" + types,
            search: searchParams.toString()
        });
        OnClose();
    };

    const onClose = () => {
        const typeOfLead: { key: string, value: string }[] = searchParams.get("typeOfLead") ? JSON.parse(String(searchParams.get("typeOfLead"))) : [];
        const jobId: { key: string, value: string }[] = searchParams.get("jobId") ? JSON.parse(String(searchParams.get("jobId"))) : [];
        const reviewStatus: { key: string, value: string }[] = searchParams.get("reviewStatus") ? JSON.parse(String(searchParams.get("reviewStatus"))) : [];
        setState(prevState => ({
            ...prevState,
            typeOfLead,
            jobId,
            reviewStatus,
        }));
        OnClose();
    };

    const defaultStatus = [
        {
            key: "REVIEW SELECTED",
            name: "Review Selected"
        },
        {
            key: "REVIEW REJECTED",
            name: "Review Rejected"
        },
    ];

    return <>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={OnClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            <Box id="filters-container">
                <Box className="center mb-3" justifyContent="space-between" alignItems="start">
                    <div className="active-filter mb-1">
                        {
                            (state.typeOfLead.length || state.jobId.length || state.reviewStatus.length) ?
                                <>
                                    {state.typeOfLead.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("typeOfLead", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.jobId.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("jobId", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.reviewStatus.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("reviewStatus", ele.key)} label={ele.value} variant="outlined" />)}
                                </>
                                :
                                <Box className="mt-2" display="flex" alignItems="center">
                                    <FilterAltOffIcon />
                                    <Typography className="ml-2">No filters are applied</Typography>
                                </Box>
                        }
                    </div>
                    <IconButton
                        onClick={onClose}
                        style={{ marginRight: "-10px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Grid className="filter-box" container>
                    <Grid id="left" item xs={5}>
                        <List component="nav">

                            <MenuItem
                                index={0}
                                label="Engagement Type"
                                selectedMenu={state.selectedMenu === 0}
                                onChange={handleLeftListItem}
                                count={state.typeOfLead}
                            />

                            <MenuItem
                                index={1}
                                label="Job Title"
                                selectedMenu={state.selectedMenu === 1}
                                onChange={handleLeftListItem}
                                count={state.jobId}
                            />
                            {
                                types !== "pending" &&
                                <>
                                    <MenuItem
                                        index={2}
                                        label="Status"
                                        selectedMenu={state.selectedMenu === 2}
                                        onChange={handleLeftListItem}
                                        count={state.reviewStatus}
                                    />
                                </>
                            }
                        </List>
                    </Grid>
                    <Divider orientation="vertical" />

                    <Grid id="right" item xs={6}>
                        <List component="nav">
                            {
                                state.selectedMenu === 0 &&
                                engagementTypes.map((engagementType) =>
                                    <ListItemButton
                                        key={engagementType._id}
                                        selected={state.typeOfLead.find(ele => ele.key === engagementType.name.toUpperCase()) ? true : false}
                                        onClick={() => handleRightListItem("typeOfLead", engagementType.name.toUpperCase(), capitalize(engagementType.name))}
                                    >
                                        <ListItemText primary={capitalize(engagementType.name)} />
                                        <Checkbox edge="end" checked={state.typeOfLead.find(ele => ele.key === engagementType.name.toUpperCase()) ? true : false} />
                                    </ListItemButton>
                                )
                            }
                            {
                                state.selectedMenu === 1 &&
                                jobTitles.map(jobTitle => <ListItemButton
                                    key={jobTitle._id}
                                    selected={state.jobId.find(ele => ele.key === jobTitle.name) ? true : false}
                                    onClick={() => handleRightListItem("jobId", jobTitle.name, jobTitle.name)}
                                >
                                    <ListItemText primary={jobTitle.name} />
                                    <Checkbox edge="end" checked={state.jobId.find(ele => ele.key === jobTitle.name) ? true : false} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 2 &&
                                defaultStatus.map(status => <ListItemButton
                                    key={status.key}
                                    selected={state.reviewStatus.find(ele => ele.key === status.key) ? true : false}
                                    onClick={() => handleRightListItem("reviewStatus", status.key, status.name)}
                                >
                                    <ListItemText primary={status.name} />
                                    <Checkbox edge="end" checked={state.reviewStatus.find(ele => ele.key === status.key) ? true : false} />
                                </ListItemButton>)
                            }
                        </List>

                    </Grid>
                </Grid>

                <Box className="actions-btn"  marginTop="8px" textAlign="end">
                    <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
                    <Button className="ml-2" onClick={onApply}>Apply</Button>
                </Box>
            </Box>
        </Menu>
    </>;
};

export default ReviewFilter;