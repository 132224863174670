import HttpService from "../../http";
import { IJobPositionsResponse, IJobPositionResponse } from "../../../interfaces/configuration/job-position";
import { jobPosition } from "../../endpoints";

const JobPositionService = () => {
    const { httpRequest } = HttpService();
    const addJobPositions = async (payload: object): Promise<IJobPositionsResponse> => new Promise((resolve, reject) => {
        httpRequest<IJobPositionsResponse>(
            "POST",
            `${jobPosition}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getPartialJobPositions = async (search: object): Promise<IJobPositionsResponse> => new Promise((resolve, reject) => {
        httpRequest<IJobPositionsResponse>(
            "GET",
            `${jobPosition}/list/partial`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getJobPositions = async (search: object): Promise<IJobPositionsResponse> => new Promise((resolve, reject) => {
        httpRequest<IJobPositionsResponse>(
            "GET",
            `${jobPosition}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getJobPosition = async (query: object): Promise<IJobPositionResponse> => new Promise((resolve, reject) => {
        httpRequest<IJobPositionResponse>(
            "GET",
            `${jobPosition}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateJobPosition = async (id: string, payload: object): Promise<IJobPositionResponse> => new Promise((resolve, reject) => {
        httpRequest<IJobPositionResponse>(
            "PUT",
            `${jobPosition}`,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteJobPositions = async (query: object): Promise<IJobPositionsResponse> => new Promise((resolve, reject) => {
        httpRequest<IJobPositionsResponse>(
            "DELETE",
            `${jobPosition}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });


    return { addJobPositions, getPartialJobPositions, getJobPositions, getJobPosition, updateJobPosition, deleteJobPositions };
};

export { JobPositionService };
