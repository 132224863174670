import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { cpdDriveService } from "../../../../services";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Box, Chip, Grid, TextField } from "@mui/material";
import { ICpdDashboardField, ICpdDrive } from "../../../../interfaces";
import dayjs from "dayjs";
import CustomDialog from "../../../../components/mui/dialog";
import { capitalize } from "../../../../utilities/helper";

const ManageDashboard = () => {
    const isDisable = true;
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParam] = useSearchParams();
    const { getCpdDrive } = cpdDriveService();
    const hitQuery = (id === "new" || id === "view") ? false : true;
    const CpdDashboard = useQuery({ queryKey: [hitQuery], queryFn: () => getCpdDrive({ _id: id }), enabled: hitQuery });
    const { control, setValue, getValues, watch, resetField } = useForm<ICpdDrive>({
        defaultValues: {
            _college: "",
            cpdId: "",
            status: "",
            startDate: "",
            endDate: "",
            collegeId: {
                category: "",
                email: [],
                city: "",
            }
        }
    });

    useEffect(() => {
        if (id) {
            if (CpdDashboard.data?.data) {
                setValue("_college", capitalize(CpdDashboard.data.data?.collegeId?.name));
                setValue("cpdId", CpdDashboard.data.data?.cpdId);
                setValue("status", capitalize(CpdDashboard.data.data?.status));
                setValue("collegeId.category", capitalize(CpdDashboard.data.data?.collegeId?.category));
                setValue("collegeId.email", CpdDashboard.data.data?.collegeId?.email || []);
                setValue("collegeId.city", capitalize(CpdDashboard.data.data?.collegeId?.city));
                CpdDashboard.data.data?.startDate && setValue("startDate", dayjs(CpdDashboard.data.data?.startDate).format("ll"));
                CpdDashboard.data.data?.endDate && setValue("endDate", dayjs(CpdDashboard.data.data?.endDate).format("ll"));
            }
        }
    }, [id, CpdDashboard.data?.data]);

    const removeEmail = (key: string, value: string) => {
        if (key === "emailText") {
            let payload = getValues("collegeId.email");
            payload = payload.filter(email => email !== value);
            setValue("collegeId.email", payload);
        }
    };

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === "collegeId.email") {
                resetField("emailText");
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const onClose = () => {
        navigate({
            pathname: "/cpd/dashboard",
            search: searchParam.toString()
        });
    };
    const fields: ICpdDashboardField[] = [

        {
            label: "College Name",
            name: "_college",
            type: "text",
        },
        {
            label: "Category",
            name: "collegeId.category",
            type: "text",
        },
        {
            label: "College Email",
            name: "emailText",
            type: "text",
        },
        {
            label: "City",
            name: "collegeId.city",
            type: "text",
        },
        {
            label: "CPD ID",
            name: "cpdId",
            type: "text",
        },
        {
            label: "Status",
            name: "status",
            type: "text",
        },
        {
            label: "Start Date",
            name: "startDate",
            type: "text",
        },
        {
            label: "End Date",
            name: "endDate",
            type: "text",
        },
    ];

    console.log("cpd dashboard", { isDisable });


    return (
        <Box>
            <CustomDialog
                title={"Dashboard Details"}
                isOpen={id ? true : false}
                disabled={true}
                onClose={onClose}
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "text") {
                                return (<Grid key={field.label} item xs={12} md={field.width ? field.width : 6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            {...prop.field}
                                            label={field.label}
                                            className="disable-text"
                                            disabled={isDisable}
                                            variant={isDisable ? "standard" : "outlined"}
                                            size={isDisable ? "medium" : "small"}
                                            placeholder={field.placeholder}
                                        />}
                                    />
                                    {
                                        field.name === "emailText" && Array.isArray(getValues("collegeId.email")) && getValues("collegeId.email").map(email => (
                                            <Chip
                                                key={email}
                                                label={email}
                                                onDelete={() => removeEmail(field.name, email)}
                                                disabled={isDisable}
                                                color={isDisable ? undefined : "primary"}
                                                variant="outlined"
                                                sx={{ margin: "5px" }}
                                            />
                                        ))
                                    }

                                </Grid>
                                );
                            }
                        })
                    }
                </Grid>
            </CustomDialog>
        </Box>
    );
};

export default ManageDashboard;