import Joi from "joi";

export const internCareerValidation = Joi.object({
    _cpd: Joi.string()
        .label("CPD")
        .allow(""),
    collegeName: Joi.string()
        .label("College Name")
        .allow(""),
    graduationYear: Joi.number()
        .min(2000)
        .max(2030)
        .allow("")
        .label("Graduation Year"),
    degree: Joi.string()
        .label("Degree")
        .allow(""),
    comments: Joi.string()
        .label("College Note")
        .allow(""),
});

export const fullTimeCareerValidation = Joi.object({
    experience: Joi.number()
        .min(0)
        .label("Experience")
        .allow(""),
    approxExperience: Joi.number()
        .min(0)
        .label("Approximate Experience")
        .allow(""),
    noticePeriod: Joi.number()
        .min(0)
        .max(100)
        .label("Notice Period")
        .allow(""),
    ctc: Joi.number()
        .min(0)
        .max(100)
        .allow("")
        .label("CTC"),
    ectc: Joi.number()
        .min(0)
        .max(100)
        .label("ECTC")
        .allow(""),
    lwd: Joi.string()
        .label("LWD")
        .allow(""),
    comments: Joi.string()
        .label("Note")
        .allow(""),
});
