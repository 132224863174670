import { Box, capitalize, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ChangeEvent } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import {
  createIndex,
  formatHtmlString,
} from "../../../utilities/helper";
import { IErrorResponse, IPagination } from "../../../interfaces";
import Header from "../../../components/header";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import useDebounce from "../../../hooks/useDebounce";
import useResource from "../../../hooks/useResource";
import { IassessmentInstructions, IAssessmentInstructionsRow, IAssessmentInstructionsState } from "../../../interfaces/configuration/assessments-instructions";
import { AssessmentInstructionService } from "../../../services/configuration/assessment-instructions";

interface outletProps {
  refetchTests: () => void;
}
const AssessmentInstruction = () => {
  let rows: IAssessmentInstructionsRow[] = [];
  const { snackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getAssessmentsInstructions, deleteAssessmentsInstructions } = AssessmentInstructionService();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IAssessmentInstructionsState>({
    deleteWarning: false,
    _instruction: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filters: {
      search: "",
    },
  });


  const assessmentInstructions = useQuery({
    queryKey: ["all-assessmentInstructions", state.pagination.page, state.filters],
    queryFn: () =>
      getAssessmentsInstructions({
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page,
        search: state.filters.search,
      }),
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    searchRecord.length
      ? setSearchParams((prev) => ({
          ...prev,
          page: 1,
          search: searchRecord,
        }))
      : (searchParams.delete("search"), setSearchParams(searchParams));
  }, [searchRecord]);

  useEffect(() => {
    assessmentInstructions.data &&
      assessmentInstructions.data.data &&
      assessmentInstructions.data.data.length &&
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: assessmentInstructions.data.meta.page,
          totalPages: assessmentInstructions.data.meta.totalPages,
          totalRecords: assessmentInstructions.data.meta.totalRecords,
        },
      }));
  }, [assessmentInstructions.data && assessmentInstructions.data.data && assessmentInstructions.data.meta]);

  useEffect(() => {
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
      },
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const handleDelete = (_instruction= "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _instruction,
    }));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteAssessmentsInstructions({ _id: state._instruction });
      snackbar(deleted.message, "info");
      handleDelete();
      assessmentInstructions.refetch();
      outlet.refetchTests && outlet.refetchTests();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
    }
  };

  const columns = [
    {
      id: "id",
      label: "S No.",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "instructions",
      label: "Instructions",
    },
    {
      id: "action",
      label: "Actions",
    },
  ];
  const createRow = (index: number, assessmentInstructions: IassessmentInstructions, pagination: IPagination) => {
    const action = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () =>
              navigate({
                pathname: "" + assessmentInstructions._id,
                search: searchParams.toString(),
              }),
            disabled: !resourceAllocate("assessment-instruction.write")
          },
          {
            name: "Delete",
            method: () => handleDelete(assessmentInstructions._id),
            disabled: !resourceAllocate("assessment-instruction.remove"),
          },
        ]}
      />
    );

      const instruction =  <Tooltip title={(assessmentInstructions.instructions || "")}>
      <span>
      <Tooltip title={assessmentInstructions.instructions ?? ""}>
          <span>
            {assessmentInstructions.instructions && assessmentInstructions.instructions.toString().length > 100
              ? `${formatHtmlString(assessmentInstructions.instructions.toString()) .slice(0, 100)}...`
              : formatHtmlString(assessmentInstructions.instructions)}
          </span>
        </Tooltip>
      </span>
    </Tooltip>;


    return {
      id: createIndex(pagination, index),
      name: assessmentInstructions.name && assessmentInstructions.name.length > 60
      ? `${capitalize(formatHtmlString(assessmentInstructions.name)).slice(0, 60)}...`
      : assessmentInstructions.name ? capitalize(formatHtmlString(assessmentInstructions.name)) : "",
      instructions: instruction,
      action,
    };
  };

  if (assessmentInstructions.data && assessmentInstructions.data.data && assessmentInstructions.data.data.length) {
    rows = ((assessmentInstructions.data && assessmentInstructions.data.data && assessmentInstructions.data.data) || []).map(
      (data:IassessmentInstructions , i:number) => createRow(i, data, state.pagination)
    );
  }

  return (
    <>
      <Header
        className="my-2"
        searchPlaceholder="Search by Name"
        onSearch={onSearch}
        btnText="ADD NEW INSTRUCTION"
        onBtnClick={
          resourceAllocate("assessment-instruction.write") ? () => navigate("new") : undefined
        }
      />
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Technical Question"
        description="Are you sure you want to delete this question?"
      />
      <Outlet context={{ ...outlet, reFetch: assessmentInstructions.refetch }} />
    </>
  );
};
export default AssessmentInstruction;