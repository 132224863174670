import HttpService from "../../http";
import { IJobTitlesResponse, IJobTitleResponse } from "../../../interfaces/configuration/job-title";
import { jobTitle } from "../../endpoints";

const JobTitleService = () => {
    const { httpRequest } = HttpService();
    const addJobTitles = async (payload: object): Promise<IJobTitlesResponse> => new Promise((resolve, reject) => {
        httpRequest<IJobTitlesResponse>(
            "POST",
            `${jobTitle}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getPartialJobTitles = async (search: object): Promise<IJobTitlesResponse> => new Promise((resolve, reject) => {
        httpRequest<IJobTitlesResponse>(
            "GET",
            `${jobTitle}/list/partial`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getJobTitles = async (search: object): Promise<IJobTitlesResponse> => new Promise((resolve, reject) => {
        httpRequest<IJobTitlesResponse>(
            "GET",
            `${jobTitle}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getJobTitle = async (query: object): Promise<IJobTitleResponse> => new Promise((resolve, reject) => {
        httpRequest<IJobTitleResponse>(
            "GET",
            `${jobTitle}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateJobTitle = async (id: string, payload: object): Promise<IJobTitleResponse> => new Promise((resolve, reject) => {
        httpRequest<IJobTitleResponse>(
            "PUT",
            `${jobTitle}`,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteJobTitles = async (payload: object): Promise<IJobTitlesResponse> => new Promise((resolve, reject) => {
        httpRequest<IJobTitlesResponse>(
            "DELETE",
            `${jobTitle}`,
            {},
            payload
        )
            .then(resolve)
            .catch(reject);
    });


    return { addJobTitles, getPartialJobTitles, getJobTitles, getJobTitle, updateJobTitle, deleteJobTitles };
};

export { JobTitleService };
