import HttpService from "../../http";

const IntegrationService = () => {
    const { httpRequest } = HttpService();
    const generateGoogleCode = async (): Promise<{ data: string }> => new Promise((resolve, reject) => {
        httpRequest<{ data: string }>(
            "GET",
            "configuration/google-integration/generate-code",
        )
            .then(resolve)
            .catch(reject);
    });

    const setGoogleCredentials = async (payload: object): Promise<{ data: string }> => new Promise((resolve, reject) => {
        httpRequest<{ data: string }>(
            "POST",
            "configuration/google-integration/credentials",
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { generateGoogleCode, setGoogleCredentials };
};

export { IntegrationService };
