import { Box, Button, Grid, InputLabel, Link, MenuItem, TextField } from "@mui/material";
import CustomDialog from "../../../../../components/mui/dialog";
import { Controller, useForm } from "react-hook-form";
import { IErrorResponse } from "../../../../../interfaces/shared/response";
import useSnackbar from "../../../../../hooks/useSnackbar";
import { FC, useEffect, ChangeEvent } from "react";
import { capitalize } from "../../../../../utilities/helper";
import { VendorContractService } from "../../../../../services/vendor/contract-detail";
import { useQuery } from "@tanstack/react-query";
import Select from "../../../../../components/mui/select";
import HttpService from "../../../../../services/http";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CustomLabel from "../../../../../components/mui/custom-label";
import { IContract, IContractDetailManage } from "../../../../../interfaces/vendor/contract-details";
import { VendorCategoryService } from "../../../../../services/vendor/vendor-category";
import { vendorEditContractValidation } from "../../../../../validations/vendor/manage-vendor/contract-detail";
import { joiResolver } from "@hookform/resolvers/joi";

interface props {
  contract: string;
  isOpen: boolean;
  onClose: () => void;
  contactDetailRefetch?: () => void;
  refetch: () => void;
}

const ManageVendorContractDetail: FC<props> = ({ contract, isOpen, onClose, refetch }) => {
  const { updateContract, getContract } = VendorContractService();
  const { httpFormRequest } = HttpService();
  const { snackbar } = useSnackbar();
  const { handleSubmit, control, getValues, setValue, trigger, formState: { errors } } = useForm<IContractDetailManage>({
    resolver: joiResolver(vendorEditContractValidation),
    defaultValues: {
      name: "",
      _category: "",
      link: "",
      startDate: "",
      endDate: ""
    }
  });

  const contractData = useQuery({
    queryKey: ["contract", contract],
    queryFn: () =>
      getContract({
        _id: contract
      }),
    enabled: !!contract && isOpen,
  });

  useEffect(() => {
    if (contractData?.data?.data) {
      const data = contractData.data.data;
      setValue("name", data.name);
      setValue("_category", data._category._id);
      setValue("link", data.link);
      setValue("startDate", data.startDate);
      setValue("endDate", data.endDate || "");
    }
  }, [contractData?.data?.data, isOpen]);

  const onSubmit = async (data: IContractDetailManage) => {
    const payload = {
      ...data,
      _id: contractData?.data?.data?._id,
    };
    try {
      const user = await updateContract(payload);
      snackbar(user.message, "info");
      refetch();
      onClose();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log("error in manage contract", error);
    }
  };

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const uploaded = await httpFormRequest<{ data: string }>(
        e.target.files,
        e.target.files ? e.target.files[0].name : "",
        ["doc", "docx", "pdf", "png", "jpeg", "jpg"],
        10
      );
      setValue("link", uploaded.data.split("uploads")[1]);
    } catch (error) {
      console.log("error in candidate detail upload", error);
    }

  };

  const { getVendorPartialCategory } = VendorCategoryService();

  const categories = useQuery({
    queryKey: ["category"],
    queryFn: () =>
      getVendorPartialCategory({
        type: "DOCUMENT",
      }),
    enabled: !!contract && isOpen,
  });

  const selectDate = (value: string | number | Date | dayjs.Dayjs | null | undefined, name: string) => {
    const date = value && dayjs(value).toString() !== "Invalid Date" ? dayjs(value)?.toISOString() : undefined;
    const keyExist = name === "startDate" || name === "endDate";
    if (keyExist) {
      setValue(name, date);
      trigger(name);
    }
  };

  const fields: IContract[] = [
    {
      type: "input",
      name: "name",
      label: "Name",
      placeholder: "Type name here",
      required: true
    },
    {
      type: "select",
      required: true,
      displayFieldKey: "name",
      storeFieldKey: "_id",
      name: "_category",
      label: "Category*",
      placeholder: "Select category",
      children: categories?.data?.data && categories?.data?.data.map((category) => <MenuItem key={category._id} value={category._id} >{capitalize(category.name)}</MenuItem>)
    },
    {
      label: "Start Date",
      name: "startDate",
      type: "date",
      required: true
    },
    {
      label: "End Date",
      name: "endDate",
      type: "date"
    },
    {
      label: "Document",
      name: "link",
      type: "doc",
      required: true
    }
  ];

  return (
    <Box>
      <CustomDialog
        title={"Edit Contract Detail"}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4}>
          {
            fields.map(field => {
              if (field.type === "input") {
                return (<Grid key={field.label} item xs={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <TextField
                      label={<CustomLabel label={field.label} required={field?.required} />}
                      className="disable-text"
                      variant={"outlined"}
                      size={"small"}
                      placeholder={field.placeholder}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      {...prop.field}
                    />}
                  />
                </Grid>
                );
              } else if (field.type === "select") {
                return (<Grid key={field.label} item xs={6} >
                  <Select
                    control={control}
                    className="disable-text"
                    name={field.name}
                    label={<CustomLabel label={field.label} required={field?.required} />}
                    error={!!errors[field.name]}
                    helperText={errors[field.name]?.message}
                  >
                    {field.children}
                  </Select>
                </Grid>
                );
              } else if (field.type === "date") {
                return (<Grid key={field.label} item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      label={<CustomLabel label={field.label} required={field?.required} />}
                      onChange={(e) => selectDate(e, field.name)}
                      value={dayjs(getValues(field.name))}
                      slotProps={{
                        textField: {
                          error: errors[field.name] ? true : false,
                          helperText: errors[field.name]?.message
                        }
                      }}
                      format="LL"
                    />
                  </LocalizationProvider>

                </Grid>
                );
              } else {
                const hasValue = getValues(field.name);
                return (
                  <Grid key={field.label} item xs={6} >
                    <div>
                      <div style={{ color: "rgb(118, 118, 118)", marginTop: "-17px", marginBottom: "3px" }}>
                        {hasValue &&
                          <label>{field.label}</label>
                        }
                      </div>
                      <Grid container spacing={2}>
                        {hasValue && (
                          <Grid item xs>
                            <Link href={!getValues(field.name)?.toString().includes("https://") ? String(process.env.REACT_APP_S3_BASE_URL) + getValues(field.name)?.toString() : getValues(field.name)?.toString()} target="_blank" underline="none" color="inherit">
                              <Button variant="outlined" fullWidth>
                                Preview
                              </Button>
                            </Link>
                          </Grid>
                        )}
                        {!hasValue && (
                          <Grid item xs>
                            <InputLabel id={`upload-${field.name}`}>
                              <Button component="label" variant="outlined" fullWidth>
                                Upload {field.label}*
                                <input hidden type="file" id={`upload-${field.name}`} onChange={e => uploadFile(e)} accept="application/pdf, image/png, image/jpeg, .doc, .docx" />
                              </Button>
                            </InputLabel>
                            {errors[field.name] && <span style={{ color: "#d32f2f", fontWeight: 400, fontSize: "0.85rem" }}>{errors[field.name]?.message}</span>}
                          </Grid>
                        )}
                        {hasValue && (
                          <Grid item xs>
                            <Button onClick={() => {
                              setValue(field.name, "");
                              trigger(field.name);
                            }} variant="outlined" color="error" fullWidth>
                              Delete
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  </Grid>
                );
              }
            })}
        </Grid>
      </CustomDialog>
    </Box>
  );
};

ManageVendorContractDetail.defaultProps = {
  isOpen: false
};

export default ManageVendorContractDetail;
