
import { blogImage, blogImageList } from "../../endpoints";
import HttpService from "../../http";
import { IBlogImageResponse, IBlogImageResponses } from "../../../interfaces/content-marketing/blog/image";
const blogImageService = () => {

    const { httpRequest } = HttpService();
    const getBlogImageList = async (search: object): Promise<IBlogImageResponses> =>
        new Promise((resolve, reject) => {
            httpRequest<IBlogImageResponses>(
                "GET",
                `${blogImageList}`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });


    const addBlogImage = async (payload: object): Promise<IBlogImageResponse> => new Promise((resolve, reject) => {
        httpRequest<IBlogImageResponse>(
            "POST",
            `${blogImage}`,
            payload)
            .then(resolve)
            .catch(reject);
    });

    const getBlogImage = async (query: object): Promise<IBlogImageResponse> => new Promise((resolve, reject) => {
        httpRequest<IBlogImageResponse>(
            "GET",
            `${blogImage}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteBlogImage = async (payload: object): Promise<IBlogImageResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IBlogImageResponse>("DELETE", `${blogImage}`, payload)
                .then(resolve)
                .catch(reject);
        });

    const updateBlogImage = async (payload: object): Promise<IBlogImageResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IBlogImageResponse>("PUT", `${blogImage}`, payload)
                .then(resolve)
                .catch(reject);
        });

    return { addBlogImage, getBlogImageList, getBlogImage, deleteBlogImage, updateBlogImage };

};

export { blogImageService };