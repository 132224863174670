import { IStatus } from "../../../../interfaces";
import { LOAD_STATUS } from "../../../actions";

interface IAction {
    type: "LOAD_STATUS",
    payload: IStatus
}

interface IState {
    list: IStatus[]
}

const initialState: IState = {
    list: [
        {
            createdAt:"",
            name:"",
            position: 0,
            _id: "",
            status: "ACTIVE",
            type:"CANDIDATE",
            category:"OPEN"
        }
    ]
};

const statusReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case LOAD_STATUS: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
};

export { statusReducer };
