import { IVendorsServiceResponse, IVendorsServicesResponse } from "../../../interfaces/vendor/vendor-service";
import HttpService from "../../http";
import { vendorService } from "../../endpoints";


const VendorServiceService = () => {
    const { httpRequest } = HttpService();

    const deleteVendorServices = async (payload: object): Promise<IVendorsServicesResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsServicesResponse>(
            "DELETE",
            `${vendorService}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getVendorServices = async (search: object): Promise<IVendorsServicesResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsServicesResponse>(
            "GET",
            `${vendorService}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getVendorPartialService = async (search: object): Promise<IVendorsServicesResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsServicesResponse>(
            "GET",
            `${vendorService}/partial/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getVendorService = async (search: object): Promise<IVendorsServiceResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsServiceResponse>(
            "GET",
            `${vendorService}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const addVendorService = async (payload: object): Promise<IVendorsServicesResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsServicesResponse>(
            "POST",
            "vendor/service",
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const updateVendorService = async (payload: object): Promise<IVendorsServicesResponse> => new Promise((resolve, reject) => {
        httpRequest<IVendorsServicesResponse>(
            "PUT",
            `${vendorService}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { getVendorPartialService, getVendorServices, deleteVendorServices, addVendorService, updateVendorService, getVendorService};
};

export { VendorServiceService };