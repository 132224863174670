import { useSelector } from "react-redux";
import { FC, useEffect, useState } from "react";
import { capitalize } from "../../../../utilities/helper";
import { MenuItem } from "../../../../components/shared/filter";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../../../components/shared/filter/style.scss";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { IEngagement } from "../../../../interfaces";
import { IAutomationFilter } from "../../../../interfaces/configuration/automation";
import { defaultSource } from "../../../candidate-layout/common/helper";

interface props {
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  OnClose: () => void;
}

const AutomationFilters: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const module = searchParams.get("module");
  const engagementTypes =
    useSelector<{ engagementType: { list: IEngagement[] } }, IEngagement[]>(
      (state) => state.engagementType.list
    ) || [];
  const [state, setState] = useState<IAutomationFilter>({
    selectedMenu: 0,
    source:[],
    status: [],
    engagementType: [],
    jobType: [],
    communicationType: [],
    category: []
  });

  useEffect(() => {
    const status: { key: string; value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
    const communicationType: { key: string; value: string }[] = searchParams.get("communicationType") ? JSON.parse(String(searchParams.get("communicationType"))) : [];
    const jobType: { key: string; value: string }[] = searchParams.get("jobType") ? JSON.parse(String(searchParams.get("jobType"))) : [];
    const engagementType: { key: string; value: string }[] = searchParams.get("engagementType") ? JSON.parse(String(searchParams.get("engagementType"))) : [];
    const source: { key: string; value: string }[] = searchParams.get("source") ? JSON.parse(String(searchParams.get("source"))) : [];
    const category: { key: string; value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
    setState((prevState) => ({
      ...prevState,
      status,
      communicationType,
      jobType,
      engagementType,
      source,
      category
    }));
  }, [searchParams]);

  const handleLeftListItem = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setState((prevState) => ({
      ...prevState,
      selectedMenu: index,
    }));
  };

  const handleRightListItem = (
    name: "status" | "source" | "communicationType" | "jobType" | "engagementType" | "category",
    key: string,
    value: string
  ) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];

    const isExist = state[name].find((ele) => ele.key === key);
    if (isExist) {
      payload = state[name].filter((ele) => ele.key !== key);
    } else {
      payload = state[name];
      payload.push({
        key,
        value,
      });
    }

    setState((prevState) => ({
      ...prevState,
      [name]: payload,
    }));
  };

  const deleteChip = (
    name: "status" | "source" | "communicationType" | "jobType" | "engagementType" | "category",
    key: string
  ) => {
    let payload: Array<{
      key: string;
      value: string;
    }> = [];

    payload = state[name].filter((ele) => ele.key !== key);
    setState((prevState) => ({
      ...prevState,
      [name]: payload,
    }));
  };
  const resetFilter = () => {
    setState({
      selectedMenu: 0,
      source:[],
      status: [],
      engagementType: [],
      jobType: [],
      communicationType: [],
      category: []
    });
  };

  const onApply = () => {
    searchParams.set("jobType", JSON.stringify(state.jobType));
    searchParams.set("status", JSON.stringify(state.status));
    searchParams.set("engagementType", JSON.stringify(state.engagementType));
    searchParams.set("communicationType", JSON.stringify(state.communicationType));
    searchParams.set("source", JSON.stringify(state.source));
    searchParams.set("category", JSON.stringify(state.category));
    searchParams.set("page", "1");
    navigate({
      pathname: "/configurations/automation/list",
      search: searchParams.toString(),
    });
    OnClose();
  };

  const onClose = () => {
    
    const status: { key: string; value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
    const communicationType: { key: string; value: string }[] = searchParams.get("communicationType") ? JSON.parse(String(searchParams.get("communicationType"))) : [];
    const jobType: { key: string; value: string }[] = searchParams.get("jobType") ? JSON.parse(String(searchParams.get("jobType"))) : [];
    const engagementType: { key: string; value: string }[] = searchParams.get("engagementType") ? JSON.parse(String(searchParams.get("engagementType"))) : [];
    const source: { key: string; value: string }[] = searchParams.get("source") ? JSON.parse(String(searchParams.get("source"))) : [];
    const category: { key: string; value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
    setState((prevState) => ({
      ...prevState,
        status,
        source,
        engagementType,
        jobType,
        communicationType,
        category
    }));
    OnClose();
  };

  const statusList = [
    {
      key: "ACTIVE",
      name: "Active",
    },
    {
      key: "INACTIVE",
      name: "Inactive",
    }
  ];

  const communicationTypeList = [
    {
      key: "EMAIL",
      name: "Email",
    },
    {
      key: "WHATSAPP",
      name: "Whats App",
    }
  ];

  const jobTypeList = [
    {
      key: "TECHNICAL",
      name: "Technical",
    },
    {
      key: "NON_TECHNICAL",
      name: "Non Technical",
    }
  ];

  const categoryList = [
    {
      key: "tier-1",
      name: "tier-1",
    },
    {
      key: "tier-2",
      name: "tier-2",
    },
    {
      key: "tier-3",
      name: "tier-3"
    }
  ];

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={OnClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box id="filters-container">
          <Box
            className="center mb-3"
            justifyContent="space-between"
            alignItems="start"
          >
            <div className="active-filter mb-1">
              {state.source.length ||
              state.communicationType.length ||
              state.jobType.length ||
              state.engagementType.length ||
              state.category.length ||
              state.status.length ? (
                <>
                  {state.communicationType.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("communicationType", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                  {state.source.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("source", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                  {state.status.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("status", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                  {state.jobType.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("jobType", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                  {state.engagementType.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("engagementType", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                  {state.category.map((ele) => (
                    <Chip
                      key={ele.key}
                      className="m-1"
                      color="primary"
                      onDelete={() => deleteChip("category", ele.key)}
                      label={ele.value}
                      variant="outlined"
                    />
                  ))}
                </>
              ) : (
                <Box className="mt-2" display="flex" alignItems="center">
                  <FilterAltOffIcon />
                  <Typography className="ml-2">
                    No filters are applied
                  </Typography>
                </Box>
              )}
            </div>
            <IconButton onClick={onClose} style={{ marginRight: "-10px" }}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Grid className="filter-box" container>
            <Grid id="left" item xs={5}>
              <List component="nav">
                <MenuItem
                  index={0}
                  label="Communication Type"
                  selectedMenu={state.selectedMenu === 0}
                  onChange={handleLeftListItem}
                  count={state.communicationType}
                />

                {module === "candidate" && <MenuItem
                  index={1}
                  label="Engagement Type"
                  selectedMenu={state.selectedMenu === 1}
                  onChange={handleLeftListItem}
                  count={state.engagementType}
                />}

                <MenuItem
                  index={2}
                  label="Status"
                  selectedMenu={state.selectedMenu === 2}
                  onChange={handleLeftListItem}
                  count={state.status}
                />

                {module === "candidate" && <MenuItem
                  index={3}
                  label="Source"
                  selectedMenu={state.selectedMenu === 3}
                  onChange={handleLeftListItem}
                  count={state.source}
                />}

                {module === "candidate" && <MenuItem
                  index={4}
                  label="Job Type"
                  selectedMenu={state.selectedMenu === 4}
                  onChange={handleLeftListItem}
                  count={state.jobType}
                />}

                {module === "cpd" && <MenuItem
                  index={5}
                  label="Category"
                  selectedMenu={state.selectedMenu === 5}
                  onChange={handleLeftListItem}
                  count={state.category}
                />}
              </List>
            </Grid>
            <Divider orientation="vertical" />

            <Grid id="right" item xs={6}>
              <List component="nav">
                {state.selectedMenu === 0 &&
                  communicationTypeList.map((type) => (
                    <ListItemButton
                      key={type.key}
                      selected={!!state.communicationType.find((ele) => ele.key === type.key)}
                      onClick={() =>
                        handleRightListItem(
                          "communicationType",
                          type.key,
                          capitalize(type.name)
                        )
                      }
                    >
                      <ListItemText primary={capitalize(type.name)} />
                      <Checkbox
                        edge="end"
                        checked={!!state.communicationType.find((ele) => ele.key === type.key)}
                      />
                    </ListItemButton>
                  ))}
                {state.selectedMenu === 1 &&
                  engagementTypes.map((engagementType) => (
                    <ListItemButton
                      key={engagementType._id}
                      selected={!!state.engagementType.find((ele) => ele.key === engagementType.name)}
                      onClick={() =>
                        handleRightListItem(
                          "engagementType",
                          engagementType.name.toUpperCase(),
                          capitalize(engagementType.name)
                        )
                      }
                    >
                      <ListItemText primary={capitalize(engagementType.name)} />
                      <Checkbox
                        edge="end"
                        checked={!!state.engagementType.find((ele) => ele.key === engagementType.name.toUpperCase())}
                      />
                    </ListItemButton>
                  ))}
                {state.selectedMenu === 2 &&
                  statusList.map((status) => (
                    <ListItemButton
                      key={status.key}
                      selected={!!state.status.find((ele) => ele.key === status.key)}
                      onClick={() =>
                        handleRightListItem("status", status.key, status.name)
                      }
                    >
                      <ListItemText primary={status.name} />
                      <Checkbox
                        edge="end"
                        checked={!!state.status.find((ele) => ele.key === status.key)}
                      />
                    </ListItemButton>
                  ))}
                {state.selectedMenu === 3 &&
                  defaultSource.map((user) => (
                    <ListItemButton
                      key={user.key}
                      selected={!!state.source.find((ele) => ele.key === user.key)}
                      onClick={() =>
                        handleRightListItem(
                          "source",
                          user.key,
                          user.name
                        )
                      }
                    >
                      <ListItemText
                        primary={capitalize(user.name)}
                      />
                      <Checkbox
                        edge="end"
                        checked={!!state.source.find((ele) => ele.key === user.key)}
                      />
                    </ListItemButton>
                  ))}
                {state.selectedMenu === 4 &&
                  jobTypeList.map((user) => (
                    <ListItemButton
                      key={user.key}
                      selected={!!state.jobType.find((ele) => ele.key === user.key)}
                      onClick={() =>
                        handleRightListItem(
                          "jobType",
                          user.key,
                          user.name
                        )
                      }
                    >
                      <ListItemText
                        primary={user.name}
                      />
                      <Checkbox
                        edge="end"
                        checked={!!state.jobType.find((ele) => ele.key === user.key)}
                      />
                    </ListItemButton>
                  ))}

                {state.selectedMenu === 5 &&
                  categoryList.map((user) => (
                    <ListItemButton
                      key={user.key}
                      selected={!!state.category.find((ele) => ele.key === user.key)}
                      onClick={() =>
                        handleRightListItem(
                          "category",
                          user.key,
                          user.name
                        )
                      }
                    >
                      <ListItemText
                        primary={capitalize(user.name)}
                      />
                      <Checkbox
                        edge="end"
                        checked={!!state.category.find((ele) => ele.key === user.key)}
                      />
                    </ListItemButton>
                  ))}
              </List>
            </Grid>
          </Grid>

          <Box className="actions-btn" marginTop="8px" textAlign="end">
            <Button
              variant="outlined"
              color="error"
              onClick={() => resetFilter()}
            >
              Clear All
            </Button>
            <Button className="ml-2" onClick={onApply}>
              Apply
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default AutomationFilters;
