import { Box, Tooltip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Outlet, useNavigate } from "react-router-dom";

import { WriterReportRoles } from "../../../services";
import CustomTable from "../../../components/mui/table";
import { IWriterReportRolesRow, IWriterReportRoleData, IRoleData } from "../../../interfaces";
import { capitalize, formatDateTime } from "../../../utilities/helper";
import GetActions from "../../../components/get-actions";
import useResource from "../../../hooks/useResource";


const WriterReport = () => {
  const { resourceAllocate } = useResource();
  const navigate = useNavigate();
  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "roles",
      label: "Roles"
    },
    {
      id: "createdAt",
      label: "Created At"
    },
    {
      id: "action",
      label: "Action"
    }
  ];

  const { getWriterReportRoles } = WriterReportRoles();
  const reportData = useQuery({
    queryKey: ["all-writer-report-roles"],
    queryFn: () => getWriterReportRoles()
  });

  const createRow = () => {
    const row: IWriterReportRolesRow[] = [];

    if(reportData && reportData.data && reportData.data.data.length) {

      reportData.data.data.map((data: IWriterReportRoleData, index: number) => {
        const roles = data._roles.map((role: IRoleData) => capitalize(role.name));
        row.push({
          id: index + 1,
          roles: <Tooltip title={roles.join(", ")}><span >{roles[0] } { roles.length > 1 ? <span style={{ color: "blue" }}>+{roles.length - 1}</span> : ""}</span></Tooltip>,
          createdAt: formatDateTime(data.createdAt),
          action: <GetActions icons={[{ name: "Edit", disabled: !resourceAllocate("cms-writer-report-roles.write"), method: () => navigate(`${data._id}`)}]}/>
        });
      });
    }

    return row;
  };
  
  return (
    <Box marginTop={"20px"}>
      <CustomTable 
        columns = {columns}
        rows = {createRow()}
        height="calc(100vh - 200px)" 
      />
      <Outlet context={{ refetchWriterReportRoles: reportData.refetch }}/>
    </Box>
  );
};

export default WriterReport;
