import Joi from "joi";
import { required } from "../../../shared";

export const blogImagesValidation = Joi.object({
    form: Joi.array()
        .items({
            imageType: Joi.string()
                .required()
                .label("Image Type")
                .messages({
                    "string.empty": required,
                }),
            imageUrl: Joi.string()
                .required()
                .label("Image")
                .messages({
                    "string.empty": required,
                }),
        })
        .sparse()
        .optional()
});
